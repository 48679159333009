import _ from 'lodash';
import moment from 'moment';
import DailySchedulePDF from '@/views/scheduling/pdf_templates/daily_schedule/DailySchedulePDF';
import { formatText, getDuration, isWorkingShiftForValidation, isOnCallWorkingShiftForValidation } from '@/utils/scheduling';

class DailySchedulePDF001 extends DailySchedulePDF {
  static get defaultOptions () {
    return {
      loadLastDates: false,
      loadExtraShifts: true
    };
  }

  static get name () {
    return 'DailySchedulePDF001';
  }

  /**
   * Gets shifts in the format specified by "format" in options. Defaults to stack.
   * @param {object} options Shifts display and query options
   * @returns pdfmake document definition object
   */
  _getShifts (options) {
    const t = this.t;
    const content = [];
    const {
      label = '',
      text = ''
    } = options;
    const shifts = this.adaptor.getShifts(options);
    const flags = this.store.state.org.flags.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});
    const shiftTypes = this.store.state.org.shiftTypes.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});
    const hasNonWorkingFlag = (shift) => {
      return (!_.isEmpty(shift.flags) && shift.flags.some(flagId => flags[flagId] && !flags[flagId].working));
    };
    const shouldCountShift = (shift) => {
      return isWorkingShiftForValidation(shift, flags) || isOnCallWorkingShiftForValidation(shift, flags);
    };

    const getInfo = (text, info) => {
      const getList = (shift) => {
        const list = [];
        if (shift.assignee.departmentId !== this.department.id) {
          const assigneeDepartment = _.find(this.store.state.org.departments, (d) => d.id === shift.assignee.departmentId);
          list.push(this._getText({
            content: `${t('labels.floatInFrom', { dept: assigneeDepartment.fullName })}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.overtime) {
          list.push(this._getText({
            content: `${t('labels.overtime')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.canceled) {
          list.push(this._getText({
            content: `${t('labels.canceled')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.swapped) {
          list.push(this._getText({
            content: `${t('labels.swapped')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.giveaway) {
          list.push(this._getText({
            content: `${t('labels.givenaway')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.onCall) {
          list.push(this._getText({
            content: `${t('labels.onCall')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.flags && shift.flags.length > 0) {
          const flagNames = [];
          for (let i = 0, count = shift.flags.length; i < count; i++) {
            if (flags[shift.flags[i]] && this.canShowFlag(shift.assigneeId, shift.flags[i])) {
              flagNames.push(flags[shift.flags[i]].name);
            }
          }
          list.push(this._getText({
            content: `${flagNames.join(', ')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.sitter) {
          const sitter = [`${t('labels.sitter')}`];
          const sitterInfo = [];
          const room = _.get(shift, 'settings.sitter.room', null);
          const reason = _.get(shift, 'settings.sitter.reason', null);
          if (reason) {
            sitterInfo.push(reason);
          }
          if (room) {
            sitterInfo.push(`${t('labels.room')}: ${room}`);
          }
          if (sitterInfo.length > 0) {
            sitter.push(sitterInfo.join(', '));
          }
          list.push(this._getText({
            content: sitter.join(' - '),
            style: 'shiftDetails'
          }));
        }
        if (shift.comments && (!this.isStaff || shift.assigneeId === this.store.state.account.userId)) {
          list.push(this._getText({
            content: `${shift.comments}`,
            style: 'shiftDetails'
          }));
        }
        return list;
      };
      const shift = info.activities[0];
      const content = [
        this._getText({
          content: formatText(text, shift),
          style: info.activities.length === 1 && (shift.canceled || shift.swapped || shift.giveaway || hasNonWorkingFlag(shift)) ? ['offDuty'] : []
        })
      ];
      const shiftList = {
        type: 'none',
        ul: []
      };
      const dailyHours = _.get(info, ['hours', 'daily', shift.date], 0);
      if (info.assignee.dailyHours && dailyHours > info.assignee.dailyHours) {
        const startTime = moment(`${this.date} 00:00:00`);
        const endTime = startTime.clone().add(dailyHours - info.assignee.dailyHours, 'hours');
        shiftList.ul.push([
          this._getText({
            content: this.t('descriptions.overtimeDaily', { time: getDuration(startTime, endTime) }),
            style: 'overtimeShiftDetails'
          })
        ]);
      }
      const weeklyHours = _.get(info, ['hours', 'weekly'], 0);
      if (info.assignee.weeklyHours && weeklyHours > info.assignee.weeklyHours) {
        const startTime = moment(`${this.date} 00:00:00`);
        const endTime = startTime.clone().add(weeklyHours - info.assignee.weeklyHours, 'hours');
        shiftList.ul.push([
          this._getText({
            content: this.t('descriptions.overtimeWeekly', { time: getDuration(startTime, endTime) }),
            style: 'overtimeShiftDetails'
          })
        ]);
      }
      for (let activityIndex = 0, activityCount = info.activities.length; activityIndex < activityCount; activityIndex++) {
        const shift = info.activities[activityIndex];
        const shiftType = shiftTypes[shift.typeId];
        const startTime = shift.startTime ? shift.startTime : shiftType.startTime;
        const endTime = shift.endTime ? shift.endTime : shiftType.endTime;
        const shiftInfo = [];
        if (startTime !== shiftType.startTime || endTime !== shiftType.endTime || activityCount > 1) {
          let time = `${_.split(startTime, ':', 2).join(':')} - ${_.split(endTime, ':', 2).join(':')}`;
          shiftInfo.push(this._getText({
            content: time,
            style: shift.canceled || shift.swapped || shift.giveaway || hasNonWorkingFlag(shift) ? ['offDuty'] : ['shiftDetails']
          }));
        }
        const list = getList(shift);
        shiftInfo.push({
          color: '#757575',
          type: 'square',
          ul: list,
          style: 'shiftDetailsList'
        });
        shiftList.ul.push(shiftInfo);
      }
      content.push(shiftList);
      return content;
    };

    let header;
    if (label) {
      header = {
        layout: 'groupHeader',
        table: {
          body: [
            [
              {
                bold: true,
                border: [false, false, false, false],
                text: `  ${label}  `,
                style: ['groupLabel']
              }
            ]
          ]
        }
      };
      content.push(header);
    }
    let shiftCount = 0;
    if (shifts.length > 0) {
      const center = Math.ceil(shifts.length / 2);
      const shiftsForLeftColumn = shifts.slice(0, center);
      const shiftsForRightColumn = shifts.slice(center);
      const leftColumn = {
        width: '50%',
        stack: []
      };
      for (let i = 0, count = shiftsForLeftColumn.length; i < count; i++) {
        leftColumn.stack.push(...getInfo(text, shiftsForLeftColumn[i]));
        if (shiftsForLeftColumn[i].activities.some((a) => shouldCountShift(a))) {
          shiftCount++;
        }
      }
      const rightColumn = {
        width: '50%',
        stack: []
      };
      for (let i = 0, count = shiftsForRightColumn.length; i < count; i++) {
        rightColumn.stack.push(...getInfo(text, shiftsForRightColumn[i]));
        if (shiftsForRightColumn[i].activities.some((a) => shouldCountShift(a))) {
          shiftCount++;
        }
      }
      const columns = {
        columns: [
          leftColumn,
          rightColumn
        ]
      };
      content.push(columns);
    }
    if (header) {
      header.table.body[0].push({
        border: [false, false, false, false],
        text: `${t('labels.total')}: ${shiftCount}`,
        style: 'shiftCount'
      });
    }
    return content;
  }

  generate () {
    const content = [];
    const staffNeeded = _.get(this.department, 'settings.staffNeeded', []);
    const dailyScheduleShiftTypes = _.get(this.type, 'shiftTypes', []);
    for (let group of staffNeeded) {
      let jobTitles = group.jobTypes.map((id) => _.get(this.jobTypesById, [id, 'name'], '')).join(' / ');
      let filterForCharge = false;
      for (let i = 0, count = group.jobTypes.length; i < count; i++) {
        if (this.jobTypesById[group.jobTypes[i]] && _.get(this.jobTypesById[group.jobTypes[i]], 'settings.isChargeNurse', false)) {
          filterForCharge = true;
          break;
        }
      }
      for (let shiftTypeId in group.shiftTypes) {
        if (dailyScheduleShiftTypes.includes(parseInt(shiftTypeId))) {
          let sectionTitle = `${jobTitles} - ${_.get(this.shiftTypesById, [shiftTypeId, 'name'], '')}`;
          if (_.get(this.shiftTypesById, [shiftTypeId, 'onCall'], false)) {
            sectionTitle += ` (${this.t('labels.onCall')})`;
          }
          let nameLabel = '';
          if (group.jobTypes.length > 1 || filterForCharge) {
            nameLabel += '{assignee.jobTypeName} ';
          }
          nameLabel += '{assignee.jobStatusShortCode} {assignee.lastName}, {assignee.firstName} {assignee.alias}';
          const filters = [
            [{ field: 'assignee.jobTypeId', op: 'in', value: group.jobTypes }, { field: 'type.id', op: '=', value: parseInt(shiftTypeId) }]
          ];
          if (filterForCharge) {
            filters.push([
              { field: 'type.id', op: '=', value: parseInt(shiftTypeId) },
              { field: 'assignee.charge', op: '=', value: true }
            ]);
            filters.push([
              { field: 'assignee.jobTypeId', op: 'in', value: group.jobTypes }
            ]);
          }
          content.push({
            type: 'shifts',
            format: 'stack',
            label: sectionTitle,
            text: nameLabel,
            filters,
            groupBy: [],
            orderBy: [
              {
                field: 'assignee.jobTypeName',
                direction: 'asc'
              },
              {
                field: 'assignee.jobStatusShortCode',
                direction: 'asc'
              },
              {
                field: 'assignee.lastName',
                direction: 'asc'
              },
              {
                field: 'assignee.firstName',
                direction: 'asc'
              }
            ]
          });
        }
      }
    }
    if (!this.store.getters['account/isStaff']()) {
      content.push({
        type: 'divider'
      });
      content.push({
        type: 'acuity',
        totals: true,
        details: true
      });
      content.push({
        type: 'divider'
      });
      content.push({
        type: 'notes'
      });
    }
    this.definition.content.push(...this._getStack(content));
  }
}

export default DailySchedulePDF001;
