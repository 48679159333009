<template>
  <v-container
    id="nurseDetails"
    class="px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-sliders-h</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.staffDetails') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-row>
      <v-col
        class="pb-0"
        cols="12"
      >
        <span class="grey--text text--darken-3 body-2 font-weight-medium mr-3">
          {{ latestUser.fullName }}
        </span>
        <v-icon
          :color="userState.color"
          size="8"
        >
          fas fa-circle
        </v-icon>
        <span class="caption grey--text text--darken-2">
          {{ userState.text }}
        </span>
        <v-btn
          v-if="allowEditingProfile"
          class="float-right grey lighten-3"
          icon
          small
          @click="showUserDialog"
        >
          <v-icon size="16">
            fal fa-pencil
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="px-0 mb-2"
      no-gutters
    >
      <v-col class="caption grey--text text--darken-1">
        <template v-for="(detail, idx) in userDetails">
          <div
            :key="`${detail}`"
            class="d-inline-block"
          >
            {{ detail }}
          </div>
          <v-divider
            v-if="idx < userDetails.length - 1"
            :key="`${detail}-div`"
            class="separator mx-2 d-inline pb-1"
            vertical
          />
        </template>
      </v-col>
    </v-row>
    <v-list
      class="grey--text text--darken-3 caption pt-0"
      dense
    >
      <v-list-item class="pa-0 user-contact-info v-list-item--active">
        <v-list-item-icon class="my-0 mr-0">
          <v-icon
            x-small
          >
            fal fa-phone
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pa-0">
          <span v-if="latestUser.phonePersonal">
            {{ latestUser.phonePersonal | VMask(phoneMask) }}
          </span>
          <span v-else>
            {{ $t('labels.noPhoneConfigured') }}
          </span>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="pa-0 user-contact-info v-list-item--active">
        <v-list-item-icon class="my-0 mr-0">
          <v-icon
            x-small
          >
            fal fa-envelope
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pa-0">
          <a :href="`mailto:${latestUser.email}`">
            {{ latestUser.email }}
          </a>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="pa-0 user-contact-info v-list-item--active">
        <v-list-item-icon class="my-0 mr-0">
          <v-icon
            x-small
          >
            fal fa-clock
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pa-0">
          <div class="d-inline-block">
            {{ $t('labels.hrsPerDay', { hours: latestUser.dailyHours || 0 }) }}
          </div>
          <v-divider
            class="separator mx-2 d-inline"
            vertical
          />
          <div class="d-inline-block">
            {{ $t('labels.hrsPerWeek', { hours: latestUser.weeklyHours || 0 }) }}
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="latestUser.charge"
        class="pa-0 user-contact-info v-list-item--active"
      >
        <v-list-item-icon class="my-0 mr-0">
          <v-icon
            x-small
          >
            fal fa-check
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content class="pa-0">
          {{ $t('labels.canActAsCharge') }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-tabs
      id="nurseDetailsContent"
      v-model="tab"
      centered
      class="dense"
      grow
      hide-slider
    >
      <v-tab
        class="text-capitalize"
        href="#notes"
      >
        {{ $t('labels.notes') }}
      </v-tab>
      <v-tab
        class="text-capitalize"
        href="#schedule"
      >
        {{ $t('labels.downloadSchedule') }}
      </v-tab>
      <v-tab-item
        :style="tabItemStyle"
        value="notes"
      >
        <Comments
          v-model="notes.new"
          :auto-grow="true"
          class="py-2"
          counter="1000"
          :disclosure-hint="$t('descriptions.disclaimer')"
          maxlength="1000"
          outlined
          :placeholder="$t('labels.addNotesPlaceholder')"
          rows="1"
          single-line
          :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
        />
        <v-btn
          class="px-4 py-5 float-right save-notes"
          color="accent"
          :disabled="savingNotes || notes.new === notes.previous"
          width="70"
          @click="saveNotes"
        >
          <v-progress-circular
            v-if="savingNotes"
            color="primary lighten-2"
            indeterminate
            size="16"
            width="2"
          />
          <span v-else>
            {{ $t('labels.save') }}
          </span>
        </v-btn>
      </v-tab-item>
      <v-tab-item
        value="schedule"
      >
        <v-card
          class="pa-4"
          outlined
        >
          <span class="grey--text caption">
            {{ $t('labels.downloadNurseWeeklySchedule') }}
          </span>
          <v-row>
            <v-col cols="7">
              <v-menu
                v-model="schedule.showSchedulePicker"
                min-width="250px"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, value }">
                  <v-btn
                    class="title font-weight-regular text-capitalize schedule-dropdown px-2"
                    elevation="0"
                    outlined
                    width="100%"
                    v-on="on"
                  >
                    <span class="body-2 font-weight-regular">
                      {{ selectedScheduleText }}
                    </span>
                    <v-spacer />
                    <v-icon
                      size="16"
                    >
                      {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                    </v-icon>
                  </v-btn>
                </template>
                <ScheduleSelection
                  :department="department"
                  :selected-schedule-id="schedule.selected.id"
                  @select="setSelectedSchedule"
                  @close="schedule.showSchedulePicker = false"
                />
              </v-menu>
            </v-col>
            <v-col
              class="px-0"
              cols="5"
            >
              <v-switch
                v-model="schedule.customPeriodEnabled"
                class="pt-0 mt-2 d-inline-block custom-period-toggle"
                color="success"
                dense
                hide-details
                inset
              >
                <template v-slot:label>
                  <span
                    class="body-2 grey--text text--darken-3"
                    :title="$t('labels.periodCustom')"
                  >
                    {{ $t('labels.periodCustom') }}
                  </span>
                </template>
              </v-switch>
            </v-col>
          </v-row>
          <v-row
            v-if="schedule.customPeriodEnabled"
          >
            <v-col cols="6">
              <v-menu
                ref="customPeriodStart"
                v-model="schedule.customPeriod.showStartPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    hide-details
                    :label="$t('labels.startDate')"
                    outlined
                    append-icon="fal fa-calendar-alt"
                    readonly
                    :value="schedule.customPeriod.start ? moment(schedule.customPeriod.start).format(dateFormatLong) : ''"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="schedule.customPeriod.start"
                  :min="schedule.selected.startOn"
                  :max="schedule.selected.endOn"
                  no-title
                  :picker-date.sync="schedule.customPeriod.startPickerDate"
                  @change="schedule.customPeriod.showStartPicker = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="customPeriodEnd"
                v-model="schedule.customPeriod.showEndPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    hide-details
                    :label="$t('labels.endDate')"
                    outlined
                    append-icon="fal fa-calendar-alt"
                    readonly
                    :value="schedule.customPeriod.end ? moment(schedule.customPeriod.end).format(dateFormatLong) : ''"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="schedule.customPeriod.end"
                  :min="schedule.selected.startOn"
                  :max="schedule.selected.endOn"
                  no-title
                  :picker-date.sync="schedule.customPeriod.endPickerDate"
                  @change="schedule.customPeriod.showEndPicker = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-select
                v-model="schedule.periods"
                class="weeks-picker subtitle-2"
                chips
                dense
                hide-details
                :items="weeks"
                :label="$t('labels.weeks')"
                :menu-props="{ top: false, offsetY: true }"
                multiple
                outlined
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="data.item.shortText"
                    v-bind="data.attrs"
                    class="my-1 font-weight-regular"
                    color="grey lighten-3"
                    small
                  >
                    {{ data.item.shortText }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="6">
              <v-btn
                class="px-4 py-5 float-right"
                color="secondary"
                :disabled="disabledScheduleDownload"
                width="144"
                @click="downloadSchedule"
              >
                <v-progress-circular
                  v-if="schedule.downloading"
                  color="primary lighten-2"
                  indeterminate
                  size="16"
                  width="2"
                />
                <span v-else>
                  {{ $t('labels.downloadPDF') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { showStatus } from '@/plugins/vue-notification';
import Comments from '@/components/Comments';
import UserSchedulePDF from '@/views/scheduling/UserSchedulePDF';
import ScheduleSelection from '@/components/scheduling/ScheduleSelection';
import { DATE_FORMAT } from '@/utils';
import { getRangeDisplayText } from '@/utils/scheduling';
import { ACCOUNT_STATE } from '@/services/constants';

export default {
  components: {
    Comments,
    ScheduleSelection
  },
  props: {
    date: {
      required: true,
      type: String
    },
    profileKey: {
      default: 'id',
      type: String
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    const department = this.$store.getters['org/getActiveDepartment']();
    let scheduleId = department.schedulingPeriods[0].id;
    if (this.date) {
      const schedulingPeriod = _.find(department.schedulingPeriods, (s) => this.date >= s.startOn && this.date <= s.endOn);
      if (schedulingPeriod) {
        scheduleId = schedulingPeriod.id;
      }
    }
    const selectedSchedule = _.find(department.schedulingPeriods, (s) => s.id === scheduleId);
    const user = this.$store.state.org.employees[this.user.userId];
    return {
      notes: {
        new: user.scheduleNotes,
        previous: user.scheduleNotes
      },
      phoneMask: '+# (###) ###-####',
      savingNotes: false,
      schedule: {
        customPeriod: {
          showEndPicker: false,
          showStartPicker: false,
          start: '',
          startPickerDate: moment(selectedSchedule.startOn).format('YYYY-MM'),
          end: '',
          endPickerDate: moment(selectedSchedule.endOn).format('YYYY-MM')
        },
        customPeriodEnabled: false,
        downloading: false,
        periods: [],
        selected: selectedSchedule,
        showSchedulePicker: false
      },
      tab: 'notes',
      tabHeight: 500
    };
  },
  computed: {
    allowEditingProfile () {
      return this.$store.getters['account/isDepartmentManagement']() || this.$store.getters['account/isOperationsManagement']();
    },
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    dateTimeFormat () {
      return this.$store.getters['org/getDateTimeFormatLong']();
    },
    department () {
      return this.$store.getters['org/getActiveDepartment']();
    },
    disabledScheduleDownload () {
      let invalidData = false;
      if (this.schedule.customPeriodEnabled) {
        invalidData = !(this.schedule.customPeriod.start && this.schedule.customPeriod.end);
      } else {
        invalidData = this.schedule.periods.length === 0;
      }
      return this.schedule.downloading || invalidData;
    },
    latestUser () {
      return this.$store.state.org.employees[this.user.userId];
    },
    selectedScheduleText () {
      const startFrom = moment(this.schedule.selected.startOn);
      const endBy = moment(this.schedule.selected.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    tabItemStyle () {
      const PADDING = 20;
      return {
        'height': `${this.tabHeight - PADDING}px`
      };
    },
    userDetails () {
      const details = [
        this.latestUser.jobTypeName,
        this.latestUser.jobStatusName,
        this.latestUser.shiftTypeName,
        this.latestUser.employeeId
      ];

      if (this.latestUser.gender) {
        details.push(this.$t(`labels.${this.latestUser.gender}`));
      }
      const hiredOn = _.get(this.latestUser, 'jobInfo.hiredOn', null);
      if (hiredOn) {
        details.push(`${this.$t('labels.hired')}: ${moment(hiredOn).format(this.dateFormatLong)}`);
      }

      return details;
    },
    userState () {
      return {
        color: this.latestUser.state === ACCOUNT_STATE.active ? 'success' : 'grey',
        text: this.$t(`labels.${_.findKey(ACCOUNT_STATE, (state) => state === this.latestUser.state)}`)
      };
    },
    weeks () {
      const schedule = this.schedule.selected;
      const start = moment(schedule.startOn);
      const end = moment(schedule.endOn);
      const numberOfWeeks = Math.floor((end.diff(start, 'days') + 1) / 7);
      const weeks = [];
      for (let i = 0; i < numberOfWeeks; i++) {
        const weekStart = start.clone().add(i * 7, 'day');
        const weekEnd = weekStart.clone().add(6, 'day');
        weeks.push({
          shortText: this.$t('labels.weekNumber', { number: i + 1 }),
          text: `${this.$t('labels.weekNumber', { number: i + 1 })} (${getRangeDisplayText(weekStart, weekEnd, this.$store)})`,
          value: i
        });
      }
      return weeks;
    }
  },
  watch: {
    'schedule.selected' () {
      this.schedule.customPeriod.start = '';
      this.schedule.customPeriod.startPickerDate = moment(this.schedule.selected.startOn).format('YYYY-MM');
      this.schedule.customPeriod.end = '';
      this.schedule.customPeriod.endPickerDate = moment(this.schedule.selected.endOn).format('YYYY-MM');
      this.schedule.periods = [];
    },
    'latestUser.scheduleNotes' () {
      this.notes.new = this.latestUser.scheduleNotes;
      this.notes.previous = this.latestUser.scheduleNotes;
    }
  },
  mounted: function () {
    this.updateTabHeight();
    window.addEventListener('resize', _.debounce(this.updateTabHeight, 500));
  },
  beforeDestroy: function () {
    this.$emit('destroyed');
    window.removeEventListener('resize', this.updateTabHeight);
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    downloadSchedule () {
      this.schedule.downloading = true;
      const shiftParams = {
        include_assignee_ids: this.latestUser.userId,
        canceled: 'false',
        non_duty: 'false'
      };

      const groupShifts = (shifts) => {
        const groupedShifts = {};
        for (let i = 0, count = shifts.length; i < count; i++) {
          if (!groupedShifts[shifts[i].date]) {
            groupedShifts[shifts[i].date] = [];
          }
          groupedShifts[shifts[i].date].push(shifts[i]);
        }
        return groupedShifts;
      };

      const scheduleStartOn = moment(this.schedule.selected.startOn);
      const weeks = _.sortBy(this.schedule.periods);
      if (this.schedule.customPeriodEnabled) {
        shiftParams.between = [this.schedule.customPeriod.start, this.schedule.customPeriod.end].join(',');
      } else {
        const dates = [];
        for (let i = 0, weeksCount = weeks.length; i < weeksCount; i++) {
          for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
            dates.push(scheduleStartOn.clone().add(weeks[i] * 7 + dayIndex, 'day').format(DATE_FORMAT));
          }
        }
        shiftParams.on = dates.join(',');
      }

      const lastCanceledCriteria = {
        type: 'canceled',
        assignee_ids: this.latestUser.userId
      };
      const lastFloatCriteria = {
        type: 'float',
        assignee_ids: this.latestUser.userId
      };
      Promise.all([
        this.dispatch('scheduling/retrieveShifts', shiftParams),
        this.dispatch('scheduling/retrieveLastShifts', lastFloatCriteria),
        this.dispatch('scheduling/retrieveLastShifts', lastCanceledCriteria)
      ]).then((response) => {
        const groupedShifts = groupShifts(response[0]);
        const lastFloat = _.get(response[1], [this.latestUser.userId, 'last', 'date'], null);
        const lastCancel = _.get(response[2], [this.latestUser.userId, 'last', 'date'], null);
        const userSchedulePDF = new UserSchedulePDF(this.latestUser, this.$store, (...args) => this.$t(...args));
        userSchedulePDF.setHeader(
          `${this.$t('labels.page')} {currentPage}/{pageCount}`,
          [
            moment().format(this.dateTimeFormat)
          ]
        );
        userSchedulePDF.setTitle(this.schedule.selected, lastFloat, lastCancel);
        if (this.schedule.customPeriodEnabled) {
          userSchedulePDF.addActivities(groupedShifts, getRangeDisplayText(this.schedule.customPeriod.start, this.schedule.customPeriod.end, this.$store));
        } else {
          for (let i = 0, weeksCount = weeks.length; i < weeksCount; i++) {
            const weekStart = scheduleStartOn.clone().add(weeks[i] * 7, 'day');
            const weekEnd = weekStart.clone().add(6, 'day');
            const weekShifts = {};
            for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
              const day = scheduleStartOn.clone().add(weeks[i] * 7 + dayIndex, 'day').format(DATE_FORMAT);
              if (groupedShifts[day]) {
                weekShifts[day] = groupedShifts[day];
              }
            }
            userSchedulePDF.addActivities(weekShifts, `${this.$t('labels.weekNumber', { number: weeks[i] + 1 })} (${getRangeDisplayText(weekStart, weekEnd, this.$store)})`, true);
          }
        }
        userSchedulePDF.download(`${this.latestUser.firstName}_schedule_${getRangeDisplayText(this.schedule.selected.startOn, this.schedule.selected.endOn, this.$store)}`);
      }).catch((error) => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.scheduleExportFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.schedule.downloading = false;
      });
    },
    moment,
    saveNotes () {
      this.savingNotes = true;
      const profile = {
        userId: this.latestUser.userId,
        profileId: this.latestUser[this.profileKey],
        scheduleNotes: this.notes.new
      };
      this.dispatch('scheduling/saveUserProfile', profile).then(() => {
        this.notes.previous = this.notes.new;
        this.$emit('notes-saved', this.notes.new);
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.scheduleUserNotesSaveFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.savingNotes = false;
      });
    },
    setSelectedSchedule (schedule) {
      this.schedule.selected = schedule;
      this.schedule.showSchedulePicker = false;
    },
    showUserDialog () {
      this.$store.commit('set_selected_user', this.user);
    },
    updateTabHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const nurseDetailsContent = document.getElementById('nurseDetailsContent');
      if (el && nurseDetailsContent) {
        this.tabHeight = el.clientHeight - nurseDetailsContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>

<style lang="scss">
#nurseDetails {
  @include round-tabs(#837EB7, #FFF);
  .custom-period-toggle {
    .v-input--selection-controls__input {
      margin-right: 0px;
    }
  }
  .schedule-dropdown {
    border-color: map-get($grey, 'lighten-2');
    justify-content: start;
  }
  .user-contact-info {
    min-height: 20px;
    .v-list-item__content > * {
      -webkit-box-flex: unset;
      flex: none;
    }
  }
  .v-tabs-bar__content {
    .v-tab {
      width: 50%;
    }
  }
  .weeks-picker {
    .v-chip__content {
      text-shadow: none !important;
    }
  }
}
</style>
