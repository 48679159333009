var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-3" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c("v-list-item-icon"),
          _c("v-list-item-content"),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("NoContent", {
            attrs: { height: 140, width: 140 },
            scopedSlots: _vm._u([
              {
                key: "message",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "subtitle-2 mt-10 text-center",
                        attrs: { slot: "message" },
                        slot: "message"
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("descriptions.noContent")) +
                              "\n          "
                          )
                        ]),
                        _c("div", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("descriptions.selectScheduleCell")
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }