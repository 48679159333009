var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "requests", attrs: { fluid: "" } },
    [
      _c("portal", { attrs: { to: "page-title" } }, [
        _c("span", { staticClass: "title page-title text-truncate" }, [
          _vm._v("\n      " + _vm._s(_vm.$tc("labels.request", 2)) + "\n    ")
        ])
      ]),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "no-gutters" },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    grow: _vm.$vuetify.breakpoint.smAndDown ? true : false,
                    "mobile-breakpoint": "",
                    "show-arrows": false,
                    "slider-color": "accent",
                    "slider-size": "3"
                  },
                  on: { change: _vm.retrieveRequestsForSelectedTab },
                  model: {
                    value: _vm.selectedTab,
                    callback: function($$v) {
                      _vm.selectedTab = $$v
                    },
                    expression: "selectedTab"
                  }
                },
                [
                  _c(
                    "v-tab",
                    { staticClass: "px-6", attrs: { href: "#giveaway" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.giveaway")) +
                          "\n          "
                      ),
                      _vm.$store.getters["request/giveawayRequestCount"] ||
                      _vm.$store.getters["request/giveawayRequestTotalCount"]
                        ? _c(
                            "div",
                            {
                              class: [
                                "font-weight-bold request-count ml-2 px-1 ratio"
                              ]
                            },
                            [
                              _vm.$store.getters["request/hasGiveawayFilters"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$store.getters[
                                            "request/giveawayRequestCount"
                                          ]
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$store.getters[
                                            "request/giveawayRequestCount"
                                          ] ||
                                            _vm.$store.getters[
                                              "request/giveawayRequestTotalCount"
                                            ]
                                        ) +
                                        "\n            "
                                    )
                                  ])
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-tab",
                    { staticClass: "px-6", attrs: { href: "#split" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("labels.split", 1)) +
                          "\n          "
                      ),
                      _vm.$store.getters["request/splitRequestCount"] ||
                      _vm.$store.getters["request/splitRequestTotalCount"]
                        ? _c(
                            "div",
                            {
                              class: [
                                "font-weight-bold request-count ml-2 px-1 ratio"
                              ]
                            },
                            [
                              _vm.$store.getters["request/hasSplitFilters"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$store.getters[
                                            "request/splitRequestCount"
                                          ]
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$store.getters[
                                            "request/splitRequestCount"
                                          ] ||
                                            _vm.$store.getters[
                                              "request/splitRequestTotalCount"
                                            ]
                                        ) +
                                        "\n            "
                                    )
                                  ])
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-tab",
                    { staticClass: "px-6", attrs: { href: "#swap" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.swap")) +
                          "\n          "
                      ),
                      _vm.$store.getters["request/swapRequestCount"] ||
                      _vm.$store.getters["request/swapRequestTotalCount"]
                        ? _c(
                            "div",
                            {
                              class: [
                                "font-weight-bold request-count ml-2 px-1 ratio"
                              ]
                            },
                            [
                              _vm.$store.getters["request/hasSwapFilters"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$store.getters[
                                            "request/swapRequestCount"
                                          ]
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$store.getters[
                                            "request/swapRequestCount"
                                          ] ||
                                            _vm.$store.getters[
                                              "request/swapRequestTotalCount"
                                            ]
                                        ) +
                                        "\n            "
                                    )
                                  ])
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-tab",
                    { staticClass: "px-6", attrs: { href: "#event" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tc("labels.event", 1)) +
                          "\n          "
                      ),
                      _vm.$store.getters["request/eventRequestCount"] ||
                      _vm.$store.getters["request/eventRequestTotalCount"]
                        ? _c(
                            "div",
                            {
                              class: [
                                "font-weight-bold request-count ml-2 px-1 ratio"
                              ]
                            },
                            [
                              _vm.$store.getters["request/hasEventFilters"]
                                ? _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$store.getters[
                                            "request/eventRequestCount"
                                          ]
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$store.getters[
                                            "request/eventRequestCount"
                                          ] ||
                                            _vm.$store.getters[
                                              "request/eventRequestTotalCount"
                                            ]
                                        ) +
                                        "\n            "
                                    )
                                  ])
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-tab",
                    { staticClass: "px-6", attrs: { href: "#history" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.history")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      attrs: { touchless: "" },
                      model: {
                        value: _vm.selectedTab,
                        callback: function($$v) {
                          _vm.selectedTab = $$v
                        },
                        expression: "selectedTab"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            "reverse-transition": false,
                            transition: false,
                            value: "giveaway"
                          }
                        },
                        [_c("GiveawayRequestList", { ref: "giveaway" })],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            "reverse-transition": false,
                            transition: false,
                            value: "split"
                          }
                        },
                        [_c("SplitRequestList", { ref: "split" })],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            "reverse-transition": false,
                            transition: false,
                            value: "swap"
                          }
                        },
                        [_c("SwapRequestList", { ref: "swap" })],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            "reverse-transition": false,
                            transition: false,
                            value: "event"
                          }
                        },
                        [_c("EventRequestList", { ref: "event" })],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          attrs: {
                            "reverse-transition": false,
                            transition: false,
                            value: "history"
                          }
                        },
                        [_c("HistoryList", { ref: "history" })],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }