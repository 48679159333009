<template>
  <v-container
    class="activity-details px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-calendar-day</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.scheduleDetails') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-container
      id="activitiesContent"
      class="pa-0"
      :style="panelStyle"
    >
      <v-hover
        v-if="shifts.length > 0"
        v-slot:default="{ hover }"
        class="mb-3"
      >
        <ShiftsCard
          :elevation="hover ? 2 : 0"
          :shifts="shifts"
          :user="user"
          @click="$emit('show-shifts', shifts)"
        />
      </v-hover>
    </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import ShiftsCard from '@/components/scheduling/ShiftsCard';

export default {
  components: {
    ShiftsCard
  },
  props: {
    shifts: {
      default: function () {
        return [];
      },
      type: Array,
      required: true
    },
    user: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    }
  },
  data () {
    return {
      panelHeight: 500
    };
  },
  computed: {
    dateFormatStringWithDoW () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    panelStyle () {
      return {
        'height': `${this.panelHeight}px`,
        'overflow-y': 'auto'
      };
    }
  },
  mounted: function () {
    this.updatePanelHeight();
    window.addEventListener('resize', _.debounce(this.updatePanelHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updatePanelHeight);
  },
  methods: {
    moment,
    updatePanelHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const activitiesContent = document.getElementById('activitiesContent');
      if (el && activitiesContent) {
        this.panelHeight = el.clientHeight - activitiesContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>
