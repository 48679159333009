<template>
  <v-container class="review-comments px-8 py-0">
    <v-list-item class="headline page-title pa-0">
      <v-list-item-content class="panel-title">
        {{ $tc('labels.comment', 2) }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-row>
      <v-col cols="12">
        <span>{{ $t('labels.requestComment') }}</span>
        <v-textarea
          v-model="updatedComments"
          :auto-grow="true"
          counter="1000"
          maxlength="1000"
          outlined
          :placeholder="`${$t('descriptions.disclaimer')}`"
          rows="6"
          single-line
        />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col
        class="text-right"
        cols="6"
      >
        <v-btn
          class="px-8"
          color="accent"
          @click="$emit('reject', updatedComments)"
        >
          {{ $t('labels.reject') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    comments: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      updatedComments: this.comments
    };
  }
};
</script>
