exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.employee-selector .selected {\n  border: 1px solid #e0e0e0 !important;\n  margin-top: 4px !important;\n  text-shadow: none;\n}\n.employee-selector .selected .name {\n  max-width: 120px;\n}\n.employee-selector .selected .v-avatar {\n  font-size: 9px !important;\n  height: 18px !important;\n  min-width: 18px !important;\n  width: 18px !important;\n}\n.employee-selector .v-text-field__details {\n  padding: 0px !important;\n}\n.employee-selector .v-text-field__details .v-messages__message {\n  color: #9e9e9e;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};