<template>
  <v-container
    class="requests pa-0"
    fluid
  >
    <v-row
      v-if="!retrievingPendingRequests && !pendingRequestsFiltered && !swapRequestCount"
      class="no-request"
      no-gutters
    >
      <v-col
        align-self="center"
        cols="12"
        md="10"
      >
        <v-row
          class="mb-4"
          justify="center"
        >
          <v-img
            contain
            max-width="120"
            src="@/assets/images/no-content-penguin.svg"
          />
        </v-row>
        <v-row
          class="mt-4"
          justify="center"
        >
          {{ $t('descriptions.noRequestPending') }}
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row
      v-else
      no-gutters
    >
      <v-data-table
        v-model="selectedRows"
        v-resize.quiet="onWindowResized"
        class="elevation-1 pending-requests"
        dense
        :expanded="expandedRows"
        :headers="pendingRequestsHeaders"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="$store.state.request.swapRequests.records"
        :items-per-page="$store.state.request.pageSize"
        :loading="retrievingPendingRequests"
        :loading-text="$t('descriptions.loadingRequests')"
        mobile-breakpoint=""
        must-sort
        :no-data-text="$t('descriptions.noRequestPending')"
        :options.sync="pendingRequestsOptions"
        :page.sync="pendingRequestsCurrentPage"
        :server-items-length="$store.state.request.swapRequests.count"
        show-expand
        :single-select="true"
        @click:row="onItemClicked"
      >
        <template #header.createdBy>
          <StaffSearch
            v-model.trim="pendingRequestsOptions.searchByName"
            :append-icon="pendingRequestsOptions.searchByName ? '' : 'fal fa-search'"
            target-class="py-2 search-name"
            :clearable="!!pendingRequestsOptions.searchByName"
            dense
            hide-details
            solo
          />
        </template>
        <template #header.deptIds="{ header }">
          <MultiSelectionList
            :selection="departments"
            @selectionConfirmed="onPendingRequestFilterChanged(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ $tc('labels.department', 1) }}
                <v-icon
                  v-if="pendingRequestsOptions.filterBy.deptIds"
                  class="ml-2 mt-1"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2 mt-1"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
        </template>
        <template #item.createdBy="{ item }">
          <v-list-item
            class="px-0"
          >
            <v-list-item-avatar class="mr-1">
              <v-avatar
                :color="$store.state.org.employees[item.assocContent.sourceUserId].avatarBgColor"
                size="30"
              >
                <span class="white--text subtitle-2">
                  {{ getAvatar($store.state.org.employees[item.assocContent.sourceUserId]) }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="body-2 mb-1 name-n-avatar">
                <UserName
                  :user="$store.state.org.employees[item.assocContent.sourceUserId]"
                />
              </v-list-item-title>
              <v-list-item-subtitle class="caption mt-1">
                {{ getJobInfo(item) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #item.deptIds="{ item }">
          <div class="text-capitalize type">
            {{ getDepartment(item) }}
          </div>
        </template>
        <template #item.scheduledDate="{ item }">
          <div class="text-capitalize type">
            {{ formatDate(item.assocContent.sourceShiftDate) }}
          </div>
        </template>
        <template #item.requestedDate="{ item }">
          <div class="text-capitalize type">
            {{ formatDate(item.assocContent.targetShiftDate) }}
          </div>
        </template>
        <template #item.createdOn="{ item }">
          {{ formatDateTime(item.createdOn) }}
        </template>
        <template #item.reviewerId="{ item }">
          {{ getReviewerName(item) }}
        </template>
        <template #no-data>
          <div class="body-2 grey--text mt-5 no-results text--darken-3 text-left">
            <div class="my-2 subtitle-2">
              {{ $t('descriptions.noResultsFound') }}
            </div>
            <v-divider />
            <div class="mb-2 mt-4">
              {{ $t('descriptions.emptyGridHelpTitle') }}
            </div>
            <div class="my-2">
              <ul>
                <li>{{ $t('descriptions.emptyGridHelpRemoveFilters') }}</li>
                <li>{{ $t('descriptions.emptyGridHelpShorterWords') }}</li>
                <li>{{ $t('descriptions.emptyGridHelpSpelling') }}</li>
              </ul>
            </div>
          </div>
        </template>
        <template #item.data-table-expand="{ expand, isExpanded }">
          <v-btn
            class="request-expand"
            icon
            small
            @click.stop.prevent="expand(!isExpanded)"
          >
            <v-icon
              small
            >
              {{ isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers: innerHeaders, item }">
          <td
            :colspan="innerHeaders.length"
          >
            <SwapRequestSummary
              class="pl-11"
              :request="item"
            />
          </td>
        </template>
        <template #footer>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <div class="mobile pa-1 pagination text-center">
              <v-btn
                :disabled="retrievingPendingRequests || pendingRequestsCurrentPage === 1"
                icon
                @click="paginatePendingRequests(-1)"
              >
                <v-icon small>
                  far fa-chevron-left
                </v-icon>
              </v-btn>
              <span class="body-2 mx-1">
                {{ pendingRequestsCurrentPage }}
              </span>
              <span class="body-2 grey--text text--darken-2">/</span>
              <span class="body-2 mx-1 grey--text text--darken-2">
                {{ $store.state.request.swapRequests.pages }}
              </span>
              <v-btn
                :disabled="retrievingPendingRequests || pendingRequestsCurrentPage === $store.state.request.swapRequests.pages"
                icon
                @click="paginatePendingRequests(1)"
              >
                <v-icon small>
                  far fa-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </template>
          <v-pagination
            v-else
            v-model="pendingRequestsCurrentPage"
            class="ma-1"
            color="secondary"
            :disabled="retrievingPendingRequests"
            :length="$store.state.request.swapRequests.pages"
            next-icon="far fa-chevron-right"
            prev-icon="far fa-chevron-left"
            :total-visible="maxPaginationControls"
          />
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import MultiSelectionList from '@/components/MultiSelectionList';
import StaffSearch from '@/components/StaffSearch';
import { showStatus } from '@/plugins/vue-notification';
import { RequestCanceledError } from '@/services/errors';
import { getAvatar, SEARCH_INPUT_DEBOUNCE } from '@/utils';
import Resize from 'vuetify/es5/directives/resize';
import UserName from '@/components/scheduling/UserName';
import SwapRequestSummary from '@/views/scheduling/requests/SwapRequestSummary';

export default {
  directives: {
    Resize
  },

  components: {
    MultiSelectionList,
    StaffSearch,
    SwapRequestSummary,
    UserName
  },

  data () {
    return {
      debouncingWsRequest: false,
      expandedRows: [],
      maxPaginationControls: 7,
      nameFilter: '',
      pendingRequestsHeaders: [
        { sortable: false, text: '', value: 'createdBy' },
        { sortable: false, text: this.$tc('labels.department', 1), value: 'deptIds' },
        { sortable: false, text: this.$t('labels.scheduledDate'), value: 'scheduledDate' },
        { sortable: false, text: this.$t('labels.requestedDate'), value: 'requestedDate' },
        { text: this.$t('labels.createdOn'), value: 'createdOn', width: 155 },
        { sortable: false, text: this.$t('labels.reviewer'), value: 'reviewerId' },
        { text: '', value: 'data-table-expand' }
      ],
      pendingRequestsOptions: {
        filterBy: {},
        page: 1,
        searchByName: '',
        sortBy: [ 'createdOn' ],
        sortDesc: [ true ]
      },
      retrievingPendingRequests: false,
      selectedRows: []
    };
  },

  computed: {
    // There are certain Vuetify Data Table properties that we'd like to watch together. However Vuejs 2.x
    // doesn't support watching multiple properties for a single callback. All computed properties below
    // with 'Watchable' suffix are created as a workaround to this limitation.
    ...mapGetters('request', [
      'swapRequestCount', 'swapRequestTotalCount'
    ]),
    departments () {
      return _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling).map(department => {
        return { label: department.name, value: department.id };
      });
    },
    pendingRequestsCurrentPage: {
      get () {
        return this.$store.state.request.swapRequests.currentPage;
      },
      set (value) {
        this.$store.commit('request/set_current_page', value);
      }
    },
    pendingRequestsFiltered () {
      let isFiltered = false;
      isFiltered |= !!this.pendingRequestsOptions.searchByName;
      isFiltered |= !_.isEmpty(this.pendingRequestsOptions.filterBy);
      return !!isFiltered;
    },
    pendingRequestsOrderingWatchable () {
      return this.pendingRequestsOptions.sortBy.reduce((orderingCriteria, value, idx) => {
        orderingCriteria[value] = this.pendingRequestsOptions.sortDesc[idx];
        return orderingCriteria;
      }, {});
    },
    ...mapState(['sidePanelOpened'])
  },
  watch: {
    'pendingRequestsOptions.page': function () {
      // Page number is already updated in the store via setter function of the respective computed property.
      this.retrieveRequests();
    },
    'pendingRequestsOptions.searchByName': function (value) {
      this.$store.commit('request/set_filter', { [this.pendingRequestsHeaders[0].value]: value });
      if (value) {
        this.debouncingWsRequest = true;
        this.retrieveRequestsDebounced();
      } else {
        // When search box is cleared, retrieve data immediately so that the "no content penguin" won't flash.
        this.retrieveRequests();
      }
    },
    pendingRequestsOrderingWatchable (newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue)) {
        this.$store.commit('request/set_swap_requests_ordering', newValue);
        this.retrieveRequests();
      }
    }
  },
  mounted () {
    this.calcPageSize();
    this.retrieveRequests();
  },
  methods: {
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 48;
      const tableHeaderHeight = 55;
      const tdHeight = 63;
      const tableFooterHeight = 40;
      const bottomNavHeight = 56; // Mobile only
      const marginAndPadding = 50;
      let tbodyHeight;

      if (this.$vuetify.breakpoint.smAndDown) {
        tbodyHeight = window.innerHeight - (
          topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + bottomNavHeight + marginAndPadding
        );
      } else {
        tbodyHeight = window.innerHeight - (
          topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
        );
      }

      const pageSize = Math.floor(tbodyHeight / tdHeight);
      if (pageSize !== this.$store.state.request.pageSize) {
        this.$store.commit('request/set_page_size', pageSize);
        return true;
      }
      return false;
    },
    getAvatar,
    getDepartment (item) {
      let propName = 'departmentId';
      if (item.inferredType === 'swap') {
        propName = 'sourceDepartmentId';
      }
      const deptId = _.get(item, ['assocContent', propName], null);
      let department = '';
      if (deptId) {
        department = this.$store.getters['org/getDepartmentById'](deptId, 'name');
      }
      return department;
    },
    getJobInfo (itemData) {
      const userInfo = this.$store.state.org.employees[itemData.assocContent.sourceUserId];
      return [userInfo.jobTypeName, userInfo.jobStatusShortCode].filter(Boolean).join(' ');
    },
    getReviewerName (itemData) {
      return _.get(this.$store.state.org.employees, [itemData.reviewerId, 'fullName'], '');
    },
    formatDate (date) {
      const dateFormat = this.$store.getters['org/getDateFormatLong']();
      return moment(date).format(dateFormat);
    },
    formatDateTime (originalDateTime) {
      const dateTimeFormat = this.$store.getters['org/getDateTimeFormatLong']();
      return moment(originalDateTime).format(dateTimeFormat);
    },
    moment,
    onItemClicked (itemData) {
      if (this.sidePanelOpened && _.get(this.selectedRows, '0.assocContent.id', null) === itemData.assocContent.id) {
        return;
      }
      this.selectedRows = [itemData];
      this.$store.dispatch('showRequestPanel', {
        id: itemData.assocContent.id,
        type: itemData.assocContent.contentType
      }, { root: true });
    },
    onNotificationReceived (data) {
      if (!this.retrievingPendingRequests) {
        this.retrieveRequests();
      }
    },
    onPendingRequestFilterChanged (columnName, criteria) {
      if (criteria.length) {
        this.$set(this.pendingRequestsOptions.filterBy, columnName, criteria);
      } else {
        this.$delete(this.pendingRequestsOptions.filterBy, columnName);
      }

      this.$store.commit('request/set_filter', { [columnName]: criteria });
      this.retrieveRequests();
    },
    onRequestUpdateReceived (request) {
      if (!this.retrievingPendingRequests) {
        const data = JSON.parse(request.data);
        const associatedObjectId = _.get(data, 'associated_object_id');
        if (this.$store.state.request.swapRequests.records.find(
          el => el.assocContent && el.assocContent.id === associatedObjectId
        )) {
          this.retrieveRequests();
        }
      }
    },
    onWindowResized () {
      if (this.$store.state.request.activeList === 'swap' && this.calcPageSize()) {
        this.retrieveRequests();
      }
    },
    paginatePendingRequests (increment) {
      let newPageNumber = this.pendingRequestsCurrentPage + increment;
      if (newPageNumber >= 1 && newPageNumber <= this.$store.state.request.swapRequests.pages) {
        this.pendingRequestsCurrentPage = newPageNumber;
      }
    },
    retrieveRequests () {
      if (!this.retrievingPendingRequests) {
        this.retrievingPendingRequests = true;
        if (!this.pendingRequestsOptions.searchByName) {
          this.expandedRows = [];
        }
        this.$store.dispatch('request/retrieveSwapRequests').then(response => {
          if (this.pendingRequestsOptions.searchByName) {
            for (let record of this.$store.state.request.swapRequests.records) {
              const targetUser = this.$store.state.org.employees[record.assocContent.targetUserId];
              if (targetUser) {
                const names = this.pendingRequestsOptions.searchByName.split(' ').map((n) => n.trim().toLowerCase());
                const phoneNumber = this.pendingRequestsOptions.searchByName.trim().replace(/[ ()-]/g, '');
                let matches = false;
                if (names.length === 1) {
                  matches = targetUser.alias.toLowerCase().includes(names[0]) || targetUser.firstName.toLowerCase().includes(names[0]) ||
                    targetUser.lastName.toLowerCase().includes(names[0]) || targetUser.phonePersonal.includes(phoneNumber);
                } else {
                  matches = targetUser.firstName.toLowerCase().includes(names[0]) ||
                    targetUser.lastName.toLowerCase().includes(names[1]) || targetUser.phonePersonal.includes(phoneNumber);
                }
                if (matches) {
                  this.expandedRows.push(record);
                }
              }
            }
          }
        }).catch(error => {
          // Many non-dependent async operations on this page could trigger the retrieval of request data
          // (such as resizing window, receiving WebSocket notification, etc.). In theses cases is it okay
          // to ignore the error raised by WsProxy when it detected duplicate requests.
          if (!(error instanceof RequestCanceledError)) {
            const data = { error: _.get(error, 'response.data') };
            showStatus({ text: this.$t('descriptions.requestRetrievePendingFail'), type: 'error', data });
          }
        }).finally(() => {
          this.retrievingPendingRequests = false;
          this.selectedRows = [];
        });
      }
    },
    retrieveRequestsDebounced: _.debounce(function () {
      this.retrieveRequests();
      this.debouncingWsRequest = false;
    }, SEARCH_INPUT_DEBOUNCE)
  }
};
</script>

<style lang="scss">
.requests {
  .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
    background: map-get($grey, 'lighten-5') !important;
    box-shadow: none !important;
  }
}
</style>
