<template>
  <v-container
    class="openshifts"
    fluid
  >
    <template>
      <portal to="page-title">
        <span class="title page-title text-truncate">
          {{ $tc('labels.openShift', 2) }}
        </span>
      </portal>
      <v-container fluid>
        <v-row class="no-gutters">
          <v-tabs
            v-model="selectedTab"
            :grow="$vuetify.breakpoint.smAndDown ? true : false"
            mobile-breakpoint=""
            :show-arrows="false"
            slider-color="accent"
            slider-size="3"
            @change="retrieveOpenShiftsForSelectedTab"
          >
            <v-tab
              class="px-6"
              href="#pending"
            >
              {{ pendingOpenShiftsTabLabel }}
            </v-tab>
            <v-tab
              class="px-6"
              href="#history"
            >
              {{ historyOpenShiftsTabLabel }}
            </v-tab>
            <v-tabs-items
              v-model="selectedTab"
              touchless
            >
              <v-tab-item
                :reverse-transition="false"
                :transition="false"
                value="pending"
              >
                <v-row
                  v-if="!retrievingPendingOpenShifts && !pendingOpenShiftsFiltered && !pendingOpenShiftCount"
                  class="no-openshift"
                  no-gutters
                >
                  <v-col
                    align-self="center"
                    cols="12"
                    md="10"
                  >
                    <v-row
                      class="mb-4"
                      justify="center"
                    >
                      <v-img
                        contain
                        max-width="120"
                        src="@/assets/images/no-content-penguin.svg"
                      />
                    </v-row>
                    <v-row
                      class="mt-4"
                      justify="center"
                    >
                      {{ $t('descriptions.noOpenShiftPending') }}
                    </v-row>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-data-table
                    v-model="selectedRows"
                    v-resize.quiet="onWindowResized"
                    class="elevation-1 pending-openshifts"
                    dense
                    :headers="pendingOpenShiftsHeaders"
                    :header-props="{ sortIcon: 'fa fa-arrow-up' }"
                    hide-default-footer
                    :items="$store.state.openshift.pendingOpenShifts.records"
                    :items-per-page="$store.state.openshift.pageSize"
                    :loading="retrievingPendingOpenShifts"
                    :loading-text="$t('descriptions.loadingOpenShifts')"
                    mobile-breakpoint=""
                    must-sort
                    :no-data-text="$t('descriptions.noOpenShiftPending')"
                    :options.sync="pendingOpenShiftsOptions"
                    :page.sync="pendingOpenShiftsCurrentPage"
                    :server-items-length="$store.state.openshift.pendingOpenShifts.count"
                    :single-select="true"
                    @click:row="onItemClicked"
                  >
                    <template #header.departmentId="{ header }">
                      <MultiSelectionList
                        :selection="departments"
                        @selectionConfirmed="onPendingOpenShiftFilterChanged(header.value, $event)"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            class="ml-n4 subtitle-2 text-capitalize"
                            color="secondary"
                            text
                            v-on="on"
                          >
                            {{ $tc('labels.department', 1) }}
                            <v-icon
                              v-if="pendingOpenShiftsOptions.filterBy.departmentId"
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fas fa-filter
                            </v-icon>
                            <v-icon
                              v-else
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fal fa-filter
                            </v-icon>
                          </v-btn>
                        </template>
                      </MultiSelectionList>
                    </template>
                    <template #header.jobTypes="{ header }">
                      <MultiSelectionList
                        :selection="jobTypes"
                        @selectionConfirmed="onPendingOpenShiftFilterChanged(header.value, $event)"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            class="ml-n4 subtitle-2 text-capitalize"
                            color="secondary"
                            text
                            v-on="on"
                          >
                            {{ $tc('labels.jobType', 2) }}
                            <v-icon
                              v-if="pendingOpenShiftsOptions.filterBy.jobTypes"
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fas fa-filter
                            </v-icon>
                            <v-icon
                              v-else
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fal fa-filter
                            </v-icon>
                          </v-btn>
                        </template>
                      </MultiSelectionList>
                    </template>
                    <template #header.bids="{ header }">
                      <v-menu
                        v-model="bidsFilterOpen"
                        :close-on-content-click="false"
                        max-width="400"
                        min-width="140"
                        :offset-y="true"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            class="ml-n4 subtitle-2 text-capitalize"
                            color="secondary"
                            text
                            v-on="on"
                          >
                            {{ $t('labels.selectedBids') }}
                            <v-icon
                              v-if="pendingOpenShiftsOptions.filterBy.bids"
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fas fa-filter
                            </v-icon>
                            <v-icon
                              v-else
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fal fa-filter
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-card
                          class="px-4"
                        >
                          <v-switch
                            v-model="pendingBidsOnlyFilter"
                            color="success"
                            @change="onPendingOpenShiftFilterChangedInternal(header.value, $event)"
                          >
                            <template v-slot:label>
                              <span
                                class="mr-4 body-2 grey--text text--darken-3"
                                :title="$t('labels.pendingBidsOnly')"
                              >
                                {{ $t('labels.pendingBidsOnly') }}
                              </span>
                            </template>
                          </v-switch>
                        </v-card>
                      </v-menu>
                    </template>
                    <template #header.typeId="{ header }">
                      <MultiSelectionList
                        :selection="shiftTypes"
                        @selectionConfirmed="onPendingOpenShiftFilterChanged(header.value, $event)"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            class="ml-n4 subtitle-2 text-capitalize"
                            color="secondary"
                            text
                            v-on="on"
                          >
                            {{ $t('labels.shiftType') }}
                            <v-icon
                              v-if="pendingOpenShiftsOptions.filterBy.typeId"
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fas fa-filter
                            </v-icon>
                            <v-icon
                              v-else
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fal fa-filter
                            </v-icon>
                          </v-btn>
                        </template>
                      </MultiSelectionList>
                    </template>
                    <template #header.fcfs="{ header }">
                      <MultiSelectionList
                        :selection="fcfs"
                        @selectionConfirmed="onPendingOpenShiftFilterChanged(header.value, $event)"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            class="ml-n4 subtitle-2 text-uppercase"
                            color="secondary"
                            text
                            v-on="on"
                          >
                            {{ $t('labels.fcfs') }}
                            <v-icon
                              v-if="pendingOpenShiftsOptions.filterBy.fcfs"
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fas fa-filter
                            </v-icon>
                            <v-icon
                              v-else
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fal fa-filter
                            </v-icon>
                          </v-btn>
                        </template>
                      </MultiSelectionList>
                    </template>
                    <template #item.departmentId="{ item }">
                      <div
                        class="text-truncate type"
                        style="width: 116px;"
                        :title="getDepartment(item)"
                      >
                        {{ getDepartment(item) }}
                      </div>
                    </template>
                    <template #item.jobTypes="{ item }">
                      <div
                        class="text-truncate type"
                        style="width: 116px;"
                        :title="getJobType(item)"
                      >
                        {{ getJobType(item) }}
                      </div>
                    </template>
                    <template #item.bids="{ item }">
                      <div
                        class="text-truncate type"
                      >
                        {{ getSelectedBids(item) }}
                      </div>
                    </template>
                    <template #item.typeId="{ item }">
                      <div
                        class="text-truncate type"
                        style="width: 116px;"
                        :title="getShiftType(item)"
                      >
                        {{ getShiftType(item) }}
                      </div>
                    </template>
                    <template #item.fcfs="{ item }">
                      {{ item.fcfs ? $t('labels.yes') : $t('labels.no') }}
                    </template>
                    <template #item.endsIn="{ item }">
                      {{ getEndsIn(item) }}
                    </template>
                    <template #item.date="{ item }">
                      {{ formatDate(item.date) }}
                    </template>
                    <template #item.createdOn="{ item }">
                      {{ formatDateTime(item.createdOn) }}
                    </template>
                    <template #no-data>
                      <div class="body-2 grey--text mt-5 no-results text--darken-3 text-left">
                        <div class="my-2 subtitle-2">
                          {{ $t('descriptions.noResultsFound') }}
                        </div>
                        <v-divider />
                        <div class="mb-2 mt-4">
                          {{ $t('descriptions.emptyGridHelpTitle') }}
                        </div>
                        <div class="my-2">
                          <ul>
                            <li>{{ $t('descriptions.emptyGridHelpRemoveFilters') }}</li>
                            <li>{{ $t('descriptions.emptyGridHelpShorterWords') }}</li>
                            <li>{{ $t('descriptions.emptyGridHelpSpelling') }}</li>
                          </ul>
                        </div>
                      </div>
                    </template>
                    <template #footer>
                      <template v-if="$vuetify.breakpoint.smAndDown">
                        <div class="mobile pa-1 pagination text-center">
                          <v-btn
                            :disabled="retrievingPendingOpenShifts || pendingOpenShiftsCurrentPage === 1"
                            icon
                            @click="paginatePendingOpenShifts(-1)"
                          >
                            <v-icon small>
                              far fa-chevron-left
                            </v-icon>
                          </v-btn>
                          <span class="body-2 mx-1">
                            {{ pendingOpenShiftsCurrentPage }}
                          </span>
                          <span class="body-2 grey--text text--darken-2">/</span>
                          <span class="body-2 mx-1 grey--text text--darken-2">
                            {{ $store.state.openshift.pendingOpenShifts.pages }}
                          </span>
                          <v-btn
                            :disabled="retrievingPendingOpenShifts || pendingOpenShiftsCurrentPage === $store.state.openshift.pendingOpenShifts.pages"
                            icon
                            @click="paginatePendingOpenShifts(1)"
                          >
                            <v-icon small>
                              far fa-chevron-right
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <v-pagination
                        v-else
                        v-model="pendingOpenShiftsCurrentPage"
                        class="ma-1"
                        color="secondary"
                        :disabled="retrievingPendingOpenShifts"
                        :length="$store.state.openshift.pendingOpenShifts.pages"
                        next-icon="far fa-chevron-right"
                        prev-icon="far fa-chevron-left"
                        :total-visible="maxPaginationControls"
                      />
                    </template>
                  </v-data-table>
                </v-row>
              </v-tab-item>
              <v-tab-item
                :reverse-transition="false"
                :transition="false"
                value="history"
              >
                <v-row
                  v-if="!retrievingHistoryOpenShifts && !historyOpenShiftsFiltered && !historyOpenShiftCount"
                  class="no-openshift"
                  no-gutters
                >
                  <v-col
                    align-self="center"
                    cols="12"
                    md="10"
                  >
                    <v-row
                      class="mb-4"
                      justify="center"
                    >
                      <v-img
                        contain
                        max-width="120"
                        src="@/assets/images/no-content-penguin.svg"
                      />
                    </v-row>
                    <v-row
                      class="mt-4"
                      justify="center"
                    >
                      {{ $t('descriptions.noOpenShiftHistory') }}
                    </v-row>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-data-table
                    v-model="selectedRows"
                    v-resize.quiet="onWindowResized"
                    class="elevation-1 history-openshifts"
                    dense
                    :headers="historyOpenShiftsHeaders"
                    :header-props="{ sortIcon: 'fa fa-arrow-up' }"
                    hide-default-footer
                    :items="$store.state.openshift.historyOpenShifts.records"
                    :items-per-page="$store.state.openshift.pageSize"
                    :loading="retrievingHistoryOpenShifts"
                    :loading-text="$t('descriptions.loadingOpenShifts')"
                    mobile-breakpoint=""
                    must-sort
                    :no-data-text="$t('descriptions.noOpenShiftHistory')"
                    :options.sync="historyOpenShiftsOptions"
                    :page.sync="historyOpenShiftsCurrentPage"
                    :server-items-length="$store.state.openshift.historyOpenShifts.count"
                    :single-select="true"
                    @click:row="onItemClicked"
                  >
                    <template #header.departmentId="{ header }">
                      <MultiSelectionList
                        :selection="departments"
                        @selectionConfirmed="onHistoryOpenShiftFilterChanged(header.value, $event)"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            class="ml-n4 subtitle-2 text-capitalize"
                            color="secondary"
                            text
                            v-on="on"
                          >
                            {{ $tc('labels.department', 1) }}
                            <v-icon
                              v-if="historyOpenShiftsOptions.filterBy.departmentId"
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fas fa-filter
                            </v-icon>
                            <v-icon
                              v-else
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fal fa-filter
                            </v-icon>
                          </v-btn>
                        </template>
                      </MultiSelectionList>
                    </template>
                    <template #header.jobTypes="{ header }">
                      <MultiSelectionList
                        :selection="jobTypes"
                        @selectionConfirmed="onHistoryOpenShiftFilterChanged(header.value, $event)"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            class="ml-n4 subtitle-2 text-capitalize"
                            color="secondary"
                            text
                            v-on="on"
                          >
                            {{ $tc('labels.jobType', 2) }}
                            <v-icon
                              v-if="historyOpenShiftsOptions.filterBy.jobTypes"
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fas fa-filter
                            </v-icon>
                            <v-icon
                              v-else
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fal fa-filter
                            </v-icon>
                          </v-btn>
                        </template>
                      </MultiSelectionList>
                    </template>
                    <template #header.typeId="{ header }">
                      <MultiSelectionList
                        :selection="shiftTypes"
                        @selectionConfirmed="onHistoryOpenShiftFilterChanged(header.value, $event)"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            class="ml-n4 subtitle-2 text-capitalize"
                            color="secondary"
                            text
                            v-on="on"
                          >
                            {{ $t('labels.shiftType') }}
                            <v-icon
                              v-if="historyOpenShiftsOptions.filterBy.typeId"
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fas fa-filter
                            </v-icon>
                            <v-icon
                              v-else
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fal fa-filter
                            </v-icon>
                          </v-btn>
                        </template>
                      </MultiSelectionList>
                    </template>
                    <template #header.fcfs="{ header }">
                      <MultiSelectionList
                        :selection="fcfs"
                        @selectionConfirmed="onHistoryOpenShiftFilterChanged(header.value, $event)"
                      >
                        <template #activator="{ on }">
                          <v-btn
                            class="ml-n4 subtitle-2 text-uppercase"
                            color="secondary"
                            text
                            v-on="on"
                          >
                            {{ $t('labels.fcfs') }}
                            <v-icon
                              v-if="historyOpenShiftsOptions.filterBy.fcfs"
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fas fa-filter
                            </v-icon>
                            <v-icon
                              v-else
                              class="ml-2 mt-1"
                              color="grey"
                              right
                              x-small
                            >
                              fal fa-filter
                            </v-icon>
                          </v-btn>
                        </template>
                      </MultiSelectionList>
                    </template>
                    <template #item.departmentId="{ item }">
                      <div
                        class="text-truncate type"
                        style="width: 116px;"
                        :title="getDepartment(item)"
                      >
                        {{ getDepartment(item) }}
                      </div>
                    </template>
                    <template #item.jobTypes="{ item }">
                      <div
                        class="text-truncate type"
                        style="width: 116px;"
                        :title="getJobType(item)"
                      >
                        {{ getJobType(item) }}
                      </div>
                    </template>
                    <template #item.bids="{ item }">
                      <div
                        class="text-truncate type"
                      >
                        {{ getSelectedBids(item) }}
                      </div>
                    </template>
                    <template #item.typeId="{ item }">
                      <div
                        class="text-truncate type"
                        style="width: 116px;"
                        :title="getShiftType(item)"
                      >
                        {{ getShiftType(item) }}
                      </div>
                    </template>
                    <template #item.fcfs="{ item }">
                      {{ item.fcfs ? $t('labels.yes') : $t('labels.no') }}
                    </template>
                    <template #item.date="{ item }">
                      {{ formatDate(item.date) }}
                    </template>
                    <template #item.createdOn="{ item }">
                      {{ formatDateTime(item.createdOn) }}
                    </template>
                    <template #no-data>
                      <div class="body-2 grey--text mt-5 no-results text--darken-3 text-left">
                        <div class="my-2 subtitle-2">
                          {{ $t('descriptions.noResultsFound') }}
                        </div>
                        <v-divider />
                        <div class="mb-2 mt-4">
                          {{ $t('descriptions.emptyGridHelpTitle') }}
                        </div>
                        <div class="my-2">
                          <ul>
                            <li>{{ $t('descriptions.emptyGridHelpRemoveFilters') }}</li>
                            <li>{{ $t('descriptions.emptyGridHelpShorterWords') }}</li>
                            <li>{{ $t('descriptions.emptyGridHelpSpelling') }}</li>
                          </ul>
                        </div>
                      </div>
                    </template>
                    <template #footer>
                      <template v-if="$vuetify.breakpoint.smAndDown">
                        <div class="mobile pa-1 pagination text-center">
                          <v-btn
                            :disabled="retrievingHistoryOpenShifts || historyOpenShiftsCurrentPage === 1"
                            icon
                            @click="paginateHistoryOpenShifts(-1)"
                          >
                            <v-icon small>
                              far fa-chevron-left
                            </v-icon>
                          </v-btn>
                          <span class="body-2 mx-1">
                            {{ historyOpenShiftsCurrentPage }}
                          </span>
                          <span class="body-2 grey--text text--darken-2">/</span>
                          <span class="body-2 mx-1 grey--text text--darken-2">
                            {{ $store.state.openshift.historyOpenShifts.pages }}
                          </span>
                          <v-btn
                            :disabled="retrievingHistoryOpenShifts || historyOpenShiftsCurrentPage === $store.state.openshift.historyOpenShifts.pages"
                            icon
                            @click="paginateHistoryOpenShifts(1)"
                          >
                            <v-icon small>
                              far fa-chevron-right
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <v-pagination
                        v-else
                        v-model="historyOpenShiftsCurrentPage"
                        class="ma-1"
                        color="secondary"
                        :disabled="retrievingHistoryOpenShifts"
                        :length="$store.state.openshift.historyOpenShifts.pages"
                        next-icon="far fa-chevron-right"
                        prev-icon="far fa-chevron-left"
                        :total-visible="maxPaginationControls"
                      />
                    </template>
                  </v-data-table>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
            <v-spacer />
            <v-tooltip
              v-if="$can('edit', 'openShift')"
              left
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="grey lighten-2"
                  icon
                  v-bind="attrs"
                  v-on="{ ...on, click: showOpenShiftDialog }"
                >
                  <v-icon
                    size="16"
                  >
                    fal fa-plus
                  </v-icon>
                </v-btn>
              </template>
              {{ $t('labels.createOpenShift') }}
            </v-tooltip>
          </v-tabs>
        </v-row>
      </v-container>
    </template>
    <SidePanel
      :panels="panels"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import MultiSelectionList from '@/components/MultiSelectionList';
import SidePanel from '@/components/SidePanel';
import CreateOpenShift from '@/views/scheduling/open_shifts/Create';
import { showStatus } from '@/plugins/vue-notification';
import { CONTENT_TYPES } from '@/services/constants';
import { RequestCanceledError } from '@/services/errors';
import Resize from 'vuetify/es5/directives/resize';

export default {
  directives: {
    Resize
  },
  components: {
    MultiSelectionList,
    SidePanel
  },
  data () {
    return {
      bidsFilterOpen: false,
      debouncingWsOpenShift: false,
      endsIn: {},
      endsInInterval: null,
      historyOpenShiftsHeaders: [
        { sortable: false, text: this.$tc('labels.department', 1), value: 'departmentId', width: 150 },
        { sortable: false, text: this.$tc('labels.jobType', 1), value: 'jobTypes', width: 150 },
        { sortable: true, text: this.$t('labels.need'), value: 'opening', width: 85 },
        { sortable: false, text: this.$t('labels.selectedBids'), value: 'bids', width: 85 },
        { sortable: false, text: this.$t('labels.shiftType'), value: 'typeId', width: 150 },
        { sortable: true, text: this.$t('labels.shiftDate'), value: 'date', width: 150 },
        { sortable: false, text: this.$t('labels.fcfs'), value: 'fcfs', width: 150 },
        { sortable: true, text: this.$t('labels.createdOn'), value: 'createdOn', width: 150 }
      ],
      historyOpenShiftsOptions: {
        filterBy: {},
        page: 1,
        sortBy: [ 'date' ],
        sortDesc: [ true ]
      },
      maxPaginationControls: 7,
      nameFilter: '',
      panels: [],
      pendingBidsOnlyFilter: false,
      pendingOpenShiftsHeaders: [
        { sortable: false, text: this.$tc('labels.department', 1), value: 'departmentId', width: 150 },
        { sortable: false, text: this.$tc('labels.jobType', 1), value: 'jobTypes', width: 150 },
        { sortable: true, text: this.$t('labels.need'), value: 'opening', width: 85 },
        { sortable: false, text: this.$t('labels.selectedBids'), value: 'bids', width: 85 },
        { sortable: false, text: this.$t('labels.shiftType'), value: 'typeId', width: 150 },
        { sortable: true, text: this.$t('labels.shiftDate'), value: 'date', width: 150 },
        { sortable: false, text: this.$t('labels.fcfs'), value: 'fcfs', width: 150 },
        { sortable: true, text: this.$t('labels.biddingEndsIn'), value: 'endsIn', width: 200 },
        { sortable: true, text: this.$t('labels.createdOn'), value: 'createdOn', width: 150 }
      ],
      pendingOpenShiftsOptions: {
        filterBy: {},
        page: 1,
        sortBy: [ 'endsIn' ],
        sortDesc: [ false ]
      },
      retrievingHistoryOpenShifts: false,
      retrievingPendingOpenShifts: false,
      selectedRows: []
    };
  },
  computed: {
    // There are certain Vuetify Data Table properties that we'd like to watch together. However Vuejs 2.x
    // doesn't support watching multiple properties for a single callback. All computed properties below
    // with 'Watchable' suffix are created as a workaround to this limitation.
    ...mapGetters('openshift', [
      'historyOpenShiftCount', 'pendingOpenShiftCount', 'pendingOpenShiftTotalCount'
    ]),
    departments () {
      return this.$store.state.org.departments.map(department => {
        return { label: department.name, value: department.id };
      });
    },
    departmentsById () {
      return this.departments.reduce((departments, d) => {
        departments[d.value] = d;
        return departments;
      }, {});
    },
    fcfs () {
      return [
        {
          label: this.$t('labels.no'),
          value: 'false'
        },
        {
          label: this.$t('labels.yes'),
          value: 'true'
        }
      ];
    },
    historyOpenShiftsCurrentPage: {
      get () {
        return this.$store.state.openshift.historyOpenShifts.currentPage;
      },
      set (value) {
        this.$store.commit('openshift/set_current_page', value);
      }
    },
    historyOpenShiftsFiltered () {
      let isFiltered = false;
      isFiltered |= !!this.historyOpenShiftsOptions.searchByName;
      isFiltered |= !_.isEmpty(this.historyOpenShiftsOptions.filterBy);
      return !!isFiltered;
    },
    historyOpenShiftsOrderingWatchable () {
      return this.historyOpenShiftsOptions.sortBy.reduce((orderingCriteria, value, idx) => {
        orderingCriteria[value] = this.historyOpenShiftsOptions.sortDesc[idx];
        return orderingCriteria;
      }, {});
    },
    historyOpenShiftsTabLabel () {
      if (this.historyOpenShiftsFiltered && !this.debouncingWsOpenShift && !this.retrievingHistoryOpenShifts) {
        return this.$t('labels.history') + ' (' + this.historyOpenShiftCount + ')';
      } else {
        return this.$t('labels.history');
      }
    },
    jobTypes () {
      return this.$store.state.org.jobTypes.map(jobType => {
        return { label: jobType.name, value: jobType.id };
      });
    },
    jobTypesById () {
      return this.jobTypes.reduce((jobType, jt) => {
        jobType[jt.value] = jt;
        return jobType;
      }, {});
    },
    pendingOpenShiftsCurrentPage: {
      get () {
        return this.$store.state.openshift.pendingOpenShifts.currentPage;
      },
      set (value) {
        this.$store.commit('openshift/set_current_page', value);
      }
    },
    pendingOpenShiftsFiltered () {
      let isFiltered = false;
      isFiltered |= !!this.pendingOpenShiftsOptions.searchByName;
      isFiltered |= !_.isEmpty(this.pendingOpenShiftsOptions.filterBy);
      return !!isFiltered;
    },
    pendingOpenShiftsOrderingWatchable () {
      return this.pendingOpenShiftsOptions.sortBy.reduce((orderingCriteria, value, idx) => {
        orderingCriteria[value] = this.pendingOpenShiftsOptions.sortDesc[idx];
        return orderingCriteria;
      }, {});
    },
    pendingOpenShiftsTabLabel () {
      if (this.pendingOpenShiftsFiltered && !this.debouncingWsOpenShift && !this.retrievingPendingOpenShifts) {
        return this.$t('labels.pending') + ' (' + this.pendingOpenShiftCount + ')';
      } else {
        return this.$t('labels.pending');
      }
    },
    selectedTab: {
      get () {
        return this.$store.state.openshift.activeList;
      },
      set (value) {
        this.$store.commit('openshift/set_active_list', value);
      }
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.map(shiftType => {
        return { label: shiftType.name, value: shiftType.id };
      });
    },
    shiftTypesById () {
      return this.shiftTypes.reduce((shiftType, st) => {
        shiftType[st.value] = st;
        return shiftType;
      }, {});
    },
    ...mapState(['sidePanelOpened'])
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.$store.getters['account/isOperationsManagement']()) {
        next('/page_not_found');
      }
    });
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('openshift/reset_state');
    this.$store.commit(
      'remove_ws_update_callback',
      'open_shift_page',
      { root: true }
    );
    next();
  },
  watch: {
    'historyOpenShiftsOptions.page': function () {
      // Page number is already updated in the store via setter function of the respective computed property.
      this.retrieveOpenShiftsForSelectedTab();
    },
    historyOpenShiftsOrderingWatchable (newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue)) { // See the note here: https://vuejs.org/v2/api/#vm-watch
        this.$store.commit('openshift/set_history_open_shifts_ordering', newValue);
        this.retrieveOpenShiftsForSelectedTab();
      }
    },
    'pendingOpenShiftsOptions.page': function () {
      // Page number is already updated in the store via setter function of the respective computed property.
      this.retrieveOpenShiftsForSelectedTab();
    },
    pendingOpenShiftsOrderingWatchable (newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue)) {
        this.$store.commit('openshift/set_pending_open_shifts_ordering', newValue);
        this.retrieveOpenShiftsForSelectedTab();
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.endsInInterval);
  },
  mounted () {
    this.calcPageSize();
    this.retrieveOpenShiftsForSelectedTab();
    setInterval(this.calcEndsIn, 60000);
    this.$store.commit(
      'add_ws_update_callback',
      { name: 'open_shift_page', callback: this.onWsUpdate },
      { root: true }
    );
  },
  methods: {
    calcEndsIn () {
      const calculate = (item) => {
        if (item.biddingClosed || item.biddingEnded) {
          return;
        }

        const due = moment.utc(item.biddingEndsOn).local();
        const minutes = due.diff(moment().startOf('minute'), 'minutes');
        const hours = Math.floor(minutes / 60);
        const duration = [];
        let fiveMinutesBeforeEnding = false;
        if (hours > 0) {
          duration.push(`${hours} h`);
          const remainder = minutes - (hours * 60);
          if (remainder > 0) {
            duration.push(`${remainder} m`);
          }
        } else {
          if (minutes <= 5) {
            fiveMinutesBeforeEnding = true;
          } else {
            duration.push(`${minutes} m`);
          }
        }

        if (fiveMinutesBeforeEnding) {
          return this.$t('labels.openShiftEndsSoonShort');
        } else {
          return duration.join(' ');
        }
      };
      for (let r of this.$store.state.openshift.pendingOpenShifts.records) {
        this.endsIn[r.id] = calculate(r);
      }
    },
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 48;
      const tableHeaderHeight = 55;
      const tdHeight = 63;
      const tableFooterHeight = 40;
      const bottomNavHeight = 56; // Mobile only
      const marginAndPadding = 50;
      let tbodyHeight;

      if (this.$vuetify.breakpoint.smAndDown) {
        tbodyHeight = window.innerHeight - (
          topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + bottomNavHeight + marginAndPadding
        );
      } else {
        tbodyHeight = window.innerHeight - (
          topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
        );
      }

      const pageSize = Math.floor(tbodyHeight / tdHeight);
      this.$store.commit('openshift/set_page_size', pageSize);
    },
    closePanels (start, count) {
      this.panels.splice(start, count);
    },
    closeAllPanels () {
      this.panels.splice(0, this.panels.length);
    },
    closeLastPanel () {
      this.panels.pop();
    },
    getEndsIn (item) {
      return this.endsIn[item.id] || '';
    },
    getDepartment (item) {
      return this.departmentsById[item.departmentId].label;
    },
    getJobType (item) {
      return item.jobTypes.map((id) => this.jobTypesById[id].label).join(', ');
    },
    getReviewerName (itemData) {
      return _.get(this.$store.state.org.employees, [itemData.reviewerId, 'fullName'], '');
    },
    getSelectedBids (item) {
      if (item.fcfs) {
        return `${item.bidders.length}/${item.bidders.length}`;
      } else {
        return `${item.selectedBidders.length}/${item.bidders.length}`;
      }
    },
    getShiftType (item) {
      return this.shiftTypesById[item.typeId].label;
    },
    formatDate (originalDate) {
      const dateTimeFormat = this.$store.getters['org/getDateFormatLong']();
      return moment(originalDate).format(dateTimeFormat);
    },
    formatDateTime (originalDateTime) {
      const dateTimeFormat = this.$store.getters['org/getDateTimeFormatLong']();
      return moment(originalDateTime).format(dateTimeFormat);
    },
    moment,
    onItemClicked (itemData) {
      if (this.sidePanelOpened && _.get(this.selectedRows, '0.id', null) === itemData.id) {
        return;
      }
      this.selectedRows = [itemData];
      this.$store.dispatch('showOpenShiftPanel', itemData.id, { root: true });
    },
    onHistoryOpenShiftFilterChanged (columnName, criteria) {
      if (criteria.length) {
        this.$set(this.historyOpenShiftsOptions.filterBy, columnName, criteria);
      } else {
        this.$delete(this.historyOpenShiftsOptions.filterBy, columnName);
      }

      this.$store.commit('openshift/set_filter', { [columnName]: criteria });
      this.retrieveOpenShiftsForSelectedTab();
    },
    onPendingOpenShiftFilterChangedInternal (columnName, value) {
      let internalValue = value;
      if (value) {
        this.$set(this.pendingOpenShiftsOptions.filterBy, columnName, value);
      } else {
        internalValue = null;
        this.$delete(this.pendingOpenShiftsOptions.filterBy, columnName);
      }

      this.$store.commit('openshift/set_filter', { [columnName]: internalValue });
      this.retrieveOpenShiftsForSelectedTab();
    },
    onPendingOpenShiftFilterChanged (columnName, criteria) {
      if (criteria.length) {
        this.$set(this.pendingOpenShiftsOptions.filterBy, columnName, criteria);
      } else {
        this.$delete(this.pendingOpenShiftsOptions.filterBy, columnName);
      }

      this.$store.commit('openshift/set_filter', { [columnName]: criteria });
      this.retrieveOpenShiftsForSelectedTab();
    },
    onOpenShiftUpdateReceived (event) {
      if (this.selectedTab === 'pending' && !this.retrievingPendingOpenShifts) {
        const data = JSON.parse(event.data);
        const id = _.get(data, 'id');
        const action = _.get(data, 'action');
        if (action === 'create' || this.$store.state.openshift.pendingOpenShifts.records.find(el => el.id === id)) {
          this.retrieveOpenShiftsForSelectedTab();
        }
      }
    },
    onWsUpdate (eventInfo) {
      const data = JSON.parse(eventInfo.data);
      const contentType = data.content_type;
      switch (contentType) {
        case CONTENT_TYPES.openShift:
          this.onOpenShiftUpdateReceived(eventInfo);
          break;
      }
    },
    onWindowResized () {
      this.retrieveOpenShiftsForSelectedTab();
      this.calcPageSize();
    },
    paginateHistoryOpenShifts (increment) {
      let newPageNumber = this.historyOpenShiftsCurrentPage + increment;
      if (newPageNumber >= 1 && newPageNumber <= this.$store.state.openshift.historyOpenShifts.pages) {
        this.historyOpenShiftsCurrentPage = newPageNumber;
      }
    },
    paginatePendingOpenShifts (increment) {
      let newPageNumber = this.pendingOpenShiftsCurrentPage + increment;
      if (newPageNumber >= 1 && newPageNumber <= this.$store.state.openshift.pendingOpenShifts.pages) {
        this.pendingOpenShiftsCurrentPage = newPageNumber;
      }
    },
    preparePanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      const closeCallback = _.get(panelData, ['events', 'close']);
      panelData.events.close = () => {
        if (closeCallback) {
          if (closeCallback()) {
            this.closeLastPanel();
          }
        } else {
          this.closeLastPanel();
        }
      };
      return panelData;
    },
    retrieveOpenShiftsForSelectedTab () {
      let action, errorMsgKey, flag;
      switch (this.selectedTab) {
        case 'history':
          action = 'openshift/retrieveHistoryOpenShifts';
          errorMsgKey = 'descriptions.openShiftRetrieveHistoryFail';
          flag = 'retrievingHistoryOpenShifts';
          break;
        case 'pending':
          action = 'openshift/retrievePendingOpenShifts';
          errorMsgKey = 'descriptions.openShiftRetrievePendingFail';
          flag = 'retrievingPendingOpenShifts';
          break;
      }

      this[flag] = true;
      this.$store.dispatch(action).then(response => {
        if (this.selectedTab === 'pending') {
          this.calcEndsIn();
        }
        // No need to do anything here. Retrieved open shifts are already saved in Vuex store.
      }).catch(error => {
        // Many non-dependent async operations on this page could trigger the retrieval of open shift data
        // (such as resizing window, receiving WebSocket notification, etc.). In theses cases is it okay
        // to ignore the error raised by WsProxy when it detected duplicate open shifts.
        if (!(error instanceof RequestCanceledError)) {
          const data = { error: _.get(error, 'response.data') };
          showStatus({ text: this.$t(errorMsgKey), type: 'error', data });
        }
      }).finally(() => {
        this[flag] = false;
      });
    },
    showOpenShiftDialog () {
      this.showPanel(() => {
        return {
          component: CreateOpenShift,
          props: {
            allowEditingDate: true,
            allowEditingDepartment: true
          },
          events: {
            created: (openShift) => {
              this.retrieveOpenShiftsForSelectedTab();
              this.closeLastPanel();
              this.$store.dispatch('showOpenShiftPanel', openShift.id, { root: true });
            }
          }
        };
      }, [], true);
    },
    showPanel (panelDataCallback, params, resetPanels) {
      if (resetPanels && this.panels.length > 0) {
        this.closePanels(1, this.panels.length);
        this.updatePanel(0, panelDataCallback, params);
      } else {
        const panelData = this.preparePanelData(panelDataCallback, params);
        panelData.id = _.uniqueId();
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.panels.push(_.cloneDeep(panelData));
      }
    },
    updatePanel (panelIdx, panelDataCallback, params) {
      const panel = this.panels[panelIdx];
      const panelData = this.preparePanelData(panelDataCallback, params);
      panelData.id = panel.id;
      panelData.panelDataCallback = panelDataCallback;
      panelData.params = params;
      this.panels.splice(panelIdx, 1, _.cloneDeep(panelData));
    }
  }
};
</script>

<style lang="scss">
.container.openshifts {
  height: 100%;

  > .container:not(.skeleton-loader) {
    height: 100%;

    > .row {
      height: 100%;

      .v-tabs {
        .v-tabs-bar {
          background-color: $page-background;

          .v-tab {
            width: 170px;
          }
        }

        .v-tabs-items {
          height: calc(100% - 24px);
          background: none;

          .v-window-item {
            height: 100%;

            .row.no-openshift  {
              background-color: white;
              height: calc(100% - 280px);
              min-height: 200px;

              .spacer {
                background-color: $page-background;
              }
            }

            .v-data-table {
              &.pending-openshifts {
                table {
                  min-width: 600px;
                }
              }

              &.history-openshifts {
                table {
                  min-width: 1000px;
                }
              }

              thead {
                .v-input.search-name {
                  font-size: 14px;
                }

                th {
                  font-weight: 500;
                }
              }

              tbody {
                tr {
                  height: 62px;
                }
                tr:last-child {
                  td {
                    border-bottom: thin solid #0000001f;
                  }
                }

                td {
                  .name-n-avatar {
                    max-width: 150px;
                  }

                  .name {
                    min-width: 110px;
                    max-width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;;
                    white-space: nowrap;
                  }

                  .type {
                    min-width: 70px;;
                  }
                }
              }

              .no-results {
                height: 250px;
              }

              .v-pagination {
                .v-pagination__item,
                .v-pagination__navigation {
                  font-size: 14px;
                  height: 30px;

                  &:focus {
                    outline: none;
                  }

                  .v-icon {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
