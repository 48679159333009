exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.schedule-filters > .v-card > .row {\n  margin-bottom: 8px;\n}\n.schedule-filters .inline .v-select__selections {\n  font-size: 14px;\n  display: inline-block;\n  overflow-y: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.schedule-filters .inline .v-select__selections input {\n  position: absolute;\n  left: 0px;\n  width: 10px;\n}\n.schedule-filters .primary-shift-types .v-text-field__details, .schedule-filters .shift-mode .v-text-field__details {\n  padding: 0px;\n}\n.schedule-filters .primary-shift-types .v-text-field__details .v-messages__message, .schedule-filters .shift-mode .v-text-field__details .v-messages__message {\n  color: #9e9e9e;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};