var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-0", attrs: { id: "nurseDetails" } },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-sliders-h")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("labels.staffDetails")) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-0", attrs: { cols: "12" } },
            [
              _c(
                "span",
                {
                  staticClass:
                    "grey--text text--darken-3 body-2 font-weight-medium mr-3"
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.latestUser.fullName) + "\n      "
                  )
                ]
              ),
              _c(
                "v-icon",
                { attrs: { color: _vm.userState.color, size: "8" } },
                [_vm._v("\n        fas fa-circle\n      ")]
              ),
              _c("span", { staticClass: "caption grey--text text--darken-2" }, [
                _vm._v("\n        " + _vm._s(_vm.userState.text) + "\n      ")
              ]),
              _vm.allowEditingProfile
                ? _c(
                    "v-btn",
                    {
                      staticClass: "float-right grey lighten-3",
                      attrs: { icon: "", small: "" },
                      on: { click: _vm.showUserDialog }
                    },
                    [
                      _c("v-icon", { attrs: { size: "16" } }, [
                        _vm._v("\n          fal fa-pencil\n        ")
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "px-0 mb-2", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { staticClass: "caption grey--text text--darken-1" },
            [
              _vm._l(_vm.userDetails, function(detail, idx) {
                return [
                  _c(
                    "div",
                    { key: "" + detail, staticClass: "d-inline-block" },
                    [_vm._v("\n          " + _vm._s(detail) + "\n        ")]
                  ),
                  idx < _vm.userDetails.length - 1
                    ? _c("v-divider", {
                        key: detail + "-div",
                        staticClass: "separator mx-2 d-inline pb-1",
                        attrs: { vertical: "" }
                      })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-list",
        {
          staticClass: "grey--text text--darken-3 caption pt-0",
          attrs: { dense: "" }
        },
        [
          _c(
            "v-list-item",
            { staticClass: "pa-0 user-contact-info v-list-item--active" },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "my-0 mr-0" },
                [
                  _c("v-icon", { attrs: { "x-small": "" } }, [
                    _vm._v("\n          fal fa-phone\n        ")
                  ])
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "pa-0" }, [
                _vm.latestUser.phonePersonal
                  ? _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("VMask")(
                              _vm.latestUser.phonePersonal,
                              _vm.phoneMask
                            )
                          ) +
                          "\n        "
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.noPhoneConfigured")) +
                          "\n        "
                      )
                    ])
              ])
            ],
            1
          ),
          _c(
            "v-list-item",
            { staticClass: "pa-0 user-contact-info v-list-item--active" },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "my-0 mr-0" },
                [
                  _c("v-icon", { attrs: { "x-small": "" } }, [
                    _vm._v("\n          fal fa-envelope\n        ")
                  ])
                ],
                1
              ),
              _c("v-list-item-content", { staticClass: "pa-0" }, [
                _c("a", { attrs: { href: "mailto:" + _vm.latestUser.email } }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.latestUser.email) + "\n        "
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "v-list-item",
            { staticClass: "pa-0 user-contact-info v-list-item--active" },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "my-0 mr-0" },
                [
                  _c("v-icon", { attrs: { "x-small": "" } }, [
                    _vm._v("\n          fal fa-clock\n        ")
                  ])
                ],
                1
              ),
              _c(
                "v-list-item-content",
                { staticClass: "pa-0" },
                [
                  _c("div", { staticClass: "d-inline-block" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("labels.hrsPerDay", {
                            hours: _vm.latestUser.dailyHours || 0
                          })
                        ) +
                        "\n        "
                    )
                  ]),
                  _c("v-divider", {
                    staticClass: "separator mx-2 d-inline",
                    attrs: { vertical: "" }
                  }),
                  _c("div", { staticClass: "d-inline-block" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("labels.hrsPerWeek", {
                            hours: _vm.latestUser.weeklyHours || 0
                          })
                        ) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.latestUser.charge
            ? _c(
                "v-list-item",
                { staticClass: "pa-0 user-contact-info v-list-item--active" },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "my-0 mr-0" },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("\n          fal fa-check\n        ")
                      ])
                    ],
                    1
                  ),
                  _c("v-list-item-content", { staticClass: "pa-0" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("labels.canActAsCharge")) +
                        "\n      "
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          staticClass: "dense",
          attrs: {
            id: "nurseDetailsContent",
            centered: "",
            grow: "",
            "hide-slider": ""
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab",
            { staticClass: "text-capitalize", attrs: { href: "#notes" } },
            [_vm._v("\n      " + _vm._s(_vm.$t("labels.notes")) + "\n    ")]
          ),
          _c(
            "v-tab",
            { staticClass: "text-capitalize", attrs: { href: "#schedule" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("labels.downloadSchedule")) +
                  "\n    "
              )
            ]
          ),
          _c(
            "v-tab-item",
            { style: _vm.tabItemStyle, attrs: { value: "notes" } },
            [
              _c("Comments", {
                staticClass: "py-2",
                attrs: {
                  "auto-grow": true,
                  counter: "1000",
                  "disclosure-hint": _vm.$t("descriptions.disclaimer"),
                  maxlength: "1000",
                  outlined: "",
                  placeholder: _vm.$t("labels.addNotesPlaceholder"),
                  rows: "1",
                  "single-line": "",
                  "visibility-hint": _vm.$t(
                    "descriptions.commentVisibilitySchedulers"
                  )
                },
                model: {
                  value: _vm.notes.new,
                  callback: function($$v) {
                    _vm.$set(_vm.notes, "new", $$v)
                  },
                  expression: "notes.new"
                }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "px-4 py-5 float-right save-notes",
                  attrs: {
                    color: "accent",
                    disabled:
                      _vm.savingNotes || _vm.notes.new === _vm.notes.previous,
                    width: "70"
                  },
                  on: { click: _vm.saveNotes }
                },
                [
                  _vm.savingNotes
                    ? _c("v-progress-circular", {
                        attrs: {
                          color: "primary lighten-2",
                          indeterminate: "",
                          size: "16",
                          width: "2"
                        }
                      })
                    : _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.save")) +
                            "\n        "
                        )
                      ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "schedule" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-4", attrs: { outlined: "" } },
                [
                  _c("span", { staticClass: "grey--text caption" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("labels.downloadNurseWeeklySchedule")) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "7" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "min-width": "250px",
                                "close-on-content-click": false,
                                "offset-y": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var value = ref.value
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "title font-weight-regular text-capitalize schedule-dropdown px-2",
                                            attrs: {
                                              elevation: "0",
                                              outlined: "",
                                              width: "100%"
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "body-2 font-weight-regular"
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.selectedScheduleText
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          ),
                                          _c("v-spacer"),
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "16" } },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    value
                                                      ? "fas fa-caret-up"
                                                      : "fas fa-caret-down"
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.schedule.showSchedulePicker,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.schedule,
                                    "showSchedulePicker",
                                    $$v
                                  )
                                },
                                expression: "schedule.showSchedulePicker"
                              }
                            },
                            [
                              _c("ScheduleSelection", {
                                attrs: {
                                  department: _vm.department,
                                  "selected-schedule-id":
                                    _vm.schedule.selected.id
                                },
                                on: {
                                  select: _vm.setSelectedSchedule,
                                  close: function($event) {
                                    _vm.schedule.showSchedulePicker = false
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-0", attrs: { cols: "5" } },
                        [
                          _c("v-switch", {
                            staticClass:
                              "pt-0 mt-2 d-inline-block custom-period-toggle",
                            attrs: {
                              color: "success",
                              dense: "",
                              "hide-details": "",
                              inset: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "body-2 grey--text text--darken-3",
                                        attrs: {
                                          title: _vm.$t("labels.periodCustom")
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("labels.periodCustom")
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.schedule.customPeriodEnabled,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.schedule,
                                  "customPeriodEnabled",
                                  $$v
                                )
                              },
                              expression: "schedule.customPeriodEnabled"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.schedule.customPeriodEnabled
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "customPeriodStart",
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "min-width": "auto",
                                    "offset-y": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      dense: "",
                                                      "hide-details": "",
                                                      label: _vm.$t(
                                                        "labels.startDate"
                                                      ),
                                                      outlined: "",
                                                      "append-icon":
                                                        "fal fa-calendar-alt",
                                                      readonly: "",
                                                      value: _vm.schedule
                                                        .customPeriod.start
                                                        ? _vm
                                                            .moment(
                                                              _vm.schedule
                                                                .customPeriod
                                                                .start
                                                            )
                                                            .format(
                                                              _vm.dateFormatLong
                                                            )
                                                        : ""
                                                    }
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    3612157654
                                  ),
                                  model: {
                                    value:
                                      _vm.schedule.customPeriod.showStartPicker,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.schedule.customPeriod,
                                        "showStartPicker",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "schedule.customPeriod.showStartPicker"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      min: _vm.schedule.selected.startOn,
                                      max: _vm.schedule.selected.endOn,
                                      "no-title": "",
                                      "picker-date":
                                        _vm.schedule.customPeriod
                                          .startPickerDate
                                    },
                                    on: {
                                      "update:pickerDate": function($event) {
                                        return _vm.$set(
                                          _vm.schedule.customPeriod,
                                          "startPickerDate",
                                          $event
                                        )
                                      },
                                      "update:picker-date": function($event) {
                                        return _vm.$set(
                                          _vm.schedule.customPeriod,
                                          "startPickerDate",
                                          $event
                                        )
                                      },
                                      change: function($event) {
                                        _vm.schedule.customPeriod.showStartPicker = false
                                      }
                                    },
                                    model: {
                                      value: _vm.schedule.customPeriod.start,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.schedule.customPeriod,
                                          "start",
                                          $$v
                                        )
                                      },
                                      expression: "schedule.customPeriod.start"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "customPeriodEnd",
                                  attrs: {
                                    "close-on-content-click": false,
                                    transition: "scale-transition",
                                    "offset-y": ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-text-field",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      dense: "",
                                                      "hide-details": "",
                                                      label: _vm.$t(
                                                        "labels.endDate"
                                                      ),
                                                      outlined: "",
                                                      "append-icon":
                                                        "fal fa-calendar-alt",
                                                      readonly: "",
                                                      value: _vm.schedule
                                                        .customPeriod.end
                                                        ? _vm
                                                            .moment(
                                                              _vm.schedule
                                                                .customPeriod
                                                                .end
                                                            )
                                                            .format(
                                                              _vm.dateFormatLong
                                                            )
                                                        : ""
                                                    }
                                                  },
                                                  "v-text-field",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              )
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    1181356985
                                  ),
                                  model: {
                                    value:
                                      _vm.schedule.customPeriod.showEndPicker,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.schedule.customPeriod,
                                        "showEndPicker",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "schedule.customPeriod.showEndPicker"
                                  }
                                },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      min: _vm.schedule.selected.startOn,
                                      max: _vm.schedule.selected.endOn,
                                      "no-title": "",
                                      "picker-date":
                                        _vm.schedule.customPeriod.endPickerDate
                                    },
                                    on: {
                                      "update:pickerDate": function($event) {
                                        return _vm.$set(
                                          _vm.schedule.customPeriod,
                                          "endPickerDate",
                                          $event
                                        )
                                      },
                                      "update:picker-date": function($event) {
                                        return _vm.$set(
                                          _vm.schedule.customPeriod,
                                          "endPickerDate",
                                          $event
                                        )
                                      },
                                      change: function($event) {
                                        _vm.schedule.customPeriod.showEndPicker = false
                                      }
                                    },
                                    model: {
                                      value: _vm.schedule.customPeriod.end,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.schedule.customPeriod,
                                          "end",
                                          $$v
                                        )
                                      },
                                      expression: "schedule.customPeriod.end"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                staticClass: "weeks-picker subtitle-2",
                                attrs: {
                                  chips: "",
                                  dense: "",
                                  "hide-details": "",
                                  items: _vm.weeks,
                                  label: _vm.$t("labels.weeks"),
                                  "menu-props": { top: false, offsetY: true },
                                  multiple: "",
                                  outlined: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function(data) {
                                      return [
                                        _c(
                                          "v-chip",
                                          _vm._b(
                                            {
                                              key: data.item.shortText,
                                              staticClass:
                                                "my-1 font-weight-regular",
                                              attrs: {
                                                color: "grey lighten-3",
                                                small: ""
                                              }
                                            },
                                            "v-chip",
                                            data.attrs,
                                            false
                                          ),
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(data.item.shortText) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.schedule.periods,
                                  callback: function($$v) {
                                    _vm.$set(_vm.schedule, "periods", $$v)
                                  },
                                  expression: "schedule.periods"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                  _c(
                    "v-row",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "px-4 py-5 float-right",
                              attrs: {
                                color: "secondary",
                                disabled: _vm.disabledScheduleDownload,
                                width: "144"
                              },
                              on: { click: _vm.downloadSchedule }
                            },
                            [
                              _vm.schedule.downloading
                                ? _c("v-progress-circular", {
                                    attrs: {
                                      color: "primary lighten-2",
                                      indeterminate: "",
                                      size: "16",
                                      width: "2"
                                    }
                                  })
                                : _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("labels.downloadPDF")) +
                                        "\n              "
                                    )
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }