exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.activity-selection .shift-types {\n  display: inline-block;\n}\n.activity-selection .shift-types button.shift-type {\n  background-color: #E9F7F9 !important;\n  border: none !important;\n  border-radius: 4px !important;\n  color: #25ADBF !important;\n  height: 40px !important;\n  width: 350px !important;\n}\n.activity-selection .shift-types button.shift-type.e {\n  width: 170px !important;\n}\n.activity-selection .shift-types button.shift-type.o {\n  width: 110px !important;\n}\n.activity-selection .shift-types button.shift-type.v-btn--active {\n  background-color: #25ADBF !important;\n  color: #FFF;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};