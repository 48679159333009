var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "daily-schedule", attrs: { fluid: "" } },
    [
      _vm.retrievingSchedule && _vm.showSkeletonLoader
        ? [
            _c(
              "v-container",
              { staticClass: "schedule", attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _vm._l(2, function(n) {
                          return _c(
                            "v-col",
                            { key: n, attrs: { cols: "auto" } },
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "single-action",
                                attrs: { type: "actions" }
                              })
                            ],
                            1
                          )
                        })
                      : _vm._l(5, function(n) {
                          return _c(
                            "v-col",
                            { key: n, attrs: { cols: "auto" } },
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "single-action",
                                attrs: { type: "actions" }
                              })
                            ],
                            1
                          )
                        }),
                    _c("v-spacer")
                  ],
                  2
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "no-action",
                          attrs: { type: "table" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : !_vm.retrievingSchedule
        ? [
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "portal",
                  { attrs: { to: "page-title" } },
                  [
                    (_vm.$vuetify.breakpoint.mdAndDown ||
                      _vm.showFinalizeButton) &&
                    !_vm.$vuetify.breakpoint.xl
                      ? [
                          _c(
                            "v-container",
                            { staticClass: "schedule-header py-0 mx-0" },
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "space-between" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-1",
                                      attrs: {
                                        "align-self": "center",
                                        cols: "auto"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass:
                                          "daily-schedule-picker subtitle-2",
                                        style: _vm.selectorsStyle,
                                        attrs: {
                                          dense: "",
                                          "hide-details": "",
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.dailyScheduleTypes,
                                          "menu-props": {
                                            top: false,
                                            offsetY: true
                                          },
                                          outlined: "",
                                          "return-object": ""
                                        },
                                        on: {
                                          change: _vm.onDailyScheduleTypeChange
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "item",
                                              fn: function(ref) {
                                                var attrs = ref.attrs
                                                var item = ref.item
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-list-item",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "daily-schedule-item"
                                                        },
                                                        "v-list-item",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    item.name
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3194219055
                                        ),
                                        model: {
                                          value: _vm.activeDailyScheduleType,
                                          callback: function($$v) {
                                            _vm.activeDailyScheduleType = $$v
                                          },
                                          expression: "activeDailyScheduleType"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-0",
                                      attrs: {
                                        "align-self": "center",
                                        cols: "auto"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("DepartmentSelector", {
                                            style: _vm.selectorsStyle
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.width <=
                                  _vm.ELLIPSIS_BREAKPOINT
                                    ? [
                                        _c("v-spacer"),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              "align-self": "center",
                                              cols: "auto"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                attrs: {
                                                  "min-width": "200",
                                                  "offset-y": true
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mx-1 account-icon",
                                                                attrs: {
                                                                  icon: "",
                                                                  small: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "\n                          fal fa-ellipsis-v\n                        "
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1407316930
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  [
                                                    _vm.showFinalizeButton
                                                      ? _c(
                                                          "v-list-item",
                                                          {
                                                            attrs: {
                                                              title: _vm.$t(
                                                                "labels.finalize"
                                                              )
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.finalizeScheduleChanges.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-icon",
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      small: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            fas fa-paper-plane\n                          "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "labels.finalize"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            _vm.shareDialogOpened = true
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-icon",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            fas fa-share-alt\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.share"
                                                                    )
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item",
                                                      {
                                                        on: {
                                                          click:
                                                            _vm.toggleHelpPanel
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            fal fa-info-circle\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.information"
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              "align-self": "center",
                                              cols: "auto"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              {
                                                staticClass: "ml-1",
                                                attrs: { align: "center" }
                                              },
                                              [
                                                !_vm.readOnly
                                                  ? [
                                                      _vm.showFinalizeButton
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                bottom: "",
                                                                "nudge-top":
                                                                  "10"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var tooltipOn =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "finalize mr-2",
                                                                                attrs: {
                                                                                  color:
                                                                                    "secondary",
                                                                                  disabled:
                                                                                    _vm.finalizing,
                                                                                  icon:
                                                                                    "",
                                                                                  title: _vm.$t(
                                                                                    "labels.finalize"
                                                                                  )
                                                                                }
                                                                              },
                                                                              "v-btn",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            Object.assign(
                                                                              {},
                                                                              tooltipOn,
                                                                              {
                                                                                click:
                                                                                  _vm.finalizeScheduleChanges
                                                                              }
                                                                            )
                                                                          ),
                                                                          [
                                                                            _vm.finalizing
                                                                              ? _c(
                                                                                  "v-progress-circular",
                                                                                  {
                                                                                    attrs: {
                                                                                      color:
                                                                                        "primary lighten-2",
                                                                                      indeterminate:
                                                                                        "",
                                                                                      size:
                                                                                        "22",
                                                                                      width:
                                                                                        "2"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              : _c(
                                                                                  "v-icon",
                                                                                  {
                                                                                    attrs: {
                                                                                      size:
                                                                                        "20"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                              fas fa-paper-plane\n                            "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                2805860054
                                                              )
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "body-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "labels.finalize"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm.showFinalizeMessage
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "caption font-weight-medium"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$vuetify
                                                                      .breakpoint
                                                                      .lgAndDown
                                                                      ? ""
                                                                      : _vm.$t(
                                                                          "descriptions.dailyScheduleFinalized"
                                                                        )
                                                                  ) +
                                                                  "\n                        "
                                                              ),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    "max-width":
                                                                      "320px",
                                                                    bottom: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "activator",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var on =
                                                                            ref.on
                                                                          var attrs =
                                                                            ref.attrs
                                                                          return [
                                                                            _c(
                                                                              "v-icon",
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticClass:
                                                                                      "mt-n1 ml-1",
                                                                                    attrs: {
                                                                                      color:
                                                                                        "info",
                                                                                      size:
                                                                                        "14"
                                                                                    }
                                                                                  },
                                                                                  "v-icon",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              fal fa-question-circle\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    873761178
                                                                  )
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "descriptions.dailyScheduleFinalizedHint"
                                                                          )
                                                                        ) +
                                                                        "\n                          "
                                                                    )
                                                                  ])
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  : _vm._e(),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: {
                                                      bottom: "",
                                                      "nudge-top": "10"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var tooltipOn =
                                                              ref.on
                                                            var attrs =
                                                              ref.attrs
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticClass:
                                                                        "mr-2",
                                                                      attrs: {
                                                                        color:
                                                                          "secondary",
                                                                        icon:
                                                                          "",
                                                                        title: _vm.$t(
                                                                          "labels.share"
                                                                        )
                                                                      }
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  Object.assign(
                                                                    {},
                                                                    tooltipOn,
                                                                    {
                                                                      click: function() {
                                                                        return (_vm.shareDialogOpened = true)
                                                                      }
                                                                    }
                                                                  )
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        size:
                                                                          "20"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fas fa-share-alt\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      627642870
                                                    )
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-2" },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.share"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-spacer")
                                      ],
                                  _vm.scheduleStateIndicator &&
                                  _vm.$vuetify.breakpoint.lgAndUp
                                    ? [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              "align-self": "center",
                                              cols: "auto"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                class: [
                                                  "font-weight-medium white--text",
                                                  _vm.scheduleStateIndicator
                                                    .stateLabelCssClass
                                                ],
                                                attrs: { small: "" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        _vm
                                                          .scheduleStateIndicator
                                                          .stateLabelKey
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c("v-spacer")
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ]
                      : [
                          _c(
                            "v-row",
                            {
                              staticClass: "schedule-header",
                              attrs: { justify: "space-between" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    "align-self": "center",
                                    cols: "auto"
                                  }
                                },
                                [
                                  _c("v-select", {
                                    staticClass:
                                      "daily-schedule-picker subtitle-2",
                                    staticStyle: { "max-width": "200px" },
                                    attrs: {
                                      dense: "",
                                      "hide-details": "",
                                      "item-text": "name",
                                      "item-value": "id",
                                      items: _vm.dailyScheduleTypes,
                                      "menu-props": {
                                        top: false,
                                        offsetY: true
                                      },
                                      outlined: "",
                                      "return-object": ""
                                    },
                                    on: {
                                      change: _vm.onDailyScheduleTypeChange
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function(ref) {
                                          var attrs = ref.attrs
                                          var item = ref.item
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-list-item",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "daily-schedule-item"
                                                  },
                                                  "v-list-item",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(item.name) +
                                                          "\n                      "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.activeDailyScheduleType,
                                      callback: function($$v) {
                                        _vm.activeDailyScheduleType = $$v
                                      },
                                      expression: "activeDailyScheduleType"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    "align-self": "center",
                                    cols: "auto"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { align: "center" }
                                    },
                                    [_c("DepartmentSelector")],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    "align-self": "center",
                                    cols: "auto"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      !_vm.readOnly
                                        ? [
                                            _vm.showFinalizeButton
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "finalize mr-2",
                                                    attrs: {
                                                      color: "secondary",
                                                      disabled: _vm.finalizing,
                                                      text:
                                                        _vm.$vuetify.breakpoint
                                                          .lgAndUp,
                                                      icon:
                                                        _vm.$vuetify.breakpoint
                                                          .mdAndDown,
                                                      title: _vm.$t(
                                                        "labels.finalize"
                                                      )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.finalizeScheduleChanges.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm.finalizing
                                                      ? _c(
                                                          "v-progress-circular",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "primary lighten-2",
                                                              indeterminate: "",
                                                              size: "22",
                                                              width: "2"
                                                            }
                                                          }
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              size: "20"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      fas fa-paper-plane\n                    "
                                                            )
                                                          ]
                                                        ),
                                                    _vm.$vuetify.breakpoint
                                                      .lgAndUp
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass: "ml-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.finalize"
                                                                  )
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _vm.showFinalizeMessage
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption font-weight-medium"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$vuetify
                                                            .breakpoint
                                                            .lgAndDown
                                                            ? ""
                                                            : _vm.$t(
                                                                "descriptions.dailyScheduleFinalized"
                                                              )
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          "max-width": "320px",
                                                          bottom: ""
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "mt-n1",
                                                                          attrs: {
                                                                            color:
                                                                              "info",
                                                                            size:
                                                                              "14"
                                                                          }
                                                                        },
                                                                        "v-icon",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          fal fa-question-circle\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          3565160615
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "descriptions.dailyScheduleFinalizedHint"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            color: "secondary",
                                            text:
                                              _vm.$vuetify.breakpoint.lgAndUp,
                                            icon:
                                              _vm.$vuetify.breakpoint.mdAndDown,
                                            title: _vm.$t("labels.share")
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              _vm.shareDialogOpened = true
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "20" } },
                                            [
                                              _vm._v(
                                                "\n                    fas fa-share-alt\n                  "
                                              )
                                            ]
                                          ),
                                          _vm.$vuetify.breakpoint.lgAndUp
                                            ? _c(
                                                "span",
                                                { staticClass: "ml-2" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t("labels.share")
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _vm.scheduleStateIndicator
                                ? [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          "align-self": "center",
                                          cols: "auto"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "ml-1",
                                            attrs: { align: "center" }
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                class: [
                                                  "font-weight-medium ml-5 white--text",
                                                  _vm.scheduleStateIndicator
                                                    .stateLabelCssClass
                                                ],
                                                attrs: { small: "" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        _vm
                                                          .scheduleStateIndicator
                                                          .stateLabelKey
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm.readOnly
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: [
                                                      "caption font-weight-medium ml-3 grey--text text--darken-1"
                                                    ]
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.readOnlyMode"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-spacer")
                                  ]
                                : _vm._e(),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pr-0",
                                  attrs: {
                                    "align-self": "center",
                                    cols: "auto"
                                  }
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", "nudge-top": "10" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var tooltipOn = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "mx-1",
                                                      attrs: { icon: "" }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  Object.assign({}, tooltipOn, {
                                                    click: _vm.toggleHelpPanel
                                                  })
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      fal fa-info-circle\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", { staticClass: "body-2" }, [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("labels.information")
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ]
                  ],
                  2
                ),
                _c(
                  "v-container",
                  {
                    staticClass: "body-container actions",
                    attrs: { fluid: "" }
                  },
                  [
                    _c(
                      "v-row",
                      {
                        style: [
                          _vm.$vuetify.breakpoint.smAndDown
                            ? { "text-align": "center" }
                            : {}
                        ],
                        attrs: { align: "center", "no-gutters": "" }
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass:
                              "subtitle-2 font-weight-medium primary--text",
                            attrs: { cols: "12", sm: "6", md: "4" }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm
                                      .moment(_vm.date)
                                      .format(_vm.dateFormatString)
                                  ) +
                                  "\n            "
                              )
                            ]),
                            _c(
                              "v-btn",
                              {
                                staticClass: "grey--text text--darken-3 mb-1",
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.loadNewContent(_vm.previousDay)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "primary", size: "16" } },
                                  [
                                    _vm._v(
                                      "\n                far fa-chevron-left\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-menu",
                              {
                                ref: "datePicker",
                                attrs: {
                                  "close-on-content-click": false,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "max-width": "290px",
                                  "min-width": "290px"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "primary--text mb-1",
                                                attrs: { icon: "" }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "gray",
                                                  size: "18"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    far fa-calendar-day\n                  "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.showDatePicker,
                                  callback: function($$v) {
                                    _vm.showDatePicker = $$v
                                  },
                                  expression: "showDatePicker"
                                }
                              },
                              [
                                _c(
                                  "v-date-picker",
                                  {
                                    attrs: { value: _vm.date },
                                    on: {
                                      change: function(newDate) {
                                        return _vm.loadNewContent(_vm.setDate, [
                                          newDate
                                        ])
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.date === _vm.today,
                                          outlined: "",
                                          small: ""
                                        },
                                        on: { click: _vm.goToToday }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.$t("labels.today")) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "grey--text text--darken-3 mb-1",
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.loadNewContent(_vm.nextDay)
                                  }
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "primary", size: "16" } },
                                  [
                                    _vm._v(
                                      "\n                far fa-chevron-right\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-col",
                          { attrs: { cols: "12", sm: "6", md: "8" } },
                          [
                            _c(
                              "div",
                              {
                                class: [
                                  "actions",
                                  _vm.$vuetify.breakpoint.smAndDown
                                    ? ""
                                    : "float-right"
                                ]
                              },
                              [
                                _vm.$can("edit", "shift") ||
                                _vm.$can("edit", "event")
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-menu",
                                                    {
                                                      attrs: {
                                                        "min-width": "150",
                                                        "offset-y": ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var menuOn =
                                                                ref.on
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        class:
                                                                          _vm
                                                                            .actionStyles
                                                                            .addActivity
                                                                            .button
                                                                            .classes,
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          value:
                                                                            "staff"
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    Object.assign(
                                                                      {},
                                                                      on,
                                                                      menuOn
                                                                    )
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        class:
                                                                          _vm
                                                                            .actionStyles
                                                                            .addActivity
                                                                            .icon
                                                                            .classes,
                                                                        attrs: {
                                                                          size:
                                                                            "16"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          fal fa-plus\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "v-list",
                                                        {
                                                          staticClass: "py-0",
                                                          attrs: { dense: "" }
                                                        },
                                                        [
                                                          _vm.$can(
                                                            "edit",
                                                            "shift"
                                                          )
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.setPanelName(
                                                                        "addShift"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "body-2 font-weight-regular"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm.$tc(
                                                                              "labels.addShift",
                                                                              1
                                                                            )
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm.$can(
                                                            "edit",
                                                            "event"
                                                          )
                                                            ? _c(
                                                                "v-list-item",
                                                                {
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.setPanelName(
                                                                        "addEvent"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "body-2 font-weight-regular"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            _vm.$tc(
                                                                              "labels.addEvent",
                                                                              1
                                                                            )
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3224784664
                                        )
                                      },
                                      [
                                        _c("span", { staticClass: "body-2" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.addActivity")
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.$can("view", "openShift")
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      staticClass:
                                                        "badge-panel count",
                                                      attrs: {
                                                        avatar: "",
                                                        color: "error",
                                                        content:
                                                          _vm.openShiftCount,
                                                        overlap: "",
                                                        value:
                                                          _vm.openShiftCount > 0
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              class:
                                                                _vm.actionStyles
                                                                  .viewOpenShift
                                                                  .button
                                                                  .classes,
                                                              attrs: {
                                                                icon: "",
                                                                value:
                                                                  "viewOpenShift"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.setPanelName(
                                                                    "viewOpenShift"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              class:
                                                                _vm.actionStyles
                                                                  .viewOpenShift
                                                                  .icon.classes,
                                                              attrs: {
                                                                size: "16"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        fal fa-list\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1096775995
                                        )
                                      },
                                      [
                                        _c("span", { staticClass: "body-2" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t("labels.viewOpenShift")
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-badge",
                                              {
                                                staticClass: "badge-panel",
                                                attrs: {
                                                  avatar: "",
                                                  color: "transparent",
                                                  overlap: ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "badge",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              class: [
                                                                "status",
                                                                _vm.hasCensusErrors
                                                                  ? ""
                                                                  : "no-errors"
                                                              ],
                                                              attrs: {
                                                                color: "error",
                                                                size: "12"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        fas fa-exclamation-triangle\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        class:
                                                          _vm.actionStyles
                                                            .census.button
                                                            .classes,
                                                        attrs: {
                                                          icon: "",
                                                          value: "census"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.setPanelName(
                                                              "census"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        class:
                                                          _vm.actionStyles
                                                            .census.icon
                                                            .classes,
                                                        attrs: { size: "16" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        fal fa-cloud-upload-alt\n                      "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("labels.inputCensus")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    class:
                                                      _vm.actionStyles.memos
                                                        .button.classes,
                                                    attrs: {
                                                      icon: "",
                                                      value: "memos"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.setPanelName(
                                                          "memos"
                                                        )
                                                      }
                                                    }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      _vm.actionStyles.memos
                                                        .icon.classes,
                                                    attrs: { size: "16" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      fal fa-file-alt\n                    "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("labels.notes")) +
                                          "\n                "
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-container",
                  { staticClass: "body-container pa-0", attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "", "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tabs",
                              {
                                staticClass: "job-type-tabs",
                                attrs: {
                                  "mobile-breakpoint": "768",
                                  "show-arrows": "",
                                  "slider-color": "accent",
                                  "slider-size": "3"
                                },
                                model: {
                                  value: _vm.selectedJobTypeTab,
                                  callback: function($$v) {
                                    _vm.selectedJobTypeTab = $$v
                                  },
                                  expression: "selectedJobTypeTab"
                                }
                              },
                              _vm._l(_vm.jobTypes, function(
                                jobTypeGroup,
                                index
                              ) {
                                return _c(
                                  "v-tab",
                                  { key: index },
                                  [
                                    _vm._l(jobTypeGroup, function(
                                      jobType,
                                      jobIndex
                                    ) {
                                      return [
                                        jobIndex > 0
                                          ? _c(
                                              "span",
                                              {
                                                key: jobType.id + "-plus",
                                                staticClass: "ml-2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    +\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            key: jobType.id + "-name",
                                            class: jobIndex > 0 ? "ml-2" : ""
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(jobType.name) +
                                                "\n                  "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            key: jobType.id + "-count",
                                            class: [
                                              "font-weight-bold job-count ml-2 px-1",
                                              _vm.ratioCount[jobType.id] &&
                                              _vm.ratioCount[jobType.id] !==
                                                _vm.jobTypeCount[jobType.id]
                                                ? "imbalance"
                                                : ""
                                            ]
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.jobTypeCount[jobType.id]
                                                  ) +
                                                  "\n                    "
                                              )
                                            ])
                                          ]
                                        ),
                                        jobType.id !== _vm.ALL_JOB_TYPES_ID &&
                                        _vm.ratioCount[jobType.id] &&
                                        _vm.ratioCount[jobType.id] !==
                                          _vm.jobTypeCount[jobType.id]
                                          ? _c(
                                              "div",
                                              {
                                                key: jobType.id + "-ratio",
                                                class: [
                                                  "font-weight-bold job-count ml-2 px-1 ratio"
                                                ]
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.ratioCount[
                                                          jobType.id
                                                        ]
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    })
                                  ],
                                  2
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-data-table", {
                      staticClass: "categories",
                      attrs: {
                        dense: "",
                        "fixed-header": "",
                        "group-by": "category",
                        headers: _vm.headers,
                        "header-props": { sortIcon: "fa fa-arrow-up" },
                        height: _vm.tableHeight + "px",
                        "hide-default-footer": "",
                        items: _vm.filteredItems,
                        "items-per-page": _vm.filteredItems.length,
                        "mobile-breakpoint": "",
                        "no-data-text": _vm.$t("descriptions.noShifts"),
                        "sort-by": _vm.sort.columns,
                        "sort-desc": _vm.sort.order
                      },
                      on: {
                        "update:sort-by": _vm.setSortBy,
                        "update:sort-desc": _vm.setSortDirection
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "item",
                          fn: function(ref) {
                            var categoryItem = ref.item
                            return [
                              _c(
                                "td",
                                { attrs: { colspan: _vm.headers.length } },
                                [
                                  _c("v-data-table", {
                                    class: [
                                      "schedule-items",
                                      _vm.selectedJobTypeTab ===
                                        _vm.ALL_JOB_TYPES_ID ||
                                      _vm.jobTypes[_vm.selectedJobTypeTab]
                                        .length > 1
                                        ? "multiple"
                                        : "single"
                                    ],
                                    attrs: {
                                      dense: "",
                                      "fixed-header": "",
                                      "group-by": "jobTypeIndex",
                                      headers: _vm.headers,
                                      "hide-default-footer": "",
                                      items: categoryItem.activities,
                                      "items-per-page":
                                        categoryItem.activities.length,
                                      "mobile-breakpoint": "",
                                      "no-data-text": _vm.$t(
                                        "descriptions.noShifts"
                                      ),
                                      "show-expand": "",
                                      "single-select": ""
                                    },
                                    on: { "click:row": _vm.showItemDetails },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "group.header",
                                          fn: function(ref) {
                                            var jobTypeIndex = ref.group
                                            return [
                                              _c(
                                                "td",
                                                {
                                                  attrs: {
                                                    colspan: _vm.headers.length
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "pl-1" },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.getJobLabel(
                                                              jobTypeIndex
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold job-count ml-2 px-1 inner-table"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              categoryItem.category ===
                                                                _vm
                                                                  .categoryMapping
                                                                  .onDuty
                                                                ? _vm.getJobCount(
                                                                    jobTypeIndex
                                                                  )
                                                                : _vm.getCategoryCount(
                                                                    categoryItem.category,
                                                                    jobTypeIndex
                                                                  )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.assigneeId",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "v-list-item",
                                                { staticClass: "px-0" },
                                                [
                                                  _c(
                                                    "v-list-item-avatar",
                                                    { staticClass: "mr-1" },
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          attrs: {
                                                            color:
                                                              _vm.$store.state
                                                                .org.employees[
                                                                item.user.userId
                                                              ].avatarBgColor,
                                                            size: "30"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "white--text subtitle-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.getAvatar(
                                                                      _vm.$store
                                                                        .state
                                                                        .org
                                                                        .employees[
                                                                        item
                                                                          .user
                                                                          .userId
                                                                      ]
                                                                    )
                                                                  ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          class: [
                                                            "body-2 mb-1 name-n-avatar",
                                                            item.user.state ===
                                                            _vm.ACCOUNT_STATE
                                                              .active
                                                              ? ""
                                                              : "grey--text"
                                                          ],
                                                          attrs: {
                                                            title:
                                                              item.user.fullName
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "d-inline-block text-truncate",
                                                              staticStyle: {
                                                                width: "134px"
                                                              }
                                                            },
                                                            [
                                                              _c("UserName", {
                                                                attrs: {
                                                                  user:
                                                                    _vm.$store
                                                                      .state.org
                                                                      .employees[
                                                                      item.user
                                                                        .userId
                                                                    ]
                                                                },
                                                                on: {
                                                                  saved:
                                                                    _vm.updateNurseDetails
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        {
                                                          class: [
                                                            "caption mt-1",
                                                            item.user.state ===
                                                            _vm.ACCOUNT_STATE
                                                              .active
                                                              ? ""
                                                              : "grey--text"
                                                          ]
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.getJobInfo(
                                                                  item
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        "nudge-left": "5",
                                                        right: ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var tooltipOn =
                                                                ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _vm.openedPanelName ===
                                                                  "nurse-details" &&
                                                                _vm.panels[0] &&
                                                                _vm.panels[0]
                                                                  .userId ===
                                                                  item.user.id
                                                                  ? _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "info darken-1 staff-notes",
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              "x-small":
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                $event.stopPropagation()
                                                                                return _vm.toggleNurseDetailsPanel(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        tooltipOn
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "white--text",
                                                                            attrs: {
                                                                              size:
                                                                                "10"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            fal fa-sliders-h fa-inverse\n                          "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : item.user
                                                                      .scheduleNotes
                                                                  ? _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "secondary lighten-4 staff-notes",
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              "x-small":
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                $event.stopPropagation()
                                                                                return _vm.toggleNurseDetailsPanel(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        tooltipOn
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "anchor--text",
                                                                            attrs: {
                                                                              size:
                                                                                "10"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            fal fa-sliders-h\n                          "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "grey lighten-3 staff-notes",
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              "x-small":
                                                                                ""
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                $event.stopPropagation()
                                                                                return _vm.toggleNurseDetailsPanel(
                                                                                  item
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          "v-btn",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        tooltipOn
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "grey--text text--darken-3",
                                                                            attrs: {
                                                                              size:
                                                                                "10"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            fal fa-sliders-h\n                          "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "body-2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.viewStaffDetails"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm.getErrors(item.user)
                                                    .length > 0
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            "nudge-left": "5",
                                                            right: "",
                                                            "max-width": "300px"
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var tooltipOn =
                                                                    ref.on
                                                                  var attrs =
                                                                    ref.attrs
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            staticClass:
                                                                              "has-errors",
                                                                            attrs: {
                                                                              color:
                                                                                "error",
                                                                              "x-small":
                                                                                ""
                                                                            }
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        tooltipOn
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          fal fa-exclamation-triangle\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "body-2"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "descriptions.staffErrors"
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.typeId",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              item.value &&
                                              item.type === "shift"
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      class: [
                                                        "shift-type",
                                                        "pa-0",
                                                        item.value.overtime
                                                          ? "error--text"
                                                          : ""
                                                      ],
                                                      staticStyle: {
                                                        position: "relative"
                                                      },
                                                      attrs: {
                                                        align: "center",
                                                        justify: "center",
                                                        "no-gutters": ""
                                                      }
                                                    },
                                                    [
                                                      item.value
                                                        .departmentId !==
                                                        _vm.activeDepartment
                                                          .id ||
                                                      _vm.hasDefaultShiftTime(
                                                        item.value
                                                      )
                                                        ? [
                                                            item.value
                                                              .departmentId !==
                                                            _vm.activeDepartment
                                                              .id
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.getDepartmentByID(
                                                                          item
                                                                            .value
                                                                            .departmentId
                                                                        ).name
                                                                      ) +
                                                                      "\n                      "
                                                                  )
                                                                ])
                                                              : [
                                                                  _c(
                                                                    "ScheduleSymbol",
                                                                    {
                                                                      attrs: {
                                                                        symbol: _vm.getSymbol(
                                                                          "shift",
                                                                          item
                                                                        ),
                                                                        entity:
                                                                          _vm
                                                                            .shiftTypes[
                                                                            item
                                                                              .value
                                                                              .typeId
                                                                          ]
                                                                      }
                                                                    }
                                                                  ),
                                                                  item.value
                                                                    .obligatory
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                          *\n                        "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                            item.category ===
                                                            _vm.categoryMapping
                                                              .nonDuty
                                                              ? _c("span", {
                                                                  staticClass:
                                                                    "non-duty"
                                                                })
                                                              : _vm._e()
                                                          ]
                                                        : [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "caption"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "d-block"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.getShiftTime(
                                                                            item.value
                                                                          )[0] +
                                                                            (item
                                                                              .value
                                                                              .obligatory
                                                                              ? "*"
                                                                              : "")
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "d-block"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          _vm.getShiftTime(
                                                                            item.value
                                                                          )[1]
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            item.category ===
                                                            _vm.categoryMapping
                                                              .nonDuty
                                                              ? _c("span", {
                                                                  staticClass:
                                                                    "non-duty custom-time"
                                                                })
                                                              : _vm._e()
                                                          ]
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              item.value &&
                                              item.type === "shift" &&
                                              _vm.wasShiftModifiedByManagement(
                                                item.value,
                                                _vm.$store.state
                                              )
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "management",
                                                      attrs: {
                                                        color: "nb-gold",
                                                        size: "10"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    fas fa-pencil\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.value &&
                                              item.type === "shift" &&
                                              _vm.hasDifferentPayrollDate(item)
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "payroll-diff text--darken-3",
                                                      attrs: {
                                                        color: "grey",
                                                        size: "10"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    fas fa-level-up\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.value && item.request
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "request",
                                                      attrs: {
                                                        color: "warning",
                                                        size: "10"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    fas fa-circle\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.value &&
                                              item.type === "event"
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      staticStyle: {
                                                        height: "100%"
                                                      },
                                                      attrs: {
                                                        "align-content": "end",
                                                        "no-gutters": "",
                                                        title: _vm.getEventType(
                                                          item.value
                                                        ).name
                                                      }
                                                    },
                                                    [
                                                      _c("ScheduleSymbol", {
                                                        staticClass:
                                                          "ml-3 mb-1",
                                                        attrs: {
                                                          symbol: {
                                                            symbolType: "bar",
                                                            color: _vm.getEventType(
                                                              item.value
                                                            ).color,
                                                            style: {
                                                              height:
                                                                "5px !important",
                                                              width:
                                                                "32px !important"
                                                            }
                                                          }
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.flags",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              item.value &&
                                              item.type === "shift"
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-truncate",
                                                      style: { width: "200px" }
                                                    },
                                                    _vm._l(
                                                      item.value.flags,
                                                      function(flagId) {
                                                        return _c(
                                                          "v-chip",
                                                          {
                                                            key: flagId,
                                                            staticClass:
                                                              "lighten-2 grey--text text--darken-3 flag-short-code mb-1 mr-1",
                                                            attrs: {
                                                              color: "info",
                                                              small: "",
                                                              title:
                                                                _vm
                                                                  .shiftFlagsById[
                                                                  flagId
                                                                ].name
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  _vm
                                                                    .shiftFlagsById[
                                                                    flagId
                                                                  ].shortCode
                                                                ) +
                                                                "\n                    "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.comments",
                                          fn: function(ref) {
                                            var item = ref.item
                                            var header = ref.header
                                            return [
                                              item.value
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-inline-block text-truncate",
                                                      style: {
                                                        width:
                                                          header.width -
                                                          32 +
                                                          "px"
                                                      },
                                                      attrs: {
                                                        title:
                                                          item.value.comments
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            item.value.comments
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.lastFloat",
                                          fn: function(ref) {
                                            var item = ref.item
                                            var header = ref.header
                                            return [
                                              item.value &&
                                              _vm.lastFloat.users[
                                                item.value.assigneeId
                                              ] &&
                                              _vm.lastFloat.users[
                                                item.value.assigneeId
                                              ].last
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-inline-block text-truncate",
                                                      style: {
                                                        width:
                                                          header.width -
                                                          32 +
                                                          "px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm
                                                              .moment(
                                                                _vm.lastFloat
                                                                  .users[
                                                                  item.value
                                                                    .assigneeId
                                                                ].last
                                                                  .payrollDate ||
                                                                  _vm.lastFloat
                                                                    .users[
                                                                    item.value
                                                                      .assigneeId
                                                                  ].last.date
                                                              )
                                                              .format(
                                                                _vm.DATE_FORMAT_US
                                                              )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.lastCancel",
                                          fn: function(ref) {
                                            var item = ref.item
                                            var header = ref.header
                                            return [
                                              item.value &&
                                              _vm.lastCanceled.users[
                                                item.value.assigneeId
                                              ] &&
                                              _vm.lastCanceled.users[
                                                item.value.assigneeId
                                              ].last
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-inline-block text-truncate",
                                                      style: {
                                                        width:
                                                          header.width -
                                                          32 +
                                                          "px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm
                                                              .moment(
                                                                _vm.lastCanceled
                                                                  .users[
                                                                  item.value
                                                                    .assigneeId
                                                                ].last
                                                                  .payrollDate ||
                                                                  _vm
                                                                    .lastCanceled
                                                                    .users[
                                                                    item.value
                                                                      .assigneeId
                                                                  ].last.date
                                                              )
                                                              .format(
                                                                _vm.DATE_FORMAT_US
                                                              )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.lastOvertime",
                                          fn: function(ref) {
                                            var item = ref.item
                                            var header = ref.header
                                            return [
                                              item.value &&
                                              _vm.lastOvertime.users[
                                                item.value.assigneeId
                                              ] &&
                                              _vm.lastOvertime.users[
                                                item.value.assigneeId
                                              ].last
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "d-inline-block text-truncate",
                                                      style: {
                                                        width:
                                                          header.width -
                                                          32 +
                                                          "px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm
                                                              .moment(
                                                                _vm.lastOvertime
                                                                  .users[
                                                                  item.value
                                                                    .assigneeId
                                                                ].last
                                                                  .payrollDate ||
                                                                  _vm
                                                                    .lastOvertime
                                                                    .users[
                                                                    item.value
                                                                      .assigneeId
                                                                  ].last.date
                                                              )
                                                              .format(
                                                                _vm.DATE_FORMAT_US
                                                              )
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.data-table-expand",
                                          fn: function(ref) {
                                            var expand = ref.expand
                                            var isExpanded = ref.isExpanded
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return expand(!isExpanded)
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            isExpanded
                                                              ? "fas fa-chevron-up"
                                                              : "fas fa-chevron-down"
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "expanded-item",
                                          fn: function(ref) {
                                            var innerHeaders = ref.headers
                                            var item = ref.item
                                            return [
                                              item.value
                                                ? _c(
                                                    "td",
                                                    {
                                                      attrs: {
                                                        colspan:
                                                          innerHeaders.length
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-container",
                                                        { staticClass: "mx-0" },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pa-0"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-menu",
                                                                    {
                                                                      attrs: {
                                                                        "min-width":
                                                                          "250px",
                                                                        "close-on-content-click": false,
                                                                        "offset-y":
                                                                          ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var value =
                                                                                ref.value
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "title font-weight-regular text-capitalize schedule-dropdown px-2",
                                                                                      staticStyle: {
                                                                                        width:
                                                                                          "198px"
                                                                                      },
                                                                                      attrs: {
                                                                                        disabled:
                                                                                          _vm
                                                                                            .lastCanceled
                                                                                            .loading,
                                                                                        elevation:
                                                                                          "0",
                                                                                        outlined:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "body-2 font-weight-regular"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.selectedLastCanceledScheduleText
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-spacer"
                                                                                    ),
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text--darken-3",
                                                                                        attrs: {
                                                                                          color:
                                                                                            "grey",
                                                                                          size:
                                                                                            "16"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              value
                                                                                                ? "fas fa-caret-up"
                                                                                                : "fas fa-caret-down"
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ScheduleSelection",
                                                                        {
                                                                          attrs: {
                                                                            department:
                                                                              _vm.activeDepartment,
                                                                            "selected-schedule-id":
                                                                              _vm
                                                                                .lastCanceled
                                                                                .schedule
                                                                                .id
                                                                          },
                                                                          on: {
                                                                            select:
                                                                              _vm.setSelectedLastCanceledSchedule
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 font-weight-medium"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "labels.canceledDates"
                                                                            )
                                                                          ) +
                                                                          _vm._s(
                                                                            _vm
                                                                              .lastCanceled
                                                                              .loading
                                                                              ? ""
                                                                              : " (" +
                                                                                  _vm.getLastCanceledCount(
                                                                                    item
                                                                                  ) +
                                                                                  ")"
                                                                          ) +
                                                                          ":\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm
                                                                    .lastCanceled
                                                                    .loading
                                                                    ? _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3",
                                                                          attrs: {
                                                                            color:
                                                                              "primary lighten-2",
                                                                            indeterminate:
                                                                              "",
                                                                            size:
                                                                              "22",
                                                                            width:
                                                                              "2"
                                                                          }
                                                                        }
                                                                      )
                                                                    : [
                                                                        !_vm
                                                                          .lastCanceled
                                                                          .users[
                                                                          item
                                                                            .value
                                                                            .assigneeId
                                                                        ] ||
                                                                        _vm
                                                                          .lastCanceled
                                                                          .users[
                                                                          item
                                                                            .value
                                                                            .assigneeId
                                                                        ].shifts
                                                                          .length ===
                                                                          0
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.noRecords"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.getDates(
                                                                                        _vm
                                                                                          .lastCanceled
                                                                                          .users[
                                                                                          item
                                                                                            .value
                                                                                            .assigneeId
                                                                                        ]
                                                                                          .shifts
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-container",
                                                        {
                                                          staticClass:
                                                            "mx-0 pt-0"
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pa-0"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-menu",
                                                                    {
                                                                      attrs: {
                                                                        "min-width":
                                                                          "250px",
                                                                        "close-on-content-click": false,
                                                                        "offset-y":
                                                                          ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var value =
                                                                                ref.value
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "title font-weight-regular text-capitalize schedule-dropdown px-2",
                                                                                      staticStyle: {
                                                                                        width:
                                                                                          "198px"
                                                                                      },
                                                                                      attrs: {
                                                                                        disabled:
                                                                                          _vm
                                                                                            .lastFloat
                                                                                            .loading,
                                                                                        elevation:
                                                                                          "0",
                                                                                        outlined:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "body-2 font-weight-regular"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.selectedLastFloatScheduleText
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-spacer"
                                                                                    ),
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text--darken-3",
                                                                                        attrs: {
                                                                                          color:
                                                                                            "grey",
                                                                                          size:
                                                                                            "16"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              value
                                                                                                ? "fas fa-caret-up"
                                                                                                : "fas fa-caret-down"
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ScheduleSelection",
                                                                        {
                                                                          attrs: {
                                                                            department:
                                                                              _vm.activeDepartment,
                                                                            "selected-schedule-id":
                                                                              _vm
                                                                                .lastFloat
                                                                                .schedule
                                                                                .id
                                                                          },
                                                                          on: {
                                                                            select:
                                                                              _vm.setSelectedLastFloatSchedule
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "ml-3 font-weight-medium"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "labels.floatDates"
                                                                            )
                                                                          ) +
                                                                          ":\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm.lastFloat
                                                                    .loading
                                                                    ? _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3",
                                                                          attrs: {
                                                                            color:
                                                                              "primary lighten-2",
                                                                            indeterminate:
                                                                              "",
                                                                            size:
                                                                              "22",
                                                                            width:
                                                                              "2"
                                                                          }
                                                                        }
                                                                      )
                                                                    : [
                                                                        !_vm
                                                                          .lastFloat
                                                                          .users[
                                                                          item
                                                                            .value
                                                                            .assigneeId
                                                                        ] ||
                                                                        _vm
                                                                          .lastFloat
                                                                          .users[
                                                                          item
                                                                            .value
                                                                            .assigneeId
                                                                        ].shifts
                                                                          .length ===
                                                                          0
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.noRecords"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.getDates(
                                                                                        _vm
                                                                                          .lastFloat
                                                                                          .users[
                                                                                          item
                                                                                            .value
                                                                                            .assigneeId
                                                                                        ]
                                                                                          .shifts
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-container",
                                                        {
                                                          staticClass:
                                                            "mx-0 pt-0"
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "pa-0"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-menu",
                                                                    {
                                                                      attrs: {
                                                                        "min-width":
                                                                          "250px",
                                                                        "close-on-content-click": false,
                                                                        "offset-y":
                                                                          ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var value =
                                                                                ref.value
                                                                              return [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "title font-weight-regular text-capitalize schedule-dropdown px-2",
                                                                                      staticStyle: {
                                                                                        width:
                                                                                          "198px"
                                                                                      },
                                                                                      attrs: {
                                                                                        disabled:
                                                                                          _vm
                                                                                            .lastOvertime
                                                                                            .loading,
                                                                                        elevation:
                                                                                          "0",
                                                                                        outlined:
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "body-2 font-weight-regular"
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              _vm.selectedLastOvertimeScheduleText
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-spacer"
                                                                                    ),
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        staticClass:
                                                                                          "text--darken-3",
                                                                                        attrs: {
                                                                                          color:
                                                                                            "grey",
                                                                                          size:
                                                                                            "16"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                  " +
                                                                                            _vm._s(
                                                                                              value
                                                                                                ? "fas fa-caret-up"
                                                                                                : "fas fa-caret-down"
                                                                                            ) +
                                                                                            "\n                                "
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "ScheduleSelection",
                                                                        {
                                                                          attrs: {
                                                                            department:
                                                                              _vm.activeDepartment,
                                                                            "selected-schedule-id":
                                                                              _vm
                                                                                .lastOvertime
                                                                                .schedule
                                                                                .id
                                                                          },
                                                                          on: {
                                                                            select:
                                                                              _vm.setSelectedLastOvertimeSchedule
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "ml-3 font-weight-medium"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "labels.overtimeDates"
                                                                            )
                                                                          ) +
                                                                          ":\n                          "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm
                                                                    .lastOvertime
                                                                    .loading
                                                                    ? _c(
                                                                        "v-progress-circular",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3",
                                                                          attrs: {
                                                                            color:
                                                                              "primary lighten-2",
                                                                            indeterminate:
                                                                              "",
                                                                            size:
                                                                              "22",
                                                                            width:
                                                                              "2"
                                                                          }
                                                                        }
                                                                      )
                                                                    : [
                                                                        !_vm
                                                                          .lastOvertime
                                                                          .users[
                                                                          item
                                                                            .value
                                                                            .assigneeId
                                                                        ] ||
                                                                        _vm
                                                                          .lastOvertime
                                                                          .users[
                                                                          item
                                                                            .value
                                                                            .assigneeId
                                                                        ].shifts
                                                                          .length ===
                                                                          0
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "labels.noRecords"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      _vm.getDates(
                                                                                        _vm
                                                                                          .lastOvertime
                                                                                          .users[
                                                                                          item
                                                                                            .value
                                                                                            .assigneeId
                                                                                        ]
                                                                                          .shifts
                                                                                      )
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.selectedRows,
                                      callback: function($$v) {
                                        _vm.selectedRows = $$v
                                      },
                                      expression: "selectedRows"
                                    }
                                  }),
                                  _vm.readOnly
                                    ? _c("span", {
                                        staticClass: "read-only-overlay"
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "group.header",
                          fn: function(ref) {
                            var group = ref.group
                            var isOpen = ref.isOpen
                            var toggle = ref.toggle
                            return [
                              _c(
                                "td",
                                {
                                  ref: group,
                                  class: isOpen ? "expanded" : "",
                                  attrs: { colspan: _vm.headers.length },
                                  on: {
                                    click: function($event) {
                                      return _vm.expandGroup(
                                        group,
                                        isOpen,
                                        toggle
                                      )
                                    }
                                  }
                                },
                                [
                                  isOpen
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticStyle: { width: "14px" },
                                          attrs: {
                                            color: "secondary",
                                            dense: "",
                                            size: "14"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                fas fa-caret-down\n              "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "v-icon",
                                        {
                                          staticStyle: { width: "14px" },
                                          attrs: {
                                            color: "secondary",
                                            dense: "",
                                            size: "14"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                fas fa-caret-right\n              "
                                          )
                                        ]
                                      ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "secondary--text pl-1 text-capitalize"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.getCategoryLabel(group)) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "header.assigneeId",
                          fn: function() {
                            return [
                              _c("StaffSearch", {
                                attrs: {
                                  "append-icon": _vm.staffFilter
                                    ? ""
                                    : "fal fa-search",
                                  "target-class": "daily-search-staff py-3",
                                  clearable: !!_vm.staffFilter,
                                  dense: "",
                                  "hide-details": "",
                                  solo: ""
                                },
                                model: {
                                  value: _vm.staffFilter,
                                  callback: function($$v) {
                                    _vm.staffFilter =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "staffFilter"
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", width: "500" },
                    model: {
                      value: _vm.shareDialogOpened,
                      callback: function($$v) {
                        _vm.shareDialogOpened = $$v
                      },
                      expression: "shareDialogOpened"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      { attrs: { id: "shareScheduleDialog" } },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "primary--text subtitle-2 py-2" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("labels.share")) +
                                "\n            "
                            ),
                            _c("v-spacer"),
                            _vm.sharingSummaryState !==
                            _vm.SHARING_SUMMARY_IN_PROGRESS
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "float-right",
                                    attrs: { icon: "", "x-small": "" },
                                    on: {
                                      click: function($event) {
                                        _vm.shareDialogOpened = false
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("fal fa-times")])],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("v-divider", { staticClass: "mx-2" }),
                        _vm.loadingShareDialog
                          ? [
                              _c(
                                "v-row",
                                {
                                  staticStyle: { height: "540px" },
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "v-row",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "text-center" },
                                            [
                                              _c("v-progress-circular", {
                                                attrs: {
                                                  color: "info",
                                                  indeterminate: "",
                                                  size: "75",
                                                  width: "6"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { staticClass: "text-center" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "descriptions.loading"
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-spacer")
                                ],
                                1
                              )
                            ]
                          : [
                              _c(
                                "v-card-text",
                                { staticClass: "mt-4 pb-0" },
                                [
                                  _c("div", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$t("descriptions.shareSummary")
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _c("br"),
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "px-0 lighten-5",
                                      attrs: { color: "grey", outlined: "" }
                                    },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "share-list mx-4",
                                        attrs: {
                                          dense: "",
                                          "fixed-header": "",
                                          headers: _vm.shareSummaryHeaders,
                                          height: "400",
                                          "hide-default-footer": "",
                                          items: _vm.shareWithDepartments,
                                          "items-per-page":
                                            _vm.shareWithDepartments.length,
                                          "mobile-breakpoint": "",
                                          "single-select": ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "header.dailySchedule",
                                            fn: function(ref) {
                                              var header = ref.header
                                              return [
                                                _vm.shareCount[header.id] ===
                                                _vm.shareWithDepartments.length
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.sharingSummaryState ===
                                                            _vm.SHARING_SUMMARY_IN_PROGRESS,
                                                          icon: "",
                                                          ripple: false,
                                                          height: "12",
                                                          width: "12"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.shareWithZeroDepartments(
                                                              header.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "info",
                                                              size: "14"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        fas fa-check-square\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm.shareCount[header.id] >
                                                    0
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.sharingSummaryState ===
                                                            _vm.SHARING_SUMMARY_IN_PROGRESS,
                                                          icon: "",
                                                          ripple: false,
                                                          height: "12",
                                                          width: "12"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.shareWithZeroDepartments(
                                                              header.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "info",
                                                              size: "14"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        fas fa-minus-square\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.sharingSummaryState ===
                                                            _vm.SHARING_SUMMARY_IN_PROGRESS,
                                                          icon: "",
                                                          ripple: false,
                                                          height: "12",
                                                          width: "12"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.shareWithAllDepartments(
                                                              header.id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              size: "14"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        fal fa-square\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "body-2 grey--text text--darken-3 ml-2",
                                                    style: {
                                                      cursor:
                                                        _vm.sharingSummaryState !==
                                                        _vm.SHARING_SUMMARY_IN_PROGRESS
                                                          ? "pointer"
                                                          : ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.shareCount[
                                                          header.id
                                                        ] > 0
                                                          ? _vm.shareWithZeroDepartments(
                                                              header.id
                                                            )
                                                          : _vm.shareWithAllDepartments(
                                                              header.id
                                                            )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.selectAll"
                                                          )
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.department",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-3 dept-name d-inline-block text-truncate",
                                                    attrs: { title: item.name }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(item.name) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                ),
                                                item.status === "pending"
                                                  ? _c("v-progress-linear", {
                                                      staticClass:
                                                        "share-progress",
                                                      attrs: {
                                                        color: "nb-azure",
                                                        rounded: "",
                                                        value: "100"
                                                      }
                                                    })
                                                  : item.status ===
                                                    "in_progress"
                                                  ? _c("v-progress-linear", {
                                                      staticClass:
                                                        "share-progress",
                                                      attrs: {
                                                        indeterminate: "",
                                                        color: "secondary",
                                                        rounded: ""
                                                      }
                                                    })
                                                  : item.status === "success"
                                                  ? [
                                                      _c("v-progress-linear", {
                                                        staticClass:
                                                          "share-progress",
                                                        attrs: {
                                                          color: "secondary",
                                                          rounded: "",
                                                          value: "100"
                                                        }
                                                      }),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "ml-1 share-progress-status",
                                                          attrs: {
                                                            color: "secondary",
                                                            size: "10"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        far fa-check\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : item.status === "failed"
                                                  ? [
                                                      _c("v-progress-linear", {
                                                        staticClass:
                                                          "share-progress",
                                                        attrs: {
                                                          color: "error",
                                                          rounded: "",
                                                          value: "100"
                                                        }
                                                      }),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "ml-1 share-progress-status",
                                                          attrs: {
                                                            color: "error",
                                                            size: "10"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        far fa-exclamation-triangle\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ]
                                            }
                                          },
                                          {
                                            key: "item.dailySchedule",
                                            fn: function(ref) {
                                              var header = ref.header
                                              var item = ref.item
                                              return [
                                                _c("v-simple-checkbox", {
                                                  staticClass:
                                                    "extra-dense-checkbox d-inline-block daily-schedule-check",
                                                  attrs: {
                                                    color: "info",
                                                    disabled:
                                                      _vm.sharingSummaryState ===
                                                      _vm.SHARING_SUMMARY_IN_PROGRESS,
                                                    ripple: false
                                                  },
                                                  model: {
                                                    value:
                                                      item.dailySchedules[
                                                        header.id
                                                      ].send,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item.dailySchedules[
                                                          header.id
                                                        ],
                                                        "send",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.dailySchedules[header.id].send"
                                                  }
                                                }),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "ml-2 grey--text text--darken-3 daily-schedule-label d-inline-block text-truncate",
                                                    style: {
                                                      cursor:
                                                        _vm.sharingSummaryState !==
                                                        _vm.SHARING_SUMMARY_IN_PROGRESS
                                                          ? "pointer"
                                                          : ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.toggleDailyScheduleShare(
                                                          item,
                                                          header
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(header.text) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grey--text caption-2 mt-n2"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          item.dailySchedules[
                                                            header.id
                                                          ].lastShared
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                    item.dailySchedules[
                                                      header.id
                                                    ].lastShared
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              right: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticStyle: {
                                                                                "vertical-align":
                                                                                  "inherit"
                                                                              },
                                                                              attrs: {
                                                                                color:
                                                                                  "grey",
                                                                                size:
                                                                                  "10"
                                                                              }
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            fal fa-info-circle\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      item
                                                                        .dailySchedules[
                                                                        header
                                                                          .id
                                                                      ]
                                                                        .lastSharedDetails
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c("EmployeeSelector", {
                                    staticClass: "mt-4",
                                    attrs: {
                                      disabled:
                                        _vm.sharingSummaryState ===
                                        _vm.SHARING_SUMMARY_IN_PROGRESS,
                                      filters: [
                                        {
                                          op: "in",
                                          prop: "classification",
                                          value: ["ops_management"]
                                        }
                                      ],
                                      hint: _vm.$t(
                                        "descriptions.emailAdditionalRecipients"
                                      ),
                                      label: _vm.$t(
                                        "labels.emailAdditionalRecipients"
                                      ),
                                      multiple: ""
                                    },
                                    on: {
                                      change: _vm.updateAdditionalRecipients
                                    }
                                  }),
                                  _vm.shareWithAdditionalRecipients.status ===
                                  "pending"
                                    ? _c("v-progress-linear", {
                                        staticClass: "share-progress",
                                        attrs: {
                                          color: "nb-azure",
                                          rounded: "",
                                          value: "100"
                                        }
                                      })
                                    : _vm.shareWithAdditionalRecipients
                                        .status === "in_progress"
                                    ? _c("v-progress-linear", {
                                        staticClass: "share-progress",
                                        attrs: {
                                          indeterminate: "",
                                          color: "secondary",
                                          rounded: ""
                                        }
                                      })
                                    : _vm.shareWithAdditionalRecipients
                                        .status === "success"
                                    ? [
                                        _c("v-progress-linear", {
                                          staticClass: "share-progress",
                                          attrs: {
                                            color: "secondary",
                                            rounded: "",
                                            value: "100"
                                          }
                                        }),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "ml-1 share-progress-status",
                                            attrs: {
                                              color: "secondary",
                                              size: "10"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  far fa-check\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm.shareWithAdditionalRecipients
                                        .status === "failed"
                                    ? [
                                        _c("v-progress-linear", {
                                          staticClass: "share-progress",
                                          attrs: {
                                            color: "error",
                                            rounded: "",
                                            value: "100"
                                          }
                                        }),
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "ml-1 share-progress-status",
                                            attrs: {
                                              color: "error",
                                              size: "10"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  far fa-exclamation-triangle\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "px-6" },
                                [
                                  _vm.failedSharingSummary
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "my-3 px-4 mr-1",
                                          attrs: { text: "" },
                                          on: { click: _vm.retryShareSummary }
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t("labels.retry")) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "my-3 px-4 download-pdf",
                                      attrs: {
                                        color: "primary",
                                        disabled:
                                          !_vm.selectedDepartmentsToShare ||
                                          _vm.sharingSummaryState ===
                                            _vm.SHARING_SUMMARY_IN_PROGRESS,
                                        outlined: ""
                                      },
                                      on: { click: _vm.downloadSummary }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t("labels.downloadPDF")
                                            ) +
                                            "\n                "
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "my-3 px-4",
                                      attrs: {
                                        color: "accent",
                                        disabled:
                                          !_vm.selectedDepartmentsToShare ||
                                          _vm.sharingSummaryState ===
                                            _vm.SHARING_SUMMARY_IN_PROGRESS
                                      },
                                      on: { click: _vm.emailSummary }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.$t("labels.emailPDF")) +
                                            "\n                "
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("SidePanel", { attrs: { panels: _vm.sidePanels } })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }