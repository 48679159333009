<template>
  <component
    :is="component"
    v-bind="$props"
    v-on="$listeners"
  />
</template>
<script>
import EventRequestCard from '@/views/scheduling/cards/EventRequestCard';
import ShiftRequestCard from '@/views/scheduling/cards/ShiftRequestCard';
import SwapRequestCard from '@/views/scheduling/cards/SwapRequestCard';

export default {
  components: {
    EventRequestCard,
    ShiftRequestCard,
    SwapRequestCard
  },
  props: {
    hover: {
      default: false,
      type: Boolean
    },
    request: {
      type: Object,
      required: true
    },
    scheduleId: {
      default: 0,
      type: [Number, String]
    }
  },
  computed: {
    component () {
      return `${this.request.type}-request-card`;
    }
  }
};
</script>
