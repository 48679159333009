<template>
  <v-container
    class="pa-0"
  >
    <NoContent
      v-if="shifts.length === 0"
      exciting
      :height="140"
      :width="140"
      :message="$t('descriptions.consecutiveShiftsNoErrors')"
    />
    <template v-else>
      <ShiftsCard
        v-for="shift in shifts"
        :key="shift.id"
        class="mb-4"
        :shifts="[shift]"
        :show-shift-date="true"
        :user="user"
        @click="$emit('show-shift', shift)"
      />
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import NoContent from '@/components/NoContent';
import ShiftsCard from '@/components/scheduling/ShiftsCard';

export default {
  components: {
    NoContent,
    ShiftsCard
  },
  props: {
    error: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    shifts () {
      return _.uniqBy(_.values(this.error.data), 'id');
    }
  }
};
</script>

<style lang="scss">

</style>
