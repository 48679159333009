var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-0 schedule-help" },
    [
      _c(
        "v-list-item",
        { staticClass: "pa-0 mb-5" },
        [
          _c(
            "v-list-item-icon",
            [_c("v-icon", [_vm._v(" fal fa-info-circle")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "pb-0" }, [
            _c("span", { staticClass: "page-title panel-title" }, [
              _vm._v(_vm._s(_vm.$t("labels.information")))
            ]),
            _c("div", {
              staticClass: "caption",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$t("descriptions.togglePanel", { key: _vm.toggleKey })
                )
              }
            })
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "schedule-help-content pa-0" },
        [
          _c(
            "v-tabs",
            {
              staticClass: "dense",
              attrs: { centered: "", grow: "", "hide-slider": "" },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              !_vm.$store.getters["account/isStaff"]()
                ? _c("v-tab", { attrs: { href: "#shortcuts" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("labels.keyboardShortcuts")) +
                        "\n      "
                    )
                  ])
                : _vm._e(),
              _c("v-tab", { attrs: { href: "#legend" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("labels.legend")) + "\n      "
                )
              ]),
              _c("v-tab", { attrs: { href: "#flags" } }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$tc("labels.flag", 2)) + "\n      "
                )
              ]),
              _c(
                "v-tab-item",
                { attrs: { value: "shortcuts" } },
                [
                  _c("div", {
                    staticClass: "caption grey--text text--darken-3",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("descriptions.obligatoryShiftShortcut", {
                          key: _vm.shiftKey
                        })
                      )
                    }
                  }),
                  _c(
                    "v-row",
                    { staticClass: "mb-1", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            staticClass: "extra-dense-text-field mt-3",
                            attrs: {
                              "append-icon": _vm.shortcutFilter
                                ? ""
                                : "fal fa-search",
                              clearable: !!_vm.shortcutFilter,
                              dense: "",
                              "hide-details": "",
                              outlined: "",
                              placeholder: _vm.$t("labels.quickSearch")
                            },
                            model: {
                              value: _vm.shortcutFilter,
                              callback: function($$v) {
                                _vm.shortcutFilter =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "shortcutFilter"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    { style: _vm.infoStyle },
                    [
                      _vm.shiftTypes.length === 0
                        ? _c(
                            "v-list-item",
                            { staticClass: "pa-0", attrs: { dense: "" } },
                            [
                              _c(
                                "v-list-item-content",
                                { staticClass: "caption pa-0" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tc(
                                          "labels.showingResultsCountFor",
                                          0,
                                          { filter: _vm.shortcutFilter }
                                        )
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.shiftTypes, function(shift) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: "shift" + shift.id,
                              staticClass: "keyboard-shortcut pl-0",
                              attrs: { title: shift.description || shift.name }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                {
                                  staticClass:
                                    "d-inline-block help-icon text-center mr-3 my-0",
                                  style: { "align-self": "center" }
                                },
                                [
                                  _c("ScheduleSymbol", {
                                    attrs: {
                                      symbol: shift.styles.web,
                                      entity: shift
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                {
                                  staticClass:
                                    "body-2 text-truncate d-inline-block",
                                  attrs: {
                                    title: shift.description || shift.name
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(shift.description || shift.name) +
                                      "\n              "
                                  )
                                ]
                              ),
                              shift.styles.web.keyboardShortcut
                                ? _c(
                                    "v-list-item-action",
                                    { staticClass: "d-inline-block" },
                                    [
                                      shift.styles.web.keyboardModifier
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "mx-2 text-capitalize",
                                                  attrs: {
                                                    color: "secondary",
                                                    label: "",
                                                    small: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        shift.styles.web
                                                          .keyboardModifier
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                  +\n                "
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-chip",
                                        {
                                          staticClass: "mx-2 text-capitalize",
                                          attrs: {
                                            color: "secondary",
                                            label: "",
                                            small: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                shift.styles.web
                                                  .keyboardShortcut
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("v-divider", { key: "shiftdiv" + shift.id })
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "legend" } },
                [
                  _c(
                    "v-list",
                    { style: _vm.infoStyle },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: { title: _vm.$t("labels.availableShift") }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 mt-3"
                            },
                            [
                              _c("ScheduleSymbol", {
                                attrs: {
                                  symbol: _vm.getSymbolStyle("available")
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("labels.availableShift")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: { title: _vm.$t("labels.obligatoryShift") }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 mt-3"
                            },
                            [_vm._v("\n              A*\n            ")]
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "body-2" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.obligatoryShift")) +
                                  "\n              "
                              ),
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "300px", top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "obligatory-help ml-2",
                                                  attrs: {
                                                    color: "info",
                                                    "x-small": ""
                                                  }
                                                },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                    fal fa-question-circle\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("span", { staticClass: "body-2" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "descriptions.obligatoryShiftHelp"
                                          )
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: { title: _vm.$t("labels.nonDutyShift") }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 mt-3"
                            },
                            [
                              _c("span", { staticClass: "non-duty" }),
                              _vm._v("\n              A\n            ")
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("labels.nonDutyShift")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: { title: _vm.$tc("labels.event", 2) }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 mt-3"
                            },
                            [
                              _c("ScheduleSymbol", {
                                attrs: {
                                  symbol: {
                                    symbolType: "bar",
                                    color: "#D9CAFD",
                                    style: {
                                      height: "5px !important",
                                      width: "20px !important"
                                    }
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$tc("labels.event", 2)) +
                                " (" +
                                _vm._s(_vm.$t("labels.nonDuty")) +
                                ")\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0 flag",
                          attrs: { title: _vm.$tc("labels.flag", 1) }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 pl-3"
                            },
                            [
                              _c("div", { staticClass: "grid-cell-dashed" }, [
                                _vm._v("\n                A\n              ")
                              ]),
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "info lighten-1", size: "6" }
                                },
                                [
                                  _vm._v(
                                    "\n                fas fa-flag\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$tc("labels.flag", 1)) +
                                "\n            "
                            )
                          ]),
                          _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "view-flags",
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.tab = "flags"
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("labels.viewFlags")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0 modified",
                          attrs: {
                            title: _vm.$t("labels.modifiedByManagement")
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 pl-3"
                            },
                            [
                              _c("div", { staticClass: "grid-cell-dashed" }, [
                                _vm._v("\n                A\n              ")
                              ]),
                              _c(
                                "v-icon",
                                { attrs: { color: "nb-gold", size: "6" } },
                                [
                                  _vm._v(
                                    "\n                fas fa-pencil\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("labels.modifiedByManagement")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: {
                            title: _vm.$t("labels.consecutiveShiftsExcessive")
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 pl-3"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "grid-cell-dashed consecutive-shifts"
                                },
                                [_vm._v("\n                A\n              ")]
                              )
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("labels.consecutiveShiftsExcessive")
                                ) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: {
                            title:
                              _vm.$t("labels.overtime") +
                              "/" +
                              _vm.$t("labels.undertime")
                          }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 pl-3"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "grid-cell-dashed extra-shifts"
                                },
                                [_vm._v("\n                8\n              ")]
                              )
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("labels.overtime") +
                                    "/" +
                                    _vm.$t("labels.undertime")
                                ) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: { title: _vm.$tc("labels.holiday", 1) }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 pl-3"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "grid-cell-dashed holiday",
                                  style: _vm.holidayStyle
                                },
                                [_vm._v("\n                A\n              ")]
                              )
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$tc("labels.holiday", 1)) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0 request",
                          attrs: { title: _vm.$t("labels.newRequest") }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 pl-3"
                            },
                            [
                              _c("div", { staticClass: "grid-cell-dashed" }, [
                                _vm._v("\n                A\n              ")
                              ]),
                              _c(
                                "v-icon",
                                { attrs: { color: "warning", size: "6" } },
                                [
                                  _vm._v(
                                    "\n                fas fa-circle\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("labels.newRequest")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0 request",
                          attrs: { title: _vm.$t("labels.newRequest") }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 pl-3"
                            },
                            [
                              _c("div", { staticClass: "grid-cell-error" }, [
                                _vm._v("\n                A\n              ")
                              ])
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("labels.conflicts")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: { title: _vm.$t("labels.canceled") }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 mt-3"
                            },
                            [
                              _c("span", { staticClass: "py-2 grid-cell" }, [
                                _c("span", {
                                  staticClass: "non-duty canceled"
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "shift-status",
                                    style: Object.assign(
                                      {},
                                      _vm.getSymbolStyle("canceled")["css"]
                                    )
                                  },
                                  [
                                    _vm._v(
                                      "\n                  A\n                "
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("labels.canceled")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: { title: _vm.$t("labels.nonProductive") }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 mt-3"
                            },
                            [
                              _c("span", { staticClass: "py-2 grid-cell" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "shift-status",
                                    style: Object.assign(
                                      {},
                                      _vm.getSymbolStyle("canceled")["css"]
                                    )
                                  },
                                  [
                                    _vm._v(
                                      "\n                  A\n                "
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("labels.nonProductive")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: { title: _vm.$t("labels.onCall") }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 mt-3"
                            },
                            [
                              _c("span", { staticClass: "py-2 grid-cell" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "shift-status",
                                    style: Object.assign(
                                      {},
                                      _vm.getSymbolStyle("onCall")["css"]
                                    )
                                  },
                                  [
                                    _vm._v(
                                      "\n                  A\n                "
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("labels.onCall")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "legend pl-0",
                          attrs: { title: _vm.$t("labels.sitter") }
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            {
                              staticClass:
                                "d-inline-block help-icon text-center mr-3 mt-3"
                            },
                            [
                              _c("span", { staticClass: "py-2 grid-cell" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "shift-status",
                                    style: Object.assign(
                                      {},
                                      _vm.getSymbolStyle("sitter")["css"]
                                    )
                                  },
                                  [
                                    _vm._v(
                                      "\n                  A\n                "
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _c("v-list-item-content", { staticClass: "body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("labels.sitter")) +
                                "\n            "
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-divider")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "flags" } },
                [
                  _c(
                    "v-list",
                    { style: _vm.infoStyle },
                    [
                      _vm._l(_vm.flags, function(flag) {
                        return [
                          _c(
                            "v-list-item",
                            {
                              key: "flag" + flag.id,
                              staticClass: "keyboard-shortcut pl-0",
                              attrs: { title: flag.description || flag.name }
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                {
                                  staticClass:
                                    "d-inline-block help-icon text-center mr-3 mt-3"
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      staticClass:
                                        "lighten-2 grey--text text--darken-3 flag-short-code mb-1",
                                      attrs: { color: "info", small: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(flag.shortCode) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                {
                                  staticClass:
                                    "body-2 text-truncate d-inline-block"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(flag.name) +
                                      "\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("v-divider", { key: "flagdiv" + flag.id })
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }