import _ from 'lodash';
import moment from 'moment';
import { formatText, getCensusRatio, getCensusTotal, getLatestAcuity } from '@/utils/scheduling';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfMakeUnicode from 'pdfmake-unicode';

pdfMake.vfs = pdfMakeUnicode.pdfMake.vfs;
pdfMake.tableLayouts = {
  acuityTotals: {
    paddingLeft: function (i) {
      return 0;
    },
    paddingRight: function (i, node) {
      return 0;
    }
  },
  divider: {
    hLineColor: function (i, node) {
      return '#9E9E9E';
    }
  },
  groupHeader: {
    paddingLeft: function (i) {
      return 0;
    }
  }
};

class DailySchedulePDF {
  constructor (dailySchedule, date, department, type, store, t, tc, adaptor, context) {
    this.context = context;
    this.adaptor = adaptor;
    this.date = date;
    this.dailySchedule = dailySchedule;
    this.definition = {
      defaultStyle: {
        color: '#000000',
        fontSize: 12,
        lineHeight: 1.2
      },
      styles: {
        overtimeShiftDetails: {
          color: '#E74C3C',
          italics: true,
          margin: [0, 0]
        },
        groupLabel: {
          background: '#424242',
          bold: true,
          color: '#FFFFFF',
          lineHeight: 1,
          margin: [0, 10, 0, 5]
        },
        header: {
          fontSize: 10
        },
        headerMargin: {
          margin: [35, 10]
        },
        offDuty: {
          color: '#9E9E9E',
          decoration: 'lineThrough'
        },
        sectionLabel: {
          bold: true,
          margin: [0, 10, 0, 5]
        },
        shiftCount: {
          color: '#757575',
          fontSize: 10,
          italics: true,
          margin: [10, 13, 0, 5]
        },
        shiftCountInner: {
          color: '#757575',
          fontSize: 10,
          italics: true,
          margin: [10, 0, 0, 5]
        },
        shiftDetails: {
          color: '#757575',
          italics: true,
          margin: [0, 0]
        },
        shiftDetailsList: {
          margin: [10, 0]
        },
        tableHeader: {
          fillColor: '#E0E0E0'
        },
        title: {
          alignment: 'center',
          bold: true
        }
      },
      pageMargins: [40, 52, 40, 60],
      pageSize: 'LETTER',
      content: [
        {
          text: t('descriptions.dailySchedulePDFTitle', { date: moment(date).format(store.getters['org/getDateFormatLongWithDoW']()) }),
          style: ['title']
        },
        {
          text: type.name,
          style: ['title']
        },
        {
          text: department.fullName,
          style: ['title']
        }
      ]
    };
    this.department = department;
    this.store = store;
    this.t = t;
    this.tc = tc;
    this.type = type;
    this.jobTypesById = this.store.state.org.jobTypes.reduce(
      (obj, jobStatus) => {
        obj[jobStatus.id] = jobStatus;
        return obj;
      }, // eslint-disable-line no-return-assign, no-sequences
      {}
    );
    this.shiftTypesById = this.store.state.org.shiftTypes.reduce(
      (obj, shiftType) => (obj[shiftType.id] = shiftType, obj), // eslint-disable-line no-return-assign, no-sequences
      {}
    );

    this.flagsById = this.store.state.org.flags.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});

    this.isStaff = this.store.getters['account/isStaff']();
  }

  canShowFlag (assigneeId, flagId) {
    let canShowFlag = !!this.flagsById[flagId];
    if (canShowFlag && this.isStaff) {
      if (assigneeId !== this.store.state.account.userId) {
        canShowFlag &= this.flagsById[flagId].visibleToOtherStaff;
      }
    }
    return canShowFlag;
  }

  /**
   * Sets pdf header. Placeholders available:
   *  {currentPage}
   *  {pageCount}
   * @param {string|array} headerLeft String or list of strings to place in top left header
   * @param {string|array} headerRight String or list of strings to place in top right header
   */
  setHeader (headerLeft, headerRight) {
    this.definition.header = (currentPage, pageCount) => {
      const replacePlaceholders = (text) => {
        return text.replace('{currentPage}', currentPage).replace('{pageCount}', pageCount);
      };

      const left = {
        margin: this.definition.styles.headerMargin.margin,
        width: '50%'
      };

      if (headerLeft) {
        if (_.isArray(headerLeft)) {
          left.stack = [];
          for (let i = 0, count = headerLeft.length; i < count; i++) {
            left.stack.push({
              text: replacePlaceholders(headerLeft[i]),
              alignment: 'left',
              style: ['header']
            });
          }
        } else if (typeof headerLeft === 'string') {
          left.text = replacePlaceholders(headerLeft);
          left.style = ['header'];
        }
      }

      const right = {
        margin: this.definition.styles.headerMargin.margin,
        width: '50%'
      };

      if (headerRight) {
        if (_.isArray(headerRight)) {
          right.stack = [];
          for (let i = 0, count = headerRight.length; i < count; i++) {
            right.stack.push({
              text: replacePlaceholders(headerRight[i]),
              alignment: 'right',
              style: ['header']
            });
          }
        } else if (typeof headerRight === 'string') {
          right.text = replacePlaceholders(headerRight);
          right.style = ['header'];
        }
      }

      return {
        columns: [left, right]
      };
    };
  }

  /**
   * Adds content to PDF
   * @param {array} content Every entry in the array is considered a row
   */
  generate (content) {
    // Overrride this in templates classes
  }

  /**
   * Gets acuity totals
   * @param {object} options Display options
   * @returns pdfmake document definition object
   */
  _getAcuity (options) {
    if (this.store.getters['account/isStaff']()) {
      return [];
    }
    if (!this.dailySchedule || this.dailySchedule.census.length === 0) {
      return [
        {
          alignment: 'center',
          text: this.t('descriptions.noCensusFound')
        }
      ];
    }
    const content = [];
    if (options.totals) {
      const acuity = getLatestAcuity(this.dailySchedule.census);
      const totals = [];
      for (let level in acuity) {
        totals.push({
          layout: 'acuityTotals',
          table: {
            widths: ['*'],
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: acuity[level].label,
                  alignment: 'center'
                }
              ],
              [
                {
                  text: acuity[level].value,
                  alignment: 'center'
                }
              ]
            ]
          },
          width: 50,
          alignment: 'center'
        });
      }
      content.push({ alignment: 'center', text: 'Acuity', style: 'sectionLabel' });
      content.push({
        columns: [
          { width: '*', text: '' },
          ...totals,
          { width: '*', text: '' }
        ],
        columnGap: 5
      });
    }

    if (options.details) {
      content.push(this._getSpacer({}));
      const widths = [50, 50, 50];
      const header = [
        {
          text: 'Time',
          style: 'tableHeader',
          alignment: 'center'
        },
        {
          text: 'Census',
          style: 'tableHeader',
          alignment: 'center'
        },
        {
          text: '',
          style: 'tableHeader',
          alignment: 'center'
        }
      ];
      const jobTypes = [];
      const rawJobTypes = _.cloneDeep(this.store.getters['org/getJobTypes'](this.department.id));
      for (let i = 0, count = rawJobTypes.length; i < count; i++) {
        if (rawJobTypes[i].partakeInScheduling) {
          jobTypes.push(rawJobTypes[i]);
          header.push({
            text: rawJobTypes[i].name,
            style: 'tableHeader',
            alignment: 'center'
          });
          widths.push('auto');
        }
      }
      header.push({
        text: 'Sitter',
        style: 'tableHeader',
        alignment: 'center'
      });
      widths.push('auto');
      header.push({
        text: 'Comments',
        style: 'tableHeader',
        alignment: 'center'
      });
      widths.push('*');
      const census = [];
      for (let i = 0, count = this.dailySchedule.census.length; i < count; i++) {
        const censusData = this.dailySchedule.census[i];
        const censusTotal = getCensusTotal(censusData);
        const censusRatio = getCensusRatio(censusData, jobTypes, this.type);
        const censusRowActual = [];
        const censusRowRatio = [];
        censusRowActual.push({
          rowSpan: 2,
          text: censusData.name
        });
        censusRowRatio.push('');
        censusRowActual.push({
          rowSpan: 2,
          text: censusTotal
        });
        censusRowRatio.push('');
        censusRowActual.push('Actual');
        censusRowRatio.push('Ratio');
        const ratio = {};
        for (let i = 0, count = censusData.extraStaff.length; i < count; i++) {
          if (censusData.extraStaff[i].count) {
            ratio[censusData.extraStaff[i].jobTypeId] = censusData.extraStaff[i].count;
          }
        }
        for (let i = 0, count = jobTypes.length; i < count; i++) {
          if (_.isEmpty(censusData.staffCount)) {
            censusRowActual.push('');
          } else {
            const jobTypeInfo = _.find(censusData.staffCount, (jobInfo) => jobInfo.id === jobTypes[i].id);
            censusRowActual.push(jobTypeInfo ? jobTypeInfo.value.length : '');
          }
          const value = [censusRatio[jobTypes[i].id] || '0'];
          if (ratio[jobTypes[i].id]) {
            value.push(ratio[jobTypes[i].id]);
          }
          censusRowRatio.push(value.join(' + '));
        }
        if (_.isEmpty(censusData.staffCount)) {
          censusRowActual.push('');
        } else {
          const sitterInfo = _.find(censusData.staffCount, (jobInfo) => jobInfo.id === 'sitter');
          censusRowActual.push(sitterInfo ? sitterInfo.value.length : '0');
        }
        censusRowRatio.push('');
        censusRowActual.push({
          rowSpan: 2,
          text: censusData.notes
        });
        censusRowRatio.push('');
        census.push(censusRowActual);
        census.push(censusRowRatio);
      }
      content.push({
        columns: [
          { width: '*', text: '' },
          {
            table: {
              widths,
              dontBreakRows: true,
              headerRows: 1,
              keepWithHeaderRows: true,
              body: [
                header,
                ...census
              ]
            },
            width: 'auto',
            alignment: 'center'
          },
          { width: '*', text: '' }
        ]
      });
    }

    return content;
  }

  /**
   * Gets columns object
   * @param {object} options Columns definition
   * @returns pdfmake document definition object
   */
  _getColumns (options) {
    const columns = [];
    const columnsDefinition = options.content;
    for (let i = 0, count = columnsDefinition.length; i < count; i++) {
      columns.push({
        width: columnsDefinition[i].width || '100%',
        stack: this._getStack(columnsDefinition[i].content)
      });
    }
    return {
      columns,
      margin: options.margin
    };
  }

  /**
   * Gets divider object
   * @param {object} options Divider definition
   * @returns pdfmake document definition object
   */
  _getDivider (options) {
    return {
      layout: 'divider',
      table: {
        body: [
          [
            {
              bold: true,
              border: [false, true, false, false],
              text: ''
            }
          ]
        ],
        widths: ['*']
      },
      margin: [0, 20]
    };
  }

  /**
   * Gets employees in the format specified by "format" in options. Defaults to stack.
   * @param {object} options Employee display and query options
   * @returns pdfmake document definition object
   */
  _getEmployees (options) {
    const content = [];
    const {
      format = 'stack',
      groupBy = [],
      label = '',
      text = ''
    } = options;
    const employees = this.adaptor.getEmployees(options);
    if (label) {
      content.push(this._getText({
        content: label,
        style: ['sectionLabel']
      }));
    }
    switch (format) {
      default:
        if (groupBy.length === 0) {
          for (let i = 0, count = employees.length; i < count; i++) {
            content.push(this._getText({
              content: formatText(text, employees[i])
            }));
          }
        }
    }
    return content;
  }

  /**
   * Gets daily schedule notes.
   * @param {object} options Notes options
   * @returns pdfmake document definition object
   */
  _getNotes (options) {
    if (this.store.getters['account/isStaff']()) {
      return [];
    }
    if (this.dailySchedule && this.dailySchedule.memos) {
      const memo = _.get(this.dailySchedule, ['memos', 0], null);
      if (memo && memo.access === 'public' && memo.notes) {
        return [
          { alignment: 'center', text: this.t('labels.scheduleNotes'), style: 'sectionLabel' },
          { alignment: 'left', text: memo.notes }
        ];
      }
    }
    return [{ alignment: 'center', text: this.t('descriptions.scheduleNotesEmpty') }];
  }

  /**
   * Gets shifts in the format specified by "format" in options. Defaults to stack.
   * @param {object} options Shifts display and query options
   * @returns pdfmake document definition object
   */
  _getShifts (options) {
    return [];
  }

  /**
   * Gets spacer object
   * @param {object} options Spacer definition
   * @returns pdfmake document definition object
   */
  _getSpacer (options) {
    return {
      text: '',
      margin: [0, 10]
    };
  }

  /**
   * Gets stack list
   * @param {array} items list of object definitions
   * @returns pdfmake document definition object
   */
  _getStack (items) {
    const stack = [];
    for (let i = 0, count = items.length; i < count; i++) {
      stack.push(this._getContent(items[i]));
    }
    return stack;
  }

  /**
   * Gets text object with styles
   * @param {object} options text content and sttyling options
   * @returns pdfmake document definition object
   */
  _getText (options) {
    return {
      text: options.content,
      style: options.style
    };
  }

  _getContent (contentDefinition) {
    switch (contentDefinition.type) {
      case 'acuity':
        return this._getAcuity(contentDefinition);
      case 'columns':
        return this._getColumns(contentDefinition);
      case 'divider':
        return this._getDivider(contentDefinition);
      case 'notes':
        return this._getNotes(contentDefinition);
      case 'text':
        return this._getText(contentDefinition);
      case 'spacer':
        return this._getSpacer(contentDefinition);
      case 'stack':
        return this._getStack(contentDefinition.content);
      case 'shifts':
        return this._getShifts(contentDefinition);
      case 'employees':
        return this._getEmployees(contentDefinition);
      default:
        return [];
    }
  }

  /**
   * Gets the base64 representation of the PDF.
   * @return {Promise} Resolves to the base64 string of the pdf file
   */
  getBase64 () {
    return new Promise((resolve, reject) => {
      try {
        const pdfDocGenerator = pdfMake.createPdf(this.definition);
        pdfDocGenerator.getBase64((data) => {
          resolve(data);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Gets the URL data representation of the PDF.
   * @return {Promise} Resolves to the URL data string of the pdf file
   */
  getDataUrl () {
    return new Promise((resolve, reject) => {
      try {
        const pdfDocGenerator = pdfMake.createPdf(this.definition);
        pdfDocGenerator.getDataUrl((data) => {
          resolve(data + '#toolbar=0&navpanes=0&scrollbar=0');
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  download () {
    const title = [
      this.department.name,
      this.t('descriptions.dailySchedulePDFTitle', { date: moment(this.date).format(this.store.getters['org/getDateFormatLongWithDoW']()) }),
      `(${this.type.name})`
    ].join(' ');
    pdfMake.createPdf(this.definition).download(title);
  }
}

export default DailySchedulePDF;
