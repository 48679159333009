var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mb-5",
      attrs: { elevation: _vm.hover ? 2 : 0, outlined: "" }
    },
    [
      _c(
        "v-list",
        { staticClass: "pa-0", attrs: { flat: "" } },
        [
          _c(
            "v-list-item-group",
            [
              _c(
                "v-list-item",
                {
                  attrs: { "two-line": "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("click", _vm.request)
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-10",
                      attrs: { color: _vm.style.color, size: "14" }
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.style.icon) + "\n        "
                      )
                    ]
                  ),
                  _c("v-list-item-content", [
                    _c(
                      "span",
                      { staticClass: "body-2 grey--text text--darken-3" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tc("labels.split", 1)) +
                            "\n          "
                        )
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "caption grey--text text--darken-2" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("labels.shiftRequester", {
                                requester: _vm.userDisplayName
                              })
                            ) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }