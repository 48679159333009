<template>
  <v-container
    class="requests pa-0"
    fluid
  >
    <v-row
      v-if="!retrievingHistoryRequests && !historyRequestsFiltered && !historyRequestCount"
      class="no-request"
      no-gutters
    >
      <v-col
        align-self="center"
        cols="12"
        md="10"
      >
        <v-row
          class="mb-4"
          justify="center"
        >
          <v-img
            contain
            max-width="120"
            src="@/assets/images/no-content-penguin.svg"
          />
        </v-row>
        <v-row
          class="mt-4"
          justify="center"
        >
          {{ $t('descriptions.noRequestHistory') }}
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row
      v-else
      no-gutters
    >
      <v-data-table
        v-model="selectedRows"
        v-resize.quiet="onWindowResized"
        class="elevation-1 history-requests"
        dense
        :expanded="expandedRows"
        :headers="historyRequestsHeaders"
        :header-props="{ sortIcon: 'fa fa-arrow-up' }"
        hide-default-footer
        :items="$store.state.request.historyRequests.records"
        :items-per-page="$store.state.request.pageSize"
        :loading="retrievingHistoryRequests"
        :loading-text="$t('descriptions.loadingRequests')"
        mobile-breakpoint=""
        must-sort
        :no-data-text="$t('descriptions.noRequestHistory')"
        :options.sync="historyRequestsOptions"
        :page.sync="historyRequestsCurrentPage"
        :server-items-length="$store.state.request.historyRequests.count"
        show-expand
        :single-select="true"
        @click:row="onItemClicked"
      >
        <template #header.createdBy>
          <StaffSearch
            v-model.trim="historyRequestsOptions.searchByName"
            :append-icon="historyRequestsOptions.searchByName ? '' : 'fal fa-search'"
            target-class="py-2 search-name"
            :clearable="!!historyRequestsOptions.searchByName"
            dense
            hide-details
            solo
          />
        </template>
        <template #header.deptIds="{ header }">
          <MultiSelectionList
            :selection="departments"
            @selectionConfirmed="onHistoryRequestFilterChanged(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ $tc('labels.department', 1) }}
                <v-icon
                  v-if="historyRequestsOptions.filterBy.deptIds"
                  class="ml-2 mt-1"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2 mt-1"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
        </template>
        <template #header.inferredType="{ header }">
          <MultiSelectionList
            :selection="requestTypes"
            @selectionConfirmed="onHistoryRequestFilterChanged(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ $t('labels.type') }}
                <v-icon
                  v-if="historyRequestsOptions.filterBy.inferredType"
                  class="ml-2 mt-1"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2 mt-1"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
        </template>
        <template #header.assocContentState="{ header }">
          <MultiSelectionList
            :selection="requestStatus"
            @selectionConfirmed="onHistoryRequestFilterChanged(header.value, $event)"
          >
            <template #activator="{ on }">
              <v-btn
                class="ml-n4 subtitle-2 text-capitalize"
                color="secondary"
                text
                v-on="on"
              >
                {{ $t('labels.status') }}
                <v-icon
                  v-if="historyRequestsOptions.filterBy.assocContentState"
                  class="ml-2 mt-1"
                  color="grey"
                  right
                  x-small
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  class="ml-2 mt-1"
                  color="grey"
                  right
                  x-small
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
          </MultiSelectionList>
        </template>
        <template #item.createdBy="{ item }">
          <v-list-item
            v-if="item.assocContent.contentType == 'event'"
            class="px-0"
          >
            <v-list-item-avatar class="mr-1">
              <v-avatar
                :color="$store.state.org.employees[item.assocContent.assigneeId].avatarBgColor"
                size="30"
              >
                <span class="white--text subtitle-2">
                  {{ getAvatar($store.state.org.employees[item.assocContent.assigneeId]) }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="body-2 mb-1 name-n-avatar">
                <UserName
                  :user="$store.state.org.employees[item.assocContent.assigneeId]"
                />
              </v-list-item-title>
              <v-list-item-subtitle class="caption mt-1">
                {{ getJobInfo(item) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else-if="item.assocContent.contentType == 'swap'"
            class="px-0"
          >
            <v-list-item-avatar class="mr-1">
              <v-avatar
                :color="$store.state.org.employees[item.assocContent.sourceUserId].avatarBgColor"
                size="30"
              >
                <span class="white--text subtitle-2">
                  {{ getAvatar($store.state.org.employees[item.assocContent.sourceUserId]) }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="body-2 mb-1 name-n-avatar">
                <UserName
                  :user="$store.state.org.employees[item.assocContent.sourceUserId]"
                />
              </v-list-item-title>
              <v-list-item-subtitle class="caption mt-1">
                {{ getJobInfo(item) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else-if="item.assocContent.contentType == 'shiftrequest'"
            class="px-0"
          >
            <v-list-item-avatar class="mr-1">
              <v-avatar
                :color="$store.state.org.employees[item.assocContent.assigneeId].avatarBgColor"
                size="30"
              >
                <span class="white--text subtitle-2">
                  {{ getAvatar($store.state.org.employees[item.assocContent.assigneeId]) }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="body-2 mb-1 name-n-avatar">
                <UserName
                  :user="$store.state.org.employees[item.assocContent.assigneeId]"
                />
              </v-list-item-title>
              <v-list-item-subtitle class="caption mt-1">
                {{ getJobInfo(item) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #item.deptIds="{ item }">
          <div class="text-capitalize type">
            {{ getDepartment(item) }}
          </div>
        </template>
        <template #item.inferredType="{ item }">
          <div class="text-capitalize type">
            {{ item.inferredType }}
          </div>
        </template>
        <template #item.assocContentState="{ item }">
          <v-tooltip
            v-if="isApproved(item)"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                class="not-clickable"
                color="success"
                size="16"
                v-bind="attrs"
                v-on="on"
              >
                fas fa-check-circle
              </v-icon>
            </template>
            <span class="body-2">
              {{ $t('labels.approved') }}
            </span>
          </v-tooltip>
          <v-tooltip
            v-if="isRejected(item)"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                class="not-clickable"
                color="error"
                size="16"
                v-bind="attrs"
                v-on="on"
              >
                fas fa-times-circle
              </v-icon>
            </template>
            <span class="body-2">
              {{ $t('labels.rejected') }}
            </span>
          </v-tooltip>
          <v-tooltip
            v-if="isWithDrawn(item)"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                class="not-clickable"
                color="darken-1 grey"
                size="16"
                v-bind="attrs"
                v-on="on"
              >
                fas fa-trash-undo-alt
              </v-icon>
            </template>
            <span class="body-2">
              {{ $t('labels.withdrawn') }}
            </span>
          </v-tooltip>
          <v-tooltip
            v-if="isExpired(item)"
            top
          >
            <template #activator="{ on, attrs }">
              <v-icon
                class="not-clickable"
                color="darken-1 grey"
                size="16"
                v-bind="attrs"
                v-on="on"
              >
                fas fa-clock
              </v-icon>
            </template>
            <span class="body-2">
              {{ $t('labels.expired') }}
            </span>
          </v-tooltip>
        </template>
        <template #item.createdOn="{ item }">
          {{ formatDateTime(item.createdOn) }}
        </template>
        <template #item.notificationReceipts="{ item }">
          <template v-for="(userId) in notificationReceiptUsers(item)">
            <v-tooltip
              v-if="receiptIsReadByUser(item, userId)"
              :key="`tooltip-${userId}`"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="info--text ml-1 not-clickable text--lighten-1"
                  size="16"
                  v-bind="attrs"
                  v-on="on"
                >
                  fas fa-user-check
                </v-icon>
              </template>
              <div class="body-2">
                {{ $store.state.org.employees[userId].fullName }}
              </div>
              <div class="caption">
                {{ formatDateTime(item.notificationReceipts[userId].lastReadOn) }}
              </div>
            </v-tooltip>
            <v-icon
              v-else
              :key="`tooltip-check-${userId}`"
              class="grey--text ml-1 not-clickable text--lighten-2"
              size="16"
            >
              fas fa-user-check
            </v-icon>
          </template>
        </template>
        <template #item.modifiedBy="{ item }">
          <div class="name">
            {{ $store.state.org.employees[item.modifiedBy].fullName }}
          </div>
        </template>
        <template #item.modifiedOn="{ item }">
          {{ formatDateTime(item.modifiedOn) }}
        </template>
        <template #no-data>
          <div class="body-2 grey--text mt-5 no-results text--darken-3 text-left">
            <div class="my-2 subtitle-2">
              {{ $t('descriptions.noResultsFound') }}
            </div>
            <v-divider />
            <div class="mb-2 mt-4">
              {{ $t('descriptions.emptyGridHelpTitle') }}
            </div>
            <div class="my-2">
              <ul>
                <li>{{ $t('descriptions.emptyGridHelpRemoveFilters') }}</li>
                <li>{{ $t('descriptions.emptyGridHelpShorterWords') }}</li>
                <li>{{ $t('descriptions.emptyGridHelpSpelling') }}</li>
              </ul>
            </div>
          </div>
        </template>
        <template #item.data-table-expand="{ expand, isExpanded, item }">
          <v-btn
            class="request-expand"
            icon
            small
            :style="{ visibility: isEventRequest(item) ? 'hidden' : 'visible' }"
            @click.stop.prevent="expand(!isExpanded)"
          >
            <v-icon
              small
            >
              {{ isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers: innerHeaders, item }">
          <td
            :colspan="innerHeaders.length"
          >
            <SwapRequestSummary
              v-if="isSwapRequest(item)"
              class="pl-11"
              :request="item"
            />
            <SplitRequestSummary
              v-else-if="isSplitRequest(item)"
              class="pl-11"
              :request="item"
            />
            <GiveawayRequestSummary
              v-else-if="isGiveawayRequest(item)"
              class="pl-11"
              :request="item"
            />
          </td>
        </template>
        <template #footer>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <div class="mobile pa-1 pagination text-center">
              <v-btn
                :disabled="retrievingHistoryRequests || historyRequestsCurrentPage === 1"
                icon
                @click="paginateHistoryRequests(-1)"
              >
                <v-icon small>
                  far fa-chevron-left
                </v-icon>
              </v-btn>
              <span class="body-2 mx-1">
                {{ historyRequestsCurrentPage }}
              </span>
              <span class="body-2 grey--text text--darken-2">/</span>
              <span class="body-2 mx-1 grey--text text--darken-2">
                {{ $store.state.request.historyRequests.pages }}
              </span>
              <v-btn
                :disabled="retrievingHistoryRequests || historyRequestsCurrentPage === $store.state.request.historyRequests.pages"
                icon
                @click="paginateHistoryRequests(1)"
              >
                <v-icon small>
                  far fa-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </template>
          <v-pagination
            v-else
            v-model="historyRequestsCurrentPage"
            class="ma-1"
            color="secondary"
            :disabled="retrievingHistoryRequests"
            :length="$store.state.request.historyRequests.pages"
            next-icon="far fa-chevron-right"
            prev-icon="far fa-chevron-left"
            :total-visible="maxPaginationControls"
          />
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import MultiSelectionList from '@/components/MultiSelectionList';
import StaffSearch from '@/components/StaffSearch';
import { showStatus } from '@/plugins/vue-notification';
import { CONTENT_TYPES } from '@/services/constants';
import { RequestCanceledError } from '@/services/errors';
import { getAvatar, SEARCH_INPUT_DEBOUNCE } from '@/utils';
import { REQUEST_STATES } from '@/views/scheduling/constants';
import Resize from 'vuetify/es5/directives/resize';
import UserName from '@/components/scheduling/UserName';
import GiveawayRequestSummary from '@/views/scheduling/requests/GiveawayRequestSummary';
import SplitRequestSummary from '@/views/scheduling/requests/SplitRequestSummary';
import SwapRequestSummary from '@/views/scheduling/requests/SwapRequestSummary';

export default {
  directives: {
    Resize
  },
  components: {
    GiveawayRequestSummary,
    MultiSelectionList,
    SplitRequestSummary,
    StaffSearch,
    SwapRequestSummary,
    UserName
  },
  data () {
    return {
      debouncingWsRequest: false,
      expandedRows: [],
      historyRequestsHeaders: [
        { sortable: false, text: '', value: 'createdBy' },
        { sortable: false, text: this.$tc('labels.department', 1), value: 'deptIds' },
        { sortable: false, text: this.$t('labels.type'), value: 'inferredType' },
        { align: 'center', sortable: false, text: this.$t('labels.status'), value: 'assocContentState' },
        { text: this.$t('labels.createdOn'), value: 'createdOn', width: 155 },
        { align: 'center', sortable: false, text: this.$t('labels.read'), value: 'notificationReceipts', width: 85 },
        { text: this.$t('labels.respondedBy'), value: 'modifiedBy' },
        { text: this.$t('labels.respondedOn'), value: 'modifiedOn', width: 155 },
        { text: '', value: 'data-table-expand' }
      ],
      historyRequestsOptions: {
        filterBy: {},
        page: 1,
        searchByName: '',
        sortBy: [ 'modifiedOn' ],
        sortDesc: [ true ]
      },
      maxPaginationControls: 7,
      nameFilter: '',
      retrievingHistoryRequests: false,
      selectedRows: []
    };
  },

  computed: {
    // There are certain Vuetify Data Table properties that we'd like to watch together. However Vuejs 2.x
    // doesn't support watching multiple properties for a single callback. All computed properties below
    // with 'Watchable' suffix are created as a workaround to this limitation.
    ...mapGetters('request', [
      'historyRequestCount'
    ]),
    departments () {
      return _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling).map(department => {
        return { label: department.name, value: department.id };
      });
    },
    historyRequestsCurrentPage: {
      get () {
        return this.$store.state.request.historyRequests.currentPage;
      },
      set (value) {
        this.$store.commit('request/set_current_page', value);
      }
    },
    historyRequestsFiltered () {
      let isFiltered = false;
      isFiltered |= !!this.historyRequestsOptions.searchByName;
      isFiltered |= !_.isEmpty(this.historyRequestsOptions.filterBy);
      return !!isFiltered;
    },
    historyRequestsOrderingWatchable () {
      return this.historyRequestsOptions.sortBy.reduce((orderingCriteria, value, idx) => {
        orderingCriteria[value] = this.historyRequestsOptions.sortDesc[idx];
        return orderingCriteria;
      }, {});
    },
    requestStatus () {
      return [
        {
          label: this.$t('labels.approved'),
          value: REQUEST_STATES.APPROVED
        },
        {
          label: this.$t('labels.expired'),
          value: REQUEST_STATES.EXPIRED
        },
        {
          label: this.$t('labels.rejected'),
          value: REQUEST_STATES.REJECTED
        },
        {
          label: this.$t('labels.withdrawn'),
          value: REQUEST_STATES.WITHDRAWN + ',' + REQUEST_STATES.WITHDRAWN_BEFORE_APPROVAL
        }
      ];
    },
    requestTypes () {
      // The UI presents a "flattened" list of various types of requests - all event types are listed out,
      // along side with swap requests and schedule requests. Therefore we are not using type ID to identify
      // then as there is no "swap" type and "schedule" type. The back-end API supports filtering request
      // using the name of the types within the current organization.
      const types = this.$store.state.org.eventTypes.map(eventType => {
        return { label: eventType.name, value: eventType.name.toLowerCase() };
      });
      types.push({ label: this.$t('labels.swap'), value: 'swap' });
      types.push({ label: this.$tc('labels.split', 1), value: 'split' });
      types.push({ label: this.$t('labels.giveaway'), value: 'giveaway' });

      return types;
    },
    ...mapState(['sidePanelOpened'])
  },
  watch: {
    'historyRequestsOptions.page': function () {
      // Page number is already updated in the store via setter function of the respective computed property.
      this.retrieveRequests();
    },
    'historyRequestsOptions.searchByName': function (value) {
      this.$store.commit('request/set_filter', { [this.historyRequestsHeaders[0].value]: value });
      if (value) {
        this.debouncingWsRequest = true;
        this.retrieveRequestsDebounced();
      } else {
        // When search box is cleared, retrieve data immediately so that the "no content penguin" won't flash.
        this.retrieveRequests();
      }
    },
    historyRequestsOrderingWatchable (newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue)) { // See the note here: https://vuejs.org/v2/api/#vm-watch
        this.$store.commit('request/set_history_requests_ordering', newValue);
        this.retrieveRequests();
      }
    }
  },
  mounted () {
    this.calcPageSize();
    this.retrieveRequests();
  },
  methods: {
    calcPageSize () {
      const topNavHeight = 48;
      const tabBarHeight = 48;
      const tableHeaderHeight = 55;
      const tdHeight = 63;
      const tableFooterHeight = 40;
      const bottomNavHeight = 56; // Mobile only
      const marginAndPadding = 50;
      let tbodyHeight;

      if (this.$vuetify.breakpoint.smAndDown) {
        tbodyHeight = window.innerHeight - (
          topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + bottomNavHeight + marginAndPadding
        );
      } else {
        tbodyHeight = window.innerHeight - (
          topNavHeight + tabBarHeight + tableHeaderHeight + tableFooterHeight + marginAndPadding
        );
      }

      const pageSize = Math.floor(tbodyHeight / tdHeight);
      if (pageSize !== this.$store.state.request.pageSize) {
        this.$store.commit('request/set_page_size', pageSize);
        return true;
      }
      return false;
    },
    getAvatar,
    getDepartment (item) {
      let propName = 'departmentId';
      if (item.inferredType === 'swap') {
        propName = 'sourceDepartmentId';
      }
      const deptId = _.get(item, ['assocContent', propName], null);
      let department = '';
      if (deptId) {
        department = this.$store.getters['org/getDepartmentById'](deptId, 'name');
      }
      return department;
    },
    getJobInfo (itemData) {
      let userInfo = '';
      switch (itemData.assocContent.contentType) {
        case 'event':
          userInfo = this.$store.state.org.employees[itemData.assocContent.assigneeId];
          break;
        case 'swap':
          userInfo = this.$store.state.org.employees[itemData.assocContent.sourceUserId];
          break;
        case 'shiftrequest':
          userInfo = this.$store.state.org.employees[itemData.assocContent.assigneeId];
          break;
      }
      return [userInfo.jobTypeName, userInfo.jobStatusShortCode].filter(Boolean).join(' ');
    },

    getReviewerName (itemData) {
      return _.get(this.$store.state.org.employees, [itemData.reviewerId, 'fullName'], '');
    },
    formatDateTime (originalDateTime) {
      const dateTimeFormat = this.$store.getters['org/getDateTimeFormatLong']();
      return moment(originalDateTime).format(dateTimeFormat);
    },
    isApproved (request) {
      return request.assocContentState === REQUEST_STATES.APPROVED;
    },
    isEventRequest (request) {
      const idx = this.$store.state.org.eventTypes.find(
        eventType => eventType.name.toLowerCase() === request.inferredType.toLowerCase()
      );

      return idx !== undefined;
    },
    isExpired (request) {
      const withdrawnStates = [ REQUEST_STATES.EXPIRED ];
      return withdrawnStates.includes(request.assocContentState);
    },
    isGiveawayRequest (request) {
      return request.inferredType === 'giveaway';
    },
    isRejected (request) {
      return request.assocContentState === REQUEST_STATES.REJECTED;
    },
    isSplitRequest (request) {
      return request.inferredType === 'split';
    },
    isSwapRequest (request) {
      return request.inferredType === 'swap';
    },
    isWithDrawn (request) {
      const withdrawnStates = [ REQUEST_STATES.WITHDRAWN, REQUEST_STATES.WITHDRAWN_BEFORE_APPROVAL ];
      return withdrawnStates.includes(request.assocContentState);
    },
    moment,
    notificationReceiptUsers (request) {
      let userIds = _.uniq(_.keys(_.get(request, ['notificationReceipts'], {})).map((id) => parseInt(id)));
      const contentType = _.get(request, 'assocContent.contentType', '');
      switch (contentType) {
        case CONTENT_TYPES.event:
          userIds = _.intersection(userIds, [request.assocContent.assigneeId]);
          break;
        case CONTENT_TYPES.swap:
          userIds = _.intersection(userIds, [request.assocContent.sourceUserId, request.assocContent.targetUserId]);
          break;
        case CONTENT_TYPES.shiftRequest:
          if (request.assocContent.requestType === 'split') {
            userIds = _.intersection(userIds, request.assocContent.splits.map((split) => split.assigneeId));
          } else if (request.assocContent.requestType === 'giveaway') {
            userIds = _.intersection(userIds, [request.assocContent.assigneeId, request.assocContent.newAssigneeId]);
          }
      }
      return userIds;
    },
    onItemClicked (itemData) {
      if (this.sidePanelOpened && _.get(this.selectedRows, '0.assocContent.id', null) === itemData.assocContent.id) {
        return;
      }
      this.selectedRows = [itemData];
      this.$store.dispatch('showRequestPanel', {
        id: itemData.assocContent.id,
        type: itemData.assocContent.contentType
      }, { root: true });
    },
    onHistoryRequestFilterChanged (columnName, criteria) {
      if (criteria.length) {
        this.$set(this.historyRequestsOptions.filterBy, columnName, criteria);
      } else {
        this.$delete(this.historyRequestsOptions.filterBy, columnName);
      }

      this.$store.commit('request/set_filter', { [columnName]: criteria });
      this.retrieveRequests();
    },
    onWindowResized () {
      if (this.$store.state.request.activeList === 'history' && this.calcPageSize()) {
        this.retrieveRequests();
      }
    },
    paginateHistoryRequests (increment) {
      let newPageNumber = this.historyRequestsCurrentPage + increment;
      if (newPageNumber >= 1 && newPageNumber <= this.$store.state.request.historyRequests.pages) {
        this.historyRequestsCurrentPage = newPageNumber;
      }
    },
    receiptIsReadByUser (request, userId) {
      const lastReadOn = _.get(request, ['notificationReceipts', userId, 'lastReadOn'], null);
      return !!lastReadOn;
    },
    retrieveRequests () {
      this.retrievingHistoryRequests = true;
      if (!this.historyRequestsOptions.searchByName) {
        this.expandedRows = [];
      }
      this.$store.dispatch('request/retrieveHistoryRequests').then(response => {
        if (this.historyRequestsOptions.searchByName) {
          for (let record of this.$store.state.request.historyRequests.records) {
            if (this.isGiveawayRequest(record)) {
              const targetUser = this.$store.state.org.employees[record.assocContent.newAssigneeId];
              if (targetUser) {
                const names = this.historyRequestsOptions.searchByName.split(' ').map((n) => n.trim().toLowerCase());
                const phoneNumber = this.historyRequestsOptions.searchByName.trim().replace(/[ ()-]/g, '');

                let matches = false;
                if (names.length === 1) {
                  matches = targetUser.alias.toLowerCase().includes(names[0]) || targetUser.firstName.toLowerCase().includes(names[0]) ||
                    targetUser.lastName.toLowerCase().includes(names[0]) || targetUser.phonePersonal.includes(phoneNumber);
                } else {
                  matches = targetUser.firstName.toLowerCase().includes(names[0]) ||
                    targetUser.lastName.toLowerCase().includes(names[1]) || targetUser.phonePersonal.includes(phoneNumber);
                }
                if (matches) {
                  this.expandedRows.push(record);
                }
              }
            } else if (this.isSplitRequest(record)) {
              for (let split of record.assocContent.splits) {
                const targetUser = this.$store.state.org.employees[split.assigneeId];
                if (targetUser && split.assigneeId !== record.assocContent.assigneeId) {
                  const names = this.historyRequestsOptions.searchByName.split(' ').map((n) => n.trim().toLowerCase());
                  const phoneNumber = this.historyRequestsOptions.searchByName.trim().replace(/[ ()-]/g, '');
                  let matches = false;
                  if (names.length === 1) {
                    matches = targetUser.alias.toLowerCase().includes(names[0]) || targetUser.firstName.toLowerCase().includes(names[0]) ||
                      targetUser.lastName.toLowerCase().includes(names[0]) || targetUser.phonePersonal.includes(phoneNumber);
                  } else {
                    matches = targetUser.firstName.toLowerCase().includes(names[0]) ||
                      targetUser.lastName.toLowerCase().includes(names[1]) || targetUser.phonePersonal.includes(phoneNumber);
                  }
                  if (matches) {
                    this.expandedRows.push(record);
                  }
                }
              }
            } else if (this.isSwapRequest(record)) {
              const targetUser = this.$store.state.org.employees[record.assocContent.targetUserId];
              if (targetUser) {
                const names = this.historyRequestsOptions.searchByName.split(' ').map((n) => n.trim().toLowerCase());
                const phoneNumber = this.historyRequestsOptions.searchByName.trim().replace(/[ ()-]/g, '');
                let matches = false;
                if (names.length === 1) {
                  matches = targetUser.alias.toLowerCase().includes(names[0]) || targetUser.firstName.toLowerCase().includes(names[0]) ||
                    targetUser.lastName.toLowerCase().includes(names[0]) || targetUser.phonePersonal.includes(phoneNumber);
                } else {
                  matches = targetUser.firstName.toLowerCase().includes(names[0]) ||
                    targetUser.lastName.toLowerCase().includes(names[1]) || targetUser.phonePersonal.includes(phoneNumber);
                }
                if (matches) {
                  this.expandedRows.push(record);
                }
              }
            }
          }
        }
      }).catch(error => {
        // Many non-dependent async operations on this page could trigger the retrieval of request data
        // (such as resizing window, receiving WebSocket notification, etc.). In theses cases is it okay
        // to ignore the error raised by WsProxy when it detected duplicate requests.
        if (!(error instanceof RequestCanceledError)) {
          const data = { error: _.get(error, 'response.data') };
          showStatus({ text: this.$t('descriptions.requestRetrieveHistoryFail'), type: 'error', data });
        }
      }).finally(() => {
        this.retrievingHistoryRequests = false;
        this.selectedRows = [];
      });
    },

    retrieveRequestsDebounced: _.debounce(function () {
      this.retrieveRequests();
      this.debouncingWsRequest = false;
    }, SEARCH_INPUT_DEBOUNCE)
  }
};
</script>

<style lang="scss">
.container.requests {
  height: 100%;

  > .container:not(.skeleton-loader) {
    height: 100%;

    > .row {
      height: 100%;

      .v-tabs {
        .v-tabs-bar {
          background-color: $page-background;

          .v-tab {
            width: 170px;
          }
        }

        .v-tabs-items {
          height: calc(100% - 24px);
          background: none;

          .v-window-item {
            height: 100%;

            .row.no-request  {
              background-color: white;
              height: calc(100% - 280px);
              min-height: 200px;;

              .spacer {
                background-color: $page-background;
              }
            }

            .v-data-table {
              &.pending-requests {
                table {
                  min-width: 600px;
                }
              }

              &.history-requests {
                table {
                  min-width: 1000px;
                }
              }

              thead {
                .v-input.search-name {
                  font-size: 14px;
                }

                th {
                  font-weight: 500;
                }
              }

              tbody {
                tr:last-child {
                  td {
                    border-bottom: thin solid #0000001f;
                  }
                }

                td {
                  .name-n-avatar {
                    max-width: 150px;
                  }

                  .name {
                    min-width: 110px;
                    max-width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;;
                    white-space: nowrap;
                  }

                  .type {
                    min-width: 70px;;
                  }
                }
              }

              .no-results {
                height: 250px;
              }

              .v-pagination {
                .v-pagination__item,
                .v-pagination__navigation {
                  font-size: 14px;
                  height: 30px;

                  &:focus {
                    outline: none;
                  }

                  .v-icon {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
