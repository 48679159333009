<template>
  <v-container class="px-0 py-4 shift-activity">
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid, passes }"
    >
      <div class="px-3">
        <v-row
          class="pb-4 pt-0"
          align="center"
          justify="center"
          no-gutters
        >
          <v-col
            cols="auto"
          >
            <v-switch
              v-model="shiftCopy.obligatory"
              class="pt-0 mt-1 d-inline-block obligatory-toggle"
              color="success"
              dense
              :disabled="disabled"
              hide-details
              inset
              @change="updateShiftOnChange"
            >
              <template v-slot:label>
                <span
                  class="mr-2 body-2 grey--text text--darken-3"
                  :title="$t('labels.obligatory')"
                >
                  {{ $t('labels.obligatory') }}
                </span>
                <v-tooltip
                  max-width="300px"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-icon
                      color="info"
                      x-small
                      v-bind="attrs"
                      v-on="on"
                    >
                      fal fa-question-circle
                    </v-icon>
                  </template>
                  <span class="body-2">
                    {{ $t('descriptions.obligatoryShiftCreate') }}
                  </span>
                </v-tooltip>
              </template>
            </v-switch>
          </v-col>
          <v-spacer />
          <v-tooltip
            v-if="!disabled"
            max-width="300px"
            top
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="grey lighten-3 ml-2 delete-shift"
                icon
                small
                v-bind="attrs"
                @click="removeShift"
                v-on="on"
              >
                <v-icon size="16">
                  fal fa-trash-alt
                </v-icon>
              </v-btn>
            </template>
            <span class="body-2">
              {{ $t('labels.deleteShift') }}
            </span>
          </v-tooltip>
        </v-row>
        <v-row
          align="center"
          class="grey--text text--darken-3 no-gutters pb-4 pt-0"
          justify="center"
        >
          <v-col
            cols="12"
          >
            <VeeSelect
              ref="selectShiftType"
              v-model="shiftCopy.typeId"
              class="d-inline-block mr-5 type-select"
              dense
              :disabled="disabled"
              hide-details
              item-text="name"
              item-value="id"
              :items="shiftTypes"
              :label="$t('labels.shift')"
              :menu-props="{ top: false, offsetY: true }"
              outlined
              :rules="{ required: true }"
              style="width: 100px"
              @change="updateShiftType"
            />
            <v-menu
              v-if="allowUpdatingShiftTime"
              :key="`start-${shiftStartTimeCount}`"
              ref="shiftStartTimeMenu"
              v-model="showShiftStartTime"
              close-on-content-click
              offset-y
              max-height="300px"
              max-width="100px"
              min-width="100px"
              :nudge-bottom="0"
              :nudge-left="0"
            >
              <template v-slot:activator="{ on, attrs }">
                <VeeTextField
                  ref="shiftStartTime"
                  v-model="shiftStartTimeDisplay"
                  v-mask="timeMask"
                  :autocomplete="false"
                  class="shift-time d-inline-block"
                  dense
                  :disabled="disabled || !shiftCopy.typeId"
                  hide-details
                  name="shiftStartTime"
                  outlined
                  :label="$t('labels.start')"
                  :rules="{ required: true, time: true, excluded: [shiftEndTimeDisplay] }"
                  v-bind="attrs"
                  @keyup="updateStartTime()"
                  v-on="on"
                />
              </template>
              <v-list
                dense
                flat
              >
                <v-list-item
                  v-for="time in startTimes"
                  :key="time.id"
                  class="caption"
                  :disabled="time.disabled"
                  :title="time.name"
                  @click.prevent="time.disabled ? null: updateStartTime(time.id)"
                >
                  <v-list-item-title>
                    {{ time.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <fieldset
              v-else
              class="nb-fieldset d-inline-flex"
            >
              <legend class="grey--text text--darken-2 caption-2">
                {{ $tc('labels.start', 1) }}
              </legend>
              <div class="body-2 pb-2 text-truncate grey--text">
                {{ getShiftDisplayTime(shiftCopy.startTime) }}
              </div>
            </fieldset>
            <span class="px-2">
              -
            </span>
            <v-menu
              v-if="allowUpdatingShiftTime"
              :key="`end-${shiftEndTimeCount}`"
              ref="shiftEndTimeMenu"
              v-model="showShiftEndTime"
              close-on-content-click
              offset-y
              max-height="300px"
              max-width="100px"
              min-width="100px"
              :nudge-bottom="0"
              :nudge-left="0"
            >
              <template v-slot:activator="{ on, attrs }">
                <VeeTextField
                  ref="shiftEndTime"
                  v-model="shiftEndTimeDisplay"
                  v-mask="timeMask"
                  :autocomplete="false"
                  class="shift-time d-inline-block"
                  dense
                  :disabled="disabled || !shiftCopy.typeId"
                  hide-details
                  name="shiftEndTime"
                  outlined
                  :label="$t('labels.end')"
                  :rules="{ required: true, time: true, excluded: [shiftStartTimeDisplay] }"
                  v-bind="attrs"
                  @keyup="updateEndTime()"
                  v-on="on"
                />
              </template>
              <v-list
                dense
                flat
              >
                <v-list-item
                  v-for="time in endTimes"
                  :key="time.id"
                  class="caption"
                  :disabled="time.disabled"
                  :title="time.name"
                  @click.prevent="time.disabled ? null: updateEndTime(time.id)"
                >
                  <v-list-item-title>
                    {{ time.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <fieldset
              v-else
              class="nb-fieldset d-inline-flex"
            >
              <legend class="grey--text text--darken-2 caption-2">
                {{ $tc('labels.end', 1) }}
              </legend>
              <div class="body-2 pb-2 text-truncate grey--text">
                {{ getShiftDisplayTime(shiftCopy.endTime) }}
              </div>
            </fieldset>
          </v-col>
        </v-row>
        <v-row class="grey--text text--darken-3 no-gutters pb-4 pt-0">
          <v-col
            cols="12"
          >
            <FlagSelection
              ref="selectShiftFlag"
              v-model="shiftCopy.flags"
              small-chips
              :has-flag-access="canShowFlag"
              class="shift-flags"
              dense
              :disabled="disabled"
              :filter="flagsFilter"
              hide-details
              item-text="shortCode"
              item-value="id"
              :items="shiftFlags"
              :label="$tc('labels.flag', 2)"
              multiple
              outlined
              :return-object="false"
              @change="updateShiftOnChange"
            />
          </v-col>
        </v-row>
        <v-row class="grey--text text--darken-3 no-gutters pb-3 pt-0">
          <v-col
            cols="auto"
          >
            <v-switch
              v-model="shiftCopy.sitter"
              class="pt-0 mt-1 d-inline-block sitter-toggle"
              color="success"
              dense
              :disabled="disabled"
              inset
              hide-details
              @change="updateShiftOnChange"
            >
              <template v-slot:label>
                <span
                  class="mr-2 body-2 grey--text text--darken-3"
                  :title="$t('labels.assignAsSitter')"
                >
                  {{ $t('labels.assignAsSitter') }}
                </span>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row
          v-if="shiftCopy.sitter"
          align="center"
          class="grey--text text--darken-3 no-gutters pb-4 pt-0"
          justify="center"
        >
          <v-col cols="4">
            <v-text-field
              v-model="shiftCopy.settings.sitter.room"
              class="sitter-details"
              clearable
              dense
              :disabled="disabled || !shiftCopy.sitter"
              hide-details
              :label="$t('labels.room')"
              outlined
              @click:clear="updateShiftOnChange"
              @keyup="updateShiftDelayed"
            />
          </v-col>
          <v-spacer />
          <v-col cols="7">
            <v-text-field
              v-model="shiftCopy.settings.sitter.reason"
              class="sitter-details"
              clearable
              dense
              :disabled="disabled || !shiftCopy.sitter"
              hide-details
              :label="$t('labels.reason')"
              outlined
              @click:clear="updateShiftOnChange"
              @keyup="updateShiftDelayed"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="canShowData()"
          class="no-gutters pb-2"
        >
          <Comments
            v-model="shiftCopy.comments"
            :auto-grow="true"
            class="body-2 mb-3"
            counter="1000"
            :disabled="disabled"
            :disclosure-hint="$t('descriptions.disclaimer')"
            maxlength="1000"
            outlined
            :placeholder="placeholder"
            rows="1"
            single-line
            :visibility-hint="$t('descriptions.commentVisibilityAll')"
            @keyup="updateShiftDelayed"
          />
          <Comments
            v-if="canShowInternal()"
            v-model="shiftCopy.internalComments"
            :auto-grow="true"
            class="body-2"
            counter="1000"
            :disabled="disabled"
            :disclosure-hint="$t('descriptions.disclaimer')"
            :label="$t('labels.internalComments')"
            maxlength="1000"
            mode="internal"
            outlined
            :placeholder="placeholder"
            rows="1"
            single-line
            :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
            @keyup="updateShiftDelayed"
          />
        </v-row>
        <v-row
          class="pb-4 pt-0"
          align="center"
          justify="center"
          no-gutters
        >
          <v-col
            cols="auto"
          >
            <v-switch
              v-model="shiftCopy.overtime"
              class="pt-0 mt-1 d-inline-block overtime-toggle"
              color="success"
              dense
              :disabled="disabled"
              hide-details
              inset
              @change="updateShiftOnChange"
            >
              <template v-slot:label>
                <span
                  class="mr-2 body-2 grey--text text--darken-3"
                  :title="$t('labels.overtime')"
                >
                  {{ $t('labels.overtime') }}
                </span>
              </template>
            </v-switch>
          </v-col>
          <v-spacer />
        </v-row>
        <v-row
          v-if="!disabled && allowSaving"
        >
          <v-col
            class="py-0"
            cols="6"
          >
            <v-btn
              block
              class="reset"
              color="primary"
              :disabled="saving"
              outlined
              @click="reset"
            >
              {{ $t('labels.reset') }}
            </v-btn>
          </v-col>
          <v-col
            class="py-0"
            cols="6"
          >
            <v-btn
              block
              class="save"
              color="accent"
              :disabled="invalid || saving"
              @click="passes(updateShift)"
            >
              <v-progress-circular
                v-if="saving"
                color="primary lighten-2"
                indeterminate
                size="22"
                width="2"
              />
              <span v-else>
                {{ $t('labels.save') }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </ValidationObserver>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { getAvatar } from '@/utils';
import { showStatus } from '@/plugins/vue-notification';
import { isWorkingShiftForValidation, wasShiftModified } from '@/utils/scheduling';
import { SHIFT_TIME_INTERVAL } from '@/views/scheduling/constants';
import Comments from '@/components/Comments';
import VeeTextField from '@/components/form_controls/VeeTextField';
import VeeSelect from '@/components/form_controls/VeeSelect';
import FlagSelection from '@/components/scheduling/FlagSelection';

const DeepDiff = require('deep-diff').diff;

export default {
  components: {
    Comments,
    FlagSelection,
    VeeSelect,
    VeeTextField
  },
  props: {
    allowSaving: {
      default: true,
      type: Boolean
    },
    allowUpdatingShiftTime: {
      default: true,
      type: Boolean
    },
    offset: {
      type: Number,
      required: true
    },
    shift: {
      type: Object,
      required: true
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    updateOnChange: {
      default: false,
      type: Boolean
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    const shiftCopy = _.cloneDeep(this.shift);
    const sitterInfo = _.get(shiftCopy, 'settings.sitter', {});
    if (_.isEmpty(sitterInfo)) {
      _.set(shiftCopy, 'settings.sitter', {
        reason: '',
        room: ''
      });
    }
    const shiftType = this.$store.getters['org/getShiftTypeById'](shiftCopy.typeId);
    if (shiftType) {
      if (!shiftCopy.startTime) {
        shiftCopy.startTime = shiftType.startTime;
      }
      if (!shiftCopy.endTime) {
        shiftCopy.endTime = shiftType.endTime;
      }
    } else {
      shiftCopy.startTime = '';
      shiftCopy.endTime = '';
    }

    return {
      originalShiftCopy: _.cloneDeep(shiftCopy),
      saving: false,
      shiftCopy,
      // These shift<XYZ>TimeCount properties are used to trigger a redraw on the shift time components
      // because the v-mask directive does not propagate model changes when the model is changed in the parent.
      // This is the github issue https://github.com/probil/v-mask/issues/530.
      shiftEndTimeCount: 0,
      shiftStartTimeCount: 0,
      shiftEndTimeDisplay: this.formatTime(shiftCopy.endTime),
      shiftStartTimeDisplay: this.formatTime(shiftCopy.startTime),
      showShiftEndTime: false, // Used to show/hide the end time menu for users to select a time
      showShiftStartTime: false // Used to show/hide the start time menu for users to select a time
    };
  },
  computed: {
    assigneeAvatar () {
      if (this.user.userId) {
        return getAvatar(this.$store.state.org.employees[this.user.userId]);
      }

      return '';
    },
    assigneeColor () {
      return _.get(this.$store.state.org.employees, [this.user.userId, 'avatarBgColor'], '');
    },
    assigneeName () {
      return _.get(this.$store.state.org.employees, [this.user.userId, 'fullName'], '');
    },
    createdOn () {
      const createdOn = {};
      const createdBy = _.get(this.$store.state.org.employees, [this.shiftCopy.createdBy, 'fullName'], '');
      const createdDate = moment(this.shiftCopy.createdOn).format(this.$store.getters['org/getDateTimeFormatLong']());
      if (createdBy) {
        createdOn.user = createdBy;
        createdOn.date = this.$t('descriptions.createdShift', { date: createdDate });
      }
      return createdOn;
    },
    departments () {
      return _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling);
    },
    disabled () {
      return this.readOnly || !this.$can('edit', 'template');
    },
    endTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.shiftCopy.startTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    },
    jobType () {
      return _.find(this.$store.state.org.jobTypes, (jobType) => {
        return jobType.id === this.user.jobTypeId;
      });
    },
    hasChanges () {
      const diff = DeepDiff(this.originalShiftCopy, this.shiftCopy);
      return diff && diff.length > 0;
    },
    lastUpdate () {
      let lastUpdate = {};
      const modifiedBy = _.get(this.$store.state.org.employees, [this.shiftCopy.modifiedBy, 'fullName'], '');
      const modifiedOn = moment(this.shiftCopy.modifiedOn).format(this.$store.getters['org/getDateTimeFormatLong']());
      if (wasShiftModified(this.shiftCopy) && modifiedBy) {
        lastUpdate.user = modifiedBy;
        lastUpdate.date = this.$t('descriptions.modifiedShift', { date: modifiedOn });
      }
      return lastUpdate;
    },
    payrollDate () {
      let payrollDate = '';
      if (this.shiftCopy.payrollDate && !moment(this.shiftCopy.payrollDate).isSame(moment(this.shiftCopy.date))) {
        payrollDate = moment(this.shiftCopy.payrollDate).format(this.$store.getters['org/getDateFormatLong']());
      }

      return payrollDate;
    },
    placeholder () {
      let placeholder = `${this.$t('labels.addCommentsPlaceholder')}`;
      if (this.readOnly && !this.shiftCopy.comments) {
        placeholder = '';
      }
      return placeholder;
    },
    shiftFlags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftName () {
      return this.getShiftTypeById(this.shiftCopy.typeId).name;
    },
    shiftTime () {
      const shiftType = this.getShiftTypeById(this.shiftCopy.typeId);
      return [_.split(shiftType.startTime, ':', 2).join(':'), _.split(shiftType.endTime, ':', 2).join(':')].join(' - ');
    },
    shiftTypes () {
      const shiftTypes = this.jobType ? _.filter(this.$store.state.org.shiftTypes, (shiftType) => {
        return _.indexOf(this.jobType.settings.associatedShiftTypes, shiftType.id) >= 0;
      }) : [];

      if (this.user.charge) {
        const chargeJobTypes = _.filter(this.$store.state.org.jobTypes, (jt) => {
          return _.get(jt, 'settings.isChargeNurse', false);
        });
        for (let jt of chargeJobTypes) {
          const associatedShiftTypes = _.get(jt, 'settings.associatedShiftTypes', []);
          for (let id of associatedShiftTypes) {
            if (this.shiftTypesById[id]) {
              shiftTypes.push(this.shiftTypesById[id]);
            }
          }
        }
      }

      return _.uniqBy(_.sortBy(shiftTypes, 'name'), 'id');
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    startTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.shiftCopy.endTime);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    }
  },
  watch: {
    shift: {
      handler (newShift) {
        const shiftCopy = _.cloneDeep(newShift);
        const sitterInfo = _.get(shiftCopy, 'settings.sitter', {});
        if (_.isEmpty(sitterInfo)) {
          _.set(shiftCopy, 'settings.sitter', {
            reason: '',
            room: ''
          });
        }
        const shiftType = this.$store.getters['org/getShiftTypeById'](shiftCopy.typeId);
        if (shiftType) {
          if (!shiftCopy.startTime) {
            shiftCopy.startTime = shiftType.startTime;
          }
          if (!shiftCopy.endTime) {
            shiftCopy.endTime = shiftType.endTime;
          }
        } else {
          shiftCopy.startTime = '';
          shiftCopy.endTime = '';
        }
        this.shiftCopy = shiftCopy;
        this.shiftStartTimeDisplay = this.formatTime(this.shiftCopy.startTime);
        this.shiftEndTimeDisplay = this.formatTime(this.shiftCopy.endTime);
        this.shiftStartTimeCount++;
        this.showShiftStartTime = false;
        this.shiftEndTimeCount++;
        this.showShiftEndTime = false;
      },
      deep: true
    },
    shiftCopy: {
      handler () {
        this.$emit('has-changes', this.hasChanges);
      },
      deep: true
    }
  },
  created () {
    this.updateShiftDelayedWrapper = _.debounce(() => {
      this.updateShiftOnChange();
    }, 1000);
  },
  mounted () {
    try {
      this.$refs.observer.validate();
    } catch {
      // Ignore validation errors. The ValidationObserver will prevent submission in case
      // there are form errors.
    }
    this.$emit('has-changes', this.hasChanges);
  },
  methods: {
    canShowData () {
      if (this.$store.getters['account/isStaff']()) {
        return this.user.userId === this.$store.state.account.userId;
      }
      return true;
    },
    canShowInternal () {
      return this.$store.getters['account/isOperationsManagement']();
    },
    canShowFlag (flagId) {
      let canShowFlag = !!this.shiftFlagsById[flagId];
      if (canShowFlag && this.$store.getters['account/isStaff']()) {
        if (this.user.userId !== this.$store.state.account.userId) {
          canShowFlag &= this.shiftFlagsById[flagId].visibleToOtherStaff;
        }
      }
      return canShowFlag;
    },
    isWorkingShiftForValidation,
    removeShift () {
      this.dispatch('scheduleTemplate/updateScheduleTemplate', {
        profileId: this.$store.state.org.employees[this.user.userId].id,
        offset: this.offset,
        activities: []
      }).then(() => {
        this.$emit('removed', _.cloneDeep(this.originalShiftCopy));
        showStatus({
          text: this.$t('descriptions.shiftDeleteSuccess')
        });
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.shiftDeleteFail'),
          type: 'error',
          data
        });
      });
    },
    dispatch (action, payload) {
      // This function is added mainly for easy of mocking during in unit tests.
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    flagsFilter (item, queryText) {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.shortCode.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    formatTime (time) {
      const [hour, minutes] = time.split(':');
      return `${hour}:${minutes}`;
    },
    getShiftDisplayTime (time) {
      if (time) {
        return _.split(time, ':', 2).join(':');
      }

      return time;
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    getTimes () {
      const times = [];
      let date = moment('00:00:00', 'HH:mm:ss');
      let end = moment('23:30:00', 'HH:mm:ss');
      while (date.isSameOrBefore(end)) {
        times.push({
          id: date.format('HH:mm:ss'),
          name: date.format('HH:mm')
        });
        date.add(SHIFT_TIME_INTERVAL, 'minutes');
      }
      return times;
    },
    isOnCallShiftType (shift) {
      const shiftType = this.$store.getters['org/getShiftTypeById'](shift.typeId);
      return shiftType.onCall;
    },
    moment,
    parseTime (time) {
      return `${time}:00`;
    },
    reset () {
      this.$dialog.confirm({
        body: this.$t('descriptions.continueResetChanges'),
        confirmText: this.$t('labels.reset'),
        cancelText: this.$t('labels.cancel'),
        title: this.$t('labels.continueResetChanges'),
        titleIcon: 'fal fa-exclamation-triangle'
      }, { persistent: true, width: 400 }).then(() => {
        this.shiftCopy = _.cloneDeep(this.originalShiftCopy);
        this.shiftStartTimeDisplay = this.formatTime(this.shiftCopy.startTime);
        this.shiftEndTimeDisplay = this.formatTime(this.shiftCopy.endTime);
        this.shiftStartTimeCount++;
        this.shiftEndTimeCount++;
      }).catch(() => {});
    },
    timeMask (value) {
      const hours = [
        /[0-2]/,
        value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/
      ];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2
        ? [...hours, ':', ...minutes]
        : hours;
    },
    updateEndTime (time) {
      const updateProps = () => {
        this.shiftEndTimeCount++;
        this.showShiftEndTime = false;
        // It's possible for users to manually enter the same time for start and end time which is invalid and the
        // time is not updated on the model, but if a user edits either one of them that will make the other time valid
        // and needs to be updated without requiring the user to trigger a "blur".
        if (this.$refs.shiftStartTime.valid) {
          this.shiftCopy.startTime = this.parseTime(this.shiftStartTimeDisplay);
          this.shiftStartTimeCount++;
          this.showShiftStartTime = false;
        }
        this.updateShiftOnChange();
      };
      if (time) {
        this.shiftCopy.endTime = time;
        this.shiftEndTimeDisplay = this.formatTime(time);
        updateProps();
      } else if (this.$refs.shiftEndTime.valid) {
        this.shiftCopy.endTime = this.parseTime(this.shiftEndTimeDisplay);
        updateProps();
      }
    },
    updateShift () {
      if (this.allowSaving && !this.saving) {
        this.saving = true;
        this.shiftCopy.flags = _.filter(this.shiftCopy.flags, _.isFinite);
        const shift = _.cloneDeep(this.shiftCopy);
        this.dispatch('scheduleTemplate/updateScheduleTemplate', {
          profileId: this.user.id,
          offset: this.offset,
          activities: [shift]
        }).then(() => {
          this.originalShiftCopy = _.cloneDeep(shift);
          this.shiftCopy = shift;
          this.$emit('updated', shift);
          showStatus({
            text: this.$t('descriptions.shiftSaveSuccess')
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.shiftSaveFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    updateShiftOnChange () {
      if (this.updateOnChange) {
        this.shiftCopy.flags = _.filter(this.shiftCopy.flags, _.isFinite);
        this.$emit('change', _.cloneDeep(this.shiftCopy));
      }
    },
    updateShiftType () {
      const shiftType = this.$store.getters['org/getShiftTypeById'](this.shiftCopy.typeId);
      this.shiftCopy.onCall = shiftType.onCall;
      if (this.allowUpdatingShiftTime) {
        this.shiftCopy.startTime = shiftType.startTime;
        this.shiftStartTimeDisplay = this.formatTime(this.shiftCopy.startTime);
        this.shiftStartTimeCount++;
        this.shiftCopy.endTime = shiftType.endTime;
        this.shiftEndTimeDisplay = this.formatTime(this.shiftCopy.endTime);
        this.shiftEndTimeCount++;
      }
      this.updateShiftOnChange();
    },
    updateShiftDelayed () {
      this.updateShiftDelayedWrapper();
    },
    updateStartTime (time) {
      const updateProps = () => {
        this.shiftStartTimeCount++;
        this.showShiftStartTime = false;
        // It's possible for users to manually enter the same time for start and end time which is invalid and the
        // time is not updated on the model, but if a user edits either one of them that will make the other time valid
        // and needs to be updated without requiring the user to trigger a "blur".
        if (this.$refs.shiftEndTime.valid) {
          this.shiftCopy.endTime = this.parseTime(this.shiftEndTimeDisplay);
          this.shiftEndTimeCount++;
          this.showShiftEndTime = false;
        }
        this.updateShiftOnChange();
      };
      if (time) {
        this.shiftCopy.startTime = time;
        this.shiftStartTimeDisplay = this.formatTime(time);
        updateProps();
      } else if (this.$refs.shiftStartTime.valid) {
        this.shiftCopy.startTime = this.parseTime(this.shiftStartTimeDisplay);
        updateProps();
      }
    }
  }
};
</script>

<style lang="scss">
@mixin schedule-details-btn($color, $bgColor) {
  background-color: $bgColor !important;
  border: none !important;
  border-radius: 4px !important;
  color: $color !important;
  height: 40px !important;
}

.shift-activity {
  .assignee {
    .v-btn:hover::before {
      opacity: 0;
    }
    .v-btn__content span {
      font-weight: 400 !important;
      text-transform: initial !important;
    }
  }
  .available {
    .v-label {
      color: map-get($grey, 'darken-3');
      font-size: 14px;
    }
    .v-messages__message {
      color: $error;
      font-size: 12px;
    }
  }
  .chip-avatar {
    height: 18px !important;
    min-width: 18px !important;
    width: 18px !important;
  }
  .shift-time {
    width: 100px;
  }
  .sitter-details {
    input {
      font-size: 14px;
    }
    .v-label {
      font-size: 14px;
    }
  }
}
</style>
