var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "schedule-overview px-8 py-0" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-address-card")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("labels.requestsOverview")) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "caption font-weight-medium mb-3" },
        [
          _vm.search
            ? [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$tc(
                        "labels.showingResultsCount",
                        _vm.pendingRequestsCount,
                        { count: _vm.pendingRequestsCount }
                      )
                    ) +
                    "\n    "
                )
              ]
            : [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("labels.totalCount", {
                        count: _vm.pendingRequestsCount
                      })
                    ) +
                    "\n    "
                )
              ]
        ],
        2
      ),
      _c("v-text-field", {
        staticClass: "pb-3",
        attrs: {
          "append-icon": _vm.search ? "" : "fal fa-search",
          clearable: !!_vm.search,
          dense: "",
          "hide-details": "",
          placeholder: _vm.$t("labels.searchByName") + "...",
          outlined: ""
        },
        model: {
          value: _vm.search,
          callback: function($$v) {
            _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "search"
        }
      }),
      _c(
        "div",
        { style: _vm.style, attrs: { id: "requestsOverviewContent" } },
        [
          _vm.pendingRequestsCount > 0
            ? _vm._l(_vm.pendingRequests, function(request, requestId) {
                return _c("v-hover", {
                  key: requestId,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var hover = ref.hover
                          return [
                            _c("RequestCard", {
                              attrs: {
                                hover: hover,
                                request: request,
                                "schedule-id": _vm.scheduleId
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("show-request", request)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              })
            : [
                _c(
                  "v-card",
                  { attrs: { outlined: "" } },
                  [
                    _c("NoContent", {
                      attrs: {
                        height: 140,
                        exciting: true,
                        width: 140,
                        message: _vm.$t("descriptions.noRequest")
                      }
                    })
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }