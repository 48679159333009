var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "census px-0 py-1" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title py-0 px-8" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-cloud-upload-alt")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("labels.inputCensus")) + "\n    ")
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-8 mb-5", attrs: { outlined: "" } },
        [
          _c(
            "v-container",
            { staticClass: "ratio py-4 px-8" },
            [
              _vm._l(_vm.jobTypeChunks, function(chunk, idx) {
                return [
                  _c(
                    "v-row",
                    { key: "label" + idx, staticClass: "pt-1" },
                    [
                      _c("span", { staticClass: "caption row-label" }),
                      _vm._l(chunk, function(jobType) {
                        return [
                          _c(
                            "span",
                            {
                              key: jobType.id,
                              staticClass: "caption header text-truncate",
                              attrs: { title: jobType.name }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(jobType.name) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-row",
                    { key: "onDuty" + idx, staticClass: "py-2" },
                    [
                      _c("span", { staticClass: "caption row-label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("labels.onDuty")) +
                            "\n          "
                        )
                      ]),
                      _vm._l(chunk, function(jobType) {
                        return [
                          _c(
                            "span",
                            {
                              key: "onDuty" + jobType.id + "-" + idx,
                              class: ["count", _vm.getOnDutyClass(jobType)]
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.censusjobTypeCount[jobType.id]) +
                                  "\n            "
                              )
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-row",
                    { key: "ratio" + idx, staticClass: "py-2" },
                    [
                      _c("span", { staticClass: "caption row-label" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("labels.ratio")) +
                            "\n          "
                        )
                      ]),
                      _vm._l(chunk, function(jobType) {
                        return [
                          !jobType.hideRatio
                            ? _c(
                                "span",
                                {
                                  key: "ratio" + jobType.id + "-" + idx,
                                  staticClass: "count"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.getRatio(jobType.id, true)) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  ),
                  idx < _vm.jobTypeChunks.length - 1
                    ? _c("v-divider", { key: "divider" + idx })
                    : _vm._e()
                ]
              }),
              _vm.hasHistoricalCount
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass:
                            "caption dense font-weight-medium mb-0 mt-4",
                          attrs: {
                            color: "info",
                            dense: "",
                            outlined: "",
                            text: "",
                            width: "100%"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-1 mr-3",
                              attrs: {
                                slot: "prepend",
                                color: "info",
                                size: "12"
                              },
                              slot: "prepend"
                            },
                            [
                              _vm._v(
                                "\n            fas fa-info-circle\n          "
                              )
                            ]
                          ),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("descriptions.historicalCensus")) +
                              "\n        "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _c(
            "v-container",
            { staticClass: "px-5" },
            [
              _c("v-select", {
                attrs: {
                  dense: "",
                  "hide-details": "",
                  items: _vm.censusList,
                  outlined: ""
                },
                model: {
                  value: _vm.selectedCensusName,
                  callback: function($$v) {
                    _vm.selectedCensusName = $$v
                  },
                  expression: "selectedCensusName"
                }
              })
            ],
            1
          ),
          _vm.activeCensus.model.settings.showTotalAcuityByClass &&
          _vm.activeCensus.model.settings.acuity
            ? _c(
                "v-container",
                { staticClass: "acuity px-8 pb-0" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-1" },
                    [
                      _c("span", { staticClass: "caption row-label" }),
                      _vm._l(_vm.activeCensus.model.settings.acuity, function(
                        acuity,
                        key
                      ) {
                        return _c(
                          "span",
                          {
                            key: key,
                            staticClass: "caption header text-truncate"
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(acuity.label) +
                                "\n        "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-2" },
                    [
                      _c("span", { staticClass: "caption row-label" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.acuity")) +
                            "\n        "
                        )
                      ]),
                      _vm._l(_vm.activeCensus.model.settings.acuity, function(
                        acuity,
                        key
                      ) {
                        return _c("v-text-field", {
                          key: key,
                          attrs: {
                            dense: "",
                            disabled: _vm.readOnly,
                            "hide-details": "",
                            outlined: ""
                          },
                          model: {
                            value: _vm.activeCensus.model["acuity" + key],
                            callback: function($$v) {
                              _vm.$set(
                                _vm.activeCensus.model,
                                "acuity" + key,
                                _vm._n($$v)
                              )
                            },
                            expression: "activeCensus.model[`acuity${key}`]"
                          }
                        })
                      })
                    ],
                    2
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-3" },
                    [
                      _c("span", { staticClass: "caption row-label" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("labels.total")) +
                            "\n        "
                        )
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          disabled: _vm.disabled,
                          "hide-details": "",
                          outlined: "",
                          placeholder: "" + _vm.activeCensusTotalAcuity
                        },
                        model: {
                          value: _vm.activeCensus.model.total,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.activeCensus.model,
                              "total",
                              _vm._n($$v)
                            )
                          },
                          expression: "activeCensus.model.total"
                        }
                      }),
                      _vm.activeCensus.model.total &&
                      _vm.activeCensus.model.total !==
                        _vm.activeCensusTotalAcuity
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { right: "", "max-width": "250px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-icon",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "ml-2 acuity-warning",
                                                attrs: {
                                                  color: "error",
                                                  size: "10"
                                                }
                                              },
                                              "v-icon",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              "\n              fas fa-exclamation-triangle\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                1041221914
                              )
                            },
                            [
                              _c("span", { staticClass: "body-2" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("descriptions.wrongAcuityTotal")
                                    ) +
                                    "\n          "
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.activeCensus.model.settings.showTotalAcuityByClass &&
          _vm.activeCensus.model.censusBySpecialty
            ? _c("v-divider")
            : _vm._e(),
          _vm.activeCensus.model.censusBySpecialty
            ? _vm._l(_vm.activeCensus.model.censusBySpecialty, function(
                specialty,
                idx
              ) {
                return _c(
                  "v-container",
                  { key: specialty.name, staticClass: "acuity pa-0" },
                  [
                    specialty.acuityBreakdown
                      ? [
                          _c(
                            "v-row",
                            { staticClass: "px-8 pt-3 pb-2" },
                            [
                              _c("span", { staticClass: "caption row-label" }),
                              _vm._l(
                                _vm.activeCensus.model.settings.acuity,
                                function(acuity, key) {
                                  return _c(
                                    "span",
                                    {
                                      key: key,
                                      staticClass:
                                        "caption header text-truncate"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(acuity.label) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                }
                              )
                            ],
                            2
                          ),
                          _c(
                            "v-row",
                            { staticClass: "px-8 pt-3 pb-2" },
                            [
                              _c("span", { staticClass: "caption row-label" }, [
                                _vm._v(_vm._s(specialty.name))
                              ]),
                              _vm._l(specialty.acuityBreakdown, function(
                                breakdown,
                                key
                              ) {
                                return _c("v-text-field", {
                                  key: key,
                                  attrs: {
                                    dense: "",
                                    disabled: _vm.disabled,
                                    "hide-details": "",
                                    outlined: ""
                                  },
                                  model: {
                                    value: breakdown.value,
                                    callback: function($$v) {
                                      _vm.$set(breakdown, "value", _vm._n($$v))
                                    },
                                    expression: "breakdown.value"
                                  }
                                })
                              })
                            ],
                            2
                          )
                        ]
                      : _vm._e(),
                    specialty.showAcuitySubtotal
                      ? _c(
                          "v-row",
                          { staticClass: "px-8 pt-3 pb-2" },
                          [
                            _c("span", { staticClass: "caption row-label" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    specialty.acuityBreakdown
                                      ? _vm.$t("labels.subtotal")
                                      : specialty.name
                                  ) +
                                  "\n          "
                              )
                            ]),
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                disabled: _vm.disabled,
                                "hide-details": "",
                                outlined: "",
                                placeholder: specialty.acuityBreakdown
                                  ? "" +
                                    _vm.getCensusSpecialtySubtotal(specialty)
                                  : ""
                              },
                              model: {
                                value: specialty.subtotal,
                                callback: function($$v) {
                                  _vm.$set(specialty, "subtotal", _vm._n($$v))
                                },
                                expression: "specialty.subtotal"
                              }
                            }),
                            specialty.acuityBreakdown &&
                            specialty.subtotal &&
                            specialty.subtotal !==
                              _vm.getCensusSpecialtySubtotal(specialty)
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { right: "", "max-width": "250px" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "ml-2 acuity-warning",
                                                      attrs: {
                                                        color: "error",
                                                        size: "10"
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                fas fa-exclamation-triangle\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "descriptions.wrongAcuityTotal"
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    idx !== _vm.activeCensus.model.censusBySpecialty.length - 1
                      ? _c("v-divider")
                      : _vm._e()
                  ],
                  2
                )
              })
            : _vm._e(),
          _vm.activeCensus.model.censusBySpecialty ||
          _vm.activeCensus.model.settings.showTotalAcuityByClass
            ? _c("v-divider", {})
            : _vm._e(),
          _c(
            "v-container",
            { staticClass: "acuity extra-staff px-8" },
            [
              _vm._l(_vm.activeCensus.model.extraStaff, function(
                extraStaff,
                index
              ) {
                return _c(
                  "v-row",
                  { key: extraStaff.jobTypeId, staticClass: "mb-2" },
                  [
                    _c(
                      "span",
                      {
                        key: extraStaff.jobTypeId + "-label",
                        staticClass: "caption row-label text-truncate",
                        attrs: {
                          title:
                            _vm.jobTypeLabelsById[extraStaff.jobTypeId].label
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.jobTypeLabelsById[extraStaff.jobTypeId].label
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _c("v-text-field", {
                      key: extraStaff.jobTypeId + "-input",
                      attrs: {
                        dense: "",
                        disabled: _vm.disabled,
                        "hide-details": "",
                        outlined: ""
                      },
                      model: {
                        value: extraStaff.count,
                        callback: function($$v) {
                          _vm.$set(extraStaff, "count", _vm._n($$v))
                        },
                        expression: "extraStaff.count"
                      }
                    }),
                    !_vm.disabled
                      ? _c(
                          "v-btn",
                          {
                            key: extraStaff.jobTypeId + "-delete",
                            staticClass: "ml-0 remove-resource",
                            attrs: { color: "error", icon: "", small: "" },
                            on: {
                              click: function($event) {
                                return _vm.removeResource(index)
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { "x-small": "" } }, [
                              _vm._v(
                                "\n            fal fa-trash-alt\n          "
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { right: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass:
                                            "ml-2 mt-n1 imblance-resource",
                                          style: {
                                            visibility: _vm.censusErrorMessage(
                                              extraStaff.jobTypeId
                                            )
                                              ? "visible"
                                              : "hidden"
                                          },
                                          attrs: { color: "error", size: "10" }
                                        },
                                        "v-icon",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n              fas fa-exclamation-triangle\n            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", { staticClass: "body-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.censusErrorMessage(extraStaff.jobTypeId)
                              ) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              }),
              _vm.jobTypeLabelsFiltered.length > 0 && !_vm.disabled
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            bottom: "",
                            "offset-y": "",
                            "close-on-click": "",
                            "min-width": "184px"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "caption grey--text text--darken-1 add-resource"
                                          },
                                          "span",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              "+ " +
                                                _vm.$t(
                                                  "labels.additionalResource"
                                                )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            490171844
                          )
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.jobTypeLabelsFiltered, function(item) {
                              return _c(
                                "v-list-item",
                                {
                                  key: item.id,
                                  on: {
                                    click: function($event) {
                                      return _vm.addResource(item)
                                    }
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item.label))
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        2
      ),
      _c("v-divider"),
      _c(
        "v-container",
        { staticClass: "px-8" },
        [
          _c("Comments", {
            staticClass: "py-2",
            attrs: {
              "auto-grow": true,
              counter: "1000",
              disabled: _vm.disabled,
              "disclosure-hint": _vm.$t("descriptions.disclaimer"),
              maxlength: "1000",
              outlined: "",
              placeholder: _vm.placeholder,
              rows: "6",
              "single-line": "",
              "visibility-hint": _vm.$t(
                "descriptions.commentVisibilitySchedulers"
              )
            },
            model: {
              value: _vm.activeCensus.model.notes,
              callback: function($$v) {
                _vm.$set(_vm.activeCensus.model, "notes", $$v)
              },
              expression: "activeCensus.model.notes"
            }
          })
        ],
        1
      ),
      !_vm.disabled
        ? _c(
            "v-row",
            { staticClass: "px-8" },
            [
              _c("v-spacer"),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "save",
                      attrs: {
                        block: "",
                        color: "accent",
                        disabled: _vm.saving || !_vm.hasChanges
                      },
                      on: { click: _vm.update }
                    },
                    [
                      _vm.saving
                        ? _c("v-progress-circular", {
                            attrs: {
                              color: "primary lighten-2",
                              indeterminate: "",
                              size: "22",
                              width: "2"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.save")) +
                                "\n        "
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }