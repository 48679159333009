<template>
  <v-container
    class="daily-schedule"
    fluid
  >
    <template v-if="retrievingSchedule && showSkeletonLoader">
      <v-container
        class="schedule"
        fluid
      >
        <v-row dense>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-col
              v-for="n in 2"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col
              v-for="n in 5"
              :key="n"
              cols="auto"
            >
              <v-skeleton-loader
                class="single-action"
                type="actions"
              />
            </v-col>
          </template>
          <v-spacer />
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-skeleton-loader
              class="no-action"
              type="table"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="!retrievingSchedule">
      <v-container
        fluid
      >
        <portal to="page-title">
          <template v-if="($vuetify.breakpoint.mdAndDown || showFinalizeButton) && !$vuetify.breakpoint.xl">
            <v-container class="schedule-header py-0 mx-0">
              <v-row justify="space-between">
                <v-col
                  align-self="center"
                  class="px-1"
                  cols="auto"
                >
                  <v-select
                    v-model="activeDailyScheduleType"
                    class="daily-schedule-picker subtitle-2"
                    dense
                    hide-details
                    item-text="name"
                    item-value="id"
                    :items="dailyScheduleTypes"
                    :menu-props="{ top: false, offsetY: true }"
                    outlined
                    :style="selectorsStyle"
                    return-object
                    @change="onDailyScheduleTypeChange"
                  >
                    <template v-slot:item="{ attrs, item, on }">
                      <v-list-item
                        class="daily-schedule-item"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  align-self="center"
                  class="px-0"
                  cols="auto"
                >
                  <v-row
                    align="center"
                    class="ml-1"
                  >
                    <DepartmentSelector
                      :style="selectorsStyle"
                    />
                  </v-row>
                </v-col>
                <template v-if="$vuetify.breakpoint.width <= ELLIPSIS_BREAKPOINT">
                  <v-spacer />
                  <v-col
                    align-self="center"
                    cols="auto"
                  >
                    <v-menu
                      min-width="200"
                      :offset-y="true"
                    >
                      <template
                        v-slot:activator="{ on }"
                      >
                        <v-btn
                          class="mx-1 account-icon"
                          icon
                          small
                          v-on="on"
                        >
                          <v-icon>
                            fal fa-ellipsis-v
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-if="showFinalizeButton"
                          :title="$t('labels.finalize')"
                          @click.prevent="finalizeScheduleChanges"
                        >
                          <v-list-item-icon>
                            <v-icon small>
                              fas fa-paper-plane
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('labels.finalize') }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click.prevent="shareDialogOpened = true"
                        >
                          <v-list-item-icon>
                            <v-icon small>
                              fas fa-share-alt
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>{{ $t('labels.share') }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          @click="toggleHelpPanel"
                        >
                          <v-list-item-action>
                            <v-icon small>
                              fal fa-info-circle
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-title>
                            {{ $t('labels.information') }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </template>
                <template v-else>
                  <v-col
                    align-self="center"
                    cols="auto"
                  >
                    <v-row
                      align="center"
                      class="ml-1"
                    >
                      <template v-if="!readOnly">
                        <v-tooltip
                          v-if="showFinalizeButton"
                          bottom
                          nudge-top="10"
                        >
                          <template #activator="{ on: tooltipOn, attrs }">
                            <v-btn
                              class="finalize mr-2"
                              color="secondary"
                              :disabled="finalizing"
                              icon
                              :title="$t('labels.finalize')"
                              v-bind="attrs"
                              v-on="{...tooltipOn, 'click': finalizeScheduleChanges}"
                            >
                              <v-progress-circular
                                v-if="finalizing"
                                color="primary lighten-2"
                                indeterminate
                                size="22"
                                width="2"
                              />
                              <v-icon
                                v-else
                                size="20"
                              >
                                fas fa-paper-plane
                              </v-icon>
                            </v-btn>
                          </template>
                          <span class="body-2">
                            {{ $t('labels.finalize') }}
                          </span>
                        </v-tooltip>
                        <span
                          v-else-if="showFinalizeMessage"
                          class="caption font-weight-medium"
                        >
                          {{ $vuetify.breakpoint.lgAndDown ? '': $t('descriptions.dailyScheduleFinalized') }}
                          <v-tooltip
                            max-width="320px"
                            bottom
                          >
                            <template #activator="{ on, attrs }">
                              <v-icon
                                class="mt-n1 ml-1"
                                color="info"
                                size="14"
                                v-bind="attrs"
                                v-on="on"
                              >
                                fal fa-question-circle
                              </v-icon>
                            </template>
                            <span>
                              {{ $t('descriptions.dailyScheduleFinalizedHint') }}
                            </span>
                          </v-tooltip>
                        </span>
                      </template>
                      <v-tooltip
                        bottom
                        nudge-top="10"
                      >
                        <template #activator="{ on: tooltipOn, attrs }">
                          <v-btn
                            class="mr-2"
                            color="secondary"
                            icon
                            :title="$t('labels.share')"
                            v-bind="attrs"
                            v-on="{...tooltipOn, 'click': () => shareDialogOpened = true }"
                          >
                            <v-icon
                              size="20"
                            >
                              fas fa-share-alt
                            </v-icon>
                          </v-btn>
                        </template>
                        <span class="body-2">
                          {{ $t('labels.share') }}
                        </span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                  <v-spacer />
                </template>
                <template v-if="scheduleStateIndicator && $vuetify.breakpoint.lgAndUp">
                  <v-col
                    align-self="center"
                    cols="auto"
                  >
                    <v-chip
                      :class="['font-weight-medium white--text', scheduleStateIndicator.stateLabelCssClass]"
                      small
                    >
                      {{ $t(scheduleStateIndicator.stateLabelKey) }}
                    </v-chip>
                  </v-col>
                  <v-spacer />
                </template>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <v-row
              class="schedule-header"
              justify="space-between"
            >
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-select
                  v-model="activeDailyScheduleType"
                  class="daily-schedule-picker subtitle-2"
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  :items="dailyScheduleTypes"
                  :menu-props="{ top: false, offsetY: true }"
                  outlined
                  style="max-width: 200px"
                  return-object
                  @change="onDailyScheduleTypeChange"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      class="daily-schedule-item"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.name }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <DepartmentSelector />
                </v-row>
              </v-col>
              <v-col
                align-self="center"
                cols="auto"
              >
                <v-row
                  align="center"
                  class="ml-1"
                >
                  <template v-if="!readOnly">
                    <v-btn
                      v-if="showFinalizeButton"
                      class="finalize mr-2"
                      color="secondary"
                      :disabled="finalizing"
                      :text="$vuetify.breakpoint.lgAndUp"
                      :icon="$vuetify.breakpoint.mdAndDown"
                      :title="$t('labels.finalize')"
                      @click.prevent="finalizeScheduleChanges"
                    >
                      <v-progress-circular
                        v-if="finalizing"
                        color="primary lighten-2"
                        indeterminate
                        size="22"
                        width="2"
                      />
                      <v-icon
                        v-else
                        size="20"
                      >
                        fas fa-paper-plane
                      </v-icon>
                      <span
                        v-if="$vuetify.breakpoint.lgAndUp"
                        class="ml-2"
                      >
                        {{ $t('labels.finalize') }}
                      </span>
                    </v-btn>
                    <span
                      v-else-if="showFinalizeMessage"
                      class="caption font-weight-medium"
                    >
                      {{ $vuetify.breakpoint.lgAndDown ? '': $t('descriptions.dailyScheduleFinalized') }}
                      <v-tooltip
                        max-width="320px"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-icon
                            class="mt-n1"
                            color="info"
                            size="14"
                            v-bind="attrs"
                            v-on="on"
                          >
                            fal fa-question-circle
                          </v-icon>
                        </template>
                        <span>
                          {{ $t('descriptions.dailyScheduleFinalizedHint') }}
                        </span>
                      </v-tooltip>
                    </span>
                  </template>
                  <v-btn
                    class="mr-2"
                    color="secondary"
                    :text="$vuetify.breakpoint.lgAndUp"
                    :icon="$vuetify.breakpoint.mdAndDown"
                    :title="$t('labels.share')"
                    @click.prevent="shareDialogOpened = true"
                  >
                    <v-icon
                      size="20"
                    >
                      fas fa-share-alt
                    </v-icon>
                    <span
                      v-if="$vuetify.breakpoint.lgAndUp"
                      class="ml-2"
                    >
                      {{ $t('labels.share') }}
                    </span>
                  </v-btn>
                </v-row>
              </v-col>
              <v-spacer />
              <template v-if="scheduleStateIndicator">
                <v-col
                  align-self="center"
                  cols="auto"
                >
                  <v-row
                    align="center"
                    class="ml-1"
                  >
                    <v-chip
                      :class="['font-weight-medium ml-5 white--text', scheduleStateIndicator.stateLabelCssClass]"
                      small
                    >
                      {{ $t(scheduleStateIndicator.stateLabelKey) }}
                    </v-chip>
                    <span
                      v-if="readOnly"
                      :class="['caption font-weight-medium ml-3 grey--text text--darken-1']"
                    >
                      {{ $t('labels.readOnlyMode') }}
                    </span>
                  </v-row>
                </v-col>
                <v-spacer />
              </template>
              <v-col
                align-self="center"
                class="pr-0"
                cols="auto"
              >
                <v-tooltip
                  bottom
                  nudge-top="10"
                >
                  <template #activator="{ on: tooltipOn, attrs }">
                    <v-btn
                      class="mx-1"
                      icon
                      v-bind="attrs"
                      v-on="{...tooltipOn, 'click': toggleHelpPanel}"
                    >
                      <v-icon color="primary">
                        fal fa-info-circle
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.information') }}
                  </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </template>
        </portal>
        <v-container
          class="body-container actions"
          fluid
        >
          <v-row
            align="center"
            no-gutters
            :style="[$vuetify.breakpoint.smAndDown ? {'text-align': 'center'} : {}]"
          >
            <v-col
              class="subtitle-2 font-weight-medium primary--text"
              cols="12"
              sm="6"
              md="4"
            >
              <span>
                {{ moment(date).format(dateFormatString) }}
              </span>
              <v-btn
                class="grey--text text--darken-3 mb-1"
                icon
                @click="loadNewContent(previousDay)"
              >
                <v-icon
                  color="primary"
                  size="16"
                >
                  far fa-chevron-left
                </v-icon>
              </v-btn>
              <v-menu
                ref="datePicker"
                v-model="showDatePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="primary--text mb-1"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      color="gray"
                      size="18"
                    >
                      far fa-calendar-day
                    </v-icon>
                  </v-btn>
                </template>
                <v-date-picker
                  :value="date"
                  @change="(newDate) => loadNewContent(setDate, [newDate])"
                >
                  <v-btn
                    color="primary"
                    :disabled="date === today"
                    outlined
                    small
                    @click="goToToday"
                  >
                    {{ $t('labels.today') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-btn
                class="grey--text text--darken-3 mb-1"
                icon
                @click="loadNewContent(nextDay)"
              >
                <v-icon
                  color="primary"
                  size="16"
                >
                  far fa-chevron-right
                </v-icon>
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="12"
              sm="6"
              md="8"
            >
              <div
                :class="['actions', $vuetify.breakpoint.smAndDown ? '' : 'float-right']"
              >
                <v-tooltip
                  v-if="$can('edit', 'shift') || $can('edit', 'event')"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-menu
                      min-width="150"
                      offset-y
                    >
                      <template v-slot:activator="{ on: menuOn }">
                        <v-btn
                          :class="actionStyles.addActivity.button.classes"
                          icon
                          value="staff"
                          v-bind="attrs"
                          v-on="{ ...on, ...menuOn }"
                        >
                          <v-icon
                            :class="actionStyles.addActivity.icon.classes"
                            size="16"
                          >
                            fal fa-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list
                        dense
                        class="py-0"
                      >
                        <v-list-item
                          v-if="$can('edit', 'shift')"
                          @click.prevent="setPanelName('addShift')"
                        >
                          <v-list-item-title class="body-2 font-weight-regular">
                            {{ $tc('labels.addShift', 1) }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="$can('edit', 'event')"
                          @click.prevent="setPanelName('addEvent')"
                        >
                          <v-list-item-title class="body-2 font-weight-regular">
                            {{ $tc('labels.addEvent', 1) }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.addActivity') }}
                  </span>
                </v-tooltip>
                <v-tooltip
                  v-if="$can('view', 'openShift')"
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-badge
                      avatar
                      class="badge-panel count"
                      color="error"
                      :content="openShiftCount"
                      overlap
                      :value="openShiftCount > 0"
                    >
                      <v-btn
                        :class="actionStyles.viewOpenShift.button.classes"
                        icon
                        value="viewOpenShift"
                        v-bind="attrs"
                        v-on="on"
                        @click="setPanelName('viewOpenShift')"
                      >
                        <v-icon
                          :class="actionStyles.viewOpenShift.icon.classes"
                          size="16"
                        >
                          fal fa-list
                        </v-icon>
                      </v-btn>
                    </v-badge>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.viewOpenShift') }}
                  </span>
                </v-tooltip>
                <v-tooltip
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-badge
                      avatar
                      class="badge-panel"
                      color="transparent"
                      overlap
                    >
                      <template v-slot:badge>
                        <v-icon
                          :class="['status', hasCensusErrors ? '' : 'no-errors']"
                          color="error"
                          size="12"
                        >
                          fas fa-exclamation-triangle
                        </v-icon>
                      </template>
                      <v-btn
                        :class="actionStyles.census.button.classes"
                        icon
                        value="census"
                        v-bind="attrs"
                        v-on="on"
                        @click="setPanelName('census')"
                      >
                        <v-icon
                          :class="actionStyles.census.icon.classes"
                          size="16"
                        >
                          fal fa-cloud-upload-alt
                        </v-icon>
                      </v-btn>
                    </v-badge>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.inputCensus') }}
                  </span>
                </v-tooltip>
                <v-tooltip
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :class="actionStyles.memos.button.classes"
                      icon
                      value="memos"
                      v-bind="attrs"
                      v-on="on"
                      @click="setPanelName('memos')"
                    >
                      <v-icon
                        :class="actionStyles.memos.icon.classes"
                        size="16"
                      >
                        fal fa-file-alt
                      </v-icon>
                    </v-btn>
                  </template>
                  <span class="body-2">
                    {{ $t('labels.notes') }}
                  </span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          class="body-container pa-0"
          fluid
        >
          <v-row
            dense
            no-gutters
          >
            <v-col
              cols="12"
            >
              <v-tabs
                v-model="selectedJobTypeTab"
                class="job-type-tabs"
                mobile-breakpoint="768"
                show-arrows
                slider-color="accent"
                slider-size="3"
              >
                <v-tab
                  v-for="(jobTypeGroup, index) in jobTypes"
                  :key="index"
                >
                  <template
                    v-for="(jobType, jobIndex) in jobTypeGroup"
                  >
                    <span
                      v-if="jobIndex > 0"
                      :key="`${jobType.id}-plus`"
                      class="ml-2"
                    >
                      +
                    </span>
                    <span
                      :key="`${jobType.id}-name`"
                      :class="jobIndex > 0 ? 'ml-2' : ''"
                    >
                      {{ jobType.name }}
                    </span>
                    <div
                      :key="`${jobType.id}-count`"
                      :class="['font-weight-bold job-count ml-2 px-1', ratioCount[jobType.id] && ratioCount[jobType.id] !== jobTypeCount[jobType.id] ? 'imbalance' : '']"
                    >
                      <span>
                        {{ jobTypeCount[jobType.id] }}
                      </span>
                    </div>
                    <div
                      v-if="jobType.id !== ALL_JOB_TYPES_ID && ratioCount[jobType.id] && ratioCount[jobType.id] !== jobTypeCount[jobType.id]"
                      :key="`${jobType.id}-ratio`"
                      :class="['font-weight-bold job-count ml-2 px-1 ratio']"
                    >
                      <span>
                        {{ ratioCount[jobType.id] }}
                      </span>
                    </div>
                  </template>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-data-table
            class="categories"
            dense
            fixed-header
            group-by="category"
            :headers="headers"
            :header-props="{ sortIcon: 'fa fa-arrow-up' }"
            :height="`${tableHeight}px`"
            hide-default-footer
            :items="filteredItems"
            :items-per-page="filteredItems.length"
            mobile-breakpoint=""
            :no-data-text="$t('descriptions.noShifts')"
            :sort-by="sort.columns"
            :sort-desc="sort.order"
            @update:sort-by="setSortBy"
            @update:sort-desc="setSortDirection"
          >
            <template #item="{ item: categoryItem }">
              <td :colspan="headers.length">
                <v-data-table
                  v-model="selectedRows"
                  :class="['schedule-items', selectedJobTypeTab === ALL_JOB_TYPES_ID || jobTypes[selectedJobTypeTab].length > 1 ? 'multiple' : 'single']"
                  dense
                  fixed-header
                  group-by="jobTypeIndex"
                  :headers="headers"
                  hide-default-footer
                  :items="categoryItem.activities"
                  :items-per-page="categoryItem.activities.length"
                  mobile-breakpoint=""
                  :no-data-text="$t('descriptions.noShifts')"
                  show-expand
                  single-select
                  @click:row="showItemDetails"
                >
                  <template #group.header="{ group: jobTypeIndex }">
                    <td
                      :colspan="headers.length"
                    >
                      <span
                        class="pl-1"
                      >
                        {{ getJobLabel(jobTypeIndex) }}
                      </span>
                      <div
                        class="font-weight-bold job-count ml-2 px-1 inner-table"
                      >
                        <span>
                          {{ categoryItem.category === categoryMapping.onDuty ? getJobCount(jobTypeIndex) : getCategoryCount(categoryItem.category, jobTypeIndex) }}
                        </span>
                      </div>
                    </td>
                  </template>
                  <template #item.assigneeId="{ item }">
                    <v-list-item class="px-0">
                      <v-list-item-avatar class="mr-1">
                        <v-avatar
                          :color="$store.state.org.employees[item.user.userId].avatarBgColor"
                          size="30"
                        >
                          <span class="white--text subtitle-2">
                            {{ getAvatar($store.state.org.employees[item.user.userId]) }}
                          </span>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          :class="['body-2 mb-1 name-n-avatar', item.user.state === ACCOUNT_STATE.active ? '' : 'grey--text']"
                          :title="item.user.fullName"
                        >
                          <span
                            class="d-inline-block text-truncate"
                            style="width: 134px;"
                          >
                            <UserName
                              :user="$store.state.org.employees[item.user.userId]"
                              @saved="updateNurseDetails"
                            />
                          </span>
                        </v-list-item-title>
                        <v-list-item-subtitle
                          :class="['caption mt-1', item.user.state === ACCOUNT_STATE.active ? '' : 'grey--text']"
                        >
                          {{ getJobInfo(item) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-tooltip
                        nudge-left="5"
                        right
                      >
                        <template #activator="{ on: tooltipOn, attrs }">
                          <v-btn
                            v-if="openedPanelName === 'nurse-details' && panels[0] && panels[0].userId === item.user.id"
                            class="info darken-1 staff-notes"
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="tooltipOn"
                            @click.prevent.stop="toggleNurseDetailsPanel(item)"
                          >
                            <v-icon
                              class="white--text"
                              size="10"
                            >
                              fal fa-sliders-h fa-inverse
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-else-if="item.user.scheduleNotes"
                            class="secondary lighten-4 staff-notes"
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="tooltipOn"
                            @click.prevent.stop="toggleNurseDetailsPanel(item)"
                          >
                            <v-icon
                              class="anchor--text"
                              size="10"
                            >
                              fal fa-sliders-h
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            class="grey lighten-3 staff-notes"
                            icon
                            x-small
                            v-bind="attrs"
                            v-on="tooltipOn"
                            @click.prevent.stop="toggleNurseDetailsPanel(item)"
                          >
                            <v-icon
                              class="grey--text text--darken-3"
                              size="10"
                            >
                              fal fa-sliders-h
                            </v-icon>
                          </v-btn>
                        </template>
                        <span class="body-2">
                          {{ $t('labels.viewStaffDetails') }}
                        </span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="getErrors(item.user).length > 0"
                        nudge-left="5"
                        right
                        max-width="300px"
                      >
                        <template #activator="{ on: tooltipOn, attrs }">
                          <v-icon
                            class="has-errors"
                            color="error"
                            x-small
                            v-bind="attrs"
                            v-on="tooltipOn"
                          >
                            fal fa-exclamation-triangle
                          </v-icon>
                        </template>
                        <span class="body-2">
                          {{ $t('descriptions.staffErrors') }}
                        </span>
                      </v-tooltip>
                    </v-list-item>
                  </template>
                  <template #item.typeId="{ item }">
                    <v-row
                      v-if="item.value && item.type === 'shift'"
                      align="center"
                      :class="['shift-type', 'pa-0', item.value.overtime ? 'error--text' : '']"
                      justify="center"
                      no-gutters
                      style="position: relative"
                    >
                      <template v-if="item.value.departmentId !== activeDepartment.id || hasDefaultShiftTime(item.value)">
                        <span v-if="item.value.departmentId !== activeDepartment.id">
                          {{ getDepartmentByID(item.value.departmentId).name }}
                        </span>
                        <template v-else>
                          <ScheduleSymbol
                            :symbol="getSymbol('shift', item)"
                            :entity="shiftTypes[item.value.typeId]"
                          />
                          <span v-if="item.value.obligatory">
                            *
                          </span>
                        </template>
                        <span
                          v-if="item.category === categoryMapping.nonDuty"
                          class="non-duty"
                        />
                      </template>
                      <template v-else>
                        <span
                          class="caption"
                        >
                          <span class="d-block">
                            {{ getShiftTime(item.value)[0] + (item.value.obligatory ? '*' : '') }}
                          </span>
                          <span class="d-block">
                            {{ getShiftTime(item.value)[1] }}
                          </span>
                        </span>
                        <span
                          v-if="item.category === categoryMapping.nonDuty"
                          class="non-duty custom-time"
                        />
                      </template>
                    </v-row>
                    <v-icon
                      v-if="item.value && item.type === 'shift' && wasShiftModifiedByManagement(item.value, $store.state)"
                      class="management"
                      color="nb-gold"
                      size="10"
                    >
                      fas fa-pencil
                    </v-icon>
                    <v-icon
                      v-if="item.value && item.type === 'shift' && hasDifferentPayrollDate(item)"
                      class="payroll-diff text--darken-3"
                      color="grey"
                      size="10"
                    >
                      fas fa-level-up
                    </v-icon>
                    <v-icon
                      v-if="item.value && item.request"
                      class="request"
                      color="warning"
                      size="10"
                    >
                      fas fa-circle
                    </v-icon>
                    <v-row
                      v-if="item.value && item.type === 'event'"
                      align-content="end"
                      no-gutters
                      style="height: 100%;"
                      :title="getEventType(item.value).name"
                    >
                      <ScheduleSymbol
                        class="ml-3 mb-1"
                        :symbol="{ symbolType: 'bar', color: getEventType(item.value).color, style: { height: '5px !important', width: '32px !important' } }"
                      />
                    </v-row>
                  </template>
                  <template #item.flags="{ item }">
                    <div
                      v-if="item.value && item.type === 'shift'"
                      class="text-truncate"
                      :style="{ width: `200px`}"
                    >
                      <v-chip
                        v-for="flagId in item.value.flags"
                        :key="flagId"
                        class="lighten-2 grey--text text--darken-3 flag-short-code mb-1 mr-1"
                        color="info"
                        small
                        :title="shiftFlagsById[flagId].name"
                      >
                        {{ shiftFlagsById[flagId].shortCode }}
                      </v-chip>
                    </div>
                  </template>
                  <template #item.comments="{ item, header }">
                    <span
                      v-if="item.value"
                      class="d-inline-block text-truncate"
                      :style="{ width: `${header.width - 32}px`}"
                      :title="item.value.comments"
                    >
                      {{ item.value.comments }}
                    </span>
                  </template>
                  <template #item.lastFloat="{ item, header }">
                    <span
                      v-if="item.value && lastFloat.users[item.value.assigneeId] && lastFloat.users[item.value.assigneeId].last"
                      class="d-inline-block text-truncate"
                      :style="{ width: `${header.width - 32}px`}"
                    >
                      {{ moment(lastFloat.users[item.value.assigneeId].last.payrollDate || lastFloat.users[item.value.assigneeId].last.date).format(DATE_FORMAT_US) }}
                    </span>
                  </template>
                  <template #item.lastCancel="{ item, header }">
                    <span
                      v-if="item.value && lastCanceled.users[item.value.assigneeId] && lastCanceled.users[item.value.assigneeId].last"
                      class="d-inline-block text-truncate"
                      :style="{ width: `${header.width - 32}px`}"
                    >
                      {{ moment(lastCanceled.users[item.value.assigneeId].last.payrollDate || lastCanceled.users[item.value.assigneeId].last.date).format(DATE_FORMAT_US) }}
                    </span>
                  </template>
                  <template #item.lastOvertime="{ item, header }">
                    <span
                      v-if="item.value && lastOvertime.users[item.value.assigneeId] && lastOvertime.users[item.value.assigneeId].last"
                      class="d-inline-block text-truncate"
                      :style="{ width: `${header.width - 32}px`}"
                    >
                      {{ moment(lastOvertime.users[item.value.assigneeId].last.payrollDate || lastOvertime.users[item.value.assigneeId].last.date).format(DATE_FORMAT_US) }}
                    </span>
                  </template>
                  <template #item.data-table-expand="{ expand, isExpanded }">
                    <v-btn
                      icon
                      small
                      @click.stop.prevent="expand(!isExpanded)"
                    >
                      <v-icon
                        small
                      >
                        {{ isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:expanded-item="{ headers: innerHeaders, item }">
                    <td
                      v-if="item.value"
                      :colspan="innerHeaders.length"
                    >
                      <v-container class="mx-0">
                        <v-row>
                          <v-col
                            class="pa-0"
                          >
                            <v-menu
                              min-width="250px"
                              :close-on-content-click="false"
                              offset-y
                            >
                              <template v-slot:activator="{ on, value }">
                                <v-btn
                                  class="title font-weight-regular text-capitalize schedule-dropdown px-2"
                                  :disabled="lastCanceled.loading"
                                  elevation="0"
                                  outlined
                                  style="width: 198px"
                                  v-on="on"
                                >
                                  <span class="body-2 font-weight-regular">
                                    {{ selectedLastCanceledScheduleText }}
                                  </span>
                                  <v-spacer />
                                  <v-icon
                                    class="text--darken-3"
                                    color="grey"
                                    size="16"
                                  >
                                    {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                                  </v-icon>
                                </v-btn>
                              </template>
                              <ScheduleSelection
                                :department="activeDepartment"
                                :selected-schedule-id="lastCanceled.schedule.id"
                                @select="setSelectedLastCanceledSchedule"
                              />
                            </v-menu>
                            <span class="ml-2 font-weight-medium">
                              {{ $t('labels.canceledDates') }}{{ lastCanceled.loading ? '' : ` (${getLastCanceledCount(item)})` }}:
                            </span>
                            <v-progress-circular
                              v-if="lastCanceled.loading"
                              class="ml-3"
                              color="primary lighten-2"
                              indeterminate
                              size="22"
                              width="2"
                            />
                            <template v-else>
                              <span v-if="!lastCanceled.users[item.value.assigneeId] || lastCanceled.users[item.value.assigneeId].shifts.length === 0">
                                {{ $t('labels.noRecords') }}
                              </span>
                              <span v-else>
                                {{ getDates(lastCanceled.users[item.value.assigneeId].shifts) }}
                              </span>
                            </template>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container class="mx-0 pt-0">
                        <v-row>
                          <v-col class="pa-0">
                            <v-menu
                              min-width="250px"
                              :close-on-content-click="false"
                              offset-y
                            >
                              <template v-slot:activator="{ on, value }">
                                <v-btn
                                  class="title font-weight-regular text-capitalize schedule-dropdown px-2"
                                  :disabled="lastFloat.loading"
                                  elevation="0"
                                  outlined
                                  style="width: 198px"
                                  v-on="on"
                                >
                                  <span class="body-2 font-weight-regular">
                                    {{ selectedLastFloatScheduleText }}
                                  </span>
                                  <v-spacer />
                                  <v-icon
                                    class="text--darken-3"
                                    color="grey"
                                    size="16"
                                  >
                                    {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                                  </v-icon>
                                </v-btn>
                              </template>
                              <ScheduleSelection
                                :department="activeDepartment"
                                :selected-schedule-id="lastFloat.schedule.id"
                                @select="setSelectedLastFloatSchedule"
                              />
                            </v-menu>
                            <span class="ml-3 font-weight-medium">
                              {{ $t('labels.floatDates') }}:
                            </span>
                            <v-progress-circular
                              v-if="lastFloat.loading"
                              class="ml-3"
                              color="primary lighten-2"
                              indeterminate
                              size="22"
                              width="2"
                            />
                            <template v-else>
                              <span v-if="!lastFloat.users[item.value.assigneeId] || lastFloat.users[item.value.assigneeId].shifts.length === 0">
                                {{ $t('labels.noRecords') }}
                              </span>
                              <span v-else>
                                {{ getDates(lastFloat.users[item.value.assigneeId].shifts) }}
                              </span>
                            </template>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container class="mx-0 pt-0">
                        <v-row>
                          <v-col class="pa-0">
                            <v-menu
                              min-width="250px"
                              :close-on-content-click="false"
                              offset-y
                            >
                              <template v-slot:activator="{ on, value }">
                                <v-btn
                                  class="title font-weight-regular text-capitalize schedule-dropdown px-2"
                                  :disabled="lastOvertime.loading"
                                  elevation="0"
                                  outlined
                                  style="width: 198px"
                                  v-on="on"
                                >
                                  <span class="body-2 font-weight-regular">
                                    {{ selectedLastOvertimeScheduleText }}
                                  </span>
                                  <v-spacer />
                                  <v-icon
                                    class="text--darken-3"
                                    color="grey"
                                    size="16"
                                  >
                                    {{ value ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
                                  </v-icon>
                                </v-btn>
                              </template>
                              <ScheduleSelection
                                :department="activeDepartment"
                                :selected-schedule-id="lastOvertime.schedule.id"
                                @select="setSelectedLastOvertimeSchedule"
                              />
                            </v-menu>
                            <span class="ml-3 font-weight-medium">
                              {{ $t('labels.overtimeDates') }}:
                            </span>
                            <v-progress-circular
                              v-if="lastOvertime.loading"
                              class="ml-3"
                              color="primary lighten-2"
                              indeterminate
                              size="22"
                              width="2"
                            />
                            <template v-else>
                              <span v-if="!lastOvertime.users[item.value.assigneeId] || lastOvertime.users[item.value.assigneeId].shifts.length === 0">
                                {{ $t('labels.noRecords') }}
                              </span>
                              <span v-else>
                                {{ getDates(lastOvertime.users[item.value.assigneeId].shifts) }}
                              </span>
                            </template>
                          </v-col>
                        </v-row>
                      </v-container>
                    </td>
                  </template>
                </v-data-table>
                <span
                  v-if="readOnly"
                  class="read-only-overlay"
                />
              </td>
            </template>
            <template #group.header="{ group, isOpen, toggle }">
              <td
                :ref="group"
                :colspan="headers.length"
                :class="isOpen ? 'expanded' : ''"
                @click="expandGroup(group, isOpen, toggle)"
              >
                <v-icon
                  v-if="isOpen"
                  color="secondary"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-down
                </v-icon>
                <v-icon
                  v-else
                  color="secondary"
                  dense
                  size="14"
                  style="width: 14px"
                >
                  fas fa-caret-right
                </v-icon>
                <span
                  class="secondary--text pl-1 text-capitalize"
                >
                  {{ getCategoryLabel(group) }}
                </span>
              </td>
            </template>
            <template #header.assigneeId>
              <StaffSearch
                v-model.trim="staffFilter"
                :append-icon="staffFilter ? '' : 'fal fa-search'"
                target-class="daily-search-staff py-3"
                :clearable="!!staffFilter"
                dense
                hide-details
                solo
              />
            </template>
          </v-data-table>
        </v-container>
        <v-dialog
          v-model="shareDialogOpened"
          persistent
          width="500"
        >
          <v-card id="shareScheduleDialog">
            <v-card-title
              class="primary--text subtitle-2 py-2"
            >
              {{ $t('labels.share') }}
              <v-spacer />
              <v-btn
                v-if="sharingSummaryState !== SHARING_SUMMARY_IN_PROGRESS"
                class="float-right"
                icon
                x-small
                @click="shareDialogOpened = false"
              >
                <v-icon>fal fa-times</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider class="mx-2" />
            <template v-if="loadingShareDialog">
              <v-row
                align="center"
                style="height: 540px"
              >
                <v-spacer />
                <v-col cols="6">
                  <v-row class="text-center">
                    <v-col class="text-center">
                      <v-progress-circular
                        color="info"
                        indeterminate
                        size="75"
                        width="6"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-center">
                      <span>{{ $t('descriptions.loading') }}</span>
                    </v-col>
                  </v-row>
                </v-col>
                <v-spacer />
              </v-row>
            </template>
            <template v-else>
              <v-card-text class="mt-4 pb-0">
                <div class="body-2">
                  {{ $t('descriptions.shareSummary') }}
                </div>
                <br>
                <v-card
                  class="px-0 lighten-5"
                  color="grey"
                  outlined
                >
                  <v-data-table
                    class="share-list mx-4"
                    dense
                    fixed-header
                    :headers="shareSummaryHeaders"
                    height="400"
                    hide-default-footer
                    :items="shareWithDepartments"
                    :items-per-page="shareWithDepartments.length"
                    mobile-breakpoint=""
                    single-select
                  >
                    <template #header.dailySchedule="{ header }">
                      <v-btn
                        v-if="shareCount[header.id] === shareWithDepartments.length"
                        :disabled="sharingSummaryState === SHARING_SUMMARY_IN_PROGRESS"
                        icon
                        :ripple="false"
                        height="12"
                        width="12"
                        @click="shareWithZeroDepartments(header.id)"
                      >
                        <v-icon
                          color="info"
                          size="14"
                        >
                          fas fa-check-square
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-else-if="shareCount[header.id] > 0"
                        :disabled="sharingSummaryState === SHARING_SUMMARY_IN_PROGRESS"
                        icon
                        :ripple="false"
                        height="12"
                        width="12"
                        @click="shareWithZeroDepartments(header.id)"
                      >
                        <v-icon
                          color="info"
                          size="14"
                        >
                          fas fa-minus-square
                        </v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        :disabled="sharingSummaryState === SHARING_SUMMARY_IN_PROGRESS"
                        icon
                        :ripple="false"
                        height="12"
                        width="12"
                        @click="shareWithAllDepartments(header.id)"
                      >
                        <v-icon
                          size="14"
                        >
                          fal fa-square
                        </v-icon>
                      </v-btn>
                      <span
                        class="body-2 grey--text text--darken-3 ml-2"
                        :style="{cursor: sharingSummaryState !== SHARING_SUMMARY_IN_PROGRESS ? 'pointer' : ''}"
                        @click="shareCount[header.id] > 0 ? shareWithZeroDepartments(header.id) : shareWithAllDepartments(header.id)"
                      >
                        {{ $t('labels.selectAll') }}
                      </span>
                    </template>
                    <template #item.department="{ item }">
                      <span
                        class="grey--text text--darken-3 dept-name d-inline-block text-truncate"
                        :title="item.name"
                      >
                        {{ item.name }}
                      </span>
                      <v-progress-linear
                        v-if="item.status === 'pending'"
                        class="share-progress"
                        color="nb-azure"
                        rounded
                        value="100"
                      />
                      <v-progress-linear
                        v-else-if="item.status === 'in_progress'"
                        class="share-progress"
                        indeterminate
                        color="secondary"
                        rounded
                      />
                      <template v-else-if="item.status === 'success'">
                        <v-progress-linear
                          class="share-progress"
                          color="secondary"
                          rounded
                          value="100"
                        />
                        <v-icon
                          class="ml-1 share-progress-status"
                          color="secondary"
                          size="10"
                        >
                          far fa-check
                        </v-icon>
                      </template>
                      <template v-else-if="item.status === 'failed'">
                        <v-progress-linear
                          class="share-progress"
                          color="error"
                          rounded
                          value="100"
                        />
                        <v-icon
                          class="ml-1 share-progress-status"
                          color="error"
                          size="10"
                        >
                          far fa-exclamation-triangle
                        </v-icon>
                      </template>
                    </template>
                    <template #item.dailySchedule="{ header, item }">
                      <v-simple-checkbox
                        v-model="item.dailySchedules[header.id].send"
                        class="extra-dense-checkbox d-inline-block daily-schedule-check"
                        color="info"
                        :disabled="sharingSummaryState === SHARING_SUMMARY_IN_PROGRESS"
                        :ripple="false"
                      />
                      <span
                        class="ml-2 grey--text text--darken-3 daily-schedule-label d-inline-block text-truncate"
                        :style="{cursor: sharingSummaryState !== SHARING_SUMMARY_IN_PROGRESS ? 'pointer' : ''}"
                        @click="toggleDailyScheduleShare(item, header)"
                      >
                        {{ header.text }}
                      </span>
                      <div class="grey--text caption-2 mt-n2">
                        {{ item.dailySchedules[header.id].lastShared }}
                        <v-tooltip
                          v-if="item.dailySchedules[header.id].lastShared"
                          right
                        >
                          <template #activator="{ on, attrs }">
                            <v-icon
                              color="grey"
                              size="10"
                              style="vertical-align: inherit;"
                              v-bind="attrs"
                              v-on="on"
                            >
                              fal fa-info-circle
                            </v-icon>
                          </template>
                          <span class="body-2">
                            {{ item.dailySchedules[header.id].lastSharedDetails }}
                          </span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-data-table>
                </v-card>
                <EmployeeSelector
                  class="mt-4"
                  :disabled="sharingSummaryState === SHARING_SUMMARY_IN_PROGRESS"
                  :filters="[ { op: 'in', prop: 'classification', value: ['ops_management'] }]"
                  :hint="$t('descriptions.emailAdditionalRecipients')"
                  :label="$t('labels.emailAdditionalRecipients')"
                  multiple
                  @change="updateAdditionalRecipients"
                />
                <v-progress-linear
                  v-if="shareWithAdditionalRecipients.status === 'pending'"
                  class="share-progress"
                  color="nb-azure"
                  rounded
                  value="100"
                />
                <v-progress-linear
                  v-else-if="shareWithAdditionalRecipients.status === 'in_progress'"
                  class="share-progress"
                  indeterminate
                  color="secondary"
                  rounded
                />
                <template v-else-if="shareWithAdditionalRecipients.status === 'success'">
                  <v-progress-linear
                    class="share-progress"
                    color="secondary"
                    rounded
                    value="100"
                  />
                  <v-icon
                    class="ml-1 share-progress-status"
                    color="secondary"
                    size="10"
                  >
                    far fa-check
                  </v-icon>
                </template>
                <template v-else-if="shareWithAdditionalRecipients.status === 'failed'">
                  <v-progress-linear
                    class="share-progress"
                    color="error"
                    rounded
                    value="100"
                  />
                  <v-icon
                    class="ml-1 share-progress-status"
                    color="error"
                    size="10"
                  >
                    far fa-exclamation-triangle
                  </v-icon>
                </template>
              </v-card-text>
              <v-card-actions class="px-6">
                <v-btn
                  v-if="failedSharingSummary"
                  class="my-3 px-4 mr-1"
                  text
                  @click="retryShareSummary"
                >
                  {{ $t('labels.retry') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  class="my-3 px-4 download-pdf"
                  color="primary"
                  :disabled="!selectedDepartmentsToShare || sharingSummaryState === SHARING_SUMMARY_IN_PROGRESS"
                  outlined
                  @click="downloadSummary"
                >
                  <span>
                    {{ $t('labels.downloadPDF') }}
                  </span>
                </v-btn>
                <v-btn
                  class="my-3 px-4"
                  color="accent"
                  :disabled="!selectedDepartmentsToShare || sharingSummaryState === SHARING_SUMMARY_IN_PROGRESS"
                  @click="emailSummary"
                >
                  <span>
                    {{ $t('labels.emailPDF') }}
                  </span>
                </v-btn>
              </v-card-actions>
            </template>
          </v-card>
        </v-dialog>
      </v-container>
      <SidePanel
        :panels="sidePanels"
      />
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState } from 'vuex';
import { DATE_FORMAT, DATE_FORMAT_US, getAvatar, getUnsavedChangesDialogProps } from '@/utils';
import {
  calculateStartOfWeek,
  filterData,
  getCensusRatio,
  groupData,
  isProductiveShift,
  isWorkingShiftForDisplay,
  isWorkingShiftForValidation,
  prepareLastDates,
  prepareShiftHours,
  sortData,
  sortAndGroupShifts,
  wasShiftModifiedByManagement
} from '@/utils/scheduling';
import { showStatus } from '@/plugins/vue-notification';
import Mousetrap from '@/plugins/mousetrap';
import Help from '@/views/scheduling/panels/Help';
import SidePanel from '@/components/SidePanel';
import DepartmentSelector from '@/components/DepartmentSelector';
import EmployeeSelector from '@/components/EmployeeSelector';
import ScheduleSymbol from '@/views/scheduling/ScheduleSymbol';
import NurseDetails from '@/views/scheduling/panels/NurseDetails';
import Census from '@/views/scheduling/panels/daily_schedule/Census';
import Notes from '@/views/scheduling/panels/daily_schedule/Notes';
import Details from '@/views/scheduling/panels/daily_schedule/Details';
import AddEvent from '@/views/scheduling/panels/daily_schedule/AddEvent';
import AddShift from '@/views/scheduling/panels/daily_schedule/AddShift';
import CreateOpenShift from '@/views/scheduling/open_shifts/Create';
import OpenShiftDetails from '@/views/scheduling/open_shifts/Details';
import OpenShiftsList from '@/views/scheduling/open_shifts/List';
import EventDetails from '@/views/scheduling/panels/EventDetails';
import EventRequest from '@/views/scheduling/requests/EventRequest';
import ShiftRequest from '@/views/scheduling/requests/ShiftRequest';
import SwapRequest from '@/views/scheduling/requests/SwapRequest';
import ScheduleSelection from '@/components/scheduling/ScheduleSelection';
import StaffSearch from '@/components/StaffSearch';
import UserName from '@/components/scheduling/UserName';
import * as Sentry from '@sentry/vue';
import { DAILY_SCHEDULE_STATES, SCHEDULE_STATES } from '@/views/scheduling/constants';
import { CONTENT_TYPES, ACCOUNT_STATE } from '@/services/constants';
import { getDailyScheduleDefaultOptions, getDailyScheduleTemplate } from '@/views/scheduling/pdf_templates/daily_schedule';
import { userMatchesText } from '@/utils/org';

export default {
  components: {
    DepartmentSelector,
    EmployeeSelector,
    ScheduleSelection,
    ScheduleSymbol,
    SidePanel,
    StaffSearch,
    UserName
  },
  props: {
    typeId: {
      default: null,
      type: [Number, String]
    },
    showSkeletonLoader: {
      default: true,
      type: Boolean
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.$can('view', 'dailySchedule')) {
        next('/page_not_found');
      }
    });
  },
  data () {
    const categoryMapping = {
      onDuty: 'a',
      onCall: 'b',
      sitter: 'c',
      nonDuty: 'e',
      floatOut: 'd'
    };
    const activeDepartment = this.$store.getters['org/getActiveDepartment']();
    const dailyScheduleTypes = _.sortBy(this.$store.state.org.dailyScheduleTypes, ['name']);

    let activeDailyScheduleType = dailyScheduleTypes[0];
    if (this.typeId) {
      const dailyScheduleType = _.find(dailyScheduleTypes, (d) => d.id === this.typeId);
      if (dailyScheduleType) {
        activeDailyScheduleType = dailyScheduleType;
      }
    }

    const shareWithDepartments = [];
    const departments = _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling);
    for (let i = 0, count = departments.length; i < count; i++) {
      const departmentItem = {
        dailySchedules: {},
        id: departments[i].id,
        name: departments[i].name,
        status: 'ready' // null, pending, in_progress, success, failed
      };
      for (let j = 0, dailyCount = dailyScheduleTypes.length; j < dailyCount; j++) {
        departmentItem.dailySchedules[dailyScheduleTypes[j].id] = {
          id: dailyScheduleTypes[j].id,
          send: departments[i].id === activeDepartment.id && dailyScheduleTypes[j].id === activeDailyScheduleType.id,
          lastShared: '',
          lastSharedDetails: ''
        };
      }
      shareWithDepartments.push(departmentItem);
    }

    const date = this.$store.state.scheduling.dailySchedule.date || moment().format(DATE_FORMAT);
    const SHARING_SUMMARY_READY = 'ready';
    let schedule = activeDepartment.schedulingPeriods[0];
    const scheduleForDate = _.find(activeDepartment.schedulingPeriods, (s) => date >= s.startOn && date <= s.endOn);
    if (scheduleForDate) {
      schedule = scheduleForDate;
    }

    let selectedJobTypeTab = 0;
    const user = this.$store.state.scheduling.dailySchedule.user;
    if (user) {
      const jobTypes = this.getJobTypes(activeDepartment);
      const userSelectedJobTypeTab = _.findIndex(jobTypes, (group) => {
        return _.findIndex(group, (job) => job.associatedJobTypes && job.associatedJobTypes.includes(user.jobTypeId)) >= 0;
      });
      if (userSelectedJobTypeTab >= 0) {
        selectedJobTypeTab = userSelectedJobTypeTab;
      }
      this.$store.commit('scheduling/set_daily_schedule_filters', { user: null });
    }
    return {
      ALL_JOB_TYPES_ID: 0,
      ACCOUNT_STATE,
      ELLIPSIS_BREAKPOINT: 600,
      DATE_FORMAT_US,
      SHARING_SUMMARY_READY,
      SHARING_SUMMARY_IN_PROGRESS: 'in_progress',
      SHARING_SUMMARY_FINISHED: 'finished',
      SHARING_SUMMARY_DOWNLOAD_TYPE: 'download',
      SHARING_SUMMARY_EMAIL_TYPE: 'email',
      activeDailyScheduleType,
      previousActiveDailyScheduleType: _.cloneDeep(activeDailyScheduleType),
      categoryMapping,
      census: [],
      dailySchedules: [],
      dailyScheduleTypes,
      date,
      finalizing: false,
      hasChanges: false,
      helpPanel: [],
      helpPanelData: {
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      },
      id: 0,
      lastCanceled: {
        loading: false,
        schedule,
        users: {}
      },
      lastFloat: {
        loading: false,
        schedule,
        users: {}
      },
      lastOvertime: {
        loading: false,
        schedule,
        users: {}
      },
      loadingShareDialog: true,
      mousetrap: new Mousetrap(this.$el),
      memos: [],
      openedPanelName: undefined,
      openShiftCount: 0,
      panels: [], // Opened right side panels
      retrievingSchedule: true, // Flag for paginating through different time period of the schedule.
      savedCensus: [],
      schedule: {
        id: null,
        state: ''
      },
      scheduleSymbols: this.$store.state.org.settings.scheduling.symbols,
      shareDialogOpened: false,
      shareOptions: getDailyScheduleDefaultOptions(
        _.get(this.$store.state.org.settings, 'scheduling.dailySchedule.templates.pdf', null)
      ),
      shareWithDepartments,
      shareWithAdditionalRecipients: {
        recipients: [],
        status: 'ready' // null, pending, in_progress, success, failed
      },
      sharingSummaryType: null,
      sharingSummaryState: SHARING_SUMMARY_READY,
      selectedJobTypeTab,
      selectedRows: [],
      showHelp: false,
      showDatePicker: false,
      showStaffNotes: false,
      staffFilter: '',
      state: 'draft',
      summary: {
        id: null,
        checkedById: null,
        checkedOn: null,
        date: this.date,
        hospitalId: this.$store.state.org.id
      },
      tableHeight: 500,
      today: moment().format(DATE_FORMAT)
    };
  },
  computed: {
    actionStyles () {
      const defaultButtonClasses = ['grey', 'lighten-2', 'mr-2'];
      const defaultIconClasses = ['grey--text', 'text--darken-3'];
      const styles = {
        addActivity: {
          button: {
            classes: defaultButtonClasses.concat(['add-activity'])
          },
          icon: {
            classes: defaultIconClasses
          }
        },
        census: {
          button: {
            classes: defaultButtonClasses.concat(['census'])
          },
          icon: {
            classes: defaultIconClasses
          }
        },
        memos: {
          button: {
            classes: defaultButtonClasses.concat(['memos'])
          },
          icon: {
            classes: defaultIconClasses
          }
        },
        viewOpenShift: {
          button: {
            classes: defaultButtonClasses.concat(['view-open-shift'])
          },
          icon: {
            classes: defaultIconClasses
          }
        }
      };

      if (this.memos.length > 0 && this.memos[0].notes) {
        styles.memos.button.classes = ['primary', 'lighten-2', 'memos', 'mr-2'];
        styles.memos.icon.classes = ['white--text'];
      }

      let openedPanelName = this.openedPanelName;
      if (['addShift', 'addEvent'].includes(openedPanelName)) {
        openedPanelName = 'addActivity';
      }

      if (openedPanelName && styles[openedPanelName]) {
        styles[openedPanelName].button.classes = ['primary', 'mr-2', openedPanelName];
        styles[openedPanelName].icon.classes = ['white--text'];
      }

      return styles;
    },
    activeDepartment () {
      return this.$store.getters['org/getActiveDepartment']();
    },
    allowsCensus () {
      const predefinedCensus = _.get(this.activeDailyScheduleType, 'settings.census.predefined', []);
      return predefinedCensus.length > 0;
    },
    allDepartments () {
      return this.$store.state.org.departments.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
    },
    associatedJobTypes () {
      const jobTypes = this.jobTypes[this.selectedJobTypeTab];
      const associatedJobTypes = [];
      if (jobTypes) {
        for (let i = 0, count = jobTypes.length; i < count; i++) {
          associatedJobTypes.push(...jobTypes[i].associatedJobTypes);
        }
      }
      return associatedJobTypes;
    },
    categories () {
      return [
        {
          expanded: true,
          key: this.categoryMapping.onCall,
          label: this.$t('labels.onCall'),
          match: (item) => isWorkingShiftForDisplay(item.value, this.shiftFlagsById) && item.value.onCall
        },
        {
          expanded: false,
          key: this.categoryMapping.nonDuty,
          label: this.$t('labels.nonDuty'),
          match: (item) => !item.value.available && !item.value.giveaway && !item.value.swapped && !isWorkingShiftForValidation(item.value, this.shiftFlagsById)
        },
        {
          expanded: true,
          key: this.categoryMapping.floatOut,
          label: this.$t('labels.floatOut'),
          match: (item) => {
            const hasFloatFlag = _.intersection(item.value.flags, this.floatFlagIds).length > 0;
            return hasFloatFlag && item.value.departmentId !== this.activeDepartment.id && isWorkingShiftForValidation(item.value, this.shiftFlagsById);
          }
        },
        {
          expanded: true,
          key: this.categoryMapping.sitter,
          label: this.$t('labels.sitter'),
          match: (item) => isWorkingShiftForValidation(item.value, this.shiftFlagsById) && item.value.sitter
        },
        {
          expanded: true,
          key: this.categoryMapping.onDuty,
          label: this.$t('labels.onDuty'),
          match: (item) => isWorkingShiftForValidation(item.value, this.shiftFlagsById) && item.value.departmentId === this.activeDepartment.id
        }
      ];
    },
    canEdit () {
      return this.$can('edit', 'dailySchedule');
    },
    chargeJobType () {
      return _.find(this.jobTypesById, (jt) => _.get(jt, 'settings.isChargeNurse', false));
    },
    chargeShiftTypes () {
      if (this.chargeJobType) {
        return _.get(this.chargeJobType, 'settings.associatedShiftTypes', []);
      }
      return null;
    },
    dateFormatShort () {
      return this.$store.getters['org/getDateFormatShort']();
    },
    dateFormatLong () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    departments () {
      return _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling);
    },
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    dateFormatStringLong () {
      return this.$store.getters['org/getDateTimeFormatLong']();
    },
    selectorsStyle () {
      const style = {};
      let extraWidth = this.showFinalizeButton ? 300 : 0;
      if (this.$vuetify.breakpoint.width < 530 + extraWidth) {
        style['max-width'] = '120px';
      } else if (this.$vuetify.breakpoint.width < 600 + extraWidth) {
        style['max-width'] = '150px';
      } else {
        style['max-width'] = '190px';
      }
      return style;
    },
    failedSharingSummary () {
      if (this.shareWithAdditionalRecipients.status === 'failed') {
        return true;
      }
      for (let i = 0, count = this.shareWithDepartments.length; i < count; i++) {
        const departmentInfo = this.shareWithDepartments[i];
        if (departmentInfo.status === 'failed') {
          return true;
        }
      }
      return false;
    },
    filteredItems () {
      const sortedItems = _.sortBy(this.items, [(item) => item.user.fullName]);
      const staffFilter = this.staffFilter ? this.staffFilter.toLowerCase() : '';
      const filteredItems = _.filter(sortedItems, (item) => {
        let matchesStaffFilter = true;
        if (staffFilter) {
          matchesStaffFilter = userMatchesText(item.user, staffFilter);
        }
        return _.indexOf(this.associatedJobTypes, item.user.jobTypeId) >= 0 && matchesStaffFilter;
      });

      const items = [];
      const itemsByCategory = {};
      for (let i = 0, count = filteredItems.length; i < count; i++) {
        const item = filteredItems[i];
        if (!itemsByCategory[item.category]) {
          itemsByCategory[item.category] = [];
        }
        itemsByCategory[item.category].push(item);
      }

      for (let categoryKey in itemsByCategory) {
        let activities = itemsByCategory[categoryKey];
        if (this.sort.columns.length > 0 && this.sort.order.length > 0) {
          const column = this.sort.columns[0];
          const iteratees = [
            (a) => {
              if (!a.value) {
                return '';
              }
              switch (column) {
                case 'lastCancel':
                  return _.get(this.lastCanceled.users, [a.value.assigneeId, 'last', 'date'], '');
                case 'lastFloat':
                  return _.get(this.lastFloat.users, [a.value.assigneeId, 'last', 'date'], '');
                case 'lastOvertime':
                  return _.get(this.lastOvertime.users, [a.value.assigneeId, 'last', 'date'], '');
                case 'typeId':
                  if (a.type === 'shift') {
                    return a.value.startTime;
                  } else {
                    return '';
                  }
                default:
                  return '';
              }
            }
          ];
          const orders = [this.sort.order[0] ? 'desc' : 'asc'];
          activities = _.orderBy(activities, iteratees, orders);
        }
        items.push({
          id: _.uniqueId(), // This ID is just for the data table to distinguish rows for selection.
          activities,
          category: categoryKey
        });
      }
      return items;
    },
    floatFlagIds () {
      return _.filter(this.$store.state.org.flags, f => f.float).map(f => f.id);
    },
    shiftFlagsById () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    hasCensusErrors () {
      for (let jobId in this.jobTypeCount) {
        if (this.ratioCount[jobId] && this.ratioCount[jobId] !== this.jobTypeCount[jobId]) {
          return true;
        }
      }
      return false;
    },
    headers () {
      return [
        { sortable: false, text: this.$t('labels.name'), value: 'assigneeId', width: 220 },
        { sortable: true, text: this.$t('labels.shift'), value: 'typeId', width: 54 },
        { sortable: false, text: this.$tc('labels.flag', 2), value: 'flags', width: 220 },
        { class: 'shift-column', sortable: false, text: this.$tc('labels.comment', 2), value: 'comments', width: 220 },
        { class: 'shift-column', sortable: true, text: this.$t('labels.lastFloat'), value: 'lastFloat', width: 150 },
        { class: 'shift-column', sortable: true, text: this.$t('labels.lastCancel'), value: 'lastCancel', width: 150 },
        { class: 'shift-column', sortable: true, text: this.$t('labels.lastOvertime'), value: 'lastOvertime', width: 150 },
        { text: '', value: 'data-table-expand' }
      ];
    },
    items () {
      const records = _.get(this.$store.state.scheduling.grids[this.schedule.id], ['records'], []);
      const items = [];
      const field = moment(this.date).valueOf();
      let item;
      for (let r of records) {
        const activities = _.get(r, [field, 'activities'], []);
        const request = _.get(r, [field, 'request'], null);
        for (let i = 0, len = activities.length; i < len; i++) {
          switch (activities[i].type) {
            case 'shift':
              item = this.getShiftItem(activities[i], r.user);
              if (item && this.activeDailyScheduleType.shiftTypes.includes(activities[i].typeId)) {
                items.push({
                  ...item,
                  request
                });
              }
              break;
            case 'event':
              items.push(this.getEventItem(activities[i], r.user));
              break;
          }
        }
      }
      return items;
    },
    jobStatusById () {
      return this.$store.state.org.jobStatus.reduce(
        (obj, jobStatus) => {
          obj[jobStatus.id] = jobStatus;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    jobTypeAssignees () {
      const jobTypeAssignees = {
        'sitter': []
      };
      const jobTypeMapping = {};
      for (let group = 0, groupCount = this.jobTypes.length; group < groupCount; group++) {
        for (let i = 0, count = this.jobTypes[group].length; i < count; i++) {
          const jobType = this.jobTypes[group][i];
          jobTypeAssignees[jobType.id] = [];
          const ids = String(jobType.id).split('|');

          for (let j = 0, idsCount = ids.length; j < idsCount; j++) {
            jobTypeMapping[ids[j]] = jobType.id;
          }
        }
      }
      const chargeShiftTypes = this.chargeShiftTypes;
      for (let i = 0, count = this.items.length; i < count; i++) {
        const item = this.items[i];
        if (item.category === this.categoryMapping.onDuty || item.category === this.categoryMapping.sitter) {
          let jobTypeId = item.user.jobTypeId;
          if (item.type === 'shift' && item.value && !isProductiveShift(item.value, this.shiftFlagsById)) {
            continue;
          }
          if (item.type === 'shift' && item.value && item.user.charge && chargeShiftTypes) {
            const associatedShiftTypes = _.get(this.jobTypesById[jobTypeId], 'settings.associatedShiftTypes', []);
            if (chargeShiftTypes.includes(item.value.typeId) && !associatedShiftTypes.includes(item.value.typeId)) {
              jobTypeId = this.chargeJobType.id;
            }
          }
          if (item.value.sitter) {
            jobTypeAssignees['sitter'].push(item.user.userId);
          } else if (jobTypeAssignees[jobTypeMapping[jobTypeId]]) {
            jobTypeAssignees[jobTypeMapping[jobTypeId]].push(item.user.userId);
          } else {
            if (!jobTypeAssignees[jobTypeId]) {
              jobTypeAssignees[jobTypeId] = [];
            }
            jobTypeAssignees[jobTypeId].push(item.user.userId);
          }
        }
      }
      return jobTypeAssignees;
    },
    jobTypeCount () {
      const jobTypeCount = {};
      let total = 0;
      for (let id in this.jobTypeAssignees) {
        jobTypeCount[id] = this.jobTypeAssignees[id].length;
        total += jobTypeCount[id];
      }
      jobTypeCount[this.ALL_JOB_TYPES_ID] = total;
      return jobTypeCount;
    },
    jobTypes () {
      return this.getJobTypes(this.activeDepartment);
    },
    jobTypesById () {
      return this.$store.state.org.jobTypes.reduce(
        (obj, jobType) => {
          obj[jobType.id] = jobType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    jobTypesUngrouped () {
      const jobTypes = [];
      // Start at 1 to exclude the all tab
      for (let i = 1, count = this.jobTypes.length; i < count; i++) {
        jobTypes.push(...this.jobTypes[i]);
      }
      return jobTypes;
    },
    maxDate () {
      const schedule = _.find(_.orderBy(this.activeDepartment.schedulingPeriods, ['startOn'], ['desc']), (s) => [SCHEDULE_STATES.CURRENT, SCHEDULE_STATES.PUBLISHED].includes(s.state));
      if (schedule) {
        return schedule.endOn;
      }

      return moment().add(7, 'd').format(DATE_FORMAT);
    },
    ratioCount () {
      let ratio = {};
      let latestCensus = null;
      for (let i = this.census.length - 1; i >= 0; i--) {
        const census = this.census[i];
        const acuities = _.keys(census.settings.acuity);
        if (census.settings.showTotalAcuityByClass) {
          if (census.total) {
            latestCensus = census;
          } else {
            for (let a = 0, aCount = acuities.length; a < aCount; a++) {
              if (census[`acuity${acuities[a]}`]) {
                latestCensus = census;
              }
            }
          }
        }
        if (census.censusBySpecialty) {
          const specialties = census.censusBySpecialty;
          for (let s = 0, sCount = specialties.length; s < sCount; s++) {
            if (specialties[s].showAcuitySubtotal && specialties[s].subtotal) {
              latestCensus = census;
            } else if (specialties[s].acuityBreakdown) {
              for (let a = 0, aCount = acuities.length; a < aCount; a++) {
                if (_.get(specialties[s].acuityBreakdown, [acuities[a], 'value'], null)) {
                  latestCensus = census;
                }
              }
            }
          }
        }
        if (census.extraStaff.length > 0) {
          latestCensus = census;
        }
        if (latestCensus) {
          break;
        }
      }
      if (latestCensus) {
        ratio = getCensusRatio(latestCensus, this.jobTypesUngrouped, this.$store.getters['org/getDailyScheduleTypeById'](this.activeDailyScheduleType.id));
        for (let i = 0, count = latestCensus.extraStaff.length; i < count; i++) {
          if (latestCensus.extraStaff[i].count) {
            if (!ratio[latestCensus.extraStaff[i].jobTypeId]) {
              ratio[latestCensus.extraStaff[i].jobTypeId] = 0;
            }
            ratio[latestCensus.extraStaff[i].jobTypeId] += latestCensus.extraStaff[i].count;
          }
        }
      }
      return ratio;
    },
    records: {
      cache: false,
      get: function () {
        return _.get(this.$store.state.scheduling.grids[this.schedule.id], ['records'], []);
      }
    },
    readOnly () {
      // Always allow editing shifts regardless of schedule state and date.
      return !this.$can('edit', 'dailySchedule');
    },
    schedulePeriods () {
      const schedulingPeriods = this.activeDepartment.schedulingPeriods;
      const periods = [];
      if (schedulingPeriods) {
        for (let i = 0, len = schedulingPeriods.length; i < len; i++) {
          const startFrom = moment(schedulingPeriods[i].startOn);
          const endBy = moment(schedulingPeriods[i].endOn);
          let text = '';
          if (endBy.isSame(startFrom, 'year')) {
            text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
          } else {
            // Display year info for both start and end date if the end date is in different year.
            text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
          }
          periods.push({
            text,
            value: schedulingPeriods[i].id,
            ...schedulingPeriods[i]
          });
        }
      }
      return periods;
    },
    scheduleStateIndicator () {
      if (!this.schedule.id) {
        return null;
      }
      const mobile = this.$vuetify.breakpoint.lgAndDown;
      const stateIndicator = {
        stateLabelCssClass: 'grey darken-1',
        stateLabelKey: mobile ? 'labels.scheduleStatePastAbbr' : 'labels.scheduleStatePast'
      };
      switch (this.schedule.state) {
        case SCHEDULE_STATES.PENDING_POST_APPROVAL:
        case SCHEDULE_STATES.PENDING_PUBLISH_APPROVAL:
          stateIndicator.stateLabelCssClass = 'error';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePendingApprovalDirectorAbbr' : 'labels.scheduleStatePendingApprovalDirector';
          break;

        case SCHEDULE_STATES.UNDER_NURSE_REVIEW:
          stateIndicator.stateLabelCssClass = 'info';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateUnderReviewNurseAbbr' : 'labels.scheduleStateUnderReviewNurse';
          break;

        case SCHEDULE_STATES.PUBLISHED:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStatePublishedAbbr' : 'labels.scheduleStatePublished';
          break;

        case SCHEDULE_STATES.DRAFT:
          stateIndicator.stateLabelCssClass = 'nb-orange';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateDraftAbbr' : 'labels.scheduleStateDraft';
          break;
        case SCHEDULE_STATES.SELF_SCHEDULE:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateSelfScheduleAbbr' : 'labels.scheduleStateSelfSchedule';
          break;
        case SCHEDULE_STATES.CURRENT:
          stateIndicator.stateLabelCssClass = 'success';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateCurrentAbbr' : 'labels.scheduleStateCurrent';
          break;
        case SCHEDULE_STATES.INITIAL:
          stateIndicator.stateLabelCssClass = 'nb-purple';
          stateIndicator.stateLabelKey = mobile ? 'labels.scheduleStateInitialAbbr' : 'labels.scheduleStateInitial';
          break;
      }

      return stateIndicator;
    },
    selectedDepartmentsToShare () {
      return _.sum(_.values(this.shareCount));
    },
    selectedLastCanceledScheduleText () {
      const startFrom = moment(this.lastCanceled.schedule.startOn);
      const endBy = moment(this.lastCanceled.schedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    selectedLastFloatScheduleText () {
      const startFrom = moment(this.lastFloat.schedule.startOn);
      const endBy = moment(this.lastFloat.schedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    selectedLastOvertimeScheduleText () {
      const startFrom = moment(this.lastOvertime.schedule.startOn);
      const endBy = moment(this.lastOvertime.schedule.endOn);
      let text = '';
      if (endBy.isSame(startFrom, 'year')) {
        text = startFrom.format(this.dateFormatShort) + ' - ' + endBy.format(this.dateFormatLong);
      } else {
        // Display year info for both start and end date if the end date is in different year.
        text = startFrom.format(this.dateFormatLong) + ' - ' + endBy.format(this.dateFormatLong);
      }
      return text;
    },
    shareCount () {
      const shareCount = {};
      for (let i = 0, count = this.dailyScheduleTypes.length; i < count; i++) {
        const id = this.dailyScheduleTypes[i].id;
        shareCount[id] = _.filter(this.shareWithDepartments, (item) => item.dailySchedules[id].send).length;
      }
      return shareCount;
    },
    shareSummaryHeaders () {
      const headers = [
        { sortable: false, text: '', value: 'department', width: 170 }
      ];
      for (let i = 0, count = this.dailyScheduleTypes.length; i < count; i++) {
        headers.push({
          sortable: false,
          text: this.dailyScheduleTypes[i].name,
          value: 'dailySchedule',
          id: this.dailyScheduleTypes[i].id
        });
      }
      return headers;
    },
    shiftTypes () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => (obj[shiftType.id] = shiftType, obj), // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    showFinalizeButton () {
      return this.$can('edit', 'dailySchedule') && this.schedule.id && [SCHEDULE_STATES.CURRENT, SCHEDULE_STATES.PUBLISHED].includes(this.schedule.state) && this.state !== DAILY_SCHEDULE_STATES.FINALIZED;
    },
    showFinalizeMessage () {
      return this.$can('edit', 'dailySchedule') && this.schedule.id && [SCHEDULE_STATES.CURRENT, SCHEDULE_STATES.PUBLISHED].includes(this.schedule.state) && this.state === DAILY_SCHEDULE_STATES.FINALIZED;
    },
    sidePanels () {
      const helpPanel = [{
        id: _.uniqueId(),
        component: Help,
        props: {},
        events: {
          close: () => {
            this.toggleHelpPanel();
          }
        }
      }];
      return this.showHelp ? helpPanel : this.panels;
    },
    sort () {
      return this.$store.state.scheduling.dailySchedule.sort;
    },
    storeSchedule () {
      return this.$store.state.scheduling.schedules[this.schedule.id];
    },
    userRecordMap () {
      return _.get(this.$store.state.scheduling.grids[this.schedule.id], ['userRecordMap'], {});
    },
    ...mapState(['sidePanelOpened'])
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit(
      'remove_ws_update_callback',
      'daily_schedule_page',
      { root: true }
    );
    next();
  },
  watch: {
    date () {
      this.showDatePicker = false;
      this.retrieveData();
      this.resetShareDialog();
      this.$store.commit('scheduling/set_daily_schedule_filters', { date: this.date });
    },
    activeDepartment () {
      this.hasChanges = false;
      this.retrievingSchedule = true;
      let schedule = this.activeDepartment.schedulingPeriods[0];
      const scheduleForDate = _.find(this.activeDepartment.schedulingPeriods, (s) => this.date >= s.startOn && this.date <= s.endOn);
      if (scheduleForDate) {
        schedule = scheduleForDate;
      }
      this.lastCanceled = {
        loading: false,
        schedule,
        users: {}
      };
      this.lastFloat = {
        loading: false,
        schedule,
        users: {}
      };
      this.retrieveData();
      this.resetShareDialog();
    },
    retrievingSchedule () {
      if (!this.retrievingSchedule) {
        this.$nextTick(() => {
          for (let i = 0, count = this.categories.length; i < count; i++) {
            const ref = this.$refs[this.categories[i].key];
            if (ref) {
              const expanded = ref.classList.contains('expanded');
              if (expanded !== this.categories[i].expanded) {
                this.$refs[this.categories[i].key].click();
              }
            }
          }
        });
      }
    },
    'lastCanceled.schedule' () {
      if (!this.retrievingSchedule) {
        this.retrieveCanceledData();
      }
    },
    'lastFloat.schedule' () {
      if (!this.retrievingSchedule) {
        this.retrieveFloatData();
      }
    },
    'lastOvertime.schedule' () {
      if (!this.retrievingSchedule) {
        this.retrieveOvertimeData();
      }
    },
    openedPanelName (openedPanelName) {
      if (openedPanelName) {
        switch (openedPanelName) {
          case 'addEvent':
            this.openCreateEventPanel();
            break;
          case 'addShift':
            this.openCreateShiftPanel();
            break;
          case 'census':
            this.openCensusPanel();
            break;
          case 'memos':
            this.openNotesPanel();
            break;
          case 'viewOpenShift':
            this.openOpenShiftsListPanel();
            break;
        }
      } else {
        this.closeAllPanels();
      }
    },
    selectedJobTypeTab () {
      this.refreshPanels();
    },
    shareDialogOpened () {
      this.sharingSummaryState = this.SHARING_SUMMARY_READY;
      for (let i = 0, count = this.shareWithDepartments.length; i < count; i++) {
        this.shareWithDepartments[i].status = null;
      }
      if (this.shareDialogOpened) {
        this.loadShareDialog();
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.updateDynamicHeights();
    });
    this.retrieveData();
    window.addEventListener('resize', this.updateDynamicHeights);

    this.$store.commit(
      'add_ws_update_callback',
      { name: 'daily_schedule_page', callback: this.onWsUpdate },
      { root: true }
    );
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateDynamicHeights);
  },
  methods: {
    has: _.has,
    closeAllPanels () {
      this.panels.splice(0, this.panels.length);
      this.openedPanelName = undefined;
    },
    closeLastPanel () {
      this.panels.pop();
    },
    closePanels (start, count) {
      this.panels.splice(start, count);
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    expandGroup (group, isOpen, toggle) {
      const category = _.find(this.categories, (category) => category.key === group);
      if (category) {
        category.expanded = !isOpen;
      }
      toggle();
    },
    finalizeScheduleChanges () {
      this.finalizing = true;
      let action = '';
      let payload = {};
      if (!this.id) {
        action = 'scheduling/createDailySchedule';
        payload = {
          date: this.date,
          departmentId: this.activeDepartment.id,
          typeId: this.activeDailyScheduleType.id,
          state: DAILY_SCHEDULE_STATES.FINALIZED
        };
      } else if (this.id > 0) {
        action = 'scheduling/updateDailySchedule';
        payload = {
          id: this.id,
          data: {
            state: DAILY_SCHEDULE_STATES.FINALIZED
          }
        };
      } else {
        this.finalizing = false;
        return;
      }

      this.$dialog.confirm({
        title: this.$t('labels.finalizeCurrentSchedule'),
        subtitle: '',
        bodyIcon: '',
        body: this.$t('descriptions.finalizeCurrentSchedule'),
        confirmText: this.$t('labels.finalize'),
        cancelText: this.$t('labels.cancel')
      }, { persistent: true, width: 400 }).then(() => {
        this.dispatch(action, payload).then((dailySchedule) => {
          this.id = dailySchedule.id;
          this.state = dailySchedule.state;

          showStatus({
            text: this.$t('descriptions.dailyScheduleFinalizedSuccess')
          });
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$t('descriptions.dailyScheduleFinalizedFail'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.finalizing = false;
        });
      }).catch(() => {
        this.finalizing = false;
      });
    },
    findEventIndexById (id) {
      return _.findIndex(this.items, (item) => {
        return item.type === 'event' && item.value.id === id;
      });
    },
    findShiftById (userId, id) {
      const row = _.get(this.records, [this.userRecordMap[userId]], {});
      for (let prop in row) {
        const timestamp = moment(parseInt(prop));
        if (!timestamp.isValid()) {
          continue;
        }

        const shift = _.find(_.get(row[prop], 'activities', []), (a) => {
          return a.type === 'shift' && a.id === id;
        });
        if (shift) {
          return shift;
        }
      }
      return null;
    },
    findShiftIndexById (id) {
      return _.findIndex(this.items, (item) => {
        return item.type === 'shift' && item.value.id === id;
      });
    },
    generatePDF (department, dailySchedule, dailyScheduleType, shifts, usersInfo) {
      return new Promise((resolve, reject) => {
        try {
          const DailySchedulePDFTemplate = getDailyScheduleTemplate(
            _.get(this.$store.state.org.settings, 'scheduling.dailySchedule.templates.pdf', null)
          );
          const sharedBy = this.$store.state.org.employees[this.$store.state.account.userId].fullName;
          const dailySchedulePDF = new DailySchedulePDFTemplate(
            dailySchedule,
            this.date,
            department,
            dailyScheduleType,
            this.$store,
            (...args) => this.$t(...args),
            (...args) => this.$tc(...args),
            {
              getEmployees: (options) => {
                const {
                  filters = [],
                  groupBy = [],
                  orderBy = []
                } = options;
                const filteredEmployees = filterData(_.filter(_.values(this.$store.state.org.employees), (e) => e.departmentId === department.id && e.state === ACCOUNT_STATE.active), filters);
                if (groupBy.length > 0) {
                  return groupData(filteredEmployees, groupBy, (data) => sortData(data, orderBy));
                } else {
                  return sortData(filteredEmployees, orderBy);
                }
              },
              getShifts: (options) => {
                const {
                  filters = [],
                  groupBy = [],
                  orderBy = []
                } = options;
                const filteredShifts = filterData(shifts, filters);
                if (groupBy.length > 0) {
                  return groupData(filteredShifts, groupBy, (data) => sortAndGroupShifts(data, orderBy, usersInfo));
                } else {
                  return sortAndGroupShifts(filteredShifts, orderBy, usersInfo);
                }
              }
            },
            this
          );

          dailySchedulePDF.setHeader(
            `${this.$t('labels.page')} {currentPage}/{pageCount}`,
            [
              this.$t('labels.sharedBy', { name: sharedBy }),
              moment().format(this.dateFormatStringLong)
            ]
          );
          dailySchedulePDF.generate(this.shareOptions);
          dailySchedulePDF.getBase64().then((base64) => {
            resolve({
              base64,
              dailySchedulePDF
            });
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getAvatar,
    getCategoryCount (key, jobTypeIndex) {
      let associatedJobTypes = [];
      if (_.has(this.jobTypes, `${jobTypeIndex}.associatedJobTypes`)) {
        associatedJobTypes = _.get(this.jobTypes, `${jobTypeIndex}.associatedJobTypes`, []);
      }
      const count = this.items.reduce((count, item) => {
        if (item.category === key && associatedJobTypes.includes(item.user.jobTypeId)) {
          count++;
        }
        return count;
      }, 0);

      return count || '';
    },
    getCategoryLabel (key) {
      let label = '';
      const category = _.find(this.categories, (category) => category.key === key);
      if (category) {
        label = category.label;
      }
      return label;
    },
    getLastCanceledCount (item) {
      return _.get(this.lastCanceled.users, [item.value.assigneeId, 'shifts'], []).length;
    },
    getDates (shifts) {
      const dates = [];
      for (let i = 0, count = shifts.length; i < count; i++) {
        dates.push(moment(shifts[i].payrollDate || shifts[i].date).format(DATE_FORMAT_US));
      }
      return _.uniq(dates).join(', ');
    },
    getDepartmentByID (id) {
      return this.$store.getters['org/getDepartmentById'](id);
    },
    getEventItem (event, user) {
      return {
        id: `event${event.id}`, // This ID is just for the data table to distinguish rows for selection.
        category: this.categoryMapping.nonDuty,
        value: event,
        jobTypeIndex: this.getJobTypeIndex(user),
        type: 'event',
        user: {
          ...user,
          id: user.userId,
          profileId: user.id
        }
      };
    },
    getEventType (event) {
      const eventType = this.$store.getters['org/getEventTypeById'](event.typeId);
      const type = {};
      if (eventType) {
        type.name = eventType.name;
        type.color = _.get(eventType, 'styles.web.color', '');
      }
      return type;
    },
    getJobCount (jobIndex) {
      let id = null;
      if (_.has(this.jobTypes, `${jobIndex}.id`)) {
        id = _.get(this.jobTypes, `${jobIndex}.id`, null);
      } else {
        id = _.get(this.jobTypesById, [jobIndex, 'id'], null);
      }
      let count = 0;
      if (id) {
        count = this.jobTypeCount[id];
      }
      return count;
    },
    getJobInfo (itemData) {
      const jobInfo = [itemData.user.jobTypeName, itemData.user.jobStatusShortCode].filter(Boolean).join(' ');
      if (itemData.user.departmentId !== this.activeDepartment.id) {
        return `${jobInfo} | ${itemData.user.departmentName}`;
      } else {
        return jobInfo;
      }
    },
    getJobLabel (jobIndex) {
      if (_.has(this.jobTypes, `${jobIndex}.name`)) {
        return _.get(this.jobTypes, `${jobIndex}.name`, '');
      } else {
        return _.get(this.jobTypesById, [jobIndex, 'name'], '');
      }
    },
    getJobTypeIndex (user, shift) {
      let jobTypeId = user.jobTypeId;
      const chargeShiftTypes = this.chargeShiftTypes;
      if (shift && user.charge && chargeShiftTypes) {
        const associatedShiftTypes = _.get(this.jobTypesById[jobTypeId], 'settings.associatedShiftTypes', []);
        if (chargeShiftTypes.includes(shift.typeId) && !associatedShiftTypes.includes(shift.typeId)) {
          jobTypeId = this.chargeJobType.id;
        }
      }

      for (let group = 1, groupCount = this.jobTypes.length; group < groupCount; group++) {
        for (let jobType = 0, jobTypeCount = this.jobTypes[group].length; jobType < jobTypeCount; jobType++) {
          if (this.jobTypes[group][jobType].associatedJobTypes.includes(jobTypeId)) {
            return `${group}.${jobType}`;
          }
        }
      }

      return jobTypeId;
    },
    getJobTypes (department) {
      const staffNeeded = _.cloneDeep(_.get(department, ['settings', 'staffNeeded'], []));
      const jobTypesMap = this.$store.state.org.jobTypes.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
      const jobTypes = [];
      for (let i = 0, count = staffNeeded.length; i < count; i++) {
        const associatedJobTypes = [];
        const descriptions = [];
        const names = [];
        let partakeInScheduling = false;
        for (let j = 0, typeCount = staffNeeded[i].jobTypes.length; j < typeCount; j++) {
          const jobTypeInfo = jobTypesMap[staffNeeded[i].jobTypes[j]];
          if (jobTypeInfo) {
            associatedJobTypes.push(jobTypeInfo.id);
            descriptions.push(jobTypeInfo.description);
            names.push(jobTypeInfo.name);
            if (jobTypeInfo.partakeInScheduling) {
              partakeInScheduling = true;
            }
          }
        }
        if (partakeInScheduling) {
          jobTypes.push({
            description: descriptions.join(' / '),
            id: staffNeeded[i].jobTypes.join('|'),
            name: names.join(' / '),
            associatedJobTypes,
            associatedShiftTypes: _.keys(staffNeeded[i].shiftTypes).map(id => parseInt(id)),
            staffNeeded: staffNeeded[i].shiftTypes,
            staffingMatrix: staffNeeded[i].staffingMatrix,
            groupRight: _.get(staffNeeded[i], 'display.dailySchedule.groupRight', false)
          });
        }
      }
      const groupedJobTypes = [];
      let group = [];
      if (jobTypes.length > 0) {
        group.push(jobTypes[0]);
      }
      for (let i = 1, count = jobTypes.length; i < count; i++) {
        if (group[group.length - 1].groupRight) {
          group.push(jobTypes[i]);
        } else {
          groupedJobTypes.push([...group]);
          group = [jobTypes[i]];
        }
      }
      groupedJobTypes.push(group);

      groupedJobTypes.unshift([
        {
          description: this.$t('labels.all'),
          id: this.ALL_JOB_TYPES_ID,
          name: this.$t('labels.all'),
          associatedJobTypes: _.keys(this.jobTypesById).map(id => parseInt(id)),
          associatedShiftTypes: this.activeDailyScheduleType.shiftTypes,
          groupRight: false
        }
      ]);

      return groupedJobTypes;
    },
    getShiftItem (shift, user) {
      const category = _.find(this.categories, (category) => category.match({ value: shift }));
      if (!category) {
        return null;
      }
      return {
        id: `shift${shift.id}`, // This ID is just for the data table to distinguish rows for selection.
        category: category.key,
        value: shift,
        jobTypeIndex: this.getJobTypeIndex(user, shift),
        type: 'shift',
        user: {
          ...user,
          id: user.userId,
          profileId: user.id
        }
      };
    },
    getSitterInfo (item) {
      const sitter = {
        room: '',
        reason: ''
      };

      const room = _.get(item, 'settings.sitter.room', null);
      const reason = _.get(item, 'settings.sitter.reason', null);

      if (room) {
        sitter.room = room;
      }
      if (reason) {
        sitter.reason = reason;
      }
      return sitter;
    },
    getSymbol (type, item) {
      let symbol = {};
      switch (type) {
        case 'shift':
          if (item.value.available) {
            symbol = _.cloneDeep(this.scheduleSymbols.available) || {};
          } else {
            symbol = _.cloneDeep(this.shiftTypes[item.value.typeId].styles.web);
          }
          if (item.value.obligatory) {
            if (_.get(symbol, 'web.symbolType', '') === 'text') {
              symbol.web.symbolValue += '*';
            }
          }
          break;
      }
      return symbol;
    },
    getUserInitials (userId) {
      const user = this.$store.state.org.employees[userId];
      return user.firstName[0] + user.lastName[0];
    },
    getShiftTime (shift) {
      const shiftType = this.shiftTypes[shift.typeId];
      const startTime = shift.startTime ? shift.startTime : shiftType.startTime;
      const endTime = shift.endTime ? shift.endTime : shiftType.endTime;
      return [startTime.substring(0, 5), endTime.substring(0, 5)];
    },
    getErrors (user, hypotheticalShift) {
      const errors = [];
      const validators = this.$store.getters['scheduling/getValidator'](this.schedule.id);
      for (let name in validators) {
        const dayErrors = validators[name].getDayErrors(this.date, user, hypotheticalShift);
        if (dayErrors) {
          errors.push(dayErrors);
        }
      }
      return errors;
    },
    goToToday () {
      this.date = moment().format(DATE_FORMAT);
    },
    hasDefaultShiftTime (shift) {
      const shiftType = this.shiftTypes[shift.typeId];
      let startTime = shift.startTime ? shift.startTime : shiftType.startTime;
      let endTime = shift.endTime ? shift.endTime : shiftType.endTime;
      return startTime === shiftType.startTime && endTime === shiftType.endTime;
    },
    hasDifferentPayrollDate (item) {
      let hasDifferentPayrollDate = false;
      if (item.value.payrollDate) {
        hasDifferentPayrollDate = !moment(item.value.payrollDate).isSame(moment(item.value.date));
      }

      return hasDifferentPayrollDate;
    },
    loadNewContent (contentCallback, callbackParams, cancelCallback) {
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {
          cancelCallback();
        }).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          const params = callbackParams || [];
          contentCallback(...params);
        });
      } else {
        const params = callbackParams || [];
        contentCallback(...params);
      }
    },
    loadShareDialog () {
      this.loadingShareDialog = true;
      const dailyScheduleCriteria = {
        for_date: this.date
      };
      this.dispatch('scheduling/retrieveDailySchedules', dailyScheduleCriteria).then((response) => {
        this.updateLastSharedWith(response);
      }).finally(() => {
        this.loadingShareDialog = false;
      });
    },
    moment,
    nextDay () {
      this.date = moment(this.date).add(1, 'day').format(DATE_FORMAT);
    },
    onDailyScheduleTypeChange (type) {
      this.loadNewContent(this.setActiveDailyScheduleType, [type], () => {
        this.activeDailyScheduleType = _.cloneDeep(this.previousActiveDailyScheduleType);
      });
    },
    onOpenShiftUpdateReceived (event) {
      const { action } = JSON.parse(event.data);
      if (['deleted', 'closed', 'ended'].includes(action)) {
        this.updateOpenShiftCount();
      }
    },
    onShiftUpdateReceived (event) {
      const { action, date, id, assignee_id: assigneeId } = JSON.parse(event.data);
      if (!this.storeSchedule || date < this.storeSchedule.startOn || date > this.storeSchedule.endOn || !_.has(this.$store.state.org.employees, [assigneeId])) {
        return;
      }
      let activity;
      switch (action) {
        case 'create':
          this.dispatch('scheduling/retrieveShift', id).then(shift => {
            const existingShift = this.findShiftById(shift.assigneeId, id);
            if (!existingShift) {
              this.$store.commit('scheduling/add_shift', {
                ...shift,
                date: moment(shift.date).toDate(),
                type: 'shift'
              });
              if (date === this.date) {
                const category = _.find(this.categories, (category) => category.match({ value: shift }));
                if (category) {
                  this.lastCanceled.users[shift.assigneeId] = {
                    last: null,
                    shifts: []
                  };
                  this.lastFloat.users[shift.assigneeId] = {
                    last: null,
                    shifts: []
                  };
                  this.lastOvertime.users[shift.assigneeId] = {
                    last: null,
                    shifts: []
                  };

                  Promise.all([
                    this.retrieveCanceledData([shift.assigneeId]),
                    this.retrieveFloatData([shift.assigneeId]),
                    this.retrieveOvertimeData([shift.assigneeId])
                  ]).catch(() => {});
                }
              }
            }
          }).catch(() => {});
          break;
        case 'update':
          this.dispatch('scheduling/retrieveShift', id).then(shift => {
            shift.type = 'shift';
            shift.date = moment(shift.date).toDate();
            let originalShift = this.findShiftById(shift.assigneeId, id);
            if (!originalShift) {
              originalShift = _.cloneDeep(shift);
            }

            this.$store.commit('scheduling/update_shift', { originalShift, shift });
          }).catch(() => {});
          break;
        case 'delete':
          activity = this.findShiftById(assigneeId, id);
          if (activity) {
            this.$store.commit('scheduling/remove_shift', activity);
          }
          break;
      }
    },
    onWsUpdate (eventInfo) {
      const data = JSON.parse(eventInfo.data);
      const contentType = data.content_type;
      switch (contentType) {
        case CONTENT_TYPES.openShift:
          this.onOpenShiftUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.shift:
          this.onShiftUpdateReceived(eventInfo);
          break;
      }
    },
    openCensusPanel () {
      this.showPanel(() => {
        return {
          component: Census,
          props: {
            id: this.id,
            census: this.census,
            dailyScheduleTypeId: this.activeDailyScheduleType.id,
            date: this.date,
            departmentId: this.activeDepartment.id,
            jobTypeCount: this.jobTypeAssignees,
            jobTypes: this.jobTypesUngrouped,
            readOnly: this.readOnly
          },
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            change: (dailySchedule) => {
              this.updateData(dailySchedule);
              this.refreshPanels();
            }
          }
        };
      }, [], true);
    },
    openCreateOpenShiftPanel () {
      this.showPanel(() => {
        return {
          component: CreateOpenShift,
          props: {
            date: this.date,
            jobTypeId: this.associatedJobTypes[0],
            shiftTypeId: this.$store.getters['org/getDailyScheduleTypeById'](this.activeDailyScheduleType.id, 'shiftTypes')[0]
          },
          events: {
            close: () => {
              this.openOpenShiftsListPanel();
            },
            created: (openShift) => {
              this.openShiftCount++;
              this.openOpenShiftDetailsPanel(openShift, true);
            }
          }
        };
      }, [], false);
    },
    openNotesPanel () {
      this.showPanel(() => {
        return {
          component: Notes,
          props: {
            dailySchedule: {
              id: this.id,
              census: this.census,
              memos: this.memos,
              state: this.state
            },
            date: this.date,
            departmentId: this.activeDepartment.id,
            readOnly: this.readOnly,
            typeId: this.activeDailyScheduleType.id
          },
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            change: (dailySchedule) => {
              this.updateData(dailySchedule);
              this.refreshPanels();
            }
          }
        };
      }, [], true);
    },
    openOpenShiftDetailsPanel (openShift, replaceLastPanel = false) {
      let openShiftCopy = _.cloneDeep(openShift);
      const data = () => {
        return {
          component: OpenShiftDetails,
          props: {
            id: openShiftCopy.id
          },
          events: {
            close: () => {
              this.openOpenShiftsListPanel();
            }
          }
        };
      };
      if (replaceLastPanel) {
        this.updatePanel(this.panels.length - 1, data, []);
      } else {
        this.showPanel(data, [], false);
      }
    },
    openOpenShiftsListPanel () {
      this.showPanel(() => {
        return {
          component: OpenShiftsList,
          props: {
            date: this.date,
            departmentIds: [this.activeDepartment.id]
          },
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            create: () => {
              this.openCreateOpenShiftPanel();
            },
            'open-details': (openShift) => {
              this.openOpenShiftDetailsPanel(openShift, false);
            }
          }
        };
      }, [], true);
    },
    openCreateEventPanel () {
      this.showPanel(() => {
        return {
          component: AddEvent,
          props: {
            date: this.date
          },
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            created: (event) => {
              const employee = this.$store.state.org.employees[event.assigneeId];
              this.showItemDetails(this.getEventItem(event, employee), false);
            }
          }
        };
      }, [], true);
    },
    openCreateShiftPanel () {
      this.showPanel(() => {
        return {
          component: AddShift,
          props: {
            date: this.date,
            selectedStaff: {}
          },
          events: {
            close: () => {
              this.openedPanelName = undefined;
              return true;
            },
            'add-staff': (data) => {
              const { departmentId, employee, shift, section } = data;
              let changes = {};
              let value = {};
              if (shift && ['floatOutData', 'floatInData'].includes(section)) {
                changes = {
                  canceled: false,
                  departmentId
                };
                value = _.cloneDeep(shift);
                if (!this.activeDailyScheduleType.shiftTypes.includes(value.typeId)) {
                  value.typeId = null;
                  value.startTime = null;
                  value.endTime = null;
                }
              } else {
                let shiftTemplate = {
                  available: false,
                  id: null,
                  comments: '',
                  endTime: '',
                  flags: [],
                  internalComments: '',
                  obligatory: false,
                  onCall: false,
                  settings: {},
                  startTime: '',
                  typeId: null
                };
                if (shift && shift.available) {
                  shiftTemplate = shift;
                }
                _.set(shiftTemplate, 'settings.sitter', {
                  reason: '',
                  room: ''
                });
                value = {
                  ...shiftTemplate,
                  assigneeId: employee.userId,
                  canceled: false,
                  date: this.date,
                  departmentId: departmentId,
                  sitter: false
                };
              }
              this.showItemDetails({
                action: 'add',
                id: _.uniqueId(),
                category: null,
                changes,
                jobTypeIndex: this.getJobTypeIndex(employee, value),
                value,
                type: 'shift',
                user: {
                  ...employee,
                  departmentId: employee.departmentId,
                  id: employee.userId,
                  fullName: employee.fullName,
                  profileId: employee.id
                }
              }, false);
            }
          }
        };
      }, [], true);
    },
    preparePanelData (panelDataCallback, params) {
      const panelData = panelDataCallback(...params);
      if (panelData) {
        const closeCallback = _.get(panelData, ['events', 'close']);
        panelData.events.close = () => {
          if (closeCallback) {
            if (closeCallback()) {
              this.closeLastPanel();
            }
          } else {
            this.closeLastPanel();
          }
        };
      }
      return panelData;
    },
    previousDay () {
      this.date = moment(this.date).subtract(1, 'day').format(DATE_FORMAT);
    },
    refreshPanels (count) {
      const len = count || this.panels.length;
      for (let i = 0; i < len; i++) {
        if (this.panels[i]) {
          this.updatePanel(i, this.panels[i].panelDataCallback, this.panels[i].params);
        }
      }
    },
    resetShareDialog () {
      this.sharingSummaryState = this.SHARING_SUMMARY_READY;
      this.loadingShareDialog = true;
      for (let i = 0, count = this.shareWithDepartments.length; i < count; i++) {
        const dailySchedules = this.shareWithDepartments[i].dailySchedules;
        this.shareWithDepartments[i].status = null;
        for (let id in dailySchedules) {
          dailySchedules[id].send = this.shareWithDepartments[i].id == this.activeDepartment.id && id == this.activeDailyScheduleType.id;
          dailySchedules[id].lastShared = '';
          dailySchedules[id].lastSharedDetails = '';
        }
      }
    },
    retrieveData (reset = true) {
      if (reset) {
        this.retrievingSchedule = true;
        this.closeAllPanels();
        this.staffFilter = '';
      }
      const dailySchedulecriteria = {
        deptId: this.activeDepartment.id,
        date: this.date,
        shiftTypes: this.activeDailyScheduleType.shiftTypes,
        typeId: this.activeDailyScheduleType.id
      };

      const requests = [
        this.dispatch('scheduling/retrieveDailyScheduleData', dailySchedulecriteria),
        this.updateOpenShiftCount()
      ];
      if (this.canEdit) {
        requests.push(this.dispatch('scheduling/retrieveDailySchedule', dailySchedulecriteria));
      }

      return Promise.all(requests).then(response => {
        const { schedule } = response[0];
        let dailySchedule = null;
        if (this.canEdit) {
          dailySchedule = response[2];
        }
        if (dailySchedule) {
          this.id = dailySchedule.id;
          this.updateData(dailySchedule);
        } else {
          this.updateData({
            id: 0,
            census: [],
            memos: [],
            state: 'draft'
          });
        }

        this.schedule = schedule;
        this.lastCanceled.users = {};
        this.lastFloat.users = {};
        this.lastOvertime.users = {};

        for (let item of this.items) {
          this.lastCanceled.users[item.user.id] = {
            last: null,
            shifts: []
          };
          this.lastFloat.users[item.user.id] = {
            last: null,
            shifts: []
          };
          this.lastOvertime.users[item.user.id] = {
            last: null,
            shifts: []
          };
        }

        Promise.all([
          this.retrieveCanceledData(),
          this.retrieveFloatData(),
          this.retrieveOvertimeData()
        ]).finally(() => {
          this.retrievingSchedule = false;
          this.$store.commit('scheduling/set_active_schedule_id', schedule.id);
        });
      }).catch(error => {
        Sentry.captureException(error);
        // Setting the ID to -1 indicates an error occurred retrieving daily schedule date.
        this.updateData({
          id: -1,
          census: [],
          memos: [],
          state: 'draft'
        });
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.scheduleRetrievalFail'),
          type: 'error',
          data
        });
      });
    },
    retrieveCanceledData (users) {
      let assignees = _.keys(this.lastCanceled.users);
      if (users) {
        assignees = users;
      }
      if (assignees.length === 0) {
        return Promise.resolve();
      }
      this.lastCanceled.loading = true;
      for (let i = 0, count = assignees.length; i < count; i++) {
        this.lastCanceled.users[assignees[i]].shifts = [];
      }
      const lastCanceledCriteria = {
        type: 'canceled',
        before: this.date,
        assignee_ids: assignees.join(',')
      };
      const lastCanceledSchedule = this.lastCanceled.schedule;
      const canceledCriteria = {
        canceled: 'true',
        between: [lastCanceledSchedule.startOn, lastCanceledSchedule.endOn].join(','),
        include_assignee_ids: assignees.join(',')
      };

      return Promise.all([
        this.dispatch('scheduling/retrieveLastShifts', lastCanceledCriteria),
        this.dispatch('scheduling/retrieveShifts', canceledCriteria)
      ]).then((shifts) => {
        const lastCanceled = shifts[0];
        for (let userId in lastCanceled) {
          this.lastCanceled.users[userId].last = lastCanceled[userId].last;
        }

        const canceled = shifts[1];
        for (let i = 0, count = canceled.length; i < count; i++) {
          this.lastCanceled.users[canceled[i].assigneeId].shifts.push(canceled[i]);
        }
      }).catch(error => {
        Sentry.captureException(error);
      }).finally(() => {
        this.lastCanceled.loading = false;
      });
    },
    retrieveFloatData (users) {
      let assignees = _.keys(this.lastFloat.users);
      if (users) {
        assignees = users;
      }
      if (assignees.length === 0) {
        return Promise.resolve();
      }
      this.lastFloat.loading = true;
      for (let i = 0, count = assignees.length; i < count; i++) {
        this.lastFloat.users[assignees[i]].shifts = [];
      }
      const lastFloatCriteria = {
        type: 'float',
        before: this.date,
        assignee_ids: assignees.join(',')
      };
      const lastFloatSchedule = this.lastFloat.schedule;
      const floatCriteria = {
        float_with_flag: 'true',
        between: [lastFloatSchedule.startOn, lastFloatSchedule.endOn].join(','),
        include_assignee_ids: assignees.join(',')
      };

      return Promise.all([
        this.dispatch('scheduling/retrieveLastShifts', lastFloatCriteria),
        this.dispatch('scheduling/retrieveShifts', floatCriteria)
      ]).then((shifts) => {
        const lastFloat = shifts[0];
        for (let userId in lastFloat) {
          this.lastFloat.users[userId].last = lastFloat[userId].last;
        }
        const float = shifts[1];
        for (let i = 0, count = float.length; i < count; i++) {
          this.lastFloat.users[float[i].assigneeId].shifts.push(float[i]);
        }
      }).catch(error => {
        Sentry.captureException(error);
      }).finally(() => {
        this.lastFloat.loading = false;
      });
    },
    retrieveOvertimeData (users) {
      let assignees = _.keys(this.lastOvertime.users);
      if (users) {
        assignees = users;
      }
      if (assignees.length === 0) {
        return Promise.resolve();
      }
      this.lastOvertime.loading = true;
      for (let i = 0, count = assignees.length; i < count; i++) {
        this.lastOvertime.users[assignees[i]].shifts = [];
      }
      const lastOvertimeCriteria = {
        type: 'overtime',
        before: this.date,
        assignee_ids: assignees.join(',')
      };
      const lastOvertimeSchedule = this.lastOvertime.schedule;
      const overtimeCriteria = {
        overtime: 'true',
        between: [lastOvertimeSchedule.startOn, lastOvertimeSchedule.endOn].join(','),
        include_assignee_ids: assignees.join(',')
      };

      return Promise.all([
        this.dispatch('scheduling/retrieveLastShifts', lastOvertimeCriteria),
        this.dispatch('scheduling/retrieveShifts', overtimeCriteria)
      ]).then((shifts) => {
        const lastOvertime = shifts[0];
        for (let userId in lastOvertime) {
          this.lastOvertime.users[userId].last = lastOvertime[userId].last;
        }

        const overtime = shifts[1];
        for (let i = 0, count = overtime.length; i < count; i++) {
          this.lastOvertime.users[overtime[i].assigneeId].shifts.push(overtime[i]);
        }
      }).catch(error => {
        Sentry.captureException(error);
      }).finally(() => {
        this.lastOvertime.loading = false;
      });
    },
    retryShareSummary () {
      this.sharingSummaryState = this.SHARING_SUMMARY_IN_PROGRESS;
      let requests = Promise.resolve();
      const dailySchedules = [];
      for (let i = 0, count = this.shareWithDepartments.length; i < count; i++) {
        const departmentInfo = this.shareWithDepartments[i];
        if (departmentInfo.status === 'failed') {
          const typeIds = [];
          for (let dailyScheduleTypeId in departmentInfo.dailySchedules) {
            if (departmentInfo.dailySchedules[dailyScheduleTypeId].send) {
              typeIds.push(departmentInfo.dailySchedules[dailyScheduleTypeId].id);
            }
          }
          if (typeIds.length > 0) {
            departmentInfo.status = 'pending';
            requests = requests.then(() => {
              return this.shareDailyScheduleWithDepartment(departmentInfo.id, typeIds).then((ds) => {
                if (this.sharingSummaryType === this.SHARING_SUMMARY_EMAIL_TYPE) {
                  dailySchedules.push(...ds);
                }
              }).catch(() => {});
            });
          }
        }
      }
      if (this.sharingSummaryType === this.SHARING_SUMMARY_EMAIL_TYPE && this.shareWithAdditionalRecipients.recipients.length > 0) {
        this.shareWithAdditionalRecipients.status = 'pending';
        requests = requests.then(() => {
          return this.shareDailyScheduleWithRecipients(dailySchedules).catch(() => {});
        });
      } else {
        this.shareWithAdditionalRecipients.status = this.SHARING_SUMMARY_READY;
      }
      requests.finally(() => {
        this.sharingSummaryState = this.SHARING_SUMMARY_FINISHED;
      });
    },
    setActiveDailyScheduleType (type) {
      this.previousActiveDailyScheduleType = _.cloneDeep(type);
      this.activeDailyScheduleType = type;
      this.retrieveData();
      this.resetShareDialog();
    },
    setDate (date) {
      this.date = date;
    },
    setPanelName (panel) {
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          this.openedPanelName = panel;
        });
      } else {
        this.openedPanelName = panel;
      }
    },
    setSelectedLastCanceledSchedule (schedule) {
      this.lastCanceled.schedule = schedule;
    },
    setSelectedLastFloatSchedule (schedule) {
      this.lastFloat.schedule = schedule;
    },
    setSelectedLastOvertimeSchedule (schedule) {
      this.lastOvertime.schedule = schedule;
    },
    setSortDirection (order) {
      this.$store.commit('scheduling/set_daily_schedule_sort', {
        name: 'order',
        userId: this.$store.state.account.userId,
        value: order
      });
    },
    setSortBy (columns) {
      this.$store.commit('scheduling/set_daily_schedule_sort', {
        name: 'columns',
        userId: this.$store.state.account.userId,
        value: columns
      });
    },
    shareDailySchedulePayload (departmentId, typeId, dailySchedules) {
      return new Promise((resolve, reject) => {
        const dailyScheduleType = this.dailyScheduleTypes.find((t) => t.id === typeId);
        const shiftsCriteria = {
          dept_id: departmentId,
          flex_on: 'false',
          date_or_payroll_date_on: this.date,
          shift_type_ids: dailyScheduleType.shiftTypes.join(',')
        };
        this.dispatch('scheduling/retrieveShifts', shiftsCriteria).then(shifts => {
          const department = _.find(this.$store.state.org.departments, (d) => d.id === departmentId);
          const dailySchedule = _.find(this.dailySchedules, (ds) => ds.typeId === typeId && ds.departmentId === departmentId && ds.date === this.date);
          const processedShifts = [];
          for (let i = 0, count = shifts.length; i < count; i++) {
            if (shifts[i].payrollDate && shifts[i].payrollDate !== this.date) {
              continue;
            }
            processedShifts.push(shifts[i]);
          }
          if (processedShifts.length === 0) {
            this.generatePDF(department, dailySchedule, dailyScheduleType, processedShifts, {}).then((data) => {
              dailySchedules.push({
                dept_id: departmentId,
                type_id: typeId,
                ...data
              });
              resolve(dailySchedules);
            }).catch((error) => {
              reject(error);
            });
          } else {
            const includeAssigneeIds = [];
            const userInfo = {};
            let jobCount = 1;
            const jobTypeWeights = _.get(department, ['settings', 'staffNeeded'], []).reduce(
              (obj, staffNeeded) => {
                const jobTypes = _.get(staffNeeded, 'jobTypes', []);
                for (let j = 0, typeCount = jobTypes.length; j < typeCount; j++) {
                  obj[jobTypes[j]] = jobCount;
                  jobCount++;
                }
                return obj;
              },
              {}
            );
            for (let i = 0, count = processedShifts.length; i < count; i++) {
              processedShifts[i].assignee = this.$store.state.org.employees[processedShifts[i].assigneeId];
              processedShifts[i].assignee.jobTypeWeight = jobTypeWeights[_.get(processedShifts[i], 'assignee.jobTypeId', 0)] || 1000;
              processedShifts[i].type = this.shiftTypes[processedShifts[i].typeId];
              includeAssigneeIds.push(processedShifts[i].assigneeId);
              if (!userInfo[processedShifts[i].assigneeId]) {
                userInfo[processedShifts[i].assigneeId] = {
                  hours: {
                    daily: {},
                    weekly: 0
                  },
                  last: {
                    canceled: null,
                    float: null,
                    overtime: null
                  }
                };
              }
            }

            const requests = [];
            const requestsCallbacks = [];

            if (this.shareOptions.loadExtraShifts) {
              let startOfWeek = calculateStartOfWeek(this.date, this.$store.state.org.settings.scheduling.startOfWeek);
              const weeklyShiftsCriteria = {
                dept_id: departmentId,
                flex_on: 'false',
                between: [startOfWeek.format(DATE_FORMAT), startOfWeek.add(6, 'd').format(DATE_FORMAT)].join(','),
                include_assignee_ids: includeAssigneeIds.join(',')
              };
              requests.push(this.dispatch('scheduling/retrieveShifts', weeklyShiftsCriteria));
              requestsCallbacks.push((shifts) => {
                prepareShiftHours(this.$store, shifts, userInfo);
              });
            }
            if (this.shareOptions.loadLastDates) {
              requests.push(this.dispatch('scheduling/retrieveLastShifts', {
                type: 'float',
                before: this.date,
                assignee_ids: includeAssigneeIds.join(',')
              }));
              requestsCallbacks.push((shifts) => {
                prepareLastDates(shifts, userInfo, 'float');
              });
              requests.push(this.dispatch('scheduling/retrieveLastShifts', {
                type: 'canceled',
                before: this.date,
                assignee_ids: includeAssigneeIds.join(',')
              }));
              requestsCallbacks.push((shifts) => {
                prepareLastDates(shifts, userInfo, 'canceled');
              });
              requests.push(this.dispatch('scheduling/retrieveLastShifts', {
                type: 'overtime',
                before: this.date,
                assignee_ids: includeAssigneeIds.join(',')
              }));
              requestsCallbacks.push((shifts) => {
                prepareLastDates(shifts, userInfo, 'overtime');
              });
            }

            Promise.all(requests).then((response) => {
              for (let i = 0, count = response.length; i < count; i++) {
                requestsCallbacks[i](response[i]);
              }
              this.generatePDF(department, dailySchedule, dailyScheduleType, processedShifts, userInfo).then((data) => {
                dailySchedules.push({
                  dept_id: departmentId,
                  type_id: typeId,
                  ...data
                });
                resolve(dailySchedules);
              }).catch((error) => {
                reject(error);
              });
            }).catch((error) => {
              reject(error);
            });
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    shareDailyScheduleWithRecipients (dailySchedules) {
      return new Promise((resolve, reject) => {
        this.shareWithAdditionalRecipients.status = 'in_progress';
        this.dispatch('scheduling/shareDailySchedule', {
          date: this.date,
          daily_schedules: dailySchedules,
          recipients: this.shareWithAdditionalRecipients.recipients
        }).then((response) => {
          this.shareWithAdditionalRecipients.status = 'success';
          resolve();
        }).catch((error) => {
          this.shareWithAdditionalRecipients.status = 'failed';
          reject(error);
        });
      });
    },
    shareDailyScheduleWithDepartment (departmentId, typeIds) {
      return new Promise((resolve, reject) => {
        let requests = Promise.resolve([]);
        const departmentInfo = _.find(this.shareWithDepartments, (d) => d.id === departmentId);
        departmentInfo.status = 'in_progress';
        for (let i = 0, count = typeIds.length; i < count; i++) {
          requests = requests.then((dailySchedules) => {
            return this.shareDailySchedulePayload(departmentId, typeIds[i], dailySchedules);
          });
        }
        requests.then((dailySchedules) => {
          const payload = {
            date: this.date,
            daily_schedules: dailySchedules.map((ds) => {
              return {
                dept_id: ds.dept_id,
                type_id: ds.type_id,
                pdf: ds.base64
              };
            })
          };
          switch (this.sharingSummaryType) {
            case this.SHARING_SUMMARY_DOWNLOAD_TYPE:
              try {
                for (const ds of dailySchedules) {
                  ds.dailySchedulePDF.download();
                }
                departmentInfo.status = 'success';
                resolve();
              } catch (error) {
                departmentInfo.status = 'failed';
                reject(error);
              }
              break;
            case this.SHARING_SUMMARY_EMAIL_TYPE:
              this.dispatch('scheduling/shareDailySchedule', payload).then((response) => {
                departmentInfo.status = 'success';
                this.updateLastSharedWith(response.dailySchedules);
                for (let dailySchedule of response.dailySchedules) {
                  if (dailySchedule.departmentId === this.activeDepartment.id && dailySchedule.typeId === this.activeDailyScheduleType.id) {
                    this.updateData(dailySchedule);
                    break;
                  }
                }
                resolve(payload.daily_schedules);
              }).catch((error) => {
                departmentInfo.status = 'failed';
                reject(error);
              });
          }
        }).catch(error => {
          departmentInfo.status = 'failed';
          reject(error);
        });
      });
    },
    downloadSummary () {
      if (this.selectedDepartmentsToShare && this.sharingSummaryState !== this.SHARING_SUMMARY_IN_PROGRESS) {
        this.sharingSummaryType = this.SHARING_SUMMARY_DOWNLOAD_TYPE;
        this.sharingSummaryState = this.SHARING_SUMMARY_IN_PROGRESS;
        let requests = Promise.resolve();
        for (let i = 0, count = this.shareWithDepartments.length; i < count; i++) {
          const departmentInfo = this.shareWithDepartments[i];
          const typeIds = [];
          for (let dailyScheduleTypeId in departmentInfo.dailySchedules) {
            if (departmentInfo.dailySchedules[dailyScheduleTypeId].send) {
              typeIds.push(departmentInfo.dailySchedules[dailyScheduleTypeId].id);
            }
          }
          if (typeIds.length > 0) {
            departmentInfo.status = 'pending';
            requests = requests.then(() => {
              return this.shareDailyScheduleWithDepartment(departmentInfo.id, typeIds).catch(() => {});
            });
          }
        }
        requests.finally(() => {
          this.sharingSummaryState = this.SHARING_SUMMARY_FINISHED;
        });
      }
    },
    emailSummary () {
      if (this.selectedDepartmentsToShare && this.sharingSummaryState !== this.SHARING_SUMMARY_IN_PROGRESS) {
        this.sharingSummaryType = this.SHARING_SUMMARY_EMAIL_TYPE;
        this.sharingSummaryState = this.SHARING_SUMMARY_IN_PROGRESS;
        let requests = Promise.resolve();
        const dailySchedules = [];
        for (let i = 0, count = this.shareWithDepartments.length; i < count; i++) {
          const departmentInfo = this.shareWithDepartments[i];
          const typeIds = [];
          for (let dailyScheduleTypeId in departmentInfo.dailySchedules) {
            if (departmentInfo.dailySchedules[dailyScheduleTypeId].send) {
              typeIds.push(departmentInfo.dailySchedules[dailyScheduleTypeId].id);
            }
          }
          if (typeIds.length > 0) {
            departmentInfo.status = 'pending';
            requests = requests.then(() => {
              return this.shareDailyScheduleWithDepartment(departmentInfo.id, typeIds).then((ds) => {
                dailySchedules.push(...ds);
              }).catch(() => {});
            });
          }
        }
        if (this.shareWithAdditionalRecipients.recipients.length > 0) {
          this.shareWithAdditionalRecipients.status = 'pending';
          requests = requests.then(() => {
            return this.shareDailyScheduleWithRecipients(dailySchedules).catch(() => {});
          });
        } else {
          this.shareWithAdditionalRecipients.status = this.SHARING_SUMMARY_READY;
        }
        requests.finally(() => {
          this.sharingSummaryState = this.SHARING_SUMMARY_FINISHED;
        });
      }
    },
    shareWithAllDepartments (dailyScheduleTypeId) {
      for (let i = 0, count = this.shareWithDepartments.length; i < count; i++) {
        this.shareWithDepartments[i].dailySchedules[dailyScheduleTypeId].send = true;
      }
    },
    shareWithZeroDepartments (dailyScheduleTypeId) {
      for (let i = 0, count = this.shareWithDepartments.length; i < count; i++) {
        this.shareWithDepartments[i].dailySchedules[dailyScheduleTypeId].send = false;
      }
    },
    showItemDetails (itemData, reset = true, replace = false) {
      this.selectedRows = [itemData];
      if (reset) {
        this.openedPanelName = undefined;
      }
      this.$nextTick(() => {
        if (itemData.request) {
          const request = itemData.request;
          let component;
          switch (request.type) {
            case 'swap':
              component = SwapRequest;
              break;
            case 'event':
              component = EventRequest;
              break;
            case 'shift':
              component = ShiftRequest;
              break;
          }
          if (component) {
            this.showPanel(() => {
              return {
                component,
                props: {
                  'request-id': request.id
                },
                events: {
                  'approved': () => {
                    this.closeAllPanels();
                  },
                  'rejected': () => {
                    this.closeAllPanels();
                  }
                }
              };
            }, [], true);
          }
        } else {
          const callback = (itemData) => {
            if (itemData.type === 'shift') {
              let shiftData = itemData;
              if (itemData.value.id && itemData.action !== 'add') {
                const shift = this.findShiftById(shiftData.user.userId, itemData.value.id);
                if (!shift) {
                  this.closeAllPanels();
                  return;
                }
                shiftData = this.getShiftItem(shift, shiftData.user);
                if (!shiftData) {
                  this.closeAllPanels();
                  return;
                }
              }
              return {
                component: Details,
                props: {
                  allowDelete: this.$can('edit', 'shift'),
                  date: moment(this.date),
                  details: shiftData,
                  readOnly: !this.$can('edit', 'shift'),
                  user: this.$store.state.org.employees[shiftData.user.userId],
                  getErrors: this.getErrors
                },
                events: {
                  'saved-splits': ({ createdShifts, updatedShift }) => {
                    for (let i = 0, count = createdShifts.length; i < count; i++) {
                      let needsData = false;
                      if (!this.lastCanceled.users[createdShifts[i].assigneeId]) {
                        needsData = true;
                        this.lastCanceled.users[createdShifts[i].assigneeId] = {
                          last: null,
                          shifts: []
                        };
                      }
                      if (!this.lastFloat.users[createdShifts[i].assigneeId]) {
                        needsData = true;
                        this.lastFloat.users[createdShifts[i].assigneeId] = {
                          last: null,
                          shifts: []
                        };
                      }
                      if (!this.lastOvertime.users[createdShifts[i].assigneeId]) {
                        needsData = true;
                        this.lastOvertime.users[createdShifts[i].assigneeId] = {
                          last: null,
                          shifts: []
                        };
                      }
                      if (needsData) {
                        this.retrieveCanceledData([createdShifts[i].assigneeId]);
                        this.retrieveFloatData([createdShifts[i].assigneeId]);
                        this.retrieveOvertimeData([createdShifts[i].assigneeId]);
                      }
                    }
                    this.$store.commit('scheduling/update_shift', { originalShift: updatedShift, shift: updatedShift });
                    for (let i = 0, len = createdShifts.length; i < len; i++) {
                      this.$store.commit('scheduling/add_shift', createdShifts[i]);
                    }
                    this.refreshPanels();
                  },
                  'canceled-create': () => {
                    this.closeLastPanel();
                  },
                  created: (shift) => {
                    let needsData = false;
                    if (!this.lastCanceled.users[shift.assigneeId]) {
                      needsData = true;
                      this.lastCanceled.users[shift.assigneeId] = {
                        last: null,
                        shifts: []
                      };
                    }
                    if (!this.lastFloat.users[shift.assigneeId]) {
                      needsData = true;
                      this.lastFloat.users[shift.assigneeId] = {
                        last: null,
                        shifts: []
                      };
                    }
                    if (!this.lastOvertime.users[shift.assigneeId]) {
                      needsData = true;
                      this.lastOvertime.users[shift.assigneeId] = {
                        last: null,
                        shifts: []
                      };
                    }
                    if (needsData) {
                      this.retrieveCanceledData([shift.assigneeId]);
                      this.retrieveFloatData([shift.assigneeId]);
                      this.retrieveOvertimeData([shift.assigneeId]);
                    }
                    this.$store.commit('scheduling/add_shift', shift);
                    const idx = this.findShiftIndexById(shift.id);
                    if (idx >= 0) {
                      this.showItemDetails(this.items[idx], reset, true);
                    }
                  },
                  'has-changes': (hasChanges) => {
                    this.hasChanges = hasChanges;
                  },
                  updated: (data) => {
                    this.$store.commit('scheduling/update_shift', data);
                  },
                  removed: (shift) => {
                    this.$store.commit('scheduling/remove_shift', shift);
                    this.closeLastPanel();
                    this.refreshPanels();
                  }
                }
              };
            } else {
              return {
                component: EventDetails,
                props: {
                  allowDelete: this.$can('edit', 'event'),
                  date: moment(this.date),
                  event: itemData.value,
                  readOnly: !this.$can('edit', 'event'),
                  user: this.$store.state.org.employees[itemData.user.userId]
                },
                events: {
                  updated: (data) => {
                    this.$store.commit('scheduling/update_event', data);
                  },
                  removed: (event) => {
                    this.$store.commit('scheduling/remove_event', {
                      event
                    });
                    this.closeLastPanel();
                  }
                }
              };
            }
          };
          if (replace && this.panels.length > 0) {
            this.updatePanel(this.panels.length - 1, callback, [itemData]);
          } else {
            this.showPanel(callback, [itemData], reset);
          }
        }
      });
    },
    showPanel (panelDataCallback, params, resetPanels) {
      const show = () => {
        if (resetPanels && this.panels.length > 0) {
          this.closePanels(1, this.panels.length);
          this.updatePanel(0, panelDataCallback, params);
        } else {
          const panelData = this.preparePanelData(panelDataCallback, params);
          if (panelData) {
            panelData.id = _.uniqueId();
            panelData.panelDataCallback = panelDataCallback;
            panelData.params = params;
            this.panels.push(_.cloneDeep(panelData));
          }
        }
      };
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.hasChanges = false;
          this.$store.commit('unmark_all_unsaved_changes');
          show();
        });
      } else {
        show();
      }
    },
    toggleHelpPanel () {
      this.showHelp = !this.showHelp;
    },
    toggleDailyScheduleShare (item, header) {
      if (this.sharingSummaryState === this.SHARING_SUMMARY_READY) {
        item.dailySchedules[header.id].send = !item.dailySchedules[header.id].send;
      }
    },
    updateNurseDetails (user) {
      this.$store.commit('scheduling/update_user', user);
      this.refreshPanels(this.panels.length - 1);
    },
    toggleNurseDetailsPanel (item) {
      if (this.openedPanelName === 'nurse-details' && this.panels[0].userId === item.user.userId) {
        this.closeAllPanels();
        return;
      }
      this.openedPanelName = 'nurse-details';
      this.showPanel(() => {
        const latestUserInfo = _.cloneDeep(this.$store.state.org.employees[item.user.userId]);
        return {
          component: NurseDetails,
          props: {
            date: this.date,
            user: latestUserInfo
          },
          events: {
            'close': () => {
              this.openedPanelName = undefined;
              return true;
            },
            'notes-saved': (notes) => {
              // Nothing to do
            },
            'updated': (user) => {
              this.$store.commit('scheduling/update_user', user);
              this.refreshPanels(this.panels.length - 1);
            }
          },
          userId: item.user.userId
        };
      }, [], true);
    },
    updateAdditionalRecipients (recipients) {
      this.shareWithAdditionalRecipients.recipients = recipients;
    },
    updateData (dailySchedule) {
      const props = ['id', 'census', 'memos', 'state'];
      for (let prop of props) {
        if (_.has(dailySchedule, [prop])) {
          this[prop] = dailySchedule[prop];
        }
      }
    },
    updateDynamicHeights () {
      this.tableHeight = Math.max(500, window.innerHeight - 215);
    },
    updateLastSharedWith (dailySchedules) {
      for (let i = 0, count = dailySchedules.length; i < count; i++) {
        if (dailySchedules[i].sharedById && dailySchedules[i].sharedOn) {
          const matchedItem = _.find(this.shareWithDepartments, (item) => item.id === dailySchedules[i].departmentId);
          if (matchedItem && matchedItem.dailySchedules[dailySchedules[i].typeId]) {
            const shortPlaceholders = {
              name: this.getUserInitials(dailySchedules[i].sharedById)
            };
            const datailsPlaceholders = {
              name: this.$store.state.org.employees[dailySchedules[i].sharedById].fullName,
              date: moment(dailySchedules[i].sharedOn).format(this.dateFormatString),
              time: moment(dailySchedules[i].sharedOn).format('h:mm a')
            };
            matchedItem.dailySchedules[dailySchedules[i].typeId].lastShared = this.$t('descriptions.summaryLastShared', shortPlaceholders);
            matchedItem.dailySchedules[dailySchedules[i].typeId].lastSharedDetails = this.$t('descriptions.summaryLastSharedDetails', datailsPlaceholders);
          }
        }
        const idx = _.findIndex(this.dailySchedules, (ds) => ds.id === dailySchedules[i].id);
        if (idx >= 0) {
          this.dailySchedules.splice(idx, 1, dailySchedules[i]);
        } else {
          this.dailySchedules.push(dailySchedules[i]);
        }
      }
    },
    updateOpenShiftCount () {
      return new Promise((resolve, reject) => {
        const queryParams = {
          date: this.date,
          department_id: this.activeDepartment.id,
          open: 'true',
          self_schedule_only: 'false'
        };
        this.dispatch('scheduling/retrieveOpenShiftsCount', queryParams).then(count => {
          this.openShiftCount = count;
          resolve(this.openShiftCount);
        }).catch(() => {
          resolve(0);
        });
      });
    },
    updatePanel (panelIdx, panelDataCallback, params) {
      const panel = this.panels[panelIdx];
      const panelData = this.preparePanelData(panelDataCallback, params);
      if (panelData) {
        panelData.id = panel.id;
        panelData.panelDataCallback = panelDataCallback;
        panelData.params = params;
        this.panels.splice(panelIdx, 1, _.cloneDeep(panelData));
      }
    },
    wasShiftModifiedByManagement
  }
};
</script>

<style lang="scss">
.container.daily-schedule {
  height: 100%;
  margin-left: 0px;
  width: 1300px;

  .actions {
    .v-btn {
      border-radius: 18px !important;
      height: 36px !important;
      min-width: 36px !important;
      width: 36px !important;

      &::before {
        opacity: 0 !important;
      }
    }
  }

  .attendance-chip {
    display: inline-block;
    text-align: center;
    width: 80px;
  }
  .body-container {
    background-color: white;
    border-color: map-get($grey, 'lighten-3');
    border-style: solid;
    border-width: 1px;

    &.actions {
      border-bottom: none;
    }
  }
  .categories {
    .shift-column {
      padding-left: 26px;
    }
    tr {
      th:nth-child(2) {
        padding: 0px;
        text-align-last: center;
      }
      td:nth-child(2) {
        background-color: #F4F4F4;
        padding: 0px;
      }
    }
    td {
      position: relative;
    }
    .read-only-overlay {
      background: rgba(224, 224, 224, 0.3);
      height: 100%;
      left: 220px;
      pointer-events: none;
      position: absolute;
      top: 0px;
      width: 100%;
    }
    .schedule-items {
      border-top: 1px solid map-get($grey, 'lighten-3');
      &.single {
        .v-row-group__header {
          visibility: collapse;
        }
      }
      .management {
        font-size: 8px !important;
        position: absolute;
        top: 4px;
        right: 4px;
      }
      .payroll-diff {
        font-size: 8px !important;
        position: absolute;
        top: 4px;
        left: 4px;
      }
      .request {
        font-size: 8px !important;
        position: absolute;
        top: 4px;
        right: 24px;
      }
      .non-duty {
        border-top: 1px solid map-get($grey, 'darken-3');
        display: inline-block;
        left: 12px;
        position: absolute;
        top: 10px;
        -webkit-transform: rotate(20deg);
        -moz-transform: rotate(20deg);
        -ms-transform: rotate(20deg);
        -o-transform: rotate(20deg);
        transform: rotate(20deg);
        width: 30px;

        &.custom-time {
          left: 2px;
          top: 18px;
          -webkit-transform: rotate(42deg);
          -moz-transform: rotate(42deg);
          -ms-transform: rotate(42deg);
          -o-transform: rotate(42deg);
          transform: rotate(42deg);
          width: 50px;
        }
      }
      .shift-type {
        &.modified {
          span {
            font-weight: bold !important;
          }
        }
      }
      thead {
        visibility: collapse;
      }
      tr {
        th:nth-child(2) {
          padding: 0px;
          text-align-last: center;
        }
        td:nth-child(2) {
          background-color: #F4F4F4;
          padding: 0px;
        }
        &.v-data-table__expanded__content {
          background-color: map-get($grey, 'lighten-5') !important;
          box-shadow: none !important;
        }
      }
      td {
        .name-n-avatar {
          width: 144px;
        }
      }
      .v-row-group__header {
        background-color: #F5F5FF !important;
        cursor: default;

        td, td:hover {
          border: none !important;
          color: map-get($grey, 'darken-3');
        }
      }
    }
    .v-row-group__header {
      background-color: map-get($nb-azure, 'base') !important;
      cursor: pointer;
      td {
        border-bottom: thin solid map-get($nb-azure, 'darken-1') !important;
      }
    }

    .v-data-table-header {
      th {
        border-top: 1px solid map-get($grey, 'lighten-3');
        color: map-get($grey, 'darken-3') !important;
        &.sortable {
          color: $secondary !important;
        }
      }
    }
  }
  .cell-details {
    transition: width 0.3s;
  }
  .badge-panel {
    &.count {
      .v-badge__wrapper {
        span {
          font-size: 10px;
          height: 16px;
          min-width: 16px;
          width: 16px;
        }
      }
    }
    .v-badge__badge {
      margin-left: -12px;
      padding-top: 4px;
    }
    .status {
      &.no-errors {
        display: none;
      }
    }
  }
  .job-count {
    background-color: $primary-lighten-2;
    border-radius: 4px;
    color: white;
    display: inline-block;
    height: 16px;
    line-height: 13px;
    padding-top: 1px;
    margin-top: -2px;
    min-width: 16px;
    text-align: center;

    &.sitter {
      background-color: $primary-lighten-4;
    }
    &.imbalance {
      background-color: $error !important;
    }
    &.inner-table {
      vertical-align: top;
      margin-top: 1px;
    }
    span {
      font-size: 10px;
    }
  }
  .schedule-dropdown {
    border-color: map-get($grey, 'lighten-2');
    justify-content: start;
  }
  .sitter-info {
    span {
      width: 144px;
    }
  }
  .staff-new {
    position: absolute;
    right: -9px;
    bottom: 5px;
  }
  .staff-notes {
    position: absolute;
    right: -10px;
    top: 5px;
  }
  .has-errors {
    position: absolute;
    right: -6px;
    bottom: 8px;
  }
  .job-type-tabs {
    .v-slide-group__content {
      background-color: white;

      .v-tab--active {
        color: map-get($grey, 'darken-3') !important;
      }
    }
  }
  .flag-indicator {
    background-color: $info-lighten-2;
    border-color: $info-lighten-2;
    border-width: 1px;
    margin-top: -23px;
    position: absolute;
    width: 44px;
  }
}
.daily-schedule-notes {
  background-color: white;
}
#shareScheduleDialog {
  .daily-schedule-check {
    vertical-align: inherit;
  }
  .daily-schedule-label {
    width: 88px;
  }
  .dept-name {
    width: 145px;
  }
  .share-list {
    background-color: map-get($grey, 'lighten-5');
  }
  .share-progress {
    display: inline-block;
    vertical-align: inherit;
    width: 120px;
  }
  .share-progress-status {
    display: inline-block;
    margin-top: -4px;
    vertical-align: inherit;
  }
  .v-data-table__wrapper {
    .v-data-table-header {
      th {
        background-color: map-get($grey, 'lighten-5');
        border-bottom: none !important;
        color: $secondary !important;
        padding: 0px 4px;
      }
    }
    tbody {
      td, td:hover {
        border-bottom: none !important;
        height: 56px;
        padding: 10px 4px 0px 4px;
        vertical-align: top;
      }
    }
  }
}
.daily-schedule-picker {
  fieldset {
    background: $page-background !important;
  }
}
</style>
