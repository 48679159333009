const Mousetrap = require('mousetrap');
require('@/assets/js/mousetrap/plugins/mousetrap-record.min.js');
require('@/assets/js/mousetrap/plugins/mousetrap-global-bind.min.js');

// Override the stop callback to prevent keyboard events from trigering when focusing
// special elements such as vuetify components.

// The original callback stops the callback when the following elements are focused:
// INPUT, SELECT, TEXTAREA and elements with the property isContentEditable = 'true'.
// This only applies when using the "bind" method. Using "bindGlobal" always triggers
// regardless of what element is focused.
const _originalStopCallback = Mousetrap.prototype.stopCallback;
Mousetrap.prototype.stopCallback = function (e, element, combo, sequence) {
  if ((' ' + element.className + ' ').indexOf(' dropdown ') > -1) {
    return true;
  }
  return _originalStopCallback.call(this, e, element, combo, sequence);
};

export default Mousetrap;
