<template>
  <v-container
    class="px-8 py-3"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-calendar-week</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.weeklySummary') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-container
      class="weekly-summary pa-0 mt-4"
      :style="containerStyle"
    >
      <v-container
        v-if="hasUsers"
        class="pa-0"
      >
        <v-card
          v-for="(u) in users"
          :key="u.employee.id"
          class="mb-4"
          outlined
          @click="$emit('show-user-summary', u)"
        >
          <v-list
            class="pa-0"
            dense
            flat
          >
            <v-list-item-group active-class="">
              <v-list-item
                two-line
                dense
                class="py-2"
              >
                <v-list-item-content>
                  <span class="body-2 grey--text text--darken-3 font-weight-medium text-truncate">{{ u.employee.fullName }}</span>
                  <span class="caption grey--text text--darken-3">{{ [u.employee.jobTypeName, u.employee.jobStatusShortCode].join(' ') }}</span>
                </v-list-item-content>
                <v-list-item-content>
                  <v-chip
                    v-for="(error) in u.errors"
                    :key="error.label"
                    class="grey--text text--darken-3 error-type mb-1"
                    small
                  >
                    {{ $t(error.label) }}
                  </v-chip>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-container>
      <v-card
        v-else
        outlined
      >
        <NoContent
          exciting
          :height="140"
          :width="140"
          :message="$t('descriptions.noScheduleErrors')"
        />
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import NoContent from '@/components/NoContent';

export default {
  components: {
    NoContent
  },
  props: {
    header: {
      type: Object,
      required: true
    },
    users: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      height: 500
    };
  },
  computed: {
    containerStyle () {
      return {
        height: `${this.height}px`,
        overflow: 'scroll'
      };
    },
    hasUsers () {
      return !_.isEmpty(this.users);
    }
  },
  mounted () {
    this.updateContainerHeight();
    window.addEventListener('resize', _.debounce(this.updateContainerHeight, 200));
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateContainerHeight);
  },
  methods: {
    updateContainerHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      if (el) {
        const HEADER_HEIGHT = 90; // This includes padding
        this.height = el.clientHeight - HEADER_HEIGHT;
      }
    }
  }
};
</script>

<style lang="scss">
.weekly-summary {
  .error-type {
    background-color: $error-lighten-1 !important;
    flex: none !important;
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
</style>
