import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT_US } from '@/utils';
import { calculateShiftHours, getDuration, getRangeDisplayText, isWorkingShiftForValidation } from '@/utils/scheduling';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfMakeUnicode from 'pdfmake-unicode';

pdfMake.vfs = pdfMakeUnicode.pdfMake.vfs;

class UserSchedulePDF {
  constructor (user, store, t) {
    this.user = user;
    this.definition = {
      defaultStyle: {
        color: '#000000',
        fontSize: 12,
        lineHeight: 1
      },
      styles: {
        activityGroupLabel: {
          background: '#E0E0E0',
          bold: true,
          lineHeight: 0.9
        },
        activityDate: {
          bold: true
        },
        activityDetails: {
          color: '#757575',
          italics: true,
          margin: [0, 4, 0, 0]
        },
        activityDetailsList: {
          margin: [10, 0]
        },
        header: {
          fontSize: 10
        },
        hint: {
          color: '#9E9E9E',
          italics: true
        },
        overtime: {
          color: '#E74C3C',
          italics: true,
          margin: [0, 4, 0, 0]
        },
        subtitle: {
          alignment: 'center',
          color: '#9E9E9E'
        },
        title: {
          alignment: 'center',
          bold: true
        }
      },
      pageMargins: [40, 52, 40, 60],
      pageSize: 'LETTER'
    };
    this.header = '';
    this.title = [];
    this.shifts = [];
    this.store = store;
    this.t = t;
  }

  getDefinition (title) {
    return {
      ...this.definition,
      content: [
        this.title,
        {
          layout: {
            hLineWidth: function (i, node) {
              const noBorder = _.get(node.table.body, [i, 0, 'groupLabel'], false) || _.get(node.table.body, [i - 1, 0, 'groupLabel'], false);
              return (i === 0 || i === node.table.body.length || noBorder) ? 0 : 1;
            },
            vLineWidth: function (i, node) {
              return 0;
            },
            hLineColor: function (i, node) {
              return '#E0E0E0';
            },
            vLineColor: function (i, node) {
              return 'white';
            },
            paddingBottom: function (i, node) {
              const isGroupLabel = _.get(node.table.body, [i, 0, 'groupLabel'], false);
              return isGroupLabel ? 6 : 16;
            },
            paddingTop: function (i, node) {
              return 16;
            }
          },
          table: {
            widths: [200, '*'],
            body: this.shifts
          }
        }
      ],
      header: this.header,
      info: {
        title
      }
    };
  }

  /**
   * Sets pdf header. Placeholders available:
   *  {currentPage}
   *  {pageCount}
   * @param {string|array} headerLeft String or list of strings to place in top left header
   * @param {string|array} headerRight String or list of strings to place in top right header
   */
  setHeader (headerLeft, headerRight) {
    this.header = (currentPage, pageCount) => {
      const replacePlaceholders = (text) => {
        return text.replace('{currentPage}', currentPage).replace('{pageCount}', pageCount);
      };

      const left = {
        margin: [35, 10],
        width: '50%'
      };

      if (headerLeft) {
        if (_.isArray(headerLeft)) {
          left.stack = [];
          for (let i = 0, count = headerLeft.length; i < count; i++) {
            left.stack.push({
              text: replacePlaceholders(headerLeft[i]),
              alignment: 'left',
              style: ['header']
            });
          }
        } else if (typeof headerLeft === 'string') {
          left.text = replacePlaceholders(headerLeft);
          left.style = ['header'];
        }
      }

      const right = {
        margin: [35, 10],
        width: '50%'
      };

      if (headerRight) {
        if (_.isArray(headerRight)) {
          right.stack = [];
          for (let i = 0, count = headerRight.length; i < count; i++) {
            right.stack.push({
              text: replacePlaceholders(headerRight[i]),
              alignment: 'right',
              style: ['header']
            });
          }
        } else if (typeof headerRight === 'string') {
          right.text = replacePlaceholders(headerRight);
          right.style = ['header'];
        }
      }

      return {
        columns: [left, right]
      };
    };
  }

  /**
   * Sets title
   */
  setTitle (schedule, lastFloat, lastCancel) {
    const subtitle = [];
    if (lastFloat) {
      subtitle.push(this.t('labels.lastFloatDate', { date: moment(lastFloat).format(DATE_FORMAT_US) }));
    }
    if (lastCancel) {
      subtitle.push(this.t('labels.lastCancelDate', { date: moment(lastCancel).format(DATE_FORMAT_US) }));
    }
    this.title = [
      {
        style: ['title'],
        text: getRangeDisplayText(schedule.startOn, schedule.endOn, this.store)
      },
      {
        margin: [0, 0, 0, 8],
        style: ['title'],
        text: this.t('labels.weeklySchedule')
      },
      {
        style: ['title'],
        text: `${this.user.jobStatusShortCode} ${this.user.lastName}, ${this.user.firstName}`
      }
    ];

    if (subtitle.length > 0) {
      this.title.push({
        style: ['subtitle'],
        text: subtitle.join(', ')
      });
    }
  }

  /**
   * Adds activities to PDF
   * @param {object} activitiesByDate Activities keyed by their date
   * @param {string} label Label for the activities provided
   * @param {boolean} showWeekOvertime True to display total overtime for the activities provided
   */
  addActivities (activitiesByDate, label, showWeekOvertime) {
    const t = this.t;
    const departments = this.store.state.org.departments.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});
    const flags = this.store.state.org.flags.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});
    const shiftTypes = this.store.state.org.shiftTypes.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});
    const dateFormat = this.store.getters['org/getDateFormatLongWithDoW']();

    let totalHours = 0;
    const getActivityInfo = (activities) => {
      let isAvailable = false;
      const getList = (shift) => {
        const list = [];
        if (shift.onCall) {
          list.push({
            text: `${t('labels.onCall')}`,
            style: ['activityDetails']
          });
        }
        if (shift.overtime) {
          list.push({
            text: `${t('labels.overtime')}`,
            style: ['activityDetails']
          });
        }
        if (shift.flags && shift.flags.length > 0) {
          const flagNames = [];
          for (let i = 0, count = shift.flags.length; i < count; i++) {
            if (flags[shift.flags[i]]) {
              flagNames.push(flags[shift.flags[i]].name);
            }
          }
          list.push({
            text: `${flagNames.join(', ')}`,
            style: ['activityDetails']
          });
        }
        if (shift.sitter) {
          const sitter = [`${t('labels.sitter')}`];
          const sitterInfo = [];
          const room = _.get(shift, 'settings.sitter.room', null);
          const reason = _.get(shift, 'settings.sitter.reason', null);
          if (reason) {
            sitterInfo.push(reason);
          }
          if (room) {
            sitterInfo.push(`${t('labels.room')}: ${room}`);
          }
          if (sitterInfo.length > 0) {
            sitter.push(sitterInfo.join(', '));
          }
          list.push({
            text: sitter.join(' - '),
            style: ['activityDetails']
          });
        }
        if (shift.comments) {
          list.push({
            text: shift.comments,
            style: ['activityDetails']
          });
        }
        if (shift.available) {
          isAvailable = true;
        }
        return list;
      };
      const content = [];
      const shiftList = {
        type: 'none',
        ul: []
      };
      let hours = 0;
      for (let activityIndex = 0, activityCount = activities.length; activityIndex < activityCount; activityIndex++) {
        const shift = activities[activityIndex];
        const shiftType = shiftTypes[shift.typeId];
        const startTime = shift.startTime ? shift.startTime : shiftType.startTime;
        const endTime = shift.endTime ? shift.endTime : shiftType.endTime;
        const customTime = (startTime !== shiftType.startTime || endTime !== shiftType.endTime);
        const shiftInfo = [
          {
            text: [
              customTime ? '' : `${t('labels.shiftName', { name: shiftType.name })} `,
              `${_.split(startTime, ':', 2).join(':')} - ${_.split(endTime, ':', 2).join(':')}`,
              shift.obligatory ? '*' : '',
              '  │  ',
              departments[shift.departmentId].name
            ],
            margin: activityIndex > 0 ? [0, 4, 0, 0] : []
          },
          {
            color: '#757575',
            type: 'square',
            ul: getList(shift),
            style: 'activityDetailsList'
          }
        ];
        shiftList.ul.push(shiftInfo);
        if (isWorkingShiftForValidation(shift, flags)) {
          const shiftHours = calculateShiftHours(shift, shiftType);
          hours += shiftHours;
          totalHours += shiftHours;
        }
      }
      content.push(shiftList);
      let overtime = false;
      if (this.user.dailyHours && hours > this.user.dailyHours) {
        const startTime = moment().startOf('day');
        const endTime = startTime.clone().add(hours - this.user.dailyHours, 'hours');
        overtime = getDuration(startTime, endTime);
      }
      return {
        content,
        isAvailable,
        overtime
      };
    };

    const weeklyOvertime = {
      text: '',
      style: ['overtime']
    };
    if (label) {
      this.shifts.push([
        {
          groupLabel: true,
          stack: [
            {
              style: ['activityGroupLabel'],
              text: ` ${label} `
            },
            weeklyOvertime
          ]
        },
        ''
      ]);
    }
    if (_.isEmpty(activitiesByDate)) {
      this.shifts.push([
        {
          text: t('labels.noShifts'),
          style: ['hint']
        },
        ''
      ]);
    } else {
      for (let date in activitiesByDate) {
        const { content, isAvailable, overtime } = getActivityInfo(activitiesByDate[date]);
        this.shifts.push([
          [
            {
              text: [
                { text: moment(date).format(dateFormat), style: ['activityDate'] },
                { text: isAvailable ? '  Av' : '' }
              ]
            },
            overtime ? { text: t('descriptions.overtimeDaily', { time: overtime }), style: ['overtime'] } : ''
          ],
          [
            ...content
          ]
        ]);
      }
      if (showWeekOvertime && this.user.weeklyHours && totalHours > this.user.weeklyHours) {
        const startTime = moment().startOf('day');
        const endTime = startTime.clone().add(totalHours - this.user.weeklyHours, 'hours');
        weeklyOvertime.text = t('descriptions.overtimeWeekly', { time: getDuration(startTime, endTime) });
      }
    }
  }

  /**
   * Gets the base64 representation of the PDF.
   * @return {Promise} Resolves to the base64 string of the pdf file
   */
  download (title) {
    const win = window.open('', '_blank');
    pdfMake.createPdf(this.getDefinition(title)).print({}, win);
  }
}

export default UserSchedulePDF;
