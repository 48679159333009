<template>
  <v-container
    class="px-8 py-3 user-errors-overview"
  >
    <v-list-item
      v-if="showHeader"
      class="headline page-title pa-0"
    >
      <v-list-item-icon class="icon">
        <v-icon>fal fa-calendar-week</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.weeklySummary') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-tabs
      v-if="errors.length > 0"
      id="errorsContent"
      centered
      class="dense"
      grow
      hide-slider
      show-arrows
    >
      <v-tab
        v-for="error in errors"
        :key="`tab${getValidatorName(error)}`"
        class="text-capitalize"
        :href="`#${getValidatorName(error)}`"
      >
        {{ $t(error.label) }}
      </v-tab>

      <v-tab-item
        v-for="error in errors"
        :key="`content${getValidatorName(error)}`"
        :style="tabItemStyle"
        :value="getValidatorName(error)"
      >
        <component
          :is="getValidatorName(error)"
          :error="error"
          :user="user"
          @show-shift="(shift) => $emit('show-shift', shift)"
        />
      </v-tab-item>
    </v-tabs>
    <v-card
      v-else
      outlined
    >
      <NoContent
        exciting
        :height="140"
        :width="140"
        :message="$t('descriptions.noScheduleErrorsForUser', { name: user.fullName })"
      />
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash';
import ImbalanceValidator from '@/views/scheduling/validators/imbalance/ImbalanceValidator.js';
import OvertimeValidator from '@/views/scheduling/validators/overtime/OvertimeValidator.js';
import ConsecutiveShiftsValidator from '@/views/scheduling/validators/consecutive_shifts/ConsecutiveShiftsValidator.js';
import TimeConflictValidator from '@/views/scheduling/validators/time_conflict/TimeConflictValidator.js';
import UserImbalance from '@/views/scheduling/validators/imbalance/UserImbalance.vue';
import UserOvertime from '@/views/scheduling/validators/overtime/UserOvertime.vue';
import UserConsecutiveShifts from '@/views/scheduling/validators/consecutive_shifts/UserConsecutiveShifts.vue';
import UserTimeConflict from '@/views/scheduling/validators/time_conflict/UserTimeConflict.vue';
import UserMinimumCount from '@/views/scheduling/validators/minimum_count/UserMinimumCount.vue';
import NoContent from '@/components/NoContent';

export default {
  components: {
    NoContent,
    UserImbalance,
    UserOvertime,
    UserConsecutiveShifts,
    UserMinimumCount,
    UserTimeConflict
  },
  props: {
    header: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: true
    },
    showHeader: {
      default: true,
      type: Boolean
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tabHeight: 500,
      validators: {
        [ImbalanceValidator.name]: 'UserImbalance',
        [OvertimeValidator.name]: 'UserOvertime',
        [ConsecutiveShiftsValidator.name]: 'UserConsecutiveShifts',
        [TimeConflictValidator.name]: 'UserTimeConflict'
      }
    };
  },
  computed: {
    tabItemStyle () {
      const PADDING = 20;
      return {
        'height': `${this.tabHeight - PADDING}px`
      };
    }
  },
  mounted: function () {
    this.updateTabHeight();
    window.addEventListener('resize', _.debounce(this.updateTabHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateTabHeight);
  },
  methods: {
    getValidatorName (error) {
      return this.validators[error.name];
    },
    updateTabHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const errorsContent = document.getElementById('errorsContent');
      if (el && errorsContent) {
        this.tabHeight = el.clientHeight - errorsContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>

<style lang="scss">
.user-errors-overview {
  @include round-tabs(#837EB7, #FFF);

  .v-tab {
    padding-left: 0px;
    padding-right: 0px;
    width: 125px;
  }
}
</style>
