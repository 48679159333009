var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "review-comments px-8 py-0" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v("\n      " + _vm._s(_vm.$tc("labels.comment", 2)) + "\n    ")
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("labels.requestComment")))]),
              _c("v-textarea", {
                attrs: {
                  "auto-grow": true,
                  counter: "1000",
                  maxlength: "1000",
                  outlined: "",
                  placeholder: "" + _vm.$t("descriptions.disclaimer"),
                  rows: "6",
                  "single-line": ""
                },
                model: {
                  value: _vm.updatedComments,
                  callback: function($$v) {
                    _vm.updatedComments = $$v
                  },
                  expression: "updatedComments"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "end" } },
        [
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "6" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "px-8",
                  attrs: { color: "accent" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("reject", _vm.updatedComments)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.reject")) + "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }