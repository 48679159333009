<template>
  <v-container
    id="AddEvent"
    class="px-0 py-1"
  >
    <v-list-item class="headline page-title py-0 px-8">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-plus</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $tc('labels.addEvent', 1) }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card
      class="mx-8 pa-2 select-event"
      outlined
    >
      <v-row
        class="px-1"
        no-gutters
      >
        <v-col
          class="subtitle-2 py-2"
          cols="auto"
        >
          {{ $t('labels.selectEvent') }}
        </v-col>
      </v-row>
      <v-select
        v-model="typeId"
        class="event-type mt-2 px-1"
        dense
        hide-details
        item-text="name"
        item-value="id"
        :items="eventTypes"
        :placeholder="$tc('labels.event', 1)"
        outlined
      />
      <v-menu
        ref="rangeDatePicker"
        v-model="showDatePicker"
        :close-on-content-click="false"
        :return-value.sync="dates"
        offset-y
        max-width="290px"
        min-width="290px"
        nudge-right="40px"
        nudge-top="24px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-container class="px-0 pb-0">
            <v-row class="px-1">
              <v-col
                cols="12"
              >
                <v-combobox
                  v-model="dates"
                  append-icon="fal fa-calendar-alt"
                  class="event-dates mt-0 pt-0"
                  color="secondary"
                  multiple
                  chips
                  dense
                  hide-details
                  :label="$tc('labels.date', 2)"
                  outlined
                  small-chips
                  readonly
                  v-bind="attrs"
                  v-on="{ ...on, 'click:append': (e) => on.click(e) }"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="data.item"
                      v-bind="data.attrs"
                      class="date-chip grey--text text--darken-1 mb-1"
                      color="grey lighten-3"
                      small
                      v-on="on"
                    >
                      {{ formatDate(data.item) }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <v-date-picker
          v-model="dates"
          class="schedule-details-date-range-picker"
          multiple
          no-title
          scrollable
          @click:date="updateStaffSelectionHeight"
        >
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="showDatePicker = false"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="$refs.rangeDatePicker.save(dates)"
          >
            {{ $t('labels.ok') }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-card>
    <StaffSelection
      class="px-8 mt-4"
      :date="date"
      :height="height"
      :selected-staff="selectedStaff"
      :show-flout-in="false"
      :show-flout-out="false"
      :type="'event'"
      @add-staff="setAssigneeId"
      @undo-staff="unsetAssigneeId"
    />
    <v-row
      v-if="$can('edit', 'event')"
      class="create-event px-8"
    >
      <v-spacer />
      <v-col cols="6">
        <v-btn
          block
          color="accent"
          :disabled="hasInvalidData || creating"
          @click="create"
        >
          <v-progress-circular
            v-if="creating"
            color="primary lighten-2"
            indeterminate
            size="22"
            width="2"
          />
          <span v-else>
            {{ $t('labels.add') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import StaffSelection from '@/components/scheduling/StaffSelection';
import { showStatus } from '@/plugins/vue-notification';
import { DATE_FORMAT } from '@/utils';

export default {
  components: {
    StaffSelection
  },
  props: {
    date: {
      default: function () {
        return moment().format(DATE_FORMAT);
      },
      type: String
    }
  },
  data () {
    return {
      DATE_FORMAT,
      assigneeId: null,
      contentHeight: 500,
      creating: false,
      dates: [],
      height: 500,
      showDatePicker: false,
      typeId: null
    };
  },
  computed: {
    activeDepartment () {
      return this.$store.getters['org/getActiveDepartment']();
    },
    eventTypes () {
      return _.sortBy(this.$store.state.org.eventTypes, (e) => e.name);
    },
    hasInvalidData () {
      return !this.typeId || this.dates.length === 0 || !this.assigneeId;
    },
    selectedStaff () {
      const selectedStaff = {};
      if (this.assigneeId) {
        selectedStaff[this.assigneeId] = {
          departmentId: this.activeDepartment.id,
          typeId: this.typeId
        };
      }
      return selectedStaff;
    }
  },
  watch: {
    dates () {
      this.updateStaffSelectionHeight();
    }
  },
  mounted: function () {
    this.updateStaffSelectionHeight();
    window.addEventListener('resize', _.debounce(this.updateStaffSelectionHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateStaffSelectionHeight);
  },
  methods: {
    create () {
      this.creating = true;
      const event = {
        assigneeId: this.assigneeId,
        departmentId: this.activeDepartment.id,
        typeId: this.typeId,
        dates: this.dates
      };
      this.dispatch('scheduling/createEvents', [event]).then((events) => {
        event.id = events[0].id;
        event.dates = event.dates.map((d) => moment(d));
        event.type = 'event';
        this.$store.commit('scheduling/add_event', {
          event
        });
        this.$emit('created', event);
        showStatus({
          text: this.$t('descriptions.eventSaveSuccess')
        });
      }).catch(error => {
        const data = {
          error: _.get(error, 'response.data')
        };

        showStatus({
          text: this.$t('descriptions.eventSaveFail'),
          type: 'error',
          data
        });
      }).finally(() => {
        this.reset();
      });
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatDate (date) {
      return moment(date).format('YYYY/MM/DD');
    },
    moment,
    reset () {
      this.assigneeId = null;
      this.creating = false;
      this.dates = [];
      this.typeId = null;
    },
    setAssigneeId (data) {
      this.assigneeId = data.employee.userId;
    },
    unsetAssigneeId () {
      this.assigneeId = null;
    },
    updateStaffSelectionHeight () {
      this.$nextTick(() => {
        const el = document.getElementsByClassName('side-panel')[0];
        const tabContent = document.getElementById('AddEvent');
        if (el && tabContent) {
          const tabHeight = el.clientHeight - tabContent.getBoundingClientRect().top;
          const selectEventHeight = tabContent.getElementsByClassName('select-event')[0].clientHeight;
          this.height = tabHeight - selectEventHeight - 40;
        }
      });
    }
  }
};
</script>

<style lang="scss">
#AddEvent {
  @include round-tabs(#837EB7, #FFF);
  .event-dates {
    .v-select__selections {
      margin-top: 4px !important;
    }
  }
}
</style>
