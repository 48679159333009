<template>
  <v-combobox
    ref="selectEmployees"
    v-model="selected"
    class="employee-selector"
    dense
    :disabled="disabled"
    :filter="comboboxFilter"
    :hide-details="!hint"
    :hint="hint"
    item-value="userId"
    :items="filteredEmployees"
    :label="label"
    multiple
    outlined
    :persistent-hint="!!hint"
    :return-object="false"
    @change="onChange"
  >
    <template #selection="{ item }">
      <v-chip
        v-if="employees[item]"
        class="selected"
        close
        color="white"
        @click:close="removeSelectedEmployee(item)"
      >
        <v-avatar
          :color="employees[item].avatarBgColor"
          size="18"
        >
          <span class="white--text caption-2">
            {{ getAvatar(employees[item]) }}
          </span>
        </v-avatar>
        <span class="ml-2 body-2 grey--text text--darken-3 name text-truncate">
          {{ employees[item].fullName }}
        </span>
      </v-chip>
    </template>
    <template v-slot:item="{ attrs, item, on }">
      <v-list-item
        :class="[selected.includes(item.userId) ? 'v-list-item--active' : '', '']"
        :title="item.fullName"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-avatar class="mr-1">
          <v-avatar
            :color="item.avatarBgColor"
            size="30"
          >
            <span class="white--text subtitle-2">
              {{ getAvatar(item) }}
            </span>
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="body-2 name-n-avatar mb-n1"
            :title="item.fullName"
          >
            <span
              class="d-inline-block text-truncate"
              style="width: 164px;"
            >
              {{ item.fullName }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
            <span>
              {{ item.jobTypeName }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-combobox>
</template>>

<script>
import _ from 'lodash';
import { getAvatar } from '@/utils';

export default {
  props: {
    disabled: {
      default: true,
      type: Boolean
    },
    filters: {
      default () {
        return [];
      },
      type: Array
    },
    hint: {
      default: '',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    multiple: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      selected: []
    };
  },
  computed: {
    employees () {
      return this.$store.state.org.employees;
    },
    filteredEmployees () {
      const employees = [];
      const matches = (e) => {
        let match = true;
        for (let f of this.filters) {
          switch (f.op) {
            case '=':
              match &= _.get(e, f.prop, null) === f.value;
              break;
            case 'in':
              match &= f.value.includes(_.get(e, f.prop, null));
              break;
          }
        }
        return match;
      };
      for (let eId in this.$store.state.org.employees) {
        if (matches(this.$store.state.org.employees[eId])) {
          employees.push(this.$store.state.org.employees[eId]);
        }
      }
      return _.sortBy(employees, ['fullName']);
    }
  },
  methods: {
    comboboxFilter (item, queryText) {
      return item.fullName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    getAvatar,
    onChange () {
      this.selected = _.filter(this.selected, _.isFinite);
      this.$emit('change', this.selected);
    },
    removeSelectedEmployee (item) {
      const idx = _.findIndex(this.selected, (id) => id === item);
      if (idx >= 0) {
        this.selected.splice(idx, 1);
        this.onChange();
      }
    }
  }
};
</script>

<style lang="scss">
.employee-selector {
  .selected {
    border: 1px solid map-get($grey, 'lighten-2') !important;
    margin-top: 4px !important;
    text-shadow: none;
    .name {
      max-width: 120px;
    }
    .v-avatar {
      font-size: 9px !important;
      height: 18px !important;
      min-width: 18px !important;
      width: 18px !important;
    }
  }
  .v-text-field__details {
    padding: 0px !important;
    .v-messages__message {
      color: map-get($grey, 'base');
    }
  }
}
</style>
