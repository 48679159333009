<template>
  <v-container
    class="swap-request pa-0"
  >
    <v-row
      class="d-inline-block"
      dense
    >
      <v-col
        class="details"
        cols="12"
      >
        <v-list-item
          class="px-0 d-inline-block"
        >
          <v-list-item-content>
            <v-list-item-title class="body-2 mb-1">
              <UserName
                :user="$store.state.org.employees[request.assocContent.sourceUserId]"
              />
            </v-list-item-title>
            <v-list-item-subtitle class="caption mt-1">
              {{ getJobInfo(request.assocContent.sourceUserId) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <div class="d-inline-flex">
          <span class="grey--text text--darken-3">
            {{ sourceDepartment }}
          </span>
          <v-divider
            class="d-inline mx-2"
            vertical
          />
          <span class="grey--text text--darken-3">
            {{ sourceShiftType }}
          </span>
          <v-icon
            class="mx-9"
            color="warning"
            size="20"
          >
            fal fa-exchange
          </v-icon>
        </div>
        <v-list-item
          class="px-0 d-inline-block"
        >
          <v-list-item-content>
            <v-list-item-title class="body-2 mb-1">
              <UserName
                :user="$store.state.org.employees[request.assocContent.targetUserId]"
              />
            </v-list-item-title>
            <v-list-item-subtitle class="caption mt-1">
              {{ getJobInfo(request.assocContent.targetUserId) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <div class="d-inline-flex">
          <span class="grey--text text--darken-3">
            {{ targetDepartment }}
          </span>
          <v-divider
            class="d-inline mx-2"
            vertical
          />
          <span class="grey--text text--darken-3">
            {{ targetShiftType }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import UserName from '@/components/scheduling/UserName';

export default {
  components: {
    UserName
  },
  props: {
    request: {
      required: true,
      type: Object
    }
  },
  data () {
    return {};
  },
  computed: {
    sourceDepartment () {
      return this.$store.getters['org/getDepartmentById'](this.request.assocContent.sourceDepartmentId, 'name');
    },
    sourceShiftDate () {
      return moment(this.request.assocContent.sourceShiftDate).format(this.$store.getters['org/getDateFormatLong']());
    },
    sourceShiftType () {
      return this.$store.getters['org/getShiftTypeById'](this.request.assocContent.sourceShiftTypeId, 'name');
    },
    targetDepartment () {
      return this.$store.getters['org/getDepartmentById'](this.request.assocContent.targetDepartmentId, 'name');
    },
    targetShiftDate () {
      return moment(this.request.assocContent.targetShiftDate).format(this.$store.getters['org/getDateFormatLong']());
    },
    targetShiftType () {
      return this.$store.getters['org/getShiftTypeById'](this.request.assocContent.targetShiftTypeId, 'name');
    }
  },
  methods: {
    getJobInfo (userId) {
      const userInfo = this.$store.state.org.employees[userId];
      return [userInfo.jobTypeName, userInfo.jobStatusShortCode].filter(Boolean).join(' ');
    }
  }
};
</script>

<style lang="scss">
.swap-request {
  .details {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 72px;
  }
}
</style>
