<template>
  <v-list
    v-if="errorCount > 0"
    dense
  >
    <span class="body-2 grey--text text--darken-3">
      {{ $t('descriptions.consecutiveShiftsHint') }}
    </span>
    <template v-for="(error, errorIdx) in errors">
      <v-list-item
        :key="`${error.user.id}`"
        @click="$emit('filter', getFilter(error.user))"
      >
        <v-list-item-content class="subtitle-2 font-weight-regular d-inline-block text-truncate pr-1">
          {{ error.user.fullName }}
        </v-list-item-content>
        <v-list-item-action>
          <span
            class="mx-4 caption d-inline-block text-truncate pr-1 info--text"
          >
            {{ getUserStatus(error.user) }}
          </span>
        </v-list-item-action>
        <v-list-item-action class="subtitle-2 font-weight-medium d-inline-block text-truncate error--text">
          {{ error.count }}
        </v-list-item-action>
      </v-list-item>
      <v-divider
        v-if="errorIdx < errorCount - 1"
        :key="`div${error.user.id}`"
      />
    </template>
  </v-list>
  <NoContent
    v-else
    :height="140"
    :exciting="true"
    :width="140"
    :message="$t('descriptions.consecutiveShiftsNoErrors')"
  />
</template>

<script>
import _ from 'lodash';
import NoContent from '@/components/NoContent';

export default {
  components: {
    NoContent
  },
  props: {
    jobTypeIds: {
      type: Array,
      default: function () {
        return [];
      }
    },
    scheduleId: {
      type: [Number, String],
      required: true
    },
    shiftType: {
      type: Number,
      default: null
    },
    validator: {
      type: Object,
      required: true
    }
  },
  computed: {
    errorCount () {
      return this.errors.length;
    },

    errors () {
      const errors = [];
      const users = this.state.data;
      const employees = this.$store.state.org.employees;
      for (let id in users) {
        const user = employees[id];
        if (user) {
          const userJobId = user.jobTypeId;
          const userShiftId = user.shiftTypeId;
          const count = _.keys(users[id].errors).length;
          if (count > 0) {
            let match = false;
            const associatedJobTypes = this.getAssociatedJobTypes();
            if (associatedJobTypes && this.shiftType) {
              if (associatedJobTypes.length > 0) {
                match = (_.indexOf(associatedJobTypes, userJobId) >= 0 && this.shiftType === userShiftId);
              }
            } else if (associatedJobTypes) {
              if (associatedJobTypes.length > 0) {
                match = (_.indexOf(associatedJobTypes, userJobId) >= 0);
              }
            } else if (this.shiftType) {
              match = (this.shiftType === userShiftId);
            } else {
              match = true;
            }

            if (match) {
              errors.push({
                user,
                count
              });
            }
          }
        }
      }

      return _.sortBy(errors, [(error) => { return error.user.fullName; }]);
    },
    jobTypes () {
      return this.$store.getters['scheduling/getJobTypesById'](this.scheduleId);
    },
    state () {
      return this.validator.state;
    }
  },
  methods: {
    getAssociatedJobTypes () {
      if (this.jobTypeIds && this.jobTypeIds.length > 0) {
        return this.jobTypeIds.map((id) => {
          return _.get(this.jobTypes, [id, 'associatedJobTypes'], []);
        }).reduce((accumulator, currentValue) => {
          accumulator.push(...currentValue);
          return accumulator;
        }, []);
      }
      return null;
    },
    getFilter (user) {
      return {
        job: user.jobTypeId,
        shift: this.shiftType,
        user
      };
    },
    getUserStatus (user) {
      return [user.jobTypeName, user.jobStatusShortCode].join(' ');
    }
  }
};
</script>

<style lang="scss" >
</style>
