var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { position: "absolute", top: "12px", right: "32px" },
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("fal fa-times")])],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          color: "info",
                          indeterminate: "",
                          size: "75",
                          width: "6"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("descriptions.loading")))])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _vm.loadFailed
    ? _c(
        "v-row",
        { staticStyle: { height: "100%" }, attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { position: "absolute", top: "12px", right: "32px" },
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [_c("v-icon", [_vm._v("fal fa-times")])],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src: require("@/assets/images/oops-penguin.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("headlines.genericError")))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      )
    : _c(
        "v-container",
        { staticClass: "px-8 py-0", attrs: { id: "selfSchedule" } },
        [
          _c(
            "v-list-item",
            { staticClass: "headline page-title pa-0" },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "icon" },
                [_c("v-icon", [_vm._v("fal fa-calendar-alt")])],
                1
              ),
              _c("v-list-item-content", { staticClass: "panel-title" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("labels.selfSchedule")) + "\n    "
                )
              ]),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-times")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { attrs: { id: "selfScheduleContent", outlined: "" } },
            [
              _c(
                "v-toolbar",
                { attrs: { dense: "", flat: "", height: "28" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        color: "grey darken-2",
                        fab: "",
                        height: "22",
                        text: "",
                        width: "22"
                      },
                      on: { click: _vm.prev }
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("\n          fal fa-chevron-left\n        ")
                      ])
                    ],
                    1
                  ),
                  _vm.$refs.calendar
                    ? _c("v-toolbar-title", { staticClass: "body-2" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$refs.calendar.title) +
                            "\n      "
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        color: "grey darken-2",
                        fab: "",
                        height: "22",
                        text: "",
                        width: "22"
                      },
                      on: { click: _vm.next }
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("\n          fal fa-chevron-right\n        ")
                      ])
                    ],
                    1
                  ),
                  _c("v-spacer")
                ],
                1
              ),
              _c("v-calendar", {
                ref: "calendar",
                attrs: { color: "primary", type: "month" },
                scopedSlots: _vm._u([
                  {
                    key: "day-label",
                    fn: function(ref) {
                      var date = ref.date
                      var day = ref.day
                      return [
                        _c(
                          "div",
                          {
                            class: _vm.markDisabled(date) ? "disabled" : "",
                            on: {
                              click: function($event) {
                                _vm.markDisabled(date)
                                  ? null
                                  : _vm.onDayPress(date)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "caption-2 accent--text mt-n1",
                                staticStyle: { height: "20px" }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      day === 1
                                        ? _vm.moment(date).format("MMM")
                                        : ""
                                    ) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm.consolidatedItinerary[date] &&
                            _vm.consolidatedItinerary[date].hasFlags
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass: "flag-indicator",
                                    attrs: { size: "4" }
                                  },
                                  [
                                    _vm._v(
                                      "\n            fas fa-circle\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.consolidatedItinerary[date] &&
                            _vm.consolidatedItinerary[date].errors
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { "max-width": "300px", top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "validation-error",
                                                      attrs: {
                                                        color: "error",
                                                        "x-small": ""
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                fas fa-caret-down\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.getErrorMessage(
                                              _vm.consolidatedItinerary[date]
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm.consolidatedItinerary[date] &&
                            _vm.consolidatedItinerary[date].hasShift
                              ? _c(
                                  "v-avatar",
                                  {
                                    staticClass: "mt-n1",
                                    style: _vm.getShiftStyle(
                                      _vm.consolidatedItinerary[date]
                                    ),
                                    attrs: { color: _vm.shiftColor, size: "28" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "caption grey--text text--darken-3"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(day) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm.showSlits(
                                      _vm.consolidatedItinerary[date]
                                    )
                                      ? [
                                          _c("div", {
                                            staticClass: "left-slit"
                                          }),
                                          _c("div", {
                                            staticClass: "right-slit"
                                          })
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _c(
                                  "v-avatar",
                                  {
                                    staticClass: "mt-n1",
                                    attrs: {
                                      color: _vm.markDisabled(date)
                                        ? ""
                                        : "white",
                                      size: "28"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "caption grey--text text--darken-3"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(day) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "day",
                    fn: function(ref) {
                      var past = ref.past
                      var date = ref.date
                      return [
                        _c(
                          "v-container",
                          {
                            class: [
                              "d-block pa-0 text-center day-slot mt-n1",
                              date < _vm.scheduleStartDate ||
                              date > _vm.scheduleEndDate ||
                              (_vm.consolidatedItinerary[date] &&
                                _vm.consolidatedItinerary[date].disabled)
                                ? "disabled"
                                : ""
                            ],
                            attrs: { "fill-height": "" }
                          },
                          [
                            _vm.consolidatedItinerary[date] &&
                            _vm.consolidatedItinerary[date].loading
                              ? _c("v-progress-circular", {
                                  staticClass: "mt-2",
                                  attrs: {
                                    color: "primary lighten-2",
                                    indeterminate: "",
                                    size: "22",
                                    width: "2"
                                  }
                                })
                              : [
                                  date >= _vm.scheduleStartDate &&
                                  date <= _vm.scheduleEndDate
                                    ? _c("v-chip", {
                                        class: ["pill-symbol mt-2"],
                                        style: {
                                          visibility:
                                            _vm.consolidatedItinerary[date] &&
                                            _vm.consolidatedItinerary[date]
                                              .canBid
                                              ? "visible"
                                              : "hidden"
                                        },
                                        attrs: {
                                          color: _vm.shiftColor,
                                          outlined:
                                            _vm.tab === "addOnCallShift",
                                          "x-small": ""
                                        }
                                      })
                                    : _vm._e(),
                                  _vm.consolidatedItinerary[date] &&
                                  _vm.consolidatedItinerary[date].hasEvent
                                    ? [
                                        _vm.consolidatedItinerary[date]
                                          .startingDay &&
                                        _vm.consolidatedItinerary[date]
                                          .endingDay
                                          ? _c("div", {
                                              staticClass: "start-and-end",
                                              style: {
                                                "background-color":
                                                  _vm.consolidatedItinerary[
                                                    date
                                                  ].eventColor
                                              }
                                            })
                                          : _vm.consolidatedItinerary[date]
                                              .startingDay &&
                                            !_vm.consolidatedItinerary[date]
                                              .endingDay
                                          ? _c("div", {
                                              staticClass: "start-only",
                                              style: {
                                                "background-color":
                                                  _vm.consolidatedItinerary[
                                                    date
                                                  ].eventColor
                                              }
                                            })
                                          : !_vm.consolidatedItinerary[date]
                                              .startingDay &&
                                            _vm.consolidatedItinerary[date]
                                              .endingDay
                                          ? _c("div", {
                                              staticClass: "end-only",
                                              style: {
                                                "background-color":
                                                  _vm.consolidatedItinerary[
                                                    date
                                                  ].eventColor
                                              }
                                            })
                                          : _c("div", {
                                              staticClass: "extend",
                                              style: {
                                                "background-color":
                                                  _vm.consolidatedItinerary[
                                                    date
                                                  ].eventColor
                                              }
                                            })
                                      ]
                                    : _vm._e()
                                ]
                          ],
                          2
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.visibleDate,
                  callback: function($$v) {
                    _vm.visibleDate = $$v
                  },
                  expression: "visibleDate"
                }
              }),
              _c(
                "v-container",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "dense",
                      attrs: {
                        id: "selfScheduleAddActivites",
                        centered: "",
                        color: "accent",
                        grow: ""
                      },
                      model: {
                        value: _vm.tab,
                        callback: function($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          staticClass: "text-capitalize",
                          attrs: { disabled: _vm.hasChanges, href: "#addShift" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$tc("labels.addShift", 1)) +
                              "\n        "
                          )
                        ]
                      ),
                      _vm.allowOnCallShifts
                        ? _c(
                            "v-tab",
                            {
                              staticClass: "text-capitalize",
                              attrs: {
                                disabled: _vm.hasChanges,
                                href: "#addOnCallShift"
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("labels.addOnCallShift")) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.allowAvailableShifts
                        ? _c(
                            "v-tab",
                            {
                              staticClass: "text-capitalize",
                              attrs: {
                                disabled: _vm.hasChanges,
                                href: "#addAvailableShift"
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("labels.markAvailability")) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-tab",
                        {
                          staticClass: "text-capitalize",
                          attrs: { disabled: _vm.hasChanges, href: "#addEvent" }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$tc("labels.addEvent", 1)) +
                              "\n        "
                          )
                        ]
                      ),
                      _c(
                        "v-tab-item",
                        { staticClass: "pa-4", attrs: { value: "addShift" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass:
                                "caption dense font-weight-medium mx-0",
                              attrs: {
                                color: "info",
                                dense: "",
                                outlined: "",
                                text: ""
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-n1 mr-3",
                                  attrs: {
                                    slot: "prepend",
                                    color: "info",
                                    size: "12"
                                  },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n              fas fa-info-circle\n            "
                                  )
                                ]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("descriptions.selfScheduleAddShift")
                                  ) +
                                  "\n          "
                              )
                            ],
                            1
                          ),
                          _c("v-select", {
                            ref: "selectShift",
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.shiftTypes,
                              label: _vm.$t("labels.shift"),
                              outlined: ""
                            },
                            model: {
                              value: _vm.shiftType,
                              callback: function($$v) {
                                _vm.shiftType = $$v
                              },
                              expression: "shiftType"
                            }
                          }),
                          !_vm.bidForOpening
                            ? _c(
                                "v-container",
                                { staticClass: "px-0 pb-0 text-right" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "save-shifts",
                                      attrs: {
                                        color: "secondary",
                                        disabled: !_vm.hasChanges || _vm.saving
                                      },
                                      on: { click: _vm.saveActivities }
                                    },
                                    [
                                      _vm.saving
                                        ? _c("v-progress-circular", {
                                            attrs: {
                                              color: "secondary",
                                              indeterminate: "",
                                              size: "22",
                                              width: "2"
                                            }
                                          })
                                        : _c("span", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("labels.save")) +
                                                "\n              "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        {
                          staticClass: "pa-4",
                          attrs: { value: "addOnCallShift" }
                        },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass:
                                "caption dense font-weight-medium mx-0",
                              attrs: {
                                color: "info",
                                dense: "",
                                outlined: "",
                                text: ""
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-n1 mr-3",
                                  attrs: {
                                    slot: "prepend",
                                    color: "info",
                                    size: "12"
                                  },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n              fas fa-info-circle\n            "
                                  )
                                ]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("descriptions.selfScheduleAddShift")
                                  ) +
                                  "\n          "
                              )
                            ],
                            1
                          ),
                          _c("v-select", {
                            ref: "selectOnShift",
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.onCallShiftTypes,
                              label: _vm.$t("labels.shift"),
                              outlined: ""
                            },
                            model: {
                              value: _vm.onCallShiftType,
                              callback: function($$v) {
                                _vm.onCallShiftType = $$v
                              },
                              expression: "onCallShiftType"
                            }
                          }),
                          !_vm.bidForOpening
                            ? _c(
                                "v-container",
                                { staticClass: "px-0 pb-0 text-right" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "save-shifts",
                                      attrs: {
                                        color: "secondary",
                                        disabled: !_vm.hasChanges || _vm.saving
                                      },
                                      on: { click: _vm.saveActivities }
                                    },
                                    [
                                      _vm.saving
                                        ? _c("v-progress-circular", {
                                            attrs: {
                                              color: "secondary",
                                              indeterminate: "",
                                              size: "22",
                                              width: "2"
                                            }
                                          })
                                        : _c("span", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("labels.save")) +
                                                "\n              "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.allowAvailableShifts
                        ? _c(
                            "v-tab-item",
                            {
                              staticClass: "pa-4",
                              attrs: { value: "addAvailableShift" }
                            },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass:
                                    "caption dense font-weight-medium mx-0",
                                  attrs: {
                                    color: "info",
                                    dense: "",
                                    outlined: "",
                                    text: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-n1 mr-3",
                                      attrs: {
                                        slot: "prepend",
                                        color: "info",
                                        size: "12"
                                      },
                                      slot: "prepend"
                                    },
                                    [
                                      _vm._v(
                                        "\n              fas fa-info-circle\n            "
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "descriptions.selfScheduleMarkAvailability"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ],
                                1
                              ),
                              _vm.allowPartialShift
                                ? [
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c("v-select", {
                                              ref: "selectAvailableShift",
                                              attrs: {
                                                dense: "",
                                                "hide-details": "",
                                                "item-text": "name",
                                                "item-value": "id",
                                                items: _vm.availableShiftTypes,
                                                label: _vm.$t("labels.shift"),
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.availableShiftType,
                                                callback: function($$v) {
                                                  _vm.availableShiftType = $$v
                                                },
                                                expression: "availableShiftType"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                key:
                                                  "start-" +
                                                  _vm.shiftStartTimeCount,
                                                ref: "shiftStartTimeMenu",
                                                attrs: {
                                                  "close-on-content-click": "",
                                                  "offset-y": "",
                                                  "max-height": "300px",
                                                  "max-width": "100px",
                                                  "min-width": "100px",
                                                  "nudge-bottom": 0,
                                                  "nudge-left": 0
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "VeeTextField",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "mask",
                                                                      rawName:
                                                                        "v-mask",
                                                                      value:
                                                                        _vm.timeMask,
                                                                      expression:
                                                                        "timeMask"
                                                                    }
                                                                  ],
                                                                  ref:
                                                                    "shiftStartTime",
                                                                  staticClass:
                                                                    "shift-time d-inline-block",
                                                                  attrs: {
                                                                    autocomplete: false,
                                                                    dense: "",
                                                                    "hide-details":
                                                                      "",
                                                                    name:
                                                                      "shiftStartTime",
                                                                    outlined:
                                                                      "",
                                                                    label: _vm.$t(
                                                                      "labels.start"
                                                                    ),
                                                                    rules: {
                                                                      required: true,
                                                                      time: true,
                                                                      excluded: [
                                                                        _vm.shiftEndTimeDisplay
                                                                      ]
                                                                    }
                                                                  },
                                                                  on: {
                                                                    keyup: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.updateStartTime()
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.shiftStartTimeDisplay,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.shiftStartTimeDisplay = $$v
                                                                    },
                                                                    expression:
                                                                      "shiftStartTimeDisplay"
                                                                  }
                                                                },
                                                                "VeeTextField",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1432784538
                                                ),
                                                model: {
                                                  value: _vm.showShiftStartTime,
                                                  callback: function($$v) {
                                                    _vm.showShiftStartTime = $$v
                                                  },
                                                  expression:
                                                    "showShiftStartTime"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  {
                                                    attrs: {
                                                      dense: "",
                                                      flat: ""
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.startTimes,
                                                    function(time) {
                                                      return _c(
                                                        "v-list-item",
                                                        {
                                                          key: time.id,
                                                          staticClass:
                                                            "caption",
                                                          attrs: {
                                                            disabled:
                                                              time.disabled,
                                                            title: time.name
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              time.disabled
                                                                ? null
                                                                : _vm.updateStartTime(
                                                                    time.id
                                                                  )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-list-item-title",
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    time.name
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "3" } },
                                          [
                                            _c(
                                              "v-menu",
                                              {
                                                key:
                                                  "end-" +
                                                  _vm.shiftEndTimeCount,
                                                ref: "shiftEndTimeMenu",
                                                attrs: {
                                                  "close-on-content-click": "",
                                                  "offset-y": "",
                                                  "max-height": "300px",
                                                  "max-width": "100px",
                                                  "min-width": "100px",
                                                  "nudge-bottom": 0,
                                                  "nudge-left": 0
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "VeeTextField",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "mask",
                                                                      rawName:
                                                                        "v-mask",
                                                                      value:
                                                                        _vm.timeMask,
                                                                      expression:
                                                                        "timeMask"
                                                                    }
                                                                  ],
                                                                  ref:
                                                                    "shiftEndTime",
                                                                  staticClass:
                                                                    "shift-time d-inline-block",
                                                                  attrs: {
                                                                    autocomplete: false,
                                                                    dense: "",
                                                                    "hide-details":
                                                                      "",
                                                                    name:
                                                                      "shiftEndTime",
                                                                    outlined:
                                                                      "",
                                                                    label: _vm.$t(
                                                                      "labels.end"
                                                                    ),
                                                                    rules: {
                                                                      required: true,
                                                                      time: true,
                                                                      excluded: [
                                                                        _vm.shiftStartTimeDisplay
                                                                      ]
                                                                    }
                                                                  },
                                                                  on: {
                                                                    keyup: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.updateEndTime()
                                                                    }
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.shiftEndTimeDisplay,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.shiftEndTimeDisplay = $$v
                                                                    },
                                                                    expression:
                                                                      "shiftEndTimeDisplay"
                                                                  }
                                                                },
                                                                "VeeTextField",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1814231738
                                                ),
                                                model: {
                                                  value: _vm.showShiftEndTime,
                                                  callback: function($$v) {
                                                    _vm.showShiftEndTime = $$v
                                                  },
                                                  expression: "showShiftEndTime"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  {
                                                    attrs: {
                                                      dense: "",
                                                      flat: ""
                                                    }
                                                  },
                                                  _vm._l(_vm.endTimes, function(
                                                    time
                                                  ) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        key: time.id,
                                                        staticClass: "caption",
                                                        attrs: {
                                                          disabled:
                                                            time.disabled,
                                                          title: time.name
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.preventDefault()
                                                            time.disabled
                                                              ? null
                                                              : _vm.updateEndTime(
                                                                  time.id
                                                                )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  time.name
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : [
                                    _c("v-select", {
                                      ref: "selectAvailableShift",
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        items: _vm.availableShiftTypes,
                                        label: _vm.$t("labels.shift"),
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.availableShiftType,
                                        callback: function($$v) {
                                          _vm.availableShiftType = $$v
                                        },
                                        expression: "availableShiftType"
                                      }
                                    })
                                  ],
                              _c(
                                "v-container",
                                { staticClass: "px-0 pb-0 text-right" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "save-available",
                                      attrs: {
                                        color: "secondary",
                                        disabled: !_vm.hasChanges || _vm.saving
                                      },
                                      on: { click: _vm.saveActivities }
                                    },
                                    [
                                      _vm.saving
                                        ? _c("v-progress-circular", {
                                            attrs: {
                                              color: "secondary",
                                              indeterminate: "",
                                              size: "22",
                                              width: "2"
                                            }
                                          })
                                        : _c("span", [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.$t("labels.save")) +
                                                "\n              "
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e(),
                      _c(
                        "v-tab-item",
                        { staticClass: "pa-4", attrs: { value: "addEvent" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass:
                                "caption dense font-weight-medium mx-0",
                              attrs: {
                                color: "info",
                                dense: "",
                                outlined: "",
                                text: ""
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "ml-n1 mr-3",
                                  attrs: {
                                    slot: "prepend",
                                    color: "info",
                                    size: "12"
                                  },
                                  slot: "prepend"
                                },
                                [
                                  _vm._v(
                                    "\n              fas fa-info-circle\n            "
                                  )
                                ]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("descriptions.selfScheduleAddEvent")
                                  ) +
                                  "\n          "
                              )
                            ],
                            1
                          ),
                          _c("v-select", {
                            ref: "selectEvent",
                            attrs: {
                              dense: "",
                              "hide-details": "",
                              "item-text": "name",
                              "item-value": "id",
                              items: _vm.eventTypes,
                              label: _vm.$tc("labels.event", 1),
                              outlined: ""
                            },
                            model: {
                              value: _vm.eventType,
                              callback: function($$v) {
                                _vm.eventType = $$v
                              },
                              expression: "eventType"
                            }
                          }),
                          _c(
                            "v-container",
                            { staticClass: "px-0 pb-0 text-right" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "save-available",
                                  attrs: {
                                    color: "secondary",
                                    disabled: !_vm.hasChanges || _vm.saving
                                  },
                                  on: { click: _vm.saveActivities }
                                },
                                [
                                  _vm.saving
                                    ? _c("v-progress-circular", {
                                        attrs: {
                                          color: "secondary",
                                          indeterminate: "",
                                          size: "22",
                                          width: "2"
                                        }
                                      })
                                    : _c("span", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("labels.save")) +
                                            "\n              "
                                        )
                                      ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }