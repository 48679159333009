import _ from 'lodash';
import moment from 'moment';
import DailySchedulePDF from '@/views/scheduling/pdf_templates/daily_schedule/DailySchedulePDF';
import { isWorkingShiftForValidation, isOnCallWorkingShiftForValidation } from '@/utils/scheduling';

class DailySchedulePDF002 extends DailySchedulePDF {
  static get defaultOptions () {
    return {
      orderBy: [
        {
          field: 'assignee.jobTypeWeight',
          direction: 'asc'
        },
        {
          field: 'startTime',
          direction: 'asc'
        },
        {
          field: 'assignee.lastName',
          direction: 'asc'
        },
        {
          field: 'assignee.firstName',
          direction: 'asc'
        }
      ],
      loadLastDates: true,
      showNonDutyEmployees: false,
      showOnCallEmployees: true,
      loadExtraShifts: false,
      showShiftNotes: true
    };
  }

  static get name () {
    return 'DailySchedulePDF002';
  }

  constructor (dailySchedule, date, department, type, store, t, tc, adaptor, context) {
    super(dailySchedule, date, department, type, store, t, tc, adaptor, context);

    this.definition.defaultStyle.fontSize = 10;
    this.definition.defaultStyle.lineHeight = 1.0;
    this.definition.pageMargins = [15, 30];
    this.definition.styles.subtext = {
      color: '#757575',
      fontSize: 8
    };
    this.definition.styles.offDuty = {
      color: '#757575'
    };
    this.definition.styles.offDutyStrikeThrough = {
      decoration: 'lineThrough'
    };
    this.definition.styles.headerMargin.margin = [15, 10];
  }

  /**
   * Gets shifts in the format specified by "format" in data. Defaults to stack.
   * @param {object} data Shifts display and query data
   * @returns pdfmake document definition object
   */
  _getShifts (data) {
    const {
      options
    } = data;
    const shifts = this.adaptor.getShifts(data);
    const flagsById = this.store.state.org.flags.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});
    const shiftTypes = this.store.state.org.shiftTypes.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});

    const isWorkingShift = (shift) => {
      return isWorkingShiftForValidation(shift, flagsById) || isOnCallWorkingShiftForValidation(shift, flagsById);
    };

    const getRow = (info, shift, workingShift) => {
      let assigneeDetails = `${info.assignee.jobTypeName} ${info.assignee.jobStatusShortCode}`;
      if (shift.assignee.departmentId !== this.department.id) {
        const assigneeDepartment = _.find(this.store.state.org.departments, (d) => d.id === shift.assignee.departmentId);
        assigneeDetails += ` | ${assigneeDepartment.name}`;
      }

      const textStyle = [];
      if (!workingShift) {
        textStyle.push('offDuty');
      }
      const name = {
        stack: [
          this._getText({
            content: info.assignee.fullName,
            style: [...textStyle, !workingShift ? 'offDutyStrikeThrough' : '']
          }),
          this._getText({
            content: assigneeDetails,
            style: [...textStyle, 'subtext']
          })
        ]
      };

      const flagNames = [];
      if (shift.flags && shift.flags.length > 0) {
        for (let i = 0, count = shift.flags.length; i < count; i++) {
          if (flagsById[shift.flags[i]] && this.canShowFlag(shift.assigneeId, shift.flags[i])) {
            flagNames.push(flagsById[shift.flags[i]].shortCode);
          }
        }
      }
      const flags = this._getText({
        content: `${flagNames.join(', ')}`,
        style: textStyle
      });

      const ot = this._getText({
        content: shift.overtime ? '$' : '',
        style: textStyle
      });

      const shiftType = shiftTypes[shift.typeId];
      const startTime = shift.startTime ? shift.startTime : shiftType.startTime;
      const endTime = shift.endTime ? shift.endTime : shiftType.endTime;
      const hours = {
        stack: [
          this._getText({
            content: `${_.split(startTime, ':', 2).join(':')} - ${_.split(endTime, ':', 2).join(':')}`,
            style: [...textStyle, !workingShift ? 'offDutyStrikeThrough' : '']
          }),
          this._getText({
            content: shiftType.name,
            style: [...textStyle, 'subtext']
          })
        ]
      };

      let sitterInfo = '';
      if (shift.sitter) {
        const room = _.get(shift, 'settings.sitter.room', null);
        const reason = _.get(shift, 'settings.sitter.reason', null);
        if (room && reason) {
          sitterInfo = `${this.t('labels.sitter')}(Room ${room}): ${reason}`;
        } else if (room) {
          sitterInfo = `${this.t('labels.sitter')}(Room ${room})`;
        } else if (reason) {
          sitterInfo = `${this.t('labels.sitter')}: ${reason}`;
        } else {
          sitterInfo = this.t('labels.sitter');
        }
        if (shift.comments) {
          sitterInfo += '\n';
        }
      }
      const comments = this._getText({
        content: `${sitterInfo}${(!this.isStaff || shift.assigneeId === this.store.state.account.userId) ? shift.comments : ''}`,
        style: textStyle
      });

      const last = {
        stack: []
      };
      const lastFloat = _.get(info, 'last.float', null);
      if (lastFloat) {
        last.stack.push(this._getText({
          content: `${this.t('labels.float')} ${moment(lastFloat.date).format('MM/DD/YY')}`,
          style: textStyle
        }));
      }
      const lastCanceled = _.get(info, 'last.canceled', null);
      if (lastCanceled) {
        last.stack.push(this._getText({
          content: `${this.t('labels.canceled')} ${moment(lastCanceled.date).format('MM/DD/YY')}`,
          style: textStyle
        }));
      }
      const lastOvertime = _.get(info, 'last.overtime', null);
      if (lastOvertime) {
        last.stack.push(this._getText({
          content: `${this.t('labels.ot')} ${moment(lastOvertime.date).format('MM/DD/YY')}`,
          style: textStyle
        }));
      }
      const content = [
        name,
        flags,
        ot,
        hours
      ];

      if (options.showShiftNotes) {
        content.push(comments);
      }
      if (this.isStaff) {
        content.push({
          text: ''
        });
      } else {
        content.push(last);
      }
      return content;
    };

    let widths = [ 140, 100, 20, 65, 120, 'auto' ];
    let header = [
      {
        bold: true,
        text: this.t('labels.name')
      },
      {
        bold: true,
        text: this.tc('labels.flag', 2)
      },
      {
        bold: true,
        text: this.t('labels.ot')
      },
      {
        bold: true,
        text: this.t('labels.hours')
      },
      {
        bold: true,
        text: this.tc('labels.comment', 2)
      }
    ];

    if (!options.showShiftNotes) {
      widths = [ 180, 180, 20, 65, 'auto' ];
      header = [
        {
          bold: true,
          text: this.t('labels.name')
        },
        {
          bold: true,
          text: this.tc('labels.flag', 2)
        },
        {
          bold: true,
          text: this.t('labels.ot')
        },
        {
          bold: true,
          text: this.t('labels.hours')
        }
      ];
    }

    if (this.isStaff) {
      header.push({
        bold: true,
        text: ''
      });
    } else {
      header.push({
        bold: true,
        text: this.t('labels.last')
      });
    }

    const content = {
      layout: 'noBorders',
      table: {
        headerRows: 1,
        widths,
        body: [
          header
        ]
      }
    };
    if (shifts.length > 0) {
      const nonWorking = [];
      for (let i = 0, count = shifts.length; i < count; i++) {
        for (let activityIndex = 0, activityCount = shifts[i].activities.length; activityIndex < activityCount; activityIndex++) {
          const workingShift = isWorkingShift(shifts[i].activities[activityIndex]);

          if (shifts[i].activities[activityIndex].onCall) {
            if (!options.showOnCallEmployees) {
              continue;
            }
          }
          const row = getRow(shifts[i], shifts[i].activities[activityIndex], workingShift);
          if (!workingShift) {
            if (!options.showNonDutyEmployees) {
              continue;
            }
            nonWorking.push(row);
          } else {
            content.table.body.push(row);
          }
        }
      }
      content.table.body.push(...nonWorking);
    }
    return [
      content
    ];
  }

  generate (options) {
    const content = [];
    const dailyScheduleShiftTypes = _.get(this.type, 'shiftTypes', []);
    content.push({
      type: 'shifts',
      format: 'stack',
      filters: [
        [{ field: 'type.id', op: 'in', value: dailyScheduleShiftTypes }]
      ],
      groupBy: [],
      orderBy: options.orderBy,
      options
    });
    if (!this.store.getters['account/isStaff']()) {
      content.push({
        type: 'divider'
      });
      content.push({
        type: 'acuity',
        totals: true,
        details: true
      });
      content.push({
        type: 'divider'
      });
      content.push({
        type: 'notes'
      });
    }
    this.definition.content.push(...this._getStack(content));
  }
}

export default DailySchedulePDF002;
