<template>
  <v-container
    class="requests"
    fluid
  >
    <portal to="page-title">
      <span class="title page-title text-truncate">
        {{ $tc('labels.request', 2) }}
      </span>
    </portal>
    <v-container
      fluid
    >
      <v-row class="no-gutters">
        <v-tabs
          v-model="selectedTab"
          :grow="$vuetify.breakpoint.smAndDown ? true : false"
          mobile-breakpoint=""
          :show-arrows="false"
          slider-color="accent"
          slider-size="3"
          @change="retrieveRequestsForSelectedTab"
        >
          <v-tab
            class="px-6"
            href="#giveaway"
          >
            {{ $t('labels.giveaway') }}
            <div
              v-if="$store.getters['request/giveawayRequestCount'] || $store.getters['request/giveawayRequestTotalCount']"
              :class="['font-weight-bold request-count ml-2 px-1 ratio']"
            >
              <span
                v-if="$store.getters['request/hasGiveawayFilters']"
              >
                {{ $store.getters['request/giveawayRequestCount'] }}
              </span>
              <span v-else>
                {{ $store.getters['request/giveawayRequestCount'] || $store.getters['request/giveawayRequestTotalCount'] }}
              </span>
            </div>
          </v-tab>
          <v-tab
            class="px-6"
            href="#split"
          >
            {{ $tc('labels.split', 1) }}
            <div
              v-if="$store.getters['request/splitRequestCount'] || $store.getters['request/splitRequestTotalCount']"
              :class="['font-weight-bold request-count ml-2 px-1 ratio']"
            >
              <span
                v-if="$store.getters['request/hasSplitFilters']"
              >
                {{ $store.getters['request/splitRequestCount'] }}
              </span>
              <span
                v-else
              >
                {{ $store.getters['request/splitRequestCount'] || $store.getters['request/splitRequestTotalCount'] }}
              </span>
            </div>
          </v-tab>
          <v-tab
            class="px-6"
            href="#swap"
          >
            {{ $t('labels.swap') }}
            <div
              v-if="$store.getters['request/swapRequestCount'] || $store.getters['request/swapRequestTotalCount']"
              :class="['font-weight-bold request-count ml-2 px-1 ratio']"
            >
              <span
                v-if="$store.getters['request/hasSwapFilters']"
              >
                {{ $store.getters['request/swapRequestCount'] }}
              </span>
              <span v-else>
                {{ $store.getters['request/swapRequestCount'] || $store.getters['request/swapRequestTotalCount'] }}
              </span>
            </div>
          </v-tab>
          <v-tab
            class="px-6"
            href="#event"
          >
            {{ $tc('labels.event', 1) }}
            <div
              v-if="$store.getters['request/eventRequestCount'] || $store.getters['request/eventRequestTotalCount']"
              :class="['font-weight-bold request-count ml-2 px-1 ratio']"
            >
              <span
                v-if="$store.getters['request/hasEventFilters']"
              >
                {{ $store.getters['request/eventRequestCount'] }}
              </span>
              <span v-else>
                {{ $store.getters['request/eventRequestCount'] || $store.getters['request/eventRequestTotalCount'] }}
              </span>
            </div>
          </v-tab>
          <v-tab
            class="px-6"
            href="#history"
          >
            {{ $t('labels.history') }}
          </v-tab>
          <v-tabs-items
            v-model="selectedTab"
            touchless
          >
            <v-tab-item
              :reverse-transition="false"
              :transition="false"
              value="giveaway"
            >
              <GiveawayRequestList
                ref="giveaway"
              />
            </v-tab-item>
            <v-tab-item
              :reverse-transition="false"
              :transition="false"
              value="split"
            >
              <SplitRequestList
                ref="split"
              />
            </v-tab-item>
            <v-tab-item
              :reverse-transition="false"
              :transition="false"
              value="swap"
            >
              <SwapRequestList
                ref="swap"
              />
            </v-tab-item>
            <v-tab-item
              :reverse-transition="false"
              :transition="false"
              value="event"
            >
              <EventRequestList
                ref="event"
              />
            </v-tab-item>
            <v-tab-item
              :reverse-transition="false"
              :transition="false"
              value="history"
            >
              <HistoryList
                ref="history"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Resize from 'vuetify/es5/directives/resize';
import GiveawayRequestList from '@/views/scheduling/requests/GiveawayRequestList';
import SplitRequestList from '@/views/scheduling/requests/SplitRequestList';
import SwapRequestList from '@/views/scheduling/requests/SwapRequestList';
import EventRequestList from '@/views/scheduling/requests/EventRequestList';
import HistoryList from '@/views/scheduling/requests/HistoryList';
import { CONTENT_TYPES } from '@/services/constants';

export default {
  directives: {
    Resize
  },
  components: {
    GiveawayRequestList,
    EventRequestList,
    HistoryList,
    SplitRequestList,
    SwapRequestList
  },
  data () {
    return {
      requests: {
        event: true,
        giveaway: true,
        split: true,
        swap: true
      }
    };
  },
  computed: {
    selectedTab: {
      get () {
        return this.$store.state.request.activeList;
      },
      set (value) {
        this.$store.commit('request/set_active_list', value);
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // Remove notification listener/request panel callbacks here because if we do this in beforeDestroy
    // the route have already changed.
    this.$store.commit(
      'notification/remove_notification_listener',
      { route: from.fullPath, callback: this.onNotificationReceived }
    );

    this.$store.commit(
      'remove_ws_update_callback',
      'requests_page',
      { root: true }
    );

    this.$store.commit('request/reset_state');
    next();
  },
  mounted () {
    const { id = null, type = null } = this.$router.currentRoute.query;

    this.$store.commit(
      'notification/add_notification_listener',
      { route: this.$route.fullPath, callback: this.onNotificationReceived }
    );

    this.$store.commit(
      'add_ws_update_callback',
      { name: 'requests_page', callback: this.onWsUpdate },
      { root: true }
    );

    if (id && type) {
      this.$store.dispatch('showRequestPanel', {
        id: parseInt(id),
        type
      }, { root: true });
    }

    Promise.all([
      this.$store.dispatch('request/retrieveEventRequests', { appInitializer: '', countOnly: true }),
      this.$store.dispatch('request/retrieveGiveawayRequests', { appInitializer: '', countOnly: true }),
      this.$store.dispatch('request/retrieveSplitRequests', { appInitializer: '', countOnly: true }),
      this.$store.dispatch('request/retrieveSwapRequests', { appInitializer: '', countOnly: true })
    ]).catch(() => {}).finally(() => {
      this.requests.event = false;
      this.requests.giveaway = false;
      this.requests.split = false;
      this.requests.swap = false;
    });
  },
  methods: {
    moment,
    onNotificationReceiptUpdateReceived (event) {
      const receiptData = JSON.parse(event.data);
      if (this.selectedTab === 'history') {
        this.$store.commit('request/update_history_requests_notification_receipt', receiptData);
      }
    },
    onNotificationReceived (data) {
      if (this.selectedTab !== 'history') {
        const associatedContentType = _.get(data, 'origin_data.associated_content_type', '');
        let inferredType = associatedContentType;
        if (associatedContentType === 'shiftrequest') {
          inferredType = _.get(data, 'origin_data.inferred_type', '');
        }
        if (this.$refs[inferredType]) {
          this.$refs[inferredType].onNotificationReceived();
        }
        if (this.$store.state.request.assocContentTypes.includes(associatedContentType) && !this.requests[inferredType]) {
          this.requests[inferredType] = true;
          this.$store.dispatch(
            `request/retrieve${_.capitalize(inferredType)}Requests`, { appInitializer: '', countOnly: true }
          ).catch(() => {}).finally(() => {
            this.requests[inferredType] = false;
          });
        }
      }
    },
    onRequestUpdateReceived (request) {
      if (this.selectedTab !== 'history') {
        const data = JSON.parse(request.data);
        const associatedContentType = _.get(data, 'associated_content_type', '');
        let inferredType = associatedContentType;
        if (associatedContentType === 'shiftrequest') {
          inferredType = _.get(data, 'inferred_type', '');
        }
        if (this.$refs[inferredType]) {
          this.$refs[inferredType].onRequestUpdateReceived(request);
        }
        if (this.$store.state.request.assocContentTypes.includes(associatedContentType) && !this.requests[inferredType]) {
          this.requests[inferredType] = true;
          this.$store.dispatch(
            `request/retrieve${_.capitalize(inferredType)}Requests`, { appInitializer: '', countOnly: true }
          ).catch(() => {}).finally(() => {
            this.requests[inferredType] = false;
          });
        }
      }
    },
    onWsUpdate (eventInfo) {
      const data = JSON.parse(eventInfo.data);
      const contentType = data.content_type;
      switch (contentType) {
        case CONTENT_TYPES.notificationReceipt:
          this.onNotificationReceiptUpdateReceived(eventInfo);
          break;
        case CONTENT_TYPES.approval:
          this.onRequestUpdateReceived(eventInfo);
          break;
      }
    },
    retrieveRequestsForSelectedTab () {
      if (this.$refs[this.selectedTab]) {
        this.$refs[this.selectedTab].retrieveRequests();
      }
    }
  }
};
</script>

<style lang="scss">
.container.requests {
  height: 100%;

  > .container:not(.skeleton-loader) {
    height: 100%;

    > .row {
      height: 100%;

      .v-tabs {
        .v-tabs-bar {
          background-color: $page-background;

          .v-tab {
            width: 170px;
          }
        }

        .request-count {
          background-color: $primary-lighten-2;
          border-radius: 4px;
          color: white;
          display: inline-block;
          height: 16px;
          line-height: 13px;
          padding-top: 1px;
          margin-top: -2px;
          min-width: 16px;
          text-align: center;

          span {
            font-size: 10px;
          }
        }

        .v-tabs-items {
          height: calc(100% - 24px);
          background: none;

          .v-window-item {
            height: 100%;

            .row.no-request  {
              background-color: white;
              height: calc(100% - 280px);
              min-height: 200px;;

              .spacer {
                background-color: $page-background;
              }
            }

            .v-data-table {
              &.pending-requests {
                table {
                  min-width: 600px;
                }
              }

              &.history-requests {
                table {
                  min-width: 1000px;
                }
              }

              thead {
                .v-input.search-name {
                  font-size: 14px;
                }

                th {
                  font-weight: 500;
                }
              }

              tbody {
                tr:last-child {
                  td {
                    border-bottom: thin solid #0000001f;
                  }
                }

                td {
                  .name-n-avatar {
                    max-width: 150px;
                  }

                  .name {
                    min-width: 110px;
                    max-width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;;
                    white-space: nowrap;
                  }

                  .type {
                    min-width: 70px;;
                  }
                }
              }

              .no-results {
                height: 250px;
              }

              .v-pagination {
                .v-pagination__item,
                .v-pagination__navigation {
                  font-size: 14px;
                  height: 30px;

                  &:focus {
                    outline: none;
                  }

                  .v-icon {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
