exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.schedule-daily-summary .v-tabs.dense .v-tabs-bar {\n  height: 30px;\n}\n.schedule-daily-summary .v-tabs.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev {\n  display: none;\n}\n.schedule-daily-summary .v-tabs .v-tabs-bar {\n  margin-right: 0px !important;\n}\n.schedule-daily-summary .v-tabs .v-tabs-bar__content > a:not(:last-child) {\n  border-right: none !important;\n}\n.schedule-daily-summary .v-tabs .v-tabs-bar__content > a:first-child {\n  border-top-left-radius: 4px;\n  border-bottom-left-radius: 4px;\n}\n.schedule-daily-summary .v-tabs .v-tabs-bar__content > a:last-child {\n  border-top-right-radius: 4px;\n  border-bottom-right-radius: 4px;\n}\n.schedule-daily-summary .v-tabs .v-tab {\n  background-color: #FFF;\n  border: 1px solid #837EB7;\n  color: #837EB7 !important;\n}\n.schedule-daily-summary .v-tabs .v-tab.v-tab--active {\n  background-color: #837EB7;\n  color: #FFF !important;\n}\n.schedule-daily-summary .v-tabs-items {\n  background: transparent !important;\n  overflow-y: auto;\n  padding: 10px 0;\n}\n.schedule-daily-summary .invalid-staff {\n  position: absolute;\n  left: 20px;\n  padding-top: 5px;\n}\n.schedule-daily-summary .single-tab .v-slide-group {\n  display: none;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};