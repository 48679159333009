var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "activity-details px-8 py-0" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-calendar-day")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("labels.scheduleDetails")) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "pa-0",
          style: _vm.panelStyle,
          attrs: { id: "activitiesContent" }
        },
        [
          _vm.shifts.length > 0
            ? _c("v-hover", {
                staticClass: "mb-3",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var hover = ref.hover
                        return [
                          _c("ShiftsCard", {
                            attrs: {
                              elevation: hover ? 2 : 0,
                              shifts: _vm.shifts,
                              user: _vm.user
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("show-shifts", _vm.shifts)
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1865828530
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }