<template>
  <v-container
    class="pa-0"
  >
    <NoContent
      exciting
      :height="140"
      :width="140"
      :message="$t('descriptions.imbalanceNoErrors')"
    />
  </v-container>
</template>

<script>
import NoContent from '@/components/NoContent';

export default {
  components: {
    NoContent
  },
  props: {
    error: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss">

</style>
