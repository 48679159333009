<template>
  <v-container
    class="activity-selection px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-plus</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.addActivity') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card
      class="pa-2"
      outlined
    >
      <v-row
        class="px-1"
        no-gutters
      >
        <v-col
          class="subtitle-2 py-2"
          cols="auto"
        >
          {{ $t('labels.selectShift') }}
        </v-col>
      </v-row>
      <v-btn-toggle
        v-if="$can('edit', 'template')"
        v-model="shift.typeId"
        class="shift-types mt-2"
        tile
        group
        @change="addShift"
      >
        <v-btn
          v-for="(shiftType) in shiftTypes"
          :key="['shift', shiftType.id].join()"
          :class="['text-capitalize', shiftTypeClass]"
          :value="shiftType.id"
        >
          {{ shiftType.name }}
        </v-btn>
      </v-btn-toggle>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    user: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    }
  },
  data () {
    const shift = {
      flags: [],
      comments: '',
      internalComments: '',
      obligatory: false,
      onCall: false,
      overtime: false,
      settings: {
        sitter: {
          reason: '',
          room: ''
        }
      },
      sitter: false,
      type: 'shift',
      typeId: null
    };
    return {
      shift
    };
  },
  computed: {
    eventTypes () {
      return this.$store.state.org.eventTypes;
    },
    jobType () {
      return _.find(this.$store.state.org.jobTypes, (jobType) => {
        return jobType.id === this.user.jobTypeId;
      });
    },
    shiftTypeClass () {
      let className = 'shift-type';
      if (this.shiftTypes.length > 1) {
        className += ` ${this.shiftTypes.length % 2 === 0 ? 'e' : 'o'}`;
      }
      return className;
    },
    shiftTypes () {
      const shiftTypes = this.jobType ? _.filter(this.$store.state.org.shiftTypes, (shiftType) => {
        return _.indexOf(this.jobType.settings.associatedShiftTypes, shiftType.id) >= 0;
      }) : [];

      if (this.user.charge) {
        const chargeJobTypes = _.filter(this.$store.state.org.jobTypes, (jt) => {
          return _.get(jt, 'settings.isChargeNurse', false);
        });
        for (let jt of chargeJobTypes) {
          const associatedShiftTypes = _.get(jt, 'settings.associatedShiftTypes', []);
          for (let id of associatedShiftTypes) {
            if (this.shiftTypesById[id]) {
              shiftTypes.push(this.shiftTypesById[id]);
            }
          }
        }
      }

      return _.uniqBy(shiftTypes, 'id');
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    }
  },
  methods: {
    moment,
    addShift () {
      const shiftType = this.$store.getters['org/getShiftTypeById'](this.shift.typeId);
      this.$emit('add-shift', {
        ...this.shift,
        startTime: shiftType.startTime,
        endTime: shiftType.endTime,
        onCall: shiftType.onCall
      });
    }
  }
};
</script>

<style lang="scss">
@mixin schedule-details-btn($color, $bgColor) {
  background-color: $bgColor !important;
  border: none !important;
  border-radius: 4px !important;
  color: $color !important;
  height: 40px !important;
}

.activity-selection {
  .shift-types {
    display: inline-block;
    button.shift-type {
      @include schedule-details-btn(map-get($nb-cyan, 'base'), map-get($nb-cyan, 'lighten-5'));
      width: 350px !important;

      &.e {
        width: 170px !important;
      }
      &.o {
        width: 110px !important;
      }
      &.v-btn--active {
        background-color: map-get($nb-cyan, 'base') !important;
        color: #FFF;
      }
    }
  }
}
</style>
