var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ga-request pa-0" },
    [
      _c(
        "v-row",
        { staticClass: "d-inline-block", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "details", attrs: { cols: "12" } },
            [
              _c(
                "v-list-item",
                { staticClass: "px-0 d-inline-block" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "body-2 mb-1" },
                        [
                          _c("UserName", {
                            attrs: {
                              user:
                                _vm.$store.state.org.employees[
                                  _vm.request.assocContent.assigneeId
                                ]
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "caption mt-1" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getJobInfo(
                                  _vm.request.assocContent.assigneeId
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-inline-flex" },
                [
                  _c("span", { staticClass: "grey--text text--darken-3" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.department) + "\n        "
                    )
                  ]),
                  _c("v-divider", {
                    staticClass: "d-inline mx-2",
                    attrs: { vertical: "" }
                  }),
                  _c("span", { staticClass: "grey--text text--darken-3" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.shiftType) + "\n        "
                    )
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "mx-9",
                      attrs: { color: "warning", size: "20" }
                    },
                    [_vm._v("\n          fal fa-long-arrow-right\n        ")]
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                { staticClass: "px-0 d-inline-block" },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "body-2 mb-1" },
                        [
                          _c("UserName", {
                            attrs: {
                              user:
                                _vm.$store.state.org.employees[
                                  _vm.request.assocContent.newAssigneeId
                                ]
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "caption mt-1" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getJobInfo(
                                  _vm.request.assocContent.newAssigneeId
                                )
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }