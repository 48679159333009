var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "requests pa-0", attrs: { fluid: "" } },
    [
      !_vm.retrievingPendingRequests &&
      !_vm.pendingRequestsFiltered &&
      !_vm.giveawayRequestCount
        ? _c(
            "v-row",
            { staticClass: "no-request", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { "align-self": "center", cols: "12", md: "10" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mb-4", attrs: { justify: "center" } },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          "max-width": "120",
                          src: require("@/assets/images/no-content-penguin.svg")
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-4", attrs: { justify: "center" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("descriptions.noRequestPending")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              ),
              _c("v-spacer")
            ],
            1
          )
        : _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-data-table", {
                directives: [
                  {
                    name: "resize",
                    rawName: "v-resize.quiet",
                    value: _vm.onWindowResized,
                    expression: "onWindowResized",
                    modifiers: { quiet: true }
                  }
                ],
                staticClass: "elevation-1 pending-requests",
                attrs: {
                  dense: "",
                  expanded: _vm.expandedRows,
                  headers: _vm.pendingRequestsHeaders,
                  "header-props": { sortIcon: "fa fa-arrow-up" },
                  "hide-default-footer": "",
                  items: _vm.$store.state.request.giveawayRequests.records,
                  "items-per-page": _vm.$store.state.request.pageSize,
                  loading: _vm.retrievingPendingRequests,
                  "loading-text": _vm.$t("descriptions.loadingRequests"),
                  "mobile-breakpoint": "",
                  "must-sort": "",
                  "no-data-text": _vm.$t("descriptions.noRequestPending"),
                  options: _vm.pendingRequestsOptions,
                  page: _vm.pendingRequestsCurrentPage,
                  "server-items-length":
                    _vm.$store.state.request.giveawayRequests.count,
                  "show-expand": "",
                  "single-select": true
                },
                on: {
                  "update:options": function($event) {
                    _vm.pendingRequestsOptions = $event
                  },
                  "update:page": function($event) {
                    _vm.pendingRequestsCurrentPage = $event
                  },
                  "click:row": _vm.onItemClicked
                },
                scopedSlots: _vm._u([
                  {
                    key: "header.createdBy",
                    fn: function() {
                      return [
                        _c("StaffSearch", {
                          attrs: {
                            "append-icon": _vm.pendingRequestsOptions
                              .searchByName
                              ? ""
                              : "fal fa-search",
                            "target-class": "py-2 search-name",
                            clearable: !!_vm.pendingRequestsOptions
                              .searchByName,
                            dense: "",
                            "hide-details": "",
                            solo: ""
                          },
                          model: {
                            value: _vm.pendingRequestsOptions.searchByName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.pendingRequestsOptions,
                                "searchByName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "pendingRequestsOptions.searchByName"
                          }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "header.deptIds",
                    fn: function(ref) {
                      var header = ref.header
                      return [
                        _c("MultiSelectionList", {
                          attrs: { selection: _vm.departments },
                          on: {
                            selectionConfirmed: function($event) {
                              return _vm.onPendingRequestFilterChanged(
                                header.value,
                                $event
                              )
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "ml-n4 subtitle-2 text-capitalize",
                                          attrs: {
                                            color: "secondary",
                                            text: ""
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$tc("labels.department", 1)
                                            ) +
                                            "\n              "
                                        ),
                                        _vm.pendingRequestsOptions.filterBy
                                          .deptIds
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2 mt-1",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fas fa-filter\n              "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2 mt-1",
                                                attrs: {
                                                  color: "grey",
                                                  right: "",
                                                  "x-small": ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                fal fa-filter\n              "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ]
                    }
                  },
                  {
                    key: "item.createdBy",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-list-item",
                          { staticClass: "px-0" },
                          [
                            _c(
                              "v-list-item-avatar",
                              { staticClass: "mr-1" },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    attrs: {
                                      color:
                                        _vm.$store.state.org.employees[
                                          item.assocContent.assigneeId
                                        ].avatarBgColor,
                                      size: "30"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "white--text subtitle-2" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.getAvatar(
                                                _vm.$store.state.org.employees[
                                                  item.assocContent.assigneeId
                                                ]
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c(
                                  "v-list-item-title",
                                  { staticClass: "body-2 mb-1 name-n-avatar" },
                                  [
                                    _c("UserName", {
                                      attrs: {
                                        user:
                                          _vm.$store.state.org.employees[
                                            item.assocContent.assigneeId
                                          ]
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-subtitle",
                                  { staticClass: "caption mt-1" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.getJobInfo(item)) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item.deptIds",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "text-capitalize type" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getDepartment(item)) +
                              "\n        "
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.date",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("div", { staticClass: "text-capitalize type" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.formatDate(item.assocContent.date)) +
                              "\n        "
                          )
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.createdOn",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.formatDateTime(item.createdOn)) +
                            "\n      "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.reviewerId",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.getReviewerName(item)) +
                            "\n      "
                        )
                      ]
                    }
                  },
                  {
                    key: "no-data",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass:
                              "body-2 grey--text mt-5 no-results text--darken-3 text-left"
                          },
                          [
                            _c("div", { staticClass: "my-2 subtitle-2" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("descriptions.noResultsFound")
                                  ) +
                                  "\n          "
                              )
                            ]),
                            _c("v-divider"),
                            _c("div", { staticClass: "mb-2 mt-4" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("descriptions.emptyGridHelpTitle")
                                  ) +
                                  "\n          "
                              )
                            ]),
                            _c("div", { staticClass: "my-2" }, [
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "descriptions.emptyGridHelpRemoveFilters"
                                      )
                                    )
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "descriptions.emptyGridHelpShorterWords"
                                      )
                                    )
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "descriptions.emptyGridHelpSpelling"
                                      )
                                    )
                                  )
                                ])
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item.data-table-expand",
                    fn: function(ref) {
                      var expand = ref.expand
                      var isExpanded = ref.isExpanded
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "request-expand",
                            attrs: { icon: "", small: "" },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return expand(!isExpanded)
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    isExpanded
                                      ? "fas fa-chevron-up"
                                      : "fas fa-chevron-down"
                                  ) +
                                  "\n          "
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "expanded-item",
                    fn: function(ref) {
                      var innerHeaders = ref.headers
                      var item = ref.item
                      return [
                        _c(
                          "td",
                          { attrs: { colspan: innerHeaders.length } },
                          [
                            _c("GiveawayRequestSummary", {
                              staticClass: "pl-11",
                              attrs: { request: item }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _vm.$vuetify.breakpoint.smAndDown
                          ? [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "mobile pa-1 pagination text-center"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.retrievingPendingRequests ||
                                          _vm.pendingRequestsCurrentPage === 1,
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.paginatePendingRequests(-1)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(
                                          "\n                far fa-chevron-left\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "body-2 mx-1" }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.pendingRequestsCurrentPage) +
                                        "\n            "
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "body-2 grey--text text--darken-2"
                                    },
                                    [_vm._v("/")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "body-2 mx-1 grey--text text--darken-2"
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$store.state.request
                                              .giveawayRequests.pages
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.retrievingPendingRequests ||
                                          _vm.pendingRequestsCurrentPage ===
                                            _vm.$store.state.request
                                              .giveawayRequests.pages,
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.paginatePendingRequests(1)
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v(
                                          "\n                far fa-chevron-right\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _c("v-pagination", {
                              staticClass: "ma-1",
                              attrs: {
                                color: "secondary",
                                disabled: _vm.retrievingPendingRequests,
                                length:
                                  _vm.$store.state.request.giveawayRequests
                                    .pages,
                                "next-icon": "far fa-chevron-right",
                                "prev-icon": "far fa-chevron-left",
                                "total-visible": _vm.maxPaginationControls
                              },
                              model: {
                                value: _vm.pendingRequestsCurrentPage,
                                callback: function($$v) {
                                  _vm.pendingRequestsCurrentPage = $$v
                                },
                                expression: "pendingRequestsCurrentPage"
                              }
                            })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }