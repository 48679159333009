<template>
  <v-container
    class="schedule-daily-summary px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-calendar-day</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.dailySummary') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-row>
      <v-col
        class="secondary--text body-2"
        cols="12"
      >
        {{ moment(date).format(dateFormatStringWithDoW) }}
      </v-col>
    </v-row>
    <v-tabs
      id="dailySummaryContent"
      v-model="tab"
      centered
      :class="['dense', showSummary ? '' : 'single-tab']"
      grow
      hide-slider
    >
      <v-tab
        v-if="showSummary"
        class="text-capitalize"
        href="#summary"
      >
        {{ $t('labels.summary') }}
      </v-tab>
      <v-tab
        class="text-capitalize"
        href="#dailySchedule"
      >
        {{ $t('labels.shiftsAndEvents') }}
      </v-tab>
      <v-tab-item
        v-if="showSummary"
        :style="tabItemStyle"
        value="summary"
      >
        <v-card
          class="mb-5 mt-3 px-5 py-3"
          outlined
        >
          <v-container
            v-for="(job, jobIdx) in staffNeededSorted"
            :key="job.id"
            class="px-0 py-0"
          >
            <v-row
              class="subtitle-2 no-gutters py-2"
            >
              <v-col
                class="pl-4 pr-1"
                cols="6"
              >
                <v-icon
                  v-if="invalidStaffCountByJob(job.id)"
                  class="invalid-staff"
                  color="error"
                  size="12"
                >
                  fas fa-exclamation-circle
                </v-icon>
                <span>
                  {{ job.name }}
                </span>
              </v-col>
              <v-col cols="6">
                <v-row
                  v-for="(count, shiftId, shiftIdx) in job.shifts"
                  :key="`${job.id} ${shiftId}`"
                  :class="['no-gutters', { 'pt-2': shiftIdx > 0 }]"
                >
                  <v-col
                    class="info--text px-1"
                    cols="10"
                  >
                    {{ shiftName(shiftId) }}
                  </v-col>
                  <v-col
                    :class="['text-center', { 'error--text': invalidStaffCountByShift(job.id, shiftId) }]"
                    cols="2"
                  >
                    {{ getShiftCount(job.id, shiftId) }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider
              v-if="jobIdx < staffNeededSorted.length - 1"
            />
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item
        value="dailySchedule"
      >
        <template v-if="data.length > 0">
          <v-text-field
            v-model.trim="staffFilter"
            :append-icon="staffFilter ? '' : 'fal fa-search'"
            class="search-daily-staff py-3"
            :clearable="!!staffFilter"
            dense
            hide-details
            :placeholder="`${$t('labels.searchByName')}...`"
            outlined
          />
          <v-container
            class="pa-0"
            :style="scheduleTabStyle"
          >
            <v-hover
              v-for="(item) in schedule"
              :key="item.pseudoId"
              v-slot:default="{ hover }"
            >
              <ShiftsCard
                v-if="item.type === 'shift'"
                class="mb-4"
                :elevation="hover ? 2 : 0"
                :shifts="item.shifts"
                :show-shift-date="true"
                :user="item.user"
                @click="$emit('show-shift-details', { shifts: item.shifts, date: item.date, user: item.user })"
              />
              <EventCard
                v-else-if="item.type === 'event'"
                class="mb-4"
                :elevation="hover ? 2 : 0"
                :date="item.date"
                :event="item.event"
                :user="item.user"
                @click="$emit('show-event-details', { event: item.event, date: item.date, user: item.user })"
              />
            </v-hover>
            <v-card
              v-if="schedule.length === 0"
              outlined
            >
              <NoContent
                :height="140"
                :width="140"
                :message="$t('descriptions.noContentForDate', { date: moment(date).format(dateFormatString) })"
              />
            </v-card>
          </v-container>
        </template>
        <template v-else>
          <v-card
            outlined
          >
            <NoContent
              :height="140"
              :width="140"
              :message="$t('descriptions.noContentForDate', { date: moment(date).format(dateFormatString) })"
            />
          </v-card>
        </template>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import NoContent from '@/components/NoContent';
import ShiftsCard from '@/components/scheduling/ShiftsCard';
import EventCard from '@/components/scheduling/EventCard';
import { isWorkingShiftForValidation } from '@/utils/scheduling';

export default {
  components: {
    EventCard,
    NoContent,
    ShiftsCard
  },
  props: {
    date: {
      default: function () {
        return moment();
      },
      type: Object,
      required: true
    },
    scheduleId: {
      default: 0,
      type: [Number, String]
    },
    showSummary: {
      default: true,
      type: Boolean
    },
    staffNeeded: {
      type: Object,
      required: true
    },
    data: {
      default: function () {
        return [];
      },
      type: Array,
      required: true
    }
  },
  data () {
    const shiftCount = {};
    const jobTypes = this.$store.getters['scheduling/getJobTypes'](this.scheduleId);
    const jobMapping = {};
    const flags = _.get(this.$store.state.org, 'flags', []).reduce((flags, value) => {
      flags[value.id] = value;
      return flags;
    }, {});
    for (let i = 0, count = jobTypes.length; i < count; i++) {
      const associatedjobTypes = jobTypes[i].associatedJobTypes;
      for (let j = 0, jobsCount = associatedjobTypes.length; j < jobsCount; j++) {
        jobMapping[associatedjobTypes[j]] = jobTypes[i];
      }
      jobMapping[jobTypes[i].id] = jobTypes[i];
    }

    for (let i = 0, len = this.data.length; i < len; i++) {
      const entry = this.data[i];
      const activities = _.get(entry, ['details', 'activities'], []);
      const jobId = jobMapping[entry.user.jobTypeId] ? jobMapping[entry.user.jobTypeId].id : entry.user.jobTypeId;
      for (let i = 0, count = activities.length; i < count; i++) {
        const value = activities[i];
        if (value.type === 'shift' && isWorkingShiftForValidation(value, flags)) {
          let count = _.get(shiftCount, [jobId, value.typeId]);
          if (!count) {
            count = 1;
          } else {
            count++;
          }
          _.setWith(shiftCount, [jobId, value.typeId], count, Object);
          break;
        }
      }
    }
    return {
      shiftCount,
      staffFilter: '',
      tab: this.$store.state.scheduling.panels.dailySummary.tab,
      tabHeight: 500
    };
  },
  computed: {
    dateFormatStringWithDoW () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    schedule () {
      let filteredData = this.data;
      if (this.staffFilter) {
        const text = this.staffFilter.toLowerCase();
        filteredData = _.filter(this.data, (o) => {
          return o.user.fullName.toLowerCase().indexOf(text) >= 0;
        });
      }
      const schedule = [];
      for (let i = 0, count = filteredData.length; i < count; i++) {
        const activities = _.get(filteredData[i], ['details', 'activities'], []);
        const shifts = [];
        for (let j = 0, activitiesCount = activities.length; j < activitiesCount; j++) {
          const activity = activities[j];
          switch (activity.type) {
            case 'shift':
              shifts.push(activity);
              break;
            case 'event':
              schedule.push({
                date: this.date,
                event: activity,
                pseudoId: activity.pseudoId,
                type: 'event',
                user: filteredData[i].user
              });
              break;
          }
        }
        if (shifts.length > 0) {
          schedule.push({
            date: this.date,
            pseudoId: shifts[0].pseudoId,
            shifts,
            type: 'shift',
            user: filteredData[i].user
          });
        }
      }
      return schedule;
    },

    scheduleTabStyle () {
      const SEARCH_HEIGHT = 90;
      return {
        'height': `${this.tabHeight - SEARCH_HEIGHT}px`,
        'overflow-y': 'auto'
      };
    },

    staffNeededSorted () {
      const jobTypes = this.$store.getters['scheduling/getJobTypes'](this.scheduleId);
      const jobIds = {};
      for (let i = 0, count = jobTypes.length; i < count; i++) {
        jobIds[jobTypes[i].id] = {
          idx: i,
          name: jobTypes[i].name
        };
      }
      const sortedJobs = _.sortBy(Object.keys(this.staffNeeded), [
        function (id) {
          return _.has(jobIds, id) ? jobIds[id].idx : 1000;
        }
      ]);

      const staffNeeded = [];
      for (let i = 0, count = sortedJobs.length; i < count; i++) {
        staffNeeded.push({
          id: sortedJobs[i],
          name: jobIds[sortedJobs[i]].name,
          shifts: this.staffNeeded[sortedJobs[i]]
        });
      }
      return staffNeeded;
    },

    tabItemStyle () {
      const PADDING = 20;
      return {
        'height': `${this.tabHeight - PADDING}px`
      };
    }
  },
  watch: {
    tab (tab) {
      this.$store.commit('scheduling/update_panel', { panel: 'dailySummary', prop: 'tab', value: tab });
    }
  },
  mounted: function () {
    this.updateTabHeight();
    window.addEventListener('resize', _.debounce(this.updateTabHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateTabHeight);
  },
  methods: {
    eventName (event) {
      return this.getEventTypeById(event.typeId).name;
    },

    getEventTypeById (id) {
      return this.$store.getters['org/getEventTypeById'](id);
    },

    getShiftCount (jobId, shiftId) {
      return _.get(this.shiftCount, [jobId, shiftId], 0);
    },

    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },

    invalidStaffCountByJob (jobId) {
      let invalid = false;
      if (this.staffNeeded[jobId]) {
        for (let shiftId in this.staffNeeded[jobId]) {
          invalid |= (_.get(this.shiftCount, [jobId, shiftId], 0) !== this.staffNeeded[jobId][shiftId]);
        }
      }
      return invalid;
    },

    invalidStaffCountByShift (jobId, shiftId) {
      return this.getShiftCount(jobId, shiftId) !== _.get(this.staffNeeded, [jobId, shiftId], 0);
    },

    moment,

    shiftName (shiftId) {
      return this.getShiftTypeById(parseInt(shiftId)).name;
    },

    shiftTime (shift) {
      const shiftType = this.getShiftTypeById(shift.typeId);
      return [_.split(shiftType.startTime, ':', 2).join(':'), _.split(shiftType.endTime, ':', 2).join(':')].join(' - ');
    },

    updateTabHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const dailySummaryContent = document.getElementById('dailySummaryContent');
      if (el && dailySummaryContent) {
        this.tabHeight = el.clientHeight - dailySummaryContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>

<style lang="scss">
.schedule-daily-summary {
  @include round-tabs(#837EB7, #FFF);
  .invalid-staff {
    position: absolute;
    left: 20px;
    padding-top: 5px;
  }
  .single-tab {
    .v-slide-group {
      display: none;
    }
  }
}
</style>
