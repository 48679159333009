var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-1", attrs: { id: "dailyScheduleNotes" } },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-file-alt")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("labels.notes")) + "\n    ")
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "new-memo pa-0" },
        [
          _c("v-textarea", {
            staticClass: "notes py-2",
            attrs: {
              "auto-grow": true,
              counter: "1000",
              disabled: _vm.disabled,
              maxlength: "1000",
              outlined: "",
              placeholder: _vm.placeholder,
              rows: "6",
              "single-line": ""
            },
            model: {
              value: _vm.newMemos[0].notes,
              callback: function($$v) {
                _vm.$set(
                  _vm.newMemos[0],
                  "notes",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "newMemos[0].notes"
            }
          }),
          _c(
            "div",
            { staticClass: "hints" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { "max-width": "300px", top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-icon",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "disclosure-hint",
                                  attrs: { color: "nb-orange", "x-small": "" }
                                },
                                "v-icon",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n            fal fa-comment-exclamation\n          "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", { staticClass: "body-2" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("descriptions.disclaimer")) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _c("v-select", {
                staticClass: "memo-access subtitle-2 xx-dense-select",
                attrs: {
                  color: "info",
                  dense: "",
                  disabled: _vm.disabled,
                  "hide-details": "",
                  "item-text": "name",
                  "item-value": "id",
                  items: _vm.accessList,
                  "menu-props": { top: false, offsetY: true, "max-width": 350 },
                  outlined: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          { staticClass: "info--text font-weight-regular" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-1",
                                attrs: { color: "info", "x-small": "" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(data.item.icon) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.name) +
                                "\n          "
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var attrs = ref.attrs
                      var item = ref.item
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              { staticClass: "access-item" },
                              "v-list-item",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c(
                              "v-list-item-icon",
                              { staticClass: "mr-4" },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "pt-2", attrs: { small: "" } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.icon) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item.name) +
                                      "\n              "
                                  )
                                ]),
                                _c(
                                  "v-list-item-subtitle",
                                  { staticClass: "caption grey--text" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.description) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.newMemos[0].access,
                  callback: function($$v) {
                    _vm.$set(_vm.newMemos[0], "access", $$v)
                  },
                  expression: "newMemos[0].access"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      !_vm.disabled
        ? _c(
            "v-row",
            [
              _c("v-spacer"),
              _c(
                "v-col",
                { staticClass: "py-0", attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "save",
                      attrs: {
                        block: "",
                        color: "accent",
                        disabled: _vm.saving || !_vm.hasChanges
                      },
                      on: { click: _vm.update }
                    },
                    [
                      _vm.saving
                        ? _c("v-progress-circular", {
                            attrs: {
                              color: "primary lighten-2",
                              indeterminate: "",
                              size: "22",
                              width: "2"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.save")) +
                                "\n        "
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }