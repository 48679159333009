<template>
  <ValidationProvider
    ref="jobStatus"
    v-slot="{ invalid }"
    :detect-input="false"
    immediate
    :rules="rules"
    tag="div"
    :vid="vid"
  >
    <v-menu
      :close-on-content-click="false"
      min-width="450px"
      offset-y
      :nudge-bottom="nudgeBottom"
      :nudge-top="nudgeTop"
    >
      <template
        v-slot:activator="{ on, value: isActive }"
      >
        <fieldset
          class="nb-fieldset job-status-selection-fieldset"
          v-on="on"
        >
          <template v-if="selectedJobStatusText">
            <legend>{{ $t('labels.jobStatus') }}</legend>
            <div class="pb-2 pt-1 body-2 grey--text text--darken-3">
              {{ selectedJobStatusText }}
            </div>
          </template>
          <template v-else>
            <div
              :class="['py-2 body-2', invalid ? 'error--text' : 'grey--text text--darken-1']"
            >
              {{ label ? label : $t('labels.jobStatus') }}
            </div>
          </template>
          <v-icon
            :class="['dropdown-icon', selectedJobStatusText ? '' : 'no-selection', invalid ? 'error--text' : '']"
            small
          >
            {{ isActive ? 'fas fa-caret-up' : 'fas fa-caret-down' }}
          </v-icon>
        </fieldset>
      </template>
      <v-card
        class="pa-0 job-status-selection"
        elevation="0"
        outlined
      >
        <v-container
          v-if="multiple"
          class="py-0"
        >
          <v-row>
            <v-col
              class="all-job-status"
              cols="3"
            >
              <v-icon
                color="grey"
                x-small
                @click="toggleAllJobStatus()"
              >
                {{ allJobStatusSelected ? 'far fa-check-square' : 'far fa-square' }}
              </v-icon>
              <span
                class="body-2 ml-2"
                @click="toggleAllJobStatus()"
              >
                {{ $t('labels.selectAll') }}
              </span>
            </v-col>
            <v-col cols="9">
              <v-text-field
                v-model.trim="filter"
                append-icon="fal fa-search"
                class="job-status-name-filter extra-dense-text-field"
                :clearable="!!filter"
                dense
                hide-details
                :placeholder="$t('labels.search')"
                solo
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="py-0"
              cols="6"
            >
              <span class="body-2 font-weight-medium">
                {{ $t('labels.inHouse') }}
              </span>
            </v-col>
            <v-col
              class="py-0"
              cols="6"
            >
              <span class="body-2 font-weight-medium">
                {{ $t('labels.registry') }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="jobStatusList in [{ name: 'inHouseJobStatus', items: inHouseJobStatus }, { name: 'registryJobStatus', items: registryJobStatus }]"
              :key="jobStatusList.name"
              cols="6"
            >
              <v-card
                class="list-container"
                elevation="0"
                outlined
              >
                <div
                  v-if="jobStatusList.items.length === 0"
                  class="px-4 py-2 body-2"
                >
                  {{ $t('labels.noContent') }}
                </div>
                <v-list
                  v-else
                  class="py-0 v-select-list"
                  dense
                >
                  <v-list-item
                    @click="toggleAllJobStatus(jobStatusList.name)"
                  >
                    <template v-if="allJobStatusSelectedInSection(jobStatusList.name)">
                      <v-list-item-action class="mr-0">
                        <v-icon
                          color="secondary"
                          x-small
                        >
                          far fa-check-square
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 secondary--text font-weight-regular"
                        >
                          {{ $t('labels.selectAll') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-action class="mr-0">
                        <v-icon
                          color="grey"
                          x-small
                        >
                          far fa-square
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          class="body-2 grey--text text--darken-3 font-weight-regular"
                        >
                          {{ $t('labels.selectAll') }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                  <v-divider />
                  <v-list-item
                    v-for="js in jobStatusList.items"
                    :key="js.id"
                    :class="[`js-${js.id}`, innerValue.includes(js.id) ? 'v-list-item--active' : '']"
                    @click="toggleJobStatus(js.id)"
                  >
                    <template v-if="innerValue.includes(js.id)">
                      <v-list-item-action class="mr-0">
                        <v-icon
                          color="secondary"
                          x-small
                        >
                          far fa-check-square
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-tooltip
                          max-width="300px"
                          top
                        >
                          <template #activator="{ on, attrs }">
                            <v-list-item-title
                              class="body-2 secondary--text font-weight-regular"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ js.name }}
                            </v-list-item-title>
                          </template>
                          <span class="body-2">
                            {{ js.name }}
                          </span>
                        </v-tooltip>
                      </v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-action class="mr-0">
                        <v-icon
                          color="grey"
                          x-small
                        >
                          far fa-square
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-tooltip
                          max-width="300px"
                          top
                        >
                          <template #activator="{ on, attrs }">
                            <v-list-item-title
                              class="body-2 grey--text text--darken-3 font-weight-regular"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ js.name }}
                            </v-list-item-title>
                          </template>
                          <span class="body-2">
                            {{ js.name }}
                          </span>
                        </v-tooltip>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>
  </ValidationProvider>
</template>

<script>
import _ from 'lodash';
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider
  },
  props: {
    vid: {
      default: '',
      type: String
    },
    label: {
      default: '',
      type: String
    },
    multiple: {
      default: true,
      type: Boolean
    },
    nudgeBottom: {
      default: '',
      type: String
    },
    nudgeTop: {
      default: '',
      type: String
    },
    rules: {
      default: '',
      type: [String, Object]
    },
    value: {
      default () {
        return [];
      },
      type: [Array, Number]
    }
  },
  data () {
    return {
      filter: '',
      innerValue: _.isArray(this.value) ? _.cloneDeep(this.value) : this.value
    };
  },
  computed: {
    allJobStatusIds () {
      return [...this.inHouseJobStatusIds, ...this.registryJobStatusIds];
    },
    allJobStatusSelected () {
      return this.innerValue.length === (this.inHouseJobStatus.length + this.registryJobStatus.length);
    },
    inHouseJobStatus () {
      return _.filter(_.sortBy(this.$store.state.org.jobStatus, ['name']), (js) => {
        let matches = !js.registry;
        if (this.filter) {
          matches &= js.name.toLowerCase().includes(this.filter.toLowerCase());
        }
        return matches;
      });
    },
    inHouseJobStatusIds () {
      return this.inHouseJobStatus.map(js => js.id);
    },
    jobStatusById () {
      return this.$store.state.org.jobStatus.reduce((obj, js) => {
        obj[js.id] = js;
        return obj;
      }, {});
    },
    registryJobStatus () {
      return _.filter(_.sortBy(this.$store.state.org.jobStatus, ['name']), (js) => {
        let matches = js.registry;
        if (this.filter) {
          matches &= js.name.toLowerCase().includes(this.filter.toLowerCase());
        }
        return matches;
      });
    },
    registryJobStatusIds () {
      return this.registryJobStatus.map(js => js.id);
    },
    selectedJobStatusText () {
      return this.innerValue.map((id) => this.jobStatusById[id].name).join(', ');
    },
    someJobStatusSelected () {
      return this.innerValue.length > 0 && !this.allJobStatusSelected;
    }
  },
  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      this.$emit('input', newVal);
      this.$refs.jobStatus.syncValue(this.innerValue);
      this.$refs.jobStatus.validate();
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal;
    }
  },
  mounted () {
    this.$refs.jobStatus.syncValue(this.innerValue);
    this.$refs.jobStatus.validate();
  },
  methods: {
    allJobStatusSelectedInSection (section) {
      const sectionIds = this[`${section}Ids`];
      return _.intersection(sectionIds, this.innerValue).length === sectionIds.length;
    },
    toggleAllJobStatus (section) {
      if (section) {
        const sectionIds = this[`${section}Ids`];
        const uncheckedJobStatus = _.difference(sectionIds, this.innerValue);
        if (uncheckedJobStatus.length === 0) {
          this.innerValue = _.difference(this.innerValue, sectionIds);
        } else {
          this.innerValue.push(...uncheckedJobStatus);
        }
      } else {
        if (this.allJobStatusSelected) {
          this.innerValue = [];
        } else {
          this.innerValue = _.cloneDeep(this.allJobStatusIds);
        }
      }
    },
    toggleJobStatus (jobStatusId) {
      const idx = _.findIndex(this.innerValue, (id) => id === jobStatusId);
      if (idx >= 0) {
        this.innerValue.splice(idx, 1);
      } else {
        this.innerValue.push(jobStatusId);
      }
    }
  }
};
</script>

<style lang="scss">
.job-status-selection {
  .all-job-status {
    align-content: center;
    button, span {
      cursor: pointer;
    }
  }
  .list-container {
    height: 244px;
    overflow-y: scroll;
  }
}
.job-status-selection-fieldset {
  background-color: white;
  position: relative;
  .dropdown-icon {
    position: absolute;
    right: 18px;
    top: 5px;
    &.no-selection {
      top: 10px;
    }
  }
}
</style>
