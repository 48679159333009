var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.errorCount > 0
    ? _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c("span", { staticClass: "body-2 grey--text text--darken-3" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("descriptions.consecutiveShiftsHint")) +
                "\n  "
            )
          ]),
          _vm._l(_vm.errors, function(error, errorIdx) {
            return [
              _c(
                "v-list-item",
                {
                  key: "" + error.user.id,
                  on: {
                    click: function($event) {
                      _vm.$emit("filter", _vm.getFilter(error.user))
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-content",
                    {
                      staticClass:
                        "subtitle-2 font-weight-regular d-inline-block text-truncate pr-1"
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(error.user.fullName) + "\n      "
                      )
                    ]
                  ),
                  _c("v-list-item-action", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "mx-4 caption d-inline-block text-truncate pr-1 info--text"
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.getUserStatus(error.user)) +
                            "\n        "
                        )
                      ]
                    )
                  ]),
                  _c(
                    "v-list-item-action",
                    {
                      staticClass:
                        "subtitle-2 font-weight-medium d-inline-block text-truncate error--text"
                    },
                    [_vm._v("\n        " + _vm._s(error.count) + "\n      ")]
                  )
                ],
                1
              ),
              errorIdx < _vm.errorCount - 1
                ? _c("v-divider", { key: "div" + error.user.id })
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _c("NoContent", {
        attrs: {
          height: 140,
          exciting: true,
          width: 140,
          message: _vm.$t("descriptions.consecutiveShiftsNoErrors")
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }