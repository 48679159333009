var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _vm.shifts.length === 0
        ? _c("NoContent", {
            attrs: {
              exciting: "",
              height: 140,
              width: 140,
              message: _vm.$t("descriptions.overtimeNoErrors")
            }
          })
        : [
            _c("div", { staticClass: "mb-2" }, [
              _c("span", { staticClass: "body-2 grey--text text--darken-3" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("labels.totalWorkingHours")) +
                    "\n      "
                )
              ]),
              _c("span", { staticClass: "body-2 error--text" }, [
                _vm._v("\n        " + _vm._s(_vm.error.hours) + "\n      ")
              ]),
              _c("span", { staticClass: "body-2 grey--text text--darken-3" }, [
                _vm._v("\n        /\n      ")
              ]),
              _c("span", { staticClass: "body-2 grey--text text--darken-3" }, [
                _vm._v("\n        " + _vm._s(_vm.user.weeklyHours) + "\n      ")
              ]),
              _c("span", { staticClass: "body-2 grey--text text--darken-3" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("labels.hrs")) + "\n      ")
              ])
            ]),
            _vm._l(_vm.shifts, function(shift) {
              return _c("ShiftsCard", {
                key: shift.id,
                staticClass: "mb-4",
                attrs: {
                  shifts: [shift],
                  "show-shift-date": true,
                  user: _vm.user
                },
                on: {
                  click: function($event) {
                    return _vm.$emit("show-shift", shift)
                  }
                }
              })
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }