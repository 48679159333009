<template>
  <v-container
    class="schedule-details px-0 py-0"
  >
    <template v-if="isShift && showHistory">
      <v-list-item class="headline page-title  py-0 px-8">
        <v-list-item-icon class="icon">
          <v-btn
            icon
            @click="closeHistory"
          >
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $t('labels.history') }}
        </v-list-item-content>
      </v-list-item>
      <ShiftHistory
        class="px-8"
        :shift="shift"
        :style="{ height: `${height - 100}px` }"
      />
    </template>
    <template v-else-if="isShift && showErrors">
      <v-list-item class="headline page-title  py-0 px-8">
        <v-list-item-icon class="icon">
          <v-btn
            icon
            @click="closeErrors"
          >
            <v-icon>fal fa-arrow-left</v-icon>
          </v-btn>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $tc('labels.error', 2) }}
        </v-list-item-content>
      </v-list-item>
      <UserWeeklySummary
        class="px-8"
        :header="{}"
        :errors="errors"
        :show-header="false"
        :user="user"
        :style="{ height: `${height - 100}px` }"
      />
    </template>
    <template v-else>
      <v-list-item class="headline page-title py-0 px-8">
        <v-list-item-icon class="icon">
          <v-icon>fal fa-calendar</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $t('labels.scheduleDetails') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-alert
        v-if="isShift && errors.length > 0 && canSeeErrors"
        class="caption dense font-weight-medium mx-8"
        color="warning"
        dense
        outlined
        text
      >
        <v-icon
          slot="prepend"
          class="ml-n1 mr-3"
          color="warning"
          size="12"
        >
          fas fa-exclamation-triangle
        </v-icon>
        <v-row dense>
          <v-col cols="10">
            {{ $t('descriptions.staffErrorsPanel') }}
          </v-col>
          <v-col
            cols="2"
            align-self="center"
          >
            <v-btn
              class="show-errors"
              color="warning"
              x-small
              @click="openErrors"
            >
              {{ $t('labels.view') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-card
        v-if="isShift"
        class="mx-8"
        outlined
      >
        <v-container class="py-0">
          <v-row>
            <v-col
              class="pb-0"
              cols="12"
            >
              <span class="grey--text text--darken-3 body-2 font-weight-medium mr-3">
                <UserName
                  :user="latestUser"
                />
              </span>
              <v-icon
                :color="userState.color"
                size="8"
              >
                fas fa-circle
              </v-icon>
              <span class="caption grey--text text--darken-2">
                {{ userState.text }}
              </span>
            </v-col>
          </v-row>
          <v-row
            class="px-0 mb-2"
            no-gutters
          >
            <v-col class="caption grey--text text--darken-1">
              <template v-for="(detail, idx) in userDetails">
                <div
                  :key="`${detail}`"
                  class="d-inline-block"
                >
                  {{ detail }}
                </div>
                <v-divider
                  v-if="idx < userDetails.length - 1"
                  :key="`${detail}-div`"
                  class="separator mx-2 d-inline pb-1"
                  vertical
                />
              </template>
              <div
                v-if="latestUser.charge"
                class="pt-1"
              >
                <v-icon
                  class="pr-1"
                  x-small
                >
                  fal fa-check
                </v-icon>
                {{ $t('labels.canActAsCharge') }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-divider class="mt-0" />
        <v-container class="py-0 grey--text text--darken-3 body-2 py-2">
          <div class="shift-time">
            <span
              :class="[shift.overtime ? 'error--text' : '']"
            >
              {{ getShiftStartTime(shift) }}
            </span>
            <span
              v-if="getShiftStartTime(shift)"
              class="px-1"
            >
              -
            </span>
            <span
              :class="[shift.overtime ? 'error--text' : '']"
            >
              {{ getShiftEndTime(shift) }}
            </span>
            <v-icon
              v-if="hasDifferentPayrollDate"
              class="payroll-diff text--darken-3 mx-1"
              color="grey"
              x-small
            >
              fas fa-level-up
            </v-icon>
            <v-tooltip
              v-if="hasChanges"
              right
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  color="info"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  fal fa-exclamation-triangle
                </v-icon>
              </template>
              <span class="body-2">
                {{ $t('labels.unsavedChanges') }}
              </span>
            </v-tooltip>
          </div>
          <span class="text-capitalize grey--text text--darken-3 caption">
            {{ getShiftWorkingStatus(shift) }}
          </span>
        </v-container>
        <v-divider class="mt-0" />
        <ShiftActivity
          :allow-delete="allowDelete"
          :changes="changes"
          :date="date"
          :shift="shift"
          :read-only="readOnly"
          :update-on-change="true"
          :user="user"
          @created="createShift"
          @canceled-create="canceledCreate"
          @change="syncShift"
          @updated="updateShift"
          @split-shift="splitShift"
          @show-history="openHistory"
          @has-changes="trackChanges"
          @removed="$emit('removed', shift)"
        />
        <SplitShiftDialog
          :date="date"
          :shift="shift"
          :show="showSplitShiftDialog"
          :user="user"
          @close="hideSplitShiftDialog"
          @saved="splitSaved"
        />
      </v-card>
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import ShiftActivity from '@/components/scheduling/ShiftActivity';
import ShiftHistory from '@/components/scheduling/ShiftHistory';
import SplitShiftDialog from '@/components/scheduling/SplitShiftDialog.vue';
import UserWeeklySummary from '@/views/scheduling/panels/UserWeeklySummary';
import { getDuration, isWorkingShiftForDisplay } from '@/utils/scheduling';
import { getUnsavedChangesDialogProps } from '@/utils';
import UserName from '@/components/scheduling/UserName';

export default {
  components: {
    ShiftActivity,
    ShiftHistory,
    SplitShiftDialog,
    UserWeeklySummary,
    UserName
  },
  props: {
    allowDelete: {
      default: false,
      type: Boolean
    },
    date: {
      default: function () {
        return moment();
      },
      type: Object,
      required: true
    },
    details: {
      default: function () {
        return {};
      },
      type: Object,
      required: true
    },
    getErrors: {
      default: function () {
        return () => [];
      },
      type: Function
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    let type = null;
    let shift = {};
    let changes = {};
    let shiftAction = null;
    if (this.details) {
      type = this.details.type;
      switch (type) {
        case 'shift':
          if (!_.isEmpty(this.details.changes)) {
            changes = this.details.changes;
          }
          shift = { ...this.details.value };
          shiftAction = this.details.action;
          if (!_.get(shift, 'settings.sitter', null)) {
            _.set(shift, 'settings.sitter', {
              reason: '',
              room: ''
            });
          }
          break;
      }
    }
    return {
      changes,
      hasChanges: false,
      height: 500,
      type,
      shift,
      shiftAction,
      showErrors: false,
      showHistory: false,
      showSplitShiftDialog: false
    };
  },
  computed: {
    canSeeErrors () {
      return !this.$store.getters['account/isStaff']();
    },
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    errors () {
      try {
        return this.getErrors(this.user, this.shift.id ? null : this.shift);
      } catch {
        return [];
      }
    },
    hasDifferentPayrollDate () {
      let hasDifferentPayrollDate = false;
      if (this.shift.payrollDate) {
        hasDifferentPayrollDate = !moment(this.shift.payrollDate).isSame(moment(this.shift.date));
      }

      return hasDifferentPayrollDate;
    },
    headers () {
      return [
        { sortable: false, text: '', value: 'shift' }
      ];
    },
    isShift () {
      return this.type === 'shift' && !_.isEmpty(this.shift);
    },
    latestUser () {
      return this.$store.state.org.employees[this.user.userId];
    },
    shiftFlags () {
      return _.sortBy(this.$store.state.org.flags, ['name']);
    },
    shiftFlagsById () {
      return this.shiftFlags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftName () {
      return this.isShift && this.shift.typeId ? this.getShiftTypeById(this.shift.typeId).name : '';
    },
    shiftTime () {
      let time = '';
      if (this.isShift && this.shift.typeId) {
        const shiftType = this.getShiftTypeById(this.shift.typeId);
        const startTime = this.shift.startTime ? this.shift.startTime : shiftType.startTime;
        const endTime = this.shift.endTime ? this.shift.endTime : shiftType.endTime;
        time = [_.split(startTime, ':', 2).join(':'), _.split(endTime, ':', 2).join(':')].join(' - ');
      }
      return time;
    },
    userDetails () {
      return [
        this.latestUser.jobTypeName,
        this.latestUser.jobStatusName,
        this.latestUser.shiftTypeName
      ];
    },
    userState () {
      const colors = {
        active: 'success',
        inactive: 'grey'
      };
      let color = colors[this.latestUser.state];
      let text = this.$t(`labels.${this.latestUser.state}`);
      return {
        color,
        text
      };
    }
  },
  mounted: function () {
    this.updateHeight();
    window.addEventListener('resize', _.debounce(this.updateHeight, 200));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateHeight);
  },
  methods: {
    canceledCreate () {
      this.hasChanges = false;
      this.$store.commit('unmark_unsaved_changes', this);
      this.$emit('has-changes', false);
      this.$emit('canceled-create');
    },
    close () {
      if (this.hasChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.$store.commit('unmark_unsaved_changes', this);
          this.$emit('has-changes', false);
          this.$emit('close');
        });
      } else {
        this.$emit('close');
      }
    },
    closeErrors () {
      this.showErrors = false;
    },
    closeHistory () {
      this.showHistory = false;
    },
    createShift (shift) {
      this.shift = shift;
      this.hasChanges = false;
      this.$store.commit('unmark_unsaved_changes', this);
      this.$emit('has-changes', false);
      this.$emit('created', shift);
    },
    getShiftDuration (shift) {
      let duration = '';
      if (shift && shift.typeId) {
        const shiftType = this.getShiftTypeById(shift.typeId);
        const start = shift.startTime ? shift.startTime : shiftType.startTime;
        const end = shift.endTime ? shift.endTime : shiftType.endTime;
        duration = getDuration(start, end);
      }

      return duration;
    },
    getShiftEndTime (shift) {
      let time = '';
      if (shift && shift.typeId) {
        const shiftType = this.getShiftTypeById(shift.typeId);
        const end = shift.endTime ? shift.endTime : shiftType.endTime;
        time = _.split(end, ':', 2).join(':');
      }

      return time;
    },
    getShiftStartTime (shift) {
      let time = '';
      if (shift && shift.typeId) {
        const shiftType = this.getShiftTypeById(shift.typeId);
        const start = shift.startTime ? shift.startTime : shiftType.startTime;
        time = _.split(start, ':', 2).join(':');
      }

      return time;
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    getShiftWorkingStatus (shift) {
      let status = this.$t('labels.onDuty');
      if (shift && this.isShiftNonDuty(shift)) {
        if (shift.canceled) {
          status = this.$t('labels.canceled');
        } else if (shift.giveaway) {
          status = this.$t('labels.giveaway');
        } else if (shift.swapped) {
          status = this.$t('labels.swapped');
        } else {
          status = this.$t('labels.nonDuty');
        }
      } else if (shift && shift.onCall) {
        status = this.$t('labels.onCall');
      } else if (shift && shift.available) {
        status = this.$t('labels.available');
      }

      return status;
    },
    hideSplitShiftDialog () {
      this.showSplitShiftDialog = false;
    },
    isShiftNonDuty (shift) {
      const shiftCopy = _.cloneDeep(shift);
      shiftCopy.flags = _.filter(shiftCopy.flags, _.isFinite);
      return !isWorkingShiftForDisplay(shiftCopy, this.shiftFlagsById);
    },
    moment,
    openErrors () {
      this.showErrors = true;
    },
    openHistory () {
      if (this.$can('edit', 'shift')) {
        this.showHistory = true;
      }
    },
    splitSaved (data) {
      this.hasChanges = false;
      this.$store.commit('unmark_unsaved_changes', this);
      this.$emit('has-changes', false);
      this.$emit('saved-splits', data);
    },
    splitShift () {
      this.showSplitShiftDialog = true;
    },
    syncShift (shift) {
      this.shift = shift;
    },
    trackChanges (hasChanges) {
      this.hasChanges = hasChanges;
      if (hasChanges) {
        this.$store.commit('mark_unsaved_changes', this);
      } else {
        this.$store.commit('unmark_unsaved_changes', this);
      }
      this.$emit('has-changes', hasChanges);
    },
    updateHeight () {
      if (this.$el.parentElement) {
        this.height = this.$el.parentElement.clientHeight;
      }
    },
    updateShift (data) {
      const { shift } = data;
      this.changes = {};
      this.shift = shift;
      this.hasChanges = false;
      this.$store.commit('unmark_unsaved_changes', this);
      this.$emit('has-changes', false);
      this.$emit('updated', data);
    }
  }
};
</script>

<style lang="scss">
@mixin schedule-details-btn($color, $bgColor) {
  background-color: $bgColor !important;
  border: none !important;
  border-radius: 4px !important;
  color: $color !important;
  height: 40px !important;
}

.schedule-details {
  @include shift-activity();
  .available {
    .v-label {
      color: map-get($grey, 'darken-3');
      font-size: 14px;
    }
    .v-messages__message {
      color: $error;
      font-size: 12px;
    }
  }
  .shift {
    flex: none;
    width: 100px;
  }
  .available-badge {
    background-color: map-get($grey, 'lighten-2');
    border-radius: 4px;
    margin-left: 5px;
    padding: 2px 5px 2px 3px;
  }
  .shift-time > span {
    vertical-align: middle;
  }
  .tag {
    border: 1px dashed map-get($grey, 'base');
    &.active {
      border: 1px dashed $primary;
    }
  }
  .shift-types {
    display: inline-block;
    button.shift-type {
      @include schedule-details-btn(map-get($nb-cyan, 'base'), map-get($nb-cyan, 'lighten-5'));
      width: 350px !important;

      &.e {
        width: 170px !important;
      }
      &.o {
        width: 110px !important;
      }
      &.v-btn--active {
        background-color: map-get($nb-cyan, 'base') !important;
        color: #FFF;
      }
    }
  }
  .sitter-details {
    input {
      font-size: 14px;
    }
    .v-label {
      font-size: 14px;
    }
  }
  .user {
    flex: none;
    width: 156px;
  }
  .shift-flags {
    .v-select__selections {
      min-height: 30px !important;
    }
  }
}
.flag-short-code {
  justify-content: center;
  padding: 0px;
  width: 43px !important;
}
</style>
