var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "schedule", attrs: { fluid: "" } },
    [
      _vm.noSnapshots
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c(
                      "v-menu",
                      {
                        attrs: {
                          "min-width": "250px",
                          "close-on-content-click": false,
                          "offset-y": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var value = ref.value
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass:
                                          "title font-weight-regular text-capitalize schedule-dropdown px-2",
                                        attrs: {
                                          color: "primary",
                                          elevation: "0",
                                          outlined: "",
                                          width: "250px"
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "body-2 font-weight-medium"
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.selectedScheduleText) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c("v-icon", { attrs: { size: "20" } }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              value
                                                ? "fas fa-caret-up"
                                                : "fas fa-caret-down"
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1028772057
                        ),
                        model: {
                          value: _vm.showSchedulePicker,
                          callback: function($$v) {
                            _vm.showSchedulePicker = $$v
                          },
                          expression: "showSchedulePicker"
                        }
                      },
                      [
                        _c("ScheduleSelection", {
                          attrs: { department: _vm.department },
                          on: {
                            select: _vm.setSelectedSchedule,
                            close: function($event) {
                              _vm.showSchedulePicker = false
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-container",
              {
                staticClass: "schedule",
                attrs: { fluid: "", "fill-height": "" }
              },
              [
                _c(
                  "portal",
                  { attrs: { to: "page-title" } },
                  [
                    _c(
                      "v-container",
                      { staticClass: "schedule-header py-0 mx-0" },
                      [
                        _c(
                          "v-row",
                          { attrs: { justify: "space-between" } },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "pl-0",
                                attrs: { "align-self": "center", cols: "auto" }
                              },
                              [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "ml-1",
                                    attrs: { align: "center" }
                                  },
                                  [
                                    _c("DepartmentSelector", {
                                      attrs: {
                                        width: _vm.pageTitleWidth + "px"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-spacer")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticStyle: { "margin-top": "-150px" },
                    attrs: { justify: "center" }
                  },
                  [
                    _c("v-col", { attrs: { md: "6", sm: "12" } }, [
                      _c(
                        "div",
                        { staticClass: "mt-10 mx-3 pa-6" },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _c("v-img", {
                                attrs: {
                                  contain: "",
                                  "max-width": "50%",
                                  src: require("@/assets/images/vacation-penguin.svg")
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", {
                            staticClass:
                              "darken-2 grey--text mt-5 px-5 text--darken-3 text-center ",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("descriptions.noSnapshots")
                              )
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm.retrievingSchedule &&
          !_vm.gridInitialized &&
          _vm.showSkeletonLoader
        ? [
            _c(
              "v-container",
              { staticClass: "schedule", attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _vm._l(2, function(n) {
                          return _c(
                            "v-col",
                            { key: n, attrs: { cols: "auto" } },
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "single-action",
                                attrs: { type: "actions" }
                              })
                            ],
                            1
                          )
                        })
                      : _vm._l(5, function(n) {
                          return _c(
                            "v-col",
                            { key: n, attrs: { cols: "auto" } },
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "single-action",
                                attrs: { type: "actions" }
                              })
                            ],
                            1
                          )
                        }),
                    _c("v-spacer")
                  ],
                  2
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "no-action",
                          attrs: { type: "table" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : !_vm.retrievingSchedule
        ? [
            _c(
              "v-container",
              {
                staticClass: "schedule",
                style: _vm.gridMaxWidth,
                attrs: { fluid: "" }
              },
              [
                _c(
                  "portal",
                  { attrs: { to: "page-title" } },
                  [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? [
                          _c(
                            "v-container",
                            { staticClass: "schedule-header py-0" },
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "space-between" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-0",
                                      attrs: {
                                        "align-self": "center",
                                        cols: "auto"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("DepartmentSelector", {
                                            attrs: {
                                              width: _vm.pageTitleWidth + "px"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        "align-self": "center",
                                        cols: "auto"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          class: [
                                            "font-weight-medium white--text",
                                            _vm.scheduleStateIndicator
                                              .stateLabelCssClass
                                          ],
                                          attrs: { small: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.scheduleStateIndicator
                                                    .stateLabelKey
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-medium ml-3 grey--text text--darken-1"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.readOnlySnapshot"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : [
                          _c(
                            "v-row",
                            {
                              staticClass: "schedule-header",
                              attrs: { justify: "space-between" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    "align-self": "center",
                                    cols: "auto"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("DepartmentSelector", {
                                        attrs: {
                                          width: _vm.pageTitleWidth + "px"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    "align-self": "center",
                                    cols: "auto"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          class: [
                                            "font-weight-medium ml-5 white--text",
                                            _vm.scheduleStateIndicator
                                              .stateLabelCssClass
                                          ],
                                          attrs: { small: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.scheduleStateIndicator
                                                    .stateLabelKey
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "caption font-weight-medium ml-3 grey--text text--darken-1"
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.readOnlySnapshot"
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              !_vm.$vuetify.breakpoint.smAndDown
                                ? [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pr-0",
                                        attrs: {
                                          "align-self": "center",
                                          cols: "auto"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "nudge-top": "10"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var tooltipOn = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                icon: ""
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          Object.assign(
                                                            {},
                                                            tooltipOn,
                                                            {
                                                              click:
                                                                _vm.toggleHelpPanel
                                                            }
                                                          )
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "primary"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        fal fa-info-circle\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1846916210
                                            )
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "body-2" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "labels.information"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                  ],
                  2
                ),
                _vm.$vuetify.breakpoint.smAndDown
                  ? _c(
                      "portal",
                      { attrs: { to: "page-menu" } },
                      [
                        _c(
                          "v-list-item",
                          { on: { click: _vm.toggleHelpPanel } },
                          [
                            _c(
                              "v-list-item-action",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(
                                    "\n              fal fa-info-circle\n            "
                                  )
                                ])
                              ],
                              1
                            ),
                            _c("v-list-item-title", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("labels.information")) +
                                  "\n          "
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  { attrs: { justify: "end", "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-tabs",
                          {
                            attrs: {
                              "mobile-breakpoint": "768",
                              "show-arrows": "",
                              "slider-color": "accent",
                              "slider-size": "3"
                            },
                            model: {
                              value: _vm.selectedJobTypeTab,
                              callback: function($$v) {
                                _vm.selectedJobTypeTab = $$v
                              },
                              expression: "selectedJobTypeTab"
                            }
                          },
                          _vm._l(_vm.groupedJobTypes, function(
                            jobTypeGroup,
                            index
                          ) {
                            return _c(
                              "v-tab",
                              {
                                key: index,
                                nativeOn: {
                                  "!click": function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.onJobTypeChange(index)
                                  }
                                }
                              },
                              [
                                _vm._l(jobTypeGroup, function(
                                  jobType,
                                  jobIndex
                                ) {
                                  return [
                                    jobIndex > 0
                                      ? _c(
                                          "span",
                                          {
                                            key: jobType.id + "-plus",
                                            staticClass: "ml-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  +\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        key: jobType.id + "-name",
                                        class: jobIndex > 0 ? "ml-2" : ""
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(jobType.name) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    index > 0
                                      ? [
                                          _vm.hasValidationErrors(jobType.id)
                                            ? _c(
                                                "v-icon",
                                                {
                                                  key:
                                                    jobType.id + "-validation",
                                                  staticClass:
                                                    "error--text ml-1 mr-1",
                                                  attrs: { "x-small": "" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    fas fa-exclamation-circle\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      : [
                                          _vm.hasValidationErrors()
                                            ? _c(
                                                "v-icon",
                                                {
                                                  key:
                                                    jobType.id + "-validation",
                                                  staticClass:
                                                    "error--text ml-1 mr-1",
                                                  attrs: { "x-small": "" }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    fas fa-exclamation-circle\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                  ]
                                })
                              ],
                              2
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-spacer")
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "grid-action-bar",
                    attrs: { justify: "center", "justify-md": "end" }
                  },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "min-width": "250px",
                              "close-on-content-click": false,
                              "offset-y": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var value = ref.value
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "title font-weight-regular text-capitalize schedule-dropdown px-2",
                                          attrs: {
                                            color: "primary",
                                            elevation: "0",
                                            outlined: "",
                                            width: "250px"
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "body-2 font-weight-medium"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.selectedScheduleText
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "20" } },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  value
                                                    ? "fas fa-caret-up"
                                                    : "fas fa-caret-down"
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.showSchedulePicker,
                              callback: function($$v) {
                                _vm.showSchedulePicker = $$v
                              },
                              expression: "showSchedulePicker"
                            }
                          },
                          [
                            _c("ScheduleSelection", {
                              attrs: { department: _vm.department },
                              on: {
                                select: _vm.setSelectedSchedule,
                                close: function($event) {
                                  _vm.showSchedulePicker = false
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { staticClass: "pr-0", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            class:
                                              _vm.actionStyles.filters.button
                                                .classes,
                                            attrs: {
                                              icon: "",
                                              value: "filters"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.setOpenedPanelName(
                                                  "filters"
                                                )
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm.hasFilters &&
                                        _vm.openedPanelName !== "filters"
                                          ? _c(
                                              "v-icon",
                                              {
                                                class:
                                                  _vm.actionStyles.filters.icon
                                                    .classes,
                                                attrs: { size: "16" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  fas fa-filter\n                "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                class:
                                                  _vm.actionStyles.filters.icon
                                                    .classes,
                                                attrs: { size: "16" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  fal fa-filter\n                "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("span", { staticClass: "body-2" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.filter")) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { staticClass: "px-0", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-btn",
                          {
                            class: _vm.actionStyles.history.button.classes,
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.setOpenedPanelName(
                                  _vm.SNAPSHOT_HISTORY
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                class: _vm.actionStyles.history.icon.classes,
                                attrs: { size: "16" }
                              },
                              [
                                _vm._v(
                                  "\n              fal fa-history\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("StaffSearch", {
                  attrs: {
                    "append-icon": _vm.staffFilter ? "" : "fal fa-search",
                    clearable: !!_vm.staffFilter,
                    dense: "",
                    "hide-details": "",
                    "nudge-right": "100",
                    solo: "",
                    "target-class": "search-staff py-3 ml-4",
                    "target-style": _vm.searchStaffStyle
                  },
                  model: {
                    value: _vm.staffFilter,
                    callback: function($$v) {
                      _vm.staffFilter =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "staffFilter"
                  }
                }),
                _c("div", {
                  ref: "gridParent",
                  staticClass: "grid-parent",
                  style: _vm.gridStyle
                }),
                _vm.scheduleIsReadOnly
                  ? _c("svg", { style: _vm.gridOverlayStyle }, [
                      _c("polygon", {
                        staticStyle: {
                          "fill-opacity": "0.05",
                          stroke: "transparent",
                          "stroke-width": "0"
                        },
                        attrs: { points: _vm.gridOverlayPath }
                      })
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm.$vuetify.breakpoint.smAndDown
              ? [
                  _c(
                    "v-btn",
                    {
                      class: [
                        "elevation-0 schedule-summary-row-toggle",
                        { active: _vm.mobileSummaryRow }
                      ],
                      style: {
                        bottom: _vm.mobileSummaryRow
                          ? _vm.footerHeight + 54 + "px"
                          : "54px"
                      },
                      attrs: { fixed: "", ripple: false, small: "" },
                      on: {
                        click: function($event) {
                          _vm.mobileSummaryRow = !_vm.mobileSummaryRow
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: "error", "x-small": "" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.mobileSummaryRow
                                  ? "far fa-chevron-down"
                                  : "far fa-chevron-up"
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-footer",
                    {
                      staticClass: "elevation-0 schedule-summary-row pb-0",
                      style: {
                        bottom: _vm.mobileSummaryRow
                          ? "56px"
                          : 56 - _vm.footerHeight + "px"
                      },
                      attrs: { fixed: "" }
                    },
                    _vm._l(_vm.validators, function(validator) {
                      return _c(
                        "v-btn",
                        {
                          key: "tab" + _vm.getValidatorName(validator),
                          staticClass:
                            "mx-2 mb-2 caption font-weight-medium text-capitalize",
                          attrs: { depressed: "" },
                          on: {
                            click: function($event) {
                              return _vm.showErrorsOverview(validator)
                            }
                          }
                        },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mr-2 px-2 white--text",
                              attrs: {
                                color:
                                  _vm.getValidatorErrorCount(validator) > 0
                                    ? "error"
                                    : "primary lighten-2",
                                label: "",
                                "x-small": ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.showErrorsOverview(validator)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.getValidatorErrorCount(validator)
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "labels." + _vm.getValidatorName(validator)
                                )
                              ) +
                              "\n          "
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "pl-2 grey--text text--darken-1",
                              style: {
                                visibility: _vm.hasTabFilters
                                  ? "visible"
                                  : "hidden"
                              },
                              attrs: { "x-small": "" }
                            },
                            [_vm._v("\n            fas fa-filter\n          ")]
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]
              : _c(
                  "v-footer",
                  {
                    staticClass: "schedule-summary-row pr-8 pb-0",
                    style: _vm.summaryRowStyle,
                    attrs: { fixed: "" }
                  },
                  [
                    _vm._l(_vm.validators, function(validator) {
                      return _c(
                        "v-btn",
                        {
                          key: "tab" + _vm.getValidatorName(validator),
                          staticClass:
                            "mx-2 caption font-weight-medium text-capitalize mb-2",
                          attrs: { depressed: "" },
                          on: {
                            click: function($event) {
                              return _vm.showErrorsOverview(validator)
                            }
                          }
                        },
                        [
                          _c(
                            "v-chip",
                            {
                              staticClass: "mr-2 px-2 white--text",
                              attrs: {
                                color:
                                  _vm.getValidatorErrorCount(validator) > 0
                                    ? "error"
                                    : "primary lighten-2",
                                label: "",
                                "x-small": ""
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.showErrorsOverview(validator)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.getValidatorErrorCount(validator)
                                  ) +
                                  "\n        "
                              )
                            ]
                          ),
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "labels." + _vm.getValidatorName(validator)
                                )
                              ) +
                              "\n        "
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "pl-2 grey--text text--darken-1",
                              style: {
                                visibility: _vm.hasTabFilters
                                  ? "visible"
                                  : "hidden"
                              },
                              attrs: { "x-small": "" }
                            },
                            [_vm._v("\n          fas fa-filter\n        ")]
                          )
                        ],
                        1
                      )
                    }),
                    _c("v-spacer")
                  ],
                  2
                ),
            _c("SidePanel", {
              attrs: { panels: _vm.sidePanels },
              on: {
                transitionend: function($event) {
                  return _vm.redrawGrid(true)
                }
              }
            }),
            _vm.nurseDetails
              ? _c("UserDialog", {
                  attrs: {
                    "show-hint": false,
                    user: Object.assign({}, _vm.nurseDetails)
                  },
                  on: {
                    close: _vm.hideNurseDetails,
                    saved: _vm.updateNurseDetails
                  }
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }