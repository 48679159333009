exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n#dailyScheduleNotes .new-memo {\n  position: relative;\n}\n#dailyScheduleNotes .new-memo .hints {\n  position: absolute;\n  bottom: 20px;\n  left: 14px;\n}\n#dailyScheduleNotes .new-memo .hints .disclosure-hint {\n  margin-right: 8px;\n}\n#dailyScheduleNotes .new-memo .memo-access {\n  display: inline-block;\n}\n#dailyScheduleNotes .new-memo .memo-access fieldset {\n  border-color: #0F9FFF;\n  top: -3px;\n}\n#dailyScheduleNotes .new-memo .memo-access .v-input__icon i {\n  color: #0F9FFF;\n}\n#dailyScheduleNotes .new-memo .memo-access .v-select__selections .v-icon {\n  padding-bottom: 2px;\n}\n.access-item .caption {\n  white-space: normal;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};