var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-combobox", {
    ref: "selectEmployees",
    staticClass: "employee-selector",
    attrs: {
      dense: "",
      disabled: _vm.disabled,
      filter: _vm.comboboxFilter,
      "hide-details": !_vm.hint,
      hint: _vm.hint,
      "item-value": "userId",
      items: _vm.filteredEmployees,
      label: _vm.label,
      multiple: "",
      outlined: "",
      "persistent-hint": !!_vm.hint,
      "return-object": false
    },
    on: { change: _vm.onChange },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function(ref) {
          var item = ref.item
          return [
            _vm.employees[item]
              ? _c(
                  "v-chip",
                  {
                    staticClass: "selected",
                    attrs: { close: "", color: "white" },
                    on: {
                      "click:close": function($event) {
                        return _vm.removeSelectedEmployee(item)
                      }
                    }
                  },
                  [
                    _c(
                      "v-avatar",
                      {
                        attrs: {
                          color: _vm.employees[item].avatarBgColor,
                          size: "18"
                        }
                      },
                      [
                        _c("span", { staticClass: "white--text caption-2" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.getAvatar(_vm.employees[item])) +
                              "\n        "
                          )
                        ])
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass:
                          "ml-2 body-2 grey--text text--darken-3 name text-truncate"
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.employees[item].fullName) +
                            "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "item",
        fn: function(ref) {
          var attrs = ref.attrs
          var item = ref.item
          var on = ref.on
          return [
            _c(
              "v-list-item",
              _vm._g(
                _vm._b(
                  {
                    class: [
                      _vm.selected.includes(item.userId)
                        ? "v-list-item--active"
                        : "",
                      ""
                    ],
                    attrs: { title: item.fullName }
                  },
                  "v-list-item",
                  attrs,
                  false
                ),
                on
              ),
              [
                _c(
                  "v-list-item-avatar",
                  { staticClass: "mr-1" },
                  [
                    _c(
                      "v-avatar",
                      { attrs: { color: item.avatarBgColor, size: "30" } },
                      [
                        _c("span", { staticClass: "white--text subtitle-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getAvatar(item)) +
                              "\n          "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _c(
                      "v-list-item-title",
                      {
                        staticClass: "body-2 name-n-avatar mb-n1",
                        attrs: { title: item.fullName }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "d-inline-block text-truncate",
                            staticStyle: { width: "164px" }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.fullName) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    ),
                    _c("v-list-item-subtitle", { staticClass: "caption" }, [
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.jobTypeName) +
                            "\n          "
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ]),
    model: {
      value: _vm.selected,
      callback: function($$v) {
        _vm.selected = $$v
      },
      expression: "selected"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }