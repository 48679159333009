var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-3 user-errors-overview" },
    [
      _vm.showHeader
        ? _c(
            "v-list-item",
            { staticClass: "headline page-title pa-0" },
            [
              _c(
                "v-list-item-icon",
                { staticClass: "icon" },
                [_c("v-icon", [_vm._v("fal fa-calendar-week")])],
                1
              ),
              _c("v-list-item-content", { staticClass: "panel-title" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("labels.weeklySummary")) + "\n    "
                )
              ]),
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("fal fa-times")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.errors.length > 0
        ? _c(
            "v-tabs",
            {
              staticClass: "dense",
              attrs: {
                id: "errorsContent",
                centered: "",
                grow: "",
                "hide-slider": "",
                "show-arrows": ""
              }
            },
            [
              _vm._l(_vm.errors, function(error) {
                return _c(
                  "v-tab",
                  {
                    key: "tab" + _vm.getValidatorName(error),
                    staticClass: "text-capitalize",
                    attrs: { href: "#" + _vm.getValidatorName(error) }
                  },
                  [_vm._v("\n      " + _vm._s(_vm.$t(error.label)) + "\n    ")]
                )
              }),
              _vm._l(_vm.errors, function(error) {
                return _c(
                  "v-tab-item",
                  {
                    key: "content" + _vm.getValidatorName(error),
                    style: _vm.tabItemStyle,
                    attrs: { value: _vm.getValidatorName(error) }
                  },
                  [
                    _c(_vm.getValidatorName(error), {
                      tag: "component",
                      attrs: { error: error, user: _vm.user },
                      on: {
                        "show-shift": function(shift) {
                          return _vm.$emit("show-shift", shift)
                        }
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          )
        : _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c("NoContent", {
                attrs: {
                  exciting: "",
                  height: 140,
                  width: 140,
                  message: _vm.$t("descriptions.noScheduleErrorsForUser", {
                    name: _vm.user.fullName
                  })
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }