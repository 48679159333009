var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-3" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-calendar-week")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("labels.weeklySummary")) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "weekly-summary pa-0 mt-4", style: _vm.containerStyle },
        [
          _vm.hasUsers
            ? _c(
                "v-container",
                { staticClass: "pa-0" },
                _vm._l(_vm.users, function(u) {
                  return _c(
                    "v-card",
                    {
                      key: u.employee.id,
                      staticClass: "mb-4",
                      attrs: { outlined: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("show-user-summary", u)
                        }
                      }
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "pa-0", attrs: { dense: "", flat: "" } },
                        [
                          _c(
                            "v-list-item-group",
                            { attrs: { "active-class": "" } },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "py-2",
                                  attrs: { "two-line": "", dense: "" }
                                },
                                [
                                  _c("v-list-item-content", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "body-2 grey--text text--darken-3 font-weight-medium text-truncate"
                                      },
                                      [_vm._v(_vm._s(u.employee.fullName))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "caption grey--text text--darken-3"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            [
                                              u.employee.jobTypeName,
                                              u.employee.jobStatusShortCode
                                            ].join(" ")
                                          )
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "v-list-item-content",
                                    _vm._l(u.errors, function(error) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: error.label,
                                          staticClass:
                                            "grey--text text--darken-3 error-type mb-1",
                                          attrs: { small: "" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.$t(error.label)) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "v-card",
                { attrs: { outlined: "" } },
                [
                  _c("NoContent", {
                    attrs: {
                      exciting: "",
                      height: 140,
                      width: 140,
                      message: _vm.$t("descriptions.noScheduleErrors")
                    }
                  })
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }