var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canSeeAllDepartments
    ? _c("v-select", {
        staticClass: "department-picker subtitle-2",
        staticStyle: { "max-width": "200px" },
        attrs: {
          dense: "",
          "hide-details": "",
          "item-text": "name",
          "item-value": "id",
          items: _vm.departments,
          "menu-props": { top: false, offsetY: true },
          outlined: ""
        },
        on: { change: _vm.setActiveDepartment },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function(ref) {
                var attrs = ref.attrs
                var item = ref.item
                var on = ref.on
                return [
                  _c(
                    "v-list-item",
                    _vm._g(
                      _vm._b(
                        { staticClass: "department-item" },
                        "v-list-item",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              "\n          " + _vm._s(item.name) + "\n        "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          false,
          816647514
        ),
        model: {
          value: _vm.selectedDepartmentId,
          callback: function($$v) {
            _vm.selectedDepartmentId = $$v
          },
          expression: "selectedDepartmentId"
        }
      })
    : _c(
        "span",
        {
          staticClass: "title page-title d-inline-block text-truncate",
          style: _vm.pageTitleStyle
        },
        [_vm._v("\n  " + _vm._s(_vm.activeDepartment.name) + "\n")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }