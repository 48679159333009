var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "schedule", attrs: { fluid: "" } },
    [
      _vm.retrievingSchedule && !_vm.gridInitialized && _vm.showSkeletonLoader
        ? [
            _c(
              "v-container",
              { staticClass: "schedule", attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  { attrs: { dense: "" } },
                  [
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _vm._l(2, function(n) {
                          return _c(
                            "v-col",
                            { key: n, attrs: { cols: "auto" } },
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "single-action",
                                attrs: { type: "actions" }
                              })
                            ],
                            1
                          )
                        })
                      : _vm._l(5, function(n) {
                          return _c(
                            "v-col",
                            { key: n, attrs: { cols: "auto" } },
                            [
                              _c("v-skeleton-loader", {
                                staticClass: "single-action",
                                attrs: { type: "actions" }
                              })
                            ],
                            1
                          )
                        }),
                    _c("v-spacer")
                  ],
                  2
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("v-skeleton-loader", {
                          staticClass: "no-action",
                          attrs: { type: "table" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : !_vm.retrievingSchedule
        ? [
            _c(
              "v-container",
              {
                staticClass: "schedule",
                style: _vm.gridMaxWidth,
                attrs: { fluid: "" }
              },
              [
                _c(
                  "portal",
                  { attrs: { to: "page-title" } },
                  [
                    _vm.$vuetify.breakpoint.width < 600
                      ? [
                          _c(
                            "v-container",
                            { staticClass: "schedule-header py-0" },
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "space-between" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pl-0",
                                      attrs: {
                                        "align-self": "center",
                                        cols: "auto"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("DepartmentSelector"),
                                          !_vm.readOnly
                                            ? _c("v-switch", {
                                                staticClass: "ml-4",
                                                attrs: {
                                                  color: "success",
                                                  dense: "",
                                                  disabled:
                                                    _vm.updatingSettings,
                                                  "hide-details": "",
                                                  inset: "",
                                                  loading: _vm.updatingSettings
                                                },
                                                on: {
                                                  change:
                                                    _vm.updateTemplateAutomation
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function() {
                                                        return [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                "max-width":
                                                                  "300px",
                                                                bottom: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "activator",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var on =
                                                                        ref.on
                                                                      var attrs =
                                                                        ref.attrs
                                                                      return [
                                                                        _c(
                                                                          "span",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                staticClass:
                                                                                  "mr-4 body-2 grey--text text--darken-3",
                                                                                attrs: {
                                                                                  title: _vm.$t(
                                                                                    "labels.automate"
                                                                                  )
                                                                                }
                                                                              },
                                                                              "span",
                                                                              attrs,
                                                                              false
                                                                            ),
                                                                            on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "\n                            " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "labels.automate"
                                                                                  )
                                                                                ) +
                                                                                "\n                          "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                964125661
                                                              )
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "body-2"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "labels.applyTemplateAutomatically"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  747297323
                                                ),
                                                model: {
                                                  value:
                                                    _vm.applyTemplateAutomatically,
                                                  callback: function($$v) {
                                                    _vm.applyTemplateAutomatically = $$v
                                                  },
                                                  expression:
                                                    "applyTemplateAutomatically"
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-0",
                                      attrs: {
                                        "align-self": "center",
                                        cols: "auto"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "min-width": "200",
                                            "offset-y": ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        {
                                                          staticClass: "mx-1",
                                                          attrs: {
                                                            icon: "",
                                                            small: ""
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "\n                        fal fa-ellipsis-v\n                      "
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            4004589445
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: _vm.toggleHelpPanel
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          fal fa-info-circle\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-list-item-title", [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.information"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : [
                          _c(
                            "v-row",
                            {
                              staticClass: "schedule-header",
                              attrs: { justify: "space-between" }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    "align-self": "center",
                                    cols: "auto"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "ml-1",
                                      attrs: { align: "center" }
                                    },
                                    [
                                      _c("DepartmentSelector"),
                                      !_vm.readOnly
                                        ? _c("v-switch", {
                                            staticClass: "ml-4",
                                            attrs: {
                                              color: "success",
                                              dense: "",
                                              disabled: _vm.updatingSettings,
                                              "hide-details": "",
                                              inset: "",
                                              loading: _vm.updatingSettings
                                            },
                                            on: {
                                              change:
                                                _vm.updateTemplateAutomation
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function() {
                                                    return [
                                                      _vm.$vuetify.breakpoint
                                                        .width < 930
                                                        ? [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2 grey--text text--darken-3"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "labels.automate"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      "max-width":
                                                                        "300px",
                                                                      bottom: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      attrs: {
                                                                                        "x-small":
                                                                                          ""
                                                                                      }
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                              fa-fal fa-info-circle\n                            "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2993365897
                                                                    )
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "body-2"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "labels.applyTemplateAutomatically"
                                                                              )
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        : [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-2 grey--text text--darken-3"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "labels.applyTemplateAutomatically"
                                                                      )
                                                                    ) +
                                                                    "\n                      "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              false,
                                              182473905
                                            ),
                                            model: {
                                              value:
                                                _vm.applyTemplateAutomatically,
                                              callback: function($$v) {
                                                _vm.applyTemplateAutomatically = $$v
                                              },
                                              expression:
                                                "applyTemplateAutomatically"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("v-spacer"),
                              !_vm.$vuetify.breakpoint.smAndDown
                                ? [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pr-0",
                                        attrs: {
                                          "align-self": "center",
                                          cols: "auto"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              "nudge-top": "10"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var tooltipOn = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                icon: ""
                                                              }
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          Object.assign(
                                                            {},
                                                            tooltipOn,
                                                            {
                                                              click:
                                                                _vm.toggleHelpPanel
                                                            }
                                                          )
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "primary"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        fal fa-info-circle\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              1846916210
                                            )
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "body-2" },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "labels.information"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ]
                  ],
                  2
                ),
                _c(
                  "v-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c(
                      "v-alert",
                      {
                        staticClass: "caption dense font-weight-medium mx-0",
                        attrs: {
                          color: "info",
                          dense: "",
                          outlined: "",
                          text: ""
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "ml-n1 mr-3",
                            attrs: {
                              slot: "prepend",
                              color: "info",
                              size: "12"
                            },
                            slot: "prepend"
                          },
                          [
                            _vm._v(
                              "\n            fas fa-info-circle\n          "
                            )
                          ]
                        ),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("descriptions.scheduleTemplates")) +
                            "\n        "
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  { attrs: { justify: "end", "no-gutters": "" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "v-tabs",
                          {
                            staticClass: "job-types",
                            attrs: {
                              "mobile-breakpoint": "768",
                              "show-arrows": "",
                              "slider-color": "accent",
                              "slider-size": "3",
                              value: _vm.selectedJobTypeTab
                            }
                          },
                          _vm._l(_vm.groupedJobTypes, function(
                            jobTypeGroup,
                            index
                          ) {
                            return _c(
                              "v-tab",
                              {
                                key: index,
                                nativeOn: {
                                  "!click": function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.onJobTypeChange(index)
                                  }
                                }
                              },
                              [
                                _vm._l(jobTypeGroup, function(
                                  jobType,
                                  jobIndex
                                ) {
                                  return [
                                    jobIndex > 0
                                      ? _c(
                                          "span",
                                          {
                                            key: jobType.id + "-plus",
                                            staticClass: "ml-2"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  +\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        key: jobType.id + "-name",
                                        class: jobIndex > 0 ? "ml-2" : ""
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(jobType.name) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                })
                              ],
                              2
                            )
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-spacer")
                  ],
                  1
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "grid-action-bar",
                    attrs: { justify: "center", "justify-md": "end" }
                  },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { staticClass: "px-0", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            class:
                                              _vm.actionStyles.filters.button
                                                .classes,
                                            attrs: {
                                              icon: "",
                                              value: "filters"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.setOpenedPanelName(
                                                  "filters"
                                                )
                                              }
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm.hasFilters &&
                                        _vm.openedPanelName !== "filters"
                                          ? _c(
                                              "v-icon",
                                              {
                                                class:
                                                  _vm.actionStyles.filters.icon
                                                    .classes,
                                                attrs: { size: "16" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  fas fa-filter\n                "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                class:
                                                  _vm.actionStyles.filters.icon
                                                    .classes,
                                                attrs: { size: "16" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  fal fa-filter\n                "
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _c("span", { staticClass: "body-2" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("labels.filter")) +
                                  "\n            "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("StaffSearch", {
                  attrs: {
                    "append-icon": _vm.staffFilter ? "" : "fal fa-search",
                    "target-class": "search-staff py-3 ml-4",
                    clearable: !!_vm.staffFilter,
                    dense: "",
                    disabled: _vm.hasChanges,
                    "hide-details": "",
                    "nudge-right": "100",
                    solo: "",
                    "target-style": _vm.searchStaffStyle
                  },
                  model: {
                    value: _vm.staffFilter,
                    callback: function($$v) {
                      _vm.staffFilter =
                        typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "staffFilter"
                  }
                }),
                _c("div", {
                  ref: "gridParent",
                  staticClass: "grid-parent",
                  style: _vm.gridStyle
                }),
                _c("svg", { style: _vm.gridOverlayStyle }, [
                  _c("polygon", {
                    staticStyle: {
                      "fill-opacity": "0.05",
                      stroke: "transparent",
                      "stroke-width": "0"
                    },
                    attrs: { points: _vm.gridOverlayPath }
                  })
                ])
              ],
              1
            ),
            _c("SidePanel", {
              attrs: { panels: _vm.sidePanels },
              on: {
                transitionend: function($event) {
                  return _vm.redrawGrid(true)
                }
              }
            }),
            _vm.nurseDetails
              ? _c("UserDialog", {
                  attrs: {
                    "show-hint": false,
                    user: Object.assign({}, _vm.nurseDetails)
                  },
                  on: {
                    close: _vm.hideNurseDetails,
                    saved: _vm.updateNurseDetails
                  }
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }