<template>
  <v-card
    class="mb-5"
    :elevation="hover ? 2 : 0"
    outlined
  >
    <v-list
      class="pa-0"
      flat
    >
      <v-list-item-group>
        <v-list-item
          two-line
          @click="$emit('click', request)"
        >
          <v-icon
            class="mr-10"
            :color="style.color"
            size="14"
          >
            {{ style.icon }}
          </v-icon>
          <v-list-item-content>
            <span class="body-2 grey--text text--darken-3">
              {{ eventName }}
            </span>
            <span class="caption grey--text text--darken-2">
              {{ $t('labels.eventRequester', { requester: userDisplayName }) }}
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
import _ from 'lodash';
export default {
  props: {
    hover: {
      default: false,
      type: Boolean
    },
    request: {
      type: Object,
      required: true
    },
    scheduleId: {
      default: 0,
      type: [Number, String]
    }
  },
  computed: {
    eventName () {
      const eventInfo = this.$store.getters['org/getEventTypeById'](this.request.typeId);
      if (eventInfo) {
        return _.capitalize(eventInfo.name);
      } else {
        return this.$t('labels.eventRequest');
      }
    },
    style () {
      let style = {
        icon: 'far fa-calendar-check',
        color: 'warning'
      };
      // TODO: At the moment, request escalation is disabled therefore there should be no visual indication between both states.
      // switch (this.request.state) {
      //   case REQUEST_STATES.PENDING_SCHEDULER_APPROVAL:
      //     style.icon = 'fas fa-exclamation-triangle';
      //     style.color = 'warning';
      //     break;
      //   case REQUEST_STATES.PENDING_DIRECTOR_APPROVAL:
      //     style.icon = 'fas fa-share-square';
      //     style.color = 'nb-gold';
      //     break;
      // }
      return style;
    },
    userDisplayName () {
      let displayName = '';
      if (this.request.user) {
        displayName = this.request.user.fullName;
      } else if (this.scheduleId) {
        displayName = this.$store.getters['scheduling/getUser'](this.scheduleId, this.request.assigneeId).fullName;
      }
      return displayName;
    }
  }
};
</script>
