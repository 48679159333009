exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n#nurseDetails .v-tabs.dense .v-tabs-bar {\n  height: 30px;\n}\n#nurseDetails .v-tabs.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev {\n  display: none;\n}\n#nurseDetails .v-tabs .v-tabs-bar {\n  margin-right: 0px !important;\n}\n#nurseDetails .v-tabs .v-tabs-bar__content > a:not(:last-child) {\n  border-right: none !important;\n}\n#nurseDetails .v-tabs .v-tabs-bar__content > a:first-child {\n  border-top-left-radius: 4px;\n  border-bottom-left-radius: 4px;\n}\n#nurseDetails .v-tabs .v-tabs-bar__content > a:last-child {\n  border-top-right-radius: 4px;\n  border-bottom-right-radius: 4px;\n}\n#nurseDetails .v-tabs .v-tab {\n  background-color: #FFF;\n  border: 1px solid #837EB7;\n  color: #837EB7 !important;\n}\n#nurseDetails .v-tabs .v-tab.v-tab--active {\n  background-color: #837EB7;\n  color: #FFF !important;\n}\n#nurseDetails .v-tabs-items {\n  background: transparent !important;\n  overflow-y: auto;\n  padding: 10px 0;\n}\n#nurseDetails .custom-period-toggle .v-input--selection-controls__input {\n  margin-right: 0px;\n}\n#nurseDetails .schedule-dropdown {\n  border-color: #e0e0e0;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: start;\n}\n#nurseDetails .user-contact-info {\n  min-height: 20px;\n}\n#nurseDetails .user-contact-info .v-list-item__content > * {\n  -webkit-box-flex: unset;\n  -ms-flex: none;\n      flex: none;\n}\n#nurseDetails .v-tabs-bar__content .v-tab {\n  width: 50%;\n}\n#nurseDetails .weeks-picker .v-chip__content {\n  text-shadow: none !important;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};