var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 py-1", attrs: { id: "AddShift" } },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title py-0 px-8" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-plus")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$tc("labels.addShift", 1)) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$can("edit", "shift")
        ? _c("StaffSelection", {
            staticClass: "px-8",
            attrs: {
              date: _vm.date,
              height: _vm.contentHeight,
              "selected-staff": {},
              "show-flout-out": _vm.showFloutOut,
              "show-undo": false
            },
            on: {
              "add-staff": function(data) {
                return _vm.$emit("add-staff", data)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }