var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-1" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-plus")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("labels.createOpenShift")) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "secondary--text body-2", attrs: { cols: "12" } },
            [
              _vm.allowEditingDate
                ? _c(
                    "v-menu",
                    {
                      ref: "datePicker",
                      attrs: {
                        "close-on-content-click": false,
                        "offset-y": "",
                        "nudge-bottom": 0,
                        "max-width": "376px",
                        "min-width": "auto"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "fieldset",
                                  _vm._g(
                                    _vm._b(
                                      { staticClass: "nb-fieldset mt-n3" },
                                      "fieldset",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "legend",
                                      {
                                        staticClass:
                                          "grey--text text--darken-2 caption-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$tc("labels.date", 1)) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "body-2 pb-2 text-truncate grey--text text--darken-3"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm
                                                .moment(_vm.model.date)
                                                .format(
                                                  _vm.dateFormatStringWithDoW
                                                )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1358603389
                      ),
                      model: {
                        value: _vm.showDatePicker,
                        callback: function($$v) {
                          _vm.showDatePicker = $$v
                        },
                        expression: "showDatePicker"
                      }
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          "no-title": "",
                          value: _vm
                            .moment(_vm.model.date)
                            .format("YYYY-MM-DD"),
                          width: "376px"
                        },
                        on: { input: _vm.changeDate }
                      })
                    ],
                    1
                  )
                : [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm
                            .moment(_vm.date)
                            .format(_vm.dateFormatStringWithDoW)
                        ) +
                        "\n      "
                    )
                  ]
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { id: "createOpenShiftForm" } },
        [
          _c("ValidationObserver", {
            ref: "observer",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var invalid = ref.invalid
                  var passes = ref.passes
                  return [
                    _c(
                      "v-container",
                      { staticClass: "pa-0", style: _vm.containerStyle },
                      [
                        _c(
                          "v-card",
                          { staticClass: "pa-4", attrs: { outlined: "" } },
                          [
                            _vm.payrollDate
                              ? _c(
                                  "v-alert",
                                  {
                                    staticClass:
                                      "caption dense font-weight-medium mx-0",
                                    attrs: {
                                      color: "info",
                                      dense: "",
                                      outlined: "",
                                      text: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "ml-n1 mr-3",
                                        attrs: {
                                          slot: "prepend",
                                          color: "info",
                                          size: "12"
                                        },
                                        slot: "prepend"
                                      },
                                      [
                                        _vm._v(
                                          "\n              fas fa-info-circle\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t(
                                            "descriptions.differentPayrollDate",
                                            { date: _vm.payrollDate }
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-row",
                              {
                                class: [
                                  _vm.allowEditingDate
                                    ? "edit-dept-allowed"
                                    : ""
                                ],
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pr-4", attrs: { cols: "7" } },
                                  [
                                    _vm.allowEditingDate
                                      ? _c("v-select", {
                                          ref: "selectDepartment",
                                          attrs: {
                                            dense: "",
                                            "hide-details": "",
                                            "item-text": "name",
                                            "item-value": "id",
                                            items: _vm.departments,
                                            label: _vm.$tc(
                                              "labels.department",
                                              1
                                            ),
                                            outlined: ""
                                          },
                                          model: {
                                            value: _vm.model.departmentId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "departmentId",
                                                $$v
                                              )
                                            },
                                            expression: "model.departmentId"
                                          }
                                        })
                                      : _c(
                                          "fieldset",
                                          { staticClass: "nb-fieldset" },
                                          [
                                            _c(
                                              "legend",
                                              { staticClass: "grey--text" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$tc(
                                                        "labels.department",
                                                        1
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "body-2 pb-2 text-truncate grey--text"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.targetDepartment.name
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "4" } },
                                  [
                                    _c("v-switch", {
                                      staticClass: "sitter-toggle",
                                      attrs: {
                                        color: "success",
                                        dense: "",
                                        inset: "",
                                        "hide-details": ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mr-2 body-2 grey--text text--darken-3",
                                                    attrs: {
                                                      title: _vm.$t(
                                                        "labels.sitter"
                                                      )
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.sitter"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.model.sitter,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "sitter", $$v)
                                        },
                                        expression: "model.sitter"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "1" } },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        ref: "payrollDatePicker",
                                        attrs: {
                                          "close-on-content-click": false,
                                          "offset-y": "",
                                          "max-width": "290px",
                                          "min-width": "290px"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: {
                                                        "max-width": "300px",
                                                        top: ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var tooltipOn =
                                                                ref.on
                                                              var tooltipAttr =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        class: [
                                                                          _vm.openPayrollPicker
                                                                            ? "primary white--text"
                                                                            : "grey lighten-3",
                                                                          "payroll-picker"
                                                                        ],
                                                                        attrs: {
                                                                          icon:
                                                                            "",
                                                                          small:
                                                                            ""
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            _vm.openPayrollPicker = !_vm.openPayrollPicker
                                                                          }
                                                                        }
                                                                      },
                                                                      "v-btn",
                                                                      Object.assign(
                                                                        {},
                                                                        tooltipAttr,
                                                                        attrs
                                                                      ),
                                                                      false
                                                                    ),
                                                                    Object.assign(
                                                                      {},
                                                                      tooltipOn,
                                                                      on
                                                                    )
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "payroll-date-icon"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fal fa-calendar"
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fas fa-dollar-sign"
                                                                          }
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "body-2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "labels.payrollDate"
                                                                )
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.openPayrollPicker,
                                          callback: function($$v) {
                                            _vm.openPayrollPicker = $$v
                                          },
                                          expression: "openPayrollPicker"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-container",
                                          {
                                            staticClass: "primary white--text"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "labels.selectPayrollDate"
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        ),
                                        _c("v-date-picker", {
                                          staticClass: "payroll-picker",
                                          attrs: { "no-title": "" },
                                          on: {
                                            change: function($event) {
                                              _vm.openPayrollPicker = false
                                            }
                                          },
                                          model: {
                                            value: _vm.model.payrollDate,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "payrollDate",
                                                $$v
                                              )
                                            },
                                            expression: "model.payrollDate"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pr-4", attrs: { cols: "9" } },
                                  [
                                    _c("VeeSelect", {
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        items: _vm.jobTypes,
                                        label: _vm.$tc(
                                          "labels.eligibleJobType",
                                          2
                                        ),
                                        "menu-props": {
                                          top: false,
                                          offsetY: true
                                        },
                                        multiple: "",
                                        name: "jobTypes",
                                        outlined: "",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend-item",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    attrs: { ripple: "" },
                                                    on: {
                                                      mousedown: function(
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                      },
                                                      click:
                                                        _vm.toggleAllJobTypes
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-action",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              small: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.jobTypesSelectionIcon
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.selectAll"
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("v-divider", {
                                                  staticClass: "mt-0"
                                                })
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "secondary",
                                                          small: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.model.jobTypes.includes(
                                                                item.id
                                                              )
                                                                ? "far fa-check-square"
                                                                : "far fa-square"
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(item.name) +
                                                          "\n                    "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.model.jobTypes,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "jobTypes", $$v)
                                        },
                                        expression: "model.jobTypes"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c("VeeTextField", {
                                      attrs: {
                                        dense: "",
                                        disabled: _vm.model.sitter,
                                        "hide-details": "",
                                        label: _vm.$t("labels.need"),
                                        name: "opening",
                                        outlined: "",
                                        rules: "required|numeric|min_value:1"
                                      },
                                      model: {
                                        value: _vm.model.opening,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "opening",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression: "model.opening"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.model.sitter
                              ? _c(
                                  "v-row",
                                  {
                                    staticClass: "grey--text text--darken-3 ",
                                    attrs: {
                                      align: "center",
                                      justify: "center",
                                      "no-gutters": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pr-2",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "sitter-details",
                                          attrs: {
                                            clearable: "",
                                            dense: "",
                                            "hide-details": "",
                                            label: _vm.$t("labels.room"),
                                            outlined: ""
                                          },
                                          model: {
                                            value:
                                              _vm.model.settings.sitter.room,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model.settings.sitter,
                                                "room",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "model.settings.sitter.room"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pl-2",
                                        attrs: { cols: "6" }
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "sitter-details",
                                          attrs: {
                                            clearable: "",
                                            dense: "",
                                            "hide-details": "",
                                            label: _vm.$t("labels.reason"),
                                            outlined: ""
                                          },
                                          model: {
                                            value:
                                              _vm.model.settings.sitter.reason,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.model.settings.sitter,
                                                "reason",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "model.settings.sitter.reason"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pr-4", attrs: { cols: "5" } },
                                  [
                                    _c("VeeSelect", {
                                      ref: "selectShiftType",
                                      staticClass: "shift-select",
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        items: _vm.shiftTypes,
                                        label: _vm.$t("labels.shift"),
                                        "menu-props": {
                                          top: false,
                                          offsetY: true
                                        },
                                        outlined: "",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "span",
                                                  { staticClass: "mt-n1" },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "body-2 ml-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.formatTimeRange(
                                                              item
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          },
                                          {
                                            key: "selection",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "span",
                                                  { staticClass: "mt-n1" },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "body-2" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.model.typeId,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "typeId", $$v)
                                        },
                                        expression: "model.typeId"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c("VeeSelect", {
                                      ref: "selectShiftStart",
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        items: _vm.startTimes,
                                        label: _vm.$t("labels.start"),
                                        "menu-props": "auto",
                                        outlined: "",
                                        rules: "required"
                                      },
                                      model: {
                                        value: _vm.model.startTime,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "startTime", $$v)
                                        },
                                        expression: "model.startTime"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "shift-separator",
                                    attrs: { cols: "1" }
                                  },
                                  [_vm._v("\n              -\n            ")]
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "3" } },
                                  [
                                    _c("VeeSelect", {
                                      ref: "selectShiftEnd",
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        items: _vm.endTimes,
                                        label: _vm.$t("labels.end"),
                                        "menu-props": "auto",
                                        outlined: "",
                                        rules: "required"
                                      },
                                      model: {
                                        value: _vm.model.endTime,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "endTime", $$v)
                                        },
                                        expression: "model.endTime"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "pr-4", attrs: { cols: "7" } },
                                  [
                                    _c("VeeSelect", {
                                      ref: "selectBiddingEndtime",
                                      staticClass: "bidding-ends-select",
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        items: _vm.hoursBeforeBiddingEndsList,
                                        label: _vm.$t("labels.biddingEnds"),
                                        "menu-props": {
                                          top: false,
                                          offsetY: true
                                        },
                                        outlined: "",
                                        rules: "required"
                                      },
                                      model: {
                                        value: _vm.hoursBeforeBiddingEnds,
                                        callback: function($$v) {
                                          _vm.hoursBeforeBiddingEnds = $$v
                                        },
                                        expression: "hoursBeforeBiddingEnds"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("v-col", { attrs: { cols: "5" } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "body-2 d-inline-block pt-2"
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("labels.hoursBefore", {
                                              hours: _vm.formatTime(
                                                _vm.model.startTime
                                              )
                                            })
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("FlagSelection", {
                                      ref: "selectShiftFlag",
                                      staticClass: "shift-flags",
                                      attrs: {
                                        "small-chips": "",
                                        dense: "",
                                        filter: _vm.flagsFilter,
                                        "hide-details": "",
                                        "item-text": "shortCode",
                                        "item-value": "id",
                                        items: _vm.shiftFlags,
                                        label: _vm.$tc("labels.flag", 2),
                                        multiple: "",
                                        outlined: "",
                                        "return-object": false
                                      },
                                      model: {
                                        value: _vm.model.flags,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "flags", $$v)
                                        },
                                        expression: "model.flags"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("Comments", {
                                      staticClass: "body-2",
                                      attrs: {
                                        "auto-grow": true,
                                        counter: "1000",
                                        "disclosure-hint": _vm.$t(
                                          "descriptions.disclaimer"
                                        ),
                                        maxlength: "1000",
                                        outlined: "",
                                        placeholder: _vm.$t(
                                          "labels.addCommentsPlaceholder"
                                        ),
                                        rows: "1",
                                        "single-line": "",
                                        "visibility-hint": _vm.$t(
                                          "descriptions.commentVisibilityAll"
                                        )
                                      },
                                      model: {
                                        value: _vm.model.comments,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "comments", $$v)
                                        },
                                        expression: "model.comments"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "mb-0",
                                attrs: { "no-gutters": "" }
                              },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        staticClass: "mt-0 pt-0",
                                        attrs: {
                                          dense: "",
                                          "hide-details": "",
                                          mandatory: ""
                                        },
                                        model: {
                                          value: _vm.model.fcfs,
                                          callback: function($$v) {
                                            _vm.$set(_vm.model, "fcfs", $$v)
                                          },
                                          expression: "model.fcfs"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          staticClass: "mb-2",
                                          attrs: {
                                            color: "secondary",
                                            label: _vm.$t(
                                              "labels.firstComeFirstServed"
                                            ),
                                            value: true
                                          }
                                        }),
                                        _c("v-radio", {
                                          attrs: {
                                            color: "secondary",
                                            label: _vm.$t(
                                              "labels.manuallySelectBidders"
                                            ),
                                            value: false
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          { staticClass: "pa-4 mt-4", attrs: { outlined: "" } },
                          [
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "grey--text caption",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "descriptions.openShiftNotification"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("VeeSelect", {
                                      attrs: {
                                        dense: "",
                                        "hide-details": "",
                                        "item-text": "name",
                                        "item-value": "id",
                                        items: _vm.departments,
                                        label: _vm.$tc("labels.department", 1),
                                        multiple: "",
                                        name: "departments",
                                        outlined: "",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "prepend-item",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    attrs: { ripple: "" },
                                                    on: {
                                                      mousedown: function(
                                                        $event
                                                      ) {
                                                        $event.preventDefault()
                                                      },
                                                      click:
                                                        _vm.toggleAllDepartments
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-action",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color:
                                                                "secondary",
                                                              small: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.departmentSelectionIcon
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "labels.selectAll"
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c("v-divider", {
                                                  staticClass: "mt-2"
                                                })
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "item",
                                            fn: function(ref) {
                                              var item = ref.item
                                              return [
                                                _c(
                                                  "v-list-item-action",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "secondary",
                                                          small: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.model.eligibleDepartments.includes(
                                                                item.id
                                                              )
                                                                ? "far fa-check-square"
                                                                : "far fa-square"
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(item.name) +
                                                          "\n                    "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: _vm.model.eligibleDepartments,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "eligibleDepartments",
                                            $$v
                                          )
                                        },
                                        expression: "model.eligibleDepartments"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { attrs: { "no-gutters": "" } },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("JobStatusSelection", {
                                      attrs: {
                                        multiple: "",
                                        "nudge-top": "400px",
                                        rules: "required",
                                        vid: "jobStatus"
                                      },
                                      model: {
                                        value: _vm.model.eligibleJobStatus,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "eligibleJobStatus",
                                            $$v
                                          )
                                        },
                                        expression: "model.eligibleJobStatus"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.$can("edit", "openShift")
                      ? _c(
                          "v-row",
                          {
                            staticClass: "create-open-shift",
                            attrs: { "no-gutters": "" }
                          },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "px-4 float-right create",
                                    attrs: {
                                      color: "accent",
                                      disabled: _vm.saving || invalid
                                    },
                                    on: {
                                      click: function($event) {
                                        return passes(_vm.create)
                                      }
                                    }
                                  },
                                  [
                                    _vm.saving
                                      ? _c("v-progress-circular", {
                                          staticClass: "px-7",
                                          attrs: {
                                            color: "secondary",
                                            indeterminate: "",
                                            size: "22",
                                            width: "2"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("labels.create")) +
                                              "\n            "
                                          )
                                        ])
                                  ],
                                  1
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "px-4 mr-4 float-right",
                                    attrs: {
                                      color: "primary",
                                      disabled: _vm.saving,
                                      outlined: ""
                                    },
                                    on: { click: _vm.clear }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("labels.clear")) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }