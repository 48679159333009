var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-0" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-calendar")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("labels.scheduleDetails")) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.date
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "secondary--text body-2",
                  attrs: { cols: "12" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.moment(_vm.date).format(_vm.dateFormatString)
                      ) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c("NoContent", {
            attrs: {
              height: 140,
              width: 140,
              message: _vm.date
                ? _vm.$t("descriptions.noContentForDate", {
                    date: _vm.moment(_vm.date).format(_vm.dateFormatString)
                  })
                : _vm.$t("descriptions.noContent")
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }