var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _vm.shifts.length === 0
        ? _c("NoContent", {
            attrs: {
              exciting: "",
              height: 140,
              width: 140,
              message: _vm.$t("descriptions.timeConflictNoErrors")
            }
          })
        : _vm._l(_vm.shifts, function(shift) {
            return _c("ShiftsCard", {
              key: shift.id,
              staticClass: "mb-4",
              attrs: {
                shifts: [shift],
                "show-shift-date": true,
                user: _vm.user
              },
              on: {
                click: function($event) {
                  return _vm.$emit("show-shift", shift)
                }
              }
            })
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }