<template>
  <v-container
    class="census px-0 py-1"
  >
    <v-list-item class="headline page-title py-0 px-8">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-cloud-upload-alt</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.inputCensus') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card
      class="mx-8 mb-5"
      outlined
    >
      <v-container class="ratio py-4 px-8">
        <template v-for="(chunk, idx) in jobTypeChunks">
          <v-row
            :key="`label${idx}`"
            class="pt-1"
          >
            <span class="caption row-label" />
            <template v-for="jobType in chunk">
              <span
                :key="jobType.id"
                class="caption header text-truncate"
                :title="jobType.name"
              >
                {{ jobType.name }}
              </span>
            </template>
          </v-row>
          <v-row
            :key="`onDuty${idx}`"
            class="py-2"
          >
            <span class="caption row-label">
              {{ $t('labels.onDuty') }}
            </span>
            <template v-for="jobType in chunk">
              <span
                :key="`onDuty${jobType.id}-${idx}`"
                :class="['count', getOnDutyClass(jobType)]"
              >
                {{ censusjobTypeCount[jobType.id] }}
              </span>
            </template>
          </v-row>
          <v-row
            :key="`ratio${idx}`"
            class="py-2"
          >
            <span class="caption row-label">
              {{ $t('labels.ratio') }}
            </span>
            <template v-for="jobType in chunk">
              <span
                v-if="!jobType.hideRatio"
                :key="`ratio${jobType.id}-${idx}`"
                class="count"
              >
                {{ getRatio(jobType.id, true) }}
              </span>
            </template>
          </v-row>
          <v-divider
            v-if="idx < jobTypeChunks.length - 1"
            :key="`divider${idx}`"
          />
        </template>
        <v-row v-if="hasHistoricalCount">
          <v-alert
            class="caption dense font-weight-medium mb-0 mt-4"
            color="info"
            dense
            outlined
            text
            width="100%"
          >
            <v-icon
              slot="prepend"
              class="ml-1 mr-3"
              color="info"
              size="12"
            >
              fas fa-info-circle
            </v-icon>
            {{ $t('descriptions.historicalCensus') }}
          </v-alert>
        </v-row>
      </v-container>
      <v-container class="px-5">
        <v-select
          v-model="selectedCensusName"
          dense
          hide-details
          :items="censusList"
          outlined
        />
      </v-container>
      <v-container
        v-if="activeCensus.model.settings.showTotalAcuityByClass && activeCensus.model.settings.acuity"
        class="acuity px-8 pb-0"
      >
        <v-row class="mb-1">
          <span class="caption row-label" />

          <span
            v-for="(acuity, key) in activeCensus.model.settings.acuity"
            :key="key"
            class="caption header text-truncate"
          >
            {{ acuity.label }}
          </span>
        </v-row>
        <v-row class="mb-2">
          <span class="caption row-label">
            {{ $t('labels.acuity') }}
          </span>
          <v-text-field
            v-for="(acuity, key) in activeCensus.model.settings.acuity"
            :key="key"
            v-model.number="activeCensus.model[`acuity${key}`]"
            dense
            :disabled="readOnly"
            hide-details
            outlined
          />
        </v-row>
        <v-row class="mb-3">
          <span class="caption row-label">
            {{ $t('labels.total') }}
          </span>
          <v-text-field
            v-model.number="activeCensus.model.total"
            dense
            :disabled="disabled"
            hide-details
            outlined
            :placeholder="`${activeCensusTotalAcuity}`"
          />
          <v-tooltip
            v-if="activeCensus.model.total && activeCensus.model.total !== activeCensusTotalAcuity"
            right
            max-width="250px"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                class="ml-2 acuity-warning"
                color="error"
                size="10"
                v-bind="attrs"
                v-on="on"
              >
                fas fa-exclamation-triangle
              </v-icon>
            </template>
            <span class="body-2">
              {{ $t('descriptions.wrongAcuityTotal') }}
            </span>
          </v-tooltip>
        </v-row>
      </v-container>
      <v-divider
        v-if="activeCensus.model.settings.showTotalAcuityByClass && activeCensus.model.censusBySpecialty"
      />
      <template v-if="activeCensus.model.censusBySpecialty">
        <v-container
          v-for="(specialty, idx) in activeCensus.model.censusBySpecialty"
          :key="specialty.name"
          class="acuity pa-0"
        >
          <template v-if="specialty.acuityBreakdown">
            <v-row class="px-8 pt-3 pb-2">
              <span class="caption row-label" />
              <span
                v-for="(acuity, key) in activeCensus.model.settings.acuity"
                :key="key"
                class="caption header text-truncate"
              >
                {{ acuity.label }}
              </span>
            </v-row>
            <v-row class="px-8 pt-3 pb-2">
              <span class="caption row-label">{{ specialty.name }}</span>
              <v-text-field
                v-for="(breakdown, key) in specialty.acuityBreakdown"
                :key="key"
                v-model.number="breakdown.value"
                dense
                :disabled="disabled"
                hide-details
                outlined
              />
            </v-row>
          </template>
          <v-row
            v-if="specialty.showAcuitySubtotal"
            class="px-8 pt-3 pb-2"
          >
            <span class="caption row-label">
              {{ specialty.acuityBreakdown ? $t('labels.subtotal') : specialty.name }}
            </span>
            <v-text-field
              v-model.number="specialty.subtotal"
              dense
              :disabled="disabled"
              hide-details
              outlined
              :placeholder="specialty.acuityBreakdown ? `${getCensusSpecialtySubtotal(specialty)}` : ''"
            />
            <v-tooltip
              v-if="specialty.acuityBreakdown && specialty.subtotal && specialty.subtotal !== getCensusSpecialtySubtotal(specialty)"
              right
              max-width="250px"
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="ml-2 acuity-warning"
                  color="error"
                  size="10"
                  v-bind="attrs"
                  v-on="on"
                >
                  fas fa-exclamation-triangle
                </v-icon>
              </template>
              <span class="body-2">
                {{ $t('descriptions.wrongAcuityTotal') }}
              </span>
            </v-tooltip>
          </v-row>
          <v-divider
            v-if="idx !== activeCensus.model.censusBySpecialty.length - 1"
          />
        </v-container>
      </template>
      <v-divider
        v-if="activeCensus.model.censusBySpecialty || activeCensus.model.settings.showTotalAcuityByClass"
        class=""
      />
      <v-container
        class="acuity extra-staff px-8"
      >
        <v-row
          v-for="(extraStaff, index) in activeCensus.model.extraStaff"
          :key="extraStaff.jobTypeId"
          class="mb-2"
        >
          <span
            :key="`${extraStaff.jobTypeId}-label`"
            class="caption row-label text-truncate"
            :title="jobTypeLabelsById[extraStaff.jobTypeId].label"
          >
            {{ jobTypeLabelsById[extraStaff.jobTypeId].label }}
          </span>
          <v-text-field
            :key="`${extraStaff.jobTypeId}-input`"
            v-model.number="extraStaff.count"
            dense
            :disabled="disabled"
            hide-details
            outlined
          />
          <v-btn
            v-if="!disabled"
            :key="`${extraStaff.jobTypeId}-delete`"
            class="ml-0 remove-resource"
            color="error"
            icon
            small
            @click="removeResource(index)"
          >
            <v-icon
              x-small
            >
              fal fa-trash-alt
            </v-icon>
          </v-btn>
          <v-tooltip
            right
          >
            <template #activator="{ on, attrs }">
              <v-icon
                class="ml-2 mt-n1 imblance-resource"
                color="error"
                size="10"
                :style="{ visibility: censusErrorMessage(extraStaff.jobTypeId) ? 'visible' : 'hidden' }"
                v-bind="attrs"
                v-on="on"
              >
                fas fa-exclamation-triangle
              </v-icon>
            </template>
            <span class="body-2">
              {{ censusErrorMessage(extraStaff.jobTypeId) }}
            </span>
          </v-tooltip>
        </v-row>
        <v-row v-if="jobTypeLabelsFiltered.length > 0 && !disabled">
          <v-menu
            bottom
            offset-y
            close-on-click
            min-width="184px"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                class="caption grey--text text--darken-1 add-resource"
                v-bind="attrs"
                v-on="on"
              >
                {{ `+ ${$t('labels.additionalResource')}` }}
              </span>
            </template>
            <v-list>
              <v-list-item
                v-for="(item) in jobTypeLabelsFiltered"
                :key="item.id"
                @click="addResource(item)"
              >
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-container>
    </v-card>
    <v-divider />
    <v-container class="px-8">
      <Comments
        v-model="activeCensus.model.notes"
        :auto-grow="true"
        class="py-2"
        counter="1000"
        :disabled="disabled"
        :disclosure-hint="$t('descriptions.disclaimer')"
        maxlength="1000"
        outlined
        :placeholder="placeholder"
        rows="6"
        single-line
        :visibility-hint="$t('descriptions.commentVisibilitySchedulers')"
      />
    </v-container>
    <v-row
      v-if="!disabled"
      class="px-8"
    >
      <v-spacer />
      <v-col
        class="py-0"
        cols="6"
      >
        <v-btn
          block
          class="save"
          color="accent"
          :disabled="saving || !hasChanges"
          @click="update"
        >
          <v-progress-circular
            v-if="saving"
            color="primary lighten-2"
            indeterminate
            size="22"
            width="2"
          />
          <span v-else>
            {{ $t('labels.save') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { getCensusDefaultModel, getCensusRatio } from '@/utils/scheduling';
import Comments from '@/components/Comments';
import moment from 'moment';
import { showStatus } from '@/plugins/vue-notification';

const DeepDiff = require('deep-diff').diff;

export default {
  components: {
    Comments
  },
  props: {
    census: {
      required: true,
      type: Array
    },
    dailyScheduleTypeId: {
      required: true,
      type: Number
    },
    date: {
      required: true,
      type: String
    },
    departmentId: {
      required: true,
      type: Number
    },
    id: {
      default: 0,
      type: Number
    },
    jobTypeCount: {
      required: true,
      type: Object
    },
    jobTypes: {
      required: true,
      type: Array
    },
    readOnly: {
      default: false,
      type: Boolean
    }
  },
  data () {
    const department = this.$store.getters['org/getActiveDepartment']();
    const settings = this.$store.getters['org/getDailyScheduleTypeById'](this.dailyScheduleTypeId, 'settings');
    const predifinedCensus = _.get(settings, 'census.predefined', []);
    const defaultModel = getCensusDefaultModel(department);
    const censusList = [];
    for (let i = 0, count = predifinedCensus.length; i < count; i++) {
      const census = _.find(this.census, (c) => c.name === predifinedCensus[i].name);
      if (census) {
        const model = _.cloneDeep(census);
        censusList.push({
          text: model.name,
          value: model.name,
          model
        });
      } else {
        defaultModel.description = predifinedCensus[i].description;
        defaultModel.name = predifinedCensus[i].name;
        defaultModel.time = predifinedCensus[i].time;
        defaultModel.dayOffset = predifinedCensus[i].dayOffset;
        censusList.push({
          text: predifinedCensus[i].name,
          value: predifinedCensus[i].name,
          model: _.cloneDeep(defaultModel)
        });
      }
    }

    return {
      censusList,
      dailyScheduleId: this.id,
      originalCensusList: _.cloneDeep(censusList),
      saving: false,
      selectedCensusName: censusList[0].value
    };
  },
  computed: {
    activeCensus () {
      return _.find(this.censusList, (census) => census.model.name === this.selectedCensusName);
    },
    activeCensusTotalAcuity () {
      let total = 0;
      for (let i = 0, count = this.acuityLevels.length; i < count; i++) {
        const acuity = parseInt(this.activeCensus.model[`acuity${this.acuityLevels[i]}`]);
        if (!isNaN(acuity)) {
          total += acuity;
        }
      }
      return total;
    },
    acuityLevels () {
      return _.keys(this.activeCensus.model.settings.acuity);
    },
    censusjobTypeCount () {
      if (this.hasHistoricalCount) {
        return this.activeCensus.model.staffCount.reduce((accumulator, currentValue) => {
          accumulator[currentValue.id] = currentValue.value.length;
          return accumulator;
        }, {});
      } else {
        return _.keys(this.jobTypeCount).reduce((accumulator, currentValue) => {
          accumulator[currentValue] = this.jobTypeCount[currentValue].length;
          return accumulator;
        }, {});
      }
    },
    censusRatio () {
      return getCensusRatio(this.activeCensus.model, this.jobTypes, this.$store.getters['org/getDailyScheduleTypeById'](this.dailyScheduleTypeId));
    },
    disabled () {
      return this.readOnly || !this.$can('edit', 'dailySchedule');
    },
    department () {
      return this.$store.getters['org/getActiveDepartment']();
    },
    hasChanges () {
      const diff = DeepDiff(this.originalCensusList, this.censusList) || [];
      return diff.length > 0;
    },
    hasHistoricalCount () {
      let hasHistoricalCount = false;
      if (this.activeCensus) {
        hasHistoricalCount = moment(`${this.date} ${this.activeCensus.model.time}`).add(this.activeCensus.model.dayOffset, 'd').isBefore(moment());
      }
      return hasHistoricalCount;
    },
    jobTypeChunks () {
      const jobTypes = _.cloneDeep(this.jobTypes);
      const chunks = [];
      const chunkSize = 5;
      jobTypes.push({
        id: 'sitter',
        name: this.$t('labels.sitter'),
        hideRatio: true
      });
      for (let i = 0, count = Math.ceil(jobTypes.length / chunkSize); i < count; i++) {
        chunks.push(jobTypes.splice(0, chunkSize));
      }
      return chunks;
    },
    jobTypeLabels () {
      const jobTypeLabels = this.jobTypes.map((j) => {
        return {
          id: j.id,
          label: j.name
        };
      });
      jobTypeLabels.push({
        id: 'sitter',
        label: this.$t('labels.sitter')
      });
      return jobTypeLabels;
    },
    jobTypeLabelsById () {
      return this.jobTypeLabels.reduce(function (accumulator, currentValue) {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
    },
    jobTypeLabelsFiltered () {
      const selectedJobTypeIds = this.activeCensus.model.extraStaff.map((s) => s.jobTypeId);
      return _.filter(this.jobTypeLabels, (j) => !selectedJobTypeIds.includes(j.id));
    },
    placeholder () {
      let placeholder = `${this.$t('labels.addNotesPlaceholder')} ${this.$t('descriptions.dailyScheduleNotesPlaceholder')}`;
      if (this.readOnly && !this.activeCensus.model.notes) {
        placeholder = '';
      }
      return placeholder;
    }
  },
  methods: {
    addResource (item) {
      this.activeCensus.model.extraStaff.push({
        jobTypeId: item.id,
        count: ''
      });
    },
    censusErrorMessage (jobTypeId) {
      const ratio = this.getRatio(jobTypeId, false) || 0;
      const onDuty = this.censusjobTypeCount[jobTypeId] || 0;
      if (onDuty > ratio) {
        return this.$t('labels.overstaffed', { count: onDuty - ratio });
      } else if (onDuty < ratio) {
        return this.$t('labels.understaffed', { count: ratio - onDuty });
      } else {
        return '';
      }
    },
    dispatch (action, payload) {
      // This function is added mainly for easy of mocking during in unit tests.
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getCensusSpecialtySubtotal (specialty) {
      let total = 0;
      if (specialty.acuityBreakdown) {
        for (let i = 0, count = this.acuityLevels.length; i < count; i++) {
          const acuity = parseInt(specialty.acuityBreakdown[this.acuityLevels[i]].value);
          if (!isNaN(acuity)) {
            total += acuity;
          }
        }
      }
      return total;
    },
    getRatio (jobTypeId, forDisplay) {
      if (forDisplay) {
        const ratio = [this.censusRatio[jobTypeId] || '0'];
        const extraStaff = _.find(this.activeCensus.model.extraStaff, (s) => s.jobTypeId === jobTypeId);
        if (extraStaff && extraStaff.count) {
          ratio.push(extraStaff.count);
        }
        return ratio.join(' + ');
      } else {
        let sum = this.censusRatio[jobTypeId] || 0;
        const extraStaff = _.find(this.activeCensus.model.extraStaff, (s) => s.jobTypeId === jobTypeId);
        if (extraStaff && extraStaff.count) {
          sum += extraStaff.count;
        }
        return sum;
      }
    },
    getOnDutyClass (jobType) {
      const onDutyClass = [];
      const ratio = this.getRatio(jobType.id, false);
      if (((_.has(this.censusjobTypeCount, jobType.id) && _.has(this.censusRatio, jobType.id)) || ratio) && this.censusjobTypeCount[jobType.id] !== ratio) {
        onDutyClass.push('error');
      } else if (this.hasHistoricalCount) {
        onDutyClass.push('historical');
      }

      if (jobType.id === 'sitter') {
        onDutyClass.push('sitter');
      }

      return onDutyClass.join(' ');
    },
    removeResource (item, index) {
      this.activeCensus.model.extraStaff.splice(index, 1);
    },
    update () {
      if (!this.saving) {
        const models = this.censusList.map(census => census.model);
        this.updateCensusShiftCount(models);
        if (!this.dailyScheduleId) {
          const data = {
            date: this.date,
            departmentId: this.departmentId,
            typeId: this.dailyScheduleTypeId
          };
          this.dispatch('scheduling/createDailySchedule', data).then((dailySchedule) => {
            this.dailyScheduleId = dailySchedule.id;
            return this.dispatch('scheduling/saveCensus', { census: models, dailyScheduleId: dailySchedule.id }).then((census) => {
              dailySchedule.census = census;
              this.originalCensusList = _.cloneDeep(this.censusList);
              this.$emit('change', dailySchedule);
              showStatus({
                text: this.$t('descriptions.dailyScheduleCensusSaveSuccess')
              });
            });
          }).catch((error) => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.dailyScheduleCensusSaveFail'),
              type: 'error',
              data
            });
          });
        } else if (this.dailyScheduleId > 0) {
          this.dispatch('scheduling/saveCensus', { census: models, dailyScheduleId: this.dailyScheduleId }).then((census) => {
            this.originalCensusList = _.cloneDeep(this.censusList);
            this.$emit('change', {
              id: this.dailyScheduleId,
              census
            });
            showStatus({
              text: this.$t('descriptions.dailyScheduleCensusSaveSuccess')
            });
          }).catch((error) => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.dailyScheduleCensusSaveFail'),
              type: 'error',
              data
            });
          });
        }
      }
    },
    updateCensusShiftCount (models) {
      if (models.length > 0) {
        const now = moment();
        const jobTypeCount = _.keys(this.jobTypeCount).map((jobId) => {
          return {
            id: jobId,
            value: this.jobTypeCount[jobId]
          };
        });
        for (let i = 0, count = models.length; i < count; i++) {
          const census = models[i];
          let update = false;
          const censusDate = moment(`${this.date} ${census.time}`).add(census.dayOffset, 'd');
          if (i === 0) {
            update = now.isSameOrBefore(censusDate);
          } else {
            const previousCensusDate = moment(`${this.date} ${models[i - 1].time}`).add(models[i - 1].dayOffset, 'd');
            if (i === count - 1) {
              update = now.isAfter(previousCensusDate);
            } else {
              update = now.isAfter(previousCensusDate) && now.isSameOrBefore(censusDate);
            }
          }

          if (update) {
            census.staffCount = _.cloneDeep(jobTypeCount);
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
$height-int: 28;
$input-margin-x-int: 4;
$line-height-int: 26;
$width-int: 48;

$height: #{$height-int}px;
$input-margin-x: #{$input-margin-x-int}px;
$line-height: #{$line-height-int}px;
$width: #{$width-int}px;
.census {
  .header {
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    width: #{$width-int + $input-margin-x-int * 2}px;
  }
  .row-label {
    line-height: $line-height;
    width: $width;
  }
  .acuity {
    fieldset {
      height: $height;
      width: $width;
    }
    .v-input {
      display: inline-block;
      flex: none;
      font-size: 14px;
      height: $height;
      line-height: $line-height;
      margin-left: $input-margin-x;
      margin-right: $input-margin-x;
      text-align: center;
      width: $width;
      .v-input__slot {
        min-height: $height !important;
      }
      .v-text-field__slot {
        height: 24px;
        input {
          text-align: center;
        }
      }
    }
  }
  .acuity-warning {
    margin-top: -6px;
  }
  .add-resource {
    cursor: pointer;
  }
  .ratio {
    background-color: map-get($grey, 'lighten-4');
    .count {
      background-color: $primary-lighten-2;
      border-radius: 4px;
      color: white;
      display: inline-block;
      font-size: 14px;
      height: $height;
      line-height: $line-height;
      margin-left: $input-margin-x;
      margin-right: $input-margin-x;
      text-align: center;
      width: $width;
      &.sitter {
        background-color: $primary-lighten-4;
      }
      &.empty {
        background-color: inherit;
      }
      &.historical {
        background-color: map-get($grey, 'base') !important;
      }
      &.error {
        background-color: $error !important;
      }
    }
  }
  .remove-resource {
    margin-top: -2px;
  }
}
</style>
