var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    ref: "jobStatus",
    attrs: {
      "detect-input": false,
      immediate: "",
      rules: _vm.rules,
      tag: "div",
      vid: _vm.vid
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var invalid = ref.invalid
          return [
            _c(
              "v-menu",
              {
                attrs: {
                  "close-on-content-click": false,
                  "min-width": "450px",
                  "offset-y": "",
                  "nudge-bottom": _vm.nudgeBottom,
                  "nudge-top": _vm.nudgeTop
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var isActive = ref.value
                        return [
                          _c(
                            "fieldset",
                            _vm._g(
                              {
                                staticClass:
                                  "nb-fieldset job-status-selection-fieldset"
                              },
                              on
                            ),
                            [
                              _vm.selectedJobStatusText
                                ? [
                                    _c("legend", [
                                      _vm._v(_vm._s(_vm.$t("labels.jobStatus")))
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "pb-2 pt-1 body-2 grey--text text--darken-3"
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.selectedJobStatusText) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ]
                                : [
                                    _c(
                                      "div",
                                      {
                                        class: [
                                          "py-2 body-2",
                                          invalid
                                            ? "error--text"
                                            : "grey--text text--darken-1"
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.label
                                                ? _vm.label
                                                : _vm.$t("labels.jobStatus")
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ],
                              _c(
                                "v-icon",
                                {
                                  class: [
                                    "dropdown-icon",
                                    _vm.selectedJobStatusText
                                      ? ""
                                      : "no-selection",
                                    invalid ? "error--text" : ""
                                  ],
                                  attrs: { small: "" }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(
                                        isActive
                                          ? "fas fa-caret-up"
                                          : "fas fa-caret-down"
                                      ) +
                                      "\n        "
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "pa-0 job-status-selection",
                    attrs: { elevation: "0", outlined: "" }
                  },
                  [
                    _vm.multiple
                      ? _c(
                          "v-container",
                          { staticClass: "py-0" },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "all-job-status",
                                    attrs: { cols: "3" }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "grey", "x-small": "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleAllJobStatus()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.allJobStatusSelected
                                                ? "far fa-check-square"
                                                : "far fa-square"
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "body-2 ml-2",
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleAllJobStatus()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("labels.selectAll")) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "9" } },
                                  [
                                    _c("v-text-field", {
                                      staticClass:
                                        "job-status-name-filter extra-dense-text-field",
                                      attrs: {
                                        "append-icon": "fal fa-search",
                                        clearable: !!_vm.filter,
                                        dense: "",
                                        "hide-details": "",
                                        placeholder: _vm.$t("labels.search"),
                                        solo: ""
                                      },
                                      model: {
                                        value: _vm.filter,
                                        callback: function($$v) {
                                          _vm.filter =
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                        },
                                        expression: "filter"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "py-0", attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "body-2 font-weight-medium"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("labels.inHouse")) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "py-0", attrs: { cols: "6" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "body-2 font-weight-medium"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$t("labels.registry")) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              _vm._l(
                                [
                                  {
                                    name: "inHouseJobStatus",
                                    items: _vm.inHouseJobStatus
                                  },
                                  {
                                    name: "registryJobStatus",
                                    items: _vm.registryJobStatus
                                  }
                                ],
                                function(jobStatusList) {
                                  return _c(
                                    "v-col",
                                    {
                                      key: jobStatusList.name,
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "list-container",
                                          attrs: {
                                            elevation: "0",
                                            outlined: ""
                                          }
                                        },
                                        [
                                          jobStatusList.items.length === 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "px-4 py-2 body-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "labels.noContent"
                                                        )
                                                      ) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "v-list",
                                                {
                                                  staticClass:
                                                    "py-0 v-select-list",
                                                  attrs: { dense: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-list-item",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.toggleAllJobStatus(
                                                            jobStatusList.name
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm.allJobStatusSelectedInSection(
                                                        jobStatusList.name
                                                      )
                                                        ? [
                                                            _c(
                                                              "v-list-item-action",
                                                              {
                                                                staticClass:
                                                                  "mr-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      "x-small":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        far fa-check-square\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "body-2 secondary--text font-weight-regular"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "labels.selectAll"
                                                                          )
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        : [
                                                            _c(
                                                              "v-list-item-action",
                                                              {
                                                                staticClass:
                                                                  "mr-0"
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "grey",
                                                                      "x-small":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        far fa-square\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "body-2 grey--text text--darken-3 font-weight-regular"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "labels.selectAll"
                                                                          )
                                                                        ) +
                                                                        "\n                      "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                    ],
                                                    2
                                                  ),
                                                  _c("v-divider"),
                                                  _vm._l(
                                                    jobStatusList.items,
                                                    function(js) {
                                                      return _c(
                                                        "v-list-item",
                                                        {
                                                          key: js.id,
                                                          class: [
                                                            "js-" + js.id,
                                                            _vm.innerValue.includes(
                                                              js.id
                                                            )
                                                              ? "v-list-item--active"
                                                              : ""
                                                          ],
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.toggleJobStatus(
                                                                js.id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm.innerValue.includes(
                                                            js.id
                                                          )
                                                            ? [
                                                                _c(
                                                                  "v-list-item-action",
                                                                  {
                                                                    staticClass:
                                                                      "mr-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "secondary",
                                                                          "x-small":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        far fa-check-square\n                      "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          "max-width":
                                                                            "300px",
                                                                          top:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                var attrs =
                                                                                  ref.attrs
                                                                                return [
                                                                                  _c(
                                                                                    "v-list-item-title",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-2 secondary--text font-weight-regular"
                                                                                        },
                                                                                        "v-list-item-title",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                            " +
                                                                                          _vm._s(
                                                                                            js.name
                                                                                          ) +
                                                                                          "\n                          "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "body-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  js.name
                                                                                ) +
                                                                                "\n                        "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            : [
                                                                _c(
                                                                  "v-list-item-action",
                                                                  {
                                                                    staticClass:
                                                                      "mr-0"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "grey",
                                                                          "x-small":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                        far fa-square\n                      "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          "max-width":
                                                                            "300px",
                                                                          top:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                var attrs =
                                                                                  ref.attrs
                                                                                return [
                                                                                  _c(
                                                                                    "v-list-item-title",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {
                                                                                          staticClass:
                                                                                            "body-2 grey--text text--darken-3 font-weight-regular"
                                                                                        },
                                                                                        "v-list-item-title",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                            " +
                                                                                          _vm._s(
                                                                                            js.name
                                                                                          ) +
                                                                                          "\n                          "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "body-2"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  js.name
                                                                                ) +
                                                                                "\n                        "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }