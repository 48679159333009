<template>
  <v-container
    class="px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.scheduleDetails') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-row v-if="date">
      <v-col
        class="secondary--text body-2"
        cols="12"
      >
        {{ moment(date).format(dateFormatString) }}
      </v-col>
    </v-row>
    <v-card outlined>
      <NoContent
        :height="140"
        :width="140"
        :message="date ? $t('descriptions.noContentForDate', { date: moment(date).format(dateFormatString) }) : $t('descriptions.noContent')"
      />
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';
import NoContent from '@/components/NoContent';

export default {
  components: {
    NoContent
  },
  props: {
    date: {
      default: null,
      type: Object
    }
  },
  computed: {
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    }
  },
  methods: {
    moment
  }
};
</script>

<style lang="scss">

</style>
