exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.census .header {\n  margin-left: 0px;\n  margin-right: 0px;\n  text-align: center;\n  width: 56px;\n}\n.census .row-label {\n  line-height: 26px;\n  width: 48px;\n}\n.census .acuity fieldset {\n  height: 28px;\n  width: 48px;\n}\n.census .acuity .v-input {\n  display: inline-block;\n  -webkit-box-flex: 0;\n      -ms-flex: none;\n          flex: none;\n  font-size: 14px;\n  height: 28px;\n  line-height: 26px;\n  margin-left: 4px;\n  margin-right: 4px;\n  text-align: center;\n  width: 48px;\n}\n.census .acuity .v-input .v-input__slot {\n  min-height: 28px !important;\n}\n.census .acuity .v-input .v-text-field__slot {\n  height: 24px;\n}\n.census .acuity .v-input .v-text-field__slot input {\n  text-align: center;\n}\n.census .acuity-warning {\n  margin-top: -6px;\n}\n.census .add-resource {\n  cursor: pointer;\n}\n.census .ratio {\n  background-color: #f5f5f5;\n}\n.census .ratio .count {\n  background-color: #837EB7;\n  border-radius: 4px;\n  color: white;\n  display: inline-block;\n  font-size: 14px;\n  height: 28px;\n  line-height: 26px;\n  margin-left: 4px;\n  margin-right: 4px;\n  text-align: center;\n  width: 48px;\n}\n.census .ratio .count.sitter {\n  background-color: #B9B3EF;\n}\n.census .ratio .count.empty {\n  background-color: inherit;\n}\n.census .ratio .count.historical {\n  background-color: #9e9e9e !important;\n}\n.census .ratio .count.error {\n  background-color: #E74C3C !important;\n}\n.census .remove-resource {\n  margin-top: -2px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};