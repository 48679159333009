<template>
  <v-container
    class="schedule-overview px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-address-card</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.requestsOverview') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <div class="caption font-weight-medium mb-3">
      <template v-if="search">
        {{ $tc('labels.showingResultsCount', pendingRequestsCount, { count: pendingRequestsCount}) }}
      </template>
      <template v-else>
        {{ $t('labels.totalCount', { count: pendingRequestsCount}) }}
      </template>
    </div>
    <v-text-field
      v-model.trim="search"
      :append-icon="search ? '' : 'fal fa-search'"
      class="pb-3"
      :clearable="!!search"
      dense
      hide-details
      :placeholder="`${$t('labels.searchByName')}...`"
      outlined
    />
    <div
      id="requestsOverviewContent"
      :style="style"
    >
      <template v-if="pendingRequestsCount > 0">
        <v-hover
          v-for="(request, requestId) in pendingRequests"
          :key="requestId"
          v-slot:default="{ hover }"
        >
          <RequestCard
            :hover="hover"
            :request="request"
            :schedule-id="scheduleId"
            @click="$emit('show-request', request)"
          />
        </v-hover>
      </template>
      <template v-else>
        <v-card
          outlined
        >
          <NoContent
            :height="140"
            :exciting="true"
            :width="140"
            :message="$t('descriptions.noRequest')"
          />
        </v-card>
      </template>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import NoContent from '@/components/NoContent';
import RequestCard from '@/views/scheduling/cards/RequestCard';

export default {
  components: {
    NoContent,
    RequestCard
  },
  props: {
    jobTypeIds: {
      type: Array,
      default: function () {
        return [];
      }
    },
    scheduleId: {
      type: [Number, String],
      required: true
    },
    shiftType: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      search: '',
      height: 500
    };
  },
  computed: {
    pendingRequests () {
      let requests = {};
      if (this.jobTypeIds && this.jobTypeIds.length > 0) {
        for (let id of this.jobTypeIds) {
          _.assign(
            requests,
            this.$store.getters['scheduling/getRequests'](this.scheduleId, id, this.shiftType)
          );
        }
      } else {
        requests = this.$store.getters['scheduling/getRequests'](this.scheduleId, null, this.shiftType);
      }

      if (!this.search) {
        return requests;
      }
      const filteredRequests = {};
      const searchLowerCase = this.search.toLowerCase();
      const employees = this.$store.state.org.employees;
      for (let id in requests) {
        let user = null;
        switch (requests[id].type) {
          case 'event':
            user = employees[requests[id].assigneeId];
            break;
          case 'swap':
            user = employees[requests[id].sourceUserId];
            break;
        }
        if (user && user.fullName.toLowerCase().indexOf(searchLowerCase) >= 0) {
          filteredRequests[id] = requests[id];
        }
      }
      return filteredRequests;
    },
    pendingRequestsCount () {
      return _.keys(this.pendingRequests).length;
    },
    style () {
      return {
        'height': `${this.height}px`
      };
    }
  },
  mounted: function () {
    this.updateHeight();
    window.addEventListener('resize', _.debounce(this.updateHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateHeight);
  },
  methods: {
    moment,

    updateHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const scheduleOverviewContent = document.getElementById('requestsOverviewContent');
      if (el && scheduleOverviewContent) {
        this.height = el.clientHeight - scheduleOverviewContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>

<style lang="scss">
#requestsOverviewContent {
  overflow-y: auto;
}
</style>
