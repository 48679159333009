import _ from 'lodash';
import moment from 'moment';
import DailySchedulePDF from '@/views/scheduling/pdf_templates/daily_schedule/DailySchedulePDF';
import { formatText, getCensusDefaultModel, getLatestAcuity, isWorkingShiftForValidation, isOnCallWorkingShiftForValidation } from '@/utils/scheduling';

class DailySchedulePDFGarfield extends DailySchedulePDF {
  static get defaultOptions () {
    return {
      loadLastDates: false,
      loadExtraShifts: false
    };
  }

  static get name () {
    return 'DailySchedulePDFGarfield';
  }

  constructor (dailySchedule, date, department, type, store, t, tc, adaptor, context) {
    super(dailySchedule, date, department, type, store, t, tc, adaptor, context);

    this.definition.defaultStyle.fontSize = 10;
    this.definition.defaultStyle.lineHeight = 1.0;
    this.definition.pageMargins = [15, 30];
    this.definition.styles.subtext = {
      color: '#757575',
      fontSize: 8
    };
    this.definition.styles.offDuty = {
      color: '#757575'
    };
    this.definition.styles.offDutyStrikeThrough = {
      decoration: 'lineThrough'
    };
    this.definition.styles.headerMargin.margin = [15, 10];

    if (process.env.NODE_ENV === 'production') {
      /**
       * {
       *  69297: 'Sitter',
       *  69298: 'Command Center',
       * }
       */
      this.jobTypeOrder = [
        [1, 6, 2, 5, 4, 8, 69287, 69288], // Admin, Director, CHG, RN, LVN, Supervisor, ER RN, CSU RN
        [3, 7, 9, 10, 69304, 69299, 69276, 69289, 69290], // CNA, Nursing Operator, Transporter, Unit Scheduler, ER CNA, Nursing Scheduler, ERT, CCT, MT
        [11, 69277, 69305] // Unit secretary, OBTech, ER Unit Sec
      ];
    } else if (process.env.NODE_ENV === 'staging') {
      this.jobTypeOrder = [
        [1, 6, 2, 4, 8, 5], // Admin, Director, CHG, LVN, Supervisor, RN
        [3, 7, 9, 10], // CNA, Operator, Transporter, Unit scheduler
        [11] // Unit secretary
      ];
    } else {
      this.jobTypeOrder = [
        [1, 6, 2, 4, 8, 5], // Admin, Director, CHG, LVN, Supervisor, RN
        [3, 7, 9, 10], // CNA, Operator, Transporter, Unit scheduler
        [11] // Unit secretary
      ];
    }
    this.jobTypeOrderMapping = {};
    for (let i = 0; i < this.jobTypeOrder.length; i++) {
      for (let jobTypeId of this.jobTypeOrder[i]) {
        this.jobTypeOrderMapping[jobTypeId] = i;
      }
    }
    this.extraJobTypes = [];

    const staffNeeded = _.get(this.department, 'settings.staffNeeded', []);
    const dailyScheduleShiftTypes = _.get(this.type, 'shiftTypes', []);
    this.grid = [];
    const columnCount = this.jobTypeOrder.length;
    let rowNumbers = _.fill(Array(columnCount), 0);
    for (let group of staffNeeded) {
      for (let jobTypeId of group.jobTypes) {
        if (_.has(this.jobTypeOrderMapping, jobTypeId)) {
          const column = this.jobTypeOrderMapping[jobTypeId];
          for (let shiftTypeId in group.shiftTypes) {
            const row = rowNumbers[column];
            if (dailyScheduleShiftTypes.includes(parseInt(shiftTypeId))) {
              if (!this.grid[row]) {
                this.grid[row] = _.fill(Array(columnCount), null);
              }
              this.grid[row][column] = {
                jobTypeId,
                shiftTypeId,
                column
              };
              rowNumbers[column]++;
            }
          }
        } else {
          const shiftTypes = _.filter(_.keys(group.shiftTypes), (id) => dailyScheduleShiftTypes.includes(parseInt(id)));
          if (shiftTypes.length > 0) {
            this.extraJobTypes.push({
              jobTypeId,
              shiftTypes
            });
          }
        }
      }
    }
    rowNumbers = _.fill(Array(columnCount), this.grid.length);
    for (let i = 0, count = this.extraJobTypes.length; i < count; i++) {
      const column = i % columnCount;
      for (let shiftTypeId of this.extraJobTypes[i].shiftTypes) {
        const row = rowNumbers[column];
        if (!this.grid[row]) {
          this.grid[row] = _.fill(Array(columnCount), null);
        }
        this.grid[row][column] = {
          jobTypeId: this.extraJobTypes[i].jobTypeId,
          shiftTypeId,
          column
        };
        rowNumbers[column]++;
      }
    }
  }

  /**
   * Gets acuity totals
   * @param {object} options Display options
   * @returns pdfmake document definition object
   */
  _getAcuity (options) {
    if (this.store.getters['account/isStaff']()) {
      return [];
    }
    if (this.dailySchedule && this.dailySchedule.census.length > 0) {
      return super._getAcuity(options);
    }
    const content = [];
    const template = _.get(this.department, 'settings.acuity', {});
    if (_.isEmpty(template)) {
      return [];
    }

    const predifinedCensus = _.get(this.type.settings, 'census.predefined', []);
    const defaultModel = getCensusDefaultModel(this.department);
    const censusList = [];
    for (let i = 0, count = predifinedCensus.length; i < count; i++) {
      defaultModel.description = predifinedCensus[i].description;
      defaultModel.name = predifinedCensus[i].name;
      defaultModel.time = predifinedCensus[i].time;
      defaultModel.dayOffset = predifinedCensus[i].dayOffset;
      censusList.push(_.cloneDeep(defaultModel));
    }
    const censusBySpecialty = _.get(defaultModel, 'censusBySpecialty', []) || [];
    const censusBySpecialtyWithSubtotal = _.filter(censusBySpecialty, (c) => !_.isEmpty(c.acuityBreakdown));
    if (options.totals) {
      const acuity = getLatestAcuity(censusList);
      const totals = [];
      const header = [];
      const widths = [];
      const body = [];
      if (censusBySpecialtyWithSubtotal.length > 0) {
        header.push({ text: '', border: [false, false, true, true] });
        widths.push(50);
        for (let level in acuity) {
          header.push({ text: acuity[level].label, style: 'tableHeader', alignment: 'center' });
          widths.push(50);
        }
        for (let specialty of censusBySpecialtyWithSubtotal) {
          const row = _.fill(Array(header.length), ' ');
          row[0] = specialty.name;
          body.push(row);
        }
      } else {
        if (_.get(defaultModel, 'settings.showTotalAcuityByClass', false)) {
          for (let level in acuity) {
            header.push({ text: acuity[level].label, style: 'tableHeader', alignment: 'center' });
            widths.push(50);
          }
        }
      }

      if (header.length > 0) {
        if (_.get(defaultModel, 'settings.showTotalAcuityByClass', false)) {
          const row = _.fill(Array(header.length), ' ');
          if (censusBySpecialtyWithSubtotal.length > 0) {
            row[0] = { text: ' ', border: [false, true, true, false] };
          }
          body.push(row);
        }
        totals.push({
          table: {
            widths,
            dontBreakRows: true,
            headerRows: 1,
            keepWithHeaderRows: false,
            body: [
              header,
              ...body
            ]
          },
          width: 'auto',
          alignment: 'center'
        });
        content.push({ alignment: 'center', text: 'Acuity', style: 'sectionLabel' });
        content.push({
          columns: [
            { width: '*', text: '' },
            ...totals,
            { width: '*', text: '' }
          ],
          columnGap: 5
        });
      }
    }

    if (options.details) {
      content.push(this._getSpacer({}));
      const widths = [50, 75, 50];
      const header = [
        {
          text: 'Time',
          style: 'tableHeader',
          alignment: 'center'
        },
        {
          text: 'Census',
          style: 'tableHeader',
          alignment: 'center'
        },
        {
          text: '',
          style: 'tableHeader',
          alignment: 'center'
        }
      ];

      if ([10684].includes(this.department.id)) {
        // ER: Do not include breakdown
      } else if ([10683].includes(this.department.id)) {
        // Critical Care
        const census = [];
        const rowSpan = 3;
        for (let i = 0, count = censusList.length; i < count; i++) {
          const censusData = censusList[i];
          const rowsData = [];
          for (let rowIdx = 0, rowCount = rowSpan; rowIdx < rowCount; rowIdx++) {
            rowsData.push([]);
          }
          for (let rowIdx = 0, rowCount = rowSpan; rowIdx < rowCount; rowIdx++) {
            switch (rowIdx) {
              case 0:
                rowsData[rowIdx].push({
                  text: censusData.name,
                  rowSpan
                });
                rowsData[rowIdx].push({
                  text: 'ICU=',
                  alignment: 'left',
                  border: [true, true, true, false]
                });
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push({
                  text: 'CCT=',
                  alignment: 'left'
                });
                rowsData[rowIdx].push({
                  rowSpan,
                  text: ' '
                });
                break;
              case 1:
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push({
                  text: 'CCU=',
                  alignment: 'left',
                  border: [true, true, true, false]
                });
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push({
                  text: 'CM=',
                  alignment: 'left'
                });
                rowsData[rowIdx].push('');
                break;
              case 2:
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push({
                  text: 'CSU=',
                  alignment: 'left',
                  border: [true, true, true, true]
                });
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push('');
                rowsData[rowIdx].push('');
                break;
            }
          }
          for (let rowIdx = 0, rowCount = rowSpan; rowIdx < rowCount; rowIdx++) {
            census.push(rowsData[rowIdx]);
          }
        }
        content.push({
          table: {
            widths: [50, 75, 50, 50, 50, 50, '*'],
            dontBreakRows: true,
            headerRows: 1,
            keepWithHeaderRows: false,
            body: [
              [
                {
                  text: 'Time',
                  style: 'tableHeader',
                  alignment: 'center'
                },
                {
                  text: 'Census',
                  style: 'tableHeader',
                  alignment: 'center'
                },
                {
                  text: 'Ratio',
                  style: 'tableHeader',
                  alignment: 'center'
                },
                {
                  text: 'Actual',
                  style: 'tableHeader',
                  alignment: 'center'
                },
                {
                  text: 'Open Beds',
                  style: 'tableHeader',
                  alignment: 'center'
                },
                {
                  text: 'Tech',
                  style: 'tableHeader',
                  alignment: 'center'
                },
                {
                  text: 'Comment',
                  style: 'tableHeader',
                  alignment: 'center'
                }
              ],
              ...census
            ]
          },
          width: 'auto',
          alignment: 'center'
        });
      } else {
        const jobTypes = [];
        const rawJobTypes = _.cloneDeep(this.store.getters['org/getJobTypes'](this.department.id));
        let hasRN = false;
        for (let i = 0, count = rawJobTypes.length; i < count; i++) {
          if (rawJobTypes[i].partakeInScheduling) {
            jobTypes.push(rawJobTypes[i]);
            header.push({
              text: rawJobTypes[i].name,
              style: 'tableHeader',
              alignment: 'center'
            });
            // Add acuity staff column after the RN column
            if (rawJobTypes[i].associatedJobTypes.includes(5)) {
              hasRN = true;
              header.push({
                text: 'Acuity Staff',
                style: 'tableHeader',
                alignment: 'center'
              });
            }
          }
        }
        const length = hasRN ? jobTypes.length + 1 : jobTypes.length;
        for (let i = 0, count = length; i < count; i++) {
          widths.push(Math.round(200 / length));
        }
        header.push({
          text: 'Comments',
          style: 'tableHeader',
          alignment: 'center'
        });
        widths.push('*');
        const census = [];
        const rowSpan = Math.max(2, censusBySpecialty.length);
        for (let i = 0, count = censusList.length; i < count; i++) {
          const censusData = censusList[i];
          const rowsData = [];
          for (let rowIdx = 0, rowCount = rowSpan; rowIdx < rowCount; rowIdx++) {
            rowsData.push([]);
          }
          for (let rowIdx = 0, rowCount = rowSpan; rowIdx < rowCount; rowIdx++) {
            if (rowIdx === 0) {
              rowsData[rowIdx].push({
                text: censusData.name,
                rowSpan
              });
              rowsData[rowIdx].push({
                text: censusBySpecialty[rowIdx] ? `${censusBySpecialty[rowIdx].name}=` : '',
                alignment: 'left',
                border: [true, true, true, false]
              });
              rowsData[rowIdx].push({ text: 'Actual', alignment: 'left' });
            } else {
              rowsData[rowIdx].push('');
              rowsData[rowIdx].push({
                text: censusBySpecialty[rowIdx] ? `${censusBySpecialty[rowIdx].name}=` : '',
                alignment: 'left',
                border: rowIdx === rowCount - 1 ? [true, false, true, true] : [true, false, true, false]
              });
              if (rowIdx === rowCount - 1) {
                rowsData[rowIdx].push({ text: 'Ratio', alignment: 'left' });
              } else {
                rowsData[rowIdx].push('');
              }
            }
            for (let i = 0, count = jobTypes.length; i < count; i++) {
              rowsData[rowIdx].push('');
            }
            if (hasRN) {
              rowsData[rowIdx].push('');
            }
            if (rowIdx === 0) {
              rowsData[rowIdx].push({
                rowSpan,
                text: ' '
              });
            } else {
              rowsData[rowIdx].push('');
            }
          }
          for (let rowIdx = 0, rowCount = rowSpan; rowIdx < rowCount; rowIdx++) {
            census.push(rowsData[rowIdx]);
          }
        }
        content.push({
          table: {
            widths,
            dontBreakRows: true,
            headerRows: 1,
            keepWithHeaderRows: false,
            body: [
              header,
              ...census
            ]
          },
          width: 'auto',
          alignment: 'center'
        });
      }
    }
    return content;
  }

  _getContent (contentDefinition) {
    switch (contentDefinition.type) {
      case 'acuity':
        return this._getAcuity(contentDefinition);
      case 'columns':
        return this._getColumns(contentDefinition);
      case 'divider':
        return this._getDivider(contentDefinition);
      case 'notes':
        return this._getNotes(contentDefinition);
      case 'text':
        return this._getText(contentDefinition);
      case 'spacer':
        return this._getSpacer(contentDefinition);
      case 'stack':
        return this._getStack(contentDefinition.content);
      case 'shifts':
        return this._getShifts(contentDefinition);
      case 'employees':
        return this._getEmployees(contentDefinition);
      default:
        return contentDefinition;
    }
  }

  /**
   * Gets daily schedule notes.
   * @param {object} options Notes options
   * @returns pdfmake document definition object
   */
  _getNotes (options) {
    if (this.dailySchedule && this.dailySchedule.memos && !this.store.getters['account/isStaff']()) {
      const memo = _.get(this.dailySchedule, ['memos', 0], null);
      if (memo && memo.access === 'public' && memo.notes) {
        return [
          { alignment: 'center', text: this.t('labels.scheduleNotes'), style: 'sectionLabel' },
          { alignment: 'left', text: memo.notes }
        ];
      }
    }
    return [];
  }

  /**
   * Gets shifts in the format specified by "format" in options. Defaults to stack.
   * @param {object} options Shifts display and query options
   * @returns pdfmake document definition object
   */
  _getShifts (options) {
    const t = this.t;
    const content = [];
    const {
      label = '',
      text = ''
    } = options;
    const shifts = this.adaptor.getShifts(options);
    const flags = this.store.state.org.flags.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});
    const shiftTypes = this.store.state.org.shiftTypes.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});
    const hasNonWorkingFlag = (shift) => {
      return (!_.isEmpty(shift.flags) && shift.flags.some(flagId => flags[flagId] && !flags[flagId].working));
    };

    const isWorkingShift = (shift) => {
      return isWorkingShiftForValidation(shift, flags) || isOnCallWorkingShiftForValidation(shift, flags);
    };

    const getInfo = (text, info) => {
      const getList = (shift) => {
        const list = [];
        if (shift.assignee.departmentId !== this.department.id) {
          const assigneeDepartment = _.find(this.store.state.org.departments, (d) => d.id === shift.assignee.departmentId);
          list.push(this._getText({
            content: `${t('labels.floatInFrom', { dept: assigneeDepartment.fullName })}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.overtime) {
          list.push(this._getText({
            content: `${t('labels.overtime')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.canceled) {
          list.push(this._getText({
            content: `${t('labels.canceled')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.swapped) {
          list.push(this._getText({
            content: `${t('labels.swapped')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.giveaway) {
          list.push(this._getText({
            content: `${t('labels.givenaway')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.onCall) {
          list.push(this._getText({
            content: `${t('labels.onCall')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.flags && shift.flags.length > 0) {
          const flagNames = [];
          for (let i = 0, count = shift.flags.length; i < count; i++) {
            if (flags[shift.flags[i]] && this.canShowFlag(shift.assigneeId, shift.flags[i])) {
              flagNames.push(flags[shift.flags[i]].name);
            }
          }
          list.push(this._getText({
            content: `${flagNames.join(', ')}`,
            style: 'shiftDetails'
          }));
        }
        if (shift.sitter) {
          const sitter = [`${t('labels.sitter')}`];
          const sitterInfo = [];
          const room = _.get(shift, 'settings.sitter.room', null);
          const reason = _.get(shift, 'settings.sitter.reason', null);
          if (reason) {
            sitterInfo.push(reason);
          }
          if (room) {
            sitterInfo.push(`${t('labels.room')}: ${room}`);
          }
          if (sitterInfo.length > 0) {
            sitter.push(sitterInfo.join(', '));
          }
          list.push(this._getText({
            content: sitter.join(' - '),
            style: 'shiftDetails'
          }));
        }
        if (shift.comments && (!this.isStaff || shift.assigneeId === this.store.state.account.userId)) {
          list.push(this._getText({
            content: `${shift.comments}`,
            style: 'shiftDetails'
          }));
        }
        return list;
      };
      const shift = info.activities[0];
      const content = [
        this._getText({
          content: formatText(text, shift, true),
          style: info.activities.length === 1 && (shift.canceled || shift.swapped || shift.giveaway || hasNonWorkingFlag(shift)) ? ['offDuty'] : []
        })
      ];
      const shiftList = {
        type: 'none',
        ul: []
      };
      for (let activityIndex = 0, activityCount = info.activities.length; activityIndex < activityCount; activityIndex++) {
        const shift = info.activities[activityIndex];
        const shiftType = shiftTypes[shift.typeId];
        const startTime = shift.startTime ? shift.startTime : shiftType.startTime;
        const endTime = shift.endTime ? shift.endTime : shiftType.endTime;
        const shiftInfo = [];
        if (startTime !== shiftType.startTime || endTime !== shiftType.endTime || activityCount > 1) {
          let time = `${_.split(startTime, ':', 2).join(':')} - ${_.split(endTime, ':', 2).join(':')}`;
          shiftInfo.push(this._getText({
            content: time,
            style: shift.canceled || shift.swapped || shift.giveaway || hasNonWorkingFlag(shift) ? ['offDuty'] : ['shiftDetails']
          }));
        }
        const list = getList(shift);
        shiftInfo.push({
          color: '#757575',
          type: 'square',
          ul: list,
          style: 'shiftDetailsList'
        });
        shiftList.ul.push(shiftInfo);
      }
      content.push(shiftList);
      return content;
    };

    let header;
    if (label) {
      header = {
        layout: 'groupHeader',
        table: {
          body: [
            [
              {
                bold: true,
                border: [false, false, false, false],
                text: `  ${label}  `,
                style: ['']
              }
            ]
          ]
        }
      };
      content.push(header);
    }
    if (shifts.length > 0) {
      const leftColumn = {
        width: '100%',
        stack: [],
        margin: [0, 0, 0, 20]
      };
      for (let i = 0, count = shifts.length; i < count; i++) {
        const activities = [];
        for (let activityIndex = 0, activityCount = shifts[i].activities.length; activityIndex < activityCount; activityIndex++) {
          if (!shifts[i].activities[activityIndex].sitter && isWorkingShift(shifts[i].activities[activityIndex])) {
            activities.push(shifts[i].activities[activityIndex]);
          }
        }
        if (activities.length > 0) {
          shifts[i].activities = activities;
          leftColumn.stack.push(...getInfo(text, shifts[i]));
        }
      }
      const columns = {
        columns: [
          leftColumn
        ]
      };
      content.push(columns);
    } else {
      return [];
    }
    return content;
  }

  generate () {
    const content = [];
    for (let row of this.grid) {
      const columns = this.jobTypeOrder.map(() => {
        return {
          width: `${100 / this.jobTypeOrder.length}%`,
          content: []
        };
      });
      for (let info of row) {
        if (info) {
          const filters = [
            [{ field: 'assignee.jobTypeId', op: '=', value: parseInt(info.jobTypeId) }, { field: 'type.id', op: '=', value: parseInt(info.shiftTypeId) }]
          ];
          let filterForCharge = false;
          if (this.jobTypesById[info.jobTypeId] && _.get(this.jobTypesById[info.jobTypeId], 'settings.isChargeNurse', false)) {
            filterForCharge = true;
          }
          if (filterForCharge) {
            filters.push([
              { field: 'type.id', op: '=', value: parseInt(info.shiftTypeId) },
              { field: 'assignee.charge', op: '=', value: true }
            ]);
            filters.push([
              { field: 'assignee.jobTypeId', op: '=', value: parseInt(info.jobTypeId) }
            ]);
          }
          const shiftName = moment('2020-01-01 ' + _.get(this.shiftTypesById, [parseInt(info.shiftTypeId), 'startTime'], '')).format('ha');
          let sectionTitle = `${_.get(this.jobTypesById, [parseInt(info.jobTypeId), 'name'], '')} ${shiftName.substring(0, shiftName.length - 1)}`;
          if (_.get(this.shiftTypesById, [parseInt(info.shiftTypeId), 'onCall'], false)) {
            sectionTitle += ` (${this.t('labels.onCall')})`;
          }
          let nameLabel = '{assignee.jobTypeName} {assignee.lastName}, {assignee.firstName} {assignee.alias}';
          columns[info.column].content.push({
            type: 'shifts',
            format: 'stack',
            label: sectionTitle,
            text: nameLabel,
            filters,
            groupBy: [],
            orderBy: [
              {
                field: 'assignee.lastName',
                direction: 'asc'
              },
              {
                field: 'assignee.firstName',
                direction: 'asc'
              }
            ]
          });
        }
      }
      content.push({
        type: 'stack',
        content: [{
          type: 'columns',
          content: columns
        }]
      });
    }

    const floatTable = {
      table: {
        widths: ['*'],
        headerRows: 1,
        body: [
          [{ text: 'Float In / Registry', style: 'tableHeader', alignment: 'center', color: 'red', borderColor: ['red', 'red', 'red', 'black'] }],
          [{ text: ' ', borderColor: ['red', 'gray', 'red', 'gray'] }],
          [{ text: ' ', borderColor: ['red', 'gray', 'red', 'gray'] }],
          [{ text: ' ', borderColor: ['red', 'gray', 'red', 'gray'] }],
          [{ text: ' ', borderColor: ['red', 'gray', 'red', 'red'] }]
        ]
      }
    };
    content.push({
      type: 'columns',
      content: [
        { content: [{ ..._.cloneDeep(floatTable), margin: [0, 0, 5, 0] }], width: `${100 / this.jobTypeOrder.length}%` },
        { content: [{ ..._.cloneDeep(floatTable) }], width: `${100 / this.jobTypeOrder.length}%` },
        { content: [{ ..._.cloneDeep(floatTable), margin: [5, 0, 0, 0] }], width: `${100 / this.jobTypeOrder.length}%` }
      ],
      margin: [0, 10]
    });
    content.push({
      type: 'stack',
      content: [
        {
          type: 'acuity',
          totals: true,
          details: true
        }
      ]
    });
    const shifts = this.adaptor.getShifts({
      filters: [
        [{ field: 'sitter', op: '=', value: true }]
      ],
      groupBy: [
        {
          label: '{settings.sitter.room}'
        }
      ],
      orderBy: [
        {
          field: 'settings.sitter.room',
          direction: 'asc'
        }
      ]
    });
    const processedShifts = {};
    const flags = this.store.state.org.flags.reduce((data, value) => {
      data[value.id] = value;
      return data;
    }, {});
    const isWorkingShift = (shift) => {
      return isWorkingShiftForValidation(shift, flags) || isOnCallWorkingShiftForValidation(shift, flags);
    };
    for (let room in shifts) {
      processedShifts[room] = [];
      for (let info of shifts[room]) {
        for (let shift of info.activities) {
          if (isWorkingShift(shift)) {
            processedShifts[room].push({
              assignee: info.assignee,
              shift
            });
          }
        }
      }
    }
    const sitterRows = [];
    for (let room in processedShifts) {
      const rows = [];
      for (let i = 0, count = processedShifts[room].length; i < count; i++) {
        const row = [];
        if (i === 0) {
          row.push({
            rowSpan: processedShifts[room].length,
            text: room
          });
        } else {
          row.push('');
        }
        const { shift, assignee } = processedShifts[room][i];
        row.push(_.get(shift, 'settings.sitter.reason'));
        row.push(assignee.fullName);

        const shiftType = this.shiftTypesById[shift.typeId];
        const startTime = shift.startTime ? shift.startTime : shiftType.startTime;
        const endTime = shift.endTime ? shift.endTime : shiftType.endTime;
        const shiftInfo = [
          moment('2020-01-01 ' + _.get(shiftType, 'startTime', '')).format('ha')
        ];
        if (startTime !== shiftType.startTime || endTime !== shiftType.endTime) {
          shiftInfo.push(`(${_.split(startTime, ':', 2).join(':')} - ${_.split(endTime, ':', 2).join(':')})`);
        }
        row.push({ text: shiftInfo.join(' '), alignment: 'center' });
        row.push(shift.comments);
        rows.push(row);
      }
      sitterRows.push(...rows);
    }
    const sitterTable = {
      table: {
        widths: [60, '*', '*', '*', '*'],
        headerRows: 1,
        body: [
          [
            { text: 'Sitter Room', style: 'tableHeader', alignment: 'center' },
            { text: 'Reason', style: 'tableHeader', alignment: 'center' },
            { text: 'Sitter Name', style: 'tableHeader', alignment: 'center' },
            { text: 'Shift', style: 'tableHeader', alignment: 'center' },
            { text: 'Comment', style: 'tableHeader', alignment: 'center' }
          ],
          ...sitterRows,
          [' ', ' ', ' ', ' ', ' '],
          [' ', ' ', ' ', ' ', ' '],
          [' ', ' ', ' ', ' ', ' ']
        ]
      }
    };
    content.push({
      type: 'columns',
      content: [
        { content: [sitterTable], width: '100%' }
      ],
      margin: [0, 20]
    });
    content.push({
      type: 'notes'
    });
    this.definition.content.push(...this._getStack(content));
  }
}

export default DailySchedulePDFGarfield;
