var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "schedule-daily-summary px-8 py-0" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-calendar-day")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("labels.dailySummary")) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "secondary--text body-2", attrs: { cols: "12" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.moment(_vm.date).format(_vm.dateFormatStringWithDoW)
                  ) +
                  "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          class: ["dense", _vm.showSummary ? "" : "single-tab"],
          attrs: {
            id: "dailySummaryContent",
            centered: "",
            grow: "",
            "hide-slider": ""
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _vm.showSummary
            ? _c(
                "v-tab",
                { staticClass: "text-capitalize", attrs: { href: "#summary" } },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("labels.summary")) + "\n    "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "v-tab",
            {
              staticClass: "text-capitalize",
              attrs: { href: "#dailySchedule" }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("labels.shiftsAndEvents")) + "\n    "
              )
            ]
          ),
          _vm.showSummary
            ? _c(
                "v-tab-item",
                { style: _vm.tabItemStyle, attrs: { value: "summary" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mb-5 mt-3 px-5 py-3",
                      attrs: { outlined: "" }
                    },
                    _vm._l(_vm.staffNeededSorted, function(job, jobIdx) {
                      return _c(
                        "v-container",
                        { key: job.id, staticClass: "px-0 py-0" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "subtitle-2 no-gutters py-2" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pl-4 pr-1",
                                  attrs: { cols: "6" }
                                },
                                [
                                  _vm.invalidStaffCountByJob(job.id)
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "invalid-staff",
                                          attrs: { color: "error", size: "12" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                fas fa-exclamation-circle\n              "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(job.name) +
                                        "\n              "
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                _vm._l(job.shifts, function(
                                  count,
                                  shiftId,
                                  shiftIdx
                                ) {
                                  return _c(
                                    "v-row",
                                    {
                                      key: job.id + " " + shiftId,
                                      class: [
                                        "no-gutters",
                                        { "pt-2": shiftIdx > 0 }
                                      ]
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "info--text px-1",
                                          attrs: { cols: "10" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm.shiftName(shiftId)) +
                                              "\n                "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          class: [
                                            "text-center",
                                            {
                                              "error--text": _vm.invalidStaffCountByShift(
                                                job.id,
                                                shiftId
                                              )
                                            }
                                          ],
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.getShiftCount(
                                                  job.id,
                                                  shiftId
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          jobIdx < _vm.staffNeededSorted.length - 1
                            ? _c("v-divider")
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-tab-item",
            { attrs: { value: "dailySchedule" } },
            [
              _vm.data.length > 0
                ? [
                    _c("v-text-field", {
                      staticClass: "search-daily-staff py-3",
                      attrs: {
                        "append-icon": _vm.staffFilter ? "" : "fal fa-search",
                        clearable: !!_vm.staffFilter,
                        dense: "",
                        "hide-details": "",
                        placeholder: _vm.$t("labels.searchByName") + "...",
                        outlined: ""
                      },
                      model: {
                        value: _vm.staffFilter,
                        callback: function($$v) {
                          _vm.staffFilter =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "staffFilter"
                      }
                    }),
                    _c(
                      "v-container",
                      { staticClass: "pa-0", style: _vm.scheduleTabStyle },
                      [
                        _vm._l(_vm.schedule, function(item) {
                          return _c("v-hover", {
                            key: item.pseudoId,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var hover = ref.hover
                                    return [
                                      item.type === "shift"
                                        ? _c("ShiftsCard", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              elevation: hover ? 2 : 0,
                                              shifts: item.shifts,
                                              "show-shift-date": true,
                                              user: item.user
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit(
                                                  "show-shift-details",
                                                  {
                                                    shifts: item.shifts,
                                                    date: item.date,
                                                    user: item.user
                                                  }
                                                )
                                              }
                                            }
                                          })
                                        : item.type === "event"
                                        ? _c("EventCard", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              elevation: hover ? 2 : 0,
                                              date: item.date,
                                              event: item.event,
                                              user: item.user
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit(
                                                  "show-event-details",
                                                  {
                                                    event: item.event,
                                                    date: item.date,
                                                    user: item.user
                                                  }
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        }),
                        _vm.schedule.length === 0
                          ? _c(
                              "v-card",
                              { attrs: { outlined: "" } },
                              [
                                _c("NoContent", {
                                  attrs: {
                                    height: 140,
                                    width: 140,
                                    message: _vm.$t(
                                      "descriptions.noContentForDate",
                                      {
                                        date: _vm
                                          .moment(_vm.date)
                                          .format(_vm.dateFormatString)
                                      }
                                    )
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                : [
                    _c(
                      "v-card",
                      { attrs: { outlined: "" } },
                      [
                        _c("NoContent", {
                          attrs: {
                            height: 140,
                            width: 140,
                            message: _vm.$t("descriptions.noContentForDate", {
                              date: _vm
                                .moment(_vm.date)
                                .format(_vm.dateFormatString)
                            })
                          }
                        })
                      ],
                      1
                    )
                  ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }