<template>
  <v-select
    v-if="canSeeAllDepartments"
    v-model="selectedDepartmentId"
    class="department-picker subtitle-2"
    dense
    hide-details
    item-text="name"
    item-value="id"
    :items="departments"
    :menu-props="{ top: false, offsetY: true }"
    outlined
    style="max-width: 200px"
    @change="setActiveDepartment"
  >
    <template v-slot:item="{ attrs, item, on }">
      <v-list-item
        class="department-item"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
  <span
    v-else
    class="title page-title d-inline-block text-truncate"
    :style="pageTitleStyle"
  >
    {{ activeDepartment.name }}
  </span>
</template>

<script>
import _ from 'lodash';
import { getUnsavedChangesDialogProps } from '@/utils';

export default {
  props: {
    width: {
      default: '100px',
      type: String
    }
  },
  data () {
    const departments = _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling);
    let selectedDepartment = this.$store.getters['org/getActiveDepartment']();
    if (!selectedDepartment) {
      selectedDepartment = departments[0];
    }
    return {
      previousSelectedDepartmentId: selectedDepartment.id,
      selectedDepartmentId: selectedDepartment.id
    };
  },
  computed: {
    activeDepartment () {
      return this.$store.getters['org/getActiveDepartment']() || {};
    },
    canSeeAllDepartments () {
      return this.$store.getters['account/isOperationsManagement']() || this.$store.getters['account/isAdmin']();
    },
    departments () {
      return _.filter(this.$store.state.org.departments, (department) => department.partakeInScheduling);
    },
    pageTitleStyle () {
      return {
        'max-width': '150px'
      };
    }
  },
  methods: {
    setActiveDepartment (id, a, b, c) {
      if (this.$store.getters.hasUnsavedChanges) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {
          this.selectedDepartmentId = this.previousSelectedDepartmentId;
        }).catch(() => {
          this.previousSelectedDepartmentId = id;
          this.selectedDepartmentId = id;
          this.$store.commit('unmark_all_unsaved_changes');
          this.$store.commit('account/set_active_department_id', id);
        });
      } else {
        this.previousSelectedDepartmentId = id;
        this.selectedDepartmentId = id;
        this.$store.commit('account/set_active_department_id', id);
      }
    }
  }
};
</script>

<style lang="scss">
.department-picker {
  fieldset {
    background: $page-background !important;
  }
}
</style>
