import _ from 'lodash';
import DailySchedulePDF001 from '@/views/scheduling/pdf_templates/daily_schedule/DailySchedulePDF001';
import DailySchedulePDF002 from '@/views/scheduling/pdf_templates/daily_schedule/DailySchedulePDF002';
import DailySchedulePDFGarfield from '@/views/scheduling/pdf_templates/daily_schedule/DailySchedulePDFGarfield';

const templates = {};
templates[DailySchedulePDF001.name] = DailySchedulePDF001;
templates[DailySchedulePDF002.name] = DailySchedulePDF002;
templates[DailySchedulePDFGarfield.name] = DailySchedulePDFGarfield;

function getDailyScheduleTemplate (name) {
  for (const templateName in templates) {
    if (templateName === name) {
      return templates[templateName];
    }
  }
  return DailySchedulePDF001;
}

function getDailyScheduleDefaultOptions (name) {
  for (const templateName in templates) {
    if (templateName === name) {
      return _.cloneDeep(templates[templateName].defaultOptions);
    }
  }
  return _.cloneDeep(DailySchedulePDF001.defaultOptions);
}

export {
  getDailyScheduleDefaultOptions,
  getDailyScheduleTemplate
};
