var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 py-4 shift-activity" },
    [
      _c("ValidationObserver", {
        ref: "observer",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var invalid = ref.invalid
              var passes = ref.passes
              return [
                _c(
                  "div",
                  { staticClass: "px-3" },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "pb-4 pt-0",
                        attrs: {
                          align: "center",
                          justify: "center",
                          "no-gutters": ""
                        }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-switch", {
                              staticClass:
                                "pt-0 mt-1 d-inline-block obligatory-toggle",
                              attrs: {
                                color: "success",
                                dense: "",
                                disabled: _vm.disabled,
                                "hide-details": "",
                                inset: ""
                              },
                              on: { change: _vm.updateShiftOnChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mr-2 body-2 grey--text text--darken-3",
                                            attrs: {
                                              title: _vm.$t("labels.obligatory")
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("labels.obligatory")
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              "max-width": "300px",
                                              top: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color: "info",
                                                                "x-small": ""
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "\n                    fal fa-question-circle\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "body-2" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "descriptions.obligatoryShiftCreate"
                                                      )
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.shiftCopy.obligatory,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "obligatory", $$v)
                                },
                                expression: "shiftCopy.obligatory"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        !_vm.disabled
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { "max-width": "300px", top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "grey lighten-3 ml-2 delete-shift",
                                                  attrs: {
                                                    icon: "",
                                                    small: ""
                                                  },
                                                  on: { click: _vm.removeShift }
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "16" } },
                                                [
                                                  _vm._v(
                                                    "\n                fal fa-trash-alt\n              "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c("span", { staticClass: "body-2" }, [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.deleteShift")) +
                                      "\n          "
                                  )
                                ])
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass:
                          "grey--text text--darken-3 no-gutters pb-4 pt-0",
                        attrs: { align: "center", justify: "center" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("VeeSelect", {
                              ref: "selectShiftType",
                              staticClass: "d-inline-block mr-5 type-select",
                              staticStyle: { width: "100px" },
                              attrs: {
                                dense: "",
                                disabled: _vm.disabled,
                                "hide-details": "",
                                "item-text": "name",
                                "item-value": "id",
                                items: _vm.shiftTypes,
                                label: _vm.$t("labels.shift"),
                                "menu-props": { top: false, offsetY: true },
                                outlined: "",
                                rules: { required: true }
                              },
                              on: { change: _vm.updateShiftType },
                              model: {
                                value: _vm.shiftCopy.typeId,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "typeId", $$v)
                                },
                                expression: "shiftCopy.typeId"
                              }
                            }),
                            _vm.allowUpdatingShiftTime
                              ? _c(
                                  "v-menu",
                                  {
                                    key: "start-" + _vm.shiftStartTimeCount,
                                    ref: "shiftStartTimeMenu",
                                    attrs: {
                                      "close-on-content-click": "",
                                      "offset-y": "",
                                      "max-height": "300px",
                                      "max-width": "100px",
                                      "min-width": "100px",
                                      "nudge-bottom": 0,
                                      "nudge-left": 0
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "VeeTextField",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "mask",
                                                          rawName: "v-mask",
                                                          value: _vm.timeMask,
                                                          expression: "timeMask"
                                                        }
                                                      ],
                                                      ref: "shiftStartTime",
                                                      staticClass:
                                                        "shift-time d-inline-block",
                                                      attrs: {
                                                        autocomplete: false,
                                                        dense: "",
                                                        disabled:
                                                          _vm.disabled ||
                                                          !_vm.shiftCopy.typeId,
                                                        "hide-details": "",
                                                        name: "shiftStartTime",
                                                        outlined: "",
                                                        label: _vm.$t(
                                                          "labels.start"
                                                        ),
                                                        rules: {
                                                          required: true,
                                                          time: true,
                                                          excluded: [
                                                            _vm.shiftEndTimeDisplay
                                                          ]
                                                        }
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateStartTime()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.shiftStartTimeDisplay,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.shiftStartTimeDisplay = $$v
                                                        },
                                                        expression:
                                                          "shiftStartTimeDisplay"
                                                      }
                                                    },
                                                    "VeeTextField",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.showShiftStartTime,
                                      callback: function($$v) {
                                        _vm.showShiftStartTime = $$v
                                      },
                                      expression: "showShiftStartTime"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      { attrs: { dense: "", flat: "" } },
                                      _vm._l(_vm.startTimes, function(time) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: time.id,
                                            staticClass: "caption",
                                            attrs: {
                                              disabled: time.disabled,
                                              title: time.name
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                time.disabled
                                                  ? null
                                                  : _vm.updateStartTime(time.id)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(time.name) +
                                                  "\n                "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "fieldset",
                                  { staticClass: "nb-fieldset d-inline-flex" },
                                  [
                                    _c(
                                      "legend",
                                      {
                                        staticClass:
                                          "grey--text text--darken-2 caption-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$tc("labels.start", 1)) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "body-2 pb-2 text-truncate grey--text"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.getShiftDisplayTime(
                                                _vm.shiftCopy.startTime
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                            _c("span", { staticClass: "px-2" }, [
                              _vm._v("\n            -\n          ")
                            ]),
                            _vm.allowUpdatingShiftTime
                              ? _c(
                                  "v-menu",
                                  {
                                    key: "end-" + _vm.shiftEndTimeCount,
                                    ref: "shiftEndTimeMenu",
                                    attrs: {
                                      "close-on-content-click": "",
                                      "offset-y": "",
                                      "max-height": "300px",
                                      "max-width": "100px",
                                      "min-width": "100px",
                                      "nudge-bottom": 0,
                                      "nudge-left": 0
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "VeeTextField",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      directives: [
                                                        {
                                                          name: "mask",
                                                          rawName: "v-mask",
                                                          value: _vm.timeMask,
                                                          expression: "timeMask"
                                                        }
                                                      ],
                                                      ref: "shiftEndTime",
                                                      staticClass:
                                                        "shift-time d-inline-block",
                                                      attrs: {
                                                        autocomplete: false,
                                                        dense: "",
                                                        disabled:
                                                          _vm.disabled ||
                                                          !_vm.shiftCopy.typeId,
                                                        "hide-details": "",
                                                        name: "shiftEndTime",
                                                        outlined: "",
                                                        label: _vm.$t(
                                                          "labels.end"
                                                        ),
                                                        rules: {
                                                          required: true,
                                                          time: true,
                                                          excluded: [
                                                            _vm.shiftStartTimeDisplay
                                                          ]
                                                        }
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateEndTime()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.shiftEndTimeDisplay,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.shiftEndTimeDisplay = $$v
                                                        },
                                                        expression:
                                                          "shiftEndTimeDisplay"
                                                      }
                                                    },
                                                    "VeeTextField",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.showShiftEndTime,
                                      callback: function($$v) {
                                        _vm.showShiftEndTime = $$v
                                      },
                                      expression: "showShiftEndTime"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      { attrs: { dense: "", flat: "" } },
                                      _vm._l(_vm.endTimes, function(time) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: time.id,
                                            staticClass: "caption",
                                            attrs: {
                                              disabled: time.disabled,
                                              title: time.name
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                time.disabled
                                                  ? null
                                                  : _vm.updateEndTime(time.id)
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(time.name) +
                                                  "\n                "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "fieldset",
                                  { staticClass: "nb-fieldset d-inline-flex" },
                                  [
                                    _c(
                                      "legend",
                                      {
                                        staticClass:
                                          "grey--text text--darken-2 caption-2"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$tc("labels.end", 1)) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "body-2 pb-2 text-truncate grey--text"
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.getShiftDisplayTime(
                                                _vm.shiftCopy.endTime
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass:
                          "grey--text text--darken-3 no-gutters pb-4 pt-0"
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("FlagSelection", {
                              ref: "selectShiftFlag",
                              staticClass: "shift-flags",
                              attrs: {
                                "small-chips": "",
                                "has-flag-access": _vm.canShowFlag,
                                dense: "",
                                disabled: _vm.disabled,
                                filter: _vm.flagsFilter,
                                "hide-details": "",
                                "item-text": "shortCode",
                                "item-value": "id",
                                items: _vm.shiftFlags,
                                label: _vm.$tc("labels.flag", 2),
                                multiple: "",
                                outlined: "",
                                "return-object": false
                              },
                              on: { change: _vm.updateShiftOnChange },
                              model: {
                                value: _vm.shiftCopy.flags,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "flags", $$v)
                                },
                                expression: "shiftCopy.flags"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass:
                          "grey--text text--darken-3 no-gutters pb-3 pt-0"
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-switch", {
                              staticClass:
                                "pt-0 mt-1 d-inline-block sitter-toggle",
                              attrs: {
                                color: "success",
                                dense: "",
                                disabled: _vm.disabled,
                                inset: "",
                                "hide-details": ""
                              },
                              on: { change: _vm.updateShiftOnChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mr-2 body-2 grey--text text--darken-3",
                                            attrs: {
                                              title: _vm.$t(
                                                "labels.assignAsSitter"
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "labels.assignAsSitter"
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.shiftCopy.sitter,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "sitter", $$v)
                                },
                                expression: "shiftCopy.sitter"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.shiftCopy.sitter
                      ? _c(
                          "v-row",
                          {
                            staticClass:
                              "grey--text text--darken-3 no-gutters pb-4 pt-0",
                            attrs: { align: "center", justify: "center" }
                          },
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "4" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "sitter-details",
                                  attrs: {
                                    clearable: "",
                                    dense: "",
                                    disabled:
                                      _vm.disabled || !_vm.shiftCopy.sitter,
                                    "hide-details": "",
                                    label: _vm.$t("labels.room"),
                                    outlined: ""
                                  },
                                  on: {
                                    "click:clear": _vm.updateShiftOnChange,
                                    keyup: _vm.updateShiftDelayed
                                  },
                                  model: {
                                    value: _vm.shiftCopy.settings.sitter.room,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.shiftCopy.settings.sitter,
                                        "room",
                                        $$v
                                      )
                                    },
                                    expression: "shiftCopy.settings.sitter.room"
                                  }
                                })
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c(
                              "v-col",
                              { attrs: { cols: "7" } },
                              [
                                _c("v-text-field", {
                                  staticClass: "sitter-details",
                                  attrs: {
                                    clearable: "",
                                    dense: "",
                                    disabled:
                                      _vm.disabled || !_vm.shiftCopy.sitter,
                                    "hide-details": "",
                                    label: _vm.$t("labels.reason"),
                                    outlined: ""
                                  },
                                  on: {
                                    "click:clear": _vm.updateShiftOnChange,
                                    keyup: _vm.updateShiftDelayed
                                  },
                                  model: {
                                    value: _vm.shiftCopy.settings.sitter.reason,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.shiftCopy.settings.sitter,
                                        "reason",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "shiftCopy.settings.sitter.reason"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.canShowData()
                      ? _c(
                          "v-row",
                          { staticClass: "no-gutters pb-2" },
                          [
                            _c("Comments", {
                              staticClass: "body-2 mb-3",
                              attrs: {
                                "auto-grow": true,
                                counter: "1000",
                                disabled: _vm.disabled,
                                "disclosure-hint": _vm.$t(
                                  "descriptions.disclaimer"
                                ),
                                maxlength: "1000",
                                outlined: "",
                                placeholder: _vm.placeholder,
                                rows: "1",
                                "single-line": "",
                                "visibility-hint": _vm.$t(
                                  "descriptions.commentVisibilityAll"
                                )
                              },
                              on: { keyup: _vm.updateShiftDelayed },
                              model: {
                                value: _vm.shiftCopy.comments,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "comments", $$v)
                                },
                                expression: "shiftCopy.comments"
                              }
                            }),
                            _vm.canShowInternal()
                              ? _c("Comments", {
                                  staticClass: "body-2",
                                  attrs: {
                                    "auto-grow": true,
                                    counter: "1000",
                                    disabled: _vm.disabled,
                                    "disclosure-hint": _vm.$t(
                                      "descriptions.disclaimer"
                                    ),
                                    label: _vm.$t("labels.internalComments"),
                                    maxlength: "1000",
                                    mode: "internal",
                                    outlined: "",
                                    placeholder: _vm.placeholder,
                                    rows: "1",
                                    "single-line": "",
                                    "visibility-hint": _vm.$t(
                                      "descriptions.commentVisibilitySchedulers"
                                    )
                                  },
                                  on: { keyup: _vm.updateShiftDelayed },
                                  model: {
                                    value: _vm.shiftCopy.internalComments,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.shiftCopy,
                                        "internalComments",
                                        $$v
                                      )
                                    },
                                    expression: "shiftCopy.internalComments"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      {
                        staticClass: "pb-4 pt-0",
                        attrs: {
                          align: "center",
                          justify: "center",
                          "no-gutters": ""
                        }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("v-switch", {
                              staticClass:
                                "pt-0 mt-1 d-inline-block overtime-toggle",
                              attrs: {
                                color: "success",
                                dense: "",
                                disabled: _vm.disabled,
                                "hide-details": "",
                                inset: ""
                              },
                              on: { change: _vm.updateShiftOnChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mr-2 body-2 grey--text text--darken-3",
                                            attrs: {
                                              title: _vm.$t("labels.overtime")
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$t("labels.overtime")
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.shiftCopy.overtime,
                                callback: function($$v) {
                                  _vm.$set(_vm.shiftCopy, "overtime", $$v)
                                },
                                expression: "shiftCopy.overtime"
                              }
                            })
                          ],
                          1
                        ),
                        _c("v-spacer")
                      ],
                      1
                    ),
                    !_vm.disabled && _vm.allowSaving
                      ? _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "reset",
                                    attrs: {
                                      block: "",
                                      color: "primary",
                                      disabled: _vm.saving,
                                      outlined: ""
                                    },
                                    on: { click: _vm.reset }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("labels.reset")) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "py-0", attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "save",
                                    attrs: {
                                      block: "",
                                      color: "accent",
                                      disabled: invalid || _vm.saving
                                    },
                                    on: {
                                      click: function($event) {
                                        return passes(_vm.updateShift)
                                      }
                                    }
                                  },
                                  [
                                    _vm.saving
                                      ? _c("v-progress-circular", {
                                          attrs: {
                                            color: "primary lighten-2",
                                            indeterminate: "",
                                            size: "22",
                                            width: "2"
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.$t("labels.save")) +
                                              "\n            "
                                          )
                                        ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }