<template>
  <v-container
    class="errors-overview px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-tasks</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t(`labels.${validatorName}`) }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <component
      :is="getValidatorName(validator)"
      :job-type-ids="jobTypeIds"
      :shift-type="shiftType"
      :validator="validator"
      :schedule-id="scheduleId"
      @filter="(data) => { $emit('filter', data) }"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import ImbalanceValidator from '@/views/scheduling/validators/imbalance/ImbalanceValidator.vue';
import OvertimeValidator from '@/views/scheduling/validators/overtime/OvertimeValidator.vue';
import ConsecutiveShiftsValidator from '@/views/scheduling/validators/consecutive_shifts/ConsecutiveShiftsValidator.vue';
import TimeConflictValidator from '@/views/scheduling/validators/time_conflict/TimeConflictValidator.vue';
import MinimumCountValidator from '@/views/scheduling/validators/minimum_count/MinimumCountValidator.vue';

export default {
  components: {
    ConsecutiveShiftsValidator,
    ImbalanceValidator,
    MinimumCountValidator,
    OvertimeValidator,
    TimeConflictValidator
  },
  props: {
    jobTypeIds: {
      type: Array,
      default: function () {
        return [];
      }
    },
    scheduleId: {
      type: [Number, String],
      required: true
    },
    shiftType: {
      type: Number,
      default: null
    },
    validatorName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      tab: this.$store.state.scheduling.panels.errorsOverview.tab,
      tabHeight: 500
    };
  },
  computed: {
    tabItemStyle () {
      const PADDING = 20;
      return {
        'height': `${this.tabHeight - PADDING}px`
      };
    },
    validator () {
      return this.$store.getters['scheduling/getValidator'](this.scheduleId, this.validatorName);
    },
    validators () {
      const validators = _.values(this.$store.getters['scheduling/getValidator'](this.scheduleId));
      return _.sortBy(validators, [function (v) { return v.constructor.priority; }]);
    }
  },
  watch: {
    tab (tab) {
      this.$store.commit('scheduling/update_panel', { panel: 'errorsOverview', prop: 'tab', value: tab });
    }
  },
  mounted: function () {
    this.updateTabHeight();
    window.addEventListener('resize', _.debounce(this.updateTabHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateTabHeight);
  },
  methods: {
    getValidatorName (validator) {
      return validator.constructor.name;
    },
    updateTabHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const errorsOverviewContent = document.getElementById('errorsOverviewContent');
      if (el && errorsOverviewContent) {
        this.tabHeight = el.clientHeight - errorsOverviewContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>

<style lang="scss">
.errors-overview {
  @include round-tabs(#837EB7, #FFF);

  .v-tab {
    padding-left: 0px;
    padding-right: 0px;
    width: 125px;
  }
}
</style>
