var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "sp-request pa-0" }, [
    _c("table", { staticClass: "d-inline-block" }, [
      _c(
        "tbody",
        _vm._l(_vm.splits, function(split, idx) {
          return _c("tr", { key: split.id, attrs: { dense: "" } }, [
            _c(
              "td",
              [
                _c(
                  "v-list-item",
                  {
                    staticClass: "px-0 d-inline-block",
                    style: { visibility: idx === 0 ? "visible" : "hidden" }
                  },
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-list-item-title",
                          { staticClass: "body-2 mb-1" },
                          [
                            _c("UserName", {
                              attrs: {
                                user:
                                  _vm.$store.state.org.employees[
                                    _vm.request.assocContent.assigneeId
                                  ]
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-list-item-subtitle",
                          { staticClass: "caption mt-1" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.getJobInfo(
                                    _vm.request.assocContent.assigneeId
                                  )
                                ) +
                                "\n              "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "td",
              [
                _c(
                  "span",
                  {
                    staticClass: "grey--text text--darken-3",
                    style: { visibility: idx === 0 ? "visible" : "hidden" }
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(_vm.department) + "\n          "
                    )
                  ]
                ),
                _c("v-divider", {
                  staticClass: "d-inline mx-2",
                  style: { visibility: idx === 0 ? "visible" : "hidden" },
                  attrs: { vertical: "" }
                }),
                _c("span", { staticClass: "grey--text text--darken-3" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getSplitTime(split)) +
                      "\n          "
                  )
                ]),
                _c(
                  "v-icon",
                  {
                    staticClass: "mx-9",
                    attrs: { color: "warning", size: "20" }
                  },
                  [_vm._v("\n            fal fa-long-arrow-right\n          ")]
                )
              ],
              1
            ),
            _c(
              "td",
              [
                split.flags.length > 0
                  ? _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.getSplitFlags(split)) +
                          "\n          "
                      )
                    ])
                  : _c(
                      "v-list-item",
                      { staticClass: "px-0 d-inline-block" },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c(
                              "v-list-item-title",
                              { staticClass: "body-2 mb-1" },
                              [
                                _c("UserName", {
                                  attrs: {
                                    user:
                                      _vm.$store.state.org.employees[
                                        split.assigneeId
                                      ]
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-subtitle",
                              { staticClass: "caption mt-1" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.getJobInfo(split.assigneeId)) +
                                    "\n              "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
              ],
              1
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }