var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.schedulePeriods.length === 0
    ? _c(
        "v-container",
        { staticClass: "schedule", attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "portal",
            { attrs: { to: "page-title" } },
            [
              _c(
                "v-container",
                { staticClass: "schedule-header py-0 mx-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "space-between" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-0",
                          attrs: { "align-self": "center", cols: "auto" }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ml-1", attrs: { align: "center" } },
                            [
                              _c("DepartmentSelector", {
                                attrs: { width: _vm.pageTitleWidth + "px" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticStyle: { "margin-top": "-150px" },
              attrs: { justify: "center" }
            },
            [
              _c("v-col", { attrs: { md: "6", sm: "12" } }, [
                _c(
                  "div",
                  { staticClass: "mt-10 mx-3 pa-6" },
                  [
                    _c(
                      "v-row",
                      { attrs: { justify: "center" } },
                      [
                        _c("v-img", {
                          attrs: {
                            contain: "",
                            "max-width": "50%",
                            src: require("@/assets/images/oops-penguin.svg")
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass:
                        "darken-2 grey--text mt-5 px-5 text--darken-3 text-center ",
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("descriptions.noSchedules"))
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-container",
        { staticClass: "schedule", attrs: { fluid: "" } },
        [
          _vm.hideSchedule
            ? [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "min-width": "250px",
                              "close-on-content-click": false,
                              "offset-y": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var value = ref.value
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass:
                                              "title font-weight-regular text-capitalize schedule-dropdown px-2",
                                            attrs: {
                                              color: "primary",
                                              elevation: "0",
                                              outlined: "",
                                              width: "250px"
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "body-2 font-weight-medium"
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.selectedScheduleText
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "20" } },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    value
                                                      ? "fas fa-caret-up"
                                                      : "fas fa-caret-down"
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1028772057
                            ),
                            model: {
                              value: _vm.showSchedulePicker,
                              callback: function($$v) {
                                _vm.showSchedulePicker = $$v
                              },
                              expression: "showSchedulePicker"
                            }
                          },
                          [
                            _c("ScheduleSelection", {
                              attrs: {
                                department: _vm.department,
                                "selected-schedule-id": _vm.selectedScheduleId
                              },
                              on: {
                                select: _vm.setSelectedSchedule,
                                close: function($event) {
                                  _vm.showSchedulePicker = false
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-container",
                  {
                    staticClass: "schedule",
                    attrs: { fluid: "", "fill-height": "" }
                  },
                  [
                    _c(
                      "portal",
                      { attrs: { to: "page-title" } },
                      [
                        _c(
                          "v-container",
                          { staticClass: "schedule-header py-0 mx-0" },
                          [
                            _c(
                              "v-row",
                              { attrs: { justify: "space-between" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pl-0",
                                    attrs: {
                                      "align-self": "center",
                                      cols: "auto"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "ml-1",
                                        attrs: { align: "center" }
                                      },
                                      [
                                        _c("DepartmentSelector", {
                                          attrs: {
                                            width: _vm.pageTitleWidth + "px"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-spacer")
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticStyle: { "margin-top": "-150px" },
                        attrs: { justify: "center" }
                      },
                      [
                        _c("v-col", { attrs: { md: "6", sm: "12" } }, [
                          _c(
                            "div",
                            { staticClass: "mt-10 mx-3 pa-6" },
                            [
                              _c("div", {
                                staticClass:
                                  "darken-2 grey--text mb-5 px-5 text--darken-3 text-center balancing",
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$t("descriptions.balancingSchedule")
                                  )
                                }
                              }),
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      contain: "",
                                      "max-width": "50%",
                                      src: require("@/assets/images/work-hard-on-desktop-penguin.svg")
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm.retrievingSchedule &&
              !_vm.gridInitialized &&
              _vm.showSkeletonLoader
            ? [
                _c(
                  "v-container",
                  { staticClass: "schedule", attrs: { fluid: "" } },
                  [
                    _c(
                      "v-row",
                      { attrs: { dense: "" } },
                      [
                        _vm.$vuetify.breakpoint.smAndDown
                          ? _vm._l(2, function(n) {
                              return _c(
                                "v-col",
                                { key: n, attrs: { cols: "auto" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "single-action",
                                    attrs: { type: "actions" }
                                  })
                                ],
                                1
                              )
                            })
                          : _vm._l(5, function(n) {
                              return _c(
                                "v-col",
                                { key: n, attrs: { cols: "auto" } },
                                [
                                  _c("v-skeleton-loader", {
                                    staticClass: "single-action",
                                    attrs: { type: "actions" }
                                  })
                                ],
                                1
                              )
                            }),
                        _c("v-spacer")
                      ],
                      2
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "no-action",
                              attrs: { type: "table" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : !_vm.retrievingSchedule
            ? [
                _c(
                  "v-container",
                  {
                    staticClass: "schedule",
                    style: _vm.gridMaxWidth,
                    attrs: { fluid: "" }
                  },
                  [
                    _c(
                      "portal",
                      { attrs: { to: "page-title" } },
                      [
                        _vm.$vuetify.breakpoint.smAndDown
                          ? [
                              _c(
                                "v-container",
                                { staticClass: "schedule-header py-0" },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "space-between" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pl-0",
                                          attrs: {
                                            "align-self": "center",
                                            cols: "auto"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "ml-1",
                                              attrs: { align: "center" }
                                            },
                                            [
                                              _c("DepartmentSelector", {
                                                attrs: {
                                                  width:
                                                    _vm.pageTitleWidth + "px"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-spacer"),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            "align-self": "center",
                                            cols: "auto"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              class: [
                                                "font-weight-medium white--text",
                                                _vm.scheduleStateIndicator
                                                  .stateLabelCssClass
                                              ],
                                              attrs: { small: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.scheduleStateIndicator
                                                        .stateLabelKey
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : [
                              _c(
                                "v-row",
                                {
                                  staticClass: "schedule-header",
                                  attrs: { justify: "space-between" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        "align-self": "center",
                                        cols: "auto"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("DepartmentSelector", {
                                            attrs: {
                                              width: _vm.pageTitleWidth + "px"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        "align-self": "center",
                                        cols: "auto"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "ml-1",
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              class: [
                                                "font-weight-medium ml-5 white--text",
                                                _vm.scheduleStateIndicator
                                                  .stateLabelCssClass
                                              ],
                                              attrs: { small: "" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.scheduleStateIndicator
                                                        .stateLabelKey
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              class: [
                                                "caption font-weight-medium ml-3",
                                                _vm.scheduleStateIndicator
                                                  .stateDescCssClass
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      _vm.scheduleStateIndicator
                                                        .stateDescKey,
                                                      {
                                                        date:
                                                          _vm
                                                            .scheduleStateIndicator
                                                            .date
                                                      }
                                                    )
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  !_vm.$vuetify.breakpoint.smAndDown
                                    ? [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "pr-0",
                                            attrs: {
                                              "align-self": "center",
                                              cols: "auto"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  "nudge-top": "10"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var tooltipOn = ref.on
                                                        var attrs = ref.attrs
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  staticClass:
                                                                    "mx-1",
                                                                  attrs: {
                                                                    icon: ""
                                                                  }
                                                                },
                                                                "v-btn",
                                                                attrs,
                                                                false
                                                              ),
                                                              Object.assign(
                                                                {},
                                                                tooltipOn,
                                                                {
                                                                  click:
                                                                    _vm.toggleHelpPanel
                                                                }
                                                              )
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        fal fa-info-circle\n                      "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  1846916210
                                                )
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "body-2" },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "labels.information"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                      ],
                      2
                    ),
                    _vm.$vuetify.breakpoint.smAndDown
                      ? _c(
                          "portal",
                          { attrs: { to: "page-menu" } },
                          [
                            _c(
                              "v-list-item",
                              { on: { click: _vm.toggleHelpPanel } },
                              [
                                _c(
                                  "v-list-item-action",
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v(
                                        "\n              fal fa-info-circle\n            "
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c("v-list-item-title", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("labels.information")) +
                                      "\n          "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-row",
                      { attrs: { justify: "end", "no-gutters": "" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tabs",
                              {
                                staticClass: "job-types",
                                attrs: {
                                  "mobile-breakpoint": "768",
                                  "show-arrows": "",
                                  "slider-color": "accent",
                                  "slider-size": "3",
                                  value: _vm.selectedJobTypeTab
                                }
                              },
                              _vm._l(_vm.groupedJobTypes, function(
                                jobTypeGroup,
                                index
                              ) {
                                return _c(
                                  "v-tab",
                                  {
                                    key: index,
                                    nativeOn: {
                                      "!click": function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.onJobTypeChange(index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._l(jobTypeGroup, function(
                                      jobType,
                                      jobIndex
                                    ) {
                                      return [
                                        jobIndex > 0
                                          ? _c(
                                              "span",
                                              {
                                                key: jobType.id + "-plus",
                                                staticClass: "ml-2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  +\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            key: jobType.id + "-name",
                                            class: jobIndex > 0 ? "ml-2" : ""
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(jobType.name) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-spacer")
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "grid-action-bar",
                        attrs: { justify: "center", "justify-md": "end" }
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-menu",
                              {
                                attrs: {
                                  "min-width": "250px",
                                  "close-on-content-click": false,
                                  "offset-y": ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var value = ref.value
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "title font-weight-regular text-capitalize schedule-dropdown px-2",
                                              attrs: {
                                                color: "primary",
                                                elevation: "0",
                                                outlined: "",
                                                width: "250px"
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "body-2 font-weight-medium"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.selectedScheduleText
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "20" } },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      value
                                                        ? "fas fa-caret-up"
                                                        : "fas fa-caret-down"
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.showSchedulePicker,
                                  callback: function($$v) {
                                    _vm.showSchedulePicker = $$v
                                  },
                                  expression: "showSchedulePicker"
                                }
                              },
                              [
                                _c("ScheduleSelection", {
                                  attrs: {
                                    department: _vm.department,
                                    "selected-schedule-id":
                                      _vm.selectedScheduleId
                                  },
                                  on: {
                                    select: _vm.setSelectedSchedule,
                                    close: function($event) {
                                      _vm.showSchedulePicker = false
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _c(
                          "v-col",
                          { staticClass: "px-0", attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                class:
                                                  _vm.actionStyles.filters
                                                    .button.classes,
                                                attrs: {
                                                  icon: "",
                                                  value: "filters"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.setOpenedPanelName(
                                                      "filters"
                                                    )
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm.hasFilters &&
                                            _vm.openedPanelName !== "filters"
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      _vm.actionStyles.filters
                                                        .icon.classes,
                                                    attrs: { size: "16" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  fas fa-filter\n                "
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      _vm.actionStyles.filters
                                                        .icon.classes,
                                                    attrs: { size: "16" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  fal fa-filter\n                "
                                                    )
                                                  ]
                                                )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _c("span", { staticClass: "body-2" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("labels.filter")) +
                                      "\n            "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "px-0", attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                class:
                                                  _vm.actionStyles.download
                                                    .button.classes,
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function($event) {
                                                    _vm.download.dialogOpened = true
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                class:
                                                  _vm.actionStyles.download.icon
                                                    .classes,
                                                attrs: { size: "16" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  fal fa-download\n                "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _c("span", { staticClass: "body-2" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("labels.download")) +
                                      "\n            "
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm.scheduleIsSelfSchedule
                          ? _c(
                              "v-col",
                              { attrs: { cols: "auto" } },
                              [
                                _c("v-switch", {
                                  staticClass: "mt-1 ml-4 show-self-schedule",
                                  attrs: {
                                    color: "success",
                                    dense: "",
                                    "hide-details": "",
                                    inset: ""
                                  },
                                  on: {
                                    change: function(value) {
                                      return value
                                        ? _vm.showSelfSchedulePanel()
                                        : _vm.closeAllPanels()
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "body-2 grey--text text--darken-3",
                                                attrs: {
                                                  title: _vm.$t(
                                                    "labels.selfScheduleMode"
                                                  )
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "labels.selfScheduleMode"
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    962945070
                                  ),
                                  model: {
                                    value: _vm.selfScheduleEnabled,
                                    callback: function($$v) {
                                      _vm.selfScheduleEnabled = $$v
                                    },
                                    expression: "selfScheduleEnabled"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("v-text-field", {
                      staticClass: "search-staff py-3 ml-4",
                      style: _vm.searchStaffStyle,
                      attrs: {
                        "append-icon": _vm.staffFilter ? "" : "fal fa-search",
                        clearable: !!_vm.staffFilter,
                        dense: "",
                        "hide-details": "",
                        placeholder: _vm.$t("labels.searchByName"),
                        solo: ""
                      },
                      model: {
                        value: _vm.staffFilter,
                        callback: function($$v) {
                          _vm.staffFilter =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "staffFilter"
                      }
                    }),
                    _c("div", {
                      ref: "gridParent",
                      staticClass: "grid-parent",
                      style: _vm.gridStyle
                    })
                  ],
                  1
                ),
                _vm.missingWeekendShifts > 0 && _vm.scheduleIsSelfSchedule
                  ? _c(
                      "v-footer",
                      {
                        staticClass: "weekend-shifts-details elevation-0 py-1",
                        style: {
                          left: _vm.$vuetify.breakpoint.smAndDown
                            ? "0px"
                            : "100px"
                        },
                        attrs: { fixed: "" }
                      },
                      [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c("v-col", { staticClass: "py-1" }, [
                              _c(
                                "span",
                                { staticClass: "caption white--text" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "descriptions.missingWeekendShifts",
                                          { count: _vm.missingWeekendShifts }
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-dialog",
                  {
                    attrs: { eager: "", persistent: "", fullscreen: "" },
                    model: {
                      value: _vm.download.dialogOpened,
                      callback: function($$v) {
                        _vm.$set(_vm.download, "dialogOpened", $$v)
                      },
                      expression: "download.dialogOpened"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      { attrs: { id: "downloadMasterSchedule" } },
                      [
                        _c(
                          "v-card-title",
                          { staticClass: "body-2 d-block mb-2" },
                          [
                            _c(
                              "span",
                              { staticClass: "body-2 font-weight-medium" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("labels.downloadMasterSchedule")
                                    ) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "body-2 font-weight-medium primary--text"
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.selectedScheduleText) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "float-right mt-n1",
                                attrs: { icon: "", small: "" },
                                on: {
                                  click: function($event) {
                                    _vm.download.dialogOpened = false
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(
                                    "\n              fal fa-times\n            "
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-card-text",
                          {
                            staticClass: "pa-0 ma-0",
                            style: _vm.downloadGridContainerStyle
                          },
                          [
                            _c("div", {
                              ref: "downloadGrid",
                              staticClass: "download-grid",
                              style: _vm.downloadGridStyle
                            })
                          ]
                        ),
                        _c(
                          "v-footer",
                          {
                            attrs: { color: "white", fixed: "", height: "75px" }
                          },
                          [
                            _c("v-select", {
                              staticStyle: { "max-width": "184px" },
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                items: [
                                  {
                                    text: _vm.$t("labels.letter"),
                                    value: "LETTER"
                                  },
                                  {
                                    text: _vm.$t("labels.legal"),
                                    value: "LEGAL"
                                  }
                                ],
                                label: _vm.$t("labels.pageSize"),
                                outlined: ""
                              },
                              model: {
                                value: _vm.download.pageSize,
                                callback: function($$v) {
                                  _vm.$set(_vm.download, "pageSize", $$v)
                                },
                                expression: "download.pageSize"
                              }
                            }),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "" },
                                on: {
                                  click: function($event) {
                                    _vm.download.dialogOpened = false
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("labels.cancel")) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "start-download",
                                attrs: { color: "secondary" },
                                on: { click: _vm.downloadSchedule }
                              },
                              [
                                _vm.download.inProgress
                                  ? _c("v-progress-circular", {
                                      staticClass: "px-12",
                                      attrs: {
                                        color: "white",
                                        indeterminate: "",
                                        size: "22",
                                        width: "2"
                                      }
                                    })
                                  : _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("labels.downloadPDF")) +
                                          "\n            "
                                      )
                                    ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("SidePanel", {
                  attrs: { panels: _vm.sidePanels },
                  on: {
                    transitionend: function($event) {
                      return _vm.redrawGrid(true)
                    }
                  }
                })
              ]
            : _vm._e()
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }