var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-8 py-1", attrs: { id: "openShiftsContainer" } },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-list")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$tc("labels.openShift", 2)) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _vm.allowSelectingDate
            ? _c(
                "v-row",
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "offset-y": "",
                        "max-width": "290px",
                        "min-width": "auto",
                        "nudge-right": "12px",
                        "nudge-top": "26px"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-text-field",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "px-3",
                                        attrs: {
                                          label: _vm.$tc("labels.date", 1),
                                          "append-icon": "fal fa-calendar-alt",
                                          dense: "",
                                          outlined: "",
                                          readonly: "",
                                          value: _vm
                                            .moment(_vm.internalDate)
                                            .format(_vm.dateFormatStringWithDoW)
                                        }
                                      },
                                      "v-text-field",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        723467152
                      ),
                      model: {
                        value: _vm.showDatePicker,
                        callback: function($$v) {
                          _vm.showDatePicker = $$v
                        },
                        expression: "showDatePicker"
                      }
                    },
                    [
                      _c("v-date-picker", {
                        attrs: {
                          max: _vm.maxDate,
                          min: _vm.minDate,
                          "no-title": ""
                        },
                        on: {
                          input: function($event) {
                            _vm.showDatePicker = false
                          }
                        },
                        model: {
                          value: _vm.internalDate,
                          callback: function($$v) {
                            _vm.internalDate = $$v
                          },
                          expression: "internalDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "secondary--text body-2",
                      attrs: { cols: "12" }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm
                              .moment(_vm.internalDate)
                              .format(_vm.dateFormatStringWithDoW)
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              ),
          _c(
            "v-tabs",
            {
              staticClass: "dense",
              attrs: {
                id: "openShiftList",
                centered: "",
                grow: "",
                "hide-slider": ""
              },
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab",
                { staticClass: "text-capitalize", attrs: { href: "#opened" } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.open")) + "\n      "
                  )
                ]
              ),
              _c(
                "v-tab",
                { staticClass: "text-capitalize", attrs: { href: "#history" } },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.history")) + "\n      "
                  )
                ]
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "opened" } },
                [
                  _vm.loading
                    ? [
                        _c(
                          "v-row",
                          { style: _vm.tabStyle, attrs: { align: "center" } },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "text-center" },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            color: "info",
                                            indeterminate: "",
                                            size: "75",
                                            width: "6"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "text-center" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("descriptions.loading")
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-spacer")
                          ],
                          1
                        )
                      ]
                    : _vm.opened.length > 0
                    ? [
                        _c("div", { staticClass: "caption grey--text mb-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("labels.totalCount", {
                                  count: _vm.opened.length
                                })
                              ) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "v-container",
                          { staticClass: "pa-0", style: _vm.tabStyle },
                          _vm._l(_vm.opened, function(item) {
                            return _c("v-hover", {
                              key: item.id,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var hover = ref.hover
                                      return [
                                        _c("OpenShiftCard", {
                                          staticClass: "mb-4",
                                          attrs: {
                                            hover: hover,
                                            "open-shift": item
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "open-details",
                                                item
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          }),
                          1
                        )
                      ]
                    : [
                        _c(
                          "v-card",
                          { attrs: { outlined: "" } },
                          [
                            _c("NoContent", {
                              attrs: {
                                height: 140,
                                width: 140,
                                message: _vm.$t(
                                  "descriptions.noContentForDate",
                                  {
                                    date: _vm
                                      .moment(_vm.internalDate)
                                      .format(_vm.dateFormatStringWithDoW)
                                  }
                                )
                              }
                            })
                          ],
                          1
                        )
                      ]
                ],
                2
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "history" } },
                [
                  _vm.loading
                    ? [
                        _c(
                          "v-row",
                          { style: _vm.tabStyle, attrs: { align: "center" } },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "v-row",
                                  { staticClass: "text-center" },
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "text-center" },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            color: "info",
                                            indeterminate: "",
                                            size: "75",
                                            width: "6"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { staticClass: "text-center" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("descriptions.loading")
                                            )
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("v-spacer")
                          ],
                          1
                        )
                      ]
                    : _vm.history.length > 0
                    ? [
                        _c("div", { staticClass: "caption grey--text mb-2" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("labels.totalCount", {
                                  count: _vm.history.length
                                })
                              ) +
                              "\n          "
                          )
                        ]),
                        _c(
                          "v-container",
                          { staticClass: "pa-0", style: _vm.tabStyle },
                          _vm._l(_vm.history, function(item) {
                            return _c("v-hover", {
                              key: item.id,
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var hover = ref.hover
                                      return [
                                        _c("OpenShiftCard", {
                                          staticClass: "mb-4",
                                          attrs: {
                                            hover: hover,
                                            "open-shift": item
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "open-details",
                                                item
                                              )
                                            }
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            })
                          }),
                          1
                        )
                      ]
                    : [
                        _c(
                          "v-card",
                          { attrs: { outlined: "" } },
                          [
                            _c("NoContent", {
                              attrs: {
                                height: 140,
                                width: 140,
                                message: _vm.$t(
                                  "descriptions.noContentForDate",
                                  {
                                    date: _vm
                                      .moment(_vm.internalDate)
                                      .format(_vm.dateFormatStringWithDoW)
                                  }
                                )
                              }
                            })
                          ],
                          1
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      !_vm.loading && _vm.$can("edit", "openShift")
        ? _c(
            "v-row",
            { staticClass: "create-open-shift", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-4",
                      attrs: { color: "accent" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("create", _vm.internalDate)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("labels.createOpenShift")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }