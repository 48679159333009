exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@charset \"UTF-8\";\n.job-status-selection .all-job-status {\n  -ms-flex-line-pack: center;\n      align-content: center;\n}\n.job-status-selection .all-job-status button, .job-status-selection .all-job-status span {\n  cursor: pointer;\n}\n.job-status-selection .list-container {\n  height: 244px;\n  overflow-y: scroll;\n}\n.job-status-selection-fieldset {\n  background-color: white;\n  position: relative;\n}\n.job-status-selection-fieldset .dropdown-icon {\n  position: absolute;\n  right: 18px;\n  top: 5px;\n}\n.job-status-selection-fieldset .dropdown-icon.no-selection {\n  top: 10px;\n}", ""]);

// exports
exports.locals = {
	"bodyFontFamily": "\"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Helvetica Neue\", \"Microsoft YaHei\", 微软雅黑, STXihei, 华文细黑, \"WenQuanYi Micro Hei\", 文泉驿微米黑, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\"",
	"error": "#E74C3C"
};