<template>
  <v-container
    class="shift-details px-8 py-0"
  >
    <template>
      <v-list-item class="headline page-title pa-0">
        <v-list-item-icon class="icon">
          <v-icon>fal fa-calendar</v-icon>
        </v-list-item-icon>
        <v-list-item-content class="panel-title">
          {{ $t('labels.scheduleDetails') }}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-card
        class="mb-5"
        outlined
        width="100%"
      >
        <v-container class="py-0">
          <v-row>
            <v-col
              class="pb-0"
              cols="12"
            >
              <span class="grey--text text--darken-3 body-2 font-weight-medium mr-3">
                <UserName
                  :user="latestUser"
                />
              </span>
              <v-icon
                :color="userState.color"
                size="8"
              >
                fas fa-circle
              </v-icon>
              <span class="caption grey--text text--darken-2">
                {{ userState.text }}
              </span>
            </v-col>
          </v-row>
          <v-row
            class="px-0 mb-2"
            no-gutters
          >
            <v-col class="caption grey--text text--darken-1">
              <template v-for="(detail, idx) in userDetails">
                <div
                  :key="`${detail}`"
                  class="d-inline-block"
                >
                  {{ detail }}
                </div>
                <v-divider
                  v-if="idx < userDetails.length - 1"
                  :key="`${detail}-div`"
                  class="separator mx-2 d-inline pb-1"
                  vertical
                />
              </template>
              <div
                v-if="latestUser.charge"
                class="pt-1"
              >
                <v-icon
                  class="pr-1"
                  x-small
                >
                  fal fa-check
                </v-icon>
                {{ $t('labels.canActAsCharge') }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-tabs
          v-if="shifts.length > 1"
          id="shifts"
          v-model="tab"
          centered
          class="dense"
          color="accent"
          grow
        >
          <template v-for="(shift, idx) in shifts">
            <v-tab
              :key="`tab-${idx}`"
              :href="`#${idx}`"
            >
              <div class="shift-time">
                <span
                  :class="[shift.overtime ? 'error--text' : '']"
                >
                  {{ getShiftStartTime(shift) }}
                </span>
                <span class="px-1">
                  -
                </span>
                <span
                  :class="[shift.overtime ? 'error--text' : '']"
                >
                  {{ getShiftEndTime(shift) }}
                </span>
                <v-icon
                  v-if="hasChanges"
                  color="info"
                  x-small
                >
                  fal fa-exclamation-triangle
                </v-icon>
              </div>
              <div class="text-capitalize caption">
                {{ getShiftWorkingStatus(shift) }}
              </div>
            </v-tab>
            <v-tab-item
              :key="`tab-item--${idx}`"
              :value="`${idx}`"
            >
              <ShiftActivity
                :offset="offset"
                :shift="shift"
                :read-only="readOnly"
                :user="user"
                @removed="removeShift"
                @updated="(data) => shiftUpdated(data, idx)"
                @has-changes="(hasChanges) => trackChanges(hasChanges, shift.id)"
              />
            </v-tab-item>
          </template>
        </v-tabs>
        <template v-else>
          <v-divider class="mt-0" />
          <v-container class="pa-0 d-inline-flex">
            <v-container class="py-0 grey--text text--darken-3 body-2 py-2 d-inline-block">
              <div class="shift-time">
                <span
                  :class="[shifts[0].overtime ? 'error--text' : '']"
                >
                  {{ getShiftStartTime(shifts[0]) }}
                </span>
                <span class="px-1">
                  -
                </span>
                <span
                  :class="[shifts[0].overtime ? 'error--text' : '']"
                >
                  {{ getShiftEndTime(shifts[0]) }}
                </span>
                <v-icon
                  v-if="hasChanges"
                  color="info"
                  x-small
                >
                  fal fa-exclamation-triangle
                </v-icon>
              </div>
              <span class="text-capitalize grey--text text--darken-3 caption">
                {{ getShiftWorkingStatus(shifts[0]) }}
              </span>
            </v-container>
          </v-container>
          <v-divider class="mt-0" />
          <ShiftActivity
            :offset="offset"
            :shift="shifts[0]"
            :read-only="readOnly"
            :user="user"
            @removed="removeShift"
            @updated="(data) => shiftUpdated(data, 0)"
            @has-changes="(hasChanges) => trackChanges(hasChanges, shifts[0].id)"
          />
        </template>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import ShiftActivity from '@/views/scheduling/schedule_template/ShiftActivity';
import { isWorkingShiftForDisplay } from '@/utils/scheduling';
import { getUnsavedChangesDialogProps } from '@/utils/ui';
import UserName from '@/components/scheduling/UserName';

export default {
  components: {
    ShiftActivity,
    UserName
  },
  props: {
    offset: {
      type: Number,
      required: true
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    selectedShiftIndex: {
      default: 0,
      type: Number
    },
    shifts: {
      default: function () {
        return [];
      },
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      hasChanges: false,
      refresh: false,
      selectedShift: null,
      tab: String(this.selectedShiftIndex),
      height: 500
    };
  },
  computed: {
    hasUnsavedShift () {
      return _.filter(this.shifts, (s) => !s.id).length > 0;
    },
    headers () {
      return [
        { sortable: false, text: '', value: 'shift' }
      ];
    },
    latestUser () {
      return this.$store.state.org.employees[this.user.userId];
    },
    shiftFlags () {
      return this.$store.state.org.flags.reduce((flags, value) => {
        flags[value.id] = value;
        return flags;
      }, {});
    },
    shiftName () {
      const shiftType = this.getShiftTypeById(this.shifts[0].typeId);
      return shiftType.name;
    },
    userDetails () {
      return [
        this.latestUser.jobTypeName,
        this.latestUser.jobStatusName,
        this.latestUser.shiftTypeName
      ];
    },
    userState () {
      const colors = {
        active: 'success',
        inactive: 'grey'
      };
      let color = colors[this.latestUser.state];
      let text = this.$t(`labels.${this.latestUser.state}`);
      return {
        color,
        text
      };
    }
  },
  mounted: function () {
    this.updateHeight();
    window.addEventListener('resize', _.debounce(this.updateHeight, 200));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateHeight);
  },
  methods: {
    close () {
      // hasChanges has the shift ID value and the value can be null for new shifts so have to
      // explicitily check for boolean
      if (this.hasChanges !== false) {
        this.$dialog.confirm(getUnsavedChangesDialogProps(this)).then(() => {}).catch(() => {
          this.$store.commit('unmark_unsaved_changes', this);
          this.$emit('has-changes', false);
          this.$emit('close');
        });
      } else {
        this.$emit('close');
      }
    },
    customGroup (items, groupBy) {
      const sortedItems = this.sortShifts(items);
      const key = groupBy[0];
      const groups = [];
      for (let i = 0, count = sortedItems.length; i < count; i++) {
        groups.push(
          {
            name: sortedItems[i][key],
            items: [sortedItems[i]]
          }
        );
      }
      return groups;
    },
    dispatch (action, payload) {
      // This function is added mainly for easy of mocking during in unit tests.
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    getShiftEndTime (shift) {
      let time = '';
      if (shift) {
        const shiftType = this.getShiftTypeById(shift.typeId);
        const end = shift.endTime ? shift.endTime : shiftType.endTime;
        time = _.split(end, ':', 2).join(':');
      }

      return time;
    },
    getShiftStartTime (shift) {
      let time = '';
      if (shift) {
        const shiftType = this.getShiftTypeById(shift.typeId);
        const start = shift.startTime ? shift.startTime : shiftType.startTime;
        time = _.split(start, ':', 2).join(':');
      }

      return time;
    },
    getShiftWorkingStatus (shift) {
      let status = this.$t('labels.onDuty');
      if (shift && this.isShiftNonDuty(shift)) {
        if (shift.canceled) {
          status = this.$t('labels.canceled');
        } else if (shift.giveaway) {
          status = this.$t('labels.giveaway');
        } else if (shift.swapped) {
          status = this.$t('labels.swapped');
        } else {
          status = this.$t('labels.nonDuty');
        }
      } else if (shift && shift.onCall) {
        status = this.$t('labels.onCall');
      }

      return status;
    },
    isShiftNonDuty (shift) {
      return !isWorkingShiftForDisplay(shift, this.shiftFlags);
    },
    moment,
    removeShift (shift) {
      this.hasChanges = false;
      this.$store.commit('unmark_unsaved_changes', this);
      this.$emit('has-changes', false);
      this.$emit('removed', shift);
    },
    sortShifts (shifts) {
      return _.sortBy(shifts, [
        (shift) => {
          const shiftType = this.getShiftTypeById(shift.typeId);
          const shiftStartTime = shift.startTime ? shift.startTime : shiftType.startTime;
          let day = moment(shiftStartTime, 'HH:mm:ss');
          if (shiftStartTime < shiftType.startTime) {
            day.add(1, 'day').valueOf();
          }

          return day.valueOf();
        }
      ]);
    },
    trackChanges (hasChanges, id) {
      if (hasChanges) {
        this.hasChanges = true;
        this.$store.commit('mark_unsaved_changes', this);
      } else {
        this.hasChanges = false;
        this.$store.commit('unmark_unsaved_changes', this);
      }
      this.$emit('has-changes', hasChanges);
    },
    updateHeight () {
      if (this.$el.parentElement) {
        this.height = this.$el.parentElement.clientHeight;
      }
    },
    shiftUpdated (shift, index) {
      this.hasChanges = false;
      this.$store.commit('unmark_unsaved_changes', this);
      Vue.set(this.shifts, index, shift);
      this.refresh = !this.refresh;
      this.$emit('has-changes', false);
      this.$emit('updated', this.shifts);
    }
  }
};
</script>

<style lang="scss">
.shift-details {
  @include shift-activity();
  .user {
    flex: none;
    width: 156px;
  }

  .v-tabs {
    .v-tabs-bar {
      border-bottom: 1px solid map-get($grey, 'lighten-2') !important;
      border-top: 1px solid map-get($grey, 'lighten-2') !important;
      height: 56px;
      margin-right: 0px !important;
      .v-tab {
        display: inline-block;
        margin-left: 0px;
        padding-left: 12px;
        padding-top: 10px;
        text-align: left;
        &.v-tab--active {
          color: map-get($grey, 'darken-3') !important;
        }
      }
    }
  }
  .shift-time > span {
    vertical-align: middle;
  }
}
</style>
