<template>
  <v-card
    class="mb-5"
    :elevation="hover ? 2 : 0"
    outlined
  >
    <v-list
      class="pa-0"
      flat
    >
      <v-list-item-group>
        <v-list-item
          two-line
          @click="$emit('click', request)"
        >
          <v-icon
            class="mr-10"
            :color="style.color"
            size="14"
          >
            {{ style.icon }}
          </v-icon>
          <v-list-item-content>
            <span class="body-2 grey--text text--darken-3">
              {{ $tc('labels.split', 1) }}
            </span>
            <span class="caption grey--text text--darken-2">
              {{ $t('labels.shiftRequester', { requester: userDisplayName }) }}
            </span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>
<script>
export default {
  props: {
    hover: {
      default: false,
      type: Boolean
    },
    request: {
      type: Object,
      required: true
    },
    scheduleId: {
      default: 0,
      type: [Number, String]
    }
  },
  computed: {
    style () {
      let style = {
        icon: 'far fa-calendar-check',
        color: 'warning'
      };
      return style;
    },
    userDisplayName () {
      return this.$store.state.org.employees[this.request.assigneeId].fullName;
    }
  }
};
</script>
