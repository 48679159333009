var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 py-1", attrs: { id: "AddEvent" } },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title py-0 px-8" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-plus")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$tc("labels.addEvent", 1)) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mx-8 pa-2 select-event", attrs: { outlined: "" } },
        [
          _c(
            "v-row",
            { staticClass: "px-1", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "subtitle-2 py-2", attrs: { cols: "auto" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.selectEvent")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _c("v-select", {
            staticClass: "event-type mt-2 px-1",
            attrs: {
              dense: "",
              "hide-details": "",
              "item-text": "name",
              "item-value": "id",
              items: _vm.eventTypes,
              placeholder: _vm.$tc("labels.event", 1),
              outlined: ""
            },
            model: {
              value: _vm.typeId,
              callback: function($$v) {
                _vm.typeId = $$v
              },
              expression: "typeId"
            }
          }),
          _c(
            "v-menu",
            {
              ref: "rangeDatePicker",
              attrs: {
                "close-on-content-click": false,
                "return-value": _vm.dates,
                "offset-y": "",
                "max-width": "290px",
                "min-width": "290px",
                "nudge-right": "40px",
                "nudge-top": "24px"
              },
              on: {
                "update:returnValue": function($event) {
                  _vm.dates = $event
                },
                "update:return-value": function($event) {
                  _vm.dates = $event
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    var attrs = ref.attrs
                    return [
                      _c(
                        "v-container",
                        { staticClass: "px-0 pb-0" },
                        [
                          _c(
                            "v-row",
                            { staticClass: "px-1" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-combobox",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "event-dates mt-0 pt-0",
                                          attrs: {
                                            "append-icon":
                                              "fal fa-calendar-alt",
                                            color: "secondary",
                                            multiple: "",
                                            chips: "",
                                            dense: "",
                                            "hide-details": "",
                                            label: _vm.$tc("labels.date", 2),
                                            outlined: "",
                                            "small-chips": "",
                                            readonly: ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function(data) {
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            key: data.item,
                                                            staticClass:
                                                              "date-chip grey--text text--darken-1 mb-1",
                                                            attrs: {
                                                              color:
                                                                "grey lighten-3",
                                                              small: ""
                                                            }
                                                          },
                                                          "v-chip",
                                                          data.attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                data.item
                                                              )
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.dates,
                                            callback: function($$v) {
                                              _vm.dates = $$v
                                            },
                                            expression: "dates"
                                          }
                                        },
                                        "v-combobox",
                                        attrs,
                                        false
                                      ),
                                      Object.assign({}, on, {
                                        "click:append": function(e) {
                                          return on.click(e)
                                        }
                                      })
                                    )
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.showDatePicker,
                callback: function($$v) {
                  _vm.showDatePicker = $$v
                },
                expression: "showDatePicker"
              }
            },
            [
              _c(
                "v-date-picker",
                {
                  staticClass: "schedule-details-date-range-picker",
                  attrs: { multiple: "", "no-title": "", scrollable: "" },
                  on: { "click:date": _vm.updateStaffSelectionHeight },
                  model: {
                    value: _vm.dates,
                    callback: function($$v) {
                      _vm.dates = $$v
                    },
                    expression: "dates"
                  }
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.showDatePicker = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.cancel")) +
                          "\n        "
                      )
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.rangeDatePicker.save(_vm.dates)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("labels.ok")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("StaffSelection", {
        staticClass: "px-8 mt-4",
        attrs: {
          date: _vm.date,
          height: _vm.height,
          "selected-staff": _vm.selectedStaff,
          "show-flout-in": false,
          "show-flout-out": false,
          type: "event"
        },
        on: {
          "add-staff": _vm.setAssigneeId,
          "undo-staff": _vm.unsetAssigneeId
        }
      }),
      _vm.$can("edit", "event")
        ? _c(
            "v-row",
            { staticClass: "create-event px-8" },
            [
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        block: "",
                        color: "accent",
                        disabled: _vm.hasInvalidData || _vm.creating
                      },
                      on: { click: _vm.create }
                    },
                    [
                      _vm.creating
                        ? _c("v-progress-circular", {
                            attrs: {
                              color: "primary lighten-2",
                              indeterminate: "",
                              size: "22",
                              width: "2"
                            }
                          })
                        : _c("span", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("labels.add")) +
                                "\n        "
                            )
                          ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }