<template>
  <v-container
    id="openShiftsContainer"
    class="px-8 py-1"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-list</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $tc('labels.openShift', 2) }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-container
      class="pa-0"
    >
      <v-row v-if="allowSelectingDate">
        <v-menu
          v-model="showDatePicker"
          :close-on-content-click="false"
          offset-y
          max-width="290px"
          min-width="auto"
          nudge-right="12px"
          nudge-top="26px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="px-3"
              :label="$tc('labels.date', 1)"
              append-icon="fal fa-calendar-alt"
              dense
              outlined
              readonly
              :value="moment(internalDate).format(dateFormatStringWithDoW)"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="internalDate"
            :max="maxDate"
            :min="minDate"
            no-title
            @input="showDatePicker = false"
          />
        </v-menu>
      </v-row>
      <v-row v-else>
        <v-col
          class="secondary--text body-2"
          cols="12"
        >
          {{ moment(internalDate).format(dateFormatStringWithDoW) }}
        </v-col>
      </v-row>
      <v-tabs
        id="openShiftList"
        v-model="tab"
        centered
        class="dense"
        grow
        hide-slider
      >
        <v-tab
          class="text-capitalize"
          href="#opened"
        >
          {{ $t('labels.open') }}
        </v-tab>
        <v-tab
          class="text-capitalize"
          href="#history"
        >
          {{ $t('labels.history') }}
        </v-tab>
        <v-tab-item
          value="opened"
        >
          <template
            v-if="loading"
          >
            <v-row
              align="center"
              :style="tabStyle"
            >
              <v-spacer />
              <v-col cols="12">
                <v-row class="text-center">
                  <v-col class="text-center">
                    <v-progress-circular
                      color="info"
                      indeterminate
                      size="75"
                      width="6"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <span>{{ $t('descriptions.loading') }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer />
            </v-row>
          </template>
          <template v-else-if="opened.length > 0">
            <div class="caption grey--text mb-2">
              {{ $t('labels.totalCount', { count: opened.length }) }}
            </div>
            <v-container
              class="pa-0"
              :style="tabStyle"
            >
              <v-hover
                v-for="(item) in opened"
                :key="item.id"
                v-slot:default="{ hover }"
              >
                <OpenShiftCard
                  class="mb-4"
                  :hover="hover"
                  :open-shift="item"
                  @click="$emit('open-details', item)"
                />
              </v-hover>
            </v-container>
          </template>
          <template v-else>
            <v-card
              outlined
            >
              <NoContent
                :height="140"
                :width="140"
                :message="$t('descriptions.noContentForDate', { date: moment(internalDate).format(dateFormatStringWithDoW) })"
              />
            </v-card>
          </template>
        </v-tab-item>
        <v-tab-item
          value="history"
        >
          <template
            v-if="loading"
          >
            <v-row
              align="center"
              :style="tabStyle"
            >
              <v-spacer />
              <v-col cols="12">
                <v-row class="text-center">
                  <v-col class="text-center">
                    <v-progress-circular
                      color="info"
                      indeterminate
                      size="75"
                      width="6"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">
                    <span>{{ $t('descriptions.loading') }}</span>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer />
            </v-row>
          </template>
          <template v-else-if="history.length > 0">
            <div class="caption grey--text mb-2">
              {{ $t('labels.totalCount', { count: history.length }) }}
            </div>
            <v-container
              class="pa-0"
              :style="tabStyle"
            >
              <v-hover
                v-for="(item) in history"
                :key="item.id"
                v-slot:default="{ hover }"
              >
                <OpenShiftCard
                  class="mb-4"
                  :hover="hover"
                  :open-shift="item"
                  @click="$emit('open-details', item)"
                />
              </v-hover>
            </v-container>
          </template>
          <template v-else>
            <v-card
              outlined
            >
              <NoContent
                :height="140"
                :width="140"
                :message="$t('descriptions.noContentForDate', { date: moment(internalDate).format(dateFormatStringWithDoW) })"
              />
            </v-card>
          </template>
        </v-tab-item>
      </v-tabs>
    </v-container>
    <v-row
      v-if="!loading && $can('edit', 'openShift')"
      class="create-open-shift"
      no-gutters
    >
      <v-col cols="12">
        <v-btn
          class="px-4"
          color="accent"
          @click="$emit('create', internalDate)"
        >
          {{ $t('labels.createOpenShift') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import NoContent from '@/components/NoContent';
import OpenShiftCard from '@/views/scheduling/cards/OpenShiftCard';
import { RequestCanceledError } from '@/services/errors';
import { showStatus } from '@/plugins/vue-notification';
import { DATE_FORMAT } from '@/utils/ui';
import { CONTENT_TYPES } from '@/services/constants';

export default {
  components: {
    NoContent,
    OpenShiftCard
  },
  props: {
    allowSelectingDate: {
      default: false,
      type: Boolean
    },
    date: {
      default: function () {
        return moment().format(DATE_FORMAT);
      },
      type: String
    },
    departmentIds: {
      default: function () {
        return [];
      },
      type: Array
    },
    jobTypeIds: {
      default: function () {
        return [];
      },
      type: Array
    },
    maxDate: {
      default: '',
      type: String
    },
    minDate: {
      default: '',
      type: String
    },
    shiftTypeIds: {
      default: function () {
        return [];
      },
      type: Array
    }
  },
  data () {
    return {
      history: [],
      internalDate: this.date,
      loading: true,
      opened: [],
      showDatePicker: false,
      tab: this.$store.state.scheduling.panels.openShiftList.tab,
      tabHeight: 500
    };
  },
  computed: {
    dateFormatStringWithDoW () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    tabStyle () {
      return {
        'height': `${this.tabHeight - 86}px`, // Subtract padding for the header and footer
        'overflow-y': 'auto'
      };
    }
  },
  watch: {
    date (date) {
      this.internalDate = date;
    },
    internalDate () {
      this.load();
    },
    tab (tab) {
      this.$store.commit('scheduling/update_panel', { panel: 'openShiftList', prop: 'tab', value: tab });
    }
  },
  mounted: function () {
    this.updateTabHeight();
    this.load();
    window.addEventListener('resize', _.debounce(this.updateTabHeight, 500));
    this.$store.commit(
      'add_ws_update_callback',
      { name: 'open_shift_list', callback: this.onWsUpdate },
      { root: true }
    );
  },
  beforeDestroy: function () {
    this.$store.commit(
      'remove_ws_update_callback',
      'open_shift_list',
      { root: true }
    );
    window.removeEventListener('resize', this.updateTabHeight);
  },
  methods: {
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    load () {
      if (!this.$can('view', 'openShift')) {
        this.loading = false;
        return;
      }
      this.loading = true;
      const open = {
        date: this.internalDate,
        department_ids: this.departmentIds.join(','),
        open: 'true',
        self_schedule_only: 'false'
      };
      const history = {
        date: this.internalDate,
        department_ids: this.departmentIds.join(','),
        open: 'false',
        self_schedule_only: 'false'
      };
      if (this.jobTypeIds.length > 0) {
        open.job_type_ids = this.jobTypeIds.join(',');
        history.job_type_ids = this.jobTypeIds.join(',');
      }
      if (this.shiftTypeIds.length > 0) {
        open.shift_type_ids = this.shiftTypeIds.join(',');
        history.shift_type_ids = this.shiftTypeIds.join(',');
      }
      Promise.all([
        this.dispatch('scheduling/retrieveOpenShifts', open),
        this.dispatch('scheduling/retrieveOpenShifts', history)
      ]).then((response) => {
        this.opened = response[0];
        this.history = response[1];
        this.loading = false;
      }).catch(error => {
        if (!(error instanceof RequestCanceledError)) {
          const responseData = {
            error: _.get(error, 'response.data')
          };

          showStatus({
            text: this.$tc('descriptions.openShiftRetrievalFail', 2),
            type: 'error',
            responseData
          });
          this.loading = false;
        }
      });
    },
    markEnded (openShift) {
      openShift.biddingEnded = true;
    },
    moment,
    onOpenShiftUpdateReceived (event) {
      const { action, bidders, id } = JSON.parse(event.data);
      let index = _.findIndex(this.opened, (os) => os.id === id);
      if (action === 'deleted') {
        if (index >= 0) {
          this.opened.splice(index, 1);
        } else {
          index = _.findIndex(this.history, (os) => os.id === id);
          if (index >= 0) {
            this.history.splice(index, 1);
          }
        }
      } else {
        if (index >= 0) {
          if (['closed', 'ended'].includes(action)) {
            this.dispatch('scheduling/retrieveOpenShift', { id, source: 'list' }).then(openShift => {
              this.opened.splice(index, 1);
              this.history.splice(0, 0, openShift);
            }).catch(() => {});
          } else if (action === 'bid') {
            this.opened[index].bidders.push(...bidders);
          } else if (action === 'cancel_bid') {
            this.opened[index].bidders = _.difference(this.opened[index].bidders, bidders);
          }
        }
      }
    },
    onWsUpdate (eventInfo) {
      const data = JSON.parse(eventInfo.data);
      const contentType = data.content_type;
      switch (contentType) {
        case CONTENT_TYPES.openShift:
          this.onOpenShiftUpdateReceived(eventInfo);
          break;
      }
    },
    updateTabHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const openShiftList = document.getElementById('openShiftList');
      if (el && openShiftList) {
        this.tabHeight = el.clientHeight - openShiftList.getBoundingClientRect().top;
      }
    }
  }
};
</script>

<style lang="scss">
#openShiftsContainer {
  @include round-tabs(#837EB7, #FFF);

  .create-open-shift {
    bottom: 32px;
    position: absolute;
    right: 32px;
  }
}
</style>
