var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "schedule-details px-0 py-0" },
    [
      _vm.isShift && _vm.showHistory
        ? [
            _c(
              "v-list-item",
              { staticClass: "headline page-title  py-0 px-8" },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "icon" },
                  [
                    _c(
                      "v-btn",
                      { attrs: { icon: "" }, on: { click: _vm.closeHistory } },
                      [_c("v-icon", [_vm._v("fal fa-arrow-left")])],
                      1
                    )
                  ],
                  1
                ),
                _c("v-list-item-content", { staticClass: "panel-title" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("labels.history")) + "\n      "
                  )
                ])
              ],
              1
            ),
            _c("ShiftHistory", {
              staticClass: "px-8",
              style: { height: _vm.height - 100 + "px" },
              attrs: { shift: _vm.shift }
            })
          ]
        : _vm.isShift && _vm.showErrors
        ? [
            _c(
              "v-list-item",
              { staticClass: "headline page-title  py-0 px-8" },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "icon" },
                  [
                    _c(
                      "v-btn",
                      { attrs: { icon: "" }, on: { click: _vm.closeErrors } },
                      [_c("v-icon", [_vm._v("fal fa-arrow-left")])],
                      1
                    )
                  ],
                  1
                ),
                _c("v-list-item-content", { staticClass: "panel-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tc("labels.error", 2)) +
                      "\n      "
                  )
                ])
              ],
              1
            ),
            _c("UserWeeklySummary", {
              staticClass: "px-8",
              style: { height: _vm.height - 100 + "px" },
              attrs: {
                header: {},
                errors: _vm.errors,
                "show-header": false,
                user: _vm.user
              }
            })
          ]
        : [
            _c(
              "v-list-item",
              { staticClass: "headline page-title py-0 px-8" },
              [
                _c(
                  "v-list-item-icon",
                  { staticClass: "icon" },
                  [_c("v-icon", [_vm._v("fal fa-calendar")])],
                  1
                ),
                _c("v-list-item-content", { staticClass: "panel-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.scheduleDetails")) +
                      "\n      "
                  )
                ]),
                _c(
                  "v-list-item-action",
                  [
                    _c(
                      "v-btn",
                      { attrs: { icon: "" }, on: { click: _vm.close } },
                      [_c("v-icon", [_vm._v("fal fa-times")])],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.isShift && _vm.errors.length > 0 && _vm.canSeeErrors
              ? _c(
                  "v-alert",
                  {
                    staticClass: "caption dense font-weight-medium mx-8",
                    attrs: {
                      color: "warning",
                      dense: "",
                      outlined: "",
                      text: ""
                    }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "ml-n1 mr-3",
                        attrs: {
                          slot: "prepend",
                          color: "warning",
                          size: "12"
                        },
                        slot: "prepend"
                      },
                      [_vm._v("\n        fas fa-exclamation-triangle\n      ")]
                    ),
                    _c(
                      "v-row",
                      { attrs: { dense: "" } },
                      [
                        _c("v-col", { attrs: { cols: "10" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("descriptions.staffErrorsPanel")) +
                              "\n        "
                          )
                        ]),
                        _c(
                          "v-col",
                          { attrs: { cols: "2", "align-self": "center" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "show-errors",
                                attrs: { color: "warning", "x-small": "" },
                                on: { click: _vm.openErrors }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("labels.view")) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isShift
              ? _c(
                  "v-card",
                  { staticClass: "mx-8", attrs: { outlined: "" } },
                  [
                    _c(
                      "v-container",
                      { staticClass: "py-0" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "pb-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "grey--text text--darken-3 body-2 font-weight-medium mr-3"
                                  },
                                  [
                                    _c("UserName", {
                                      attrs: { user: _vm.latestUser }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      color: _vm.userState.color,
                                      size: "8"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              fas fa-circle\n            "
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "caption grey--text text--darken-2"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.userState.text) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          {
                            staticClass: "px-0 mb-2",
                            attrs: { "no-gutters": "" }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "caption grey--text text--darken-1"
                              },
                              [
                                _vm._l(_vm.userDetails, function(detail, idx) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        key: "" + detail,
                                        staticClass: "d-inline-block"
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(detail) +
                                            "\n              "
                                        )
                                      ]
                                    ),
                                    idx < _vm.userDetails.length - 1
                                      ? _c("v-divider", {
                                          key: detail + "-div",
                                          staticClass:
                                            "separator mx-2 d-inline pb-1",
                                          attrs: { vertical: "" }
                                        })
                                      : _vm._e()
                                  ]
                                }),
                                _vm.latestUser.charge
                                  ? _c(
                                      "div",
                                      { staticClass: "pt-1" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "pr-1",
                                            attrs: { "x-small": "" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                fal fa-check\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t("labels.canActAsCharge")
                                            ) +
                                            "\n            "
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-divider", { staticClass: "mt-0" }),
                    _c(
                      "v-container",
                      {
                        staticClass:
                          "py-0 grey--text text--darken-3 body-2 py-2"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "shift-time" },
                          [
                            _c(
                              "span",
                              {
                                class: [_vm.shift.overtime ? "error--text" : ""]
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.getShiftStartTime(_vm.shift)) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm.getShiftStartTime(_vm.shift)
                              ? _c("span", { staticClass: "px-1" }, [
                                  _vm._v("\n            -\n          ")
                                ])
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                class: [_vm.shift.overtime ? "error--text" : ""]
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.getShiftEndTime(_vm.shift)) +
                                    "\n          "
                                )
                              ]
                            ),
                            _vm.hasDifferentPayrollDate
                              ? _c(
                                  "v-icon",
                                  {
                                    staticClass:
                                      "payroll-diff text--darken-3 mx-1",
                                    attrs: { color: "grey", "x-small": "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n            fas fa-level-up\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.hasChanges
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { right: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "info",
                                                        "x-small": ""
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                fal fa-exclamation-triangle\n              "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1848313783
                                    )
                                  },
                                  [
                                    _c("span", { staticClass: "body-2" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("labels.unsavedChanges")
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-capitalize grey--text text--darken-3 caption"
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getShiftWorkingStatus(_vm.shift)) +
                                "\n        "
                            )
                          ]
                        )
                      ]
                    ),
                    _c("v-divider", { staticClass: "mt-0" }),
                    _c("ShiftActivity", {
                      attrs: {
                        "allow-delete": _vm.allowDelete,
                        changes: _vm.changes,
                        date: _vm.date,
                        shift: _vm.shift,
                        "read-only": _vm.readOnly,
                        "update-on-change": true,
                        user: _vm.user
                      },
                      on: {
                        created: _vm.createShift,
                        "canceled-create": _vm.canceledCreate,
                        change: _vm.syncShift,
                        updated: _vm.updateShift,
                        "split-shift": _vm.splitShift,
                        "show-history": _vm.openHistory,
                        "has-changes": _vm.trackChanges,
                        removed: function($event) {
                          return _vm.$emit("removed", _vm.shift)
                        }
                      }
                    }),
                    _c("SplitShiftDialog", {
                      attrs: {
                        date: _vm.date,
                        shift: _vm.shift,
                        show: _vm.showSplitShiftDialog,
                        user: _vm.user
                      },
                      on: {
                        close: _vm.hideSplitShiftDialog,
                        saved: _vm.splitSaved
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }