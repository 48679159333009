<template>
  <v-container
    class="ga-request pa-0"
  >
    <v-row
      class="d-inline-block"
      dense
    >
      <v-col
        class="details"
        cols="12"
      >
        <v-list-item
          class="px-0 d-inline-block"
        >
          <v-list-item-content>
            <v-list-item-title class="body-2 mb-1">
              <UserName
                :user="$store.state.org.employees[request.assocContent.assigneeId]"
              />
            </v-list-item-title>
            <v-list-item-subtitle class="caption mt-1">
              {{ getJobInfo(request.assocContent.assigneeId) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <div class="d-inline-flex">
          <span class="grey--text text--darken-3">
            {{ department }}
          </span>
          <v-divider
            class="d-inline mx-2"
            vertical
          />
          <span class="grey--text text--darken-3">
            {{ shiftType }}
          </span>
          <v-icon
            class="mx-9"
            color="warning"
            size="20"
          >
            fal fa-long-arrow-right
          </v-icon>
        </div>
        <v-list-item
          class="px-0 d-inline-block"
        >
          <v-list-item-content>
            <v-list-item-title class="body-2 mb-1">
              <UserName
                :user="$store.state.org.employees[request.assocContent.newAssigneeId]"
              />
            </v-list-item-title>
            <v-list-item-subtitle class="caption mt-1">
              {{ getJobInfo(request.assocContent.newAssigneeId) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserName from '@/components/scheduling/UserName';

export default {
  components: {
    UserName
  },
  props: {
    request: {
      required: true,
      type: Object
    }
  },
  data () {
    return {};
  },
  computed: {
    department () {
      return this.$store.getters['org/getDepartmentById'](this.request.assocContent.departmentId, 'name');
    },
    shiftType () {
      return this.$store.getters['org/getShiftTypeById'](this.request.assocContent.typeId, 'name');
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    }
  },
  mounted () {},
  methods: {
    getJobInfo (userId) {
      const userInfo = this.$store.state.org.employees[userId];
      return [userInfo.jobTypeName, userInfo.jobStatusShortCode].filter(Boolean).join(' ');
    }
  }
};
</script>

<style lang="scss">
.ga-request {
  .details {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 72px;
  }
}
</style>
