<template>
  <v-container
    class="sp-request pa-0"
  >
    <table class="d-inline-block">
      <tbody>
        <tr
          v-for="(split, idx) in splits"
          :key="split.id"
          dense
        >
          <td>
            <v-list-item
              class="px-0 d-inline-block"
              :style="{ visibility: idx === 0 ? 'visible' : 'hidden' }"
            >
              <v-list-item-content>
                <v-list-item-title class="body-2 mb-1">
                  <UserName
                    :user="$store.state.org.employees[request.assocContent.assigneeId]"
                  />
                </v-list-item-title>
                <v-list-item-subtitle class="caption mt-1">
                  {{ getJobInfo(request.assocContent.assigneeId) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </td>
          <td>
            <span
              class="grey--text text--darken-3"
              :style="{ visibility: idx === 0 ? 'visible' : 'hidden' }"
            >
              {{ department }}
            </span>
            <v-divider
              class="d-inline mx-2"
              :style="{ visibility: idx === 0 ? 'visible' : 'hidden' }"
              vertical
            />
            <span class="grey--text text--darken-3">
              {{ getSplitTime(split) }}
            </span>
            <v-icon
              class="mx-9"
              color="warning"
              size="20"
            >
              fal fa-long-arrow-right
            </v-icon>
          </td>
          <td>
            <span
              v-if="split.flags.length > 0"
            >
              {{ getSplitFlags(split) }}
            </span>
            <v-list-item
              v-else
              class="px-0 d-inline-block"
            >
              <v-list-item-content>
                <v-list-item-title class="body-2 mb-1">
                  <UserName
                    :user="$store.state.org.employees[split.assigneeId]"
                  />
                </v-list-item-title>
                <v-list-item-subtitle class="caption mt-1">
                  {{ getJobInfo(split.assigneeId) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </td>
        </tr>
      </tbody>
    </table>
  </v-container>
</template>

<script>
import _ from 'lodash';
import UserName from '@/components/scheduling/UserName';

export default {
  components: {
    UserName
  },
  props: {
    request: {
      required: true,
      type: Object
    }
  },
  data () {
    return {};
  },
  computed: {
    department () {
      return this.$store.getters['org/getDepartmentById'](this.request.assocContent.departmentId, 'name');
    },
    flagsById () {
      return this.$store.state.org.flags.reduce(
        (obj, flag) => {
          obj[flag.id] = flag;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    splits () {
      return _.sortBy(this.request.assocContent.splits, ['startTime']);
    }
  },
  mounted () {},
  methods: {
    getJobInfo (userId) {
      const userInfo = this.$store.state.org.employees[userId];
      return [userInfo.jobTypeName, userInfo.jobStatusShortCode].filter(Boolean).join(' ');
    },
    getSplitFlags (split) {
      return split.flags.map((fId) => {
        if (this.flagsById[fId]) {
          return this.flagsById[fId].name;
        }
        return '';
      }).join(', ');
    },
    getSplitTime (split) {
      return `${_.split(split.startTime, ':', 2).join(':')} - ${_.split(split.endTime, ':', 2).join(':')}`;
    }
  }
};
</script>

<style lang="scss">
.sp-request {
  // .details {
  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  //   height: 72px;
  // }
  td {
    border: none !important;
  }
}
</style>
