<template>
  <v-list
    v-if="jobTypeCount > 0"
    dense
  >
    <span class="body-2 grey--text text--darken-3">
      {{ $t('descriptions.imbalanceHint') }}
    </span>
    <template
      v-for="(job, jobIdx) in shiftsWithErrors"
      class="px-0 py-0"
    >
      <v-list-item
        v-for="(info, shiftId, shiftIdx) in job.shifts"
        :key="`${job.id} ${shiftId}`"
      >
        <v-list-item-content>
          <span
            v-if="shiftIdx === 0"
            class="subtitle-2 font-weight-medium d-inline-block text-truncate pr-1"
          >
            {{ job.name }}
          </span>
        </v-list-item-content>
        <v-list-item-content>
          <span
            v-if="shiftTypeCount === 1"
            class="subtitle-2 font-weight-medium d-inline-block text-truncate pr-1"
          >
            {{ shiftName(shiftId) }}
          </span>
          <a
            v-else
            href="javascript:void(0)"
            class="subtitle-2 font-weight-medium d-inline-block text-truncate pr-1"
            @click="$emit('filter', getFilter(job.id, shiftId))"
          >
            {{ shiftName(shiftId) }}
          </a>
        </v-list-item-content>
        <v-list-item-action class="subtitle-2 font-weight-medium d-inline-block text-truncate error--text">
          {{ info.errors }}
        </v-list-item-action>
      </v-list-item>
      <v-divider
        v-if="jobIdx < jobTypeCount - 1"
        :key="`div${job.id}`"
      />
    </template>
  </v-list>
  <NoContent
    v-else
    :height="140"
    :exciting="true"
    :width="140"
    :message="$t('descriptions.imbalanceNoErrors')"
  />
</template>

<script>
import _ from 'lodash';
import NoContent from '@/components/NoContent';

export default {
  components: {
    NoContent
  },
  props: {
    jobTypeIds: {
      type: Array,
      default: function () {
        return [];
      }
    },
    scheduleId: {
      type: [Number, String],
      required: true
    },
    shiftType: {
      type: Number,
      default: null
    },
    validator: {
      type: Object,
      required: true
    }
  },
  computed: {
    jobTypeCount () {
      return this.shiftsWithErrors.length;
    },
    shiftTypeCount () {
      let count = 0;
      for (let i = 0, len = this.shiftsWithErrors.length; i < len; i++) {
        count += _.keys(this.shiftsWithErrors[i].shifts).length;
      }
      return count;
    },
    shiftsWithErrors () {
      const shifts = {};
      if (this.jobTypeIds && this.jobTypeIds.length > 0 && this.shiftType) {
        for (let id of this.jobTypeIds) {
          if (this.state.data[id][this.shiftType].errors > 0) {
            _.setWith(shifts, [id, this.shiftType], this.state.data[id][this.shiftType], Object);
          }
        }
      } else if (this.jobTypeIds && this.jobTypeIds.length > 0) {
        for (let id of this.jobTypeIds) {
          const shiftsInfo = this.state.data[id];
          for (let shiftId in shiftsInfo) {
            if (shiftsInfo[shiftId].errors > 0) {
              _.setWith(shifts, [id, shiftId], shiftsInfo[shiftId], Object);
            }
          }
        }
      } else if (this.shiftType) {
        for (let jobId in this.state.data) {
          if (this.state.data[jobId][this.shiftType] && this.state.data[jobId][this.shiftType].errors > 0) {
            _.setWith(shifts, [jobId, this.shiftType], this.state.data[jobId][this.shiftType], Object);
          }
        }
      } else {
        for (let jobId in this.state.data) {
          for (let shiftId in this.state.data[jobId]) {
            if (this.state.data[jobId][shiftId].errors > 0) {
              _.setWith(shifts, [jobId, shiftId], this.state.data[jobId][shiftId], Object);
            }
          }
        }
      }
      const jobTypes = this.$store.getters['scheduling/getJobTypes'](this.scheduleId);
      const jobIds = {};
      for (let i = 0, count = jobTypes.length; i < count; i++) {
        jobIds[jobTypes[i].id] = {
          idx: i,
          name: jobTypes[i].name
        };
      }
      const sortedJobs = _.sortBy(Object.keys(shifts), [
        function (id) {
          return _.has(jobIds, id) ? jobIds[id].idx : 1000;
        }
      ]);

      const sortedShifts = [];
      for (let i = 0, count = sortedJobs.length; i < count; i++) {
        sortedShifts.push({
          id: sortedJobs[i],
          name: jobIds[sortedJobs[i]].name,
          shifts: shifts[sortedJobs[i]]
        });
      }
      return sortedShifts;
    },
    state () {
      return this.validator.state;
    }
  },
  methods: {
    getFilter (job, shift) {
      let dates = this.validator.invalidDates(job, shift);
      return {
        dates,
        job,
        shift,
        user: null
      };
    },

    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](parseInt(id));
    },

    shiftName (shiftId) {
      return this.getShiftTypeById(shiftId).name;
    },

    shiftSymbol (shiftId) {
      return this.getShiftTypeById(shiftId).styles;
    }
  }
};
</script>

<style lang="scss" >
</style>
