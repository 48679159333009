var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "activity-selection px-8 py-0" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-plus")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("labels.addActivity")) + "\n    ")
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "pa-2", attrs: { outlined: "" } },
        [
          _c(
            "v-row",
            { staticClass: "px-1", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { staticClass: "subtitle-2 py-2", attrs: { cols: "auto" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("labels.selectShift")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm.$can("edit", "template")
            ? _c(
                "v-btn-toggle",
                {
                  staticClass: "shift-types mt-2",
                  attrs: { tile: "", group: "" },
                  on: { change: _vm.addShift },
                  model: {
                    value: _vm.shift.typeId,
                    callback: function($$v) {
                      _vm.$set(_vm.shift, "typeId", $$v)
                    },
                    expression: "shift.typeId"
                  }
                },
                _vm._l(_vm.shiftTypes, function(shiftType) {
                  return _c(
                    "v-btn",
                    {
                      key: ["shift", shiftType.id].join(),
                      class: ["text-capitalize", _vm.shiftTypeClass],
                      attrs: { value: shiftType.id }
                    },
                    [_vm._v("\n        " + _vm._s(shiftType.name) + "\n      ")]
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }