var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.jobTypeCount > 0
    ? _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c("span", { staticClass: "body-2 grey--text text--darken-3" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.$t("descriptions.imbalanceHint")) + "\n  "
            )
          ]),
          _vm._l(_vm.shiftsWithErrors, function(job, jobIdx) {
            return [
              _vm._l(job.shifts, function(info, shiftId, shiftIdx) {
                return _c(
                  "v-list-item",
                  { key: job.id + " " + shiftId },
                  [
                    _c("v-list-item-content", [
                      shiftIdx === 0
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "subtitle-2 font-weight-medium d-inline-block text-truncate pr-1"
                            },
                            [
                              _vm._v(
                                "\n          " + _vm._s(job.name) + "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ]),
                    _c("v-list-item-content", [
                      _vm.shiftTypeCount === 1
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "subtitle-2 font-weight-medium d-inline-block text-truncate pr-1"
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.shiftName(shiftId)) +
                                  "\n        "
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass:
                                "subtitle-2 font-weight-medium d-inline-block text-truncate pr-1",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  _vm.$emit(
                                    "filter",
                                    _vm.getFilter(job.id, shiftId)
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.shiftName(shiftId)) +
                                  "\n        "
                              )
                            ]
                          )
                    ]),
                    _c(
                      "v-list-item-action",
                      {
                        staticClass:
                          "subtitle-2 font-weight-medium d-inline-block text-truncate error--text"
                      },
                      [_vm._v("\n        " + _vm._s(info.errors) + "\n      ")]
                    )
                  ],
                  1
                )
              }),
              jobIdx < _vm.jobTypeCount - 1
                ? _c("v-divider", { key: "div" + job.id })
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _c("NoContent", {
        attrs: {
          height: 140,
          exciting: true,
          width: 140,
          message: _vm.$t("descriptions.imbalanceNoErrors")
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }