<template>
  <v-row
    v-if="loading"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-progress-circular
            color="info"
            indeterminate
            size="75"
            width="6"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('descriptions.loading') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-row
    v-else-if="loadFailed"
    align="center"
    style="height: 100%"
  >
    <v-btn
      icon
      style="position: absolute; top: 12px; right: 32px;"
      @click="$emit('close')"
    >
      <v-icon>fal fa-times</v-icon>
    </v-btn>
    <v-spacer />
    <v-col cols="6">
      <v-row class="text-center">
        <v-col class="text-center">
          <v-img
            contain
            src="@/assets/images/oops-penguin.svg"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center">
          <span>{{ $t('headlines.genericError') }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-spacer />
  </v-row>
  <v-container
    v-else
    id="selfSchedule"
    class="px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-calendar-alt</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.selfSchedule') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card
      id="selfScheduleContent"
      outlined
    >
      <v-toolbar
        dense
        flat
        height="28"
      >
        <v-spacer />
        <v-btn
          class="mr-4"
          color="grey darken-2"
          fab
          height="22"
          text
          width="22"
          @click="prev"
        >
          <v-icon x-small>
            fal fa-chevron-left
          </v-icon>
        </v-btn>
        <v-toolbar-title
          v-if="$refs.calendar"
          class="body-2"
        >
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn
          class="ml-4"
          color="grey darken-2"
          fab
          height="22"
          text
          width="22"
          @click="next"
        >
          <v-icon x-small>
            fal fa-chevron-right
          </v-icon>
        </v-btn>
        <v-spacer />
      </v-toolbar>
      <v-calendar
        ref="calendar"
        v-model="visibleDate"
        color="primary"
        type="month"
      >
        <template #day-label="{ date, day }">
          <div
            :class="markDisabled(date) ? 'disabled': ''"
            @click="markDisabled(date) ? null : onDayPress(date)"
          >
            <div
              class="caption-2 accent--text mt-n1"
              style="height: 20px"
            >
              {{ day === 1 ? moment(date).format('MMM') : '' }}
            </div>
            <v-icon
              v-if="consolidatedItinerary[date] && consolidatedItinerary[date].hasFlags"
              class="flag-indicator"
              size="4"
            >
              fas fa-circle
            </v-icon>
            <v-tooltip
              v-if="consolidatedItinerary[date] && consolidatedItinerary[date].errors"
              max-width="300px"
              top
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  class="validation-error"
                  color="error"
                  x-small
                  v-bind="attrs"
                  v-on="on"
                >
                  fas fa-caret-down
                </v-icon>
              </template>
              <span class="body-2">
                {{ getErrorMessage(consolidatedItinerary[date]) }}
              </span>
            </v-tooltip>
            <v-avatar
              v-if="consolidatedItinerary[date] && consolidatedItinerary[date].hasShift"
              :color="shiftColor"
              class="mt-n1"
              size="28"
              :style="getShiftStyle(consolidatedItinerary[date])"
            >
              <span class="caption grey--text text--darken-3">
                {{ day }}
              </span>
              <template v-if="showSlits(consolidatedItinerary[date])">
                <div class="left-slit" />
                <div class="right-slit" />
              </template>
            </v-avatar>
            <v-avatar
              v-else
              :color="markDisabled(date) ? '' : 'white'"
              class="mt-n1"
              size="28"
            >
              <span class="caption grey--text text--darken-3">
                {{ day }}
              </span>
            </v-avatar>
          </div>
        </template>
        <template v-slot:day="{ past, date }">
          <v-container
            :class="['d-block pa-0 text-center day-slot mt-n1', date < scheduleStartDate || date > scheduleEndDate || (consolidatedItinerary[date] && consolidatedItinerary[date].disabled) ? 'disabled': '']"
            fill-height
          >
            <v-progress-circular
              v-if="consolidatedItinerary[date] && consolidatedItinerary[date].loading"
              class="mt-2"
              color="primary lighten-2"
              indeterminate
              size="22"
              width="2"
            />
            <template v-else>
              <v-chip
                v-if="date >= scheduleStartDate && date <= scheduleEndDate"
                :class="['pill-symbol mt-2']"
                :color="shiftColor"
                :outlined="tab === 'addOnCallShift'"
                :style="{ 'visibility': consolidatedItinerary[date] && consolidatedItinerary[date].canBid ? 'visible' : 'hidden' }"
                x-small
              />
              <template v-if="consolidatedItinerary[date] && consolidatedItinerary[date].hasEvent">
                <div
                  v-if="consolidatedItinerary[date].startingDay && consolidatedItinerary[date].endingDay"
                  class="start-and-end"
                  :style="{ 'background-color': consolidatedItinerary[date].eventColor }"
                />
                <div
                  v-else-if="consolidatedItinerary[date].startingDay && !consolidatedItinerary[date].endingDay"
                  class="start-only"
                  :style="{ 'background-color': consolidatedItinerary[date].eventColor }"
                />
                <div
                  v-else-if="!consolidatedItinerary[date].startingDay && consolidatedItinerary[date].endingDay"
                  class="end-only"
                  :style="{ 'background-color': consolidatedItinerary[date].eventColor }"
                />
                <div
                  v-else
                  class="extend"
                  :style="{ 'background-color': consolidatedItinerary[date].eventColor }"
                />
              </template>
            </template>
          </v-container>
        </template>
      </v-calendar>
      <v-container class="pa-0">
        <v-tabs
          id="selfScheduleAddActivites"
          v-model="tab"
          centered
          class="dense"
          color="accent"
          grow
        >
          <v-tab
            class="text-capitalize"
            :disabled="hasChanges"
            href="#addShift"
          >
            {{ $tc('labels.addShift', 1) }}
          </v-tab>
          <v-tab
            v-if="allowOnCallShifts"
            class="text-capitalize"
            :disabled="hasChanges"
            href="#addOnCallShift"
          >
            {{ $t('labels.addOnCallShift') }}
          </v-tab>
          <v-tab
            v-if="allowAvailableShifts"
            class="text-capitalize"
            :disabled="hasChanges"
            href="#addAvailableShift"
          >
            {{ $t('labels.markAvailability') }}
          </v-tab>
          <v-tab
            class="text-capitalize"
            :disabled="hasChanges"
            href="#addEvent"
          >
            {{ $tc('labels.addEvent', 1) }}
          </v-tab>
          <v-tab-item
            class="pa-4"
            value="addShift"
          >
            <v-alert
              class="caption dense font-weight-medium mx-0"
              color="info"
              dense
              outlined
              text
            >
              <v-icon
                slot="prepend"
                class="ml-n1 mr-3"
                color="info"
                size="12"
              >
                fas fa-info-circle
              </v-icon>
              {{ $t('descriptions.selfScheduleAddShift') }}
            </v-alert>
            <v-select
              ref="selectShift"
              v-model="shiftType"
              dense
              hide-details
              item-text="name"
              item-value="id"
              :items="shiftTypes"
              :label="$t('labels.shift')"
              outlined
            />
            <v-container
              v-if="!bidForOpening"
              class="px-0 pb-0 text-right"
            >
              <v-btn
                class="save-shifts"
                color="secondary"
                :disabled="!hasChanges || saving"
                @click="saveActivities"
              >
                <v-progress-circular
                  v-if="saving"
                  color="secondary"
                  indeterminate
                  size="22"
                  width="2"
                />
                <span v-else>
                  {{ $t('labels.save') }}
                </span>
              </v-btn>
            </v-container>
          </v-tab-item>
          <v-tab-item
            class="pa-4"
            value="addOnCallShift"
          >
            <v-alert
              class="caption dense font-weight-medium mx-0"
              color="info"
              dense
              outlined
              text
            >
              <v-icon
                slot="prepend"
                class="ml-n1 mr-3"
                color="info"
                size="12"
              >
                fas fa-info-circle
              </v-icon>
              {{ $t('descriptions.selfScheduleAddShift') }}
            </v-alert>
            <v-select
              ref="selectOnShift"
              v-model="onCallShiftType"
              dense
              hide-details
              item-text="name"
              item-value="id"
              :items="onCallShiftTypes"
              :label="$t('labels.shift')"
              outlined
            />
            <v-container
              v-if="!bidForOpening"
              class="px-0 pb-0 text-right"
            >
              <v-btn
                class="save-shifts"
                color="secondary"
                :disabled="!hasChanges || saving"
                @click="saveActivities"
              >
                <v-progress-circular
                  v-if="saving"
                  color="secondary"
                  indeterminate
                  size="22"
                  width="2"
                />
                <span v-else>
                  {{ $t('labels.save') }}
                </span>
              </v-btn>
            </v-container>
          </v-tab-item>
          <v-tab-item
            v-if="allowAvailableShifts"
            class="pa-4"
            value="addAvailableShift"
          >
            <v-alert
              class="caption dense font-weight-medium mx-0"
              color="info"
              dense
              outlined
              text
            >
              <v-icon
                slot="prepend"
                class="ml-n1 mr-3"
                color="info"
                size="12"
              >
                fas fa-info-circle
              </v-icon>
              {{ $t('descriptions.selfScheduleMarkAvailability') }}
            </v-alert>
            <template v-if="allowPartialShift">
              <v-row dense>
                <v-col cols="6">
                  <v-select
                    ref="selectAvailableShift"
                    v-model="availableShiftType"
                    dense
                    hide-details
                    item-text="name"
                    item-value="id"
                    :items="availableShiftTypes"
                    :label="$t('labels.shift')"
                    outlined
                  />
                </v-col>
                <v-col cols="3">
                  <v-menu
                    :key="`start-${shiftStartTimeCount}`"
                    ref="shiftStartTimeMenu"
                    v-model="showShiftStartTime"
                    close-on-content-click
                    offset-y
                    max-height="300px"
                    max-width="100px"
                    min-width="100px"
                    :nudge-bottom="0"
                    :nudge-left="0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <VeeTextField
                        ref="shiftStartTime"
                        v-model="shiftStartTimeDisplay"
                        v-mask="timeMask"
                        :autocomplete="false"
                        class="shift-time d-inline-block"
                        dense
                        hide-details
                        name="shiftStartTime"
                        outlined
                        :label="$t('labels.start')"
                        :rules="{ required: true, time: true, excluded: [shiftEndTimeDisplay] }"
                        v-bind="attrs"
                        @keyup="updateStartTime()"
                        v-on="on"
                      />
                    </template>
                    <v-list
                      dense
                      flat
                    >
                      <v-list-item
                        v-for="time in startTimes"
                        :key="time.id"
                        class="caption"
                        :disabled="time.disabled"
                        :title="time.name"
                        @click.prevent="time.disabled ? null: updateStartTime(time.id)"
                      >
                        <v-list-item-title>
                          {{ time.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-menu
                    :key="`end-${shiftEndTimeCount}`"
                    ref="shiftEndTimeMenu"
                    v-model="showShiftEndTime"
                    close-on-content-click
                    offset-y
                    max-height="300px"
                    max-width="100px"
                    min-width="100px"
                    :nudge-bottom="0"
                    :nudge-left="0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <VeeTextField
                        ref="shiftEndTime"
                        v-model="shiftEndTimeDisplay"
                        v-mask="timeMask"
                        :autocomplete="false"
                        class="shift-time d-inline-block"
                        dense
                        hide-details
                        name="shiftEndTime"
                        outlined
                        :label="$t('labels.end')"
                        :rules="{ required: true, time: true, excluded: [shiftStartTimeDisplay] }"
                        v-bind="attrs"
                        @keyup="updateEndTime()"
                        v-on="on"
                      />
                    </template>
                    <v-list
                      dense
                      flat
                    >
                      <v-list-item
                        v-for="time in endTimes"
                        :key="time.id"
                        class="caption"
                        :disabled="time.disabled"
                        :title="time.name"
                        @click.prevent="time.disabled ? null: updateEndTime(time.id)"
                      >
                        <v-list-item-title>
                          {{ time.name }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-select
                ref="selectAvailableShift"
                v-model="availableShiftType"
                dense
                hide-details
                item-text="name"
                item-value="id"
                :items="availableShiftTypes"
                :label="$t('labels.shift')"
                outlined
              />
            </template>
            <v-container class="px-0 pb-0 text-right">
              <v-btn
                class="save-available"
                color="secondary"
                :disabled="!hasChanges || saving"
                @click="saveActivities"
              >
                <v-progress-circular
                  v-if="saving"
                  color="secondary"
                  indeterminate
                  size="22"
                  width="2"
                />
                <span v-else>
                  {{ $t('labels.save') }}
                </span>
              </v-btn>
            </v-container>
          </v-tab-item>
          <v-tab-item
            class="pa-4"
            value="addEvent"
          >
            <v-alert
              class="caption dense font-weight-medium mx-0"
              color="info"
              dense
              outlined
              text
            >
              <v-icon
                slot="prepend"
                class="ml-n1 mr-3"
                color="info"
                size="12"
              >
                fas fa-info-circle
              </v-icon>
              {{ $t('descriptions.selfScheduleAddEvent') }}
            </v-alert>
            <v-select
              ref="selectEvent"
              v-model="eventType"
              dense
              hide-details
              item-text="name"
              item-value="id"
              :items="eventTypes"
              :label="$tc('labels.event', 1)"
              outlined
            />
            <v-container class="px-0 pb-0 text-right">
              <v-btn
                class="save-available"
                color="secondary"
                :disabled="!hasChanges || saving"
                @click="saveActivities"
              >
                <v-progress-circular
                  v-if="saving"
                  color="secondary"
                  indeterminate
                  size="22"
                  width="2"
                />
                <span v-else>
                  {{ $t('labels.save') }}
                </span>
              </v-btn>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import VeeTextField from '@/components/form_controls/VeeTextField';
import { CONTENT_TYPES, ERROR_CODES } from '@/services/constants';
import { DATE_FORMAT, TIME_FORMAT_FULL_24 } from '@/utils/ui';
import { REQUEST_STATES, SCHEDULE_STATES, SHIFT_TIME_INTERVAL } from '@/views/scheduling/constants';
import { showStatus } from '@/plugins/vue-notification';

const Color = require('color');
const DeepDiff = require('deep-diff').diff;

export default {
  components: {
    VeeTextField
  },
  props: {
    schedule: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      availableShiftInfo: {
        end: '',
        start: ''
      },
      availableShiftType: null,
      eventType: null,
      itinerary: {},
      // tracker needs two properties "changes" and "saved". "changes" contains events/shifts that have
      // been added/updated/deleted. "saved" contains data that is saved in the server, but only for dates that
      // the user has manipulated, so every date in "changes" is also present in "saved". "saved" is needed because
      // data is ejected when user is scrolling through the calendar so it's possible that dates that have been changed are no longer
      // in "itinerary" and we need a way to calculate the changes that were made.
      tracker: {
        changes: {},
        saved: {}
      },
      limitReached: {},
      loadFailed: false,
      loading: true,
      onCallShiftType: null,
      saving: false,
      scheduleEndDate: this.schedule.endOn,
      scheduleStartDate: this.schedule.startOn,
      shiftEndTimeCount: 0,
      shiftStartTimeCount: 0,
      shiftEndTimeDisplay: '',
      shiftStartTimeDisplay: '',
      shiftType: null,
      showShiftEndTime: false, // Used to show/hide the end time menu for users to select a time
      showShiftStartTime: false, // Used to show/hide the start time menu for users to select a time
      tab: '',
      tabHeight: 500,
      visibleDate: this.schedule.startOn,
      i: 0
    };
  },
  computed: {
    allowAvailableShifts () {
      return _.get(this.$store.state.org, 'settings.scheduling.flexOn.allowSelfSchedule', false);
    },
    allowOnCallShifts () {
      return this.onCallShiftTypes.length > 0;
    },
    allowPartialShift () {
      return _.get(this.$store.state.org, 'settings.scheduling.flexOn.allowPartialShift', false);
    },
    alwaysOpened () {
      return _.get(this.$store.state.org, 'settings.scheduling.nurseSelfSchedule.alwaysOpen', false);
    },
    availableShiftTypes () {
      const shiftTypesCopy = _.cloneDeep(this.shiftTypes);
      return shiftTypesCopy.map((st) => {
        st.name = `${st.name} (AV)`;
        return st;
      });
    },
    bidForOpening () {
      return _.get(this.$store.state.org, 'settings.scheduling.nurseSelfSchedule.bidForOpening', false);
    },
    department () {
      return this.$store.getters['org/getActiveDepartment']();
    },
    endTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.availableShiftInfo.start);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    },
    eventTypes () {
      return _.sortBy(this.$store.state.org.eventTypes.filter(et => !et.requestOnly && !et.adminOnly), ['name']);
    },
    eventTypesById () {
      return this.$store.state.org.eventTypes.reduce(
        (obj, eventType) => {
          obj[eventType.id] = eventType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    flagsById () {
      return this.$store.state.org.flags.reduce(
        (obj, flag) => {
          obj[flag.id] = flag;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    hasChanges () {
      return this.getRawChanges().length > 0;
    },
    consolidatedItinerary () {
      const consolidatedItinerary = _.cloneDeep({ ...this.itinerary, ...this.tracker.changes });
      this.markErrors(consolidatedItinerary);
      const isSelfScheduleOpen = this.isSelfScheduleOpen();
      for (let date in consolidatedItinerary) {
        if (this.shouldDisableDate(date, consolidatedItinerary[date], consolidatedItinerary)) {
          consolidatedItinerary[date].disabled = true;
        }
        consolidatedItinerary[date].canBid = false;
        const openShift = _.find(_.get(consolidatedItinerary[date], 'data.openShifts', []), (os) => {
          switch (this.tab) {
            case 'addShift':
              return os.typeId == this.shiftType;
            case 'addOnCallShift':
              return os.typeId == this.onCallShiftType;
            default:
              return false;
          }
        });
        if (openShift && isSelfScheduleOpen) {
          consolidatedItinerary[date].canBid = this.canBidForOpenShift(openShift);
        }
      }
      return consolidatedItinerary;
    },
    onCallShiftTypes () {
      const allShiftTypeIds = this.$store.getters['org/getJobTypeById'](this.$store.state.account.profile.jobTypeId, 'settings').associatedShiftTypes;
      return _.sortBy(_.map(
        _.filter(allShiftTypeIds, (id) => !!this.shiftTypesById[id].onCall),
        (id) => {
          return this.shiftTypesById[id];
        }
      ), ['name']);
    },
    selectedTypeId () {
      switch (this.tab) {
        case 'addShift':
          return this.shiftType;
        case 'addOnCallShift':
          return this.onCallShiftType;
        case 'addAvailableShift':
          return this.availableShiftType;
        case 'addEvent':
          return this.eventType;
        default:
          return null;
      }
    },
    shiftColor () {
      return _.get(this.$store.state.account, 'profile.settings.styles.orgColor', '#FFE586');
    },
    shiftTypes () {
      // This list of shift types are for the "Add shift" tab so it should not include on call.
      let shiftTypeIds = [];
      const primaryShiftOnly = _.get(this.$store.state.org, 'settings.scheduling.nurseSelfSchedule.primaryShiftOnly', true);
      if (primaryShiftOnly) {
        shiftTypeIds = [this.$store.state.account.profile.shiftTypeId];
      } else {
        shiftTypeIds = this.$store.getters['org/getJobTypeById'](this.$store.state.account.profile.jobTypeId, 'settings').associatedShiftTypes;
      }

      shiftTypeIds = _.filter(shiftTypeIds, (id) => !this.shiftTypesById[id].onCall);
      return _.sortBy(shiftTypeIds.map((id) => {
        return this.$store.getters['org/getShiftTypeById'](id);
      }), ['name']);
    },
    shiftTypesById () {
      return this.$store.state.org.shiftTypes.reduce(
        (obj, shiftType) => {
          obj[shiftType.id] = shiftType;
          return obj;
        }, // eslint-disable-line no-return-assign, no-sequences
        {}
      );
    },
    startTimes () {
      const times = this.getTimes();
      const index = _.findIndex(times, (t) => t.id === this.availableShiftInfo.end);
      if (index >= 0) {
        times[index].disabled = true;
      }
      return times;
    },
    validationData () {
      const consecutiveShiftsData = _.get(this.$store.state.org.settings, ['validations', 'consecutiveShifts', 'data'], []);
      const profile = this.$store.state.account.profile;
      let consecutiveShifts = null;
      for (let i = 0, count = consecutiveShiftsData.length; i < count; i++) {
        if (_.indexOf(consecutiveShiftsData[i].jobTypes, profile.jobTypeId) >= 0) {
          consecutiveShifts = consecutiveShiftsData[i].shiftTypes;
        }
      }

      return {
        consecutiveShifts,
        hoursRequired: profile.weeklyHours
      };
    }
  },
  watch: {
    availableShiftType () {
      const shiftType = _.find(this.availableShiftTypes, (st) => st.id === this.availableShiftType);
      if (shiftType) {
        this.availableShiftInfo.start = shiftType.startTime;
        this.availableShiftInfo.end = shiftType.endTime;
        this.shiftEndTimeDisplay = this.formatTime(shiftType.endTime);
        this.shiftStartTimeDisplay = this.formatTime(shiftType.startTime);
        this.shiftEndTimeCount++;
        this.shiftStartTimeCount++;
      }
    }
  },
  mounted () {
    this.availableShiftType = _.get(this.availableShiftTypes[0], 'id', null);
    this.eventType = _.get(this.eventTypes[0], 'id', null);
    this.shiftType = _.get(this.shiftTypes[0], 'id', null);
    this.onCallShiftType = _.get(this.onCallShiftTypes[0], 'id', null);
    this.updateTabHeight();
    this.load();
    window.addEventListener('resize', _.debounce(this.updateTabHeight, 500));
  },
  beforeDestroy () {
    this.$emit('destroyed');
    window.removeEventListener('resize', this.updateTabHeight);
  },
  methods: {
    addShift (shift) {
      const activities = _.cloneDeep(_.get(this.consolidatedItinerary, [shift.date, 'data', 'activities'], []));
      const pseudoId = _.uniqueId('FEID-');
      activities.push({
        action: 'add',
        id: null,
        pseudoId,
        type: 'shift',
        ...shift
      });
      this.$set(this.tracker.saved, shift.date, _.get(this.itinerary, [shift.date], {}));
      this.$set(this.tracker.changes, shift.date, this.getCalendarData(shift.date, activities));
    },
    addEvent (date, event) {
      const activities = _.cloneDeep(_.get(this.consolidatedItinerary, [date, 'data', 'activities'], []));
      const pseudoId = _.uniqueId('FEID-');
      activities.push({
        action: 'add',
        comments: '',
        dates: [date],
        id: null,
        pseudoId,
        ...event
      });

      this.$set(this.tracker.changes, date, this.getCalendarData(date, activities));
      this.$set(this.tracker.saved, date, _.get(this.itinerary, [date], {}));
    },
    updateShift (shift) {
      const activities = _.cloneDeep(_.get(this.consolidatedItinerary, [shift.date, 'data', 'activities'], []));
      const activityIndex = _.findIndex(activities, (a) => a.pseudoId === shift.pseudoId);
      if (activityIndex >= 0) {
        let action = activities[activityIndex].action;
        if (action !== 'add') {
          action = 'update';
        }

        activities[activityIndex].action = action;
        activities[activityIndex].startTime = shift.startTime;
        activities[activityIndex].endTime = shift.endTime;

        this.$set(this.tracker.saved, shift.date, _.get(this.itinerary, [shift.date], {}));
        this.$set(this.tracker.changes, shift.date, this.getCalendarData(shift.date, activities));
      }
    },
    updateEvent (event, dates, data) {
      // Events and Custom Events can both have multiple dates, so the logic for updating events is the same.
      const added = _.difference(dates, event.dates);
      const removed = _.difference(event.dates, dates);
      const updated = _.intersection(event.dates, dates);

      const updatedActivities = {};
      for (let i = 0, count = added.length; i < count; i++) {
        const dateActivities = _.cloneDeep(_.get(this.consolidatedItinerary, [added[i], 'data', 'activities'], []));
        dateActivities.push({
          ...event,
          ...data,
          dates,
          action: 'update'
        });
        updatedActivities[added[i]] = dateActivities;
      }

      for (let i = 0, count = removed.length; i < count; i++) {
        const dateActivities = _.cloneDeep(_.get(this.consolidatedItinerary, [removed[i], 'data', 'activities'], []));
        const dateActivityIndex = _.findIndex(dateActivities, (a) => a.pseudoId === event.pseudoId);
        if (dateActivityIndex >= 0) {
          dateActivities.splice(dateActivityIndex, 1);
        }
        updatedActivities[removed[i]] = dateActivities;
      }

      for (let i = 0, count = updated.length; i < count; i++) {
        const dateActivities = _.cloneDeep(_.get(this.consolidatedItinerary, [updated[i], 'data', 'activities'], []));
        const dateActivityIndex = _.findIndex(dateActivities, (a) => a.pseudoId === event.pseudoId);
        if (dateActivityIndex >= 0) {
          let action = dateActivities[dateActivityIndex].action;
          if (action !== 'add') {
            action = 'update';
          }
          dateActivities[dateActivityIndex] = {
            ...dateActivities[dateActivityIndex],
            ...data,
            dates,
            action
          };
          updatedActivities[updated[i]] = dateActivities;
        }
      }

      for (let date in updatedActivities) {
        this.$set(this.tracker.changes, date, this.getCalendarData(date, updatedActivities[date]));
        this.$set(this.tracker.saved, date, _.get(this.itinerary, [date], {}));
      }
    },
    deleteShift (date, shifts) {
      const activities = _.cloneDeep(_.get(this.consolidatedItinerary, [date, 'data', 'activities'], []));
      for (let shift of shifts) {
        const activityIndex = _.findIndex(activities, (a) => a.pseudoId === shift.pseudoId);
        if (activities[activityIndex].id) {
          activities[activityIndex].action = 'remove';
        } else {
          activities.splice(activityIndex, 1);
        }
      }

      this.$set(this.tracker.changes, date, this.getCalendarData(date, activities));
      this.$set(this.tracker.saved, date, _.get(this.itinerary, [date], {}));
    },
    deleteEvent (event) {
      // Deletion between event and custom event only relies on the dates field which both events and custom events have.
      const updatedActivities = {};
      for (let i = 0, count = event.dates.length; i < count; i++) {
        const activities = _.cloneDeep(_.get(this.consolidatedItinerary, [event.dates[i], 'data', 'activities'], []));
        const activityIndex = _.findIndex(activities, (a) => a.pseudoId === event.pseudoId);
        if (activityIndex >= 0) {
          if (activities[activityIndex].id) {
            activities[activityIndex].action = 'remove';
          } else {
            activities.splice(activityIndex, 1);
          }
          updatedActivities[event.dates[i]] = activities;
        }
      }
      for (let date in updatedActivities) {
        this.$set(this.tracker.changes, date, this.getCalendarData(date, updatedActivities[date]));
        this.$set(this.tracker.saved, date, _.get(this.itinerary, [date], {}));
      }
    },
    canBidForOpenShift (openShift) {
      let canBid = false;
      if (openShift.fcfs) {
        if (openShift.selfScheduleOnly) {
          // Self-schedule only open shifts are not closed automatically by the system therefore check the bidders list.
          canBid = openShift.bidders.length < openShift.opening || openShift.bidders.includes(this.$store.state.account.userId);
        } else {
          // Don't have to check the bidder count since the system will automatically end the open shift bidding.
          canBid = !openShift.biddingClosed && !openShift.biddingEnded;
        }
      } else {
        // Open shifts that are not FCFS do not have a limit to the number of bidders.
        canBid = true;
      }

      return canBid;
    },
    // This function is added mainly for easy of mocking during in unit tests.
    dispatch (action, payload) {
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    formatTime (time) {
      const [hour, minutes] = time.split(':');
      return `${hour}:${minutes}`;
    },
    getActivitiesByDate () {
      const activities = {};
      let date = moment(this.schedule.startOn);
      const endDate = moment(this.schedule.endOn);
      while (date.isSameOrBefore(endDate)) {
        const dateStr = date.format(DATE_FORMAT);
        activities[dateStr] = _.cloneDeep(_.get(this.itinerary, [dateStr, 'data', 'activities'], []));
        for (let i = 0, count = activities[dateStr].length; i < count; i++) {
          const activity = activities[dateStr][i];
          switch (activity.type) {
            case CONTENT_TYPES.event:
              activities[dateStr][i].dates = activity.dates.map((d) => moment(d).toDate());
              break;
            case CONTENT_TYPES.shift:
              activities[dateStr][i].date = moment(activity.date).toDate();
              break;
          }
        }
        date.add(1, 'd');
      }
      return activities;
    },
    getCalendarData (date, activities) {
      const shifts = _.filter(activities, (a) => a.type === CONTENT_TYPES.shift && a.action !== 'remove');
      const events = _.filter(activities, (a) => a.type === CONTENT_TYPES.event && a.action !== 'remove');
      let hasFlags, hasNonWorkingFlag, hasAvailable, hasCanceled, hasObligatory, hasOnCall, hasSitter;
      let eventColor, isStartingDay, isEndingDay;
      for (let shift of shifts) {
        if (shift.obligatory) {
          hasObligatory = true;
        }
        if (shift.canceled) {
          hasCanceled = true;
        }
        if (shift.onCall) {
          hasOnCall = true;
        }
        if (shift.available) {
          hasAvailable = true;
        }
        if (shift.sitter) {
          hasSitter = true;
        }
        if (!_.isEmpty(shift.flags)) {
          hasFlags = true;
          if (shift.flags.some(flagId => !this.flagsById[flagId].working)) {
            hasNonWorkingFlag = true;
          }
        }
      }

      // Only one event can be scheduled per day.
      const event = events[0];
      if (event) {
        const numberOfDates = event.dates.length;
        const prevDay = moment(date).subtract(1, 'day').format(DATE_FORMAT);
        const nextDay = moment(date).add(1, 'day').format(DATE_FORMAT);
        const dateIdx = _.findIndex(event.dates, (d) => d === date);
        eventColor = _.get(this.eventTypesById, [event.typeId, 'styles', 'mobile', 'bgColor'], '');
        isStartingDay = (dateIdx === 0 || prevDay !== event.dates[dateIdx - 1]);
        isEndingDay = (dateIdx === numberOfDates - 1 || nextDay !== event.dates[dateIdx + 1]);
      }

      return {
        data: {
          activities,
          openShifts: _.get(this.itinerary, [date, 'data', 'openShifts'], [])
        },
        hasFlags,
        hasNonWorkingFlag,
        hasShift: shifts.length > 0,
        available: hasAvailable,
        canceled: hasCanceled,
        obligatory: hasObligatory,
        onCall: hasOnCall,
        sitter: hasSitter,
        hasEvent: events.length > 0,
        eventColor,
        startingDay: isStartingDay,
        endingDay: isEndingDay
      };
    },
    getChanges () {
      const tracker = this.tracker;
      const diff = this.getRawChanges();
      let changes = null;
      if (diff.length > 0) {
        changes = {
          shift: {
            create: {},
            remove: {},
            update: {}
          },
          event: {
            create: {},
            remove: {},
            update: {}
          }
        };
        const datesChanged = {};
        const departmentId = this.$store.state.account.profile.departmentId;
        // Every item in the diff array from the DeepDiff library is a property changed. Here We just want the row
        // and column intersection, so we need to remove duplicates.
        for (let i = 0, len = diff.length; i < len; i++) {
          datesChanged[diff[i].path[0]] = true;
        }

        const prepareValue = (value, index) => {
          let key, date, dates;
          const data = { ...value };
          switch (value.type) {
            case 'shift':
              date = value.date;
              key = [date, index].join(':');
              data.date = date;
              data.scheduleId = this.schedule.id;
              data.departmentId = departmentId;
              break;
            case 'event':
              dates = value.dates;
              key = dates.join(':');
              data.dates = dates;
              data.departmentId = departmentId;
              break;
          }
          return { key, data };
        };

        const add = (value, index) => {
          let { key, data } = prepareValue(value, index);
          // ID's need to be empty for adding events or shifts
          if (key && !value.id) {
            changes[value.type].create[key] = data;
          }
        };

        const remove = (value) => {
          if (value.id) {
            changes[value.type].remove[value.id] = value;
          }
        };

        const update = (value, index) => {
          let { key, data } = prepareValue(value, index);
          if (key) {
            changes[value.type].update[key] = data;
          }
        };

        for (let date in datesChanged) {
          const activities = _.get(tracker.changes, [date, 'data', 'activities'], []);
          for (let i = 0, count = activities.length; i < count; i++) {
            switch (activities[i].action) {
              case 'add':
                add(activities[i], i);
                break;
              case 'update':
                update(activities[i], i);
                break;
              case 'remove':
                remove(activities[i], i);
                break;
            }
          }
        }
      }

      return changes;
    },
    getRawChanges () {
      return DeepDiff(this.tracker.saved, this.tracker.changes, {
        prefilter: (path, key) => {
          return (path.length === 1 && key !== 'data') || (path.length === 2 && key !== 'activities');
        }
      }) || [];
    },
    getErrorMessage (dateInfo) {
      let message = '';
      if (!_.isEmpty(dateInfo.errors)) {
        const { hoursRequired } = this.validationData;
        const consecutiveShifts = dateInfo.errors.consecutiveShifts ? dateInfo.errors.consecutiveShifts.length : 0;
        const overtime = dateInfo.errors.overtime ? dateInfo.errors.overtime.length : 0;
        if (consecutiveShifts && overtime) {
          message = this.$t(
            'descriptions.errorMultipleForShift',
            { countOvertime: hoursRequired, countConsecutive: consecutiveShifts }
          );
        } else if (consecutiveShifts) {
          message = this.$t(
            'descriptions.errorConsecutiveShiftsForShift',
            { count: consecutiveShifts }
          );
        } else if (overtime) {
          message = this.$t(
            'descriptions.errorOvertimeForShift',
            { count: hoursRequired }
          );
        }
      }

      return message;
    },
    getShiftStyle (shiftInfo) {
      const style = {};
      if (shiftInfo.obligatory) {
        style['border-radius'] = '4px';
      }

      const available = this.tab === 'addAvailableShift';
      const activity = _.find(_.get(shiftInfo, 'data.activities', []), (a) => {
        return a.typeId === this.selectedTypeId && a.available === available && a.action !== 'remove';
      });
      if (shiftInfo.canceled) {
        style['background-color'] = '#EEEEEE';
      } else if (shiftInfo.onCall && (this.tab === 'addOnCallShift' || !activity)) {
        style['border'] = `1px solid ${this.shiftColor}`;
        style['background-color'] = Color(this.shiftColor).alpha(0.4).string();
      } else if (shiftInfo.available && (this.tab === 'addAvailableShift' || !activity)) {
        style['background-color'] = 'white';
        style['border'] = `2px solid ${this.shiftColor}`;
      } else if (shiftInfo.sitter) {
        style['border'] = '1px solid #995A92';
      }

      if (shiftInfo.hasNonWorkingFlag) {
        style['border'] = '1px dashed #e74c3c';
      }
      return style;
    },
    getTimes () {
      const times = [];
      let date = moment('00:00:00', 'HH:mm:ss');
      let end = moment('23:30:00', 'HH:mm:ss');
      while (date.isSameOrBefore(end)) {
        times.push({
          id: date.format('HH:mm:ss'),
          name: date.format('HH:mm')
        });
        date.add(SHIFT_TIME_INTERVAL, 'minutes');
      }
      return times;
    },
    getLimitReachedErrors (date) {
      if (_.has(this.limitReached, [date])) {
        return this.limitReached[date];
      }
      return [];
    },
    isSelfScheduleOpen () {
      const schedulingSettings = this.$store.state.org.settings.scheduling;
      if (schedulingSettings.nurseSelfSchedule.alwaysOpen) {
        return true;
      } else {
        const profile = this.$store.state.account.profile;
        if (this.schedule.state === SCHEDULE_STATES.SELF_SCHEDULE) {
          const selfScheduleLatent = schedulingSettings.nurseSelfSchedule.latent;
          const selfSchedulePeriod = schedulingSettings.nurseSelfSchedule.period;
          const schedulePeriod = schedulingSettings.period;
          let selfScheduleStartDate = moment(`${this.schedule.startOn} ${schedulingSettings.nurseSelfSchedule.startTime}`).subtract(schedulePeriod - selfScheduleLatent, 'd');
          const selfScheduleEndDate = moment(selfScheduleStartDate).add(selfSchedulePeriod - 1, 'd');

          if (Array.isArray(schedulingSettings.nurseSelfSchedule.segments) && schedulingSettings.nurseSelfSchedule.segments.length > 0) {
            const segmentIdx = _.findIndex(schedulingSettings.nurseSelfSchedule.segments, (s) => s.allowedJobStatus.includes(profile.jobStatusId));
            if (segmentIdx >= 0) {
              if (schedulingSettings.nurseSelfSchedule.segments[segmentIdx].latent === null) {
                return false;
              }
              // Have to subtract 1 since the latent value is the day number from the start of self schedule which
              // is 1-based
              selfScheduleStartDate.add(schedulingSettings.nurseSelfSchedule.segments[segmentIdx].latent - 1, 'd');
            }
          }

          const today = moment();
          return today.isSameOrAfter(selfScheduleStartDate) && today.isSameOrBefore(selfScheduleEndDate);
        }
        return false;
      }
    },
    isWorkingShift (shift, checkOnCall) {
      let isWorking = !shift.available && !shift.canceled && !shift.giveaway && !shift.swapped && (_.isEmpty(shift.flags) || !shift.flags.some(flagId => !this.flagsById[flagId].working));
      if (checkOnCall) {
        isWorking &= !shift.onCall;
      }
      return isWorking;
    },
    load () {
      this.loading = true;
      const eventsCriteria = {
        deptId: this.$store.state.account.profile.departmentId,
        params: {
          assignee_id: this.$store.state.account.userId,
          intersect: [this.schedule.startOn, this.schedule.endOn].join(',')
        }
      };
      const shiftsCriteria = {
        assignee_id: this.$store.state.account.userId,
        between: [this.schedule.startOn, this.schedule.endOn].join(',')
      };
      const openShiftsCriteria = {
        between: [this.schedule.startOn, this.schedule.endOn].join(','),
        department_id: this.$store.state.account.profile.departmentId,
        job_type_ids: this.$store.state.account.profile.jobTypeId,
        open: 'true',
        self_schedule_only: 'true'
      };

      let shifts = [];
      let events = [];
      let openShifts = [];

      Promise.all([
        this.dispatch('scheduling/retrieveShifts', shiftsCriteria).then((response) => {
          shifts = response;
        }),
        this.dispatch('scheduling/retrieveEvents', eventsCriteria).then((response) => {
          events = response;
        }),
        this.dispatch('scheduling/retrieveOpenShifts', openShiftsCriteria).then((response) => {
          openShifts = response;
        })
      ]).then(() => {
        const markedDates = {};
        const scheduledShiftDates = {};
        for (let i = 0, count = shifts.length; i < count; i++) {
          const shift = shifts[i];
          shift.pseudoId = _.uniqueId('FEID-');
          const markedDateStyles = {};
          if (shift.obligatory) {
            markedDateStyles['obligatory'] = true;
          }

          let isScheduledShift = true;
          if (shift.canceled) {
            markedDateStyles['canceled'] = true;
            isScheduledShift = false;
          } else if (shift.onCall) {
            markedDateStyles['onCall'] = true;
          } else if (shift.available) {
            markedDateStyles['available'] = true;
            isScheduledShift = false;
          } else {
            markedDateStyles['regular'] = true;
            if (shift.sitter) {
              markedDateStyles['sitter'] = true;
            }
          }

          markedDateStyles['hasShift'] = true;

          if (!_.isEmpty(shift.flags)) {
            markedDateStyles['hasFlags'] = true;
            if (shift.flags.some(flagId => !this.flagsById[flagId].working)) {
              markedDateStyles['hasNonWorkingFlag'] = true;
              isScheduledShift = false;
            }
          }
          if (isScheduledShift) {
            scheduledShiftDates[shift.date] = shift;
          }

          if (!markedDates[shift.date]) {
            markedDates[shift.date] = {
              data: {
                activities: []
              }
            };
          }
          if (markedDates[shift.date].data.activities.length > 0) {
            markedDateStyles['slits'] = true;
          }
          markedDates[shift.date] = {
            ...markedDates[shift.date],
            ...markedDateStyles
          };
          markedDates[shift.date].data.activities.push({
            ...shift,
            type: CONTENT_TYPES.shift
          });
        }

        for (let i = 0, count = events.length; i < count; i++) {
          const event = events[i];
          event.pseudoId = _.uniqueId('FEID-');
          for (let dateIdx = 0, numberOfDates = event.dates.length; dateIdx < numberOfDates; dateIdx++) {
            const date = event.dates[dateIdx];
            const markedDateStyles = {};

            const eventColor = _.get(this.eventTypesById, [event.typeId, 'styles', 'mobile', 'bgColor'], '');
            let request = false;
            if (_.indexOf([REQUEST_STATES.EMPTY, REQUEST_STATES.APPROVED], event.state) >= 0) {
              // dates in events do not have to be consecutive, so we need to check against neighboring
              // dates to correctly draw them on the calendar.
              const prevDay = moment(date).subtract(1, 'day').format(DATE_FORMAT);
              const nextDay = moment(date).add(1, 'day').format(DATE_FORMAT);
              markedDateStyles['hasEvent'] = true;
              markedDateStyles['eventColor'] = eventColor;
              markedDateStyles['startingDay'] = (dateIdx === 0 || prevDay !== event.dates[dateIdx - 1]);
              markedDateStyles['endingDay'] = (dateIdx === numberOfDates - 1 || nextDay !== event.dates[dateIdx + 1]);
            }

            if (!_.isEmpty(markedDateStyles)) {
              if (!markedDates[date]) {
                markedDates[date] = {
                  data: {
                    activities: []
                  }
                };
              }
              markedDates[date] = {
                ...markedDates[date],
                ...markedDateStyles
              };
              if (request) {
                markedDates[date].data.request = {
                  ...event,
                  type: CONTENT_TYPES.event
                };
              } else {
                markedDates[date].data.activities.push({
                  ...event,
                  type: CONTENT_TYPES.event
                });
              }
            }
          }
        }

        for (let i = 0, count = openShifts.length; i < count; i++) {
          const openShift = openShifts[i];

          if (!markedDates[openShift.date]) {
            markedDates[openShift.date] = {
              data: {
                activities: []
              }
            };
          }

          if (!markedDates[openShift.date].data.openShifts) {
            markedDates[openShift.date].data.openShifts = [];
          }

          markedDates[openShift.date].data.openShifts.push(openShift);
        }

        this.itinerary = markedDates;
      }).catch(() => {
        this.loadFailed = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    loadOpenShifts (date) {
      const openShiftsCriteria = {
        date,
        department_id: this.$store.state.account.profile.departmentId,
        job_type_ids: this.$store.state.account.profile.jobTypeId,
        open: 'true',
        self_schedule_only: 'true'
      };
      return this.dispatch('scheduling/retrieveOpenShifts', openShiftsCriteria);
    },
    markDisabled (date) {
      return date < this.scheduleStartDate || date > this.scheduleEndDate || (this.consolidatedItinerary[date] && this.consolidatedItinerary[date].disabled);
    },
    markErrors (itinerary) {
      const { consecutiveShifts, hoursRequired } = this.validationData;
      const datesInItinerary = _.keys(itinerary).sort();
      const allowOvertime = _.get(this.$store.state.org.settings, 'scheduling.nurseSelfSchedule.allowOvertime', true);
      let errors = {};
      let limitReached = {};
      if (datesInItinerary.length > 0) {
        const shiftTypes = {};
        for (let shiftTypeId in this.shiftTypesById) {
          const shiftType = this.shiftTypesById[shiftTypeId];
          let seconds = moment(shiftType.endTime, TIME_FORMAT_FULL_24).diff(moment(shiftType.startTime, TIME_FORMAT_FULL_24), 'seconds');
          if (seconds < 0) {
            seconds += 86400;
          }
          shiftTypes[shiftTypeId] = { ...shiftType, hours: seconds / 3600 };
        }
        let date = moment(this.scheduleStartDate);
        let end = moment(this.scheduleEndDate);
        let consecutiveCount = 0;
        let consecutiveDates = [];
        let overtimeDates = [];
        let previousShift = null;
        let hours = 0;
        let day = 0;

        // eslint-disable-next-line no-unmodified-loop-condition
        while (date <= end) {
          const field = date.format(DATE_FORMAT);
          const activities = _.get(itinerary, [field, 'data', 'activities'], []);
          let countedConsecutiveShift = false;
          let shift = null;
          day = date.day();
          for (let i = 0, count = activities.length; i < count; i++) {
            const activity = activities[i];
            const isShift = activity.type === 'shift' && this.isWorkingShift(activity, true);

            if (consecutiveShifts && !countedConsecutiveShift) {
              if (previousShift && isShift && previousShift.typeId === activity.typeId) {
                consecutiveCount++;
                previousShift = activity;
                consecutiveDates.push(field);
                countedConsecutiveShift = true;
              } else {
                if (isShift) {
                  shift = activity;
                }
              }
            }
            if (hoursRequired) {
              if (isShift) {
                overtimeDates.push(field);
                overtimeDates = _.uniq(overtimeDates);
                if (activity.startTime || activity.endTime) {
                  const startTime = activity.startTime ? activity.startTime : shiftTypes[activity.typeId].startTime;
                  const endTime = activity.endTime ? activity.endTime : shiftTypes[activity.typeId].endTime;
                  let seconds = moment(endTime, TIME_FORMAT_FULL_24).diff(moment(startTime, TIME_FORMAT_FULL_24), 'seconds');
                  if (seconds < 0) {
                    seconds += 86400;
                  }
                  hours += seconds / 3600;
                } else {
                  hours += shiftTypes[activity.typeId].hours;
                }
              }
            }
          }
          if (consecutiveShifts && !countedConsecutiveShift) {
            if (previousShift && consecutiveShifts[previousShift.typeId] && consecutiveCount > consecutiveShifts[previousShift.typeId]) {
              for (let i = 0, count = consecutiveDates.length; i < count; i++) {
                _.set(errors, [consecutiveDates[i], 'consecutiveShifts'], [...consecutiveDates]);
              }
            }

            if (shift) {
              consecutiveCount = 1;
              consecutiveDates = [];
              previousShift = shift;
              consecutiveDates.push(field);
            } else {
              consecutiveCount = 0;
              consecutiveDates = [];
              previousShift = null;
            }
          }
          if (hoursRequired && day === 6) {
            if (hours > hoursRequired) {
              for (let i = 0, count = overtimeDates.length; i < count; i++) {
                _.set(errors, [overtimeDates[i], 'overtime'], [...overtimeDates]);
              }
            }
            if (!allowOvertime && hours >= hoursRequired) {
              let start = date.clone().startOf('week');
              while (start.isSameOrBefore(date)) {
                const startStr = start.format(DATE_FORMAT);
                if (!limitReached[startStr]) {
                  limitReached[startStr] = [];
                }
                limitReached[startStr].push({ message: 'descriptions.noOvertimeAllowed' });
                start.add(1, 'd');
              }
            }
            // New week will start next iteration therefore reset variables.
            hours = 0;
            overtimeDates = [];
          }
          date.add(1, 'd');
        }

        // Its possible the loop ends without checking the last batch of data therefore check the last
        // batch for errors.

        if (previousShift && consecutiveShifts && consecutiveShifts[previousShift.typeId] && consecutiveCount > consecutiveShifts[previousShift.typeId]) {
          for (let i = 0, count = consecutiveDates.length; i < count; i++) {
            _.set(errors, [consecutiveDates[i], 'consecutiveShifts'], [...consecutiveDates]);
          }
        }

        if (hoursRequired && hours > hoursRequired) {
          for (let i = 0, count = overtimeDates.length; i < count; i++) {
            _.set(errors, [overtimeDates[i], 'overtime'], [...overtimeDates]);
          }
        }
      }

      if (!_.isEmpty(errors)) {
        for (let date in errors) {
          itinerary[date] = {
            ...itinerary[date],
            errors: errors[date],
            limitReached: limitReached[date],
            validationData: this.validationData
          };
        }
      }
      this.limitReached = limitReached;
    },
    markEventDates (tracker, event, type) {
      const color = _.get(type, ['styles', 'mobile', 'bgColor'], '');
      const dates = _.uniq(event.dates).sort();
      for (let i = 0, count = dates.length; i < count; i++) {
        const date = dates[i];
        // dates in events do not have to be consecutive, so we need to check against neighboring
        // dates to correctly draw them on the calendar.
        const prevDay = moment(date).subtract(1, 'day').format(DATE_FORMAT);
        const nextDay = moment(date).add(1, 'day').format(DATE_FORMAT);
        tracker.changes[date] = {
          startingDay: i === 0 || prevDay !== dates[i - 1],
          endingDay: i === count - 1 || nextDay !== dates[i + 1],
          hasEvent: true,
          eventColor: color,
          data: {
            activities: [{
              ...event,
              dates
            }],
            openShifts: _.get(this.itinerary, [date, 'data', 'openShifts'], [])
          }
        };
      }
    },
    moment,
    next () {
      this.$refs.calendar.next();
    },
    onDayPress (date) {
      const abortAddingShift = () => {
        const limitReachedList = this.getLimitReachedErrors(date);
        if (limitReachedList.length > 0) {
          showStatus({
            duration: 5000,
            text: limitReachedList.map((lr) => this.$t(lr.message, lr.placeholders)).join('\n'),
            type: 'warn'
          });
          return true;
        }
        return false;
      };
      if (['addShift', 'addOnCallShift'].includes(this.tab) && this.bidForOpening) {
        this.itinerary[date] = {
          ...this.itinerary[date],
          disabled: true,
          loading: true
        };

        this.$nextTick(() => {
          // Open shifts on the schedule tab are only for self schedule which are always FCFS.
          const typeId = this.selectedTypeId;
          const openShiftBid = _.find(_.get(this.itinerary, [date, 'data', 'activities'], []), (a) => !!a.openShiftId && a.typeId == typeId);
          if (openShiftBid) {
            this.dispatch('scheduling/cancelBidForOpenShift', openShiftBid.openShiftId).then((openShift) => {
              return this.loadOpenShifts(openShift.date).then((openShifts) => {
                const shiftIndex = _.findIndex(this.itinerary[openShift.date].data.activities, (a) => a.openShiftId === openShift.id);
                const dateInfo = _.cloneDeep(this.itinerary[openShift.date]);
                dateInfo.data.openShifts = openShifts;
                dateInfo.data.activities.splice(shiftIndex, 1);
                dateInfo.hasShift = dateInfo.data.activities.length > 0;
                dateInfo.disabled = false;
                dateInfo.loading = false;

                this.itinerary[date] = dateInfo;
                this.$emit('update', this.getActivitiesByDate());
              });
            }).catch((error) => {
              const data = {
                error: _.get(error, 'response.data')
              };
              showStatus({
                text: this.$t('descriptions.genericError'),
                type: 'error',
                data
              });
              this.itinerary[date] = {
                ...this.itinerary[date],
                disabled: false,
                loading: false
              };
            });
          } else {
            if (this.tab === 'addShift' && abortAddingShift()) {
              this.itinerary[date] = {
                ...this.itinerary[date],
                disabled: false,
                loading: false
              };
              return;
            }
            const openShift = _.find(_.get(this.itinerary, [date, 'data', 'openShifts'], []), (os) => {
              return os.typeId === typeId;
            });
            this.dispatch('scheduling/bidForOpenShift', { id: openShift.id, fcfs: true }).then((shift) => {
              return this.loadOpenShifts(openShift.date).then((openShifts) => {
                const dateInfo = _.cloneDeep(this.itinerary[shift.date]);
                dateInfo.data.activities.push({ ...shift, type: CONTENT_TYPES.shift });
                dateInfo.data.openShifts = openShifts;
                dateInfo.hasShift = true;
                dateInfo.onCall = shift.onCall;
                dateInfo.disabled = false;
                dateInfo.loading = false;

                this.itinerary[date] = dateInfo;
                this.$emit('update', this.getActivitiesByDate());
              });
            }).catch((error) => {
              const status = _.get(error, 'response.status', null);
              const data = {
                error: _.get(error, 'response.data')
              };
              const detail = _.get(error, 'response.data.detail', null);
              const dateInfo = _.cloneDeep(this.itinerary[openShift.date]);
              const openShiftIndex = _.findIndex(dateInfo.data.openShifts, (os) => os.id === openShift.id);
              switch (status) {
                case ERROR_CODES.http412PreconditionFailed:
                  if (detail === 'max_shift_count') {
                    showStatus({
                      text: this.$t('descriptions.maxShiftCountReached', { name: this.shiftTypesById[typeId].name }),
                      type: 'error'
                    });
                  } else {
                    if (openShiftIndex >= 0) {
                      dateInfo.data.openShifts.splice(openShiftIndex, 1);
                    }
                    showStatus({
                      text: this.$t('descriptions.openShiftNotAvailableOnDate', { date: moment(date).format(this.$store.state.org.settings.display.datetime.dateFormatLong) }),
                      type: 'warn',
                      data
                    });
                  }
                  break;
                default:
                  showStatus({
                    text: this.$t('descriptions.genericError'),
                    type: 'error',
                    data
                  });
                  break;
              }
              this.itinerary[date] = {
                ...dateInfo,
                disabled: false,
                loading: false
              };
            });
          }
        });
      } else {
        const validateShift = (shiftType) => {
          if (!shiftType.maxShiftCount) {
            return true;
          }
          let count = 0;
          let date = moment(this.schedule.startOn);
          const endDate = moment(this.schedule.endOn);

          while (date.isSameOrBefore(endDate)) {
            const activities = _.get(this.consolidatedItinerary, [date.format(DATE_FORMAT), 'data', 'activities'], []);
            for (const activity of activities) {
              if (activity.type === 'shift' && activity.action !== 'remove' && activity.typeId === shiftType.id && !activity.available) {
                count++;
              }
            }
            date.add(1, 'd');
          }

          const canAddShift = count < shiftType.maxShiftCount;
          if (!canAddShift) {
            showStatus({
              text: this.$t('descriptions.maxShiftCountReached', { name: selectedType.name }),
              type: 'error'
            });
          }
          return canAddShift;
        };
        let selectedType;
        let validateAction = null;
        if (this.tab === 'addShift') {
          selectedType = {
            ...this.shiftTypesById[this.shiftType],
            available: false,
            type: 'shift'
          };
          validateAction = validateShift;
        } else if (this.tab === 'addOnCallShift') {
          selectedType = {
            ...this.shiftTypesById[this.onCallShiftType],
            available: false,
            type: 'shift'
          };
          validateAction = validateShift;
        } else if (this.tab === 'addAvailableShift') {
          selectedType = {
            ...this.shiftTypesById[this.availableShiftType],
            available: true,
            type: 'shift'
          };
        } else {
          selectedType = {
            ...this.eventTypesById[this.eventType],
            type: 'event'
          };
        }
        const assigneeId = this.$store.state.account.userId;
        const previousActivityIndex = _.findIndex(_.get(this.consolidatedItinerary, [date, 'data', 'activities'], []), (a) => {
          if (selectedType.type === 'event') {
            return a.type === selectedType.type;
          }
          return a.type === selectedType.type && a.typeId == selectedType.id && a.available === selectedType.available;
        });
        if (previousActivityIndex >= 0) {
          const previousActivity = this.consolidatedItinerary[date].data.activities[previousActivityIndex];
          // Remove the scheduled event/shift when users press on a day that is already scheduled.
          if (previousActivity.type === 'shift') {
            if (previousActivity.action === 'remove') {
              if (validateAction && !validateAction(selectedType)) {
                return;
              }
              this.updateShift(previousActivity);
            } else {
              this.deleteShift(date, [previousActivity]);
            }
          } else if (previousActivity.type === 'event') {
            if (previousActivity.action === 'remove') {
              this.updateEvent(previousActivity, previousActivity.dates, {});
            } else {
              const dates = _.difference(previousActivity.dates, [date]);
              if (dates.length === 0) {
                this.deleteEvent(previousActivity);
              } else {
                this.updateEvent(previousActivity, dates, {});
              }
            }
          }
        } else {
          // add/update a new event/shift to the schedule.
          const selectedTypeId = parseInt(selectedType.id);
          if (selectedType.type === 'shift') {
            if (this.tab === 'addShift' && abortAddingShift()) {
              return;
            }
            if (validateAction && !validateAction(selectedType)) {
              return;
            }
            const shift = {
              action: 'add',
              assigneeId,
              date: date,
              available: selectedType.available,
              flags: [],
              id: null,
              onCall: selectedType.onCall,
              type: 'shift',
              typeId: selectedTypeId
            };
            if (this.tab === 'addAvailableShift' && this.allowPartialShift) {
              shift.startTime = this.availableShiftInfo.start;
              shift.endTime = this.availableShiftInfo.end;
            }
            this.addShift(shift);
          } else if (selectedType.type === 'event') {
            const previousDayInfo = this.consolidatedItinerary[moment(date).subtract(1, 'day').format(DATE_FORMAT)];
            const nextDayInfo = this.consolidatedItinerary[moment(date).add(1, 'day').format(DATE_FORMAT)];
            const previousDayActivities = _.get(previousDayInfo, 'data.activities', []);
            const previousDayMatches = previousDayActivities.length === 1 && previousDayActivities[0].type === 'event' && previousDayActivities[0].typeId === selectedTypeId;
            const nextDayActivities = _.get(nextDayInfo, 'data.activities', []);
            const nextDayMatches = nextDayActivities.length === 1 && nextDayActivities[0].type === 'event' && nextDayActivities[0].typeId === selectedTypeId;
            let dates = [];
            if (previousDayMatches && nextDayMatches) {
              dates = _.sortBy([...previousDayActivities[0].dates, ...nextDayActivities[0].dates, date]);
              this.updateEvent(previousDayActivities[0], dates, {});
            } else if (previousDayMatches) {
              dates = _.sortBy([...previousDayActivities[0].dates, date]);
              this.updateEvent(previousDayActivities[0], dates, {});
            } else if (nextDayMatches) {
              dates = _.sortBy([...nextDayActivities[0].dates, date]);
              this.updateEvent(nextDayActivities[0], dates, {});
            } else {
              this.addEvent(date, {
                action: 'add',
                assigneeId,
                dates: [date],
                id: null,
                state: '',
                type: 'event',
                typeId: parseInt(selectedType.id)
              });
            }
          }
        }
      }
    },
    parseTime (time) {
      return `${time}:00`;
    },
    prev () {
      this.$refs.calendar.prev();
    },
    saveActivities () {
      if (!this.saving) {
        this.saving = true;
        const promises = [];
        let events = [];
        let eventsToCreate = [];
        let shifts = [];
        let shiftsToCreate = [];
        let partialSave = false;
        const changes = this.getChanges();
        if (!_.isEmpty(changes.shift.create)) {
          shiftsToCreate = _.values(changes.shift.create);
          promises.push(this.dispatch('scheduling/createShifts', shiftsToCreate).then((response) => {
            shifts = response;
          }).catch((error) => {
            partialSave = true;
            return error;
          }));
        }
        if (!_.isEmpty(changes.shift.remove)) {
          promises.push(this.dispatch('scheduling/deleteShifts', _.keys(changes.shift.remove)).then(() => {
            // Intentionally left empty. On success there is nothing to update
          }).catch((error) => {
            partialSave = true;
            return error;
          }));
        }
        if (!_.isEmpty(changes.shift.update)) {
          promises.push(this.dispatch('scheduling/updateShifts', changes.shift.update).then(() => {
            // Intentionally left empty. On success there is nothing to update
          }).catch((error) => {
            partialSave = true;
            return error;
          }));
        }
        if (!_.isEmpty(changes.event.create)) {
          eventsToCreate = _.values(changes.event.create);
          promises.push(this.dispatch('scheduling/createEvents', eventsToCreate).then((response) => {
            events = response;
          }).catch((error) => {
            partialSave = true;
            return error;
          }));
        }
        if (!_.isEmpty(changes.event.remove)) {
          promises.push(this.dispatch('scheduling/deleteEvents', _.keys(changes.event.remove)).then(() => {
            // Intentionally left empty. On success there is nothing to update.
          }).catch((error) => {
            return error;
          }));
        }
        if (!_.isEmpty(changes.event.update)) {
          promises.push(this.dispatch('scheduling/updateEvents', changes.event.update).then(() => {
            // Intentionally left empty. On success there is nothing to update
          }).catch((error) => {
            partialSave = true;
            return error;
          }));
        }

        Promise.all(promises).then(() => {
          if (partialSave) {
            showStatus({
              text: this.$t('descriptions.selfSchedulePartialSave'),
              type: 'error'
            });
            this.load();
          } else {
            const tracker = _.cloneDeep(this.tracker);
            for (let i = 0, len = shiftsToCreate.length; i < len; i++) {
              const shift = shiftsToCreate[i];
              const index = _.findIndex(_.get(tracker.changes, [shift.date, 'data', 'activities'], []), (a) => {
                return a.pseudoId === shift.pseudoId;
              });
              if (index >= 0) {
                tracker.changes[shift.date]['data']['activities'][index].id = shifts[i].id;
                tracker.changes[shift.date]['data']['activities'][index].action = '';
              }
            }

            const removedShifts = _.values(changes.shift.remove);
            for (let shift of removedShifts) {
              const index = _.findIndex(_.get(tracker.changes, [shift.date, 'data', 'activities'], []), (a) => {
                return a.pseudoId === shift.pseudoId;
              });
              if (index >= 0) {
                tracker.changes[shift.date].data.activities.splice(index, 1);
              }
            }

            const updatedShifts = _.values(changes.shift.update);
            for (let shift of updatedShifts) {
              const index = _.findIndex(_.get(tracker.changes, [shift.date, 'data', 'activities'], []), (a) => {
                return a.pseudoId === shift.pseudoId;
              });
              if (index >= 0) {
                tracker.changes[shift.date].data.activities[index].action = '';
              }
            }

            for (let i = 0, len = eventsToCreate.length; i < len; i++) {
              const event = eventsToCreate[i];
              for (let j = 0, datesLen = event.dates.length; j < datesLen; j++) {
                const index = _.findIndex(_.get(tracker.changes, [event.dates[i], 'data', 'activities'], []), (a) => {
                  return a.pseudoId === event.pseudoId;
                });
                if (index >= 0) {
                  tracker.changes[event.dates[i]]['data']['activities'][index].id = events[i].id;
                  tracker.changes[event.dates[i]]['data']['activities'][index].action = '';
                  tracker.changes[event.dates[i]]['data']['activities'][index].createdBy = this.$store.state.account.userId;
                }
              }
            }

            const removedEvents = _.values(changes.event.remove);
            for (let event of removedEvents) {
              const index = _.findIndex(_.get(tracker.changes, [event.dates[0], 'data', 'activities'], []), (a) => {
                return a.pseudoId === event.pseudoId;
              });
              if (index >= 0) {
                tracker.changes[event.dates[0]].data.activities.splice(index, 1);
              }
            }

            const updatedEvents = _.values(changes.event.update);
            for (let event of updatedEvents) {
              for (let i = 0, datesLen = event.dates.length; i < datesLen; i++) {
                const index = _.findIndex(_.get(tracker.changes, [event.dates[i], 'data', 'activities'], []), (a) => {
                  return a.pseudoId === event.pseudoId;
                });
                if (index >= 0) {
                  tracker.changes[event.dates[i]].data.activities[index].action = '';
                }
              }
            }

            this.itinerary = { ...this.itinerary, ...tracker.changes };
            this.tracker = {
              saved: {},
              changes: {}
            };
            this.$emit('update', this.getActivitiesByDate());
            showStatus({
              text: this.$t('descriptions.selfScheduleSaved')
            });
          }
        }).catch(error => {
          const data = {
            error: _.get(error, 'response.data')
          };
          showStatus({
            text: this.$t('descriptions.genericError'),
            type: 'error',
            data
          });
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    shouldDisableDate (date, dateInfo, itinerary) {
      // ! DATES THAT NEED TO BE DISABLED
      // * Pending swap/event requests
      // * Events that have already been confirmed
      // * Events that where created by admins (which may not have a state)
      // * Shifts that are already confirmed
      if (_.has(dateInfo, 'data.request')) {
        return true;
      } else {
        if (['addShift', 'addOnCallShift'].includes(this.tab) && this.bidForOpening) {
          const hasOpenShiftActivity = _.find(_.get(dateInfo, 'data.activities', []), (a) => {
            return a.type === CONTENT_TYPES.shift && !!a.openShiftId && a.typeId == this.selectedTypeId;
          });
          const hasExistingActivity = _.find(_.get(dateInfo, 'data.activities', []), (a) => {
            return (a.type === CONTENT_TYPES.shift && !a.openShiftId) || (a.type === CONTENT_TYPES.event);
          });
          const openShift = _.find(_.get(dateInfo, 'data.openShifts', []), (os) => {
            return os.typeId == this.selectedTypeId;
          });

          const activities = _.get(dateInfo, 'data.activities', []);
          if (activities.length > 0 && activities[0].type === CONTENT_TYPES.shift && activities[0].obligatory) {
            return true;
          } else if (hasOpenShiftActivity) {
            return false;
          } else if (hasExistingActivity || !openShift || !this.canBidForOpenShift(openShift)) {
            return true;
          }
        } else {
          let activity = null;
          const hasConflict = (typeId, available) => {
            if (!typeId) {
              return true;
            }
            const activities = _.get(dateInfo, 'data.activities', []);
            const shiftType = this.shiftTypesById[typeId];
            if (activities.length > 0) {
              if (activities[0].type === CONTENT_TYPES.event) {
                // When a day has an event, shifts cannot be added since events need to be the only activity in a day.
                return true;
              }
              const activity = _.find(activities, (a) => {
                return a.typeId == typeId && a.available === available;
              });

              if (!activity) {
                // Check for time conflicts with other shifts
                activities.push(..._.get(
                  itinerary,
                  [moment(date).subtract(1, 'd'), 'data', 'activities'], [])
                );
                activities.push(..._.get(
                  itinerary,
                  [moment(date).add(1, 'd'), 'data', 'activities'], [])
                );

                let newActivityStart, newActivityEnd;
                newActivityStart = moment(`${date} ${shiftType.startTime}`);
                newActivityEnd = moment(`${date} ${shiftType.endTime}`);
                if (shiftType.endTime <= shiftType.startTime) {
                  newActivityEnd.add(1, 'd');
                }

                return _.filter(activities, (a) => {
                  if (a.type === CONTENT_TYPES.shift) {
                    let startTime = a.startTime ? a.startTime : this.shiftTypesById[a.typeId].startTime;
                    let endTime = a.endTime ? a.endTime : this.shiftTypesById[a.typeId].endTime;
                    let start = moment(`${a.date} ${startTime}`);
                    let end = moment(`${a.date} ${endTime}`);
                    if (endTime <= startTime) {
                      end.add(1, 'd');
                    }
                    if (start.isBetween(newActivityStart, newActivityEnd, undefined, '[)') || end.isBetween(newActivityStart, newActivityEnd, undefined, '(]') ||
                      newActivityStart.isBetween(start, end, undefined, '[)') || newActivityEnd.isBetween(start, end, undefined, '(]')
                    ) {
                      return true;
                    }
                  }
                  return false;
                }).length > 0;
              } else {
                if (this.alwaysOpened) {
                  if (![SCHEDULE_STATES.INITIAL, SCHEDULE_STATES.SELF_SCHEDULE].includes(this.schedule.state) && !activity.available) {
                    return true;
                  } else if (activity.obligatory) {
                    return true;
                  }
                } else {
                  if (activity.openShiftId) {
                    return true;
                  } else if (activity.obligatory) {
                    return true;
                  }
                }
              }
            }
            return false;
          };
          switch (this.tab) {
            case 'addShift':
              return hasConflict(this.shiftType, false);
            case 'addOnCallShift':
              return hasConflict(this.onCallShiftType, false);
            case 'addAvailableShift':
              return hasConflict(this.availableShiftType, true);
            case 'addEvent':
              activity = _.get(dateInfo, 'data.activities', [])[0];
              if (activity) {
                if (activity.type !== CONTENT_TYPES.event) {
                  // When a day has shifts, events cannot be added since events need to be the only activity in a day.
                  return true;
                } else if (activity.id && (activity.state === REQUEST_STATES.APPROVED ||
                  (activity.state === REQUEST_STATES.EMPTY && activity.createdBy !== this.$store.state.account.userId))) {
                  // Events that were added by management cannot be removed.
                  return true;
                }
              }
              break;
          }
        }
      }

      return false;
    },
    showSlits (dateInfo) {
      return _.filter(
        _.get(dateInfo, 'data.activities'),
        (a) => a.type === CONTENT_TYPES.shift && a.action !== 'remove'
      ).length > 1;
    },
    timeMask (value) {
      const hours = [
        /[0-2]/,
        value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/
      ];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2
        ? [...hours, ':', ...minutes]
        : hours;
    },
    updateEndTime (time) {
      const updateProps = () => {
        this.shiftEndTimeCount++;
        this.showShiftEndTime = false;
        // It's possible for users to manually enter the same time for start and end time which is invalid and the
        // time is not updated on the model, but if a user edits either one of them that will make the other time valid
        // and needs to be updated without requiring the user to trigger a "blur".
        if (this.$refs.shiftStartTime.valid) {
          this.availableShiftInfo.start = this.parseTime(this.shiftStartTimeDisplay);
          this.shiftStartTimeCount++;
          this.showShiftStartTime = false;
        }
      };
      if (time) {
        this.availableShiftInfo.end = time;
        this.shiftEndTimeDisplay = this.formatTime(time);
        updateProps();
      } else if (this.$refs.shiftEndTime.valid) {
        this.availableShiftInfo.end = this.parseTime(this.shiftEndTimeDisplay);
        updateProps();
      }
    },
    updateStartTime (time) {
      const updateProps = () => {
        this.shiftStartTimeCount++;
        this.showShiftStartTime = false;
        // It's possible for users to manually enter the same time for start and end time which is invalid and the
        // time is not updated on the model, but if a user edits either one of them that will make the other time valid
        // and needs to be updated without requiring the user to trigger a "blur".
        if (this.$refs.shiftEndTime.valid) {
          this.availableShiftInfo.end = this.parseTime(this.shiftEndTimeDisplay);
          this.shiftEndTimeCount++;
          this.showShiftEndTime = false;
        }
      };
      if (time) {
        this.availableShiftInfo.start = time;
        this.shiftStartTimeDisplay = this.formatTime(time);
        updateProps();
      } else if (this.$refs.shiftStartTime.valid) {
        this.availableShiftInfo.start = this.parseTime(this.shiftStartTimeDisplay);
        updateProps();
      }
    },
    updateTabHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const selfScheduleContent = document.getElementById('selfScheduleContent');
      if (el && selfScheduleContent) {
        this.tabHeight = el.clientHeight - selfScheduleContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>

<style lang="scss">
#selfSchedule {
  .day-slot {
    height: 40px;
  }
  .end-only {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 10px;
    margin-right: 8px;
  }
  .extend {
    height: 10px;
  }
  .left-slit {
    background-color: #FFF;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    height: 3px;
    left: 0px;
    position: absolute;
    top: 12px;
    width: 6px;
  }
  .right-slit {
    background-color: #FFF;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 3px;
    position: absolute;
    right: 0px;
    top: 12px;
    width: 6px;
  }
  .start-and-end {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 10px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .start-only {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 10px;
    margin-left: 8px;
  }
  .v-calendar-monthly {
    border: none;
    .v-calendar-weekly__day {
      background-color: #FFFFFF !important;
      &:last-child {
        border-right: none;
      }
      .disabled {
        background-color: map-get($grey, 'lighten-3') !important;
        opacity: 0.5;
      }
    }
    .v-calendar-weekly__head {
      > div {
        background-color: #FFFFFF !important;
        border-bottom: 1px solid map-get($grey, 'lighten-2') !important;
        color: map-get($grey, 'darken-3');
        padding: 2px 0px;
      }
    }
  }
  .v-calendar-weekly__day {
    .disabled {
      &:hover {
        cursor: default;
      }
    }
    .flag-indicator {
      color: $info-lighten-1 !important;
      left: 24px;
      position: absolute;
      top: 38px;
      z-index: 1;
    }
    .validation-error {
      left: 23px;
      position: absolute;
      top: 16px;
      z-index: 1;
    }
  }
  .v-chip {
    height: 8px !important;
  }
  .v-tabs .v-tabs-bar {
    border: none !important;
    margin-right: 0px;

    .v-tab--active {
      color: map-get($grey, 'darken-3');
    }
  }
  .v-toolbar {
    border-bottom: 1px solid map-get($grey, 'lighten-2');
  }
}
</style>
