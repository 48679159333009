<template>
  <v-container
    class="snapshot-history px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-history</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.viewSnapshots') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-container
      class="pa-0 mt-4"
      :style="snapshotsListStyle"
    >
      <v-hover
        v-for="(snapshot) in availableSnapshots"
        :key="snapshot.id"
        v-slot:default="{ hover }"
      >
        <v-card
          :class="['px-4 mb-4', snapshot.id === selectedSnapshotId ? 'active' : '']"
          :elevation="hover ? 2 : 0"
          outlined
          width="100%"
          @click="$emit('change', snapshot)"
        >
          <v-list-item class="px-0">
            <v-list-item-content>
              <span>
                <v-chip
                  :class="['font-weight-medium white--text', snapshot.style.stateLabelCssClass]"
                  small
                >
                  {{ $t(snapshot.style.stateLabelKey) }}
                </v-chip>
              </span>
            </v-list-item-content>
            <v-list-item-action class="float-right caption grey--text">
              {{ `${$t('labels.createdOn')} ${moment(snapshot.createdOn).format($store.getters['org/getDateTimeFormatLong']())}` }}
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-hover>
    </v-container>
  </v-container>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    availableSnapshots: {
      required: true,
      type: Array
    },
    selectedSnapshotId: {
      required: true,
      type: [Number, String]
    }
  },
  computed: {
    snapshotsListStyle () {
      return {};
    }
  },
  methods: {
    moment
  }
};
</script>

<style lang="scss">
.snapshot-history {
  .active {
    border-color: $secondary;
  }
}
</style>
