<template>
  <v-container
    id="AddShift"
    class="px-0 py-1"
  >
    <v-list-item class="headline page-title py-0 px-8">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-plus</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $tc('labels.addShift', 1) }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <StaffSelection
      v-if="$can('edit', 'shift')"
      class="px-8"
      :date="date"
      :height="contentHeight"
      :selected-staff="{}"
      :show-flout-out="showFloutOut"
      :show-undo="false"
      @add-staff="(data) => $emit('add-staff', data)"
    />
  </v-container>
</template>

<script>
import _ from 'lodash';
import StaffSelection from '@/components/scheduling/StaffSelection';

export default {
  components: {
    StaffSelection
  },
  props: {
    date: {
      required: true,
      type: String
    },
    selectedStaff: {
      required: true,
      type: Object
    },
    showFloutOut: {
      default: true,
      type: Boolean
    }
  },
  data () {
    return {
      contentHeight: 500
    };
  },
  mounted: function () {
    this.updateTabHeight();
    window.addEventListener('resize', _.debounce(this.updateTabHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateTabHeight);
  },
  methods: {
    updateTabHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const staffContent = document.getElementById('AddShift');
      if (el && staffContent) {
        this.contentHeight = el.clientHeight - staffContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>
