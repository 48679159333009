var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "openshifts", attrs: { fluid: "" } },
    [
      [
        _c("portal", { attrs: { to: "page-title" } }, [
          _c("span", { staticClass: "title page-title text-truncate" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.$tc("labels.openShift", 2)) + "\n      "
            )
          ])
        ]),
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              { staticClass: "no-gutters" },
              [
                _c(
                  "v-tabs",
                  {
                    attrs: {
                      grow: _vm.$vuetify.breakpoint.smAndDown ? true : false,
                      "mobile-breakpoint": "",
                      "show-arrows": false,
                      "slider-color": "accent",
                      "slider-size": "3"
                    },
                    on: { change: _vm.retrieveOpenShiftsForSelectedTab },
                    model: {
                      value: _vm.selectedTab,
                      callback: function($$v) {
                        _vm.selectedTab = $$v
                      },
                      expression: "selectedTab"
                    }
                  },
                  [
                    _c(
                      "v-tab",
                      { staticClass: "px-6", attrs: { href: "#pending" } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.pendingOpenShiftsTabLabel) +
                            "\n          "
                        )
                      ]
                    ),
                    _c(
                      "v-tab",
                      { staticClass: "px-6", attrs: { href: "#history" } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.historyOpenShiftsTabLabel) +
                            "\n          "
                        )
                      ]
                    ),
                    _c(
                      "v-tabs-items",
                      {
                        attrs: { touchless: "" },
                        model: {
                          value: _vm.selectedTab,
                          callback: function($$v) {
                            _vm.selectedTab = $$v
                          },
                          expression: "selectedTab"
                        }
                      },
                      [
                        _c(
                          "v-tab-item",
                          {
                            attrs: {
                              "reverse-transition": false,
                              transition: false,
                              value: "pending"
                            }
                          },
                          [
                            !_vm.retrievingPendingOpenShifts &&
                            !_vm.pendingOpenShiftsFiltered &&
                            !_vm.pendingOpenShiftCount
                              ? _c(
                                  "v-row",
                                  {
                                    staticClass: "no-openshift",
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          "align-self": "center",
                                          cols: "12",
                                          md: "10"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mb-4",
                                            attrs: { justify: "center" }
                                          },
                                          [
                                            _c("v-img", {
                                              attrs: {
                                                contain: "",
                                                "max-width": "120",
                                                src: require("@/assets/images/no-content-penguin.svg")
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mt-4",
                                            attrs: { justify: "center" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "descriptions.noOpenShiftPending"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-spacer")
                                  ],
                                  1
                                )
                              : _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-data-table", {
                                      directives: [
                                        {
                                          name: "resize",
                                          rawName: "v-resize.quiet",
                                          value: _vm.onWindowResized,
                                          expression: "onWindowResized",
                                          modifiers: { quiet: true }
                                        }
                                      ],
                                      staticClass:
                                        "elevation-1 pending-openshifts",
                                      attrs: {
                                        dense: "",
                                        headers: _vm.pendingOpenShiftsHeaders,
                                        "header-props": {
                                          sortIcon: "fa fa-arrow-up"
                                        },
                                        "hide-default-footer": "",
                                        items:
                                          _vm.$store.state.openshift
                                            .pendingOpenShifts.records,
                                        "items-per-page":
                                          _vm.$store.state.openshift.pageSize,
                                        loading:
                                          _vm.retrievingPendingOpenShifts,
                                        "loading-text": _vm.$t(
                                          "descriptions.loadingOpenShifts"
                                        ),
                                        "mobile-breakpoint": "",
                                        "must-sort": "",
                                        "no-data-text": _vm.$t(
                                          "descriptions.noOpenShiftPending"
                                        ),
                                        options: _vm.pendingOpenShiftsOptions,
                                        page: _vm.pendingOpenShiftsCurrentPage,
                                        "server-items-length":
                                          _vm.$store.state.openshift
                                            .pendingOpenShifts.count,
                                        "single-select": true
                                      },
                                      on: {
                                        "update:options": function($event) {
                                          _vm.pendingOpenShiftsOptions = $event
                                        },
                                        "update:page": function($event) {
                                          _vm.pendingOpenShiftsCurrentPage = $event
                                        },
                                        "click:row": _vm.onItemClicked
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "header.departmentId",
                                          fn: function(ref) {
                                            var header = ref.header
                                            return [
                                              _c("MultiSelectionList", {
                                                attrs: {
                                                  selection: _vm.departments
                                                },
                                                on: {
                                                  selectionConfirmed: function(
                                                    $event
                                                  ) {
                                                    return _vm.onPendingOpenShiftFilterChanged(
                                                      header.value,
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-n4 subtitle-2 text-capitalize",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  text: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$tc(
                                                                      "labels.department",
                                                                      1
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              _vm
                                                                .pendingOpenShiftsOptions
                                                                .filterBy
                                                                .departmentId
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fas fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fal fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "header.jobTypes",
                                          fn: function(ref) {
                                            var header = ref.header
                                            return [
                                              _c("MultiSelectionList", {
                                                attrs: {
                                                  selection: _vm.jobTypes
                                                },
                                                on: {
                                                  selectionConfirmed: function(
                                                    $event
                                                  ) {
                                                    return _vm.onPendingOpenShiftFilterChanged(
                                                      header.value,
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-n4 subtitle-2 text-capitalize",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  text: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$tc(
                                                                      "labels.jobType",
                                                                      2
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              _vm
                                                                .pendingOpenShiftsOptions
                                                                .filterBy
                                                                .jobTypes
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fas fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fal fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "header.bids",
                                          fn: function(ref) {
                                            var header = ref.header
                                            return [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "max-width": "400",
                                                    "min-width": "140",
                                                    "offset-y": true
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "ml-n4 subtitle-2 text-capitalize",
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                    text: ""
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "labels.selectedBids"
                                                                      )
                                                                    ) +
                                                                    "\n                          "
                                                                ),
                                                                _vm
                                                                  .pendingOpenShiftsOptions
                                                                  .filterBy.bids
                                                                  ? _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2 mt-1",
                                                                        attrs: {
                                                                          color:
                                                                            "grey",
                                                                          right:
                                                                            "",
                                                                          "x-small":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            fas fa-filter\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2 mt-1",
                                                                        attrs: {
                                                                          color:
                                                                            "grey",
                                                                          right:
                                                                            "",
                                                                          "x-small":
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            fal fa-filter\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: _vm.bidsFilterOpen,
                                                    callback: function($$v) {
                                                      _vm.bidsFilterOpen = $$v
                                                    },
                                                    expression: "bidsFilterOpen"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    { staticClass: "px-4" },
                                                    [
                                                      _c("v-switch", {
                                                        attrs: {
                                                          color: "success"
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.onPendingOpenShiftFilterChangedInternal(
                                                              header.value,
                                                              $event
                                                            )
                                                          }
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "label",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "mr-4 body-2 grey--text text--darken-3",
                                                                      attrs: {
                                                                        title: _vm.$t(
                                                                          "labels.pendingBidsOnly"
                                                                        )
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "labels.pendingBidsOnly"
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.pendingBidsOnlyFilter,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.pendingBidsOnlyFilter = $$v
                                                          },
                                                          expression:
                                                            "pendingBidsOnlyFilter"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "header.typeId",
                                          fn: function(ref) {
                                            var header = ref.header
                                            return [
                                              _c("MultiSelectionList", {
                                                attrs: {
                                                  selection: _vm.shiftTypes
                                                },
                                                on: {
                                                  selectionConfirmed: function(
                                                    $event
                                                  ) {
                                                    return _vm.onPendingOpenShiftFilterChanged(
                                                      header.value,
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-n4 subtitle-2 text-capitalize",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  text: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.shiftType"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              _vm
                                                                .pendingOpenShiftsOptions
                                                                .filterBy.typeId
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fas fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fal fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "header.fcfs",
                                          fn: function(ref) {
                                            var header = ref.header
                                            return [
                                              _c("MultiSelectionList", {
                                                attrs: { selection: _vm.fcfs },
                                                on: {
                                                  selectionConfirmed: function(
                                                    $event
                                                  ) {
                                                    return _vm.onPendingOpenShiftFilterChanged(
                                                      header.value,
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-n4 subtitle-2 text-uppercase",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  text: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.fcfs"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              _vm
                                                                .pendingOpenShiftsOptions
                                                                .filterBy.fcfs
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fas fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fal fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.departmentId",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate type",
                                                  staticStyle: {
                                                    width: "116px"
                                                  },
                                                  attrs: {
                                                    title: _vm.getDepartment(
                                                      item
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getDepartment(item)
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.jobTypes",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate type",
                                                  staticStyle: {
                                                    width: "116px"
                                                  },
                                                  attrs: {
                                                    title: _vm.getJobType(item)
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getJobType(item)
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.bids",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate type"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getSelectedBids(
                                                          item
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.typeId",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate type",
                                                  staticStyle: {
                                                    width: "116px"
                                                  },
                                                  attrs: {
                                                    title: _vm.getShiftType(
                                                      item
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getShiftType(item)
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.fcfs",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    item.fcfs
                                                      ? _vm.$t("labels.yes")
                                                      : _vm.$t("labels.no")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.endsIn",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(_vm.getEndsIn(item)) +
                                                  "\n                  "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.date",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.formatDate(item.date)
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.createdOn",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.formatDateTime(
                                                      item.createdOn
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "no-data",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "body-2 grey--text mt-5 no-results text--darken-3 text-left"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "my-2 subtitle-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "descriptions.noResultsFound"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mb-2 mt-4"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "descriptions.emptyGridHelpTitle"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "my-2" },
                                                    [
                                                      _c("ul", [
                                                        _c("li", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "descriptions.emptyGridHelpRemoveFilters"
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "descriptions.emptyGridHelpShorterWords"
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "descriptions.emptyGridHelpSpelling"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "footer",
                                          fn: function() {
                                            return [
                                              _vm.$vuetify.breakpoint.smAndDown
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mobile pa-1 pagination text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.retrievingPendingOpenShifts ||
                                                                _vm.pendingOpenShiftsCurrentPage ===
                                                                  1,
                                                              icon: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.paginatePendingOpenShifts(
                                                                  -1
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            far fa-chevron-left\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2 mx-1"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.pendingOpenShiftsCurrentPage
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2 grey--text text--darken-2"
                                                          },
                                                          [_vm._v("/")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2 mx-1 grey--text text--darken-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$store
                                                                    .state
                                                                    .openshift
                                                                    .pendingOpenShifts
                                                                    .pages
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.retrievingPendingOpenShifts ||
                                                                _vm.pendingOpenShiftsCurrentPage ===
                                                                  _vm.$store
                                                                    .state
                                                                    .openshift
                                                                    .pendingOpenShifts
                                                                    .pages,
                                                              icon: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.paginatePendingOpenShifts(
                                                                  1
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            far fa-chevron-right\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _c("v-pagination", {
                                                    staticClass: "ma-1",
                                                    attrs: {
                                                      color: "secondary",
                                                      disabled:
                                                        _vm.retrievingPendingOpenShifts,
                                                      length:
                                                        _vm.$store.state
                                                          .openshift
                                                          .pendingOpenShifts
                                                          .pages,
                                                      "next-icon":
                                                        "far fa-chevron-right",
                                                      "prev-icon":
                                                        "far fa-chevron-left",
                                                      "total-visible":
                                                        _vm.maxPaginationControls
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.pendingOpenShiftsCurrentPage,
                                                      callback: function($$v) {
                                                        _vm.pendingOpenShiftsCurrentPage = $$v
                                                      },
                                                      expression:
                                                        "pendingOpenShiftsCurrentPage"
                                                    }
                                                  })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ]),
                                      model: {
                                        value: _vm.selectedRows,
                                        callback: function($$v) {
                                          _vm.selectedRows = $$v
                                        },
                                        expression: "selectedRows"
                                      }
                                    })
                                  ],
                                  1
                                )
                          ],
                          1
                        ),
                        _c(
                          "v-tab-item",
                          {
                            attrs: {
                              "reverse-transition": false,
                              transition: false,
                              value: "history"
                            }
                          },
                          [
                            !_vm.retrievingHistoryOpenShifts &&
                            !_vm.historyOpenShiftsFiltered &&
                            !_vm.historyOpenShiftCount
                              ? _c(
                                  "v-row",
                                  {
                                    staticClass: "no-openshift",
                                    attrs: { "no-gutters": "" }
                                  },
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          "align-self": "center",
                                          cols: "12",
                                          md: "10"
                                        }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mb-4",
                                            attrs: { justify: "center" }
                                          },
                                          [
                                            _c("v-img", {
                                              attrs: {
                                                contain: "",
                                                "max-width": "120",
                                                src: require("@/assets/images/no-content-penguin.svg")
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-row",
                                          {
                                            staticClass: "mt-4",
                                            attrs: { justify: "center" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "descriptions.noOpenShiftHistory"
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-spacer")
                                  ],
                                  1
                                )
                              : _c(
                                  "v-row",
                                  { attrs: { "no-gutters": "" } },
                                  [
                                    _c("v-data-table", {
                                      directives: [
                                        {
                                          name: "resize",
                                          rawName: "v-resize.quiet",
                                          value: _vm.onWindowResized,
                                          expression: "onWindowResized",
                                          modifiers: { quiet: true }
                                        }
                                      ],
                                      staticClass:
                                        "elevation-1 history-openshifts",
                                      attrs: {
                                        dense: "",
                                        headers: _vm.historyOpenShiftsHeaders,
                                        "header-props": {
                                          sortIcon: "fa fa-arrow-up"
                                        },
                                        "hide-default-footer": "",
                                        items:
                                          _vm.$store.state.openshift
                                            .historyOpenShifts.records,
                                        "items-per-page":
                                          _vm.$store.state.openshift.pageSize,
                                        loading:
                                          _vm.retrievingHistoryOpenShifts,
                                        "loading-text": _vm.$t(
                                          "descriptions.loadingOpenShifts"
                                        ),
                                        "mobile-breakpoint": "",
                                        "must-sort": "",
                                        "no-data-text": _vm.$t(
                                          "descriptions.noOpenShiftHistory"
                                        ),
                                        options: _vm.historyOpenShiftsOptions,
                                        page: _vm.historyOpenShiftsCurrentPage,
                                        "server-items-length":
                                          _vm.$store.state.openshift
                                            .historyOpenShifts.count,
                                        "single-select": true
                                      },
                                      on: {
                                        "update:options": function($event) {
                                          _vm.historyOpenShiftsOptions = $event
                                        },
                                        "update:page": function($event) {
                                          _vm.historyOpenShiftsCurrentPage = $event
                                        },
                                        "click:row": _vm.onItemClicked
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "header.departmentId",
                                          fn: function(ref) {
                                            var header = ref.header
                                            return [
                                              _c("MultiSelectionList", {
                                                attrs: {
                                                  selection: _vm.departments
                                                },
                                                on: {
                                                  selectionConfirmed: function(
                                                    $event
                                                  ) {
                                                    return _vm.onHistoryOpenShiftFilterChanged(
                                                      header.value,
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-n4 subtitle-2 text-capitalize",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  text: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$tc(
                                                                      "labels.department",
                                                                      1
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              _vm
                                                                .historyOpenShiftsOptions
                                                                .filterBy
                                                                .departmentId
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fas fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fal fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "header.jobTypes",
                                          fn: function(ref) {
                                            var header = ref.header
                                            return [
                                              _c("MultiSelectionList", {
                                                attrs: {
                                                  selection: _vm.jobTypes
                                                },
                                                on: {
                                                  selectionConfirmed: function(
                                                    $event
                                                  ) {
                                                    return _vm.onHistoryOpenShiftFilterChanged(
                                                      header.value,
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-n4 subtitle-2 text-capitalize",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  text: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$tc(
                                                                      "labels.jobType",
                                                                      2
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              _vm
                                                                .historyOpenShiftsOptions
                                                                .filterBy
                                                                .jobTypes
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fas fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fal fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "header.typeId",
                                          fn: function(ref) {
                                            var header = ref.header
                                            return [
                                              _c("MultiSelectionList", {
                                                attrs: {
                                                  selection: _vm.shiftTypes
                                                },
                                                on: {
                                                  selectionConfirmed: function(
                                                    $event
                                                  ) {
                                                    return _vm.onHistoryOpenShiftFilterChanged(
                                                      header.value,
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-n4 subtitle-2 text-capitalize",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  text: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.shiftType"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              _vm
                                                                .historyOpenShiftsOptions
                                                                .filterBy.typeId
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fas fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fal fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "header.fcfs",
                                          fn: function(ref) {
                                            var header = ref.header
                                            return [
                                              _c("MultiSelectionList", {
                                                attrs: { selection: _vm.fcfs },
                                                on: {
                                                  selectionConfirmed: function(
                                                    $event
                                                  ) {
                                                    return _vm.onHistoryOpenShiftFilterChanged(
                                                      header.value,
                                                      $event
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ml-n4 subtitle-2 text-uppercase",
                                                                attrs: {
                                                                  color:
                                                                    "secondary",
                                                                  text: ""
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "labels.fcfs"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                              _vm
                                                                .historyOpenShiftsOptions
                                                                .filterBy.fcfs
                                                                ? _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fas fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "grey",
                                                                        right:
                                                                          "",
                                                                        "x-small":
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            fal fa-filter\n                          "
                                                                      )
                                                                    ]
                                                                  )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.departmentId",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate type",
                                                  staticStyle: {
                                                    width: "116px"
                                                  },
                                                  attrs: {
                                                    title: _vm.getDepartment(
                                                      item
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getDepartment(item)
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.jobTypes",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate type",
                                                  staticStyle: {
                                                    width: "116px"
                                                  },
                                                  attrs: {
                                                    title: _vm.getJobType(item)
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getJobType(item)
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.bids",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate type"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getSelectedBids(
                                                          item
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.typeId",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "text-truncate type",
                                                  staticStyle: {
                                                    width: "116px"
                                                  },
                                                  attrs: {
                                                    title: _vm.getShiftType(
                                                      item
                                                    )
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.getShiftType(item)
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.fcfs",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    item.fcfs
                                                      ? _vm.$t("labels.yes")
                                                      : _vm.$t("labels.no")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.date",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.formatDate(item.date)
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "item.createdOn",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.formatDateTime(
                                                      item.createdOn
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "no-data",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "body-2 grey--text mt-5 no-results text--darken-3 text-left"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "my-2 subtitle-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "descriptions.noResultsFound"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mb-2 mt-4"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "descriptions.emptyGridHelpTitle"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "my-2" },
                                                    [
                                                      _c("ul", [
                                                        _c("li", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "descriptions.emptyGridHelpRemoveFilters"
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "descriptions.emptyGridHelpShorterWords"
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _c("li", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "descriptions.emptyGridHelpSpelling"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "footer",
                                          fn: function() {
                                            return [
                                              _vm.$vuetify.breakpoint.smAndDown
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mobile pa-1 pagination text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.retrievingHistoryOpenShifts ||
                                                                _vm.historyOpenShiftsCurrentPage ===
                                                                  1,
                                                              icon: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.paginateHistoryOpenShifts(
                                                                  -1
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            far fa-chevron-left\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2 mx-1"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.historyOpenShiftsCurrentPage
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2 grey--text text--darken-2"
                                                          },
                                                          [_vm._v("/")]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "body-2 mx-1 grey--text text--darken-2"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$store
                                                                    .state
                                                                    .openshift
                                                                    .historyOpenShifts
                                                                    .pages
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              disabled:
                                                                _vm.retrievingHistoryOpenShifts ||
                                                                _vm.historyOpenShiftsCurrentPage ===
                                                                  _vm.$store
                                                                    .state
                                                                    .openshift
                                                                    .historyOpenShifts
                                                                    .pages,
                                                              icon: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.paginateHistoryOpenShifts(
                                                                  1
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            far fa-chevron-right\n                          "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _c("v-pagination", {
                                                    staticClass: "ma-1",
                                                    attrs: {
                                                      color: "secondary",
                                                      disabled:
                                                        _vm.retrievingHistoryOpenShifts,
                                                      length:
                                                        _vm.$store.state
                                                          .openshift
                                                          .historyOpenShifts
                                                          .pages,
                                                      "next-icon":
                                                        "far fa-chevron-right",
                                                      "prev-icon":
                                                        "far fa-chevron-left",
                                                      "total-visible":
                                                        _vm.maxPaginationControls
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.historyOpenShiftsCurrentPage,
                                                      callback: function($$v) {
                                                        _vm.historyOpenShiftsCurrentPage = $$v
                                                      },
                                                      expression:
                                                        "historyOpenShiftsCurrentPage"
                                                    }
                                                  })
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ]),
                                      model: {
                                        value: _vm.selectedRows,
                                        callback: function($$v) {
                                          _vm.selectedRows = $$v
                                        },
                                        expression: "selectedRows"
                                      }
                                    })
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _vm.$can("edit", "openShift")
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { left: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "grey lighten-2",
                                              attrs: { icon: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          Object.assign({}, on, {
                                            click: _vm.showOpenShiftDialog
                                          })
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "16" } },
                                            [
                                              _vm._v(
                                                "\n                  fal fa-plus\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4036234129
                            )
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("labels.createOpenShift")) +
                                "\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      _c("SidePanel", { attrs: { panels: _vm.panels } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }