var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "snapshot-history px-8 py-0" },
    [
      _c(
        "v-list-item",
        { staticClass: "headline page-title pa-0" },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "icon" },
            [_c("v-icon", [_vm._v("fal fa-history")])],
            1
          ),
          _c("v-list-item-content", { staticClass: "panel-title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("labels.viewSnapshots")) + "\n    "
            )
          ]),
          _c(
            "v-list-item-action",
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("fal fa-times")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { staticClass: "pa-0 mt-4", style: _vm.snapshotsListStyle },
        _vm._l(_vm.availableSnapshots, function(snapshot) {
          return _c("v-hover", {
            key: snapshot.id,
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var hover = ref.hover
                    return [
                      _c(
                        "v-card",
                        {
                          class: [
                            "px-4 mb-4",
                            snapshot.id === _vm.selectedSnapshotId
                              ? "active"
                              : ""
                          ],
                          attrs: {
                            elevation: hover ? 2 : 0,
                            outlined: "",
                            width: "100%"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$emit("change", snapshot)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item",
                            { staticClass: "px-0" },
                            [
                              _c("v-list-item-content", [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-chip",
                                      {
                                        class: [
                                          "font-weight-medium white--text",
                                          snapshot.style.stateLabelCssClass
                                        ],
                                        attrs: { small: "" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                snapshot.style.stateLabelKey
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "v-list-item-action",
                                {
                                  staticClass: "float-right caption grey--text"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("labels.createdOn") +
                                          " " +
                                          _vm
                                            .moment(snapshot.createdOn)
                                            .format(
                                              _vm.$store.getters[
                                                "org/getDateTimeFormatLong"
                                              ]()
                                            )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }