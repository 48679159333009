import { render, staticRenderFns } from "./ShiftRequestCard.vue?vue&type=template&id=4b90bbda&"
import script from "./ShiftRequestCard.vue?vue&type=script&lang=js&"
export * from "./ShiftRequestCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VIcon,VList,VListItem,VListItemContent,VListItemGroup})


/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b90bbda')) {
      api.createRecord('4b90bbda', component.options)
    } else {
      api.reload('4b90bbda', component.options)
    }
    module.hot.accept("./ShiftRequestCard.vue?vue&type=template&id=4b90bbda&", function () {
      api.rerender('4b90bbda', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/scheduling/cards/ShiftRequestCard.vue"
export default component.exports