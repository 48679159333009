<template>
  <v-container
    class="schedule-daily-summary px-8 py-0"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-calendar-day</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.dailySummary') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-tabs
      id="dailySummaryContent"
      v-model="tab"
      centered
      :class="['dense', 'single-tab']"
      grow
      hide-slider
    >
      <v-tab
        class="text-capitalize"
        href="#dailySchedule"
      >
        {{ $tc('labels.shift', 2) }}
      </v-tab>
      <v-tab-item
        value="dailySchedule"
      >
        <template v-if="data.length > 0">
          <v-text-field
            v-model.trim="staffFilter"
            :append-icon="staffFilter ? '' : 'fal fa-search'"
            class="search-daily-staff py-3"
            :clearable="!!staffFilter"
            dense
            hide-details
            :placeholder="`${$t('labels.searchByName')}...`"
            outlined
          />
          <v-container
            class="pa-0"
            :style="scheduleTabStyle"
          >
            <v-hover
              v-for="(item) in schedule"
              :key="item.pseudoId"
              v-slot:default="{ hover }"
            >
              <ShiftsCard
                v-if="item.type === 'shift'"
                class="mb-4"
                :elevation="hover ? 2 : 0"
                :shifts="item.shifts"
                :user="item.user"
                @click="$emit('show-shift-details', { shifts: item.shifts, date: item.date, user: item.user })"
              />
            </v-hover>
            <v-card
              v-if="schedule.length === 0"
              outlined
            >
              <NoContent
                :height="140"
                :width="140"
                :message="$t('descriptions.noContentForDate', { date: moment(date).format(dateFormatString) })"
              />
            </v-card>
          </v-container>
        </template>
        <template v-else>
          <v-card
            outlined
          >
            <NoContent
              :height="140"
              :width="140"
              :message="$t('descriptions.noContentForDate', { date: moment(date).format(dateFormatString) })"
            />
          </v-card>
        </template>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import NoContent from '@/components/NoContent';
import ShiftsCard from '@/components/scheduling/ShiftsCard';

export default {
  components: {
    NoContent,
    ShiftsCard
  },
  props: {
    data: {
      default: function () {
        return [];
      },
      type: Array,
      required: true
    },
    date: {
      default: function () {
        return moment();
      },
      type: Object,
      required: true
    }
  },
  data () {
    return {
      staffFilter: '',
      tab: 'dailySchedule',
      tabHeight: 500
    };
  },
  computed: {
    dateFormatStringWithDoW () {
      return this.$store.getters['org/getDateFormatLongWithDoW']();
    },
    dateFormatString () {
      return this.$store.getters['org/getDateFormatLong']();
    },
    schedule () {
      let filteredData = this.data;
      if (this.staffFilter) {
        const text = this.staffFilter.toLowerCase();
        filteredData = _.filter(this.data, (o) => {
          return o.user.fullName.toLowerCase().indexOf(text) >= 0;
        });
      }
      const schedule = [];
      for (let i = 0, count = filteredData.length; i < count; i++) {
        const activities = _.get(filteredData[i], ['details', 'activities'], []);
        const shifts = [];
        for (let j = 0, activitiesCount = activities.length; j < activitiesCount; j++) {
          const activity = activities[j];
          switch (activity.type) {
            case 'shift':
              shifts.push(activity);
              break;
          }
        }
        if (shifts.length > 0) {
          schedule.push({
            date: this.date,
            pseudoId: shifts[0].pseudoId,
            shifts,
            type: 'shift',
            user: filteredData[i].user
          });
        }
      }
      return schedule;
    },
    scheduleTabStyle () {
      const SEARCH_HEIGHT = 90;
      return {
        'height': `${this.tabHeight - SEARCH_HEIGHT}px`,
        'overflow-y': 'auto'
      };
    },
    tabItemStyle () {
      const PADDING = 20;
      return {
        'height': `${this.tabHeight - PADDING}px`
      };
    }
  },
  mounted: function () {
    this.updateTabHeight();
    window.addEventListener('resize', _.debounce(this.updateTabHeight, 500));
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.updateTabHeight);
  },
  methods: {
    eventName (event) {
      return this.getEventTypeById(event.typeId).name;
    },
    getEventTypeById (id) {
      return this.$store.getters['org/getEventTypeById'](id);
    },
    getShiftTypeById (id) {
      return this.$store.getters['org/getShiftTypeById'](id);
    },
    moment,
    shiftName (shiftId) {
      return this.getShiftTypeById(parseInt(shiftId)).name;
    },
    shiftTime (shift) {
      const shiftType = this.getShiftTypeById(shift.typeId);
      return [_.split(shiftType.startTime, ':', 2).join(':'), _.split(shiftType.endTime, ':', 2).join(':')].join(' - ');
    },
    updateTabHeight () {
      const el = document.getElementsByClassName('side-panel')[0];
      const dailySummaryContent = document.getElementById('dailySummaryContent');
      if (el && dailySummaryContent) {
        this.tabHeight = el.clientHeight - dailySummaryContent.getBoundingClientRect().top;
      }
    }
  }
};
</script>

<style lang="scss">
.schedule-daily-summary {
  @include round-tabs(#837EB7, #FFF);
  .invalid-staff {
    position: absolute;
    left: 20px;
    padding-top: 5px;
  }
  .single-tab {
    .v-slide-group {
      display: none;
    }
    .new-shift {
      display: none;
    }
  }
}
</style>
