<template>
  <v-container
    class="px-8 py-3"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon />
      <v-list-item-content />
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-card outlined>
      <NoContent
        :height="140"
        :width="140"
      >
        <template v-slot:message>
          <div
            slot="message"
            class="subtitle-2 mt-10 text-center"
          >
            <div>
              {{ $t('descriptions.noContent') }}
            </div>
            <div>
              {{ $t('descriptions.selectScheduleCell') }}
            </div>
          </div>
        </template>
      </NoContent>
    </v-card>
  </v-container>
</template>

<script>
import NoContent from '@/components/NoContent';

export default {
  components: {
    NoContent
  }
};
</script>

<style lang="scss">

</style>
