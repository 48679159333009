<template>
  <v-container
    id="dailyScheduleNotes"
    class="px-8 py-1"
  >
    <v-list-item class="headline page-title pa-0">
      <v-list-item-icon class="icon">
        <v-icon>fal fa-file-alt</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="panel-title">
        {{ $t('labels.notes') }}
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          @click="$emit('close')"
        >
          <v-icon>fal fa-times</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-container class="new-memo pa-0">
      <v-textarea
        v-model.trim="newMemos[0].notes"
        :auto-grow="true"
        class="notes py-2"
        counter="1000"
        :disabled="disabled"
        maxlength="1000"
        outlined
        :placeholder="placeholder"
        rows="6"
        single-line
      />
      <div class="hints">
        <v-tooltip
          max-width="300px"
          top
        >
          <template #activator="{ on, attrs }">
            <v-icon
              class="disclosure-hint"
              color="nb-orange"
              x-small
              v-bind="attrs"
              v-on="on"
            >
              fal fa-comment-exclamation
            </v-icon>
          </template>
          <span class="body-2">
            {{ $t('descriptions.disclaimer') }}
          </span>
        </v-tooltip>
        <v-select
          v-model="newMemos[0].access"
          class="memo-access subtitle-2 xx-dense-select"
          color="info"
          dense
          :disabled="disabled"
          hide-details
          item-text="name"
          item-value="id"
          :items="accessList"
          :menu-props="{ top: false, offsetY: true, 'max-width': 350 }"
          outlined
        >
          <template v-slot:selection="data">
            <span class="info--text font-weight-regular">
              <v-icon
                class="mr-1"
                color="info"
                x-small
              >
                {{ data.item.icon }}
              </v-icon>
              {{ data.item.name }}
            </span>
          </template>
          <template v-slot:item="{ attrs, item, on }">
            <v-list-item
              class="access-item"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon class="mr-4">
                <v-icon
                  class="pt-2"
                  small
                >
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption grey--text">
                  {{ item.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </div>
    </v-container>
    <v-row
      v-if="!disabled"
    >
      <v-spacer />
      <v-col
        class="py-0"
        cols="6"
      >
        <v-btn
          block
          class="save"
          color="accent"
          :disabled="saving || !hasChanges"
          @click="update"
        >
          <v-progress-circular
            v-if="saving"
            color="primary lighten-2"
            indeterminate
            size="22"
            width="2"
          />
          <span v-else>
            {{ $t('labels.save') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { showStatus } from '@/plugins/vue-notification';
import { DATETIME_TZ_FORMAT } from '@/utils/ui';
import moment from 'moment';

const DeepDiff = require('deep-diff').diff;

export default {
  props: {
    dailySchedule: {
      default: function () {
        return {};
      },
      type: Object
    },
    date: {
      type: String,
      required: true
    },
    departmentId: {
      required: true,
      type: Number
    },
    readOnly: {
      default: false,
      type: Boolean
    },
    typeId: {
      required: true,
      type: Number
    }
  },
  data () {
    const memos = _.cloneDeep(_.get(this.dailySchedule, 'memos', []));
    if (memos.length === 0) {
      memos.push({
        'access': 'confidential',
        'notes': '',
        'createdBy': null,
        'createdOn': null,
        'modifiedBy': null,
        'modifiedOn': null
      });
    }
    return {
      accessList: [
        {
          icon: 'fal fa-eye-slash',
          id: 'confidential',
          name: this.$t('labels.confidential'),
          description: this.$t('descriptions.confidentialNotes')
        },
        {
          icon: 'fal fa-eye',
          id: 'public',
          name: this.$t('labels.public'),
          description: this.$t('descriptions.publicNotes')
        }
      ],
      id: _.get(this.dailySchedule, 'id', null),
      memos,
      newMemos: _.cloneDeep(memos),
      saving: false
    };
  },
  computed: {
    disabled () {
      return this.readOnly || !this.$can('edit', 'dailySchedule');
    },
    hasChanges () {
      const diff = DeepDiff(this.memos, this.newMemos);
      return diff && diff.length > 0;
    },
    placeholder () {
      let placeholder = `${this.$t('labels.addNotesPlaceholder')} ${this.$t('descriptions.dailyScheduleCommentsPlaceholder')}`;
      if (this.readOnly && !this.memos[0].notes) {
        placeholder = '';
      }
      return placeholder;
    }
  },
  methods: {
    dispatch (action, payload) {
      // This function is added mainly for easy of mocking during in unit tests.
      return new Promise((resolve, reject) => {
        this.$store.dispatch(action, payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },
    update () {
      if (!this.saving) {
        this.saving = true;
        const utcnow = moment.utc().format(DATETIME_TZ_FORMAT);
        if (!this.id) {
          this.newMemos[0].createdBy = this.$store.state.account.userId;
          this.newMemos[0].modifiedBy = this.$store.state.account.userId;
          this.newMemos[0].createdOn = utcnow;
          this.newMemos[0].modifiedOn = utcnow;
          const data = {
            date: this.date,
            departmentId: this.departmentId,
            memos: this.newMemos,
            typeId: this.typeId
          };
          this.dispatch('scheduling/createDailySchedule', data).then((dailySchedule) => {
            this.id = dailySchedule.id;
            this.memos = _.cloneDeep(this.newMemos);
            this.$emit('change', dailySchedule);
            showStatus({
              text: this.$t('descriptions.dailyScheduleNotesSaveSuccess')
            });
          }).catch((error) => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.dailyScheduleNotesSaveFail'),
              type: 'error',
              data
            });
          }).finally(() => {
            this.saving = false;
          });
        } else if (this.id > 0) {
          this.newMemos[0].modifiedBy = this.$store.state.account.userId;
          this.newMemos[0].modifiedOn = utcnow;
          this.dispatch('scheduling/updateDailySchedule', { id: this.id, data: { memos: this.newMemos } }).then((dailySchedule) => {
            this.id = dailySchedule.id;
            this.memos = _.cloneDeep(this.newMemos);
            this.$emit('change', dailySchedule);
            showStatus({
              text: this.$t('descriptions.dailyScheduleNotesSaveSuccess')
            });
          }).catch((error) => {
            const data = {
              error: _.get(error, 'response.data')
            };

            showStatus({
              text: this.$t('descriptions.dailyScheduleNotesSaveFail'),
              type: 'error',
              data
            });
          }).finally(() => {
            this.saving = false;
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
#dailyScheduleNotes {
  .new-memo {
    position: relative;
    .hints {
      position: absolute;
      bottom: 20px;
      left: 14px;

      .disclosure-hint {
        margin-right: 8px;
      }
    }
    .memo-access {
      display: inline-block;
      fieldset {
        border-color: $info;
        top: -3px;
      }
      .v-input__icon i {
        color: $info;
      }
      .v-select__selections {
        .v-icon {
          padding-bottom: 2px;
        }
      }
    }
  }
}
.access-item {
  .caption {
    white-space: normal;
  }
}
</style>
